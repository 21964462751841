import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const PainFree = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
      fill="#FFC220"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.29289 15.2929C5.68342 14.9024 6.31658 14.9024 6.70711 15.2929C9.63029 18.2161 14.3697 18.2161 17.2929 15.2929C17.6834 14.9024 18.3166 14.9024 18.7071 15.2929C19.0976 15.6834 19.0976 16.3166 18.7071 16.7071C15.0029 20.4113 8.99713 20.4113 5.29289 16.7071C4.90237 16.3166 4.90237 15.6834 5.29289 15.2929Z"
      fill="black"
      fillOpacity={0.5}
    />
    <path
      d="M10 9C10 10.1046 9.10457 11 8 11C6.89543 11 6 10.1046 6 9C6 7.89543 6.89543 7 8 7C9.10457 7 10 7.89543 10 9Z"
      fill="black"
      fillOpacity={0.5}
    />
    <path
      d="M18 9C18 10.1046 17.1046 11 16 11C14.8954 11 14 10.1046 14 9C14 7.89543 14.8954 7 16 7C17.1046 7 18 7.89543 18 9Z"
      fill="black"
      fillOpacity={0.5}
    />
  </svg>
);
export const PainFreeIcon = ({ ...props }: StaticIconProps) => {
  return <Icon icon={PainFree} {...props} color={undefined} />;
};
