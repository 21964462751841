import { useLocation } from 'react-router-dom';

import { parseSearch } from '@jane/shared-ecomm/util';

export const useQuery = <
  T extends Record<string, any> = Record<string, any>
>() => {
  const location = useLocation();

  return parseSearch<T>(location.search);
};
