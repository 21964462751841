import 'intersection-observer';
import startCase from 'lodash/startCase';
import { nanoid } from 'nanoid';
import ResizeObserver from 'resize-observer-polyfill';

import { config } from '@jane/shared/config';
import { Storage, TESTS } from '@jane/shared/util';

export default function bootstrap() {
  if (!window.ResizeObserver) {
    window.ResizeObserver = ResizeObserver;
  }

  if (Storage.get('user_session')) {
    // clear out old auth storage
    Storage.remove('user_session');
  }

  if (Storage.get('store-snapshot')) {
    // don't use legacy local cart persistence
    Storage.remove('store-snapshot');
  }

  // Fall back to a 5 second ready window in case a page doesn't mark as ready
  setTimeout(function () {
    window.prerenderReady = true;
  }, 5000);

  window.BUILD_VERSION = config.buildVersion?.substring(0, 7) || '';

  Storage.get('j_device_id') || Storage.set('j_device_id', nanoid());

  // Remove inactive AB tests from localStorage and Mixpanel super properties.
  try {
    // Remove historical AB tests that are no longer in local storage but in Cookies.
    const historicalTests = [
      'Test: Relevancy Score Algorithm 1',
      'Test: Relevancy Score Algorithm 2',
      'Test: Relevancy Score Algorithm 3',
      'Test: Relevancy Score Algorithm 4',
    ];
    historicalTests.forEach((t) => mixpanel.unregister(t));

    Object.entries(localStorage)
      ?.filter((entry) => entry[0].includes('test:'))
      ?.forEach((entry) => {
        const storageKey = entry[0];
        const testName = storageKey.split(':')[1];

        if (!Object.keys(TESTS).includes(testName)) {
          localStorage.removeItem(storageKey);
          mixpanel.unregister(`Test: ${startCase(testName)}`);
        }
      });
  } catch (e) {
    // localStorage not available
  }
}
