import styled from '@emotion/styled';

import chevronDown from '../../../../../../../../shared/assets/reefer/icons/dynamic/chevron-down-icon.svg';
import { shadows, spacing } from '../../../../styling';
import type { StyledFieldProps } from '../field.types';

export const StyledSelectContainer = styled.div({
  position: 'relative',
});

interface Props extends StyledFieldProps {
  disabled: boolean;
}

export const StyledSelect = styled.select<Props>(
  {
    appearance: 'none',
    WebkitAppearance: 'none',
    position: 'relative',
    backgroundImage: `url(${chevronDown})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'calc(100% - 10px) center',
    backgroundSize: '12px',
    width: '100%',
    boxShadow: shadows.drop,
    ...spacing({ py: 12, pl: 16, pr: 24 }),
  },
  ({ theme, disabled, radiusSize }) => ({
    cursor: disabled ? 'default' : 'pointer',
    backgroundColor: theme.colors.grays.white,
    border: `1px solid ${theme.colors.grays.light}`,
    borderRadius: theme.borderRadius[radiusSize],
    '&:active:not(:disabled)': {
      border: `1px solid ${theme.colors.primary.main}`,
    },
    '&:focus, &:focus-visible': {
      outline: 'none',
    },
    '&:focus-visible': {
      border: `1px solid ${theme.colors.primary.main}`,
    },
  })
);
