import { ErrorIcon, Flex, Typography } from '@jane/reefer';

import type { LocationErrors } from './userPreferencesModal';

export type ErrorMessages = {
  [key in LocationErrors]: {
    subtitle: string;
    title: string;
  };
};

export const errorMessages: ErrorMessages = {
  deliveryAddress: {
    title: 'For deliveries, please add a full address.',
    subtitle: '',
  },
  error: {
    title: 'Something went wrong.',
    subtitle:
      'Please try again or contact support@iheartjane.com to get some help.',
  },
  illegal: {
    title: 'Cannabis is not yet legalized in your state.',
    subtitle: 'Go back and try another location.',
  },
  noNearbyStores: {
    title: `There aren't any stores on Jane in your area yet.`,
    subtitle:
      'Try another location or email us at support@iheartjane.com to request a store to be added to our network!',
  },
  noDeliveryStores: {
    title: `There aren't stores that deliver to your address.`,
    subtitle: `It looks like there aren’t any stores that deliver to your address even though we detect products in your area. Change your settings and try again.`,
  },
  regulations: {
    title: 'Unavailable in your area due to state regulations.',
    subtitle:
      'The regulations in your state restrict the app from displaying your local stores. Go back and try another location.',
  },
};

export const UnservedLocationModalContent = ({
  error,
}: {
  error: LocationErrors;
}) => {
  if (!error) return null;
  return (
    <Flex justifyContent="center" flexDirection="column" alignItems="center">
      <ErrorIcon color="purple" size="xl" mb={24} />
      <Typography variant="header-bold" textAlign="center">
        {errorMessages[error].title}
      </Typography>
      {errorMessages[error].subtitle.length > 0 && (
        <Typography variant="body" mt={8} textAlign="center">
          {errorMessages[error].subtitle}
        </Typography>
      )}
    </Flex>
  );
};
