import { Flex, mediaQueries, styled } from '@jane/reefer';

export const LoadingContainer = styled(Flex)({
  position: 'absolute',
  top: '35%',
  left: 0,
  right: 0,
});

export const GroupDiscountOuter = styled(Flex)({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  [mediaQueries.desktop('sm', 'max')]: {
    flexDirection: 'row',
  },
});
