import { config } from '@jane/shared/config';
import type {
  AppMode,
  Coordinates,
  Id,
  ImpressionTrackingPlacement,
} from '@jane/shared/models';

export type DmInfo = DmInlineMatchingImpressionInfo;
export type DmEvent = DmEventMeta & {
  payload: ErrorDetails | DmInfo;
  type: 'info' | 'error';
};

export type DmEventMeta = EcommDmEventMeta | JamDmEventMeta;

interface EcommDmEventMeta {
  app_mode: AppMode;
  app_store_id?: Id;
  build_version: string;
  customer?: CustomerMeta;
  jane_device_id: string;
  source: 'client';
}

interface JamDmEventMeta {
  build_version: string;
  source: 'jam';
  user_id?: string;
}

export interface CustomerMeta {
  city_state?: string;
  coordinates?: Coordinates;
  email: string;
  first_name: string;
  id: number;
  last_name: string;
  nickname: string;
  phone: string;
}
interface ErrorDetails {
  message: string;
  name: string;
  response?: Response;
  stack?: string;
}
export interface DmInlineMatchingImpressionInfo {
  bucketNames: string[];
  matchedBucketNames: string[];
  placements: Record<string, ImpressionTrackingPlacement[]>;
  selectedFlightIds: string[];
  servedFlightIds: number[];
}

export const trackDmEvent = async (event: DmEvent): Promise<void> => {
  const { dmEventsUrl, dmServiceToken } = config;
  try {
    await fetch(dmEventsUrl, {
      method: 'POST',
      headers: { 'J-Event-Token': dmServiceToken },
      body: JSON.stringify(event),
    });
  } catch (e) {
    // do nothing
  }
};

export const getErrorDetails = (error: Error): ErrorDetails => {
  const { name, message, stack } = error;
  return { name, message, stack };
};
