import split from 'lodash/split';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Card, Flex, PartnerLogo, Typography, styled } from '@jane/reefer';
import { generateCustomLabel } from '@jane/shared-ecomm/util';
import type { Store } from '@jane/shared/models';

import { useMediaQuery } from '../../../../common/providers/mediaQueryProvider';
import { useCustomerDispatch, useCustomerSelector } from '../../../../customer';
import { setProductDetailBreadcrumbs } from '../../../../customer/redux/search';
import { isEmbeddedModeSelector } from '../../../../customer/selectors';
import { useProductReviewsEnabled } from '../../../../hooks/useProductReviewsEnabled';
import productPhotoMetadata from '../../../../lib/productPhotoMetadata';
import { titleize } from '../../../../lib/titleCase';
import { flex } from '../../../../style';
import { potencyInfo } from '../../../potency';
import { Category } from '../../../productCard/category';
import SpecialBadge from '../../../productCard/specialBadge';
import FiveStarRating from '../../../starRatings/fiveStarRating';
import { productDetailLink } from '../../productCardHelper';
import { useProductCardContext } from '../../providers/productCardProvider';

const BadgeContainer = styled.div(flex(), {
  position: 'absolute',
  top: 8,
  right: 8,
});

const getDiscountAmount = (special_amount: string, store: Store) => {
  const specialString =
    split(special_amount, ' ').length <= 2 ? special_amount : 'SALE';

  if (special_amount?.toUpperCase() === 'SALE') {
    return store?.store_compliance_language_settings?.specials || specialString;
  }

  return specialString;
};

export const ProductInfoCardContent = () => {
  const {
    appMode,
    currentSpecial,
    menuProduct,
    store,
    searchState,
    trackListViewClick,
  } = useProductCardContext();
  const { special_amount, brand, category, kind, brand_subtype, name } =
    menuProduct;
  const discountAmount = getDiscountAmount(special_amount, store);
  const { smallerThanVerticalTablet } = useMediaQuery();
  const navigate = useNavigate();

  const dispatch = useCustomerDispatch();
  const setProductBreadcrumbs = useCallback(() => {
    dispatch(setProductDetailBreadcrumbs(searchState));
  }, [searchState, dispatch]);

  const isEmbeddedMode = useCustomerSelector(isEmbeddedModeSelector);

  const productLineage = generateCustomLabel({
    appMode,
    store,
    attribute: category,
    isLineageLabel: true,
    fallback: titleize(category),
  });

  const specialApplies = discountAmount && currentSpecial;

  const photoMetadata = productPhotoMetadata(menuProduct);

  const reviewsEnabled = useProductReviewsEnabled(store);

  const onClickHandler = () => {
    trackListViewClick && trackListViewClick();
    setProductBreadcrumbs();
    navigate(
      productDetailLink({
        product: menuProduct,
        appMode,
        store,
      })
    );
  };

  return (
    <Card.Content
      onClick={onClickHandler}
      ariaLabel="Click to view product details"
    >
      <Flex
        flexDirection={smallerThanVerticalTablet ? 'row-reverse' : 'row'}
        p={8}
      >
        <Flex css={{ position: 'relative' }}>
          <PartnerLogo
            variant="store"
            name={menuProduct.name}
            image={photoMetadata.url}
          />
          <BadgeContainer>
            {specialApplies && (
              <SpecialBadge
                amount={discountAmount}
                isShorten
                specialAmount={menuProduct.special_amount}
              />
            )}
          </BadgeContainer>
        </Flex>
        <Flex
          pl={smallerThanVerticalTablet ? 0 : 16}
          flexDirection="column"
          width="100%"
        >
          <Flex flexDirection="column" justifyContent="center" minWidth={0}>
            <Flex flexDirection={'column'}>
              {category && (
                <Category
                  title={category || ''}
                  category={category}
                  editingMode={false}
                >
                  {productLineage}
                </Category>
              )}
              <Typography variant="header-bold" branded={!isEmbeddedMode}>
                {name}
              </Typography>

              <Typography color={'purple'} variant="body">
                {brand || null}
              </Typography>

              <Flex>
                <Typography variant="body">
                  {brand_subtype ? brand_subtype : titleize(kind)}
                </Typography>
                <Typography color={'grays-mid'} ml={16} variant="body">
                  {potencyInfo(menuProduct)}
                </Typography>
              </Flex>
            </Flex>
          </Flex>

          {reviewsEnabled && menuProduct.aggregate_rating ? (
            <Flex pb={16} alignItems="center">
              <FiveStarRating rating={menuProduct.aggregate_rating} small />

              <Typography mx={4}>{menuProduct.aggregate_rating}</Typography>
              <Typography color="grays-mid">
                ({menuProduct.review_count})
              </Typography>
            </Flex>
          ) : null}
        </Flex>
      </Flex>
    </Card.Content>
  );
};

export default ProductInfoCardContent;
