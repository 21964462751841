import { nanoid } from 'nanoid';
import { forwardRef, useEffect, useMemo, useState } from 'react';

import type { ModalContentProps } from '../modal.types';
import { StyledModalContent } from './modalContent.styles';

export const ModalContent = forwardRef<HTMLDivElement, ModalContentProps>(
  function ModalContent(
    { overflowY = 'auto', children, padding = true, testId },
    ref
  ) {
    const [divHeight, setDivHeight] = useState('100%');
    const uniqueId = useMemo(() => nanoid(), []);

    useEffect(() => {
      let offset = 0;

      const contentDiv = document.querySelector(`[data-id="${uniqueId}"]`);
      const previousSibling = contentDiv?.previousElementSibling;
      const nextSibling = contentDiv?.nextElementSibling;

      if (
        previousSibling?.getAttributeNode('data-id')?.value === 'modal-header'
      )
        offset += previousSibling?.clientHeight;
      if (nextSibling?.getAttributeNode('data-id')?.value === 'modal-footer')
        offset += nextSibling?.clientHeight;

      setDivHeight(`calc(100% - ${offset}px)`);
    }, [uniqueId]);

    return (
      <StyledModalContent
        ref={ref}
        overflowY={overflowY}
        data-id={uniqueId}
        height={divHeight}
        padding={padding}
        data-testid={testId}
      >
        {children}
      </StyledModalContent>
    );
  }
);
