import { useRef, useState } from 'react';

import { useWindowListener } from '../../../../../utils/useWindowListener';
import { List } from '../../../../list';
import { Typography } from '../../../../typography';
import {
  AutocompleteContainer,
  StyledAlignment,
  StyledListContainer,
  StyledOptionsContainer,
} from './textFieldAutocomplete.styles';
import type { TextFieldAutocompleteProps } from './textFieldAutocomplete.types';

export const TextFieldAutocomplete = ({
  errorMessage,
  handleClose,
  isOpen,
  name,
  onSelection,
  options,
  popoverFooter,
}: TextFieldAutocompleteProps) => {
  const popoverRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState<null | number>(null);

  const handleClickAway = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (
      popoverRef.current?.contains(event.target as Node) ||
      !document.contains(event.target as Node) ||
      target.getAttribute('name') === name
    ) {
      return;
    } else {
      setSelectedIndex(null);
      handleClose();
    }
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if (!isOpen) return;

    switch (event.key) {
      case 'Escape':
        event.preventDefault();
        setSelectedIndex(null);
        handleClose();
        return;
      case 'ArrowDown':
        event.preventDefault();
        handleArrowDown(selectedIndex);
        return;
      case 'ArrowUp':
        event.preventDefault();
        handleArrowUp(selectedIndex);
        return;
      case 'Enter':
        event.preventDefault();
        handleEnter(selectedIndex);
        return;
      default:
        if (selectedIndex) {
          setSelectedIndex(null);
        }
    }
  };

  const handleArrowDown = (selectedIndex: number | null) => {
    if (isOpen) {
      const newIndex =
        selectedIndex === null || selectedIndex === options.length - 1
          ? 0
          : selectedIndex + 1;
      setSelectedIndex(newIndex);

      document
        .getElementById(options[newIndex])
        ?.scrollIntoView({ block: 'nearest' });
    }
  };

  const handleArrowUp = (selectedIndex: number | null) => {
    if (isOpen) {
      const newIndex =
        selectedIndex === null || selectedIndex === 0
          ? options.length - 1
          : selectedIndex - 1;
      setSelectedIndex(newIndex);

      document
        .getElementById(options[newIndex])
        ?.scrollIntoView({ block: 'nearest' });
    }
  };

  const handleEnter = (selectedIndex: number | null) => {
    if (selectedIndex !== null) {
      onSelection(options[selectedIndex]);
      setSelectedIndex(null);
    }
  };

  useWindowListener('keydown', handleKeyPress);

  useWindowListener('click', handleClickAway);

  return (
    <AutocompleteContainer ref={popoverRef}>
      {errorMessage && (
        <Typography mt={0} color="error">
          {errorMessage}
        </Typography>
      )}
      {isOpen && options.length > 0 && (
        <StyledAlignment>
          <StyledOptionsContainer>
            <StyledListContainer>
              <List label={`${name}-list`}>
                {options.map((option, index) => (
                  <div id={option} key={option}>
                    <List.Item
                      py={4}
                      px={12}
                      selected={selectedIndex === index}
                      onClick={() => onSelection(option)}
                    >
                      <Typography>{option}</Typography>
                    </List.Item>
                  </div>
                ))}
              </List>
            </StyledListContainer>

            {popoverFooter && popoverFooter}
          </StyledOptionsContainer>
        </StyledAlignment>
      )}
    </AutocompleteContainer>
  );
};
