import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Indica = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.24454 4.00482V12.5068H8.75254V4.00482H7.24454Z"
      fill="#666666"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 4.00482C0 1.79568 1.79086 0.00482178 4 0.00482178H12C14.2091 0.00482178 16 1.79568 16 4.00482V12.0048C16 14.214 14.2091 16.0048 12 16.0048H4C1.79086 16.0048 0 14.214 0 12.0048V4.00482ZM4 1.00482H12C13.6569 1.00482 15 2.34797 15 4.00482V12.0048C15 13.6617 13.6569 15.0048 12 15.0048H4C2.34315 15.0048 1 13.6617 1 12.0048V4.00482C1 2.34797 2.34315 1.00482 4 1.00482Z"
      fill="#666666"
    />
  </svg>
);
export const IndicaIcon = ({ ...props }: DynamicIconProps) => {
  return <Icon icon={Indica} {...props} color={props.color} />;
};
