import type { MutableRefObject } from 'react';
import { useRef } from 'react';

import { useInViewObserver } from '../useInViewObserver';

export const useIsInView = (ref: MutableRefObject<HTMLElement | null>) => {
  const headerRef = useRef(document.getElementById('main-header'));
  const isInView = useInViewObserver(ref.current, {
    mktpOffsetTop: headerRef.current?.getBoundingClientRect().height || 0,
  });
  return isInView;
};
