import { useCustomerSelector } from '../../customer/selectors';
import { get } from '../../redux-util/selectors';
import type { IdentificationProps } from './idUploader';
import DeluxeIdentificationUploader from './idUploader';
import { WarningMessage } from './warningMessage';

const StudentIdentification = ({
  showValidationErrors,
  valid,
  onIdUploadAttempt,
}: IdentificationProps) => {
  const { document, loading_student_photo } = useCustomerSelector(
    get('identification')
  );

  if (!document) return null;

  return (
    <>
      <DeluxeIdentificationUploader
        imageKey="student_photo"
        inputName="government-id"
        label="Upload Student ID"
        document={document}
        isLoading={loading_student_photo}
        valid={valid}
        showValidationErrors={showValidationErrors}
        onIdUploadAttempt={onIdUploadAttempt}
      />
      {!document.student_photo && (
        <WarningMessage>ID required for discount</WarningMessage>
      )}
    </>
  );
};

export default StudentIdentification;
