import styled from '@emotion/styled';

import { hidden } from '../../styling';

export interface HideProps {
  /** Render the `Hide` component as this HTML element. Defaults to `div`. */
  as?: keyof JSX.IntrinsicElements;

  /**
   * The children that will be hidden when "isHidden" is true
   */
  children: React.ReactNode;

  /** Hides the children of this component */
  isHidden?: boolean;
}

/**
 * Hide is a simple helper component that can be used to programmatically hide content.
 * This can be useful for accessibility purposes, as well as many other things.
 */
export function Hide({ as = 'div', children, isHidden = false }: HideProps) {
  const HiddenSection = styled.div<{ isHidden: boolean }>(({ isHidden }) => ({
    ...hidden(isHidden),
  }));

  return (
    <HiddenSection as={as} isHidden={isHidden}>
      {children}
    </HiddenSection>
  );
}
