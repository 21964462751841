import type { ReactNode } from 'react';

import { Flex } from '../../../flex/flex';
import type { FieldProps } from '../field.types';
import { HelperText } from '../helperText/helperText';
import {
  StyledFieldWrapper,
  StyledLabel,
  StyledLabelWrapper,
} from './fieldWrapper.styles';

export interface RenderProps {
  'aria-describedby'?: string;
  'aria-labelledby': string;
}

export interface FieldWrapperProps extends Omit<FieldProps, 'autoFocus'> {
  /** Disables mobile text input styling */
  disableMobileInputStyling?: boolean;

  /** Customise helper text id */
  helperTextId?: string;

  /** If the input it is wrapping is a text input (for mobile styling) */
  isWrappingTextInput?: boolean;

  /** Where to position the field label, 't' for top, or 'r' for right. */
  labelPosition?: 't' | 'r';

  /** Render function */
  render: (renderProps: RenderProps) => ReactNode;

  /** Whether to reposition the helper text beside the label on mobile */
  repositionMobileHelperText?: boolean;
}

/**
 * This is a wrapper component for common functionality of fields.
 */
export const FieldWrapper = ({
  children,
  disabled = false,
  disableMobileInputStyling = false,
  helperText,
  helperTextId: helperTextIdProp,
  isWrappingTextInput = false,
  label,
  labelHidden = false,
  labelPosition = 't',
  name,
  render,
  repositionMobileHelperText = false,
  testId,
  width = 'auto',
  ...props
}: FieldWrapperProps) => {
  const labelId = `${name}-label`;
  let helperTextId;
  let describedBy;
  if (helperText || helperTextIdProp) {
    helperTextId = helperTextIdProp || name;
    describedBy = `${helperTextId}-helper-text`;
    if (repositionMobileHelperText) {
      describedBy = `${describedBy} ${helperTextId}-mobile-helper-text`;
    }
  }

  return (
    <StyledFieldWrapper
      data-testid={testId}
      disabled={disabled}
      isWrappingTextInput={isWrappingTextInput}
      labelHidden={labelHidden}
      repositionMobileHelperText={repositionMobileHelperText}
      width={width}
      disableMobileInputStyling={disableMobileInputStyling}
      {...props}
    >
      <Flex
        flexDirection={labelPosition === 't' ? 'column' : 'row-reverse'}
        justifyContent="flex-end"
      >
        <StyledLabelWrapper justifyContent="space-between">
          <StyledLabel
            as="label"
            disableMobileInputStyling={disableMobileInputStyling}
            htmlFor={name}
            id={labelId}
            labelPosition={labelPosition}
          >
            {label}
          </StyledLabel>
          {repositionMobileHelperText && !labelHidden && (
            <HelperText
              disabled={disabled}
              id={`${helperTextId}-mobile`}
              mb={16}
            >
              {helperText}
            </HelperText>
          )}
        </StyledLabelWrapper>

        {render({
          'aria-describedby': describedBy,
          'aria-labelledby': labelId,
        })}
      </Flex>
      {children}
      <HelperText disabled={disabled} id={helperTextId}>
        {helperText}
      </HelperText>
    </StyledFieldWrapper>
  );
};
