import {
  Box,
  CheckSmallIcon,
  Flex,
  GroupDiscountIcon,
  PromoIcon,
  Typography,
} from '@jane/reefer';
import { useGetStore } from '@jane/shared/data-access';
import type { Cart, StoreSpecial } from '@jane/shared/models';

import { Accordion } from '../../../../component-library/accordion';
import { useCustomerSelector } from '../../../../customer';
import {
  applicableCartTotalSpecial,
  applicableProductSpecials,
} from '../../../../lib/cart';
import {
  GroupDiscountHeader,
  SpecialsWrapper,
} from './cartDrawerSpecials.styles';
import { CustomerLoyaltyPoints } from './customerLoyaltyPoints/customerLoyaltyPoints';
import {
  GroupDiscounts,
  groupSpecialsTitle,
} from './groupDiscounts/groupDiscounts';
import { PromoCodes } from './promoCodes/promoCodes';

interface CartDrawerSpecialsProps {
  specials: StoreSpecial[];
}

export const CartDrawerSpecials = ({ specials }: CartDrawerSpecialsProps) => {
  const {
    appMode,
    cart: cartState,
    phone: customerPhone,
    id: customerId,
    queryPromoCode,
    disableAuthFeaturesState,
  } = useCustomerSelector(
    ({
      cart: { queryPromoCode, cart },
      customer: { id, phone },
      embeddedApp: { disableAuthFeatures, appMode },
    }) => ({
      appMode,
      cart,
      id,
      phone,
      queryPromoCode,
      disableAuthFeaturesState: disableAuthFeatures,
    })
  );

  const isKiosk = appMode === 'kiosk';
  const disableAuthFeatures = disableAuthFeaturesState || false;
  const cart = cartState as Cart;
  const { data: store } = useGetStore(cart?.store.id);

  const {
    cart_discount_special_id,
    discounted_subtotal,
    reservation_mode,
    loyalty_points_stacking_enabled,
  } = cart;

  const specialsLabel = store.store_compliance_language_settings?.specials;
  const productSpecials = applicableProductSpecials(cart, specials);

  const groupSpecials = specials.filter(
    (special) =>
      special.special_type === 'qualified_group' &&
      special.reservation_modes &&
      special.reservation_modes[reservation_mode]
  );

  const cartTotalSpecial = applicableCartTotalSpecial(
    specials,
    cart_discount_special_id
  );
  const appliedSpecials = cartTotalSpecial
    ? productSpecials.concat([cartTotalSpecial])
    : productSpecials;

  const appliedPromoCodeSpecial = appliedSpecials.find((s) => s.promo_code);

  const appliedGroupSpecial = appliedSpecials.find(
    (s) => s.special_type === 'qualified_group'
  );

  const shouldDisplayLoyaltyPoints = () => {
    return Boolean(customerPhone && store.crm_integration && !!customerId);
  };

  return (
    <SpecialsWrapper flexDirection="column">
      <Accordion>
        <Accordion.Item
          id="promo-code"
          defaultExpanded={queryPromoCode !== undefined}
        >
          <Accordion.Heading css={{ padding: '24px' }}>
            <Flex alignItems="center">
              <Flex flexDirection="column">
                <Flex>
                  <PromoIcon />
                  <Typography variant="body-bold" ml={16}>
                    Apply{' '}
                    {store?.store_compliance_language_settings?.promo_code ||
                      'promo code'}
                  </Typography>
                </Flex>
                {appliedPromoCodeSpecial && (
                  <Flex alignItems="center" mt={8}>
                    <CheckSmallIcon size="sm" color="success" mr={8} />
                    <Typography>{appliedPromoCodeSpecial.title}</Typography>
                  </Flex>
                )}
              </Flex>
              <Flex ml="auto">
                <Accordion.Indicator size={12} />
              </Flex>
            </Flex>
          </Accordion.Heading>
          <Accordion.Content css={{ padding: '0 24px' }}>
            <PromoCodes
              reservationMode={reservation_mode}
              userGroupSpecialId={appliedGroupSpecial?.id}
            />
          </Accordion.Content>
        </Accordion.Item>

        {!isKiosk && !disableAuthFeatures && groupSpecials.length > 0 && (
          <Accordion.Item id="group-discount">
            <GroupDiscountHeader>
              <Flex alignItems="center">
                <Flex flexDirection="column">
                  <Flex>
                    <GroupDiscountIcon />
                    <Typography variant="body-bold" ml={16}>
                      {groupSpecialsTitle(groupSpecials, specialsLabel)}
                    </Typography>
                  </Flex>
                  {appliedGroupSpecial && (
                    <Flex alignItems="center" mt={8}>
                      <CheckSmallIcon size="sm" color="success" mr={8} />
                      <Typography>{appliedGroupSpecial.title}</Typography>
                    </Flex>
                  )}
                </Flex>
                <Flex ml="auto">
                  <Accordion.Indicator size={12} />
                </Flex>
              </Flex>
            </GroupDiscountHeader>
            <Accordion.Content css={{ padding: '0 24px' }}>
              <GroupDiscounts
                userGroupSpecialId={appliedGroupSpecial?.id}
                groupSpecials={groupSpecials}
              />
            </Accordion.Content>
          </Accordion.Item>
        )}

        {shouldDisplayLoyaltyPoints() && (
          <Box mb={16}>
            <CustomerLoyaltyPoints
              cartDrawer={true}
              cartUuid={cart.uuid}
              phone={customerPhone}
              loyaltyPointsStacking={loyalty_points_stacking_enabled}
              postDiscountSubtotal={discounted_subtotal}
              appliedRedemptions={cart.crm_redemptions}
              reservationMode={reservation_mode}
              store={store}
            />
          </Box>
        )}
      </Accordion>
    </SpecialsWrapper>
  );
};
