import {
  BlissfulIcon,
  CbdIcon,
  CreativeIcon,
  EaseMyMindIcon,
  EdiblesIcon,
  EnergeticIcon,
  FlowerIcon,
  GetReliefIcon,
  GetSomeSleepIcon,
  HungryIcon,
  HybridIcon,
  IndicaIcon,
  RelaxedIcon,
  SativaIcon,
  SpecialIcon,
  StarIcon,
  TincturesIcon,
  VapeIcon,
} from '@jane/reefer';

export const productSortOptions = [
  {
    label: 'A-Z',
    value: 'a-z',
  },
  {
    label: 'Price Low to High',
    value: 'price-low-to-high',
    aliases: ['price-ounce-low-to-high', 'price-half-ounce-low-to-high'],
  },
  {
    label: 'Price High to Low',
    value: 'price-high-to-low',
  },
  {
    label: 'THC% Low to High',
    value: 'thc-low-to-high',
  },
  {
    label: 'THC% High to Low',
    value: 'thc-high-to-low',
  },
  {
    label: 'Rating',
    value: 'rating',
  },
];

export const emptyProductFilter = [
  {
    key: 'Empty Categories',
    label: 'Empty Categories',
    nested: false,
    showInFilterBar: true,
    type: 'multiselect' as const,
    items: [],
  },
];

export const productFilters = [
  {
    key: 'Categories',
    label: 'Categories',
    nested: true,
    showInFilterBar: true,
    type: 'multiselect' as const,
    items: [
      {
        count: 30,
        icon: <FlowerIcon color="default" />,
        label: 'flower',
        subItems: [
          {
            count: 10,
            label: 'ground flower',
            value: 'ground flower',
          },
          {
            count: 10,
            label: 'indoor',
            value: 'indoor',
          },
          {
            count: 10,
            label: 'sun grown',
            value: 'sun grown',
          },
        ],
        value: 'flower',
      },
      {
        count: 20,
        icon: <VapeIcon color="default" />,
        label: 'vape',
        subItems: [
          {
            count: 10,
            label: 'cartridges',
            value: 'cartridges',
          },
          {
            count: 10,
            label: 'disposables',
            value: 'disposables',
          },
        ],
        value: 'vape',
      },
      {
        count: 10,
        icon: <EdiblesIcon color="default" />,
        label: 'edibles',
        subItems: [
          {
            count: 5,
            label: 'drinks',
            value: 'drinks',
          },
          {
            count: 5,
            label: 'candies',
            value: 'candies',
          },
        ],
        value: 'edibles',
      },
      {
        count: 10,
        icon: <TincturesIcon color="default" />,
        label: 'Tinctures',
        value: 'tinctures',
      },
    ],
  },
  {
    items: [
      {
        count: 10,
        label: 'My Happy Brand',
        value: 'My Happy Brand',
      },
      {
        count: 10,
        label: 'mew-mew',
        value: 'mew-mew',
      },
      {
        count: 10,
        label: 'mew-mew ii',
        value: 'mew-mew ii',
      },
      {
        count: 10,
        label: 'mew-mew iii',
        value: 'mew-mew iii',
      },
      {
        count: 10,
        label: 'Kiva Confections',
        value: 'Kiva Confections',
      },
      {
        count: 10,
        label: 'Kiva Confections ii',
        value: 'Kiva Confections ii',
      },
      {
        count: 10,
        label: 'Kiva Confections iii',
        value: 'Kiva Confections iii',
      },
      {
        count: 10,
        label: 'Cheeba Chews',
        value: 'Cheeba Chews',
      },
      {
        count: 10,
        label: 'Cheeba Chews ii',
        value: 'Cheeba Chews ii',
      },
      {
        count: 10,
        label: 'Cheeba Chews iii',
        value: 'Cheeba Chews iii',
      },
      {
        count: 10,
        label: "Jane's Brew",
        value: "Jane's Brew",
      },
      {
        count: 1000,
        label: 'zany 🤪',
        value: 'so zany',
      },
    ],
    key: 'brands',
    label: 'Brands',
    showInFilterBar: true,
    type: 'multiselect' as const,
    typeahead: true,
  },
  {
    key: 'lineage',
    label: 'Lineage',
    showInFilterBar: true,
    type: 'multiselect' as const,
    items: [
      {
        count: 10,
        icon: <IndicaIcon color="grape" />,
        label: 'indica',
        value: 'indica',
      },
      {
        count: 10,
        icon: <SativaIcon color="ember" />,
        label: 'Sativa',
        value: 'Sativa',
      },
      {
        count: 10,
        icon: <HybridIcon color="grays-black" />,
        label: 'Hybrid',
        value: 'Hybrid',
      },
      {
        count: 10,
        icon: <CbdIcon color="grays-mid" />,
        label: 'Cbd',
        value: 'Cbd',
      },
    ],
  },
  {
    key: 'feelings',
    label: 'Feelings',
    showInFilterBar: true,
    type: 'multiselect' as const,
    items: [
      {
        count: 10,
        icon: <EnergeticIcon />,
        label: 'Energetic',
        value: 'Energetic',
      },
      {
        count: 10,
        icon: <RelaxedIcon />,
        label: 'Relaxed',
        value: 'Relaxed',
      },
      {
        count: 10,
        icon: <BlissfulIcon />,
        label: 'Blissful',
        value: 'Blissful',
      },
      {
        count: 10,
        icon: <HungryIcon />,
        label: 'Hungry',
        value: 'Hungry',
      },
      {
        count: 10,
        icon: <CreativeIcon />,
        label: 'Creative',
        value: 'Creative',
      },
    ],
  },
  {
    key: 'Activities',
    label: 'Activities',
    showInFilterBar: false,
    type: 'multiselect' as const,
    items: [
      {
        count: 5,
        icon: <EaseMyMindIcon />,
        label: 'Ease my mind',
        value: 'ease my mind',
      },
      {
        count: 5,
        icon: <GetReliefIcon />,
        label: 'Get relief',
        value: 'Get relief',
      },
      {
        count: 5,
        icon: <GetSomeSleepIcon />,
        label: 'Get some sleep',
        value: 'Get some sleep',
      },
    ],
  },
  {
    key: 'specials',
    label: 'Specials',
    showInFilterBar: true,
    type: 'multiselect' as const,
    items: [
      {
        count: 5,
        icon: <SpecialIcon />,
        label: 'Special 1',
        value: 'Special 1',
      },
      {
        count: 5,
        icon: <SpecialIcon />,
        label: 'Special 2',
        value: 'Special 2',
      },
      {
        count: 5,
        icon: <SpecialIcon />,
        label:
          'Special 3 with a really long name let the ppl know what we have going on today',
        value: 'Special 3',
      },
    ],
  },
  {
    key: 'Rating',
    label: 'Rating',
    modalItemsPerRow: 4,
    showInFilterBar: false,
    type: 'multiselect' as const,
    items: [
      {
        count: 5,
        icon: <StarIcon size="sm" />,
        label: '1+',
        value: '>=1',
      },
      {
        count: 5,
        icon: (
          <>
            <StarIcon size="sm" />
            <StarIcon size="sm" ml={4} />
          </>
        ),
        label: '2+',
        value: '>=2',
      },
      {
        count: 5,
        icon: (
          <>
            <StarIcon size="sm" />
            <StarIcon size="sm" ml={4} />
            <StarIcon size="sm" ml={4} />
          </>
        ),
        label: '3+',
        value: '>=3',
      },
      {
        count: 5,
        icon: (
          <>
            <StarIcon size="sm" />
            <StarIcon size="sm" ml={4} />
            <StarIcon size="sm" ml={4} />
            <StarIcon size="sm" ml={4} />
          </>
        ),
        label: '4+',
        value: '>=4',
      },
    ],
  },
  {
    items: [
      {
        label: 'CBD Potency',
        max: 15,
        min: 1,
        value: 'percent_cbd',
      },
      {
        label: 'THC Potency',
        max: 30,
        min: 5,
        value: 'percent_thc',
      },
    ],
    key: 'potencies',
    label: 'Potencies',
    nested: true,
    showInFilterBar: false,
    type: 'range' as const,
  },
  {
    key: 'price',
    label: 'Price',
    modalItemsPerRow: 5,
    showInFilterBar: false,
    type: 'multiselect' as const,
    items: [
      {
        label: 'Under 20$',
        value: '',
        min: 0,
        max: 19.99,
      },
      {
        label: '20$-40$',
        value: '',
        min: 20,
        max: 39.99,
      },
      {
        label: '40$-60$',
        value: '',
        min: 40,
        max: 59.99,
      },
      {
        label: '60$-80$',
        value: '',
        min: 60,
        max: 79.99,
      },
      {
        label: '80$ and above',
        value: '',
        min: 80,
        max: Infinity,
      },
    ],
  },
  {
    key: 'weight',
    label: 'Weights',
    modalItemsPerRow: 6,
    showInFilterBar: false,
    type: 'multiselect' as const,
    items: [
      {
        label: '1/2g',
        value: '0.5',
        count: 5,
      },
      {
        label: '1g',
        value: '1',
        count: 5,
      },
      {
        label: '3.5g',
        value: '3.5',
        count: 5,
      },
      {
        label: '7g',
        value: '7',
        count: 5,
      },
      {
        label: '14g',
        value: '14',
        count: 5,
      },
      {
        label: '28g',
        value: '28',
        count: 5,
      },
    ],
  },
];

export const typeaheadFilters = [
  {
    items: [
      {
        count: 10,
        label: 'My Happy Brand',
        value: 'My Happy Brand',
      },
      {
        count: 10,
        label: 'mew-mew',
        value: 'mew-mew',
      },
      {
        count: 10,
        label: 'mew-mew ii',
        value: 'mew-mew ii',
      },
      {
        count: 10,
        label: 'mew-mew iii',
        value: 'mew-mew iii',
      },
      {
        count: 10,
        label: 'Kiva Confections',
        value: 'Kiva Confections',
      },
      {
        count: 10,
        label: 'Kiva Confections ii',
        value: 'Kiva Confections ii',
      },
      {
        count: 10,
        label: 'Kiva Confections iii',
        value: 'Kiva Confections iii',
      },
      {
        count: 10,
        label: 'Cheeba Chews',
        value: 'Cheeba Chews',
      },
      {
        count: 10,
        label: 'Cheeba Chews ii',
        value: 'Cheeba Chews ii',
      },
      {
        count: 10,
        label: 'Cheeba Chews iii',
        value: 'Cheeba Chews iii',
      },
      {
        count: 10,
        label: "Jane's Brew",
        value: "Jane's Brew",
      },
      {
        count: 1000,
        label: 'zany 🤪',
        value: 'so zany',
      },
    ],
    key: 'brands',
    label: 'Brands',
    showInFilterBar: true,
    type: 'multiselect' as const,
    typeahead: true,
  },
  {
    items: [
      {
        count: 10,
        label: 'My Happy Brand',
        value: 'My Happy Brand',
      },
      {
        count: 10,
        label: 'mew-mew',
        value: 'mew-mew',
      },
      {
        count: 10,
        label: 'mew-mew ii',
        value: 'mew-mew ii',
      },
      {
        count: 10,
        label: 'mew-mew iii',
        value: 'mew-mew iii',
      },
      {
        count: 10,
        label: 'Kiva Confections',
        value: 'Kiva Confections',
      },
      {
        count: 10,
        label: 'Kiva Confections ii',
        value: 'Kiva Confections ii',
      },
      {
        count: 10,
        label: 'Kiva Confections iii',
        value: 'Kiva Confections iii',
      },
      {
        count: 10,
        label: 'Cheeba Chews',
        value: 'Cheeba Chews',
      },
      {
        count: 10,
        label: 'Cheeba Chews ii',
        value: 'Cheeba Chews ii',
      },
      {
        count: 10,
        label: 'Cheeba Chews iii',
        value: 'Cheeba Chews iii',
      },
      {
        count: 10,
        label: "Jane's Brew",
        value: "Jane's Brew",
      },
      {
        count: 1000,
        label: 'zany 🤪',
        value: 'so zany',
      },
    ],
    key: 'Products',
    label: 'Products',
    showInFilterBar: true,
    type: 'multiselect' as const,
    typeahead: true,
  },
];
