import type { ReactNode } from 'react';
import React from 'react';

import type { BrandPartner } from '@jane/shared/models';

export const BrandPartnerContext = React.createContext<BrandPartner>(
  undefined as any
);

interface Props {
  children: ReactNode;
  value: BrandPartner;
}

export const BrandPartnerProvider = ({ children, value }: Props) => (
  <BrandPartnerContext.Provider value={value}>
    {children}
  </BrandPartnerContext.Provider>
);

export const BrandPartnerConsumer = BrandPartnerContext.Consumer;
