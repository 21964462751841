import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Pin = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 15.0048C8 15.0048 14 12.0957 14 6.823C14 3.61028 11.3131 1.00482 8 1.00482C4.68687 1.00482 2 3.61028 2 6.823C2 12.0957 8 15.0048 8 15.0048ZM8 9.00482C9.10457 9.00482 10 8.10939 10 7.00482C10 5.90025 9.10457 5.00482 8 5.00482C6.89543 5.00482 6 5.90025 6 7.00482C6 8.10939 6.89543 9.00482 8 9.00482Z"
      fill="#0E0E0E"
    />
  </svg>
);
export const PinIcon = ({ ...props }: DynamicIconProps) => {
  return <Icon icon={Pin} {...props} color={props.color} />;
};
