import { useEffect, useRef } from 'react';

interface Props {
  [key: string]: any;
}

function logPropDifferences(newProps: Props, lastProps: Props) {
  const allKeys = new Set(Object.keys(newProps));
  Object.keys(lastProps).forEach((k) => allKeys.add(k));

  allKeys.forEach((key) => {
    const newValue = newProps[key];
    const lastValue = lastProps[key];
    if (newValue !== lastValue) {
      console.log('New Value: ', newValue);
      console.log('Last Value: ', lastValue);
    }
  });
}

export const useDebugPropChanges = (newProps: Props) => {
  const lastProps = useRef<Props>({});
  // Should only run when the component re-mounts
  useEffect(() => {
    console.log('Mounted');
  }, []);
  if (lastProps.current) {
    logPropDifferences(newProps, lastProps.current);
  }
  lastProps.current = newProps;
};
