import type { FunctionComponent } from 'react';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { closeModal } from '../../common/redux/application';
import { useCustomerDispatch } from '../../customer/dispatch';
import { logOut } from '../../customer/redux/customer';
import {
  appModeSelector,
  hasUserSelector,
  isEmbeddedModeSelector,
  useCustomerSelector,
} from '../../customer/selectors';
import { paths } from '../../lib/routes';
import LogoutForm from './form';
import LogoutModal from './modal';

export const useLogout = () => {
  const navigate = useNavigate();
  const hasUser = useCustomerSelector((state) =>
    hasUserSelector(state.customer)
  );

  const embeddedMode = useCustomerSelector(isEmbeddedModeSelector);
  const dispatch = useCustomerDispatch();

  const logout = useCallback(() => {
    dispatch(logOut());
  }, []);

  useEffect(() => {
    if (hasUser) {
      return;
    }

    if (embeddedMode) {
      return navigate(paths.embeddedLogin(), { replace: true });
    }

    dispatch(closeModal());
    navigate(paths.root(), { replace: true });
  }, [hasUser, embeddedMode]);

  return logout;
};

const Logout: FunctionComponent = () => {
  const navigate = useNavigate();
  const logout = useLogout();

  const appMode = useCustomerSelector(appModeSelector);
  const embeddedMode = useCustomerSelector(isEmbeddedModeSelector);

  if (embeddedMode) {
    return (
      <div className="former-modal__container">
        <LogoutForm
          onCancel={() =>
            navigate(
              appMode === 'brandEmbed' ? paths.profile() : paths.embeddedMenu()
            )
          }
          onLogout={logout}
          embeddedMode={embeddedMode}
        />
      </div>
    );
  }

  return <LogoutModal onLogout={logout} />;
};

export default Logout;
