import {
  BLACK,
  DARK,
  EMBER,
  EMERALD,
  GOLD,
  GRAPE,
  HOVER,
  LIGHT,
  MID,
  NEGATIVE,
  PACIFIC,
  PALM,
  POSITIVE,
  PURPLE,
  SEAFOAM,
  SUNSET,
  TANGERINE,
  ULTRALIGHT,
  WHITE,
  effects,
} from '../styling';

export const colors = {
  brand: {
    gold: {
      light: effects.lighten(GOLD, 0.8),
      main: GOLD,
      dark: effects.darken(GOLD, 0.2),
    },
    tangerine: {
      light: effects.lighten(TANGERINE, 0.8),
      main: TANGERINE,
      dark: effects.darken(TANGERINE, 0.2),
    },
    ember: {
      light: effects.lighten(EMBER, 0.8),
      main: EMBER,
      dark: effects.darken(EMBER, 0.2),
    },
    sunset: {
      light: effects.lighten(SUNSET, 0.8),
      main: SUNSET,
      dark: effects.darken(SUNSET, 0.2),
    },
    grape: {
      light: effects.lighten(GRAPE, 0.8),
      main: GRAPE,
      dark: effects.darken(GRAPE, 0.2),
    },
    purple: {
      light: effects.lighten(PURPLE, 0.8),
      main: PURPLE,
      dark: effects.darken(PURPLE, 0.2),
    },
  },
  tertiary: {
    emerald: {
      light: effects.lighten(EMERALD, 0.8),
      main: EMERALD,
      dark: effects.darken(EMERALD, 0.2),
    },
    palm: {
      light: effects.lighten(PALM, 0.8),
      main: PALM,
      dark: effects.darken(PALM, 0.2),
    },
    seafoam: {
      light: effects.lighten(SEAFOAM, 0.8),
      main: SEAFOAM,
      dark: effects.darken(SEAFOAM, 0.2),
    },
    pacific: {
      light: effects.lighten(PACIFIC, 0.8),
      main: PACIFIC,
      dark: effects.darken(PACIFIC, 0.2),
    },
  },
  system: {
    positive: {
      light: effects.lighten(POSITIVE, 0.8),
      main: POSITIVE,
      dark: effects.darken(POSITIVE, 0.2),
    },
    negative: {
      light: effects.lighten(NEGATIVE, 0.8),
      main: NEGATIVE,
      dark: effects.darken(NEGATIVE, 0.2),
    },
  },
  grays: {
    white: WHITE,
    ultralight: ULTRALIGHT,
    light: LIGHT,
    mid: MID,
    dark: DARK,
    black: BLACK,
    hover: HOVER,
  },
};
