import styled from '@emotion/styled';

import { spacing } from '../../styling';

interface StyledSearchListProps {
  width?: string;
}

export const StyledSearchField = styled.div({
  ...spacing({ pr: 16, pl: 16 }),
});

export const StyledSearchList = styled.ul<StyledSearchListProps>(
  ({ width }) => ({
    maxHeight: '420px',
    overflow: 'auto',
    ...(width && { width }),
    '> li:last-of-type > button': {
      ...spacing({ pb: 20 }),
    },
  })
);

export const StyledSearchListItem = styled.li<StyledSearchListProps>(
  ({ theme, width }) => ({
    listStyle: 'none',
    transition: 'all 250ms',
    mark: {
      color: theme.colors.primary.main,
      backgroundColor: 'transparent',
    },
    ...(width && { width }),
  })
);

export const StyledListButton = styled.button({
  outline: 'none',
  borderWidth: 0,
  textDecoration: 'none',
  backgroundColor: 'transparent',
  width: '100%',
  height: '100%',
  textAlign: 'left',
  ...spacing({ py: 12, px: 24, m: 0 }),
  display: 'block',
  cursor: 'pointer',
  color: 'inherit',
  '&:hover, &:focus, &:active': {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
});

export const StyledListButtonLabels = styled.span({
  display: 'flex',
  justifyContent: 'space-between',
  pointerEvents: 'none',
});

export const StyledListCheckbox = styled.div({
  '> div > div': {
    ...spacing({ py: 12, px: 24, m: 0 }),
    '&:hover, &:focus, &:active': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
  },
  label: {
    width: '100%',
    cursor: 'pointer',
    color: 'inherit',
  },
  cursor: 'pointer',
});

export const StyledLoadingWrapper = styled.div({
  position: 'relative',
  height: '24px',
  ...spacing({ mt: 12, mb: 16 }),
});
