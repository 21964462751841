import { useState } from 'react';

import { Box } from '@jane/reefer';
import type { AlgoliaProduct, JaneSearchState } from '@jane/search/types';

import { ActiveFilterProvider } from '../activeFilterProvider';
import { FilterBarGroup } from '../filterBarGroup';
import { FilterModal } from '../filterModal';
import { FilterSearch } from '../filterSearch';
import { FilterSort } from '../filterSort';
import { ActiveFilters } from './activeFilters';
import {
  StyledFilterWrapper,
  StyledProductFilterBar,
  StyledSortWrapper,
} from './productFilterBar.styles';
import type { ProductFilterBarProps } from './productFilterBar.types';
import { ProductFilterRadius } from './productFilterRadius';
import { ProductFilterView } from './productFilterView';

export const ProductFilterBar = ({
  activeFilters,
  currentSort,
  filters,
  isPartnerMenu = false,
  listView,
  maxRadius,
  modalSearchState,
  onChange,
  onDeselect,
  onQuery,
  onRadius,
  onSort,
  onView,
  radius,
  setModalSearchState,
  sortOptions,
  searchId,
  totalResults,
  variant = 'product',
}: ProductFilterBarProps) => {
  const [open, setOpen] = useState(false);

  const renderedBarFilters = filters?.map((filterGroup) => {
    return (
      <FilterBarGroup
        key={filterGroup.key}
        filterGroup={filterGroup}
        onChange={onChange}
        onDeselect={onDeselect}
        totalResults={totalResults}
        variant={variant}
      />
    );
  });

  return (
    <ActiveFilterProvider activeFilters={activeFilters}>
      <Box>
        <StyledProductFilterBar>
          <StyledFilterWrapper>
            <FilterSearch onSearch={onQuery} searchId={searchId} />

            <FilterModal<JaneSearchState<AlgoliaProduct>>
              filters={filters}
              onChange={onChange}
              onDeselect={onDeselect}
              isPartnerMenu={isPartnerMenu}
              searchState={modalSearchState}
              open={open}
              setOpen={setOpen}
              setSearchState={setModalSearchState}
              totalResults={totalResults}
              variant={variant}
            />

            {renderedBarFilters}
          </StyledFilterWrapper>

          <StyledSortWrapper>
            <FilterSort
              currentSort={currentSort}
              onSort={onSort}
              sortOptions={sortOptions}
              variant="product"
            />
            {onView && (
              <ProductFilterView listView={listView} onView={onView} />
            )}
            {onRadius && (
              <ProductFilterRadius
                maxRadius={maxRadius}
                onRadius={onRadius}
                radius={radius}
              />
            )}
          </StyledSortWrapper>
        </StyledProductFilterBar>
        <ActiveFilters
          activeFilters={activeFilters}
          onChange={onChange}
          onDeselect={onDeselect}
        />
      </Box>
    </ActiveFilterProvider>
  );
};
