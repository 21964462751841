import type { MobileFilterTab, ShareNetwork } from '@jane/shared-ecomm/types';
import { config } from '@jane/shared/config';
import type { AppMode, Id, MenuProduct, Product } from '@jane/shared/models';

import { EventNames } from './eventNames';
import type {
  CardType,
  ClickedBrandLink,
  LoadedLoyaltyPoints,
  NavBarTap,
  ProductReview,
  ProductReviewInteractionTypes,
  SSOChannel,
  StoreInfoAttribute,
} from './eventNames';
import { track } from './track';

export const trackCartClick = () => track({ event: EventNames.ClickedCart });

export const trackTimeSpentOnMenu = ({
  storeId,
  seconds,
}: {
  seconds: number;
  storeId: Id;
}) => track({ event: EventNames.TimedMenuLoad, storeId, seconds });

export const trackStoresPageLoad = ({ map }: { map: boolean }) =>
  track({ event: EventNames.LoadedStoresPage, map });

export const trackHomePageLoad = () =>
  track({ event: EventNames.LoadedHomePage });

export const trackClickedSegmentedMobileFilterTab = (
  filterTab: MobileFilterTab
) => track({ event: EventNames.ClickedSegmentedMobileFilterTab, filterTab });

export const trackClickedSegmentedMobileFilterToggle = (
  filterTab: MobileFilterTab
) => track({ event: EventNames.ClickedSegmentedMobileFilterToggle, filterTab });

export const trackClickedBrandLink = ({
  appMode,
  brandName,
  page,
  storeId,
}: Omit<ClickedBrandLink, 'event'>) =>
  track({
    event: EventNames.ClickedBrandLink,
    appMode,
    brandName,
    page,
    storeId,
  });

export const trackClickedStoresNearYou = () =>
  track({ event: EventNames.ClickedStoreNearYou });

export const trackClickedHomePageProductCard = (cardType: CardType) =>
  track({ event: EventNames.ClickedHomePageProductCard, cardType });

export const trackOpenedAccordionItem = (itemName: string) =>
  track({ event: EventNames.OpenedAccordionItem, itemName });

export const trackCheckoutClick = () =>
  track({ event: EventNames.ClickedCheckout });

export const trackLoadedCartPage = (isEmpty: boolean) =>
  track({ event: EventNames.LoadedCartPage, isEmpty });

export const trackLoadedCheckoutPage = () =>
  track({ event: EventNames.LoadedCheckoutPage });

export const trackLoadedGuestCheckoutPage = () =>
  track({ event: EventNames.LoadedGuestCheckoutPage });

export const trackTimedKioskInactivity = () =>
  track({ event: EventNames.TimedKioskInactivity });

export const trackTimedKioskRefreshToLanding = () =>
  track({ event: EventNames.TimedKioskRefreshToLanding });

export const trackStartedKioskOrder = () =>
  track({ event: EventNames.StartedKioskOrder });

export const trackInteractedWithProductReview = ({
  product,
  interactionType,
  interactionValue,
  aggregateRating,
  reviewCount,
}: {
  aggregateRating?: number | null;
  interactionType: ProductReviewInteractionTypes;
  interactionValue: any;
  product: Product;
  reviewCount?: number | null;
}) =>
  track({
    event: EventNames.InteractedWithProductReviews,
    interactionType,
    interactionValue,
    ...coreProductProperties(product),
    aggregateRating,
    reviewCount,
  });

export const trackLoadedCrmPoints = ({
  storeHasProvider,
  memberPoints,
  crmProvider,
  crmRewards,
}: Omit<LoadedLoyaltyPoints, 'event'>) =>
  track({
    event: EventNames.LoadedLoyaltyPoints,
    storeHasProvider,
    memberPoints,
    crmProvider,
    crmRewards,
  });

export const trackReviewedProduct = (
  product: Product,
  review: ProductReview,
  activities: string[],
  feelings: string[]
) =>
  track({
    event: EventNames.ReviewedProduct,
    activities,
    review,
    feelings,
    ...coreProductProperties(product),
  });

export const trackLoadedProductReviewForm = () =>
  track({ event: EventNames.LoadedWriteProductReviewForm });

export const trackContinueShoppingClick = () =>
  track({ event: EventNames.ClickedContinueShopping });

export const trackStoreInfoClick = (attribute: StoreInfoAttribute) =>
  track({ event: EventNames.ClickedStoreInfo, attribute });

export const trackExperimentView = (
  experimentName: string,
  variantName: string
) => track({ event: EventNames.ViewedExperiment, experimentName, variantName });

export const trackSharedProduct = ({
  productId,
  storeId,
  via,
}: {
  productId: string;
  storeId?: string;
  via: ShareNetwork;
}) => track({ event: EventNames.SharedProduct, productId, storeId, via });

export const coreProductProperties = (product: Product | MenuProduct) => ({
  productId: product.id.toString(),
  productName: product.name,
  productBrand: product.brand || undefined,
  productKind: product.kind,
  productRootSubtype: product.root_subtype || undefined,
  productBrandSubtype: product.brand_subtype || undefined,
  productLineage: product.category || undefined,
  productPercentCBD: product.percent_cbd || undefined,
  productPercentTHC: product.percent_thc || undefined,
  productAggregateRating: product.aggregate_rating || undefined,
  productReviewCount: product.review_count || undefined,
});

export const trackCustomerLogin = (ssoChannel?: SSOChannel) => {
  track({ event: EventNames.LoggedIn, ssoChannel });
};

interface RegistrationProps {
  customer: { id: number; ssoChannel?: SSOChannel };
  partnerChannel: AppMode;
  partnerId?: Id;
  partnerName?: string;
}

export const trackCustomerRegistration = ({
  customer,
  partnerChannel,
  partnerId,
  partnerName,
}: RegistrationProps) => {
  mixpanel.alias(customer.id.toString());
  track({
    event: EventNames.Registered,
    ssoChannel: customer.ssoChannel,
    partnerChannel,
    partnerId,
    partnerName,
  });
};

interface StoreCommunicationBannerProps {
  bannerEnabled: boolean;
  bannerMessage: string | null;
  isExpanded: boolean;
  storeId: Id;
}

export const trackStoreCommunicationBannerLoad = ({
  storeId,
  bannerEnabled,
  bannerMessage,
  isExpanded,
}: StoreCommunicationBannerProps) => {
  const htmlRegex = /<[^>]*>/g;
  const standardizedMessage = bannerMessage?.replace(htmlRegex, '') || '';

  track({
    event: EventNames.StoreCommunicationBannerLoaded,
    storeId,
    bannerEnabled,
    bannerMessageCharacterCount: bannerEnabled ? standardizedMessage.length : 0,
    isExpanded,
  });
};

interface BrandPageProps {
  brandId: Id;
  brandName: string;
  productReferrerId?: Id;
}

export const trackBrandPageLoad = ({
  brandId,
  brandName,
  productReferrerId,
}: BrandPageProps) => {
  track({
    event: EventNames.BrandPageLoaded,
    brandId,
    brandName,
    productReferrerId,
  });
};

export const trackBrandPageOpenVideo = (brandId: Id) => {
  track({
    event: EventNames.BrandPageOpenedVideo,
    brandId,
  });
};

interface ResetKioskSessionProps {
  appStoreId: number | string;
  janeDeviceId: string | undefined;
}

export const resetKioskSession = ({
  janeDeviceId,
  appStoreId,
}: ResetKioskSessionProps) => {
  mixpanel.reset();

  mixpanel.register({
    app: 'kiosk',
    janeDeviceId,
    appStoreId: appStoreId || null,
    build: config.buildVersion.substring(0, 7),
  });
};

export interface HomeTapType {
  cityState?: string;
  section: string;
  storeFulfillmentType?: string;
  storeSearchRadius?: number;
  storeType?: string;
  tile?: string;
  zipcode?: string;
}

export const trackHomeTap = ({
  cityState,
  section,
  storeFulfillmentType,
  storeSearchRadius,
  storeType,
  tile,
  zipcode,
}: HomeTapType) => {
  track({
    cityState,
    event: EventNames.HomeTap,
    section,
    storeFulfillmentType,
    storeSearchRadius,
    storeType,
    tile,
    zipcode,
  });
};

export type NavBarTapType = Omit<NavBarTap, 'event'>;

export const trackNavBarTap = ({
  cityState,
  navBarTap,
  source,
  storeFulfillmentType,
  storeSearchRadius,
  storeType,
  zipcode,
}: NavBarTapType) => {
  track({
    cityState,
    navBarTap,
    event: EventNames.NavBarTap,
    source,
    storeFulfillmentType,
    storeSearchRadius,
    storeType,
    zipcode,
  });
};
