import styled from '@emotion/styled';

import { mediaQueries } from '../../../styling';
import type { GridSizes } from '../grid.types';
import { getSpacing } from '../gridContainer/gridContainer.styles';

const getSizing = (size: GridSizes | undefined, direction: 'row' | 'col') => {
  if (!size) return;

  let styles;

  if (size === 'fill') {
    styles = {
      flexBasis: 'auto',
      flexGrow: 1,
      maxWidth: 'none',
    };
  } else {
    const itemSize = `${100 * (size / 12)}%`;
    styles = {
      flexBasis: itemSize,
      flexGrow: 0,
      maxWidth: direction === 'row' ? itemSize : 'none',
      maxHeight: direction === 'col' ? itemSize : 'none',
    };
  }

  return styles;
};

const createGridItem = (props: StyledGridItemContainerProps) => {
  const spacing = getSpacing(props.spacing);
  const style = {
    paddingLeft: spacing,
    paddingBottom: spacing,
    ...getSizing(props.xs, props.direction),
    [mediaQueries.mobile('md', 'min')]: {
      ...getSizing(props.sm, props.direction),
    },
    [mediaQueries.tablet('min')]: {
      ...getSizing(props.md, props.direction),
    },
    [mediaQueries.desktop('sm', 'min')]: {
      ...getSizing(props.lg, props.direction),
    },
    [mediaQueries.desktop('md', 'min')]: {
      ...getSizing(props.xl, props.direction),
    },
  };

  return style;
};

interface StyledGridItemContainerProps {
  direction: 'row' | 'col';
  lg?: GridSizes;
  md?: GridSizes;
  sm?: GridSizes;
  spacing: number | string;
  xl?: GridSizes;
  xs: GridSizes;
}

export const StyledGridItemContainer = styled.div<StyledGridItemContainerProps>(
  {
    display: 'flex',
    background: 'transparent',
  },
  ({ theme, ...props }) => ({
    ...createGridItem(props),
  })
);

export const StyledGridItem = styled.div({
  width: '100%',
  height: '100%',
});
