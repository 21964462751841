import { useState } from 'react';
import Dropzone from 'react-dropzone';

import type { ReeferTheme } from '@jane/reefer';
import { Typography, styled, styling } from '@jane/reefer';
import { ImagePreview } from '@jane/shared/util';

import { commonConnect } from '../../common/redux';
import { openModal } from '../../common/redux/application';
import { hexToRgba } from '../../component-library/styles';
import { ALPHA_MAP } from '../../component-library/styles/colors';
import { SvgIcon } from '../../component-library/svg-icon';
import { border, flex, spacing } from '../../style';

const ACCEPTED_FILE_TYPES = ['image/jpeg', 'image/jpg', 'image/png'];

interface StyledUploaderProps {
  imageValue: string;
  label: string;
  showErrors: boolean;
  showValidationErrors: boolean;
  theme: ReeferTheme;
  valid: boolean;
}

const StyledUploader = styled.div(
  flex({ alignItems: 'center', justifyContent: 'center' }),
  spacing({ px: 24, py: 12 }),
  {
    background: 'transparent',
    cursor: 'pointer',

    transition: 'all .15s ease',
    position: 'relative',
    '::before': {
      display: 'none',
    },
  },
  ({ theme, showErrors, label, imageValue, valid }: StyledUploaderProps) => ({
    width: !label || imageValue ? 100 : 'auto',
    height: !label || imageValue ? 70 : 'auto',
    border: `1px solid ${theme.colors.secondary.main}`,
    ':disabled': {
      background: 'transparent',
      color: theme.colors.grays.light,
      borderColor: theme.colors.grays.light,
      cursor: 'not-allowed',
    },
    ':hover': {
      background: hexToRgba(theme.colors.secondary.main, ALPHA_MAP[200]),
    },
    ':active': {
      background: hexToRgba(theme.colors.secondary.main, ALPHA_MAP[300]),
    },
    ':focus': {
      background: hexToRgba(theme.colors.secondary.main, ALPHA_MAP[300]),
    },
    ...border.thin(
      showErrors && !valid
        ? theme.colors.system.negative.main
        : theme.colors.grays.light
    ),
  })
);

interface Image {
  key: string;
  value: string;
}

type ImageProps = Pick<Image, 'value'>;

const UploaderPreviewLoad = styled.div<ImageProps>(
  ({ theme }) => ({
    backgroundColor: theme.colors.grays.dark,
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',

    '&::before': {
      '&::after': {
        display: 'block',
        position: 'absolute',
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
        width: '100%',
        height: '100%',
        content: '""',
        zIndex: 1,
        backgroundImage: `url(../../../../../../../shared/assets/icons/loading.svg) center center no-repeat`,
      },

      '&::before': {
        backgroundColor: styling.effects.lighten('#ffffff', 0.7),
      },
    },
  }),
  ({ value }) => ({
    ...(value && {
      backgroundImage: `url(${value})`,
    }),
  })
);

const UploaderPreview = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.grays.dark,
  position: 'absolute',
  top: '0',
  right: '0',
  bottom: '0',
  left: '0',
}));

interface UploaderPreviewImageProps {
  rotation: number;
  value: string;
}

const UploaderPreviewImage = styled.div<UploaderPreviewImageProps>(
  {
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
  },
  ({ value }) => ({
    ...(value && {
      backgroundImage: `url(${value})`,
    }),
  }),
  ({ rotation }) => ({
    ...(rotation && {
      transform: `rotate(${rotation}deg)`,
    }),
  })
);

const UploaderRemove = styled.div(({ theme }) => ({
  display: 'block',
  position: 'absolute',
  bottom: '0',
  width: '100%',
  height: '24px',
  backgroundColor: styling.effects.lighten('#000', 0.6),
  color: theme.colors.grays.white,
  fontSize: '12px',
  lineHeight: '24px',
  textAlign: 'center',
}));

interface ImageUploaderProps {
  allowPDFs?: boolean;
  className?: string;
  icon?: string;
  image: Image;
  inputName?: string;
  isLoading?: boolean;
  label?: string;
  onFailure?: () => void;
  onSetImage: (key: string, url: string) => void;
  onSuccess?: () => void;
  onUnsetImage: ((key: string) => void) | (() => void);
  showValidationErrors?: boolean;
  theme?: ReeferTheme;
  valid?: boolean;
}

const ImageUploader = ({
  allowPDFs,
  icon = 'medStorePlus',
  image,
  inputName,
  isLoading,
  label,
  onFailure,
  onSetImage,
  onSuccess,
  onUnsetImage,
  showValidationErrors,
  valid,
  theme,
}: ImageUploaderProps) => {
  const [orientation, setOrientation] = useState(null);

  const acceptedFileTypes = allowPDFs
    ? ACCEPTED_FILE_TYPES.concat(['application/pdf'])
    : ACCEPTED_FILE_TYPES;

  const acceptedFileTypesString = acceptedFileTypes.join(',');

  const setImage = (files, onSuccess, onFailure) => {
    const file = files[0];
    if (!acceptedFileTypes.includes(file.type)) {
      return onFailure && onFailure();
    }
    const { key } = image;

    ImagePreview.parse(file).then((result) => {
      onSetImage(key, result.url);
      setOrientation({ orientation: result.orientation });
      return onSuccess && onSuccess();
    });
  };

  const unsetImage = (event) => {
    const { key } = image;

    onUnsetImage(key);
    event.stopPropagation();
  };

  const zoomInImage = (event) => {
    openModal({
      name: 'zoomIn',
      image: image.value,
      orientation,
    });

    event.stopPropagation();
  };

  const renderPreview = () => {
    if (isLoading) {
      return <UploaderPreviewLoad value={image.value} />;
    }

    const { value } = image;
    if (value) {
      const rotation = ImagePreview.rotationForOrientation(orientation);

      return (
        <UploaderPreview
          role="button"
          aria-label="Zoom"
          data-testid="uploader-preview"
          onClick={zoomInImage}
        >
          <UploaderPreviewImage
            data-testid="uploader-preview__image"
            value={value}
            rotation={rotation}
          />
          <UploaderRemove>
            <button
              type="button"
              data-testid="uploader-remove-btn"
              className="uploader-remove-button"
              onClick={unsetImage}
            >
              Remove
            </button>
          </UploaderRemove>
        </UploaderPreview>
      );
    }
    return null;
  };

  const imageValue = image && image.value;

  return (
    <Dropzone
      onDrop={(files) => setImage(files, onSuccess, onFailure)}
      multiple={false}
      accept={acceptedFileTypesString}
    >
      {({ getRootProps, getInputProps }) => (
        <Typography color="secondary" as="div" variant="body-bold">
          <StyledUploader
            valid={valid}
            showValidationErrors={showValidationErrors}
            imageValue={imageValue}
            label={label}
            {...getRootProps()}
          >
            <input
              name={inputName}
              aria-invalid={!valid}
              {...getInputProps()}
            />
            {label ? (
              label
            ) : (
              <SvgIcon
                icon={icon}
                size={24}
                color={theme?.colors.brand.purple.main}
              />
            )}

            {renderPreview()}
          </StyledUploader>
        </Typography>
      )}
    </Dropzone>
  );
};

export default commonConnect(undefined, { openModal })(ImageUploader);
