import { Box, Button, CheckmarkIcon, DismissIcon, Form } from '@jane/reefer';

import {
  StyledTextField,
  StyledValidationMessage,
  SubmittableTextContainer,
} from './promoCodes.styles';

interface Props {
  hasValidationError: boolean;
  name: string;
  onReset: () => void;
  onSubmit: (value: string) => void;
  placeholder: string;
  submitLabel: string;
  validated: boolean;
  validationMessage?: string;
}

export const SubmittableTextInput = ({
  onReset,
  onSubmit,
  placeholder,
  submitLabel,
  validated,
  hasValidationError,
  validationMessage,
  name,
}: Props) => {
  const handleSubmit = (values) => {
    onSubmit(values[name]);
  };

  return (
    <Form name="promocode-form" onSubmit={handleSubmit}>
      <SubmittableTextContainer alignItems="center" justifyContent="stretch">
        {!validated && (
          <StyledTextField
            disableMobileInputStyling
            labelHidden
            label=""
            name={name}
            aria-label={name}
            placeholder={placeholder}
          />
        )}
        {validated && (
          <StyledValidationMessage
            color={hasValidationError ? 'error' : 'success'}
          >
            {validationMessage}
          </StyledValidationMessage>
        )}
        <Box width="100px" ml={24}>
          {!validated && <Form.SubmitButton label={submitLabel} full />}
          {validated && hasValidationError && (
            <Button.Icon
              icon={<DismissIcon />}
              onClick={onReset}
              type="button"
              variant="destructive"
              full
            />
          )}
          {validated && !hasValidationError && (
            <Box px={12}>
              <CheckmarkIcon color="success" />
            </Box>
          )}
        </Box>
      </SubmittableTextContainer>
    </Form>
  );
};
