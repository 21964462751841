import type { ReactNode } from 'react';
import React from 'react';

import type { Operator } from '@jane/shared/models';

export const OperatorContext = React.createContext<Operator>(undefined as any);

interface Props {
  children: ReactNode;
  value: Operator;
}

export const OperatorProvider = ({ children, value }: Props) => (
  <OperatorContext.Provider value={value}>{children}</OperatorContext.Provider>
);

export const OperatorConsumer = OperatorContext.Consumer;
