import { Flex, mediaQueries, spacing, styled } from '@jane/reefer';

import {
  DESKTOP_PADDING_HORIZONTAL,
  MOBILE_PADDING_HORIZONTAL,
  PADDING_VERTICAL,
} from '../pendingCartDrawer.styles';

export const SummaryWrapper = styled(Flex)(({ theme }) => ({
  ...spacing({ px: MOBILE_PADDING_HORIZONTAL, py: PADDING_VERTICAL }),
  [mediaQueries.tablet('min')]: {
    ...spacing({ px: DESKTOP_PADDING_HORIZONTAL }),
  },
  backgroundColor: theme.colors.grays.white,
  borderBottom: `1px solid ${theme.colors.grays.light}`,
}));
