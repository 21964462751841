import {
  fakeButtonProps,
  getAccessibilityProps,
  handleEnterKey,
} from '../../utils';
import { StyledCard } from './card.styles';
import type { CardProps } from './card.types';

/**
 * The `Card` component is a wrapper component used for displaying a styled card with an optional `border` and `box-shadow`.
 * `Card` contents can be included via sub-components `Card.Action`, `Card.Content` and `Card.Image`.
 * */

export function Card({
  ariaLabel,
  ariaLabelledBy,
  border = 'none',
  borderWidth = '1px',
  children,
  flat = false,
  height,
  onClick,
  role,
  testId,
  width,
  ...props
}: CardProps) {
  const a11yProps = getAccessibilityProps(
    { ariaLabel, ariaLabelledBy, role, onClick },
    'Card'
  );

  return (
    <StyledCard
      border={border}
      borderWidth={borderWidth}
      data-testid={testId}
      flat={flat}
      height={height}
      onClick={onClick}
      onKeyUp={(event) => handleEnterKey(event, onClick)}
      {...(onClick && fakeButtonProps)}
      width={width}
      {...a11yProps}
      {...props}
    >
      {children}
    </StyledCard>
  );
}
