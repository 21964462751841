import styled from '@emotion/styled';

import type {
  FlexItemProperties,
  FlexProperties,
  MarginProperties,
  PaddingProperties,
} from '../../styling';
import { flex, flexItem, spacing } from '../../styling';

interface StyledFlexProps
  extends FlexProperties,
    MarginProperties,
    PaddingProperties,
    FlexItemProperties {}

export const StyledFlex = styled.div<StyledFlexProps>(
  flex,
  flexItem,
  spacing,
  ({ onClick, theme }) => ({
    ...(onClick && {
      cursor: 'pointer',
      outline: 'none',
      ':focus-visible': {
        border: `2px solid ${theme.colors.grays.black}`,
        borderRadius: theme.borderRadius.lg,
      },
    }),
  })
);
