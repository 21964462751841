import { useEffect } from 'react';

import { ButtonToggle, Flex, Typography } from '@jane/reefer';
import type { ReservationMode, Store } from '@jane/shared/models';
import { sentenceCase } from '@jane/shared/util';

import { useCustomerDispatch, useCustomerSelector } from '../../../../customer';
import { updateReservationMode } from '../../../../customer/redux/cart';
import type OrderingSchedule from '../../../../lib/orderingSchedule';
import { reservationModeLabel } from '../../../../lib/store';

interface CartDrawerToggleProps {
  schedules: OrderingSchedule;
  store: Store;
}

export const CartDrawerToggle = ({
  schedules,
  store,
}: CartDrawerToggleProps) => {
  const dispatch = useCustomerDispatch();
  const { reservation_mode: currentReservationMode, userGroupSpecialId } =
    useCustomerSelector(
      ({
        cart: {
          userGroupSpecialId,
          cart: { reservation_mode },
        },
      }) => ({
        reservation_mode,
        userGroupSpecialId,
      })
    );

  const handleReservationModeToggle = (reservationMode: ReservationMode) => {
    dispatch(
      updateReservationMode({
        reservationMode,
        userGroupSpecialId,
      })
    );
  };

  const getReservationModeLabel = (reservationMode: ReservationMode) =>
    sentenceCase(reservationModeLabel(store, reservationMode));

  const reservationModes = ['delivery', 'pickup'] as ReservationMode[];
  const availableReservationModes = reservationModes.filter(
    (mode) => schedules[`${mode}Schedule`]
  );

  useEffect(() => {
    const reservationMode = availableReservationModes.includes(
      currentReservationMode
    )
      ? currentReservationMode
      : availableReservationModes[0];

    if (reservationMode !== currentReservationMode) {
      handleReservationModeToggle(reservationMode);
    }
  }, []);

  if (availableReservationModes.length > 1) {
    return (
      <Flex width="100%">
        <ButtonToggle
          variant="tertiary"
          value={currentReservationMode}
          onChange={(orderType: ReservationMode) =>
            handleReservationModeToggle(orderType)
          }
        >
          {availableReservationModes.map((mode) => (
            <ButtonToggle.Button
              label={getReservationModeLabel(mode)}
              key={mode}
              value={mode}
            />
          ))}
        </ButtonToggle>
      </Flex>
    );
  }

  return (
    <Flex width="100%" justifyContent="center">
      <Typography variant="body-bold">
        {getReservationModeLabel(currentReservationMode || reservationModes[0])}
      </Typography>
    </Flex>
  );
};
