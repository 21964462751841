import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import type { Reducer } from 'redux';

import type { Location } from '@jane/shared-ecomm/providers';
import type {
  DeepReadonly,
  Id,
  MedRec,
  ReservationMode,
  Store,
} from '@jane/shared/models';
import { trackError } from '@jane/shared/util';

import type { ShopByMode } from '../../operatorEmbed/components/operatorEmbedGates';
import { OperatorStoresSource } from '../../operatorEmbed/sources/operatorStoresSource';
import { createStandardAction } from '../../redux-util';
import type { CustomerThunkAction } from '../redux';
import { setPartner, verifyStore } from './embeddedApp';
import { GET_STORE, GET_STORE_ERROR } from './store';
import type { CustomerAction } from './types';

export const SET_OPERATOR = 'operatorEmbedded/setOperator';
export const setOperator = createStandardAction(SET_OPERATOR)<{
  id: OperatorEmbeddedState['operatorId'];
  name: OperatorEmbeddedState['operatorName'];
}>();

export const SET_RESERVATION_MODE = 'operatorEmbedded/setReservationMode';
export const setReservationMode = createStandardAction(SET_RESERVATION_MODE)<
  OperatorEmbeddedState['reservationMode'] | null
>();

export const setStore =
  ({
    operator,
    mode,
    userLocation,
    medOrRec,
    productId,
  }: {
    medOrRec: MedRec;
    mode: ShopByMode;
    operator: { id: Id };
    productId: number;
    userLocation: Location;
  }): CustomerThunkAction =>
  (dispatch) => {
    dispatch({ type: GET_STORE });
    const compactedParams = omitBy(
      {
        reservation_mode: mode,
        lat: userLocation?.coordinates?.lat,
        long: userLocation?.coordinates?.long,
        med_rec: medOrRec,
        product_id: productId,
      },
      isNil
    );
    OperatorStoresSource.get(operator.id, compactedParams)
      .then(({ store }: { store: Store }) => {
        if (store) {
          dispatch(
            setPartner({
              id: store.id,
              name: store.name,
            })
          );

          dispatch(
            verifyStore({
              storeId: store.id,
              options: { operatorEmbed: true },
            })
          );
        } else {
          dispatch({ type: GET_STORE_ERROR });
        }
      })
      .catch((e) => {
        trackError(e, {
          params: compactedParams,
        });
      });
  };

export type OperatorEmbeddedActions =
  | ReturnType<typeof setOperator>
  | ReturnType<typeof setReservationMode>;

export type OperatorEmbeddedState = DeepReadonly<{
  operatorId: number | string | undefined;
  operatorName: string | undefined;
  reservationMode: ReservationMode | undefined;
}>;

const getInitialState = (): OperatorEmbeddedState => ({
  operatorId: undefined,
  operatorName: undefined,
  reservationMode: undefined,
});

export const operatorEmbeddedReducer: Reducer<
  OperatorEmbeddedState,
  CustomerAction
> = (state = getInitialState(), action) => {
  switch (action.type) {
    case SET_OPERATOR:
      return {
        ...state,
        operatorId: action.payload.id,
        operatorName: action.payload.name,
      };
    case SET_RESERVATION_MODE:
      return { ...state, reservationMode: action.payload || undefined };
  }

  return state;
};
