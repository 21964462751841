import type { Reducer } from 'redux';

import type { DeepReadonly, Id } from '@jane/shared/models';

import { createStandardAction } from '../../redux-util';
import type { CommunicationBannerMessageSettings } from '../../sources/storeCommunicationBanner';
import { getCurrentStoreCommunicationBanner } from '../../sources/storeCommunicationBanner';
import type { CustomerThunkAction } from '../redux';
import type { CustomerAction } from './types';

export const GET_STORE_COMMUNICATION_BANNER = 'store-communication-banner/get';
export const getStoreCommunicationBanner =
  (storeId: Id): CustomerThunkAction =>
  (dispatch) => {
    getCurrentStoreCommunicationBanner(storeId).then((bannerMessage) => {
      dispatch(setStoreCommunicationBanner(bannerMessage));
    });
  };

export const SET_STORE_COMMUNICATION_BANNER = 'store-communication-banner/set';
export const setStoreCommunicationBanner = createStandardAction(
  SET_STORE_COMMUNICATION_BANNER
)<CommunicationBannerMessageSettings>();

export type StoreCommunicationActions = ReturnType<
  typeof setStoreCommunicationBanner
>;

export type StoreCommunicationBannerState = DeepReadonly<
  CommunicationBannerMessageSettings & { hasLoaded: boolean }
>;

const getInitialState = (): StoreCommunicationBannerState => ({
  created_at: '',
  enabled: false,
  hasLoaded: false,
  id: 0,
  is_expanded: true,
  message: '',
  store_id: 0,
  title: '',
  updated_at: '',
});

export const storeCommunicationBannerReducer: Reducer<
  StoreCommunicationBannerState,
  CustomerAction
> = (state = getInitialState(), action) => {
  switch (action.type) {
    case SET_STORE_COMMUNICATION_BANNER:
      return { ...state, ...(action.payload || {}), hasLoaded: true };
  }

  return state;
};
