import { useTheme } from '@emotion/react';
import find from 'lodash/find';
import has from 'lodash/has';
import type { PropsWithChildren } from 'react';

import type { MarginProperties } from '../../styling';
import type {
  ComponentColors,
  TypographyComponent,
  TypographyVariant,
} from '../../types';
import { baseTypographyVariants } from '../../types';
import { ObjectKeys, ensure } from '../../utils';
import { StyledTypography } from './typography.styles';

export interface BaseTypographyProps<T extends string = TypographyVariant>
  extends MarginProperties {
  /** Component changes the actual HTML tag that is rendered */
  as?: TypographyComponent;

  /**
   * Changes between branded and default font families
   *
   */
  branded?: boolean;

  /** Color changes the text color  */
  color?: ComponentColors;

  /** For attribute, for labels only, must match id of input label is for. **/
  htmlFor?: string;

  /** Optional ID for use with things like aria-labelledby  */
  id?: string;

  /** Role attribute, may aid accessibility. **/
  role?: string;

  /** Add `text-decoration` `line-through` to text */
  strikeThrough?: boolean;

  /** ID for testing */
  testId?: string;

  /** Aligns text */
  textAlign?: 'center' | 'left' | 'right' | 'initial';

  /** Truncates text at given width with an ellipsis **/
  truncateAt?: string;

  /** Variant changes the style of the typography */
  variant?: T;
}

export type TypographyProps<T extends string = TypographyVariant> =
  PropsWithChildren<BaseTypographyProps<T>>;

/**
 * Typography component allows you to style your text the way you need, while rendering the appropriate HTML tag
 * for accessibility. This component is **responsive** - font size will change appropriately based on device size.
 * Defaults to `variant='body'`.
 */
export function Typography<T extends string = TypographyVariant>({
  as,
  children,
  color,
  id,
  strikeThrough = false,
  testId,
  variant,
  ...props
}: TypographyProps<T>) {
  const {
    components: {
      Typography: { componentMapping, variants: themeVariants },
    },
  } = useTheme();

  const variants = ObjectKeys<T>(themeVariants) || baseTypographyVariants;
  const defaultVariant = has(variants, 'body') ? 'body' : variants[0];
  const baseVariant = ensure(
    find(variants, (base) => {
      /**
       * @todo
       * monolith (and derived) TS config thinks 'base' & 'baseVariant'
       * are a huge union type including non-strings. This & the check
       * below for 'renderAs' make it happy.
       */
      if (typeof base === 'string' && !base.includes('-')) {
        if (variant) {
          return variant.includes(base);
        } else {
          return defaultVariant.includes(base);
        }
      }
      return false;
    })
  );

  const renderAs =
    as || (typeof baseVariant === 'string' && componentMapping[baseVariant]);

  return (
    <StyledTypography<T>
      as={renderAs || 'p'}
      color={color}
      data-testid={testId}
      id={id}
      strikeThrough={strikeThrough}
      variant={(variant || defaultVariant) as T}
      {...props}
    >
      {children}
    </StyledTypography>
  );
}
