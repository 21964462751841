import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

export const toastAnimationIn = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
`;

export const toastAnimationOut = keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
`;

interface StyledToastWrapperProps {
  top: number;
}

export const StyledToastWrapper = styled.div<StyledToastWrapperProps>(
  {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    right: 15,
    zIndex: 1000,
    overflow: 'hidden',
  },
  ({ top }) => ({
    top,
  })
);
