import type { ReactNode } from 'react';

import { Box } from '@jane/reefer';

import { CarouselCardStyled } from './carouselCard.styles';
import type { ScrollSnapAlignType } from './carouselCard.styles';

export interface CarouselCardProps {
  /** Carousel children, add carousel cards here */
  children: ReactNode;

  /** Set `scroll-snap-align` css property of card */
  scrollSnapAlign?: ScrollSnapAlignType;

  /** Width of the card on desktop / tablet  */
  width?: number;

  /** Width of the card on mobile */
  widthMobile?: number;
}

export function CarouselCard({
  children,
  scrollSnapAlign = 'start',
  widthMobile,
  width,
}: CarouselCardProps) {
  return (
    <Box height="100%">
      <CarouselCardStyled
        height="100%"
        scrollSnapAlign={scrollSnapAlign}
        widthMobile={widthMobile}
        width={width}
      >
        {children}
      </CarouselCardStyled>
    </Box>
  );
}
