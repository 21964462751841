import { nanoid } from 'nanoid';

import { postMessageToIframeParent } from '@jane/shared-ecomm/util';

export class ParentLocalStorage {
  static getJaneDeviceId(): Promise<string | undefined> {
    return new Promise((resolve, reject) => {
      const token = nanoid();

      const handleGetJaneDeviceId = (message: MessageEvent) => {
        // Handling messageType respondGetJaneDeviceId
        if (message.data?.payload?.token !== token) return;
        resolve(message.data.payload.value);
        window.removeEventListener('message', handleGetJaneDeviceId);
      };

      window.addEventListener('message', handleGetJaneDeviceId);

      postMessageToIframeParent({
        messageType: 'getJaneDeviceId',
        payload: {
          token,
        },
      });

      setTimeout(() => {
        window.removeEventListener('message', handleGetJaneDeviceId);
        reject('getJaneDeviceId timeout');
      }, 3000);
    });
  }
}
