import { config } from '@jane/shared/config';

import { request } from '../lib/request';

export class ProductsSource {
  static urlRoot = `${config.apiPath}/products`;

  static getProduct(id, store_id) {
    return request(
      `${this.urlRoot}/${id}`,
      {
        method: 'GET',
      },
      { store_id }
    );
  }

  static getProducts(params) {
    return request(`${this.urlRoot}`, { method: 'GET' }, params);
  }
}
