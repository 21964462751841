import { useEffect, useRef } from 'react';

import { Typography, styled } from '@jane/reefer';
import type { SearchState } from '@jane/search/types';
import type {
  DeepReadonly,
  Id,
  MenuProduct,
  PendingCartProduct,
  PriceId,
  Recommendation,
  ReservationCartProduct,
  Store,
  StoreSpecial,
} from '@jane/shared/models';

import { useCustomerSelector } from '../../../customer';
import { appModeSelector } from '../../../customer/selectors';
import { flex, flexItem } from '../../../style';
import {
  ItemContainer,
  MENU_PRODUCT_SPONSORED_HEIGHT,
  sortByPriceId,
} from '../../storeMenu/productCardHelper';
import type { ProductCardContextProps } from '../providers/productCardProvider';
import { ProductCardProvider } from '../providers/productCardProvider';
import { ListViewProductCard } from './listView/listViewProductCard';
import { MenuProductCardContainer } from './menuProductCardContainer';

export const findCartProductForMenuProduct = (
  cartProducts: PendingCartProduct[],
  menuProductId: Id,
  storeId: Id,
  cartStoreId?: Id
): ReservationCartProduct[] => {
  const cartProductForMenuProduct = cartProducts.filter(
    (cp) => cp.id === menuProductId && cartStoreId === storeId
  ) as unknown as ReservationCartProduct[];

  return cartProductForMenuProduct;
};

const MenuItemContainer = styled.div(
  flexItem(),
  {
    position: 'relative',
    width: '100%',
  },
  ({ theme }) => {
    return [
      {
        color: theme.colors.grays.black,
        cursor: 'default',
      },
    ];
  }
);

const InlineSponsoredLabel = styled.div(flex({ justifyContent: 'center' }), {
  height: MENU_PRODUCT_SPONSORED_HEIGHT,
});

export interface Props {
  appliedWeightFilter: PriceId | '';
  carouselView?: boolean;
  currentSpecial?: StoreSpecial;
  customerId?: number | null;
  fromSpecialId?: Id | null;
  itemWidth?: number | null;
  listView: boolean;
  maxWeightOption?: number | null;
  onClick?: () => void;
  product: MenuProduct | Recommendation;
  recommendation?: boolean;
  searchState?: SearchState;
  setProductCardWidth?: (arg: number) => void;
  store: DeepReadonly<Store>;
}

export const MenuProductCard = ({
  appliedWeightFilter,
  carouselView,
  currentSpecial,
  customerId,
  itemWidth,
  listView,
  onClick,
  product,
  recommendation,
  searchState,
  setProductCardWidth,
  store,
  fromSpecialId,
  maxWeightOption,
}: Props) => {
  const appMode = useCustomerSelector(appModeSelector);
  const { cartProducts, store: cartStore } = useCustomerSelector(
    ({
      cart: {
        cart: { products: cartProducts, store },
      },
    }) => ({ cartProducts, store })
  );
  const cartStoreId = cartStore?.id;
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setProductCardWidth &&
      containerRef.current &&
      setProductCardWidth(
        itemWidth || containerRef.current.getBoundingClientRect().width
      );
  }, []);

  const cartProduct = sortByPriceId(
    findCartProductForMenuProduct(
      cartProducts as PendingCartProduct[],
      product.id,
      store.id,
      cartStoreId
    ),
    product
  ) as ReservationCartProduct[];

  const productCardContext: Partial<ProductCardContextProps> = {
    appMode,
    appliedWeightFilter,
    carouselView,
    cartProduct,
    currentSpecial,
    customerId,
    listView,
    product,
    menuProduct: product as MenuProduct,
    recommendation,
    searchState,
    store,
    trackListViewClick: onClick,
    fromSpecialId,
    maxWeightOption,
  };

  if (listView) {
    return (
      <ProductCardProvider value={productCardContext}>
        <ListViewProductCard data-product-id={product.id} />
      </ProductCardProvider>
    );
  }

  const isAd = !!(product as MenuProduct).flight;

  return (
    <ProductCardProvider value={productCardContext}>
      <ItemContainer
        data-testid={`product-card-container-${product.id}`}
        ref={containerRef}
      >
        <MenuItemContainer
          data-testid="product-card"
          data-product-id={product.id}
          onClick={onClick}
        >
          <MenuProductCardContainer />
        </MenuItemContainer>
        {isAd && (
          <InlineSponsoredLabel>
            <Typography variant="mini" color="grays-mid" mt={8}>
              Sponsored
            </Typography>
          </InlineSponsoredLabel>
        )}
      </ItemContainer>
    </ProductCardProvider>
  );
};
