import capitalize from 'lodash/capitalize';

export const formatFilterLabel = (
  label: string,
  storeLabels?: { [label: string]: string },
  storeId?: string | number
) => {
  const hasCustomLabels = storeLabels;

  let formattedLabel = label;

  if (formattedLabel.includes(':')) {
    formattedLabel = formattedLabel.split(':')[1].toLowerCase();
  }

  if (hasCustomLabels && hasCustomLabels[formattedLabel]) {
    return hasCustomLabels[formattedLabel];
  }

  if (storeId && label.includes(storeId.toString())) {
    return label.replace(`-${storeId}`, '');
  }

  if (formattedLabel.toLowerCase() === 'cbd') {
    return 'CBD';
  }

  formattedLabel = formattedLabel
    .replace('_', ' ')
    .split(' ')
    .map((word) => capitalize(word))
    .join(' ');

  return formattedLabel;
};
