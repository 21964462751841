import type { CSSObject } from '@emotion/styled';
import styled from '@emotion/styled';

import { lighten, spacing } from '../../styling';
import type { MarginProperties } from '../../styling';
import type { ComponentColors, ReeferTheme } from '../../types';
import { getColorValue } from '../../utils/componentStyles';
import type { LinkVariant } from './link';

export const NewTabLabel = styled.span({
  position: 'absolute',
  width: '1px',
  height: '1px',
  padding: 0,
  margin: '-1px',
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  border: 0,
});

interface LinkStyleProps extends MarginProperties {
  color?: ComponentColors;
  variant: LinkVariant;
}
export interface LinkStylePropsWithTheme extends LinkStyleProps {
  theme: ReeferTheme;
}

const sharedLinkStyle = ({
  color,
  theme,
  variant,
  ...marginProps
}: LinkStylePropsWithTheme) => {
  const { colors } = theme;

  const linkColor = color ? getColorValue(color, theme) : colors.grays.black;

  const styleObject = {
    color: linkColor,
    display: 'inline-block',
    height: 'fit-content',
    width: 'fit-content',
    outline: 'none',
    cursor: 'pointer',
    ...spacing(marginProps),
    ...(variant === 'underline' && {
      transition: 'all 250ms',
      backgroundPosition: 'bottom',
      backgroundRepeat: 'repeat-x',
      backgroundSize: '4px 1px',
      backgroundImage: `linear-gradient(to right, ${lighten(
        linkColor,
        0.5
      )} 50%, transparent 50%)`,
      '&:hover, &:active, &:focus': {
        backgroundImage: `linear-gradient(to right, ${linkColor} 50%, transparent 50%)`,
      },
    }),
    '&:focus-visible': {
      transition: 'none',
      backgroundImage: 'none',
      borderBottom: `2px solid ${linkColor}`,
    },
  };

  const linkStyle: CSSObject = styleObject;

  return linkStyle;
};

export const StyledLink = styled.a<LinkStyleProps>(
  {
    position: 'relative',
    textDecoration: 'none',
  },
  ({ color, theme, variant, ...props }) =>
    sharedLinkStyle({ color, theme, variant, ...props })
);

export const StyledRouterLink = styled.span<LinkStyleProps>(
  ({ color, theme, variant, ...props }) => {
    const { colors } = theme;

    const linkColor = color ? getColorValue(color, theme) : colors.grays.black;

    return {
      ...sharedLinkStyle({ color, theme, variant, ...props }),
      '.router-link': {
        textDecoration: 'none',
        outline: 'none',
        color: 'inherit',
        '&:focus-visible': {
          transition: 'none',
          borderBottom: `2px solid ${linkColor}`,
        },
      },
    };
  }
);

export const StyledButton = styled.span<LinkStyleProps>(
  ({ color, theme, variant, onClick, ...props }) => ({
    ...sharedLinkStyle({ color, theme, variant, ...props }),
    ...(!onClick && {
      cursor: 'default',
    }),
  })
);
