import get from 'lodash/get';
import isArray from 'lodash/isArray';
import set from 'lodash/set';

import type { CustomTheme } from '@jane/reefer';

import { DEFAULT_OPERATOR_THEME, DEFAULT_THEME } from '../theming';

type ThemeData = Partial<CustomTheme> & {
  [key: string]: any;
};

const THEME_MAPPING = {
  themeColor: 'colors.primary',
  navigationColor: 'colors.secondary',
  calloutBackground: 'colors.info',
  backgroundColor: 'colors.background',
  ctaTextColor: 'colors.text.inverse',
  calloutTextColor: 'colors.text.info',
  buttonBorderRadius: [
    'components.Button.primary.styles.borderRadius',
    'components.Button.secondary.styles.borderRadius',
    'components.Button.tertiary.styles.borderRadius',
    'components.Button.tertiary-selected.styles.borderRadius',
    'components.Button.destructive.styles.borderRadius',
    'components.Button.minimal.styles.borderRadius',
  ],
  'font.color': 'colors.text.main',
  'font.fontFamily': 'components.Typography.fonts.default.fontFamily',
  'font.url': 'components.Typography.fonts.default.url',
};

export const getTheme = (customTheme?: ThemeData) => {
  const mergedCustomTheme = { ...DEFAULT_THEME };

  Object.keys(THEME_MAPPING).forEach((customKey) => {
    const themeKey = THEME_MAPPING[customKey];
    const customValue = get(customTheme, customKey);

    if (customValue) {
      if (isArray(themeKey)) {
        themeKey.forEach((path) => set(mergedCustomTheme, path, customValue));
      } else {
        set(mergedCustomTheme, themeKey, customValue);
      }
    }
  });

  return mergedCustomTheme;
};

export const getOperatorTheme = (customTheme?: {
  [key: string]: any;
  navigation_color?: string | null;
  theme_color?: string | null;
}) => {
  return {
    ...DEFAULT_OPERATOR_THEME,
    colors: {
      primary:
        customTheme?.theme_color || DEFAULT_OPERATOR_THEME.colors.primary,
      secondary:
        customTheme?.navigation_color ||
        DEFAULT_OPERATOR_THEME.colors.secondary,
    },
  };
};

export const getStoreTheme = (customTheme?: {
  [key: string]: any;
  custom_navigation_color?: string | null;
  custom_theme_color?: string | null;
}) => {
  return {
    ...DEFAULT_THEME,
    colors: {
      primary: customTheme?.custom_theme_color || DEFAULT_THEME.colors.primary,
      secondary:
        customTheme?.custom_navigation_color || DEFAULT_THEME.colors.secondary,
    },
  };
};
