import styled from '@emotion/styled';

import {
  getColorValue,
  textColorForBackgroundColor,
} from '../../../utils/componentStyles';
import type { CardContentProps } from '../card.types';

type StyledCardContentProps = Omit<CardContentProps, 'testId' | 'children'>;

export const StyledCardContent = styled.div<StyledCardContentProps>(
  ({ theme, background, height, onClick, width }) => ({
    width: width,
    height: height,
    padding: '16px',
    background: getColorValue(background, theme),
    color: textColorForBackgroundColor(background, theme),
    outline: 'none',
    ...(onClick && {
      cursor: 'pointer',
      ':focus-visible': {
        background: `radial-gradient(transparent, transparent, ${theme.colors.grays.ultralight})`,
        transition: '250ms all',
      },
    }),
  })
);
