import { Typography } from '@jane/reefer';

interface Props {
  appPartner?: string;
  embeddedMode?: boolean;
  modal?: boolean;
}

const Security = ({
  appPartner = 'Jane',
  embeddedMode = false,
  modal = false,
}: Props) => {
  const alwaysText = `${appPartner} keeps personal information safe and secure.`;
  const text = embeddedMode
    ? `User accounts are powered by Jane. ${alwaysText}`
    : alwaysText;

  return (
    <Typography
      as="div"
      color="grays-mid"
      css={{
        display: 'inline-block',
      }}
      my="auto"
      mb="auto"
      textAlign="center"
      variant={modal ? 'mini' : 'body'}
    >
      {text}
    </Typography>
  );
};

export default Security;
