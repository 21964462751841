import algoliasearch from 'algoliasearch';

import { fetchDeliveryStores } from '@jane/shared-ecomm/data-access';
import type { StoreFulfillmentTypes } from '@jane/shared-ecomm/providers';
import { EventNames, track } from '@jane/shared-ecomm/tracking';
import { config } from '@jane/shared/config';
import type { GeocodingResult, StoreHit } from '@jane/shared/models';

import type { LocationErrors } from './userPreferencesModal';

const illegalStates = ['ID', 'KS', 'KY', 'NC', 'NE', 'SC', 'WY'];

const additionalRegulationStates = ['FL', 'PA', 'MO'];

const METERS_PER_MILE = 1609.34;

export const getLocationInfo = async ({
  customMaxSearchRadius,
  fulfillmentType,
  location,
  setDetecting,
}: {
  customMaxSearchRadius?: string | number | null;
  fulfillmentType: StoreFulfillmentTypes;
  location: GeocodingResult;
  setDetecting: (detecting: boolean) => void;
}): Promise<void> => {
  track({ event: EventNames.SetLocation, ...location });

  const handleReturn = ({ error }: { error: LocationErrors | null }) => {
    setDetecting(false);
    if (error) {
      return Promise.reject(error);
    } else {
      return Promise.resolve();
    }
  };

  if (!location.street && fulfillmentType === 'delivery') {
    return handleReturn({ error: 'deliveryAddress' });
  }

  if (illegalStates.includes(location.state as string)) {
    return handleReturn({ error: 'illegal' });
  }

  if (additionalRegulationStates.includes(location.state as string)) {
    return handleReturn({ error: 'regulations' });
  }

  if (!location.coordinates) {
    return handleReturn({ error: 'error' });
  }

  if (fulfillmentType === 'delivery') {
    const availableStores = await fetchDeliveryStores(
      location.coordinates,
      location.zipcode as string
    );

    if (availableStores?.store_ids.length > 0) {
      return handleReturn({ error: null });
    } else {
      return handleReturn({ error: 'noDeliveryStores' });
    }
  } else {
    return algoliasearch(config.algoliaAppId, config.algoliaApiKey)
      .search<StoreHit>([
        {
          indexName: `stores-${config.algoliaEnv}`,
          params: {
            aroundLatLng: `${location.coordinates.lat}, ${location.coordinates.long}`,
            aroundRadius: Math.ceil(
              Number(customMaxSearchRadius || 35) * METERS_PER_MILE
            ),
            filters: 'marketplace_visible:true AND product_count > 0',
            hitsPerPage: 1,
          },
          query: '',
        },
      ])
      .then(
        (response) => {
          if (response.results[0].nbHits > 0) {
            return handleReturn({ error: null });
          } else {
            return handleReturn({ error: 'noNearbyStores' });
          }
        },
        () => {
          return handleReturn({ error: 'error' });
        }
      );
  }
};
