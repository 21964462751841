import { useCallback, useMemo } from 'react';

import type { Location } from '@jane/shared-ecomm/providers';
import { useUserPreferences } from '@jane/shared-ecomm/providers';
import { config } from '@jane/shared/config';
import type { AppMode, Id } from '@jane/shared/models';
import type {
  CustomerMeta,
  DmEvent,
  DmEventMeta,
  DmInfo,
} from '@jane/shared/util';
import { getErrorDetails, trackDmEvent } from '@jane/shared/util';

import { useCustomerSelector } from '../customer';
import type { CustomerReducerState } from '../customer/redux/customer';
import { isNoStore } from '../customer/redux/store';
import { appModeSelector } from '../customer/selectors';
import { get } from '../redux-util/selectors';

export const useTrackDmEvent = () => {
  const dmEventMeta = useDmEventMeta();
  const dmEventMetaJSON = JSON.stringify(dmEventMeta);

  const trackDmError = useCallback(
    (error: Error | string) => {
      let err;
      if (error instanceof Error) {
        err = error;
      } else if (typeof error === 'string') {
        err = new Error(error);
      }
      const body: DmEvent = {
        ...dmEventMeta,
        type: 'error',
        payload: getErrorDetails(err),
      };
      err && trackDmEvent(body);
    },
    [dmEventMetaJSON]
  );

  const trackDmInfo = useCallback(
    (info: DmInfo) => {
      const body: DmEvent = {
        ...dmEventMeta,
        payload: info,
        type: 'info',
      };
      trackDmEvent(body);
    },
    [dmEventMetaJSON]
  );

  return { trackDmError, trackDmInfo };
};

const useDmEventMeta = (): DmEventMeta => {
  const { buildVersion } = config;
  const appMode = useCustomerSelector(appModeSelector);
  const customer = useCustomerSelector(get('customer'));
  const { store } = useCustomerSelector(get('store'));
  const { janeDeviceId } = useCustomerSelector(get('application'));
  const { userLocation } = useUserPreferences();
  const storeId = isNoStore(store) ? undefined : store.id;
  const meta = useMemo(
    () =>
      getDmEventMeta({
        appMode,
        janeDeviceId,
        buildVersion,
        storeId,
        customer,
        userLocation,
      }),
    [appMode, buildVersion, customer, janeDeviceId, storeId, userLocation]
  );
  return meta;
};

export const getDmEventMeta = ({
  appMode,
  janeDeviceId,
  buildVersion,
  storeId,
  customer,
  userLocation,
}: {
  appMode: AppMode;
  buildVersion: string;
  customer?: CustomerReducerState;
  janeDeviceId: string;
  storeId?: Id | undefined;
  userLocation?: Location;
}): DmEventMeta => {
  const meta: DmEventMeta = {
    source: 'client',
    app_mode: appMode,
    jane_device_id: janeDeviceId,
    build_version: buildVersion,
    app_store_id: storeId,
  };
  if (customer?.id) meta.customer = getCustomerMeta(customer, userLocation);
  return meta;
};

const getCustomerMeta = (
  customer: CustomerReducerState,
  userLocation?: Location | undefined
): CustomerMeta => {
  const { id, email, firstName, nickname, lastName, phone } = customer;
  const customerMeta: CustomerMeta = {
    id: id,
    email: email,
    first_name: firstName || nickname,
    last_name: lastName,
    nickname: nickname,
    phone: phone,
  };
  if (userLocation) {
    const { cityState, coordinates } = userLocation;
    customerMeta.city_state = cityState;
    customerMeta.coordinates = coordinates;
  }
  return customerMeta;
};
