import styled from '@emotion/styled';

import { spacing } from '../../../styling';
import { Link } from '../../link';
import type {
  StyledListItemLinkProps,
  StyledListItemProps,
} from '../list.types';

export const StyledListItem = styled.li<StyledListItemProps>(
  ({
    alignment,
    disableHover,
    hasLink,
    onClick,
    selected,
    theme,
    ...spacingProps
  }) => ({
    listStyle: 'none',
    textAlign: alignment,
    ...(alignment === 'center' && { margin: '0 auto' }),
    ...(alignment === 'right' && { margin: '0 0 0 auto' }),
    ...(selected && { backgroundColor: theme.colors.grays.ultralight }),
    ...spacing(spacingProps),
    ...((onClick || hasLink) &&
      !disableHover && {
        cursor: 'pointer',
        '&:hover, &:focus-visible': {
          backgroundColor: theme.colors.grays.hover,
          outline: 'none',
        },
      }),
  })
);

export const StyledListItemLink = styled(Link)<StyledListItemLinkProps>(
  ({ theme }) => ({
    width: '100%',
    height: '100%',
    ':focus-visible': {
      backgroundColor: theme.colors.grays.hover,
      outline: 'none',
      borderBottom: 'unset',
    },
  })
);

export const StyledListItemRouterLink = styled.span<StyledListItemLinkProps>(
  ({ theme, ...spacingProps }) => ({
    '.router-link': {
      display: 'block',
      textDecoration: 'none',
      outline: 'none',
      color: 'inherit',
      ...spacing(spacingProps),
      ':focus-visible': {
        backgroundColor: theme.colors.grays.hover,
        outline: 'none',
        borderBottom: 'unset',
      },
    },
  })
);
