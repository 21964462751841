import styled from '@emotion/styled';

import type { MarginProperties } from '../../../styling';
import { shadows, spacing } from '../../../styling';
import type { ComponentColors } from '../../../types';
import {
  getBorderRadiusValue,
  getColorValue,
  getComponentStyles,
} from '../../../utils/componentStyles';

interface StyledCardGroupProps extends MarginProperties {
  border: ComponentColors | 'none';
  borderWidth?: string;
  direction: 'column' | 'row';
  flat: boolean;
  height?: string | number;
  separator: ComponentColors | 'none';
  width?: string | number;
}

export const StyledCardGroup = styled.div<StyledCardGroupProps>(
  ({
    border,
    borderWidth,
    separator,
    direction,
    flat,
    height,
    width,
    theme,
    ...marginProps
  }) => {
    const cardStyles = getComponentStyles('Card', theme);
    const borderRadius = getBorderRadiusValue(cardStyles.borderRadius, theme);
    return {
      position: 'relative',
      display: 'inline-flex',
      flexDirection: direction === 'column' ? 'column' : 'row',
      overflow: 'hidden',
      height: height ? height : 'fit-content',
      width: width ? width : 'fit-content',
      borderRadius,
      boxShadow: flat ? 'none' : shadows.item,
      border:
        border === 'none'
          ? 'none'
          : `${borderWidth} solid ${getColorValue(border, theme)}`,
      '.card-content:not(:first-of-type)': {
        ...(direction === 'column' && {
          borderTop:
            separator === 'none'
              ? 'none'
              : `1px solid ${getColorValue(separator, theme)}`,
        }),
        ...(direction === 'row' && {
          borderLeft:
            separator === 'none'
              ? 'none'
              : `1px solid ${getColorValue(separator, theme)}`,
        }),
      },
      ...spacing(marginProps),
    };
  }
);
