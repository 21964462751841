import { Flex, TagIcon, Typography } from '@jane/reefer';

export const SpecialsTag = ({ title }: { title: string }) => {
  return (
    <Flex alignItems="center">
      <TagIcon size="sm" color="info" />
      <Typography color="info" ml={4}>
        {title}
      </Typography>
    </Flex>
  );
};
