import * as t from 'io-ts';

export const tTermsOfServiceVariants = t.union([
  t.literal('customer'),
  t.literal('business'),
  t.literal('data'),
  t.literal('brands'),
  t.literal('ads'),
  t.literal('advertising'),
  t.literal('cash_back_program'),
]);

export const tTermsOfService = t.interface({
  text: t.string,
});

export const tPrivacyPolicy = t.interface({
  text: t.string,
});

export const tAdTermsOfService = t.interface({
  text: t.string,
});

export const tPointOfSaleTermsOfService = t.interface({
  text: t.string,
});

export type TermsOfServiceVariants = t.TypeOf<typeof tTermsOfServiceVariants>;
export type TermsOfService = t.TypeOf<typeof tTermsOfService>;
