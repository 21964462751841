import { Button, DismissIcon, Flex, Typography, styled } from '@jane/reefer';
import type { PriceId } from '@jane/shared/models';
import { formatCurrency } from '@jane/shared/util';

import { useMediaQuery } from '../../../../common/providers/mediaQueryProvider';
import { useCustomerSelector } from '../../../../customer';
import getMaxCartQuantity from '../../../../lib/getMaxCartQuantity';
import { get } from '../../../../redux-util/selectors';
import { flex, spacing } from '../../../../style';
import QuantitySelector from '../../../quantitySelector';
import {
  DisplayMode,
  useProductCardContext,
} from '../../providers/productCardProvider';

const ListEditingContainer = styled.div(
  flex({ alignItems: 'center', justifyContent: 'flex-end', flexWrap: 'wrap' }),
  spacing({ p: 24 }),
  ({ theme }) => ({
    backgroundColor: theme.colors.grays.ultralight,
    rowGap: 16,
  })
);

const PriceContainer = styled.div(
  flex({ alignItems: 'center', justifyContent: 'center', column: true }),
  spacing({ mr: 16, ml: 4 })
);

const CloseContainer = styled.div({ cursor: 'pointer' });

interface EditingCardProps {
  decrementQuantity: () => void;
  discountedPrice?: number;
  incrementQuantity: () => void;
  multipleCountDisabled: boolean;
  onAddToCartPressed: () => void;
  originalPrice?: number;
  selectedQuantity: number;
  selectedWeight: PriceId;
  shoppingDisabled: boolean;
  soldByWeight: boolean;
}

const EditingCard = ({
  decrementQuantity,
  discountedPrice,
  incrementQuantity,
  multipleCountDisabled,
  onAddToCartPressed,
  originalPrice,
  selectedQuantity,
  selectedWeight,
  shoppingDisabled,
  soldByWeight,
}: EditingCardProps) => {
  const {
    cartProduct,
    menuProduct,
    setDisplayMode,
    store,
    trackListViewClick,
  } = useProductCardContext();
  const price = discountedPrice || originalPrice;
  const taxText = store.tax_included ? `Taxes included` : '';
  const { appMode } = useCustomerSelector(get('embeddedApp'));
  const { smallerThanVerticalTablet } = useMediaQuery();

  const selectedWeightInCart = cartProduct?.find(
    (product) => product.price_id === selectedWeight
  );

  const alreadyInCart =
    selectedWeight === selectedWeightInCart?.price_id &&
    selectedQuantity === selectedWeightInCart?.count;

  let buttonText = shoppingDisabled
    ? 'Shopping disabled'
    : cartProduct && selectedQuantity === 0
    ? 'Remove'
    : cartProduct && selectedWeightInCart
    ? 'Update'
    : soldByWeight && !selectedWeight
    ? 'Select a weight'
    : 'Confirm';

  if (smallerThanVerticalTablet) {
    buttonText = `${formatCurrency(price)} - ${buttonText}`;
  }

  return (
    <ListEditingContainer>
      <Flex
        onClick={() => {
          setDisplayMode(
            cartProduct.length > 0
              ? DisplayMode.Confirmation
              : DisplayMode.Product
          );
          trackListViewClick && trackListViewClick();
        }}
        justifyContent="center"
        ariaLabel="close"
        justifySelf="flex-start"
        css={{ marginRight: 'auto' }}
      >
        <CloseContainer>
          <DismissIcon color="grays-black" />
        </CloseContainer>
      </Flex>
      {!smallerThanVerticalTablet && (
        <Flex height="100%" alignItems="center">
          <PriceContainer>
            {!isNaN(price) && (
              <Typography
                color="grays-black"
                branded={appMode === 'default'}
                variant="header-bold"
              >
                {formatCurrency(price)}
              </Typography>
            )}
            <Typography color="grays-black" variant="body">
              {taxText}
            </Typography>
          </PriceContainer>
        </Flex>
      )}

      <Flex justifyContent="flex-end">
        {!multipleCountDisabled && (
          <Flex justifyContent="center" pr={16}>
            <QuantitySelector
              minQuantity={selectedWeightInCart ? 0 : 1}
              maxQuantity={getMaxCartQuantity(menuProduct, selectedWeight)}
              multipleCountDisabled={multipleCountDisabled}
              value={selectedQuantity}
              incrementQuantity={incrementQuantity}
              decrementQuantity={decrementQuantity}
            />
          </Flex>
        )}
      </Flex>
      {!isNaN(price) && (
        <Button
          full={smallerThanVerticalTablet}
          label={buttonText}
          variant="primary"
          onClick={onAddToCartPressed}
          disabled={
            shoppingDisabled ||
            (soldByWeight && !selectedWeight) ||
            alreadyInCart
          }
          ariaLabel={buttonText.toLowerCase()}
        />
      )}
    </ListEditingContainer>
  );
};

export default EditingCard;
