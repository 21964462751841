import { useEffect, useRef, useState } from 'react';

import { StyledTable } from './table.styles';
import type { TableProps } from './table.types';

export function Table({
  children,
  freezeLastColumn = false,
  freezeFirstColumn = false,
  scrollable = true,
}: TableProps) {
  const [firstCellCheckbox, setFirstCellCheckbox] = useState(false);
  const tableRef = useRef<HTMLTableElement | null>(null);

  useEffect(() => {
    // check if the first cell is a checkbox cell to determine if the frozenFirstColumn is actually the second column
    if (freezeFirstColumn) {
      const headers = tableRef?.current?.querySelectorAll(
        'th'
      ) as NodeListOf<HTMLTableCellElement>;

      if (Array.from(headers)[0].getAttribute('data-id') === 'checkbox-cell') {
        setFirstCellCheckbox(true);
      }
    }
  }, [freezeFirstColumn]);

  return (
    <StyledTable
      freezeFirstColumn={freezeFirstColumn}
      freezeLastColumn={freezeLastColumn}
      firstCellCheckbox={firstCellCheckbox}
      scrollable={scrollable || freezeFirstColumn || freezeLastColumn}
      ref={tableRef}
    >
      {children}
    </StyledTable>
  );
}
