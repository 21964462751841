import type { ReactNode } from 'react';
import { createContext, useContext } from 'react';

import type { ActiveFilters } from './filter.types';

interface ActiveFilterContextProps {
  activeFilters: ActiveFilters[];
  children?: ReactNode;
}

export const ActiveFilterContext = createContext<ActiveFilterContextProps>({
  activeFilters: [],
});

export const ActiveFilterProvider = ({
  activeFilters,
  children,
}: ActiveFilterContextProps) => {
  return (
    <ActiveFilterContext.Provider
      value={{
        activeFilters,
      }}
    >
      {children}
    </ActiveFilterContext.Provider>
  );
};

export const useActiveFilterContext = () => useContext(ActiveFilterContext);
