const cartResult = (cart) => ({
  cart,
  isPendingCart: true,
  isReservation: false,
});

const reservationResult = (reservation) => ({
  cart: reservation,
  isPendingCart: false,
  isReservation: true,
});

const emptyResult = () => ({
  cart: undefined,
  isPendingCart: false,
  isReservation: false,
});

export default (cart, inProgressReservations) => {
  if (cart.status === 'pending') {
    return cartResult(cart);
  }

  if (inProgressReservations.length > 0) {
    return reservationResult(inProgressReservations[0]);
  }

  return emptyResult();
};
