import capitalize from 'lodash/capitalize';
import compact from 'lodash/compact';

import { RadioFieldGroup } from '@jane/reefer';
import type { Id, StoreSpecial } from '@jane/shared/models';

const groupDiscountLabel = (special: StoreSpecial) => {
  const {
    discount_type,
    discount_percent,
    discount_dollar_amount,
    conditions,
  } = special;

  if (!conditions['qualified_group']) return null;

  const applicableGroup = conditions['qualified_group']['type'];

  const seniorAgeRequirement =
    applicableGroup === 'senior' &&
    `(${(conditions['qualified_group'] as any)['required_age']}+)`;

  const pediatricAgeRequirement =
    applicableGroup === 'pediatric' &&
    `(${(conditions['qualified_group'] as any)['required_age']} and under)`;

  const discountAmount =
    discount_type === 'percent'
      ? `${discount_percent}%`
      : `$${discount_dollar_amount}`;

  const groupLabel =
    applicableGroup === 'senior'
      ? `${capitalize(applicableGroup)} ${seniorAgeRequirement}`
      : applicableGroup === 'pediatric'
      ? `Pediatric/Minor ${pediatricAgeRequirement}`
      : `${capitalize(applicableGroup)}`;

  return `${groupLabel} – ${discountAmount} OFF`;
};

const discountItems = (groupDiscounts: StoreSpecial[]) =>
  compact(
    groupDiscounts.map((special) => {
      const { conditions } = special;

      const label = groupDiscountLabel(special);

      if (!conditions['qualified_group'] || !label) return null;

      return {
        value: special.id.toString(),
        label,
      };
    })
  ).concat([
    {
      value: '',
      label: 'None',
    },
  ]);

interface DiscountButtonProps {
  analyzingSpecials?: boolean;
  groupDiscounts: StoreSpecial[];
  selectSpecial: (specialId?: Id) => void;
  selectedSpecial?: Id;
}

export const GroupDiscountButton = ({
  analyzingSpecials,
  groupDiscounts,
  selectSpecial,
  selectedSpecial,
}: DiscountButtonProps) => {
  return (
    <RadioFieldGroup
      defaultChecked={selectedSpecial?.toString()}
      name="groupDiscount"
      onChange={(value) => {
        selectSpecial(parseInt(value as string) || undefined);
      }}
      options={discountItems(groupDiscounts).map((item) => ({
        id: item.value,
        value: item.value.toString(),
        label: item.label,
      }))}
      disabled={analyzingSpecials}
      css={{ input: { backgroundColor: 'unset' } }}
    />
  );
};
