import type { CSSObject } from '@emotion/styled';
import styled from '@emotion/styled';

import type { ReeferTheme } from '../../types';
import type { LogoProps } from './logo';

type LogoStyleProps = Pick<LogoProps, 'variant' | 'color' | 'size'>;

type LogoPaddingProps = Pick<LogoStyleProps, 'size'>;

interface LogoStylePropsWithTheme extends LogoStyleProps {
  theme: ReeferTheme;
}

const logoPadding = ({ size = 'md' }: LogoPaddingProps) => {
  const sizeMap = {
    xs: '8px',
    sm: '11px',
    md: '15px',
    lg: '30px',
    xl: '60px',
  };

  return {
    padding: sizeMap[size],
  };
};

const logoStyle = ({
  variant = 'primary',
  color,
  size = 'md',
  theme,
}: LogoStylePropsWithTheme) => {
  const sizeMap = {
    primary: {
      xs: {
        height: '22px',
        width: '43px',
      },
      sm: {
        height: '40px',
        width: '78px',
      },
      md: {
        height: '56px',
        width: '108px',
      },
      lg: {
        height: '110px',
        width: '213px',
      },
      xl: {
        height: '224px',
        width: '434px',
      },
    },
    secondary: {
      xs: {
        height: '13px',
        width: '43px',
      },
      sm: {
        height: '24px',
        width: '91px',
      },
      md: {
        height: '40px',
        width: '150px',
      },
      lg: {
        height: '72px',
        width: '270px',
      },
      xl: {
        height: '142px',
        width: '532px',
      },
    },
  };

  return {
    color:
      color === 'white' ? theme.colors.grays.white : theme.colors.grays.black,
    ...sizeMap[variant][size],
  } as CSSObject;
};

export const StyledLogo = styled.div<LogoStyleProps>(({ theme, ...props }) => ({
  ...logoStyle({ theme, ...props }),
}));

export const LogoWrapper = styled.div<LogoPaddingProps>(({ size }) => ({
  ...logoPadding({ size }),
  display: 'flex',
}));
