import { createContext, useState } from 'react';

import { Lightbox } from '../lightbox';
import type { CarouselProps } from './carousel.types';
import { CarouselSwiper } from './carouselSwiper/carouselSwiper';

/**
 * CarouselContext to pass props to Carousel subcomponents
 */

export const CarouselContext = createContext<
  Pick<
    CarouselProps,
    | 'desktopHeight'
    | 'desktopWidth'
    | 'mobileHeight'
    | 'mobileWidth'
    | 'onVideoClick'
    | 'stockPhoto'
  >
>({
  desktopHeight: '',
  desktopWidth: '',
  mobileHeight: '',
  mobileWidth: '',
  onVideoClick: () => {
    /* */
  },
  stockPhoto: false,
});

export const Carousel = ({
  appId,
  desktopHeight = '566px',
  desktopWidth = '566px',
  items,
  mobileHeight = '375px',
  mobileWidth = '100vw',
  onVideoClick,
  forceMobile,
  showThumbnails = true,
  stockPhoto = false,
  variant = 'product',
}: CarouselProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const updateIndex = (newIndex: number) => {
    setActiveIndex(newIndex);
  };

  return (
    <CarouselContext.Provider
      value={{
        desktopHeight,
        desktopWidth,
        mobileHeight,
        mobileWidth,
        onVideoClick,
        stockPhoto,
      }}
    >
      {!isOpen && (
        <CarouselSwiper
          items={items}
          activeIndex={activeIndex}
          updateIndex={updateIndex}
          onClick={() => setIsOpen(true)}
          showThumbnails={showThumbnails}
          forceMobile={forceMobile}
          variant={variant}
        />
      )}
      <Lightbox
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        appId={appId}
      >
        {isOpen && (
          <CarouselSwiper
            items={items}
            activeIndex={activeIndex}
            updateIndex={updateIndex}
            isLightbox={true}
            showThumbnails={showThumbnails}
            variant={variant}
          />
        )}
      </Lightbox>
    </CarouselContext.Provider>
  );
};
