import type { ReactNode } from 'react';

import { DismissMiniIcon, Flex, Typography, styled } from '@jane/reefer';

const Body = styled.div(({ theme }) => ({
  color: theme.colors.grays.mid,
}));

const IconWrapper = styled(Flex)(({ theme }) => ({
  borderRadius: '50%',
  backgroundColor: theme.colors.system.negative.main,
  color: theme.colors.grays.white,
  height: '12px',
  width: '12px',
}));

interface Props {
  children: ReactNode;
  headerText: string;
}

export const CartError = ({ headerText, children }: Props) => (
  <div>
    <Flex mb={4} alignItems="baseline">
      <IconWrapper
        alignSelf="center"
        justifyContent="center"
        alignItems="center"
        mr={8}
        p={2}
      >
        <DismissMiniIcon color="grays-white" size="sm" />
      </IconWrapper>
      <Typography as="span" color="error" variant="body-bold">
        {headerText}
      </Typography>
    </Flex>
    <Body role="alert">{children}</Body>
  </div>
);
