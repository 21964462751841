import * as t from 'io-ts';
import qs from 'qs';

import { config } from '@jane/shared/config';
import type { Address } from '@jane/shared/models';
import { tPosCart } from '@jane/shared/models';

import { request } from '../lib/request';

const urlRoot = `${config.apiPath}/pos_cart`;

export const PosCartSource = {
  get(deliveryAddress: Address | null | undefined, cart_uuid: string) {
    const address = deliveryAddress || {};
    return request(urlRoot, { method: 'GET' }, { ...address, cart_uuid });
  },
};

export const getPosCartSource = (
  cartUuid: string,
  deliveryAddress: Address | null | undefined
) => {
  const queryParams = qs.stringify({
    ...deliveryAddress,
    cart_uuid: cartUuid,
  });
  return {
    url: `${urlRoot}?${queryParams}`,
    type: t.interface({ pos_cart: tPosCart }),
  };
};
