import {
  fakeButtonProps,
  getAccessibilityProps,
  handleEnterKey,
} from '../../../utils';
import type { CardContentProps } from '../card.types';
import { StyledCardContent } from './cardContent.styles';

/**
 * In general, the `Card.Content` component should always be included as a child of a `Card` component.
 * The main contents of the card are added as children of this component.
 * */

export function CardContent({
  ariaLabel,
  ariaLabelledBy,
  background = 'grays-white',
  children,
  onClick,
  role,
  testId,
  height = '100%',
  width = '100%',
}: CardContentProps) {
  const a11yProps = getAccessibilityProps(
    { ariaLabel, ariaLabelledBy, role, onClick },
    'Card.Content'
  );

  return (
    <StyledCardContent
      background={background}
      className="card-content"
      data-testid={testId}
      onClick={onClick}
      onKeyUp={(event) => handleEnterKey(event, onClick)}
      {...(onClick && fakeButtonProps)}
      height={height}
      width={width}
      {...a11yProps}
    >
      {children}
    </StyledCardContent>
  );
}
