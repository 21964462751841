import styled from '@emotion/styled';

import { mediaQueries, shadows, spacing } from '../../../styling';
import type { ModalStyleProps } from '../modal.types';

type ModalFooterStyleProps = Omit<ModalStyleProps, 'onRequestClose' | 'open'>;

export const StyledModalFooter = styled.div(
  ({ variant }: ModalFooterStyleProps) => ({
    boxShadow: shadows.dividerTop,
    ...spacing({
      px: 24,
      py: 16,
    }),
    [mediaQueries.tablet('min')]: {
      ...spacing({
        px: variant === 'full-screen' ? 40 : 24,
      }),
    },
  })
);
