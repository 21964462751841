import styled from '@emotion/styled';
import ReactModal from 'react-modal';

import { mediaQueries } from '../../styling';
import {
  getColorValue,
  textColorForBackgroundColor,
} from '../../utils/componentStyles';
import type { ModalStyleProps } from './modal.types';

function shadowAppSelector(): HTMLElement {
  const shadowHost = document.getElementById('shadow-host')?.shadowRoot;
  let shadowApp = shadowHost?.getElementById('shadow-app-sibling');
  shadowApp = shadowApp || document.body;
  return shadowApp;
}

// assigns new className to wrap the modal and define styles
export function ReactModalAdapter({
  className,
  open,
  parentSelector,
  ...props
}: ModalStyleProps) {
  parentSelector = parentSelector || shadowAppSelector;

  return (
    <ReactModal
      portalClassName={className}
      className={className}
      overlayClassName={className}
      closeTimeoutMS={200}
      isOpen={open}
      parentSelector={parentSelector}
      {...props}
    />
  );
}

/** Sets width/height of the standard modal */
const STANDARD_SIZE = '896px';

/** Sets max-height/width and margins (24px on mobile, 64px on desktop) */
const MOBILE_MAX_SIZE = 'calc(100% - 48px)';
const DESKTOP_MAX_SIZE = 'calc(100% - 128px)';

const MODAL_VARIANTS = {
  dialogue: {
    height: 'auto',
    width: 'auto',
    maxHeight: MOBILE_MAX_SIZE,
    maxWidth: MOBILE_MAX_SIZE,
    [mediaQueries.desktop('sm', 'min')]: {
      maxHeight: DESKTOP_MAX_SIZE,
      maxWidth: DESKTOP_MAX_SIZE,
    },
  },
  'standard-dialogue': {
    height: 'auto',
    width: STANDARD_SIZE,
    maxHeight: MOBILE_MAX_SIZE,
    maxWidth: MOBILE_MAX_SIZE,
    [mediaQueries.desktop('sm', 'min')]: {
      maxHeight: DESKTOP_MAX_SIZE,
      maxWidth: STANDARD_SIZE,
    },
  },
  standard: {
    height: STANDARD_SIZE,
    width: STANDARD_SIZE,
    maxHeight: MOBILE_MAX_SIZE,
    maxWidth: MOBILE_MAX_SIZE,
    [mediaQueries.desktop('sm', 'min')]: {
      maxHeight: DESKTOP_MAX_SIZE,
      maxWidth: STANDARD_SIZE,
    },
  },
  flex: {
    height: MOBILE_MAX_SIZE,
    width: MOBILE_MAX_SIZE,
    [mediaQueries.desktop('sm', 'min')]: {
      height: DESKTOP_MAX_SIZE,
      width: DESKTOP_MAX_SIZE,
    },
  },
  'full-screen': {
    height: '100%',
    width: '100%',
  },
};

export const StyledModal = styled(ReactModalAdapter)(
  ({ theme, background, variant, overflow }) => ({
    '.ReactModal__Overlay': {
      position: 'fixed',
      background: 'rgba(0,0,0,0.4)',
      top: '0px',
      left: '0px',
      right: '0px',
      bottom: '0px',
      zIndex: '99999999',
      opacity: 0,
      transition: 'opacity 200ms ease',
    },
    '.ReactModal__Overlay--after-open': {
      opacity: 1,
    },
    '.ReactModal__Overlay--before-close': {
      opacity: 0,
    },
    '.ReactModal__Content': {
      display: 'flex',
      flex: 'auto',
      flexDirection: 'column',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      WebkitOverflowScrolling: 'touch',
      borderRadius: variant === 'full-screen' ? 0 : theme.borderRadius.lg,
      background: getColorValue(background, theme),
      color: textColorForBackgroundColor(background, theme),
      minWidth: '300px',
      overflow,
      ...(variant && MODAL_VARIANTS[variant]),
      ':focus-visible': {
        outline: 'none',
      },
      ':focus': {
        outline: 'none',
      },
    },
  })
);
