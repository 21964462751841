import { createContext, useContext, useState } from 'react';
import type { ReactNode } from 'react';

import type { SearchState } from '@jane/search/types';
import type { FlagHelper } from '@jane/shared/feature-flags';
import { FLAGS, useFlagHelper } from '@jane/shared/feature-flags';
import type {
  AppMode,
  DeepReadonly,
  Id,
  MenuProduct,
  PriceId,
  Product,
  Recommendation,
  ReservationCartProduct,
  Store,
  StoreSpecial,
} from '@jane/shared/models';

export enum DisplayMode {
  Confirmation = 'confirmation',
  Edit = 'edit',
  Product = 'product',
}

export interface ProductCardContextProps {
  appMode: AppMode;
  appliedWeightFilter: PriceId | '';
  carouselView?: boolean;
  cartProduct?: ReservationCartProduct[];
  currentSpecial?: StoreSpecial;
  customerId: number | null;
  displayMode: DisplayMode;
  /**
   * Short-lived experiment props
   * Should be removed once experiment is complete!
   */
  flags?: {
    [key: string]: FlagHelper;
  };
  fromSpecialId?: Id | null;
  listView?: boolean;
  maxWeightOption?: number | null;
  menuProduct?: MenuProduct | Recommendation;
  product?: Product | Recommendation;
  recommendation?: boolean;
  searchState: SearchState;
  setDisplayMode: (arg: DisplayMode) => void;
  store?: DeepReadonly<Store>;
  trackListViewClick?: () => void;
}

const defaultContext = {
  appMode: 'default',
  appliedWeightFilter: '',
  flags: {},
  carouselView: false,
  cartProduct: [],
  customerId: null,
  displayMode: DisplayMode.Product,
  listView: false,
  recommendation: false,
  searchState: {},
  setDisplayMode: () => {
    /**   */
  },
} as ProductCardContextProps;

export const ProductCardContext =
  createContext<ProductCardContextProps>(defaultContext);

export const ProductCardProvider = ({
  children,
  value,
}: {
  children: ReactNode;
  value: Partial<ProductCardContextProps>;
}) => {
  const [displayMode, setDisplayMode] = useState(DisplayMode.Product);
  const newBagFlag = useFlagHelper(FLAGS.newBagDrawer);

  return (
    <ProductCardContext.Provider
      value={{
        ...defaultContext,
        ...value,
        displayMode: value.displayMode || displayMode,
        setDisplayMode,
        flags: {
          [newBagFlag.name]: newBagFlag,
        },
      }}
    >
      {children}
    </ProductCardContext.Provider>
  );
};

export const useProductCardContext = () => {
  const context = useContext(ProductCardContext);

  if (context === undefined) {
    throw new Error(
      'useProductCardContext must be used within a ProductCardProvider'
    );
  }

  return context;
};
