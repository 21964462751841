import * as t from 'io-ts';

export const tDeliveryGeofenceV2 = t.interface({
  id: t.number,
  geojson: t.string,
  cart_minimum: t.string,
  fee: t.string,
  last_call_minutes: t.number,
  lead_time_minutes: t.number,
  name: t.union([t.string, t.null]),
});

export type DeliveryGeofenceV2 = t.TypeOf<typeof tDeliveryGeofenceV2>;
