import type { SearchResponse } from '@algolia/client-search';
import { useEffect, useState } from 'react';

import { getAlgoliaIndex } from '@jane/search/data-access';
import { buildMenuProductFilterString } from '@jane/search/util';
import type { Id, MenuProduct } from '@jane/shared/models';
import { deserializeCannabinoids } from '@jane/shared/util';

export const DEFAULT_HITS_PER_PAGE = 16;

export const useAlgoliaMenuProducts = ({
  productIds,
  storeId,
  hitsPerPage = DEFAULT_HITS_PER_PAGE,
}: {
  hitsPerPage?: number;
  productIds: number[];
  storeId: Id;
}): MenuProduct[] | undefined => {
  const [menuProducts, setMenuProducts] = useState<MenuProduct[]>();
  const filters = buildMenuProductFilterString(storeId, productIds);

  useEffect(() => {
    if (!productIds || productIds.length === 0) return;

    const index = getAlgoliaIndex('menu-products-');

    index
      .search('', {
        filters,
        hitsPerPage,
      })
      .then((searchResponse: SearchResponse) => {
        const hits = searchResponse.hits;
        const deserializedMenuProducts = hits.map((hit) =>
          deserializeCannabinoids(hit, 'menuProduct')
        );

        setMenuProducts(deserializedMenuProducts);
      })
      .catch((e) => {
        // do nothing
      });
  }, [productIds, storeId, hitsPerPage]);

  return menuProducts;
};
