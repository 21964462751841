import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const JaneNavBag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_3329_8146)">
      <path
        d="M23.3787 21.5155C23.6943 22.7778 22.7396 24.0006 21.4384 24.0006H2.56155C1.26041 24.0006 0.305694 22.7778 0.621268 21.5155L1.94029 16.2395C1.97995 16.0808 2 15.9179 2 15.7544V7.00061C2 5.89604 2.89543 5.00061 4 5.00061H19.7949C20.8818 5.00061 21.7697 5.86859 21.7944 6.95517L21.9949 15.7775C21.9983 15.9258 22.0182 16.0732 22.0541 16.2171L23.3787 21.5155Z"
        fill="#5E1FF0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.00061C10.3423 2.00061 9 3.3429 9 5.00061H7C7 2.23833 9.23772 0.000610352 12 0.000610352C14.7623 0.000610352 17 2.23833 17 5.00061H15C15 3.34289 13.6577 2.00061 12 2.00061Z"
        fill="#5E1FF0"
      />
    </g>
    <defs>
      <clipPath id="clip0_3329_8146">
        <rect
          width={24}
          height={24}
          fill="white"
          transform="translate(0 0.000610352)"
        />
      </clipPath>
    </defs>
  </svg>
);
export const JaneNavBagIcon = ({ ...props }: DynamicIconProps) => {
  return <Icon icon={JaneNavBag} {...props} color={props.color} />;
};
