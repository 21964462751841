import { FlowerIcon, SativaIcon, TincturesIcon } from '@jane/reefer';

export const nestedFilterGroup = {
  key: 'Categories',
  label: 'Categories',
  nested: true,
  showInFilterBar: true,
  type: 'multiselect' as const,
  items: [
    {
      count: 30,
      icon: <FlowerIcon color="default" />,
      label: 'flower',
      subItems: [
        {
          count: 10,
          label: 'ground flower',
          value: 'ground flower',
        },
        {
          count: 10,
          label: 'indoor',
          value: 'indoor',
        },
        {
          count: 10,
          label: 'sun grown',
          value: 'sun grown',
        },
      ],
      value: 'flower',
    },
    {
      count: 10,
      icon: <TincturesIcon color="default" />,
      label: 'Tinctures',
      value: 'tinctures',
    },
  ],
};

export const filterGroup = {
  key: 'lineage',
  label: 'Lineage',
  showInFilterBar: true,
  type: 'multiselect' as const,
  items: [
    {
      count: 10,
      label: 'indica',
      value: 'indica',
    },
    {
      count: 10,
      icon: <SativaIcon color="ember" />,
      label: 'Sativa',
      value: 'Sativa',
    },
  ],
};
