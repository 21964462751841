import type { ReactNode } from 'react';

import { Typography, styled } from '@jane/reefer';

import { flex, spacing } from '../../style';

const RecommendationBadgeWrapper = styled.div<{ highlight: boolean }>(
  spacing({ py: 4 }),
  flex({ justifyContent: 'center' }),
  {
    width: '100%',
  },
  ({ theme, highlight }) => [
    {
      backgroundColor: highlight
        ? theme.colors.success.main
        : theme.colors.primary.main,
    },
  ]
);

export const RecommendationBadge: React.FC<{
  children?: ReactNode;
  highlight?: boolean;
}> = ({ children, highlight }) => (
  <RecommendationBadgeWrapper highlight={highlight}>
    <Typography color="text-inverse" variant="mini-bold" as="div">
      {children}
    </Typography>
  </RecommendationBadgeWrapper>
);
