import { config } from '@jane/shared/config';

const algoliaInsights = require('search-insights');

export default () => {
  algoliaInsights('init', {
    apiKey: config.algoliaApiKey,
    appId: config.algoliaAppId,
  });
};
