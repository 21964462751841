import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Hybrid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.76895 12.5068V4.00482H6.27695V7.42382H9.72195V4.00482H11.23V12.5068H9.72195V8.73682H6.27695V12.5068H4.76895Z"
      fill="#666666"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1.00482H4C2.34315 1.00482 1 2.34797 1 4.00482V12.0048C1 13.6617 2.34315 15.0048 4 15.0048H12C13.6569 15.0048 15 13.6617 15 12.0048V4.00482C15 2.34797 13.6569 1.00482 12 1.00482ZM4 0.00482178C1.79086 0.00482178 0 1.79568 0 4.00482V12.0048C0 14.214 1.79086 16.0048 4 16.0048H12C14.2091 16.0048 16 14.214 16 12.0048V4.00482C16 1.79568 14.2091 0.00482178 12 0.00482178H4Z"
      fill="#666666"
    />
  </svg>
);
export const HybridIcon = ({ ...props }: DynamicIconProps) => {
  return <Icon icon={Hybrid} {...props} color={props.color} />;
};
