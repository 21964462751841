import styled from '@emotion/styled';

import { mediaQueries, spacing } from '../../../styling';
import type { StyledPopoverContentProps } from '../popover.types';

export const StyledPopoverContent = styled.div<StyledPopoverContentProps>(
  {
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: '90vh',
    [mediaQueries.tablet('min')]: {
      maxHeight: '420px',
    },
  },
  ({ padding }) =>
    padding && {
      ...spacing({ p: 24 }),
      [mediaQueries.tablet('min')]: {
        ...spacing({ p: 16 }),
      },
    }
);
