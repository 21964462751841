import * as t from 'io-ts';

export const tStrongholdPaymentSource = t.interface({
  id: t.string,
  label: t.string,
  active: t.boolean,
  type: t.string,
  subtype: t.string,
  provider_name: t.string,
  last4: t.string,
});

export const tStrongholdCustomer = t.interface({
  id: t.string,
  first_name: t.string,
  last_name: t.string,
  email: t.union([t.string, t.null]),
  mobile: t.union([t.string, t.null]),
  country: t.string,
  state: t.union([t.string, t.null]),
  is_blocked: t.boolean,
  payment_sources: t.array(tStrongholdPaymentSource),
});

export const tStrongholdCustomerToken = t.interface({
  token: t.string,
  expiry: t.string,
});

export const tStrongholdCustomerResult = t.interface({
  customer: tStrongholdCustomer,
  token: tStrongholdCustomerToken,
});

export type StrongholdPaymentSource = t.TypeOf<typeof tStrongholdPaymentSource>;
export type StrongholdCustomer = t.TypeOf<typeof tStrongholdCustomerResult>;
