import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Sativa = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1.00482H4C2.34315 1.00482 1 2.34797 1 4.00482V12.0048C1 13.6617 2.34315 15.0048 4 15.0048H12C13.6569 15.0048 15 13.6617 15 12.0048V4.00482C15 2.34797 13.6569 1.00482 12 1.00482ZM4 0.00482178C1.79086 0.00482178 0 1.79568 0 4.00482V12.0048C0 14.214 1.79086 16.0048 4 16.0048H12C14.2091 16.0048 16 14.214 16 12.0048V4.00482C16 1.79568 14.2091 0.00482178 12 0.00482178H4Z"
      fill="#666666"
    />
    <path
      d="M8.02001 12.8188C7.44801 12.8188 6.89334 12.7105 6.35601 12.4938C5.82734 12.2772 5.35934 11.9695 4.95201 11.5708L5.83601 10.5438C6.13934 10.8298 6.48601 11.0638 6.87601 11.2458C7.26601 11.4192 7.65601 11.5058 8.04601 11.5058C8.53134 11.5058 8.89968 11.4062 9.15101 11.2068C9.40234 11.0075 9.52801 10.7432 9.52801 10.4138C9.52801 10.0585 9.40234 9.80284 9.15101 9.64684C8.90834 9.49084 8.59634 9.33051 8.21501 9.16584L7.04501 8.65884C6.76767 8.53751 6.49467 8.38151 6.22601 8.19084C5.96601 8.00017 5.74934 7.75751 5.57601 7.46284C5.41134 7.16818 5.32901 6.81284 5.32901 6.39684C5.32901 5.94617 5.45034 5.54317 5.69301 5.18784C5.94434 4.82384 6.28234 4.53784 6.70701 4.32984C7.14034 4.11318 7.63434 4.00484 8.18901 4.00484C8.68301 4.00484 9.15967 4.10451 9.61901 4.30384C10.0783 4.49451 10.4727 4.75451 10.802 5.08384L10.035 6.04584C9.76634 5.82051 9.48034 5.64284 9.17701 5.51284C8.88234 5.38284 8.55301 5.31784 8.18901 5.31784C7.79034 5.31784 7.46534 5.40884 7.21401 5.59084C6.97134 5.76417 6.85001 6.00684 6.85001 6.31884C6.85001 6.53551 6.91068 6.71751 7.03201 6.86484C7.16201 7.00351 7.33101 7.12484 7.53901 7.22884C7.74701 7.32418 7.96801 7.41951 8.20201 7.51484L9.35901 7.99584C9.86167 8.21251 10.2733 8.49851 10.594 8.85384C10.9147 9.20051 11.075 9.68151 11.075 10.2968C11.075 10.7562 10.9537 11.1765 10.711 11.5578C10.4683 11.9392 10.1173 12.2468 9.65801 12.4808C9.20734 12.7062 8.66134 12.8188 8.02001 12.8188Z"
      fill="#666666"
    />
  </svg>
);
export const SativaIcon = ({ ...props }: DynamicIconProps) => {
  return <Icon icon={Sativa} {...props} color={props.color} />;
};
