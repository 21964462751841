import * as t from 'io-ts';

import { tApplicabilityRules } from '@jane/shared/models';

const tMenuRowOptional = t.partial({
  applicability_rules: t.array(tApplicabilityRules),
  custom_display_name: t.union([t.string, t.null]),
  enabled: t.boolean,
  id: t.number,
  is_category: t.boolean,
  store_id: t.number,
});

const tMenuRowRequired = t.interface({
  ranking: t.number,
  row_type: t.string,
});

export const tMenuRow = t.intersection([tMenuRowOptional, tMenuRowRequired]);
export type MenuRow = t.TypeOf<typeof tMenuRow>;
