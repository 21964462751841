import jwtDecode from 'jwt-decode';

import {
  SessionStorage,
  convertToUnixMs,
  isBrowserStorageAvailable,
  msFromNow,
} from '@jane/shared/util';

import { AuthStorage } from './authStorage';

function storeJwt(token: string, headless: boolean) {
  if (headless && isBrowserStorageAvailable()) {
    SessionStorage.set('jwt', token);
    AuthStorage.remove('jwt');
  } else {
    AuthStorage.set('jwt', token);
  }
}

export function setJwt(jwt: string, headless = false) {
  if (jwt) {
    storeJwt(jwt, headless);
  }
}

export function getJwt() {
  const jwt = AuthStorage.get<string>('jwt');

  if (!jwt && isBrowserStorageAvailable()) {
    return SessionStorage.get('jwt');
  }

  return jwt;
}

export function removeJwt() {
  AuthStorage.remove('jwt');

  if (isBrowserStorageAvailable()) {
    SessionStorage.remove('jwt');
  }
}

export const parseToken = (token: string) =>
  token.includes('Bearer') ? token.split('Bearer ')[1] : token;

const isExpiredFrom = (token: string, timeFromNow: number) => {
  const parsedToken = parseToken(token);
  const { exp } = jwtDecode(parsedToken) as { exp: number };
  const expInMs = exp * 1000;
  const expiration = convertToUnixMs(expInMs);
  const fromNow = msFromNow(timeFromNow);
  return expiration.isSameOrAfter(fromNow);
};

export const willTokenExpireIn = (token: string, timeFromNowMs: number) => {
  const doesTokenExpireIn = isExpiredFrom(token, timeFromNowMs);
  return doesTokenExpireIn;
};

export const checkTokenExpired = (token: string) => {
  const hasTokenExpired = isExpiredFrom(token, 0);
  return hasTokenExpired;
};
