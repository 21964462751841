import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Bag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.99998 5C8.99998 3.34228 10.3423 2 12 2C13.6577 2 15 3.34228 15 5H8.99998ZM6.99998 5C6.99998 2.23772 9.2377 0 12 0C14.7623 0 17 2.23772 17 5H19.7949C20.8818 5 21.7697 5.86798 21.7944 6.95456L21.9949 15.7769C21.9983 15.9252 22.0181 16.0726 22.0541 16.2165L23.3787 21.5149C23.6943 22.7772 22.7396 24 21.4384 24H2.56153C1.26039 24 0.305674 22.7772 0.621247 21.5149L1.94026 16.2389C1.97992 16.0802 1.99998 15.9173 1.99998 15.7538V7C1.99998 5.89543 2.89541 5 3.99998 5H6.99998ZM21.4384 22H2.56153L3.88055 16.7239C3.95987 16.4066 3.99998 16.0808 3.99998 15.7538V7H19.7949L19.9954 15.8223C20.0022 16.1189 20.0419 16.4138 20.1138 16.7016L21.4384 22Z"
      fill="#0E0E0E"
    />
  </svg>
);
export const BagIcon = ({ ...props }: DynamicIconProps) => {
  return <Icon icon={Bag} {...props} color={props.color} />;
};
