import styled from '@emotion/styled';
import type { MouseEventHandler } from 'react';

import { spacing } from '../../../styling';

interface StyledTableRowProps {
  onClick?: MouseEventHandler<HTMLTableRowElement>;
  selected: boolean;
}

export const StyledTableRow = styled.tr<StyledTableRowProps>(
  {
    zIndex: 1,
    display: 'table-row',
    verticalAlign: 'middle',
    '& td:first-of-type, th:first-of-type': {
      ...spacing({ pl: 24 }),
    },
    '& td:last-of-type, th:last-of-type': {
      ...spacing({ pr: 24 }),
    },
  },
  ({ onClick, theme, selected }) => ({
    ...(onClick && {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.colors.grays.light,
        td: {
          boxShadow: 'none !important',
          backgroundColor: 'inherit',
        },
      },
    }),
    ...(selected && {
      td: { backgroundColor: theme.colors.grays.hover, outline: 'none' },
    }),
  })
);
