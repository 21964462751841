import styled from '@emotion/styled';

interface StyledLottieProps {
  height: number;
  width: number;
}

export const StyledLottie = styled.div<StyledLottieProps>(
  ({ height, width }) => ({
    margin: '0 auto',
    height: height,
    width: width,
  })
);
