import type { CSSObject } from '@emotion/react';
import styled from '@emotion/styled';

import type { TypographyProps } from '.';
import { mediaQueries, spacing } from '../../styling';
import type { ReeferTheme, TypographyVariant } from '../../types';
import { getColorValue } from '../../utils/componentStyles';

type StyleProps<T extends string = TypographyVariant> = TypographyProps<T>;

interface StylePropsWithTheme<T extends string = TypographyVariant>
  extends StyleProps<T> {
  theme: ReeferTheme;
}

const truncate = (truncateAt?: string): CSSObject => {
  return truncateAt
    ? {
        maxWidth: truncateAt,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }
    : {};
};

export const getStyles = <T extends string = TypographyVariant>({
  theme,
  variant,
  branded,
  color,
  strikeThrough,
  textAlign,
  truncateAt,
  ...marginProps
}: StylePropsWithTheme<T>) => {
  const { fonts, variants } = theme.components.Typography;
  const variantStyles = variants[variant || 'body'];
  const {
    desktopStyles,
    mobileStyles,
    branded: originalBranded,
    ...sharedStyles
  } = variantStyles;

  const isBranded = branded !== undefined ? branded : originalBranded;

  const fontFamily = isBranded
    ? fonts.branded.fontFamily
    : fonts.default.fontFamily;

  const styles: CSSObject = {
    color: getColorValue(color, theme),
    fontFamily: `${fontFamily}, Helvetica, Arial, sans-serif`,
    ...spacing(marginProps),
    ...truncate(truncateAt),
    ...sharedStyles,
    ...mobileStyles,
    [mediaQueries.desktop('sm', 'min')]: desktopStyles,
  };

  if (strikeThrough) {
    styles.textDecoration = 'line-through';
  }

  if (textAlign) {
    styles.textAlign = textAlign;
  }

  return styles;
};

export const InnerStyledTypography = (<
  T extends string = TypographyVariant
>() => styled.div<StyleProps<T>>((props) => getStyles(props)))();

export const StyledTypography = <T extends string = TypographyVariant>({
  variant,
  ...rest
}: TypographyProps<T>) => (
  <InnerStyledTypography variant={variant as TypographyVariant} {...rest} />
);
