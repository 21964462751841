import isEmpty from 'lodash/isEmpty';
import type { Reducer } from 'redux';

import type { DeepReadonly } from '@jane/shared/models';

import { createStandardAction } from '../../redux-util';
import type { CustomerAction } from './types';

export type KioskCheckout =
  | 'anonymous'
  | 'customer_name'
  | 'customer_phone'
  | 'redirect_link';

export const SET_KIOSK_CHECKOUT = 'kiosk-checkout/set-kiosk-checkout';
export const setKioskCheckout =
  createStandardAction(SET_KIOSK_CHECKOUT)<string>();

export type KioskCheckoutActions = ReturnType<typeof setKioskCheckout>;

export type KioskCheckoutState = DeepReadonly<{
  kioskCheckout: {
    anonymous?: string;
    customer_name?: string | undefined;
    customer_phone?: string | undefined;
    redirect_link?: string | undefined;
  };
  queryParamsPresent: boolean;
}>;

const getInitialState = (): KioskCheckoutState => ({
  kioskCheckout: {
    anonymous: undefined,
    customer_name: undefined,
    customer_phone: undefined,
    redirect_link: undefined,
  },
  queryParamsPresent: false,
});

const PERMISSIBLE_PARAMS: KioskCheckout[] = [
  'anonymous',
  'customer_name',
  'customer_phone',
  'redirect_link',
];

const isPermissibleParam = (param: string): param is KioskCheckout =>
  PERMISSIBLE_PARAMS.includes(param as any);

export const kioskCheckoutReducer: Reducer<
  KioskCheckoutState,
  CustomerAction
> = (state = getInitialState(), action) => {
  switch (action.type) {
    case SET_KIOSK_CHECKOUT: {
      const query = action.payload;
      const urlParams = new URLSearchParams(query);
      const queryObject: {
        [key in KioskCheckout]?: string;
      } = {};

      urlParams.forEach((value, key) => {
        if (isPermissibleParam(key)) {
          queryObject[key] = decodeURIComponent(value);
        }
      });

      return {
        ...state,
        kioskCheckout: queryObject,
        queryParamsPresent: !isEmpty(queryObject),
      };
    }
  }

  return state;
};
