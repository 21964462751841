import { useState } from 'react';

import { Modal, SearchField, useMobileMediaQuery } from '@jane/reefer';

import type { FilterSelectItem } from '../filter.types';
import { DeselectAllButton } from './deselectAllButton';
import type { FilterModalTypeaheadProps } from './filterModal.types';
import { TypeaheadList } from './typeaheadList';

export const FilterModalTypeahead = ({
  closeTypeahead,
  filterGroup,
  isPartnerMenu,
  onChange,
  onDeselect,
}: FilterModalTypeaheadProps) => {
  const { label, items, key } = filterGroup;
  const [query, setQuery] = useState('');
  const isMobile = useMobileMediaQuery({});

  const changeHandler = (value: string) => {
    setQuery(value);
  };

  return (
    <>
      <Modal.Header
        actions={<DeselectAllButton onClick={() => onDeselect(key)} />}
        branded={!isPartnerMenu}
        children={
          <SearchField
            autoFocus
            label={`Search ${label}`}
            name="typeahead search"
            defaultValue={query}
            onChange={changeHandler}
            disableMobileInputStyling
            width="100%"
            mt={isMobile ? 32 : 20}
          />
        }
        title={label}
        textAlign="center"
        nested
        onIconClick={closeTypeahead}
      />

      <Modal.Content padding={false} testId="filter-modal-typeahead">
        <TypeaheadList
          filterKey={filterGroup.key}
          items={items as FilterSelectItem[]}
          onChange={onChange}
          query={query}
        />
      </Modal.Content>
    </>
  );
};
