import get from 'lodash/get';

import type { SearchState } from '@jane/search/types';
import { config } from '@jane/shared/config';
import type { ProductWeight } from '@jane/shared/models';

const { algoliaEnv } = config;

export const PRODUCTS_DEFAULT = `products-${algoliaEnv}`;
export const PRODUCTS_BY_RATING = `products-by-rating-${algoliaEnv}`;

export const MENU_PRODUCTS_DEFAULT = `menu-products-${algoliaEnv}`;
export const MENU_PRODUCTS_BY_RATING = `menu-products-by-rating-${algoliaEnv}`;
export const MENU_PRODUCTS_BY_PRICE_ASC = `menu-products-by-price-${algoliaEnv}`;
export const MENU_PRODUCTS_BY_PRICE_DESC = `menu-products-by-price-desc-${algoliaEnv}`;
export const MENU_PRODUCTS_BY_POTENCY_ASC = `menu-products-by-thc-potency-asc-${algoliaEnv}`;
export const MENU_PRODUCTS_BY_POTENCY_DESC = `menu-products-by-thc-potency-desc-${algoliaEnv}`;
export const MENU_PRODUCTS_BY_SPECIAL_REVIEW = `menu-products-by-special-review-${algoliaEnv}`;

export const STORE_DEFAULT = `stores-${algoliaEnv}`;
export const BRAND_PARTNER_STORES = `brand-partner-stores-${algoliaEnv}`;

export const indexNameWeightSortedByPrice = (
  weightType: ProductWeight,
  desc: boolean
) =>
  desc
    ? `menu-products-by-price-${weightType}-desc-${algoliaEnv}`
    : `menu-products-by-price-${weightType}-${algoliaEnv}`;

const currentWeightFilter = (searchState: SearchState) => {
  const weightFilterVal = get(
    searchState,
    'refinementList.available_weights.0'
  );
  return weightFilterVal ? weightFilterVal.replace(' ', '-') : null;
};

const sortedByPriceIndexName = (weightIndex: ProductWeight, desc = false) =>
  weightIndex
    ? indexNameWeightSortedByPrice(weightIndex, desc)
    : desc
    ? MENU_PRODUCTS_BY_PRICE_DESC
    : MENU_PRODUCTS_BY_PRICE_ASC;

const isSortedBy = (searchState: SearchState, sortBy: string) =>
  (searchState?.sortBy || '').includes(sortBy);

export const determineSortByIndexName = (searchState: SearchState) => {
  const desc = isSortedBy(searchState, 'desc');

  if (isSortedBy(searchState, 'price')) {
    const newWeight =
      get(searchState, 'refinementList.available_weights.0', '').replace(
        ' ',
        '-'
      ) || currentWeightFilter(searchState);

    return sortedByPriceIndexName(newWeight, desc);
  }

  if (isSortedBy(searchState, 'thc')) {
    return desc ? MENU_PRODUCTS_BY_POTENCY_DESC : MENU_PRODUCTS_BY_POTENCY_ASC;
  }

  return searchState?.sortBy?.includes('rating')
    ? MENU_PRODUCTS_BY_RATING
    : MENU_PRODUCTS_DEFAULT;
};
