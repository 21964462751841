import { Button, Flex, Modal, Typography } from '@jane/reefer';

import { useCustomerSelector } from '../../customer';
import { isEmbeddedModeSelector } from '../../customer/selectors';

interface Props {
  onCancel: () => void;
  onReplaceCart: () => void;
}

export default function ReplaceCartContents({
  onCancel,
  onReplaceCart,
}: Props) {
  const isEmbeddedMode = useCustomerSelector(isEmbeddedModeSelector);

  return (
    <Modal.Content>
      <Flex width="100%" flexDirection="column" alignItems="center">
        <Typography mb={12} branded={!isEmbeddedMode} variant="title-bold">
          Start new bag?
        </Typography>
        <Typography variant="body">
          Jane supports checkout from only one store at a time. Would you like
          to clear your existing bag and add the item from this new store?
        </Typography>
        <Flex css={{ rowGap: 16 }} flexDirection="column" mt={24} width="100%">
          <Button
            branded={!isEmbeddedMode}
            full
            variant="primary"
            label="Add item"
            onClick={onReplaceCart}
          />
          <Button
            branded={!isEmbeddedMode}
            full
            variant="secondary"
            label="Cancel"
            onClick={onCancel}
          />
        </Flex>
      </Flex>
    </Modal.Content>
  );
}
