import { fold, getOrElse, right } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/pipeable';
import { useEffect } from 'react';

import type { Source } from '@jane/shared/models';
import { trackError } from '@jane/shared/util';

import type { Loadable, LoadableOrError } from '../lib/loadable';
import { responseToLoadable } from '../lib/loadable';
import { request } from '../lib/request';
import useSafeState from './useSafeState';

/**
 * Fetch a source from the server at mount time and manage it as state.
 */

interface Options {
  manual?: boolean;
  simple?: boolean /* https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS#simple_requests */;
  trackErrors?: boolean;
}

export const useSource = <T>(
  source: null | Source<T>,
  { trackErrors = true, manual = false, simple = false }: Options = {}
): Loadable<T> => {
  const initResponse: Loadable<T> = {
    loading: false,
    data: undefined,
    error: undefined,
    fetch: () => {},
  };

  const [response, setResponse] = useSafeState<LoadableOrError<T>>(
    right<Error, Loadable<T>>(initResponse)
  );

  useEffect(() => {
    if (!source) return;

    const fetcher = async (): Promise<void> => {
      setResponse((prev: LoadableOrError<T>) => {
        const prevResponse = pipe(
          prev,
          getOrElse(() => initResponse)
        );
        return right<Error, Loadable<T>>({ ...prevResponse, loading: true });
      });

      const { url, requireAuth = false, type } = source;

      try {
        const data = await request(
          url,
          { requireAuth, method: 'GET' },
          undefined,
          simple
        );

        setResponse(
          responseToLoadable({
            response: {
              loading: false,
              data,
              error: undefined,
              fetch: fetcher,
            },
            type,
            source: { url },
          })
        );
      } catch (error) {
        trackErrors && trackError(error as Error, { url });
        setResponse(
          right<Error, Loadable<T>>({
            // @ts-ignore
            error,
            loading: false,
            data: undefined,
            fetch: fetcher,
          })
        );
      }
    };

    if (manual) {
      setResponse((prev: LoadableOrError<T>) => {
        const prevResponse = pipe(
          prev,
          getOrElse(() => initResponse)
        );
        return right<Error, Loadable<T>>({ ...prevResponse, fetch: fetcher });
      });
      return;
    }

    fetcher();
  }, [manual, source && source.url]);

  return pipe(
    response,
    fold(
      (error) => {
        throw error;
      },
      (loadable) => loadable
    )
  );
};
