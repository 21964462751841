/** BRAND */
export const GOLD = '#FFC220';
export const TANGERINE = '#FF9900';
export const EMBER = '#FF5500';
export const SUNSET = '#CE349A';
export const GRAPE = '#8E00E6';
export const PURPLE = '#5E1FF0';

/** SYSTEM */
export const POSITIVE = '#94B200';
export const NEGATIVE = '#FF3333';

/** GRAYS */
export const WHITE = '#FFFFFF';
export const ULTRALIGHT = '#F7F7F7';
export const LIGHT = '#DADADA';
export const MID = '#666666';
export const DARK = '#262626';
export const BLACK = '#0E0E0E';
export const HOVER = '#0000001a';

/** TERTIARY */
export const EMERALD = '#1D5A53';
export const PALM = '#4BA279';
export const SEAFOAM = '#63B8D2';
export const PACIFIC = '#4485DA';
