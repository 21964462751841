import { Box, Flex, mediaQueries, spacing, styled } from '@jane/reefer';

export const NotificationBannerContainer = styled(Flex)(({ theme }) => ({
  backgroundColor: theme.colors.error.main,
  justifyContent: 'center',
}));

export const CartAlertsWrapper = styled(Flex)({
  ...spacing({ px: 8, pb: 16 }),
  [mediaQueries.tablet('min')]: {
    ...spacing({ px: 24 }),
  },
});

export const AlertsContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.colors.grays.white,
}));

export const NotificationBanner = styled(Flex)({
  ...spacing({ p: 16 }),
});
