import { Box, Flex, mediaQueries, spacing, styled } from '@jane/reefer';

import {
  DESKTOP_PADDING_HORIZONTAL,
  MOBILE_PADDING_HORIZONTAL,
  PADDING_VERTICAL,
} from '../pendingCartDrawer.styles';

export const ItemsWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.colors.grays.white,
  borderBottom: `1px solid ${theme.colors.grays.light}`,
  ...spacing({
    p: 8 /** 8px + the default 16px spacing for Card.Content = 24 */,
  }),
  [mediaQueries.tablet('min')]: {
    ...spacing({
      p: 24 /** 24px + the default 16px spacing for Card.Content = 40 */,
    }),
  },
}));

export const CartDrawerStatesWrapper = styled(Flex)(({ theme }) => ({
  backgroundColor: theme.colors.grays.white,
  height: '100%',
}));

export const FinePrintWrapper = styled(Flex)({
  backgroundColor: 'inherit',
  flexGrow: 1,
  ...spacing({ px: MOBILE_PADDING_HORIZONTAL, py: PADDING_VERTICAL }),
  [mediaQueries.tablet('min')]: {
    ...spacing({ px: DESKTOP_PADDING_HORIZONTAL }),
  },
});

export const CartReferenceContainer = styled.div<{ height: string }>(
  ({ theme, height }) => ({
    height,
    minHeight: '500px',
    [mediaQueries.tablet('min')]: {
      // When resizing itself, the size differences between states (specifically loading) was jarring.
      // This minHeight is approximately the height of a drawer with one item in it.
      minHeight: '800px',
    },
    backgroundColor: theme.colors.background,
  })
);
