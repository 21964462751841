import { useTheme } from '@jane/reefer';

import { SvgIcon } from '../../component-library/svg-icon';
import { Flex } from '../../style/layout';
import type { Spacing } from '../../style/spacing';

interface Props {
  large?: boolean;
  margin?: Spacing;
  rating: number;
  size?: number;
  small?: boolean;
}

const FIVE_STARS = [1, 2, 3, 4, 5];

export default ({ rating, small, large, size, margin, ...props }: Props) => {
  const theme = useTheme();

  const getIcon = (index: number) => {
    if (index > rating && index < rating + 1) {
      return 'starHalf';
    }
    return 'starFilled';
  };

  const getColor = (index: number) => {
    if (index < rating + 1) {
      return theme.colors.brand.gold.main;
    }
    return theme.colors.grays.ultralight;
  };

  return (
    <Flex alignItems="center" inline {...props}>
      {FIVE_STARS.map((starRating) => (
        <Flex key={starRating} mr={margin || 4}>
          <SvgIcon
            size={
              size
                ? { width: size, height: size }
                : large
                ? { width: 44, height: 44 }
                : small
                ? { width: 14, height: 14 }
                : { width: 18, height: 18 }
            }
            icon={getIcon(starRating)}
            color={getColor(starRating)}
          />
        </Flex>
      ))}
    </Flex>
  );
};
