import type { Id, MenuProduct } from '@jane/shared/models';

export const trackPriceExceptions = ({
  cartToppers,
  maxPrice,
  storeId,
  trackDmError,
}: {
  cartToppers: MenuProduct[];
  maxPrice: number;
  storeId: Id;
  trackDmError: (error: Error) => void;
}) => {
  cartToppers.forEach((cartTopper) => {
    trackPriceException({
      discounted: false,
      maxPrice,
      menuProduct: cartTopper,
      storeId,
      trackDmError,
    });
    trackPriceException({
      discounted: true,
      maxPrice,
      menuProduct: cartTopper,
      storeId,
      trackDmError,
    });
  });
};

const trackPriceException = ({
  discounted,
  maxPrice,
  menuProduct,
  storeId,
  trackDmError,
}: {
  discounted: boolean;
  maxPrice: number;
  menuProduct: MenuProduct;
  storeId: Id;
  trackDmError: (error: Error) => void;
}) => {
  const lowestPrice = getLowestPrice({ menuProduct, discounted });
  const productId = menuProduct.product_id;
  if (lowestPrice && lowestPrice > maxPrice) {
    trackDmError(
      new Error(
        buildPriceErrorMessage({
          discounted,
          lowestPrice,
          maxPrice,
          productId,
          storeId,
        })
      )
    );
  }
};

export const getLowestPrice = ({
  discounted,
  menuProduct,
}: {
  discounted: boolean;
  menuProduct: MenuProduct;
}) => {
  const each = menuProduct[`${discounted ? 'discounted_' : ''}price_each`];
  const eighth_ounce =
    menuProduct[`${discounted ? 'discounted_' : ''}price_eighth_ounce`];
  const gram = menuProduct[`${discounted ? 'discounted_' : ''}price_gram`];
  const half_gram =
    menuProduct[`${discounted ? 'discounted_' : ''}price_half_gram`];
  const half_ounce =
    menuProduct[`${discounted ? 'discounted_' : ''}price_half_ounce`];
  const ounce = menuProduct[`${discounted ? 'discounted_' : ''}price_ounce`];
  const quarter_ounce =
    menuProduct[`${discounted ? 'discounted_' : ''}price_quarter_ounce`];
  const two_gram =
    menuProduct[`${discounted ? 'discounted_' : ''}price_two_gram`];

  const sortedPrices = [
    each,
    half_gram,
    gram,
    two_gram,
    eighth_ounce,
    quarter_ounce,
    half_ounce,
    ounce,
  ]
    .filter((price) => price)
    .map((price) => parseFloat(price as string))
    .sort((a, b) => a - b);
  return sortedPrices[0];
};

const buildPriceErrorMessage = ({
  discounted = false,
  lowestPrice,
  maxPrice,
  productId,
  storeId,
}: {
  discounted?: boolean;
  lowestPrice: number;
  maxPrice: number;
  productId: number;
  storeId: Id;
}) =>
  `Cart topper has min ${
    discounted ? '' : 'non-'
  }discounted price > max_price_per_product: product_id=${productId}, lowestPrice=${lowestPrice}, maxPrice=${maxPrice}, storeId=${storeId}`;
