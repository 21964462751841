import { createContext, useEffect } from 'react';

import { StyledModal } from './modal.styles';
import type { ModalProps } from './modal.types';

/**
 * Modal Context to pass "variant" and "onRequestClose" to the subcomponents
 * when necessary
 */
export const ModalContext = createContext<
  Required<Pick<ModalProps, 'variant' | 'onRequestClose'>>
>({
  variant: 'standard',
  onRequestClose: () => {
    /* */
  },
});

/**
 * `Modal` component that allows for simple dialogue modals, complex modals
 * with headers and footers, or full-screen modals.
 */
export function Modal({
  appId = 'app',
  background,
  children,
  contentLabel,
  open,
  onRequestClose,
  overflow = 'hidden',
  overlayClose,
  testId,
  variant = 'standard',
  parentId = 'parent',
  parentSelector,
}: ModalProps) {
  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'unset';
  }, [open]);

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  if (variant === 'full-screen' && !background) {
    background = 'grays-ultralight';
  } else if (!background) {
    background = 'grays-white';
  }

  const handleClose = () => {
    document.body.style.overflow = 'unset';
    onRequestClose();
  };

  return (
    <ModalContext.Provider value={{ onRequestClose: handleClose, variant }}>
      <StyledModal
        appElement={document.getElementById(appId) as HTMLElement}
        background={background}
        contentLabel={contentLabel}
        data-testid={testId}
        onRequestClose={onRequestClose}
        open={open}
        overflow={overflow}
        shouldCloseOnOverlayClick={overlayClose ? true : false}
        variant={variant}
        id={parentId}
        parentSelector={parentSelector}
      >
        {children}
      </StyledModal>
    </ModalContext.Provider>
  );
}
