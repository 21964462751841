import styled from '@emotion/styled';

import { hidden, mediaQueries } from '../../../../styling';
import { Flex } from '../../../flex';
import { Typography } from '../../../typography';

export const StyledDoubleSliderFieldset = styled(Flex)({
  [mediaQueries.tablet('min')]: {
    flexDirection: 'column',
  },
});

export interface StyledDoubleSliderLegend {
  labelHidden: boolean;
}

export const StyledDoubleSliderLegend = styled(
  Typography
)<StyledDoubleSliderLegend>(
  {
    [mediaQueries.tablet('min')]: {
      '+ p': {
        order: 3,
      },
    },
  },
  ({ labelHidden }) => ({
    ...hidden(labelHidden),
    [mediaQueries.tablet('max')]: {
      '+ p': {
        marginTop: labelHidden ? '16px' : undefined,
        order: labelHidden ? 3 : 'initial',
      },
    },
  })
);

export const StyledDoubleSliderContainer = styled.div({
  position: 'relative',
  width: '100%',
});

export interface StyledDoubleSliderProps {
  direction: 'left' | 'right';
}

export const StyledDoubleSlider = styled.div<StyledDoubleSliderProps>(
  ({ direction }) => {
    if (direction === 'right') {
      return {
        position: 'absolute',
        top: '16px',
        width: '100%',
      };
    }
    return {
      width: '100%',
    };
  }
);
