import type { LDSingleKindContext } from 'launchdarkly-js-client-sdk';

export interface FlagUser {
  authenticated: boolean;
  email?: string;
  firstName?: string;
  id?: number;
  lastName?: string;
}

export const getFlagUser = (
  janeDeviceId: string,
  user?: FlagUser
): LDSingleKindContext => {
  const flagUser = { key: janeDeviceId, anonymous: true, kind: 'user' };

  if (user?.id) {
    return {
      anonymous: false,
      key: user?.id.toString(),
      kind: 'user',
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
    };
  }
  return flagUser;
};
