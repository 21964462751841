import { Button } from '@jane/reefer';

import { useActiveFilterContext } from './activeFilterProvider';
import type { FilterGroup, FilterSelectItem } from './filter.types';

export interface FilterBarButtonsProps {
  /** Filter group to render */
  filterGroup: FilterGroup;

  /** Action when filter is changed */
  onChange: (key: string, label: string) => void;
}
export const FilterBarButtons = ({
  filterGroup,
  onChange,
}: FilterBarButtonsProps) => {
  const singleSelectItems = filterGroup.items as FilterSelectItem[];
  const { activeFilters } = useActiveFilterContext();

  return (
    <>
      {singleSelectItems.map((item) => {
        const { label, count, value } = item;

        const renderedLabel = `${label} (${count || 0})`;

        const isSelected = activeFilters.some(
          (filter) => filter.value === value
        );
        return (
          <div key={label}>
            <Button
              label={renderedLabel}
              onClick={() => onChange(filterGroup.key, value as string)}
              variant={isSelected ? 'tertiary-selected' : 'tertiary'}
            />
          </div>
        );
      })}
    </>
  );
};
