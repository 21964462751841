import type { Reducer } from 'redux';

import type { DeepReadonly, Id } from '@jane/shared/models';

import type { CustomerAction } from './types';
import * as UsersActions from './users';

export type ReservationReviewState = DeepReadonly<{
  creatingReview: boolean;
  hasCreatedReview: boolean;
  rating: undefined | number;
  reservationId: undefined | Id;
  storeName: undefined | string;
}>;

const getInitialState = (): ReservationReviewState => ({
  creatingReview: false,
  hasCreatedReview: false,
  rating: undefined,
  reservationId: undefined,
  storeName: undefined,
});

export const reservationReviewReducer: Reducer<
  ReservationReviewState,
  CustomerAction
> = (state = getInitialState(), action) => {
  switch (action.type) {
    case UsersActions.CREATE_REVIEW:
      return { ...state, creatingReview: true, hasCreatedReview: false };

    case UsersActions.CREATE_REVIEW_SUCCESS:
      return { ...state, creatingReview: false, hasCreatedReview: true };

    case UsersActions.CREATE_REVIEW_ERROR:
      return { ...state, creatingReview: false };

    case UsersActions.SET_RESERVATION_RATING:
      return {
        ...state,
        rating: action.payload.rating,
        reservationId: action.payload.reservationId,
        storeName: action.payload.storeName,
      };
  }

  return state;
};
