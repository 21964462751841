import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const DismissMini = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.2929 13.7071C12.6834 14.0976 13.3166 14.0976 13.7071 13.7071C14.0976 13.3166 14.0976 12.6834 13.7071 12.2929L9.41423 8.00002L13.7071 3.70714C14.0976 3.31661 14.0976 2.68345 13.7071 2.29293C13.3166 1.9024 12.6834 1.9024 12.2929 2.29293L8.00002 6.5858L3.70711 2.29289C3.31658 1.90237 2.68342 1.90237 2.29289 2.29289C1.90237 2.68342 1.90237 3.31658 2.29289 3.70711L6.5858 8.00002L2.29289 12.2929C1.90237 12.6835 1.90237 13.3166 2.29289 13.7071C2.68342 14.0977 3.31658 14.0977 3.70711 13.7071L8.00002 9.41423L12.2929 13.7071Z"
      fill="#0E0E0E"
    />
  </svg>
);
export const DismissMiniIcon = ({ ...props }: DynamicIconProps) => {
  return <Icon icon={DismissMini} {...props} color={props.color} />;
};
