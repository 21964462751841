import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Vape = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.95752 13.2931C3.88669 12.7116 4.00005 12.1912 4.00005 12C4.00005 11.8091 3.88718 11.2906 2.96357 10.7106C2.92049 10.6845 2.87808 10.6565 2.83643 10.6267C2.82985 10.622 2.82331 10.6173 2.81681 10.6125C1.06629 9.33987 4.80197e-07 7.5144 5.68248e-07 5.50002C6.56456e-07 3.48205 1.07011 1.65365 2.8262 0.380704C3.72052 -0.267569 4.97105 -0.0681041 5.61932 0.82622C6.26759 1.72055 6.06813 2.97107 5.1738 3.61934C4.26323 4.27939 4 4.98449 4 5.50002C4 6.00618 4.25375 6.6951 5.12482 7.34469C6.81366 8.41762 8.00005 10.0472 8.00005 12C8.00005 13.9524 6.8141 15.5817 5.12576 16.6547C4.25393 17.3045 4 17.9937 4 18.5C4 19.0155 4.26323 19.7207 5.1738 20.3807C6.06812 21.029 6.26759 22.2795 5.61932 23.1738C4.97104 24.0681 3.72052 24.2676 2.8262 23.6193C1.0701 22.3464 -8.82085e-08 20.518 0 18.5C8.78553e-08 16.4901 1.06156 14.6683 2.80514 13.396C2.83423 13.3744 2.86407 13.3534 2.89463 13.3332C2.91543 13.3194 2.9364 13.306 2.95752 13.2931Z"
      fill="#CE349A"
    />
    <path
      d="M10.9575 13.2931C11.8867 12.7116 12 12.1912 12 12C12 11.8091 11.8872 11.2906 10.9636 10.7106C10.9205 10.6845 10.8781 10.6565 10.8364 10.6267C10.8299 10.622 10.8233 10.6173 10.8168 10.6125C9.06629 9.33987 8 7.5144 8 5.50002C8 3.48205 9.07011 1.65365 10.8262 0.380704C11.7205 -0.267569 12.971 -0.0681041 13.6193 0.82622C14.2676 1.72055 14.0681 2.97107 13.1738 3.61934C12.2632 4.27939 12 4.98449 12 5.50002C12 6.00618 12.2537 6.6951 13.1248 7.34469C14.8137 8.41762 16 10.0472 16 12C16 13.9524 14.8141 15.5817 13.1258 16.6547C12.2539 17.3045 12 17.9937 12 18.5C12 19.0155 12.2632 19.7207 13.1738 20.3807C14.0681 21.029 14.2676 22.2795 13.6193 23.1738C12.971 24.0681 11.7205 24.2676 10.8262 23.6193C9.0701 22.3464 8 20.518 8 18.5C8 16.4901 9.06156 14.6683 10.8051 13.396C10.8342 13.3744 10.8641 13.3534 10.8946 13.3332C10.9154 13.3194 10.9364 13.306 10.9575 13.2931Z"
      fill="#CE349A"
    />
    <path
      d="M18.9575 13.2931C19.8867 12.7116 20 12.1912 20 12C20 11.8091 19.8872 11.2906 18.9636 10.7106C18.9205 10.6845 18.8781 10.6565 18.8364 10.6267C18.8299 10.622 18.8233 10.6173 18.8168 10.6125C17.0663 9.33987 16 7.5144 16 5.50002C16 3.48205 17.0701 1.65365 18.8262 0.380704C19.7205 -0.267569 20.971 -0.0681041 21.6193 0.82622C22.2676 1.72055 22.0681 2.97107 21.1738 3.61934C20.2632 4.27939 20 4.98449 20 5.50002C20 6.00618 20.2537 6.6951 21.1248 7.34469C22.8137 8.41762 24 10.0472 24 12C24 13.9524 22.8141 15.5817 21.1258 16.6547C20.2539 17.3045 20 17.9937 20 18.5C20 19.0155 20.2632 19.7207 21.1738 20.3807C22.0681 21.029 22.2676 22.2795 21.6193 23.1738C20.971 24.0681 19.7205 24.2676 18.8262 23.6193C17.0701 22.3464 16 20.518 16 18.5C16 16.4901 17.0616 14.6683 18.8051 13.396C18.8342 13.3744 18.8641 13.3534 18.8946 13.3332C18.9154 13.3194 18.9364 13.306 18.9575 13.2931Z"
      fill="#CE349A"
    />
  </svg>
);
export const VapeIcon = ({ ...props }: DynamicIconProps) => {
  return <Icon icon={Vape} {...props} color={props.color} />;
};
