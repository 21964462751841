import styled from '@emotion/styled';

export const StyledOrderedList = styled.ol(
  {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  {
    '& li': {
      listStyle: 'none',
    },
  }
);
