import { config } from '@jane/shared/config';

import { request } from '../lib/request';

export class IdentificationSource {
  static urlRoot = `${config.apiPath}/documents`;

  static get() {
    return request(this.urlRoot, {
      method: 'GET',
    });
  }

  static update(documentPart) {
    return request(this.urlRoot, {
      method: 'PATCH',
      body: JSON.stringify(documentPart),
    });
  }
}
