import { useContext } from 'react';

import type { MarginProperties } from '../../../styling';
import type { BorderRadiusKeys } from '../../../types';
import { SkeletonContext } from '../skeleton';
import { SkeletonBoneFill, StyledSkeletonBone } from './skeletonBone.styles';

export interface SkeletonBoneProps extends MarginProperties {
  /** Determines the borderRadius of the element, defaults to `lg`. NOTE: `circular` requires equal height and width */
  borderRadius?: BorderRadiusKeys | 'circular';

  /** Determines the height of the element, accepts any valid height property */
  height?: string | number;

  /** Determines the width of the element, accepts any valid width property */
  width?: string | number;
}

export function SkeletonBone({
  borderRadius = 'lg',
  height = '24px',
  width = '100%',
  ...marginProps
}: SkeletonBoneProps) {
  const { animate } = useContext(SkeletonContext);
  return (
    <StyledSkeletonBone
      role="presentation"
      borderRadius={borderRadius}
      height={height}
      width={width}
      {...marginProps}
    >
      <SkeletonBoneFill animate={animate} />
    </StyledSkeletonBone>
  );
}
