import type { Reducer } from 'redux';

import type { SearchState } from '@jane/search/types';

import { createSimpleAction, createStandardAction } from '../../redux-util';
import type { CustomerAction } from './types';

export const CHANGE_SEARCH_RADIUS = 'search/change-search-radius';
export const changeSearchRadius =
  createStandardAction(CHANGE_SEARCH_RADIUS)<
    SearchReduxState['searchRadius']
  >();

export const CHANGE_SEARCH_QUERY = 'search/change-search-query';
export const changeSearchQuery =
  createStandardAction(CHANGE_SEARCH_QUERY)<string>();

export const CLEAR_SEARCH_QUERY = 'search/clear-search-query';
export const clearSearchQuery = createSimpleAction(CLEAR_SEARCH_QUERY);

export const SEARCH_ALL_LOCATIONS = 'search/search-all-locations';
export const searchAllLocations = createSimpleAction(SEARCH_ALL_LOCATIONS);

export const SET_PRODUCT_DETAIL_BREADCRUMBS =
  'search/set-product-detail-breadcrumbs';
export const setProductDetailBreadcrumbs = createStandardAction(
  SET_PRODUCT_DETAIL_BREADCRUMBS
)<SearchState | undefined>();

export type SearchActions =
  | ReturnType<typeof changeSearchRadius>
  | ReturnType<typeof changeSearchQuery>
  | ReturnType<typeof clearSearchQuery>
  | ReturnType<typeof searchAllLocations>
  | ReturnType<typeof setProductDetailBreadcrumbs>;

export type SearchRadius = '5' | '10' | '15' | '20' | '25' | '30' | '35';

export type SearchReduxState = {
  searchBreadcrumbs?: SearchState;
  searchQuery: string;
  searchRadius: SearchRadius;
};

const getInitialState = (): SearchReduxState => ({
  searchQuery: '',
  searchRadius: '35',
  searchBreadcrumbs: undefined,
});

export const searchReducer: Reducer<SearchReduxState, CustomerAction> = (
  state = getInitialState(),
  action
) => {
  switch (action.type) {
    case CHANGE_SEARCH_RADIUS:
      return { ...state, searchRadius: action.payload };

    case CLEAR_SEARCH_QUERY:
    case SEARCH_ALL_LOCATIONS:
      return { ...state, searchQuery: '' };

    case CHANGE_SEARCH_QUERY:
      return { ...state, searchQuery: action.payload };

    case SET_PRODUCT_DETAIL_BREADCRUMBS:
      return { ...state, searchBreadcrumbs: action.payload };
  }

  return state;
};
