import qs from 'qs';

export const parseSearch = <
  T extends Record<string, any> = Record<string, any>
>(
  search: string
): T => qs.parse(search, { ignoreQueryPrefix: true }) as any;

export const encodeQuery = (base: string, query?: Record<string, any>) =>
  [base, query && qs.stringify(query)].filter(Boolean).join('?');
