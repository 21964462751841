import { useState } from 'react';

import { getBrandInitials } from '../../utils/getBrandInitials';
import { Typography } from '../typography/typography';
import defaultStoreImage from './assets/default-store-logo.svg';
import { StyledPartnerLogo } from './partnerLogo.styles';

export type PartnerLogoSizeType = 'sm' | 'lg' | 'xl';

export interface PartnerLogoProps {
  /** Path or url to logo image */
  image?: string | null;

  /** Store or Brand name, used for label and generating placeholder initials for the brand variant */
  name: string;

  /** Size of the icon: sm (64px), lg (96px) */
  size?: 'sm' | 'lg' | 'xl';

  /** Optional id for testing */
  testId?: string;

  /** Determines component styling. */
  variant: 'store' | 'brand';
}

/**
 * The `PartnerLogo` component is used to display a logo for our store and brand partners.
 */

export function PartnerLogo({
  image,
  name,
  size = 'lg',
  testId,
  variant = 'brand',
}: PartnerLogoProps) {
  const [hasLoadError, setHasLoadError] = useState(false);
  const handleError = () => setHasLoadError(true);
  const isBrand = variant === 'brand';

  const storeImage = image ? image : defaultStoreImage;
  const providedImage = !isBrand ? storeImage : image;
  const imageAccountingForError = hasLoadError
    ? !isBrand
      ? defaultStoreImage
      : null
    : providedImage;

  const renderLabelText = (name: string) => {
    if (!isBrand && !image) {
      return 'Placeholder image of storefront with cannabis leaf';
    }

    if (isBrand && !image) {
      return `Brand initials for ${name}`;
    }

    return `Logo for ${name}`;
  };

  const renderBrandInitials = () => {
    return (
      <Typography
        color="grays-black"
        variant={size === 'sm' ? 'header-bold' : 'title-bold'}
      >
        {getBrandInitials(name)}
      </Typography>
    );
  };

  return (
    <>
      {imageAccountingForError ? (
        <img
          alt=""
          aria-hidden
          style={{ display: 'none' }}
          src={imageAccountingForError}
          onError={handleError}
        />
      ) : null}
      <StyledPartnerLogo
        data-has-load-error={hasLoadError}
        image={imageAccountingForError}
        variant={variant}
        aria-label={renderLabelText(name)}
        role="img"
        size={size}
        data-testid={testId}
      >
        {!imageAccountingForError && isBrand && renderBrandInitials()}
      </StyledPartnerLogo>
    </>
  );
}
