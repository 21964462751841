import type { PriceType, Weight } from '@jane/shared-ecomm/util';
import { getMenuPrices } from '@jane/shared-ecomm/util';
import type {
  PendingCartProduct,
  ReservationCartProduct,
} from '@jane/shared/models';
import { formatCurrency } from '@jane/shared/util';

export const displayQuantity = (
  product: PendingCartProduct | ReservationCartProduct
) => {
  const price = getMenuPrices(product.type as PriceType).find(
    (label: Weight) => label.id === product.price_id
  );

  return (price && price.label) || '';
};

export const displayProductName = (
  product: PendingCartProduct | ReservationCartProduct
) => {
  const { brand, brand_subtype, name } = product;
  return `${name}${brand_subtype ? ' (' + brand_subtype + ')' : ''}${
    brand ? ' by ' + brand : ''
  }`;
};

export function receiptText(product: ReservationCartProduct) {
  const eachPrice = `${formatCurrency(product.discounted_checkout_price)}`;
  const displayPrice = formatCurrency(product.count * product.checkout_price);
  const productName = displayProductName(product);

  if (product.price_id !== 'each' && !product.amount) {
    return {
      displayQuantity: `${product.count} x ${displayQuantity(product)}`,
      eachPrice,
      displayPrice,
      productName,
    };
  }

  return {
    displayQuantity: `${product.count}`,
    eachPrice,
    displayPrice,
    productName,
  };
}
