import styled from '@emotion/styled';

import { mediaQueries, shadows, spacing } from '../../styling';
import type {
  PopoverAlignmentProps,
  PopoverContainerProps,
  StyledContentProps,
} from './popover.types';

// wraps target and content to connect the elements
export const PopoverContainer = styled.div<PopoverContainerProps>(
  ({ disableMobileStyling, targetWidth, ...marginProps }) => ({
    ...spacing(marginProps),
    width: targetWidth ? targetWidth : 'fit-content',
    position: disableMobileStyling ? 'relative' : 'initial',
    [mediaQueries.tablet('min')]: {
      position: 'relative',
    },
  })
);

interface PopoverTargetProps {
  openOn: 'hover' | 'click' | 'disabled';
}

export const StyledTarget = styled.span(
  ({ openOn }: PopoverTargetProps) => ({
    cursor: openOn === 'disabled' ? 'none' : 'pointer',
    ...(openOn === 'disabled' && { pointerEvents: 'none' }),
  }),
  ({ theme }) => ({
    display: 'block',
    outline: 'none',
    position: 'relative',
    [mediaQueries.tablet('min')]: {
      position: 'relative',
    },
    ':focus-visible': {
      '&:after': {
        position: 'absolute',
        content: `''`,
        display: 'inherit',
        top: '-4px',
        left: '-4px',
        bottom: '-4px',
        right: '-4px',
        border: `2px solid ${theme.colors.primary.main}`,
        borderRadius: `calc(${theme.borderRadius.lg} + 4px)`,
        backgroundColor: 'transparent',
      },
    },
  })
);

export const MobileOverlay = styled.div({
  zIndex: 10,
  width: '100%',
  height: '100vh',
  background: 'rgba(0,0,0,0.4)',
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  [mediaQueries.tablet('min')]: {
    display: 'none',
  },
});

// wraps the content on desktop, controls width and alignment
export const StyledAlignment = styled.div<PopoverAlignmentProps>(
  ({ alignment, variant }) => ({
    minWidth: variant === 'info' ? '80vw' : '240px',
    width: variant === 'info' ? '80vw' : 'auto',
    height: 'auto',
    background: 'transparent',
    position: 'absolute',
    bottom: 'unset',
    left: 'unset',
    right: 'unset',
    pointerEvents: 'auto',
    ...(alignment.vertical === 'top' && {
      bottom: '100%',
      marginTop: 0,
    }),
    ...(alignment.horizontal === 'left' && { left: 0 }),
    ...(alignment.horizontal === 'right' && { right: 0 }),
    ...(alignment.horizontal === 'center' && {
      left: '50%',
      transform: 'translate(-50%)',
    }),
    [mediaQueries.tablet('min')]: {
      minWidth: variant === 'info' ? '320px' : '240px',
      width: variant === 'info' ? '320px' : 'auto',
    },
  })
);

// the styling of the content, need margin applied here
// to keep a connecting line when openOn is set to hover
export const StyledContent = styled.div<StyledContentProps>(
  ({ alignment, disableMobileStyling, theme }) => ({
    backgroundColor: theme.colors.grays.white,
    boxShadow: shadows.hard,
    overflowY: 'hidden',
    position: 'relative',
    zIndex: 100,
    ...(disableMobileStyling
      ? {
          ...(alignment.vertical === 'top' && { marginBottom: '14px' }),
          ...(alignment.vertical === 'bottom' && { marginTop: '14px' }),
          borderRadius: theme.borderRadius.lg,
        }
      : {
          borderRadius: `${theme.borderRadius.lg} ${theme.borderRadius.lg} 0 0`,
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          maxHeight: '90vh',
          marginTop: 0,
          marginBottom: 0,
        }),
    [mediaQueries.tablet('min')]: {
      ...(alignment.vertical === 'top' && { marginBottom: '14px' }),
      ...(alignment.vertical === 'bottom' && { marginTop: '14px' }),
      borderRadius: theme.borderRadius.lg,
      bottom: 'unset',
      left: 'unset',
      right: 'unset',
      position: 'relative',
    },
  })
);
