import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const NotHigh = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={12} cy={12} r={12} fill="#DADADA" />
    <circle cx={8} cy={9} r={2} fill="black" fillOpacity={0.5} />
    <circle cx={16} cy={9} r={2} fill="black" fillOpacity={0.5} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 17H6V15H18V17Z"
      fill="black"
      fillOpacity={0.5}
    />
  </svg>
);
export const NotHighIcon = ({ ...props }: StaticIconProps) => {
  return <Icon icon={NotHigh} {...props} color={undefined} />;
};
