import { Box, Flex, useMobileMediaQuery } from '@jane/reefer';

import {
  CarouselSkeleton, // FilterSkeleton,
  MenuProductDetailMobile,
  ProductDetailDesktop,
  ProductDetailMobile,
  ProductGridSkeleton,
} from './skeletonComponents';

export const ProductSearchSkeleton = () => (
  <Flex justifyContent="center" width="100%">
    <Box width="100%" maxWidth="1440px">
      {/** NOTE: pending implementation of new filters */}
      {/* <FilterSkeleton /> */}
      <ProductGridSkeleton />
    </Box>
  </Flex>
);

export const StoreMenuSkeleton = () => (
  <Flex justifyContent="center" width="100%">
    <Box width="100%">
      {/** NOTE: pending implementation of new filters */}
      {/* <FilterSkeleton /> */}
      <CarouselSkeleton />
      <CarouselSkeleton />
      <CarouselSkeleton />
      <CarouselSkeleton />
    </Box>
  </Flex>
);

export const ProductDetailSkeleton = () => {
  const isMobile = useMobileMediaQuery({});

  return (
    <Flex justifyContent="center" width="100%">
      <Box width="100%" maxWidth="1440px">
        {isMobile ? <ProductDetailMobile /> : <ProductDetailDesktop />}
      </Box>
    </Flex>
  );
};

export const MenuProductDetailSkeleton = () => {
  const isMobile = useMobileMediaQuery({});

  return (
    <Flex justifyContent="center" width="100%">
      <Box width="100%" maxWidth="1440px">
        {isMobile ? <MenuProductDetailMobile /> : <ProductDetailDesktop />}
      </Box>
    </Flex>
  );
};
