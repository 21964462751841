import { forwardRef, useEffect, useState } from 'react';
import type { ChangeEvent, FocusEvent } from 'react';

import type { SingleSliderFieldProps } from '../field.types';
import { FieldWrapper } from '../fieldWrapper/fieldWrapper';
import {
  StyledInput,
  StyledSlider,
  StyledSliderSpacer,
} from './singleSliderField.styles';

export interface SingleSliderFieldPropsInternal extends SingleSliderFieldProps {
  helperTextId?: string;
  maxInternal?: number;
  minInternal?: number;
  onUpdate?: (value: number) => void;
  value?: number;
}

export const calculateDefaultValue = (
  min: number,
  max: number,
  step: number | 'any',
  defaultValueProp?: number
) => {
  const defaultValue =
    defaultValueProp !== undefined ? defaultValueProp - min : (max - min) / 2;
  if (typeof step === 'number') {
    let mod = defaultValue % step;
    mod = mod > step / 2 ? mod - step : mod;
    return defaultValue - mod + min;
  }
  return defaultValue + min;
};

export const SingleSliderFieldInternal = forwardRef<
  HTMLInputElement,
  SingleSliderFieldPropsInternal
>(
  (
    {
      autoFocus = false,
      children,
      defaultValue,
      direction = 'right',
      disabled = false,
      endUnit,
      helperText,
      helperTextId,
      label,
      labelHidden = false,
      max,
      min,
      minInternal: minInternalProp,
      maxInternal: maxInternalProp,
      name,
      onBlur,
      onChange,
      onUpdate,
      isDouble = true,
      step = 1,
      startUnit,
      testId,
      value: externalValue,
      variant = 'primary',
      width = 'auto',
      ...props
    },
    ref
  ) => {
    if (min > max) {
      throw Error('[Reefer/SliderField] min must be less than max.');
    }
    if (typeof step === 'number' && (max - min) % step !== 0) {
      throw Error(
        '[Reefer/SliderField] range must be evenly divisible by step.'
      );
    }

    const minInternal = minInternalProp ? minInternalProp : min;
    const maxInternal = maxInternalProp ? maxInternalProp : max;

    const [value, setValue] = useState(
      calculateDefaultValue(min, max, step, defaultValue).toString()
    );

    const parseNumber = Number.isInteger(step) ? parseInt : parseFloat;

    const percentageValue =
      direction === 'right'
        ? ((parseNumber(value) - min) / (max - min)) * 100
        : ((max - parseNumber(value)) / (max - min)) * 100;

    useEffect(() => {
      if (externalValue !== undefined && externalValue !== parseNumber(value)) {
        setValue(externalValue.toString());
        onUpdate && onUpdate(externalValue);
      }
    }, [externalValue, onUpdate, parseNumber, value]);

    const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
      const myValue = parseNumber(event.target.value);

      onBlur && onBlur(myValue);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      let myValue = parseNumber(event.target.value);
      if (myValue < minInternal) {
        myValue = minInternal;
      }

      if (myValue > maxInternal) {
        myValue = maxInternal;
      }

      setValue(myValue.toString());
      onChange && onChange(myValue);
    };

    return (
      <FieldWrapper
        disabled={disabled}
        helperText={
          !isDouble
            ? `${startUnit ? startUnit : ''}${value}${endUnit ? endUnit : ''}`
            : undefined
        }
        helperTextId={helperTextId}
        label={label}
        labelHidden={labelHidden}
        labelPosition="t"
        name={name}
        width={width}
        testId={testId}
        render={(renderProps) => (
          <>
            {(direction === 'left' || !isDouble) && (
              <StyledSliderSpacer role="presentation" />
            )}
            <StyledSlider
              direction={direction}
              role="presentation"
              isDouble={isDouble}
              variant={variant}
            />
            <StyledInput
              autoFocus={autoFocus}
              direction={direction}
              disabled={disabled}
              id={name}
              max={max}
              min={min}
              name={name}
              onBlur={handleBlur}
              onChange={handleChange}
              percentageValue={percentageValue}
              ref={ref}
              step={step}
              type="range"
              variant={variant}
              value={value}
              {...renderProps}
            />
            {(direction === 'left' || !isDouble) && (
              <StyledSliderSpacer role="presentation" />
            )}
          </>
        )}
        repositionMobileHelperText={!isDouble}
        {...props}
      >
        {children}
      </FieldWrapper>
    );
  }
);

export const SingleSliderField = forwardRef<
  HTMLInputElement,
  SingleSliderFieldProps
>((props, ref) => <SingleSliderFieldInternal ref={ref} {...props} />);
