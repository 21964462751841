import { useEffect } from 'react';

import { Button, DismissIcon } from '@jane/reefer';
import { inIframe, postMessageToIframeParent } from '@jane/shared-ecomm/util';

import {
  StyledButtonWrapper,
  StyledLightboxContent,
  StyledLightboxModal,
  StyledModalContent,
} from './lightbox.styles';
import type { LightboxProps } from './lightbox.types';

/**
 * Lightbox modal for product and brand images and videos, used in the Carousel
 */

export const Lightbox = ({
  appId = 'app',
  children,
  isOpen,
  onRequestClose,
  testId,
}: LightboxProps) => {
  const isInIframe = inIframe();

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';

      // resize iframe to 100vh height
      isInIframe && postMessageToIframeParent({ messageType: 'openModal' });
    } else {
      document.body.style.overflow = 'unset';

      // reset iframe height
      isInIframe && postMessageToIframeParent({ messageType: 'closeModal' });
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen, isInIframe]);

  return (
    <StyledLightboxModal
      appElement={document.getElementById(appId) as HTMLElement}
      data-testid={testId}
      onRequestClose={onRequestClose}
      open={isOpen}
      shouldCloseOnOverlayClick={true}
      contentLabel="lightbox"
    >
      <StyledButtonWrapper>
        <Button.Icon
          icon={<DismissIcon />}
          label="Close lightbox"
          variant="minimal-inverse"
          onClick={() => onRequestClose()}
        />
      </StyledButtonWrapper>
      <StyledModalContent>
        <StyledLightboxContent>{children}</StyledLightboxContent>
      </StyledModalContent>
    </StyledLightboxModal>
  );
};
