import {
  Box,
  Flex,
  Modal,
  SliderField,
  mediaQueries,
  spacing,
  styled,
} from '@jane/reefer';

export const StyledModal = styled(Modal)({});

export const AddressInputWrapper = styled(Flex)(({ theme }) => ({
  color: theme.colors.grays.mid,
  [mediaQueries.tablet('max')]: {
    ...spacing({ pt: 24 }),
  },
}));

export const StyledSlider = styled(SliderField)({
  input: {
    padding: 0,
  },
  p: {
    display: 'none',
  },
});

export const StyledButtonToggleButton = styled(Flex)({
  button: {
    pointerEvents: 'all',
  },
});

export const PreferencesWrapper = styled(Flex)(({ theme }) => ({
  display: 'inline-flex',
  button: {
    background: theme.colors.brand.purple.dark,
  },
}));

export const DismissIconContainer = styled(Box)({
  top: 16,
  left: 16,
});
