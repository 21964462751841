import { Typography } from '@jane/reefer';

import Modal from '../modal';
import GlobalSearchForm from './globalSearchForm';

const ProductSearchModal = () => (
  <Modal contentLabel="Product search" fixedBody>
    {(closeModal) => (
      <div className="modal-content">
        <Typography>
          Search by product name, flavors, effects, and categories.
        </Typography>
        <div className="product-search-modal__search">
          <GlobalSearchForm onSearch={closeModal} />
        </div>
      </div>
    )}
  </Modal>
);

export default ProductSearchModal;
