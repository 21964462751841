import type { DrawerFooterProps } from '../drawer.types';
import { StyledDrawerFooter } from './drawerFooter.styles';

export function DrawerFooter({
  children,
  noPadding = false,
}: DrawerFooterProps) {
  return (
    <StyledDrawerFooter noPadding={noPadding}>{children}</StyledDrawerFooter>
  );
}
