import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Hungry = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.653 1C22.212 1 22.653 1.46179 22.653 2.02074V21.8047C22.653 23.3443 20.9863 24.3065 19.653 23.5367L2.75316 13.7796C1.78839 13.2226 1.4583 11.9789 2.10551 11.0721C6.45756 4.97506 13.5911 1 21.653 1Z"
      fill="#FFC220"
    />
    <circle cx={17.153} cy={9.5} r={2.5} fill="#FF6334" />
    <circle cx={12.153} cy={14.5} r={2.5} fill="#FF6334" />
    <path
      d="M22.653 2.02073C22.653 1.46179 22.2119 1 21.653 1C13.591 1 6.4575 4.97506 2.10545 11.0721C1.45824 11.9789 1.78833 13.2226 2.7531 13.7796L4.46494 14.7679C7.95484 8.91813 14.3463 5 21.653 5C21.9883 5 22.3217 5.00825 22.653 5.02457V2.02073Z"
      fill="#FFC220"
    />
    <path
      d="M22.653 2.02073C22.653 1.46179 22.2119 1 21.653 1C13.591 1 6.4575 4.97506 2.10545 11.0721C1.45824 11.9789 1.78833 13.2226 2.7531 13.7796L4.46494 14.7679C7.95484 8.91813 14.3463 5 21.653 5C21.9883 5 22.3217 5.00825 22.653 5.02457V2.02073Z"
      fill="black"
      fillOpacity={0.2}
    />
    <circle cx={20.153} cy={16.5} r={2.5} fill="#FF6334" />
    <path
      d="M19.9831 23.665C20.6734 22.4693 20.2637 20.9403 19.068 20.25C17.8723 19.5596 16.3433 19.9693 15.653 21.165L19.9831 23.665Z"
      fill="#FF6334"
    />
  </svg>
);
export const HungryIcon = ({ ...props }: StaticIconProps) => {
  return <Icon icon={Hungry} {...props} color={undefined} />;
};
