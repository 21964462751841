import type { Reducer } from 'redux';

import type {
  DeepReadonly,
  Id,
  Reservation,
  ReviewableReservationType,
} from '@jane/shared/models';

import { createStandardAction } from '../../redux-util';
import { NotificationsService } from '../../services/notifications';
import { ReviewableReservationsSource } from '../../sources/reviewableReservations';
import type { CustomerThunkActionCreator } from '../redux';
import type { CustomerAction } from './types';
import * as UsersActions from './users';

export const GET_REVIEWABLE_RESERVATIONS =
  'customer/get-reviewable-reservations';
export const getReviewableReservations: CustomerThunkActionCreator =
  () => (dispatch) => {
    dispatch({ type: GET_REVIEWABLE_RESERVATIONS });
    ReviewableReservationsSource.get().then(
      (result) => dispatch(getSuccess(result)),
      (err) => dispatch(onError(err))
    );
  };

export const GET_SUCCESS = 'reviewable-reservations/get-success';
export const getSuccess = createStandardAction(GET_SUCCESS)<{
  reservations: ReviewableReservationType[];
}>();

export const REFUSE_REVIEWABLE_RESERVATION =
  'customer/refuse-reviewable-reservation';
export const refuseReviewableReservation: CustomerThunkActionCreator<Id> =
  (reservationId) => (dispatch) => {
    dispatch({ type: REFUSE_REVIEWABLE_RESERVATION });
    ReviewableReservationsSource.refuseReview(reservationId).then(
      (result) => dispatch(refuseReviewSuccess(result)),
      (err) => dispatch(onError(err))
    );
  };

export const REFUSE_REVIEW_SUCCESS =
  'reviewable-reservations/refuse-review-success';
export const refuseReviewSuccess = createStandardAction(REFUSE_REVIEW_SUCCESS)<{
  reservation: { id: Id; status: Reservation['status'] };
}>();

export const ON_ERROR = 'reviewable-reservations/on-error';
export const onError: CustomerThunkActionCreator<{
  message: string;
}> = (result) => (dispatch) => {
  NotificationsService.error(result.message);
  dispatch({ type: ON_ERROR });
  return true;
};

export type ReviewableReservationsActions =
  | { type: typeof GET_REVIEWABLE_RESERVATIONS }
  | { type: typeof REFUSE_REVIEWABLE_RESERVATION }
  | ReturnType<typeof refuseReviewSuccess>
  | { type: typeof ON_ERROR }
  | ReturnType<typeof getSuccess>;

export type ReviewableReservationsState = DeepReadonly<{
  isLoadingReviewableReservations: boolean;
  reservations: ReviewableReservationType[];
}>;

const getInitialState = (): ReviewableReservationsState => ({
  isLoadingReviewableReservations: false,
  reservations: [],
});

const removeReservation = (state: ReviewableReservationsState, id: Id) => ({
  ...state,
  reservations: state.reservations.filter((res) => res.id !== id),
});

export const reviewableReservationsReducer: Reducer<
  ReviewableReservationsState,
  CustomerAction
> = (state = getInitialState(), action) => {
  switch (action.type) {
    case UsersActions.CREATE_REVIEW_SUCCESS:
      return removeReservation(state, action.payload.reservationId);

    case ON_ERROR:
      return { ...state, isLoadingReviewableReservations: false };

    case GET_REVIEWABLE_RESERVATIONS:
      return { ...state, isLoadingReviewableReservations: true };

    case GET_SUCCESS:
      return {
        ...state,
        isLoadingReviewableReservations: false,
        reservations: action.payload.reservations,
      };

    case REFUSE_REVIEW_SUCCESS:
      return removeReservation(state, action.payload.reservation.id);
  }
  return state;
};
