import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import type { ChangeEvent, FocusEvent } from 'react';

import type { CheckboxFieldProps } from '../field.types';
import { FieldWrapper } from '../fieldWrapper/fieldWrapper';
import {
  StyledCheckbox,
  StyledCheckboxContainer,
  StyledInput,
} from './checkboxField.styles';

export interface CheckboxFieldPropsInternal extends CheckboxFieldProps {
  externalChecked?: boolean;
  onUpdate?: (value: boolean) => void;
}

export const CheckboxFieldInternal = forwardRef<
  HTMLInputElement,
  CheckboxFieldPropsInternal
>(
  (
    {
      autoFocus = false,
      checked: checkedProp,
      children,
      defaultChecked = false,
      disabled = false,
      helperText,
      indeterminate = false,
      label,
      labelHidden = false,
      name,
      onBlur,
      onChange,
      onChangeIndeterminate,
      onUpdate,
      testId,
      width = 'auto',
      externalChecked,
      ...props
    },
    forwardedRef
  ) => {
    const innerRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(
      forwardedRef,
      () => innerRef.current as HTMLInputElement
    );

    const [checked, setChecked] = useState(checkedProp || defaultChecked);

    const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
      setChecked(event.target.checked);
      onBlur && onBlur(event.target.checked);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      setChecked(event.target.checked);
      onChange && onChange(event.target.checked);
      event.stopPropagation();
    };

    /**
     * These `useEffect` hooks are used only for checbkox groups, where a parent checkbox
     * controls a set of child checkboxes.
     * The parent enters the indeterminate state when only some of its children are checked,
     * necessitating the addition of the first `useEffect` hook.
     * The children's checked state can be controlled by checking, or unchecking, the parent,
     * necessitating the addition of the second `useEffect` hook.
     */
    useEffect(() => {
      if (
        innerRef.current &&
        innerRef.current.indeterminate !== indeterminate
      ) {
        innerRef.current.indeterminate = indeterminate;
        onChangeIndeterminate && onChangeIndeterminate(indeterminate);
        innerRef.current.checked = indeterminate;
        setChecked(indeterminate);
      }
    }, [indeterminate, onChangeIndeterminate]);

    useEffect(() => {
      if (
        typeof checkedProp === 'boolean' &&
        checkedProp !== checked &&
        !indeterminate &&
        innerRef.current
      ) {
        innerRef.current.checked = checkedProp;
        setChecked(checkedProp);
        onChange && onChange(checkedProp);
      }
    }, [checkedProp, checked, indeterminate, onChange]);

    useEffect(() => {
      if (
        externalChecked !== undefined &&
        externalChecked !== checked &&
        checkedProp === undefined
      ) {
        setChecked(externalChecked);
        onUpdate && onUpdate(externalChecked);
      }
    }, [externalChecked, onUpdate, checked, checkedProp]);

    return (
      <FieldWrapper
        disabled={disabled}
        helperText={helperText}
        label={label}
        labelHidden={labelHidden}
        labelPosition="r"
        name={name}
        render={(renderProps) => (
          <StyledCheckboxContainer>
            <StyledInput
              autoFocus={autoFocus}
              checked={checked}
              disabled={disabled}
              id={name}
              name={name}
              onBlur={handleBlur}
              onChange={handleChange}
              ref={innerRef}
              type="checkbox"
              {...renderProps}
            />
            <StyledCheckbox role="presentation" />
          </StyledCheckboxContainer>
        )}
        testId={testId}
        width={width}
        {...props}
      >
        {children}
      </FieldWrapper>
    );
  }
);

/**
 * Checkbox fields allow users to choose an option, or not.
 *
 * Use this component *outside forms* for inputs of `type`:
 * `checkbox`
 *
 * **NOTE: DO NOT USE THIS COMPONENT WITHIN FORMS**
 *
 * *For a similar component for use within forms, see [`Form.CheckboxField`](/story/components-forms-form-textfield--default).*
 */
export const CheckboxField = forwardRef<HTMLInputElement, CheckboxFieldProps>(
  (props, ref) => <CheckboxFieldInternal ref={ref} {...props} />
);
