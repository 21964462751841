import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import algoliasearch from 'algoliasearch';
import once from 'lodash/once';

import type {
  AlgoliaConfig,
  SearchProps,
  SearchResponse,
} from '@jane/search/types';
import { fetchFromEnv } from '@jane/tools/node/env';

import {
  buildBooleanFilter,
  buildBucketFilter,
  buildFilter,
  buildRangeFilter,
  composeFilters,
} from './filters';

export const getAlgoliaConfig = (): AlgoliaConfig => ({
  algoliaApiKey: fetchFromEnv('NX_ALGOLIA_SEARCH_API_KEY'),
  algoliaAppId: fetchFromEnv('NX_ALGOLIA_APP_ID'),
  algoliaEnv: fetchFromEnv('NX_ALGOLIA_ENV'),
});

export const getAlgoliaClient = once(
  (config: AlgoliaConfig = getAlgoliaConfig()) => {
    return algoliasearch(config.algoliaAppId, config.algoliaApiKey);
  }
);

export function getAlgoliaIndex(indexPrefix: string) {
  const config = getAlgoliaConfig();
  const client = getAlgoliaClient(config);
  return client.initIndex(`${indexPrefix}${config.algoliaEnv}`);
}

export function useSearch<T>(params: SearchProps<T>) {
  return useQuery({
    enabled: true,
    queryFn: () => search<T>(params),
    queryKey: ['search response', JSON.stringify(params)],
  });
}

export function useInfiniteSearch<T>(params: SearchProps<T>) {
  return useInfiniteQuery({
    enabled: true,
    getNextPageParam: (lastPage: SearchResponse<T>) => {
      if (lastPage.page + 1 < lastPage.nbPages) {
        return lastPage.page + 1;
      }
      return undefined;
    },
    queryFn: ({ pageParam }: { pageParam?: number }) =>
      search<T>({ ...params, page: pageParam }),

    queryKey: ['infinite search response', JSON.stringify(params)],
  });
}

export async function search<T>({
  booleanFilters,
  bucketFilters,
  hitsPerPage,
  indexPrefix,
  filters,
  options,
  page,
  rangeFilters,
  searchText,
  facets,
  sortSuffix,
  staticFilters,
}: SearchProps<T>) {
  const prefix = sortSuffix ? `${indexPrefix}${sortSuffix}` : indexPrefix;

  const index = getAlgoliaIndex(prefix);

  const composedFilters = composeFilters(
    ...(staticFilters ? [staticFilters] : []),
    ...(filters
      ? Object.keys(filters).map((attribute) =>
          buildFilter(attribute, filters[attribute as keyof T])
        )
      : []),
    ...(rangeFilters
      ? Object.keys(rangeFilters).map((attribute) => {
          const value = rangeFilters[attribute as keyof T];

          return buildRangeFilter(attribute, value?.min, value?.max);
        })
      : []),
    ...(bucketFilters
      ? Object.keys(bucketFilters).map((attribute) => {
          const value = bucketFilters[attribute as keyof T];

          return buildBucketFilter(attribute, value);
        })
      : []),
    ...(booleanFilters
      ? Object.keys(booleanFilters).map((attribute) => {
          const value = booleanFilters[attribute as keyof T];

          return buildBooleanFilter(attribute, value);
        })
      : [])
  );

  let requestOptions: Parameters<typeof index.search>[1] = {
    filters: composedFilters,
    ...(hitsPerPage && { hitsPerPage: Number(hitsPerPage) }),
    ...(page && { page: Number(page) }),
    ...options,
  };

  if (facets && facets.length > 0) {
    requestOptions = {
      ...requestOptions,
      facets,
    };
  }

  return index
    .search<T>(searchText ?? '', requestOptions)
    .then((response) => ({ ...response, index: index.indexName }));
}
