import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Tinctures = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.92893 21.2682C1.02369 17.3859 1.02369 11.0916 4.92893 7.20932L10.59 1.58162C11.3701 0.806127 12.63 0.806128 13.41 1.58162L19.0711 7.20932C22.9763 11.0916 22.9763 17.3859 19.0711 21.2682C15.1658 25.1505 8.83418 25.1505 4.92893 21.2682Z"
      fill="#8E00E6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 15.1799C6.55228 15.1799 7 15.6276 7 16.1799C7 17.8367 8.34315 19.1799 10 19.1799C10.5523 19.1799 11 19.6276 11 20.1799C11 20.7322 10.5523 21.1799 10 21.1799C7.23858 21.1799 5 18.9413 5 16.1799C5 15.6276 5.44772 15.1799 6 15.1799Z"
      fill="black"
      fillOpacity={0.5}
      className="details"
    />
  </svg>
);
export const TincturesIcon = ({ ...props }: DynamicIconProps) => {
  return <Icon icon={Tinctures} {...props} color={props.color} />;
};
