import findLast from 'lodash/findLast';
import flatten from 'lodash/flatten';

import type {
  Address,
  DeliveryConstraints,
  ReservationMode,
  Store,
} from '@jane/shared/models';

import type { UnavailableSlots } from '../sources/unavailableSlots';
import type { JaneDate } from './date';
import { currentTime } from './date';
import type { WorkingDay } from './workingDay';

export interface ScheduleCheckout {
  deliveryAddress: Address | null;
  deliveryConstraints: DeliveryConstraints;
  reservationMode: ScheduleType;
}

export enum ScheduleType {
  Curbside = 'curbside',
  Delivery = 'delivery',
  Pickup = 'pickup',
  Retail = 'retail',
}

enum Day {
  Friday = 'friday',
  Monday = 'monday',
  Saturday = 'saturday',
  Sunday = 'sunday',
  Thursday = 'thursday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
}

const NEARLY_OPEN_OFFSET = 30;

export default class StoreSchedule {
  readonly allowFutureDayOrdering: boolean;
  readonly allowOffHoursOrdering: boolean;
  readonly maxLeadTimeMinutes: number;
  readonly minLeadTimeMinutes: number;
  readonly type: ScheduleType;
  readonly timeZoneIdentifier: string;
  readonly workingDays: WorkingDay[];

  constructor({
    allowFutureDayOrdering,
    allowOffHoursOrdering,
    maxLeadTimeMinutes,
    minLeadTimeMinutes,
    timeZoneIdentifier,
    type,
    workingDays,
  }: {
    allowFutureDayOrdering: boolean;
    allowOffHoursOrdering: boolean;
    maxLeadTimeMinutes: number;
    minLeadTimeMinutes: number;
    timeZoneIdentifier: string;
    type: ScheduleType;
    workingDays: WorkingDay[];
  }) {
    this.allowFutureDayOrdering = allowFutureDayOrdering;
    this.allowOffHoursOrdering = allowOffHoursOrdering;
    this.maxLeadTimeMinutes = maxLeadTimeMinutes;
    this.minLeadTimeMinutes = minLeadTimeMinutes;
    this.timeZoneIdentifier = timeZoneIdentifier;
    this.type = type;
    this.workingDays = workingDays;
  }

  get isAlwaysClosed() {
    return this.workingDays.every((workingDay) => workingDay.isClosedAllDay);
  }

  get willOpenLaterToday() {
    return this.today.willOpenLater(this.currentTime);
  }

  isCurrentlyAcceptingReservations(reservationMode: ReservationMode) {
    return this.today.isCurrentlyAcceptingReservations(
      this.currentTime,
      reservationMode,
      this.isCurrentlyOpen
    );
  }

  get isKioskCurrentlyAcceptingReservations() {
    return this.today.isCurrentlyAcceptingReservations(
      this.currentTime,
      'kiosk',
      this.isCurrentlyOpen
    );
  }

  get isCurrentlyOpen() {
    return !!this.findCurrentWorkingDay(this.currentTime);
  }

  // this duplicates the open_or_nearly_open logic from the backend
  // necessary evil in order to ensure the stores considered 'open' on the frontend match those from the backend
  get isNearlyOpen() {
    return !!this.findCurrentWorkingDay(
      this.currentTime.add(NEARLY_OPEN_OFFSET, 'minutes')
    );
  }

  get isCurrentlyClosed() {
    return !this.isCurrentlyOpen;
  }

  get currentTime() {
    return currentTime(this.timeZoneIdentifier);
  }

  get today() {
    const today =
      this.findCurrentWorkingDay(this.currentTime) ||
      this.findByName(this.todaysDayName);
    if (!today)
      throw new Error(
        `Could not find day in schedule for ${this.todaysDayName}`
      );
    return today;
  }

  get nextOpenDay() {
    if (this.isAlwaysClosed) return undefined;
    const todayIndex = this.workingDays.indexOf(this.today);
    const beforeDays = this.workingDays.slice(0, todayIndex + 1);
    const restOfWeek = this.workingDays.slice(
      todayIndex + 1,
      this.workingDays.length
    );

    return restOfWeek.concat(beforeDays).find((day) => day.isOpenPartOfTheDay);
  }

  findByName(dayName: Day) {
    return this.workingDays.find((day) => day.name === dayName);
  }

  findCurrentWorkingDay(time: JaneDate) {
    return findLast(this.workingDays, (day) => day.isTimeDuringDay(time));
  }

  get todaysDayName() {
    return this.currentTime.format('dddd').toLowerCase() as Day;
  }

  get latestFulfillmentTime() {
    const earlierTime = (timeA: JaneDate, timeB: JaneDate) =>
      timeA.isSameOrBefore(timeB) ? timeA : timeB;

    const latestFromLeadTime = this.currentTime
      .clone()
      .add(this.maxLeadTimeMinutes, 'minutes');
    const todayClosingTime = this.today.closingTime;

    if (!this.isCurrentlyOpen && !this.allowOffHoursOrdering) {
      return null;
    }

    if (this.allowFutureDayOrdering) {
      return latestFromLeadTime;
    }

    if (this.isCurrentlyOpen || this.willOpenLaterToday) {
      return earlierTime(latestFromLeadTime, todayClosingTime);
    }

    return earlierTime(latestFromLeadTime, this.nextOpenDay.closingTime);
  }

  fulfillmentSlots(
    checkout: ScheduleCheckout,
    store: Store,
    unavailableSlots: UnavailableSlots | null
  ) {
    const latestTime = this.latestFulfillmentTime;

    if (!latestTime) {
      return [];
    }

    interface DayIndexMap {
      [key: number]: number;
    }
    const dayIndexMap: DayIndexMap = {};
    this.workingDays.forEach(
      (day, index) => (dayIndexMap[day.dayIndex] = index)
    );

    return flatten(
      this.workingDays
        .filter(
          (day) =>
            day.isOpenPartOfTheDay &&
            day.openingTime &&
            day.openingTime.isBefore(latestTime)
        )
        .map((day) => {
          const dayIndex = dayIndexMap[day.dayIndex];
          return day.fulfillmentSlots({
            currentTime: this.currentTime,
            checkout,
            store,
            unavailableSlots: unavailableSlots && unavailableSlots[dayIndex],
            isStoreCurrentlyOpen: this.isCurrentlyOpen,
          });
        })
    );
  }

  clone() {
    return new StoreSchedule({
      allowFutureDayOrdering: this.allowFutureDayOrdering,
      allowOffHoursOrdering: this.allowOffHoursOrdering,
      maxLeadTimeMinutes: this.maxLeadTimeMinutes,
      minLeadTimeMinutes: this.minLeadTimeMinutes,
      timeZoneIdentifier: this.timeZoneIdentifier,
      type: this.type,
      workingDays: this.workingDays.map((d) => d.clone()),
    });
  }
}
