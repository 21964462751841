import type { Color } from '../types/colors';

const numToHex = (c: number) => {
  c = Math.round(c);
  if (c < 0) c = 0;
  if (c > 255) c = 255;

  let s = c.toString(16);
  if (s.length < 2) s = `0${s}`;

  return s;
};

export const rgbToHex = (r: number, g: number, b: number) => {
  return `#${numToHex(r)}${numToHex(g)}${numToHex(b)}`;
};

export const hexToRgb = (color: Color) => {
  color = color?.toString() as string;
  const r = parseInt(color.substring(1, 3), 16);
  const g = parseInt(color.substring(3, 5), 16);
  const b = parseInt(color.substring(5, 7), 16);
  return [r, g, b];
};

/**
 * @param color - Hex code string
 * @param percent - Decimal value beteween 0 & 1
 * @returns New Hex code string
 */
export const lighten = (color: Color, percent: number) => {
  let [r, g, b] = hexToRgb(color);

  r = (1 - percent) * r + percent * 255;
  g = (1 - percent) * g + percent * 255;
  b = (1 - percent) * b + percent * 255;

  return rgbToHex(r, g, b);
};

/**
 * @param color - Hex code string
 * @param percent - Decimal value beteween 0 & 1
 * @returns New Hex code string
 */
export const darken = (color: Color, percent: number) => {
  let [r, g, b] = hexToRgb(color);

  r = (1 - percent) * r;
  g = (1 - percent) * g;
  b = (1 - percent) * b;

  return rgbToHex(r, g, b);
};

export const effects = {
  darken,
  lighten,
  hexToRgb,
  rgbToHex,
};
