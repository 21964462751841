import styled from '@emotion/styled';

import { sharedCellStyles } from '../tableCell/tableCell.styles';

interface StyledTableHeadCell {
  onClick?: () => void;
  sorted?: boolean;
  width: string;
}

export const TableHeadCellWrapper = styled.div({
  display: 'flex',
  textAlign: 'left',
  alignItems: 'center',
  float: 'left',
});

export const StyledTableHeadCell = styled.th<StyledTableHeadCell>(
  ({ theme, sorted, onClick, width }) => ({
    width,
    cursor: onClick ? 'pointer' : 'default',
    fontWeight: sorted ? 'bold' : 'normal',
    ...sharedCellStyles(theme),
  })
);
