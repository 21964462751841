import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Add = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22 11C22.5523 11 23 11.4477 23 12C23 12.5523 22.5523 13 22 13L2 13C1.44772 13 1 12.5523 1 12C1 11.4477 1.44772 11 2 11L22 11Z"
      fill="#0E0E0E"
    />
    <path
      d="M13 22C13 22.5523 12.5523 23 12 23C11.4477 23 11 22.5523 11 22L11 2C11 1.44772 11.4477 1 12 1C12.5523 1 13 1.44772 13 2L13 22Z"
      fill="#0E0E0E"
    />
  </svg>
);
export const AddIcon = ({ ...props }: DynamicIconProps) => {
  return <Icon icon={Add} {...props} color={props.color} />;
};
