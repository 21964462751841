import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Tag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 2.00482V7.17639C0 7.70683 0.210714 8.21554 0.585786 8.59061L6.58579 14.5906C7.36684 15.3717 8.63317 15.3717 9.41421 14.5906L14.5858 9.41903C15.3668 8.63799 15.3668 7.37166 14.5858 6.59061L8.58579 0.590608C8.21071 0.215535 7.70201 0.00482178 7.17157 0.00482178H2C0.895431 0.00482178 0 0.900252 0 2.00482ZM4 6.00482C5.10457 6.00482 6 5.10939 6 4.00482C6 2.90025 5.10457 2.00482 4 2.00482C2.89543 2.00482 2 2.90025 2 4.00482C2 5.10939 2.89543 6.00482 4 6.00482Z"
      fill="#0E0E0E"
    />
  </svg>
);
export const TagIcon = ({ ...props }: DynamicIconProps) => {
  return <Icon icon={Tag} {...props} color={props.color} />;
};
