import type { FilterSelectItem } from '../filter.types';
import type { FilterBarPopoverProps } from './filterBarPopover.types';
import { FilterList } from './filterList';
import { FilterPopover } from './filterPopover';

export const FilterBarPopover = ({
  filterGroup,
  onChange,
  onDeselect,
  totalResults,
  variant,
}: FilterBarPopoverProps) => {
  const { label, items, nested, key } = filterGroup;

  const renderedFilterList = items.map((item) => {
    return (
      <FilterList
        filterKey={filterGroup.key}
        key={item.label}
        filters={item as FilterSelectItem}
        isNested={nested}
        onChange={onChange}
      />
    );
  });

  return (
    <FilterPopover
      buttonText={label}
      targetLabel={`Filter ${variant}s by ${label}`}
      listLabel={`Select ${label}`}
      onDeselect={() => onDeselect(key)}
      disabled={items.length === 0}
      totalResults={totalResults}
      nested={nested}
      variant={variant}
    >
      {renderedFilterList}
    </FilterPopover>
  );
};
