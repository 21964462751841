import * as t from 'io-ts';

export const tFeatureCollection = t.interface({
  type: t.string,
  features: t.UnknownArray,
});

export const tStoreDeliveryGeofence = t.interface({
  id: t.number,
  url: t.string,
  geojson: tFeatureCollection,
  cart_minimum: t.number,
  fee: t.number,
  name: t.union([t.string, t.null]),
  lead_time_minutes: t.union([t.number, t.null]),
  last_call_minutes: t.union([t.number, t.null]),
});

export type StoreDeliveryGeofence = t.TypeOf<typeof tStoreDeliveryGeofence>;

export type FeatureCollection = t.TypeOf<typeof tFeatureCollection>;
