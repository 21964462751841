import * as t from 'io-ts';

export const tConsentLog = t.interface({
  id: t.number,
  user_id: t.union([t.number, t.null]),
  cart_id: t.union([t.null, t.number]),
  label: t.string,
  consented: t.boolean,
  created_at: t.string,
});

export type ConsentLog = t.TypeOf<typeof tConsentLog>;
