// eslint-disable-next-line no-restricted-imports
import moment from 'moment';

export function convertToUnixMs(timestamp: number) {
  return moment(timestamp, 'x');
}

export function msFromNow(timeFromNowMs: number) {
  return moment().add(timeFromNowMs, 'ms');
}

export function subtractHoursFromCurrentAsDate(hours: number) {
  return moment().subtract(hours, 'hours').toDate();
}

export function tomorrow() {
  return moment().add(1, 'days').toDate();
}
