import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Cbd = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.72688 12.8188C8.01621 12.8188 7.37054 12.6498 6.78988 12.3118C6.20921 11.9738 5.74554 11.4798 5.39888 10.8298C5.06088 10.1712 4.89188 9.37382 4.89188 8.43782C4.89188 7.51049 5.06521 6.71749 5.41188 6.05882C5.76721 5.39149 6.23954 4.88449 6.82888 4.53782C7.41821 4.18249 8.06821 4.00482 8.77888 4.00482C9.31621 4.00482 9.79288 4.11316 10.2089 4.32982C10.6249 4.54649 10.9672 4.79782 11.2359 5.08382L10.4299 6.05882C10.2132 5.83349 9.97054 5.65582 9.70188 5.52582C9.44188 5.38716 9.14721 5.31782 8.81788 5.31782C8.35854 5.31782 7.94688 5.44349 7.58288 5.69482C7.22754 5.93749 6.94588 6.28849 6.73788 6.74782C6.53854 7.20716 6.43888 7.75749 6.43888 8.39882C6.43888 9.36949 6.65121 10.1322 7.07588 10.6868C7.50054 11.2328 8.06821 11.5058 8.77888 11.5058C9.16021 11.5058 9.49821 11.4278 9.79288 11.2718C10.0875 11.1072 10.3562 10.8948 10.5989 10.6348L11.4049 11.5838C11.0582 11.9825 10.6595 12.2902 10.2089 12.5068C9.76688 12.7148 9.27288 12.8188 8.72688 12.8188Z"
      fill="#666666"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1.00482H4C2.34315 1.00482 1 2.34797 1 4.00482V12.0048C1 13.6617 2.34315 15.0048 4 15.0048H12C13.6569 15.0048 15 13.6617 15 12.0048V4.00482C15 2.34797 13.6569 1.00482 12 1.00482ZM4 0.00482178C1.79086 0.00482178 0 1.79568 0 4.00482V12.0048C0 14.214 1.79086 16.0048 4 16.0048H12C14.2091 16.0048 16 14.214 16 12.0048V4.00482C16 1.79568 14.2091 0.00482178 12 0.00482178H4Z"
      fill="#666666"
    />
  </svg>
);
export const CbdIcon = ({ ...props }: DynamicIconProps) => {
  return <Icon icon={Cbd} {...props} color={props.color} />;
};
