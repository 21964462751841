import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Blissful = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={12} cy={12} r={12} fill="#FFC220" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.29289 15.2929C5.68342 14.9024 6.31658 14.9024 6.70711 15.2929C9.63029 18.2161 14.3697 18.2161 17.2929 15.2929C17.6834 14.9024 18.3166 14.9024 18.7071 15.2929C19.0976 15.6834 19.0976 16.3166 18.7071 16.7071C15.0029 20.4113 8.99713 20.4113 5.29289 16.7071C4.90237 16.3166 4.90237 15.6834 5.29289 15.2929Z"
      fill="black"
      fillOpacity={0.5}
    />
    <path
      d="M7 7.87971C6.69197 7.51142 6.09613 7 5.15924 7C3.95543 7 3 7.98531 3 8.95956C3 9.93411 3.53652 10.6972 4.60991 11.5751C5.67959 12.4505 6.04693 12.5386 6.99528 13C7.95341 12.5386 8.32109 12.4505 9.39043 11.5751C10.4638 10.6972 11 9.93411 11 8.95956C11 7.98531 10.0449 7 8.84076 7C7.90421 7 7.30803 7.51142 7 7.87971Z"
      fill="#FF6334"
    />
    <path
      d="M17 7.87971C16.692 7.51142 16.0961 7 15.1592 7C13.9554 7 13 7.98531 13 8.95956C13 9.93411 13.5365 10.6972 14.6099 11.5751C15.6796 12.4505 16.0469 12.5386 16.9953 13C17.9534 12.5386 18.3211 12.4505 19.3904 11.5751C20.4638 10.6972 21 9.93411 21 8.95956C21 7.98531 20.0449 7 18.8408 7C17.9042 7 17.308 7.51142 17 7.87971Z"
      fill="#FF6334"
    />
  </svg>
);
export const BlissfulIcon = ({ ...props }: StaticIconProps) => {
  return <Icon icon={Blissful} {...props} color={undefined} />;
};
