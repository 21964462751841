import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';

import type {
  AddedOrUpdatedProductInCart,
  CheckoutSuccess,
  RemovedProductFromCart,
  ViewedProduct,
} from './eventNames';

declare global {
  interface Window {
    GA4_ENABLED: boolean;
    JANE_GOOGLE_ANALYTICS_ENHANCED_ECOMMERCE_ENABLED: boolean;
  }
}

export const gaTrackProductClick = (event: ViewedProduct) => {
  if (!window.JANE_GOOGLE_ANALYTICS_ENHANCED_ECOMMERCE_ENABLED) return;

  window.GA4_ENABLED ? GA4ViewProduct(event) : UAViewProduct(event);
};

export const gaTrackAddedProductToCart = (
  event: AddedOrUpdatedProductInCart
) => {
  if (!window.JANE_GOOGLE_ANALYTICS_ENHANCED_ECOMMERCE_ENABLED) return;

  window.GA4_ENABLED ? GA4AddToCart(event) : UAAddToCart(event);
};

export const gaTrackRemovedProductFromCart = (
  event: RemovedProductFromCart
) => {
  if (!window.JANE_GOOGLE_ANALYTICS_ENHANCED_ECOMMERCE_ENABLED) return;

  window.GA4_ENABLED ? GA4RemoveFromCart(event) : UARemoveFromCart(event);
};

export const gaTrackLoadedCart = () => {
  if (!window.JANE_GOOGLE_ANALYTICS_ENHANCED_ECOMMERCE_ENABLED) return;

  if (!window.GA4_ENABLED) UALoadedCart();
};

export const gaTrackCheckoutSuccess = (event: CheckoutSuccess) => {
  if (!window.JANE_GOOGLE_ANALYTICS_ENHANCED_ECOMMERCE_ENABLED) return;

  window.GA4_ENABLED ? GA4Checkout(event) : UACheckout(event);
};

function UAViewProduct(event: ViewedProduct) {
  ReactGA.plugin.execute('ec', 'addProduct', {
    id: event.productId,
    name: event.productName,
    category: event.productKind,
    brand: event.productBrand,
  });

  ReactGA.plugin.execute('ec', 'setAction', 'detail');

  ReactGA.ga('send', 'event', 'UX', 'click', 'product details');
}

function GA4ViewProduct(event: ViewedProduct) {
  const {
    productId,
    productName,
    pricePerUnit,
    priceUnit,
    productBrand,
    productKind,
    productBrandSubtype,
    productLineage,
    storeName,
    storeCurrency = 'US',
  } = event;

  ReactGA4.event('view_item', {
    currency: storeCurrency,
    value: pricePerUnit,
    items: [
      {
        item_id: productId,
        item_name: productName,
        affiliation: storeName,
        item_brand: productBrand,
        item_category: productKind,
        item_category2: productBrandSubtype,
        item_category3: productLineage,
        item_variant: priceUnit,
        price: pricePerUnit,
        quantity: 1,
      },
    ],
  });
}

function UAAddToCart(event: AddedOrUpdatedProductInCart) {
  ReactGA.plugin.execute('ec', 'addProduct', {
    id: event.productId,
    name: event.productName,
    category: event.productKind,
    brand: event.productBrand,
    price: event.pricePerUnit,
    quantity: event.quantity,
    variant: event.priceUnit,
  });

  ReactGA.plugin.execute('ec', 'setAction', 'add');

  ReactGA.ga('send', 'event', 'UX', 'click', 'add to cart');
}

function GA4AddToCart(event: AddedOrUpdatedProductInCart) {
  const {
    productId,
    productName,
    priceUnit,
    pricePerUnit,
    quantity,
    priceTotal,
    productBrand,
    productKind,
    productBrandSubtype,
    specialDiscountTotal,
    productLineage,
    storeName,
    storeCurrency = 'US',
  } = event;

  ReactGA4.event('add_to_cart', {
    currency: storeCurrency,
    value: priceTotal,
    items: [
      {
        item_id: productId,
        item_name: productName,
        affiliation: storeName,
        discount: specialDiscountTotal,
        item_brand: productBrand,
        item_category: productKind,
        item_category2: productBrandSubtype,
        item_category3: productLineage,
        item_variant: priceUnit,
        price: pricePerUnit,
        quantity,
      },
    ],
  });
}

function UARemoveFromCart(event: RemovedProductFromCart) {
  ReactGA.plugin.execute('ec', 'addProduct', {
    id: event.productId,
    name: event.productName,
    category: event.productKind,
    brand: event.productBrand,
    quantity: event.quantity,
    price: event.productPrice,
  });

  ReactGA.plugin.execute('ec', 'setAction', 'remove');

  ReactGA.ga('send', 'event', 'UX', 'click', 'remove form cart');
}

function GA4RemoveFromCart(event: RemovedProductFromCart) {
  const {
    productId,
    productName,
    productPrice,
    quantity,
    productBrand,
    productKind,
    storeCurrency = 'US',
  } = event;

  const nonNullPrice = productPrice || 0;
  const total = nonNullPrice * (quantity || 1);

  ReactGA4.event('remove_from_cart', {
    currency: storeCurrency,
    value: total,
    items: [
      {
        item_id: productId,
        item_name: productName,
        item_brand: productBrand,
        item_category: productKind,
        price: nonNullPrice,
        quantity,
      },
    ],
  });
}

function UALoadedCart() {
  ReactGA.plugin.execute('ec', 'setAction', 'checkout', { step: 1 });

  ReactGA.ga('send', 'event', 'UX', 'click', 'load cart');
}

function UACheckout(event: CheckoutSuccess) {
  event.products.forEach((product) => {
    ReactGA.plugin.execute('ec', 'addProduct', {
      id: product.productId,
      name: product.productName,
      category: product.productKind,
      brand: product.productBrand,
      price: product.priceUnit,
      quantity: product.quantity,
    });
  });

  ReactGA.plugin.execute('ec', 'setAction', 'purchase', {
    id: event.cartId,
    revenue: event.priceTotal,
  });

  ReactGA.ga('send', 'event', 'UX', 'click', 'checkout success');
}

function GA4Checkout(event: CheckoutSuccess) {
  const items = event.products.map((product) => {
    const {
      productId,
      productName,
      productKind,
      productBrand,
      priceUnit,
      quantity,
    } = product;

    return {
      item_id: productId,
      item_name: productName,
      item_category: productKind,
      item_brand: productBrand,
      price: priceUnit,
      quantity: quantity,
    };
  });

  const { priceTotal, cartId, storeCurrency = 'US' } = event;

  ReactGA4.event('purchase', {
    transaction_id: cartId,
    value: priceTotal,
    tax: 0,
    shipping: 0,
    currency: storeCurrency,
    items,
  });
}
