import type { FunctionComponent, ReactNode } from 'react';
import { useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';

import { styled, useMobileMediaQuery } from '@jane/reefer';
import type { SearchState } from '@jane/search/types';
import type {
  AppMode,
  Brand,
  Id,
  MenuProduct,
  PendingCartProduct,
  Product,
  Recommendation,
  ReservationCartProduct,
  Store,
} from '@jane/shared/models';

import { useCustomerDispatch } from '../../customer/dispatch';
import { closeCart } from '../../customer/redux/cart';
import { setProductDetailBreadcrumbs } from '../../customer/redux/search';
import { useCustomerSelector } from '../../customer/selectors';
import { paths } from '../../lib/routes';
import { flex } from '../../style';
import type { Categories } from '../category';

export const MENU_PRODUCT_ITEM_HEIGHT = 475;
export const MENU_PRODUCT_MOBILE_ITEM_HEIGHT = 425;
export const MENU_PRODUCT_SPONSORED_HEIGHT = 32;

export const DEFAULT_MOBILE_CARD_WIDTH = 150;
export const DEFAULT_DESKTOP_CARD_WIDTH = 200;

interface PriceLookUp {
  [key: string]: string;
}

export type ProductCardMode = 'default' | 'storeMenu';

export const PRICE_LOOKUP: PriceLookUp = {
  HALF_GRAM: '.5g',
  GRAM: '1g',
  TWO_GRAM: '2g',
  EIGHTH_OUNCE: '3.5g',
  QUARTER_OUNCE: '7g',
  HALF_OUNCE: '14g',
  OUNCE: '28g',
};

export const ItemContainer = styled.div(flex({ flexDirection: 'column' }), {
  position: 'relative',
  width: '100%',
  height: '100%',
  transition: 'box-shadow .3s',
  ':focus-visible': {
    boxShadow: '0 2px 11px 0 rgba(108, 108, 108, 0.5)',
    outline: 'none',
  },
});

interface StyledLinkType {
  appMode: AppMode;
  baseCardHeight: number;
  height: string | number;
}

const StyledLink = styled(Link, {
  shouldForwardProp: (propName: string) =>
    propName !== 'appMode' && propName !== 'baseCardHeight',
})<StyledLinkType>(({ height, appMode, baseCardHeight, theme }) => ({
  position: 'relative',
  width: '100%',
  height,
  maxHeight: baseCardHeight - 200,
  pointerEvents: appMode === 'headless' ? 'none' : undefined,
  ':focus-visible': {
    outline: 'none',
  },
  ':focus-visible:before': {
    backgroundColor: theme.colors.grays.hover,
    borderRadius: 'inherit',
    content: '""',
    height: '100%',
    position: 'absolute',
    width: '100%',
    zIndex: '1',
  },
}));

export const productDetailLink = ({
  product,
  appMode,
  store,
  brand,
  fromMenu,
  bundleSpecialId,
  fromSpecialId,
}: {
  appMode: AppMode;
  brand?: Pick<Brand, 'id' | 'name'>;
  bundleSpecialId?: Id | null;
  fromMenu?: boolean;
  fromSpecialId?: Id | null;
  product: Product | MenuProduct | Recommendation;
  store?: Pick<Store, 'id' | 'name'>;
}) => {
  const productNameAndSlugAndId = {
    id: (product as MenuProduct).product_id || (product as Product).id,
    name: product.name,
    slug: product.url_slug,
    brand: product.brand,
  };
  const recommended = !!(product as Recommendation).score;

  if (appMode === 'default') {
    if (store) {
      return paths.storeProduct({
        store,
        product: productNameAndSlugAndId,
        recommended,
        fromMenu,
        bundleSpecialId,
        fromSpecialId,
      });
    }
    if (brand) {
      return paths.brandProduct({
        brand,
        product: productNameAndSlugAndId,
        recommended,
        fromMenu,
        bundleSpecialId,
      });
    }
    return paths.product({
      product: productNameAndSlugAndId,
      slug: product.url_slug,
      recommended,
      fromMenu,
      bundleSpecialId,
    });
  }

  return store && appMode === 'brandEmbed'
    ? paths.brandEmbedStoreProduct(
        store,
        productNameAndSlugAndId,
        fromMenu,
        bundleSpecialId
      )
    : paths.embeddedProductDetail({
        product: productNameAndSlugAndId,
        recommended,
        fromMenu,
        bundleSpecialId,
      });
};

interface LinkProps {
  bundleSpecialId?: Id | null;
  children: ReactNode;
  fromMenu?: boolean;
  fromSpecialId?: Id | null;
  height?: number | string;
  onClick?: () => void;
  product: Product;
  searchState: SearchState | undefined;
  store?: Store;
}

export const ProductDetailPageLink: FunctionComponent<LinkProps> = ({
  product,
  searchState,
  children,
  store,
  fromMenu,
  bundleSpecialId,
  fromSpecialId,
  height,
}) => {
  const dispatch = useCustomerDispatch();
  const { brand_id, category } = useParams<{
    brand_id: string;
    category?: Categories;
  }>();

  const { appMode, brand, cartIsOpen } = useCustomerSelector(
    ({ embeddedApp: { appMode }, brand: { brand }, cart: { cart } }) => ({
      appMode,
      brand,
      cartIsOpen: cart.is_open,
    })
  );

  const memoizedOnClick = useCallback(() => {
    if (cartIsOpen) {
      dispatch(closeCart());
    }
    dispatch(setProductDetailBreadcrumbs(searchState));
  }, [searchState, cartIsOpen]);

  const isMobile = useMobileMediaQuery({});

  const baseCardHeight = isMobile
    ? MENU_PRODUCT_MOBILE_ITEM_HEIGHT
    : MENU_PRODUCT_ITEM_HEIGHT;

  const isFromProductBrand = brand_id !== undefined;

  return (
    <StyledLink
      appMode={appMode}
      baseCardHeight={baseCardHeight}
      height={height}
      to={productDetailLink({
        product,
        appMode,
        store,
        brand: isFromProductBrand && brand,
        fromMenu,
        bundleSpecialId,
        fromSpecialId,
      })}
      onClick={memoizedOnClick}
      role="link"
      {...(category && { state: { fromCategory: category } })}
    >
      {children}
    </StyledLink>
  );
};

export const sortByPriceId = (
  cartProduct: ReservationCartProduct[] | PendingCartProduct[],
  menuProduct: MenuProduct
): ReservationCartProduct[] | PendingCartProduct[] =>
  cartProduct.sort((a, b) =>
    menuProduct.available_weights.indexOf(a.price_id.replace('_', ' ')) >
    menuProduct.available_weights.indexOf(b.price_id.replace('_', ' '))
      ? 1
      : -1
  );
