import { useContext } from 'react';
import ReactPlayer from 'react-player';

import {
  Image,
  PlayIcon,
  Typography,
  fakeButtonProps,
  handleEnterKey,
} from '@jane/reefer';

import { CarouselContext } from '../carousel';
import type { CarouselItemProps } from '../carousel.types';
import {
  CarouselPlayerWrapper,
  InnerCarouselItem,
  PlayIconWrapper,
  StockPhotoLabel,
} from './carouselItem.styles';

export const CarouselItem = ({
  imageSrc,
  imageAlt,
  videoTitle,
  videoSrc,
  activeIndex,
  isLightbox,
  onClick,
  srcSet,
}: CarouselItemProps) => {
  const { onVideoClick, stockPhoto } = useContext(CarouselContext);
  const isVideoInFocus = !!videoSrc && activeIndex === 0;

  const videoIcon = (
    <PlayIcon color="grays-white" size="xl" altText="play video" />
  );

  const videoClickHandler = () => {
    onVideoClick && onVideoClick();
  };

  const renderCarouselVideo = () => {
    if (isLightbox) {
      return (
        <CarouselPlayerWrapper>
          <ReactPlayer
            data-testid="carousel-video-player"
            controls={true}
            width="100%"
            light={imageSrc}
            playIcon={videoIcon}
            title={videoTitle}
            url={videoSrc}
            stopOnUnmount={true}
            previewTabIndex={0}
            playing={isVideoInFocus}
            onClickPreview={videoClickHandler}
          />
        </CarouselPlayerWrapper>
      );
    }

    return (
      <>
        <Image
          src={imageSrc}
          srcSet={srcSet}
          altText={imageAlt}
          objectFit="cover"
          height="100%"
        />
        <PlayIconWrapper>{videoIcon}</PlayIconWrapper>
      </>
    );
  };

  return (
    <InnerCarouselItem
      data-testid="carousel-item"
      draggable={false}
      onClick={onClick}
      {...fakeButtonProps}
      onKeyUp={(event) => handleEnterKey(event, onClick)}
    >
      {videoSrc ? (
        <>{renderCarouselVideo()}</>
      ) : (
        <Image
          src={imageSrc}
          srcSet={srcSet}
          altText={imageAlt}
          objectFit="cover"
          height="100%"
        />
      )}

      {stockPhoto && (
        <StockPhotoLabel>
          <Typography variant="caps" color="grays-mid">
            Stock photo
          </Typography>
        </StockPhotoLabel>
      )}
    </InnerCarouselItem>
  );
};
