import {
  ConditionalWrapper,
  Flex,
  JaneGoldIcon,
  PARTNER_LOGO_SIZES,
  PartnerLogo,
  Typography,
  useTabletMediaQuery,
} from '@jane/reefer';
import type { MarginProperties, PartnerLogoProps } from '@jane/reefer';
import { FLAGS, useFlagHelper } from '@jane/shared/feature-flags';

import { LinkStyled } from './brandCard.styles';

export interface BrandCardProps
  extends MarginProperties,
    Pick<PartnerLogoProps, 'image' | 'name'> {
  /** `onClick` function the card should trigger */
  onClick?: () => void;

  /** Number of products from that brand */
  productCount?: number;

  /** Whether or not to display the Jane Gold icon beside the name */
  showGoldIcon?: boolean;

  /** Test id */
  testId?: string;

  /** Internal route to be linked to */
  to?: string;
}

export const BRAND_CARD_WIDTH = PARTNER_LOGO_SIZES.xl;
export const BRAND_CARD_WIDTH_MOBILE = PARTNER_LOGO_SIZES.lg;
export const BRAND_CARD_HEIGHT = 176;
export const BRAND_CARD_HEIGHT_MOBILE = 152;

/**
 * A card component used for representing individual brands in carousels or similar.
 */
export function BrandCard({
  image,
  name,
  onClick,
  productCount,
  showGoldIcon = false,
  testId,
  to,
  ...props
}: BrandCardProps) {
  const janeGoldFlag = useFlagHelper(FLAGS.janeGold);
  const isTablet = useTabletMediaQuery({ width: 'min' });
  return (
    <ConditionalWrapper
      condition={!!(to || onClick)}
      wrapper={(children) => (
        <LinkStyled onClick={onClick} to={to} variant="minimal">
          {children}
        </LinkStyled>
      )}
    >
      <Flex
        alignItems="center"
        data-testid={testId}
        flexDirection="column"
        width={isTablet ? BRAND_CARD_WIDTH : BRAND_CARD_WIDTH_MOBILE}
        {...props}
      >
        <PartnerLogo
          image={image}
          name={name}
          size={isTablet ? 'xl' : 'lg'}
          variant="brand"
        />
        <Flex alignItems="center" mt={8}>
          <Typography
            color="grays-black"
            mr={showGoldIcon ? 4 : 0}
            textAlign="center"
            variant="body-bold"
          >
            {name}
          </Typography>
          {showGoldIcon && janeGoldFlag && <JaneGoldIcon size="sm" />}
        </Flex>
        {productCount && (
          <Typography color="grays-mid">
            {productCount} {productCount > 1 ? 'products' : 'product'}
          </Typography>
        )}
      </Flex>
    </ConditionalWrapper>
  );
}
