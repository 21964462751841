import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import type { Store } from 'redux';

import { defaultQueryClientConfig } from '@jane/shared/config';

interface Props {
  children: React.ReactNode;
  store: Store;
}

export const LegacyAppWrapper = ({ children, store }: Props) => {
  const queryClient = new QueryClient({
    ...defaultQueryClientConfig,
  });
  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <ReduxProvider store={store}>
          <BrowserRouter>{children}</BrowserRouter>
        </ReduxProvider>
      </QueryClientProvider>
    </HelmetProvider>
  );
};
