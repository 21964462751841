import { ButtonToggle as ButtonToggleBase } from './buttonToggle';
import type { ButtonToggleProps } from './buttonToggle';
import type { ButtonToggleButtonProps } from './buttonToggleButton/buttonToggleButton';
import { ButtonToggleButton } from './buttonToggleButton/buttonToggleButton';

export const ButtonToggle = Object.assign(ButtonToggleBase, {
  Button: ButtonToggleButton,
});

export type { ButtonToggleProps, ButtonToggleButtonProps };
