export const logPrefix = (component: string) => `[Reefer/${component}]:`;

/**
 * Logger methods to prefix messages with helpful information
 * @param component string - name of the component logging the message
 * @param message string - the message
 */
export const logger = {
  info: (component: string, message: string) =>
    console.log(`${logPrefix(component)} ${message}`),
  warn: (component: string, message: string) =>
    console.warn(`${logPrefix(component)} ${message}`),
  error: (component: string, message: string) =>
    console.error(`${logPrefix(component)} ${message}`),
};
