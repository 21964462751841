export enum ModalNames {
  AddCheckoutAgreement = 'Add Checkout Agreement',
  AdditionalDismissalMessages = 'Additional Dismissal Messages',
  ArrangeMenuRows = 'ArrangeMenuRows',
  CartLimitRules = 'Cart Limit Rules',
  CartLimitStoreSelect = 'Cart Limit Store Select',
  CartLimits = 'Cart Limits',
  CheckoutAgreementReportModal = 'Checkout Agreement Report Modal',
  CheckoutAgreements = 'Checkout Agreements',
  CheckoutOptions = 'Checkout Options',
  CreateCartLimitPolicy = 'Create Cart Limit Policy',
  CreateMenuRow = 'Create Menu Row',
  DeliverySettings = 'Delivery Settings',
  Discounts = 'Discounts',
  EditCartLimitPolicy = 'Edit Cart Limit Policy',
  EditCheckoutAgreement = 'Edit Checkout Agreement',
  EditMenuRow = 'Edit Menu Row',
  EditStaffMember = 'Edit Staff Member',
  Geofence = 'Geofence',
  GoogleAnalytics = 'Google Analytics',
  InviteStaffMember = 'Invite Staff Member',
  KioskNotifications = 'Kiosk Notifications',
  KioskSettings = 'Kiosk Settings',
  LoyaltySettings = 'Loyalty Settings',
  Notifications = 'Notifications',
  Payments = 'Payments',
  PickupAndDeliveryNotifications = 'Pickup And Delivery Notifications',
  SmsSettings = 'SmsSettings',
  StaffMemberStoreSelect = 'Staff Member Store Select',
  StoreDetails = 'Store Details',
  StoreHours = 'Store Hours',
  Subdomain = 'Subdomain',
  TaxesAndFees = 'Taxes and Fees',
  UpdateStaffMember = 'Update Staff Member',
}

export enum CardNames {
  CartLimits = 'Cart Limits',
  EmailNotifications = 'Email Notifications',
  FulfillmentSettings = 'Fulfillment Settings',
  OrderAhead = 'Order Ahead',
}

export enum FilterNames {
  StoreState = 'Store State',
  StoreType = 'Store Type',
}

export enum NavigationSourceIds {
  Automatic = 'Automatic',
  BusinessNav = 'Business Nav',
  ForgotPasswordLink = 'Forgot Password Link',
  Login = 'Login',
  StoreCard = 'Store Card',
  StoreDetailsSidenav = 'Store Details Sidenav',
  StoreDetailsTabs = 'Store Details Tabs',
  StoreSwitcherClick = 'Store Switcher (Click)',
  StoreSwitcherKeyboard = 'Store Switcher (Keyboard)',
}

export enum ExternalLinkSourceIds {
  BusinessNavHamburger = 'Business Nav Hamburger',
  CheckoutAgreementDetails = 'Checkout Agreement Details',
  GoogleAnalyticsModal = 'Google Analytics Modal',
  InfoPopover = 'Info Popover',
}

export enum EventNames {
  AccessDenied = 'Access Denied',
  ChangedStore = 'Changed Store',
  ClosedSettingsModal = 'Closed Settings Modal',
  ContactedUs = 'Contacted Us',
  DeletedStoreSettings = 'Deleted Store Settings',
  DeselectedFilter = 'Deselected Filter',
  DiscardChanges = 'Discard Changes',
  EditedMenu = 'Edited Menu',
  EditedPassword = 'Edited Password',
  EditedStoreSettings = 'Edited Store Settings',
  EditedUserProfile = 'Edited User Profile',
  LoggedIn = 'Logged In',
  LoggedOut = 'Logged Out',
  Navigated = 'Navigated',
  NavigatedToLegacy = 'Navigated Back to Legacy',
  OpenExternalLink = 'Open External Link',
  OpenedSettingsModal = 'Opened Settings Modal',
  OpenedStoreSwitcher = 'Opened Store Switcher',
  ReorderedMenuRows = 'Reordered Menu Rows',
  SelectedFilter = 'Selected Filter',
  SubmitFeedbackForm = 'Submit Feedback Form',
  UploadedSettingsPhoto = 'Uploaded Settings Photo',
}

interface LoggedIn {
  event: EventNames.LoggedIn;
  to_url: string;
}

interface LoggedOut {
  event: EventNames.LoggedOut;
  from_url: string;
}

interface Navigated {
  event: EventNames.Navigated;
  from_url: string;
  to_url: string;
  trigger_source_id: string;
}

interface AccessDenied {
  event: EventNames.AccessDenied;
  reason: string;
}

interface ContactedUs {
  event: EventNames.ContactedUs;
}

interface OpenExternalLink {
  event: EventNames.OpenExternalLink;
  from_url: string;
  to_url: string;
  trigger_source_id: string;
}

interface NavigatedToLegacy {
  event: EventNames.NavigatedToLegacy;
  from_url: string;
  to_url: string;
}

interface OpenedStoreSwitcher {
  event: EventNames.OpenedStoreSwitcher;
  trigger_source_id:
    | NavigationSourceIds.StoreSwitcherClick
    | NavigationSourceIds.StoreSwitcherKeyboard;
}

interface ChangedStore {
  event: EventNames.ChangedStore;
  from_store_id: string;
  to_store_id: string;
}

interface EditedUserProfile {
  changed_attributes: string[];
  event: EventNames.EditedUserProfile;
}

interface EditedPassword {
  event: EventNames.EditedPassword;
}

interface OpenedSettingsModal {
  event: EventNames.OpenedSettingsModal;
  modal_name: string;
  url: string;
}

interface ClosedSettingsModal {
  event: EventNames.ClosedSettingsModal;
  modal_name: string;
  url: string;
}

interface DiscardChanges {
  event: EventNames.DiscardChanges;
  modal_name: string;
}

interface EditedStoreSettings {
  card_name?: CardNames | string;
  changed_attributes: string[];
  event: EventNames.EditedStoreSettings;
  modal_name?: ModalNames | string;
}

interface DeletedStoreSettings {
  event: EventNames.DeletedStoreSettings;
  modal_name: ModalNames | string;
  settings_name: string;
}

interface UploadedSettingsPhoto {
  event: EventNames.UploadedSettingsPhoto;
  file_size: string;
  file_type: string;
  settings_name: string;
  url: string;
}

interface SubmitFeedbackForm {
  event: EventNames.SubmitFeedbackForm;
  source: string;
}

interface SelectedFilter {
  all_selected_values: string[];
  event: EventNames.SelectedFilter;
  filter_name: FilterNames;
  modal_name?: ModalNames;
  selected_value: string;
  url: string;
}

interface DeselectedFilter {
  all_selected_values: string[];
  deselected_value: string;
  event: EventNames.DeselectedFilter;
  filter_name: FilterNames;
  modal_name?: ModalNames;
  url: string;
}

interface EditedMenu {
  card_name?: CardNames | string;
  changed_attributes: string[];
  event: EventNames.EditedMenu;
  modal_name?: ModalNames | string;
}

export type TrackedEvent =
  | LoggedIn
  | LoggedOut
  | Navigated
  | AccessDenied
  | ContactedUs
  | OpenExternalLink
  | NavigatedToLegacy
  | OpenedStoreSwitcher
  | ChangedStore
  | EditedUserProfile
  | EditedPassword
  | OpenedSettingsModal
  | ClosedSettingsModal
  | DiscardChanges
  | EditedStoreSettings
  | DeletedStoreSettings
  | UploadedSettingsPhoto
  | SubmitFeedbackForm
  | SelectedFilter
  | DeselectedFilter
  | EditedMenu;
