import { useFlags as useLDFlags } from 'launchdarkly-react-client-sdk';
import { useSearchParams } from 'react-router-dom';

interface FlagsType {
  [key: string]: unknown;
}

export const useFlags = () => {
  const [params] = useSearchParams();
  const featureFlagsOn = params.get('featureFlagsOn')?.split(',');
  const featureFlagsOff = params.get('featureFlagsOff')?.split(',');
  const flagsLD = useLDFlags();
  const flags: FlagsType = {};
  Object.keys(flagsLD).forEach((flag) => {
    if (featureFlagsOn?.includes(flag)) {
      flags[flag] = true;
    } else if (featureFlagsOff?.includes(flag)) {
      flags[flag] = false;
    } else {
      flags[flag] = flagsLD[flag];
    }
  });
  return flags;
};
