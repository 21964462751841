import styled from '@emotion/styled';

import { styling } from '../styling';
import type { Color, ReeferTheme } from '../types';

interface StyleProps {
  theme?: ReeferTheme;
}

interface ColorStyleProps extends StyleProps {
  color: Color;
}

export const SwatchColor = styled.div<ColorStyleProps>(({ color }) => ({
  background: color,
  height: '50px',
  width: '100px',
  marginRight: '8px',
}));

export const SwatchListItem = styled.li({
  display: 'flex',
  alignItems: 'center',
});

export const SwatchContainer = styled.ul({
  listStyle: 'none',
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
});

export const SectionTitle = styled.h2({
  boxShadow: styling.shadows.divider,
  paddingBottom: '8px',
  marginBottom: '16px',
});

export const ColorSection = styled.li({
  display: 'inline-block',
  boxShadow: styling.shadows.hard,
  minWidth: '400px',
  padding: '24px',
  marginRight: '24px',
  marginBottom: '24px',
});

export const ColorList = styled.ul({
  listStyle: 'none',
  padding: '0',
});
