import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Special = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7 9C8.10457 9 9 8.10457 9 7C9 5.89543 8.10457 5 7 5C5.89543 5 5 5.89543 5 7C5 8.10457 5.89543 9 7 9Z"
      fill="#0E0E0E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 14.8284C23.5621 13.2663 23.5621 10.7337 22 9.17157L14 1.17157C13.2499 0.421429 12.2324 0 11.1716 0H4C1.79086 0 0 1.79086 0 4V11.1716C0 12.2324 0.421427 13.2499 1.17157 14L9.17157 22C10.7337 23.5621 13.2663 23.5621 14.8284 22L22 14.8284ZM13.4142 20.5858C12.6332 21.3668 11.3668 21.3668 10.5858 20.5858L2.58579 12.5858C2.21071 12.2107 2 11.702 2 11.1716V4C2 2.89543 2.89543 2 4 2H11.1716C11.702 2 12.2107 2.21071 12.5858 2.58579L20.5858 10.5858C21.3668 11.3668 21.3668 12.6332 20.5858 13.4142L13.4142 20.5858Z"
      fill="#0E0E0E"
    />
  </svg>
);
export const SpecialIcon = ({ ...props }: DynamicIconProps) => {
  return <Icon icon={Special} {...props} color={props.color} />;
};
