import { Typography, styled } from '@jane/reefer';
import type { Product, ProductLineage, ProductType } from '@jane/shared/models';

import { flex, spacing } from '../../../style';

// This file exports shared components, types, constants, etc., for the homepage
export type PopularProductCohort = {
  canonicalProductType: ProductType;
  category: ProductLineage | null;
  localized: boolean;
  product_subtype: string | null;
  product_type: ProductType | null;
  products: readonly Product[];
};

export const CATEGORIES = ['indica', 'sativa', 'hybrid', 'cbd'];

export const HomePageTextHeader = styled.header(
  spacing({ mb: 16 }),
  flex({ justifyContent: 'space-between', flexDirection: 'column' }),
  {
    width: '100%',
  }
);

export const HomePageLabel = ({ children }) => (
  <Typography mb={4} variant="title-bold">
    {children}
  </Typography>
);

export const NumberOfProductsText = ({ children }) => (
  <Typography as="div" variant="body">
    {children}
  </Typography>
);
