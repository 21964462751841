export const handleEnterKey = (
  event:
    | KeyboardEvent
    | React.KeyboardEvent<HTMLSpanElement>
    | React.KeyboardEvent<HTMLDivElement>,
  action?: (() => void) | ((event: any) => void)
) => {
  const enterKey = event.key === 'Enter';

  if (enterKey) {
    action && action(event);
  }
};

export const fakeButtonProps = { role: 'button', tabIndex: 0 };
