import { useEffect, useMemo } from 'react';

import { useAlgoliaMenuProducts } from '@jane/search/hooks';
import type { BaseCartTopper } from '@jane/shared-ecomm/data-access';
import type { AppMode, Id, MenuProduct } from '@jane/shared/models';

import { trackPriceExceptions } from './priceChecking';
import { useFetchCartToppers } from './queries';

interface Params {
  appMode: AppMode;
  cartProductIds: number[];
  janeDeviceId: string;
  maxProducts?: number;
  storeId: Id;
  trackDmError: (error: Error) => void;
}
export interface CartTopper {
  menuProduct: MenuProduct;
  mlScoringSignals?: Record<string, unknown>;
}

export const useCartToppers = ({
  appMode,
  janeDeviceId,
  storeId,
  cartProductIds,
  maxProducts = 16,
  trackDmError,
}: Params): CartTopper[] => {
  const { data = { cartToppers: [], maxPrice: 0 } } = useFetchCartToppers({
    appMode,
    cartProductIds,
    fetchOnce: true,
    janeDeviceId,
    storeId,
    maxProducts,
  });
  const rankedProducts = data.cartToppers;
  const { maxPrice } = data;
  const productIds = useMemo(
    () => rankedProducts.map((rankedProduct) => rankedProduct.product_id),
    [rankedProducts]
  );
  const menuProducts = useAlgoliaMenuProducts({ productIds, storeId });

  const cartToppers = useMemo(
    () =>
      menuProducts
        ? buildCartToppers({ menuProducts, rankedProducts, trackDmError })
        : [],
    [menuProducts, rankedProducts, trackDmError]
  );

  useEffect(() => {
    trackPriceExceptions({
      cartToppers: cartToppers.map((cartTopper) => cartTopper.menuProduct),
      maxPrice,
      storeId,
      trackDmError,
    });
  }, [cartToppers, maxPrice, storeId, trackDmError]);

  return cartToppers || [];
};

const buildCartToppers = ({
  menuProducts,
  rankedProducts,
  trackDmError,
}: {
  menuProducts: MenuProduct[];
  rankedProducts: BaseCartTopper[];
  trackDmError: (error: Error) => void;
}): CartTopper[] => {
  const cartToppers: CartTopper[] = [];
  rankedProducts?.forEach((rankedProduct) => {
    const index = menuProducts.findIndex(
      (menuProduct) => rankedProduct.product_id === menuProduct.id
    );
    if (index !== -1) {
      const menuProduct = menuProducts[index];
      const { flight, mlScoringSignals, dmMeta } = rankedProduct;
      const menuProductWithFlightAndDmMeta: MenuProduct = {
        ...menuProduct,
        flight,
        dmMeta,
      };
      cartToppers.push({
        menuProduct: menuProductWithFlightAndDmMeta,
        mlScoringSignals,
      });
    } else {
      const error = `Failed to find cart topper product details for menuProduct.id=${rankedProduct.product_id}`;
      trackDmError(new Error(error));
    }
  });
  return cartToppers;
};
