import * as t from 'io-ts';

const tReviewReportReasons = t.union([
  t.literal('inappropriate'),
  t.literal('not_helpful'),
  t.literal('spam'),
  t.literal('other'),
]);

const tReviewReport = t.interface({
  productReviewId: t.number,
  comment: t.union([t.string, t.undefined]),
  reason: t.string,
});

export type ReviewReport = t.TypeOf<typeof tReviewReport>;
export type ReportReasons = t.TypeOf<typeof tReviewReportReasons>;
