import times from 'lodash/times';
import { useEffect } from 'react';

import { Button, Typography, styled, useTheme } from '@jane/reefer';
import type { SearchState } from '@jane/search/types';
import { JANE_DEFINED_ROWS } from '@jane/search/util';
import { EventNames } from '@jane/shared-ecomm/tracking';
import { generateCustomLabel } from '@jane/shared-ecomm/util';
import type { Store } from '@jane/shared/models';
import { titleCase } from '@jane/shared/util';

import { SvgIcon } from '../../component-library/svg-icon';
import { appModeSelector, useCustomerSelector } from '../../customer/selectors';
import { border, flex, media, mediaQueries, spacing } from '../../style';
import { Flex } from '../../style/layout';
import ProgressBar from '../progressBar';
import { SeeAllLinkWrapper } from './productKindBucketCarousel';

const EmptyFooter = styled.div({ height: 40 });

const ExpandedContainer = styled.div(
  flex({ flexDirection: 'column', alignItems: 'center' }),
  spacing({ py: 24 }),
  {
    [media(mediaQueries.largerThanVerticalTablet)]: {
      display: 'grid',
      alignItems: 'end',
      justifyItems: 'center',
      gridTemplateColumns: '1fr 50px',
    },
  }
);

const ScrollButton = styled.button(spacing({ mb: 12 }), ({ theme }) => ({
  ...border.thin(theme.colors.grays.light),
  backgroundColor: theme.colors.grays.ultralight,
  borderRadius: '100%',
  boxShadow: '0 0 12px 4px rgba(220, 220, 220, 0.5)',
  height: 40,
  lineHeight: 0,
  width: 40,
  [media(mediaQueries.largerThanVerticalTablet)]: {
    height: 50,
    width: 50,
    gridColumn: 2,
    gridRow: 1,
    marginBottom: 0,
  },
}));

const OtherControls = styled.div({
  gridColumn: '1 / span 2',
  gridRow: 1,
});
const Progress = styled.div(spacing({ mt: 16 }), {
  width: 200,
});
const ButtonContainer = styled.div(spacing({ mt: 16 }));

interface Props {
  expanded: boolean;
  filterExistsOnAlgoliaRootTypes: boolean;
  hasMore: boolean;
  isKiosk: boolean;
  loadedCount: number;
  name: string;
  nonCarousel?: boolean;
  numberHitsRendered: number;
  onLoadMore: (arg?: boolean) => void;
  onScrollToTop: () => void;
  paginationCount: number;
  placementIndex: number;
  searchState: SearchState;
  store: Store;
  totalCount: number;
}

const BucketFooter = ({
  hasMore,
  loadedCount,
  onLoadMore,
  onScrollToTop,
  totalCount,
  numberHitsRendered,
  paginationCount,
  searchState,
  isKiosk,
  store,
  filterExistsOnAlgoliaRootTypes,
  name,
  placementIndex,
}: Props) => {
  const appMode = useCustomerSelector(appModeSelector);
  useEffect(() => {
    if (totalCount > numberHitsRendered && paginationCount > 1) {
      times(paginationCount - 1).reduce(
        (p, _) => p.then(() => onLoadMore(false)),
        Promise.resolve()
      );
    }
  }, []);

  const theme = useTheme();

  if (totalCount <= numberHitsRendered) return <EmptyFooter />;

  const getLabel = () => {
    const customRowDisplayName = store.custom_product_type_labels[name];
    // NOTE(elliot): If jane defined rows' custom labels are the default ie. best_selling or sale, then it should get title cased.
    const customRow =
      !JANE_DEFINED_ROWS.includes(customRowDisplayName) && customRowDisplayName;

    return generateCustomLabel({
      appMode,
      store,
      attribute: name,
      fallback: customRow ? customRow : titleCase(name),
    });
  };

  return !filterExistsOnAlgoliaRootTypes ? (
    <Flex py={24} justifyContent="center">
      <SeeAllLinkWrapper
        searchState={searchState}
        isKiosk={isKiosk}
        store={store}
        trackingInfo={{
          event: EventNames.ClickedSeeAll,
          category: name,
          linkLocation: 'bottom of bucket button',
          numberOfItems: totalCount,
          placementIndex,
        }}
      >
        {`See All ${getLabel()}`}
      </SeeAllLinkWrapper>
    </Flex>
  ) : (
    <ExpandedContainer>
      <ScrollButton onClick={onScrollToTop}>
        <SvgIcon
          icon="angleDoubleUp"
          color={theme.colors.primary.main}
          size={24}
        />
      </ScrollButton>
      <OtherControls>
        <Typography
          color="grays-mid"
          variant="body"
        >{`You've viewed ${loadedCount} of ${totalCount} products`}</Typography>
        <Progress>
          <ProgressBar complete={loadedCount} total={totalCount} />
        </Progress>
        <ButtonContainer>
          <Button
            onClick={() => onLoadMore(true)}
            label={!hasMore ? 'Done' : 'Load More'}
            disabled={!hasMore}
            full
          />
        </ButtonContainer>
      </OtherControls>
    </ExpandedContainer>
  );
};

export default BucketFooter;
