import { useContext, useRef } from 'react';

import { Button, Flex, LeftArrowIcon, RightArrowIcon } from '@jane/reefer';

import { CarouselContext } from '../carousel';
import type { CarouselThumbnailProps } from '../carousel.types';
import {
  ThumbnailButtonWrapper,
  ThumbnailRowWrapper,
  ThumbnailsWrapper,
} from './carouselThumbnail.styles';
import { CarouselThumbnailItem } from './carouselThumbnailItem';

/**
 * Carousel Thumbnails to view and select product or brand media
 */

export const CarouselThumbnails = ({
  activeIndex,
  isLightbox,
  items,
  updateIndex,
  variant,
}: CarouselThumbnailProps) => {
  const { desktopWidth } = useContext(CarouselContext);
  const showButtons = !isLightbox && variant !== 'brand' && items.length > 4;
  const thumbnailRefs = useRef<HTMLDivElement[]>([]);

  thumbnailRefs?.current[activeIndex]?.scrollIntoView({
    behavior: 'smooth',
    block: 'nearest',
    inline: 'center',
  });

  return (
    <ThumbnailsWrapper
      isLightbox={isLightbox}
      data-testid="carousel-thumbnails"
    >
      {showButtons && (
        <ThumbnailButtonWrapper>
          <Button.Icon
            icon={<LeftArrowIcon />}
            label="View previous item"
            variant="minimal-inverse"
            mr={12}
            disabled={activeIndex === 0}
            onClick={() => updateIndex(activeIndex - 1)}
          />
        </ThumbnailButtonWrapper>
      )}
      <ThumbnailRowWrapper maxWidth={desktopWidth} isLightbox={isLightbox}>
        {items.map((item, index) => {
          return (
            <Flex grow key={index}>
              <CarouselThumbnailItem
                {...item}
                activeIndex={activeIndex}
                thumbnailIndex={index}
                updateIndex={updateIndex}
                thumbnailRef={(element: HTMLDivElement) => {
                  thumbnailRefs.current[index] = element;
                }}
              />
            </Flex>
          );
        })}
      </ThumbnailRowWrapper>

      {showButtons && (
        <ThumbnailButtonWrapper>
          <Button.Icon
            icon={<RightArrowIcon />}
            label="View next item"
            variant="minimal-inverse"
            ml={12}
            disabled={activeIndex === items.length - 1}
            onClick={() => updateIndex(activeIndex + 1)}
          />
        </ThumbnailButtonWrapper>
      )}
    </ThumbnailsWrapper>
  );
};
