export function buildBooleanFilter(column: string, value?: boolean) {
  return typeof value === 'boolean' ? `${column}:${value}` : '';
}

export function buildFilter(column: string, values?: string[] | number[]) {
  return values && values.length > 0
    ? `(${values
        .map((value) => {
          if (typeof value === 'number') return `${column}:${value}`;
          return `${column}:"${value}"`;
        })
        .join(' OR ')})`
    : '';
}

export function buildBucketFilter(column: string, values?: string[]) {
  return values && values.length > 0
    ? `(${values
        .map((value) => {
          const [start, end] = value.split(':');

          if (!start) {
            return `${column} <= ${end}`;
          } else if (!end) {
            return `${column} >= ${start}`;
          } else {
            return `${column}:${start} TO ${end}`;
          }
        })
        .join(' OR ')})`
    : '';
}

export function buildRangeFilter(column: string, min?: number, max?: number) {
  if (min && max) {
    return min > 0 && max > 0
      ? `(${column} >= ${min} AND ${column} <= ${max})`
      : '';
  }
  return '';
}

export function composeFilters(...filters: string[]): string {
  return filters.filter(Boolean).join(` AND `);
}
