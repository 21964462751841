import { useMediaQuery } from 'react-responsive';

import type {
  MediaQueryDesktopProps,
  MediaQueryDesktopSize,
  MediaQueryMobileProps,
  MediaQueryMobileSize,
  MediaQueryParam,
  MediaQueryTabletProps,
} from '../types';

const SIZE_MAP = {
  'mobile-sm': '320px',
  'mobile-md': '375px',
  'mobile-lg': '425px',
  /**
   * mobile-legacy is not an official breakpoint.
   * It is used as a direct replacement for the legacy MediaQuery wrapper's mobile breakpoint
   */
  'mobile-legacy': '480px',
  tablet: '768px',
  'desktop-sm': '1024px',
  'desktop-md': '1440px',
  'desktop-lg': '2560px',
};

export const mediaQueries = {
  mobile: (size: MediaQueryMobileSize, width: MediaQueryParam) => {
    const screenSize = SIZE_MAP[`mobile-${size}`];
    return `@media (${width}-width: ${screenSize})`;
  },
  tablet: (width: MediaQueryParam) => {
    return `@media (${width}-width: ${SIZE_MAP['tablet']})`;
  },
  desktop: (size: MediaQueryDesktopSize, width: MediaQueryParam) => {
    const screenSize = SIZE_MAP[`desktop-${size}`];
    return `@media (${width}-width: ${screenSize})`;
  },
};

/**
 * Media Query Hooks
 * */

export const useMobileMediaQuery = ({
  size,
  width = 'max',
}: MediaQueryMobileProps) => {
  // defaults to 1px smaller than tablet
  const screenSize = size ? SIZE_MAP[`mobile-${size}`] : '767px';

  return useMediaQuery({
    query: `(${width}-width: ${screenSize})`,
  });
};

export const useTabletMediaQuery = ({ width = 'min' }: MediaQueryTabletProps) =>
  useMediaQuery({ query: `(${width}-width: ${SIZE_MAP['tablet']})` });

export const useDesktopMediaQuery = ({
  size = 'sm',
  width = 'min',
}: MediaQueryDesktopProps) => {
  const screenSize = SIZE_MAP[`desktop-${size}`];

  return useMediaQuery({
    query: `(${width}-width: ${screenSize})`,
  });
};
