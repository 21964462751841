import type {
  AlgoliaProduct,
  FullFetchAlgoliaProductParams,
  FullSearchAlgoliaProductsParams,
} from '@jane/search/types';
import {
  AlgoliaProductSchema,
  AlgoliaProductsSchema,
  MenuProductSortSuffix,
} from '@jane/search/types';
import { isDefined, isEmpty, isNotNull } from '@jane/shared/guards';

import { search } from './client';

export async function searchAlgoliaProducts({
  storeId,
  hitsPerPage,
  page,
  searchText,
  categories,
  feelings,
  brands,
  activities,
  prices,
  lineages,
  ratings,
  options,
  thcPotenciesMin,
  thcPotenciesMax,
  cbdPotenciesMin,
  cbdPotenciesMax,
  weights,
  sort = MenuProductSortSuffix.Default,
  storeSpecificProduct,
}: FullSearchAlgoliaProductsParams) {
  const { hits, ...rest } = await search<AlgoliaProduct>({
    booleanFilters: {
      store_specific_product: storeSpecificProduct,
    },
    filters: {
      activities: activities ? activities : [],
      brand: brands ? brands : [],
      root_types: categories ? categories : [],
      feelings: feelings ? feelings : [],
      category: lineages ? lineages : [],
      store_id: storeId ? [storeId] : [],
    },
    bucketFilters: {
      aggregate_rating: ratings ? ratings : [],
      bucket_price: prices ? prices : [],
    },
    indexPrefix: `menu-products-`,
    hitsPerPage,
    page,
    rangeFilters: {
      product_percent_thc: { min: thcPotenciesMin, max: thcPotenciesMax },
      product_percent_cbd: { min: cbdPotenciesMin, max: cbdPotenciesMax },
    },
    searchText,
    sortSuffix: sort,
    options,
  });

  return {
    hits: await AlgoliaProductsSchema.parseAsync(hits),
    ...rest,
  };
}
export async function fetchAlgoliaProduct({
  storeId,
  productId,
  uniqueSlug,
}: FullFetchAlgoliaProductParams): Promise<AlgoliaProduct | null> {
  const filters = {
    ...(productId && { product_id: [productId] }),
    ...(uniqueSlug && { unique_slug: [uniqueSlug] }),
    store_id: [storeId],
  };

  const { hits } = await search<AlgoliaProduct>({
    filters,
    indexPrefix: 'menu-products-',
  });

  if (isEmpty(hits)) return null;

  return await AlgoliaProductSchema.parseAsync(hits[0]);
}

type FetchAlgoliaProductIdsParams = {
  storeId: number;
};

// TODO - Need a new endpoint, this is inefficient
export async function fetchAlgoliaProductIds({
  storeId,
}: FetchAlgoliaProductIdsParams): Promise<number[]> {
  const { hits } = await searchAlgoliaProducts({
    hitsPerPage: 10000,
    storeId: storeId,
  });

  return hits
    .map((p) => p.product_id)
    .filter(isDefined)
    .filter(isNotNull)
    .map((id) => +id);
}
