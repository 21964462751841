import type { AbbreviatedStoreV2 } from '@jane/business-admin/types';
import type { DeliveryConfigStrategy } from '@jane/shared/models';
import { prepareTime } from '@jane/shared/util';

export const StrategyOptions = {
  isRadiusDelivery: 'radius',
  isZipcodeDelivery: 'zipcode',
  isGeofenceDelivery: 'geofence',
};

// Copied from: libs/monolith/shared/src/lib/business/helpers/store.ts
export function getStoreDeliveryOptions(
  delivery_config: DeliveryConfigStrategy
) {
  if (!delivery_config.delivery_strategy) {
    return {
      radius: true,
      zipcode: false,
      geofence: false,
    };
  }

  const radius =
    delivery_config.delivery_strategy === StrategyOptions.isRadiusDelivery;
  const zipcode =
    delivery_config.delivery_strategy === StrategyOptions.isZipcodeDelivery;
  const geofence =
    delivery_config.delivery_strategy === StrategyOptions.isGeofenceDelivery;

  return {
    radius,
    zipcode,
    geofence,
  };
}

export const formatPhoneNumber = (phone: string) => {
  const match = phone.match(/^(\d{3})(\d{3})(\d{4})$/);

  return match ? `(${match[1]}) ${match[2]}–${match[3]}` : null;
};

export function getTwentyFourHours() {
  const hours = [];

  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 4; j++) {
      hours.push({
        value: prepareTime({ hour: i, minute: j * 15 }),
        label: prepareTime({ hour: i, minute: j * 15 }),
      });
    }
  }

  return hours;
}

export const concatAddress = (store: AbbreviatedStoreV2): string => {
  return `${store.address}${store.address2 ? `, ${store.address2}` : ''}, ${
    store.city
  }, ${store.state} ${store.zip}`;
};
