// eslint-disable-next-line no-restricted-imports
import { extendMoment } from 'moment-range';

import type { JaneDate } from './types';

const moment = require('moment-timezone');

export function range(start: JaneDate, end: JaneDate) {
  const momentRange = extendMoment(moment);
  return momentRange.range(start, end);
}

export function getTodaysDateRangeInMinutes(increments: number) {
  const momentRange = extendMoment(moment);

  const start = moment().startOf('day');
  const end = moment().endOf('day');

  return Array.from(momentRange.range(start, end).by('minutes')).filter(
    (_, index) => index % increments === 0
  );
}
