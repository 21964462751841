// eslint-disable-next-line no-restricted-imports
import type { unitOfTime } from 'moment-timezone';
// eslint-disable-next-line no-restricted-imports
import moment from 'moment-timezone';

import { us_timezones } from '../data/us_timezones';
import type { JaneDate } from './types';

export function calculateMinutes(date: string | Date, timezone: string) {
  const parsed = moment(date).tz(timezone);
  const minutes = parsed.minutes();
  const hours = parsed.hours();
  return minutes + hours * 60;
}

export function convertHourMinuteToMoment(hour: number, minute: number) {
  return moment.tz().hour(hour).minute(minute).second(0).millisecond(0);
}

export function convertStringToDate(date: string) {
  return moment(date).toDate();
}

export function convertTimeFormat(
  time: string | JaneDate,
  timeformat = 'hh:mm',
  format = ''
): string {
  return moment(time, timeformat).format(format);
}

export function convertUnixTimeFormat(time: number, format = ''): string {
  return moment.unix(time).format(format);
}

export function currentTime(timezone: string) {
  return moment.tz(timezone);
}

export function differenceFromNow(date: string, timezone: string): number {
  const now = moment().tz(timezone);
  const fromNow = now.diff(date);
  return fromNow;
}

export function durationInYears(time: number): number {
  return moment.duration(time).years();
}

export function duration(time: number | null, unit: unitOfTime.Base) {
  return moment.duration(time, unit);
}

export function format(date: string | Date, format: string): string {
  return moment(date).format(format);
}

export function formatUnixTimeWithTimezone(
  unixTime: number,
  format = '',
  timezone = ''
): string {
  return moment.unix(unixTime).tz(timezone).format(format);
}

export function formatUnixTimeWithCalendar(
  unixTime: number,
  timezone = ''
): string {
  return moment.unix(unixTime).tz(timezone).calendar(undefined, {
    sameDay: '[Today] h:mm a',
    nextDay: '[Tomorrow] h:mm a',
    nextWeek: 'dddd h:mm a',
    lastDay: '[Yesterday] h:mm a',
    lastWeek: '[Last] dddd h:mm a',
    sameElse: 'MM/DD/YYYY h:mm a',
  });
}

export function formatUnixTimeForAllDayDelivery(
  unixTime: number,
  timezone = ''
): string {
  return moment.unix(unixTime).tz(timezone).calendar(undefined, {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd',
    lastDay: 'MM/DD/YYYY',
    lastWeek: 'MM/DD/YYYY',
    sameElse: 'MM/DD/YYYY',
  });
}

export const ISO8601 = moment.ISO_8601;

export function formatUtc(
  date: string,
  inputFormat: any,
  outputFormat: string
) {
  return moment.utc(date, inputFormat).format(outputFormat);
}

export function getUnixTimestamp(): number {
  return moment().unix();
}

export function isBefore(date: string): boolean {
  return moment().isBefore(date);
}

export function isValid(date: string | null | undefined): boolean {
  return moment(date).isValid();
}

export function isSameOrBeforeYears(
  date: string,
  format: string,
  yearsBefore: number
): boolean {
  const dateThreshold = moment().subtract(yearsBefore, 'years');
  return moment(date, format).isSameOrBefore(dateThreshold);
}

export function getStartOfDay(date: string | Date | any) {
  return moment(date).startOf('day').toDate();
}

export function getTomorrow() {
  return moment().add(1, 'days').startOf('day');
}

export function getWeekDays() {
  return moment.weekdays().map((day: string) => ({ label: day, value: day }));
}

export function parse(date: string | number) {
  return moment(date);
}

export function parseWithTimezone(date: string, timezone: string) {
  return moment.tz(date, timezone);
}

export function parseWithFormatAndTimezone(
  date: string,
  format: string,
  timezone: string
) {
  return moment.tz(date, format, timezone);
}

export function parseAndFormat(
  date: string | Date,
  inputFormat: string,
  outputFormat: string
) {
  return moment(date, inputFormat).format(outputFormat);
}

export function parseUnixToTimezone(unixTime: number, timezone: string) {
  return moment.unix(unixTime).tz(timezone);
}

export function prepareTime(options: any, format = 'h:mm A'): string {
  return moment().set(options).format(format);
}

export function timeFromNow(time: number): string {
  const date = moment.unix(time);
  return moment(date).fromNow();
}

export function to(date: string | null): string {
  return moment().to(date);
}

export function getJaneAllowedTimeZones() {
  return us_timezones;
}
