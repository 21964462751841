import type { Reducer } from 'redux';

import type { DeepReadonly, Id, ReservationMode } from '@jane/shared/models';

import { createSimpleAction } from '../../redux-util';
import { CartSource } from '../../sources/cart';
import type { CustomerThunkAction } from '../redux';
import { analyzeSpecialsSuccess } from './cart';
import type { CustomerAction } from './types';

export const RESET_PROMO_CODE_FORM = 'promo-code-form/reset-promo-code-form';
export const resetPromoCodeForm = createSimpleAction(RESET_PROMO_CODE_FORM);

export const APPLY_PROMO_CODE = 'promo-code-form/apply-promo-code';
export const applyPromoCode =
  ({
    promoCode,
    reservationMode,
    userGroupSpecialId,
  }: {
    promoCode: string;
    reservationMode: ReservationMode;
    userGroupSpecialId?: Id;
  }): CustomerThunkAction =>
  (dispatch, getState) => {
    const { cart: cartState } = getState();
    dispatch({ type: APPLY_PROMO_CODE });

    const onSuccess = (data: any) => {
      const { validations } = data;
      if (validations) {
        dispatch(applyPromoCodeError());
        return;
      }

      dispatch(applyPromoCodeSuccess());
      dispatch(
        analyzeSpecialsSuccess({
          cart: data.cart,
          userGroupSpecialId,
        })
      );
    };

    CartSource.validatePromoCode({
      promo_code: promoCode,
      reservation_mode: reservationMode,
      user_group_special_id: userGroupSpecialId,
      uuid: cartState.cart.uuid,
      delivery_address: cartState.deliveryAddress,
    }).then(onSuccess, () => dispatch(applyPromoCodeError()));
  };

export const APPLY_PROMO_CODE_SUCCESS =
  'promo-code-form/apply-promo-code-success';
export const applyPromoCodeSuccess = createSimpleAction(
  APPLY_PROMO_CODE_SUCCESS
);

export const APPLY_PROMO_CODE_ERROR = 'promo-code-form/apply-promo-code-error';
export const applyPromoCodeError = createSimpleAction(APPLY_PROMO_CODE_ERROR);

export type PromoCodeFormActions =
  | ReturnType<typeof resetPromoCodeForm>
  | { type: typeof APPLY_PROMO_CODE }
  | ReturnType<typeof applyPromoCodeSuccess>
  | ReturnType<typeof applyPromoCodeError>;

export type PromoCodeFormState = DeepReadonly<{
  isValid: boolean;
  validated: boolean;
}>;

const getInitialState = (): PromoCodeFormState => ({
  validated: false,
  isValid: false,
});

export const promoCodeFormReducer: Reducer<
  PromoCodeFormState,
  CustomerAction
> = (state = getInitialState(), action) => {
  switch (action.type) {
    case APPLY_PROMO_CODE_SUCCESS:
      return { validated: true, isValid: true };
    case APPLY_PROMO_CODE_ERROR:
      return { validated: true, isValid: false };
    case RESET_PROMO_CODE_FORM:
      return getInitialState();
  }

  return state;
};
