import type { Reducer } from 'redux';

import type {
  CustomerReservationDetails,
  DeepReadonly,
  GuestCustomerReservationDetails,
} from '@jane/shared/models';

import * as ReviewableReservationsActions from './reviewableReservations';
import type { CustomerAction } from './types';
import * as UsersActions from './users';

export type ReservationState = DeepReadonly<{
  guestReservation: GuestCustomerReservationDetails | null;
  hasCancelledReservation: boolean;
  isLoading: boolean;
  isPrepaid: boolean;
  isPrepaying: boolean;
  reservation: CustomerReservationDetails | null;
}>;

const getInitialState = (): ReservationState => ({
  reservation: null,
  guestReservation: null,
  isLoading: true,
  hasCancelledReservation: false,
  isPrepaid: false,
  isPrepaying: false,
});

const setReservation = (
  state: ReservationState,
  { reservation }: { reservation: CustomerReservationDetails }
) => ({
  ...state,
  reservation,
  isPrepaid: reservation.prepaid,
  isLoading: false,
  hasCancelledReservation: reservation.status === 'cancelled',
});

export const reservationReducer: Reducer<ReservationState, CustomerAction> = (
  state = getInitialState(),
  action
) => {
  switch (action.type) {
    case UsersActions.GET_RESERVATION:
      return setReservation(state, action.payload);

    case UsersActions.GET_GUEST_RESERVATION:
      return {
        ...state,
        guestReservation: action.payload.guestReservation,
      };

    case UsersActions.CREATE_REVIEW_SUCCESS:
      if (!state.reservation) {
        return state;
      }
      return {
        ...state,
        reservation: {
          ...state.reservation,
          status: 'with_review',
          rating: action.payload.rating,
        },
      };

    case ReviewableReservationsActions.REFUSE_REVIEW_SUCCESS:
      if (!state.reservation) {
        return state;
      }
      return {
        ...state,
        reservation: {
          ...state.reservation,
          status: action.payload.reservation.status,
        },
      };

    case UsersActions.CANCEL_RESERVATION_SUCCESS:
      if (!state.reservation) {
        return state;
      }
      return {
        ...state,
        hasCancelledReservation: true,
        reservation: { ...state.reservation, status: 'cancelled' },
      };

    case UsersActions.CANCEL_GUEST_RESERVATION_SUCCESS:
      if (!state.guestReservation) {
        return state;
      }
      return {
        ...state,
        guestReservation: {
          ...state.guestReservation,
          cart_status: 'cancelled',
        },
      };

    case UsersActions.NOTIFY_CURBSIDE_PICKUP_ARRIVAL:
      if (!state.reservation) {
        return state;
      }
      return {
        ...state,
        reservation: { ...state.reservation, curbside_customer_arrived: true },
      };

    case UsersActions.NOTIFY_GUEST_CURBSIDE_PICKUP_ARRIVAL:
      if (!state.guestReservation) {
        return state;
      }
      return {
        ...state,
        guestReservation: {
          ...state.guestReservation,
          curbside_customer_arrived: true,
        },
      };

    case UsersActions.PREPAY_RESERVATION:
      return { ...state, isPrepaying: true };

    case UsersActions.PREPAY_RESERVATION_SUCCESS:
      return {
        ...state,
        ...setReservation(state, action.payload),
        isPrepaying: false,
        isPrepaid: true,
      };

    case UsersActions.PREPAY_RESERVATION_ERROR:
      return { ...state, isPrepaying: false };
  }

  return state;
};
