import partition from 'lodash/partition';

import type { KindFacet, KindOrType, SearchResponse } from '@jane/search/types';
import type { Store } from '@jane/shared/models';

import { JANE_DEFINED_ROWS, getUniqueCustomRow } from './searchStateUtils';

const descendingCountThenAlphaSort = (items: KindFacet[]) =>
  items.sort((a, b) => {
    if (a.count === b.count) {
      if (a.kind < b.kind) {
        return -1;
      }

      if (a.kind > b.kind) {
        return 1;
      }

      return 0;
    }

    return b.count - a.count;
  });

export function getKindFacetBuckets(
  searchResponse: SearchResponse,
  { custom_rows, id }: Store
): KindFacet[] | null {
  if (!searchResponse) return null;

  const { facets = {} } = searchResponse;
  const rootTypeFacetData = facets['root_types'];

  // TODO(elliot): Remove this check once all algolia data is correct. This is needed because we do not want to use the *unique* version of a custom row: ie. CustomRow-23 if we don't need to yet
  const facetsAreDisambiguated = Object.keys(rootTypeFacetData || {}).some(
    (rootTypeFacet) => rootTypeFacet.endsWith(`-${id}`)
  );

  const kindFacet = facets['kind'];

  const customRows: KindFacet[] = custom_rows
    ?.filter(Boolean)
    .map((customRowName) => {
      // NOTE(elliot): jane defined rows are not unique in root_types
      const uniqueCustomRowName =
        facetsAreDisambiguated && !JANE_DEFINED_ROWS.includes(customRowName)
          ? getUniqueCustomRow(customRowName, id)
          : customRowName;
      return {
        kind: uniqueCustomRowName,
        count: rootTypeFacetData && rootTypeFacetData[uniqueCustomRowName],
        name: customRowName,
      };
    }) as KindFacet[];

  if (!kindFacet) return null;

  const allKinds: KindFacet[] = Object.keys(kindFacet).map((kind) => ({
    kind: kind as KindOrType,
    count: kindFacet[kind],
  }));
  const [flower, rest] = partition(
    allKinds,
    (kind: KindFacet) => kind.kind === 'flower'
  );

  return (customRows || [])
    .filter((facet) => facet.count)
    .concat(flower.concat(descendingCountThenAlphaSort(rest)));
}
