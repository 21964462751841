import styled from '@emotion/styled';

import { mediaQueries, spacing } from '../../../../styling';
import { mobileInputStyling } from '../field.styles';
import type { StyledFieldProps } from '../field.types';

export const StyledInput = styled.input<StyledFieldProps>(
  {
    height: '48px',
    outline: 'none',
    width: '100%',
    '&:disabled': {
      opacity: `0.3`,
    },
    position: 'relative',
    '&::-webkit-calendar-picker-indicator': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      width: 'auto',
      height: 'auto',
      color: 'transparent',
      background: 'transparent',
    },
  },
  ({ theme: { borderRadius, colors }, radiusSize }) => ({
    background: colors.grays.white,
    border: `1px solid ${colors.grays.light}`,
    borderRadius: borderRadius[radiusSize],
    ...spacing({ py: 12, px: 16 }),
    '&:active, &:focus, &:focus-visible': {
      borderColor: colors.primary.main,
    },
    '&:read-only, &:disabled': {
      borderColor: colors.grays.light,
    },
    [mediaQueries.tablet('max')]: [
      {
        ...mobileInputStyling(),
        backgroundColor: 'transparent',
      },
    ],
  })
);
