import { config } from '@jane/shared/config';
import type { Id } from '@jane/shared/models';

import { request } from '../lib/request';

const baseUrl = `${config.apiPath}/stores`;

export const MenuProductsSource = {
  get: (storeId: Id, productId: Id) => {
    const url = `${baseUrl}/${storeId}/products/${productId}`;
    return request(url, { method: 'GET' });
  },
};
