import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Extracts = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 10C2 4.47715 6.47715 0 12 0C17.5228 0 22 4.47715 22 10V14C22 15.1046 21.1046 16 20 16C18.8954 16 18 15.1046 18 14C18 12.8954 17.1046 12 16 12C14.8954 12 14 12.8954 14 14V22C14 23.1046 13.1046 24 12 24C10.8954 24 10 23.1046 10 22V14C10 12.8954 9.10457 12 8 12C6.89543 12 6 12.8954 6 14V17.9999C6 19.1044 5.10457 19.9999 4 19.9999C2.89543 19.9999 2 19.1044 2 17.9999V10Z"
      fill="#FFC220"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 8.99988C17.4477 8.99988 17 8.55216 17 7.99988C17 6.34302 15.6569 4.99988 14 4.99988C13.4477 4.99988 13 4.55216 13 3.99988C13 3.44759 13.4477 2.99988 14 2.99988C16.7614 2.99988 19 5.23845 19 7.99988C19 8.55216 18.5523 8.99988 18 8.99988Z"
      fill="black"
      fillOpacity={0.5}
      className="details"
    />
  </svg>
);
export const ExtractsIcon = ({ ...props }: DynamicIconProps) => {
  return <Icon icon={Extracts} {...props} color={props.color} />;
};
