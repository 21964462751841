import { ConditionalWrapper } from '@jane/reefer';

import { FilterButtonGrid } from '../filter.styles';
import type {
  FilterRangeItem,
  FilterSelectItem,
  OnDeselectKeys,
} from '../filter.types';
import { FilterButton } from '../filterButton';
import { FilterSlider } from '../filterSlider';
import { NestedRangeWrapper } from './filterModal.styles';
import type { FilterModalSectionProps } from './filterModal.types';
import { ModalSection } from './modalSection';

export const FilterModalSection = ({
  filterGroup,
  isPartnerMenu = false,
  onChange,
  onDeselect,
}: FilterModalSectionProps) => {
  const { items, label, key, nested, type } = filterGroup;

  const itemsPerRow = label === 'Categories' ? 3 : label === 'Specials' ? 2 : 4;

  const isNestedRange = type === 'range' && nested;

  const renderedItems = items.map((item) => {
    if (type === 'range' && !nested) {
      return (
        <FilterSlider item={item as FilterRangeItem} onChange={onChange} />
      );
    }

    if (isNestedRange) {
      const { label, key } = item as FilterRangeItem;
      return (
        <ModalSection
          isPartnerMenu={isPartnerMenu}
          key={label}
          title={label}
          onDeselectClick={() => onDeselect(key as OnDeselectKeys)}
        >
          <FilterSlider item={item as FilterRangeItem} onChange={onChange} />
        </ModalSection>
      );
    }

    return (
      <FilterButton
        filterKey={filterGroup.key}
        key={item.label}
        item={item as FilterSelectItem}
        onChange={onChange}
      />
    );
  });

  return (
    <div data-testid="filter-modal-section">
      {isNestedRange ? (
        <NestedRangeWrapper>{renderedItems}</NestedRangeWrapper>
      ) : (
        <ModalSection
          isPartnerMenu={isPartnerMenu}
          title={label}
          onDeselectClick={() => onDeselect(key)}
        >
          <ConditionalWrapper
            condition={type !== 'range'}
            wrapper={(children) => (
              <FilterButtonGrid
                itemsPerRow={filterGroup.modalItemsPerRow || itemsPerRow}
                mobileItemsPerRow={2}
              >
                {children}
              </FilterButtonGrid>
            )}
          >
            {renderedItems}
          </ConditionalWrapper>
        </ModalSection>
      )}
    </div>
  );
};
