import * as t from 'io-ts';

const tAddress = t.partial({
  id: t.union([t.string, t.number]),
  city: t.union([t.null, t.string]),
  country_code: t.union([t.null, t.string]),
  street: t.union([t.null, t.string]),
  street2: t.union([t.string, t.null]),
  state_code: t.union([t.null, t.string]),
  zipcode: t.union([t.null, t.string]),
  google_place_id: t.union([t.string, t.null]),
  lat: t.union([t.number, t.null]),
  lng: t.union([t.number, t.null]),
});

export type Address = t.TypeOf<typeof tAddress>;
