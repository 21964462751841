import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import {
  matchRoutes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import {
  Box,
  Button,
  Drawer,
  Flex,
  Link,
  Typography,
  useDrawerContext,
} from '@jane/reefer';
import { useQuery } from '@jane/shared-ecomm/hooks';
import { useGetStore } from '@jane/shared/data-access';
import type { DeliveryValidation, StoreSpecial } from '@jane/shared/models';
import { formatCurrency } from '@jane/shared/util';

import { useCustomerDispatch, useCustomerSelector } from '../../../../customer';
import {
  closeCart,
  fixUnavailableCheckout,
  refreshCart,
} from '../../../../customer/redux/cart';
import { paths } from '../../../../lib/routes';
import { CartSource } from '../../../../sources/cart';

interface CartDrawerFooterProps {
  checkoutDisabled: boolean;
  deliveryValidationData: DeliveryValidation;
  handleSubmit: () => void;
  specials: StoreSpecial[];
  total: number;
}

export const CartDrawerFooter = ({
  checkoutDisabled,
  handleSubmit,
  total,
  specials,
}: CartDrawerFooterProps) => {
  const location = useLocation();

  const { onRequestClose } = useDrawerContext();
  const navigate = useNavigate();
  const dispatch = useCustomerDispatch();
  const query = useQuery();
  const { special_id } = useParams();
  const { appMode, cart } = useCustomerSelector(
    ({ cart: { cart }, embeddedApp: { appMode } }) => ({
      appMode,
      cart,
    })
  );
  const { data: store } = useGetStore(cart?.store.id);
  const onSubmit = async () => {
    const { unavailable_product_ids } =
      await CartSource.getUnavailableProductIds(cart.uuid);
    if (checkoutDisabled) return;

    if (isEmpty(unavailable_product_ids)) {
      handleSubmit();
    } else {
      await dispatch(fixUnavailableCheckout());
      dispatch(refreshCart(cart));
    }
  };

  const getContinueShoppingUrl = () => {
    const { fromSpecialId } = query;
    const fromSpecial = find(specials, {
      id: parseInt(special_id) || parseInt(fromSpecialId),
    });

    const storeNameAndId = { id: store.id, name: store.name };

    if (appMode === 'default' || appMode === 'brandEmbed') {
      return fromSpecial
        ? paths.storeSpecial(storeNameAndId, fromSpecial)
        : paths.store(storeNameAndId);
    }

    return paths.embeddedMenu();
  };

  const handleContinueShopping = () => {
    const path = getContinueShoppingUrl();
    const noNavigationRequired = matchRoutes([{ path }], location);

    // if we're already on the menu page, just request the close (which includes the animation),
    // otherwise immediately close the drawer and navigate.
    if (noNavigationRequired) {
      onRequestClose();
    } else {
      dispatch(closeCart());
      navigate(path);
    }
  };

  return (
    <Drawer.Footer noPadding>
      <Box background="grays-white" px={24} pb={24}>
        <Flex flexDirection="column">
          <Flex mt={16} justifyContent="space-between">
            <Typography branded variant="header-bold">
              Estimated Total
            </Typography>
            <Typography branded variant="header-bold" testId="estimated-total">
              {formatCurrency(total)}
            </Typography>
          </Flex>
          <Flex flexDirection="column" alignItems="center">
            <Button
              onClick={onSubmit}
              label="Proceed to Checkout"
              full
              disabled={checkoutDisabled}
              my={16}
            />
            <Link typography="body" onClick={handleContinueShopping}>
              Continue shopping
            </Link>
          </Flex>
        </Flex>
      </Box>
    </Drawer.Footer>
  );
};
