import * as t from 'io-ts';

export const tCanPayTransaction = t.interface({
  amount: t.string,
  auth_code: t.string,
  id: t.number,
  transaction_at: t.string,
  transaction_id: t.string,
  transaction_reversal_at: t.union([t.string, t.null]),
});

export type CanPayTransaction = t.TypeOf<typeof tCanPayTransaction>;
