import {
  fakeButtonProps,
  getAccessibilityProps,
  handleEnterKey,
} from '../../../utils';
import { Image } from '../../image';
import type { CardImageProps } from '../card.types';
import { StyledImage } from './cardImage.styles';

/**
 * The `Card.Image` component can be included as a child of a `Card` component to add an image
 * that spans the full `Card` width. Should be used outside `Card.Content`.
 * */

export const CardImage = ({
  alt,
  ariaLabel,
  ariaLabelledBy,
  fallbackSrc,
  height,
  width,
  onClick,
  role,
  src,
  srcSet,
  testId,
}: CardImageProps) => {
  const a11yProps = getAccessibilityProps(
    { ariaLabel, ariaLabelledBy, role, onClick },
    'Flex'
  );

  return (
    <StyledImage
      data-testid={testId}
      {...a11yProps}
      onClick={onClick}
      onKeyUp={(event) => handleEnterKey(event, onClick)}
      {...(onClick && fakeButtonProps)}
    >
      <Image
        altText={alt}
        fallbackSrc={fallbackSrc}
        height={height}
        width={width || 'auto'}
        src={src}
        srcSet={srcSet}
      />
    </StyledImage>
  );
};
