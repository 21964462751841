import { Flex, Link, Typography } from '@jane/reefer';

export const CartDrawerCancerWarning = () => {
  return (
    <Flex mb="auto" flexDirection="column">
      <Typography variant="mini">
        WARNING: Cancer and reproductive harm
      </Typography>
      <Link href="https://www.P65Warnings.ca.gov" typography="mini">
        www.P65Warnings.ca.gov
      </Link>
    </Flex>
  );
};
