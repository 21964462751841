import omit from 'lodash/omit';
import type { ChangeEvent } from 'react';
import { Field } from 'react-final-form';
import { PatternFormat } from 'react-number-format';

import { Typography, css, useTheme } from '@jane/reefer';

import { TextInput } from '../component-library/text-input';
import type { TextInputFieldProps } from '../component-library/text-input/text-input';
import { parseAndFormat } from '../lib/date';
import { border } from '../style';

const formatIncomingDate = (date: string) =>
  parseAndFormat(date, 'YYYY-MM-DD', 'MM/DD/YYYY');

const formatOutgoingDate = (date: string) =>
  date === 'mm/dd/yyyy'
    ? ''
    : Date.parse(date)
    ? parseAndFormat(date, 'MM/DD/YYYY', 'YYYY-MM-DD')
    : date;

const DATE_FORMAT = '##/##/####';
const DATE_MASK = ['m', 'm', 'd', 'd', 'y', 'y', 'y', 'y'];

interface DateInputProps {
  className?: string;
  date: string;
  invalid: boolean;
  onChange: (arg: string) => void;
  placeholder?: string;
}

// This is a masked input component that does not implement react-final-form validations.
// This should only be used when it's necessary to render a date input outside of a form.
export const DateInput = ({
  date,
  onChange,
  invalid,
  className,
  placeholder,
}: DateInputProps) => {
  const theme = useTheme();

  return (
    <Typography
      css={!className && css(border.thin(theme.colors.grays.light))}
      variant="body"
    >
      <PatternFormat
        defaultValue={date ? formatIncomingDate(date) : ''}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          onChange(formatOutgoingDate(event.target.value))
        }
        format={DATE_FORMAT}
        mask={DATE_MASK}
        aria-invalid={invalid}
        placeholder={placeholder || 'mm/dd/yyyy'}
        type="tel"
        className={className}
      />
    </Typography>
  );
};

// Date input that should only be used in react-final-form <Form /> components.
export const DateInputField = (props: TextInputFieldProps) => (
  <Field label={props.label} name={props.name}>
    {({ meta, input }) => {
      const hasError = (meta.error || meta.submitError) && meta.touched;
      // Show error or helper text
      const helperText = hasError
        ? meta.error || meta.submitError
        : props.helperText;

      return (
        // NumberFormatProps defaultValue type doesn't match @types/react
        // https://github.com/s-yadav/react-number-format/issues/446
        // @ts-ignore
        <PatternFormat
          customInput={TextInput}
          defaultValue={input.value ? formatIncomingDate(input.value) : ''}
          format={DATE_FORMAT}
          mask={DATE_MASK}
          {...props}
          {...omit(input, 'value')}
          placeholder={meta.active ? 'mm/dd/yyyy' : undefined}
          type="tel"
          helperText={helperText}
          error={hasError}
          onBlur={input.onBlur}
          onFocus={input.onFocus}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            input.onChange(formatOutgoingDate(event.target.value))
          }
          onValueChange={({ formattedValue }) => input.onChange(formattedValue)}
        />
      );
    }}
  </Field>
);
