import type { Reducer } from 'redux';

import { config } from '@jane/shared/config';
import type { AdFlight, Id, MenuProduct } from '@jane/shared/models';

import type { SortingMode } from '../../hooks/useAd/state/client/fetchMenuProducts';
import { fetchMenuProducts } from '../../hooks/useAd/state/client/fetchMenuProducts';
import { createSimpleAction, createStandardAction } from '../../redux-util';
import type { CustomerThunkActionCreator } from '../redux';
import type { CustomerAction } from './types';

const FETCH_MENU_PRODUCT_HITS = 200;

export const FILTER_AD_MENU_PRODUCTS_SUCCESS =
  'ad-candidates/filter-ad-menu-products-success';
export const filterAdMenuProductSuccess = createStandardAction(
  FILTER_AD_MENU_PRODUCTS_SUCCESS
)<number[]>();

export const FILTER_AD_MENU_PRODUCTS_ERROR =
  'ad-candidates/filter-ad-menu-products-error';
export const filterAdMenuProductError = createSimpleAction(
  FILTER_AD_MENU_PRODUCTS_ERROR
);

export const getAdMenuProducts: CustomerThunkActionCreator<{
  filters: string;
  sortingMode?: SortingMode;
  trackDmError?: (error: Error | string) => void;
}> =
  ({ filters, sortingMode, trackDmError }) =>
  (dispatch) => {
    fetchMenuProducts({
      filters,
      sortingMode,
      hitsPerPage: FETCH_MENU_PRODUCT_HITS,
    }).then(
      (result) => {
        dispatch(getAdMenuProductSuccess(result));
      },
      (err) => {
        trackDmError && trackDmError(err);
        dispatch(getAdMenuProductError());
      }
    );
  };

export const GET_AD_MENU_PRODUCTS_SUCCESS =
  'ad-candidates/get-ad-menu-products-success';
export const getAdMenuProductSuccess = createStandardAction(
  GET_AD_MENU_PRODUCTS_SUCCESS
)<MenuProduct[]>();

export const GET_AD_MENU_PRODUCTS_ERROR =
  'ad-candidates/get-ad-menu-products-error';
export const getAdMenuProductError = createSimpleAction(
  GET_AD_MENU_PRODUCTS_ERROR
);

export const SET_KEYWORD_MATCHED_ADS = 'ad-candidates/set-keyword-matched-ads';
export const setKeywordMatchedAds = createStandardAction(
  SET_KEYWORD_MATCHED_ADS
)<KeywordMatchedAds>();

export const RESET_AD_CANDIDATES = 'ad-candidates/reset';
export const resetAdCandidates = createSimpleAction(RESET_AD_CANDIDATES);

export const GET_INLINE_ADS = 'ad-candidates/post';
export const GET_INLINE_ADS_SUCCESS = 'ad-candidates/post-success';
export const getInlineAdsSuccess = createStandardAction(
  GET_INLINE_ADS_SUCCESS
)<{ adDecisions: ResponseBody }>();
export const GET_INLINE_ADS_ERROR = 'ads-candidates/get-error';
export const getInlineAdsError = createSimpleAction(GET_INLINE_ADS_ERROR);

export type AdCandidatesActions =
  | { type: typeof GET_INLINE_ADS }
  | { type: typeof GET_INLINE_ADS_ERROR }
  | { type: typeof RESET_AD_CANDIDATES }
  | { type: typeof GET_AD_MENU_PRODUCTS_ERROR }
  | ReturnType<typeof getInlineAdsSuccess>
  | ReturnType<typeof getAdMenuProductSuccess>
  | ReturnType<typeof setKeywordMatchedAds>;

export type KeywordMatchedAds = {
  [key: string]: MenuProduct[];
};

export type AdCandidatesState = {
  adDecisions: ResponseBody;
  isFetchingAds: boolean;
  keywordMatchedAds: KeywordMatchedAds;
  menuProducts: MenuProduct[];
};

export const getInitialState = (): AdCandidatesState => ({
  adDecisions: { flights: null, rows: null },
  isFetchingAds: false,
  keywordMatchedAds: {},
  menuProducts: [],
});

export const adCandidatesReducer: Reducer<AdCandidatesState, CustomerAction> = (
  state = getInitialState(),
  action
) => {
  switch (action.type) {
    case GET_INLINE_ADS: {
      return {
        ...state,
        isFetchingAds: true,
      };
    }

    case GET_INLINE_ADS_SUCCESS: {
      const { adDecisions } = action.payload;
      return {
        ...state,
        adDecisions,
        isFetchingAds: false,
      };
    }

    case GET_INLINE_ADS_ERROR: {
      return {
        ...state,
        isFetchingAds: false,
      };
    }

    case GET_AD_MENU_PRODUCTS_SUCCESS: {
      return {
        ...state,
        menuProducts: action.payload || [],
      };
    }

    case GET_AD_MENU_PRODUCTS_ERROR: {
      return {
        ...state,
        menuProducts: [],
      };
    }

    case SET_KEYWORD_MATCHED_ADS: {
      return {
        ...state,
        keywordMatchedAds: action.payload,
      };
    }

    case RESET_AD_CANDIDATES: {
      return {
        ...getInitialState(),
      };
    }
  }

  return state;
};

export const getInlineAdsPost: CustomerThunkActionCreator<{
  id: Id;
  reqBody: RequestBody;
}> =
  ({ id, reqBody }) =>
  (dispatch) => {
    dispatch({ type: GET_INLINE_ADS });
    fetchInlineAdsPost(id, reqBody).then(
      (result) => {
        dispatch(getInlineAdsSuccess({ adDecisions: result }));
      },
      () => {
        dispatch(getInlineAdsError());
      }
    );
  };

async function fetchInlineAdsPost(
  storeId: Id,
  body: RequestBody
): Promise<ResponseBody> {
  const url = `${config.dmUrl}/stores/${storeId}/inline`;
  const httpResp = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  if (!httpResp.ok) {
    return { flights: null, rows: null };
  }
  return httpResp.json().then((data) => data as ResponseBody);
}

interface RequestBody {
  app_mode: string;
  count: number;
  jane_device_id: string;
  keywords: string[];
  search_filter?: SearchFilterBody;
  zone: string;
}

/* eslint-disable typescript-sort-keys/interface */
interface SearchFilterBody {
  refinement_list?: {
    activities?: string[];
    available_weights?: string[];
    brands?: string[];
    categories?: string[];
    feelings?: string[];
    root_types?: string[];
  };
  range?: {
    // single string format: "1:10", ":10", "1:"
    aggregate_rating?: string;
    bucket_price?: string;

    // json format:{"min":"1","max":"10"}, {"min":1}, {"max":10}
    percent_cbd?: { min?: string; max?: string };
    percent_thc?: { min?: string; max?: string };
  };
}

interface ResponseBody {
  flights: AdFlight[] | null;
  rows: ResponseRow[] | null;
}

interface ResponseRow {
  flight_id: number;
  keyword: string;
  product_id: number;
  my_high_d?: string;
}
