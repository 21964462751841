import delay from 'lodash/delay';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import type { Reducer } from 'redux';

import { config } from '@jane/shared/config';
import type {
  DeepReadonly,
  ExtendedReservation,
  JanePrinter,
  MenuProduct,
  Store,
  StoreDeliveryGeofence,
} from '@jane/shared/models';

import type { BusinessUserStore } from '../../business/redux/businessUser';
import type { setPreviousPath } from '../../customer/redux/users';
import { SET_PREVIOUS_PATH } from '../../customer/redux/users';
import { createSimpleAction, createStandardAction } from '../../redux-util';
import type { CommonDispatch, CommonThunkAction } from '../redux';
import type { CommonActions } from './types';

/* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
declare global {
  interface Window {
    Appcues: any;
    GA4_ENABLED: boolean;
    JANE_GOOGLE_ANALYTICS_ENHANCED_ECOMMERCE_ENABLED: boolean;
  }
}

export const APPLICATION_OPEN_MODAL = 'application/open-modal';

export const APPLICATION_ADD_GOOGLE_ANALYTICS_INSTANCE =
  'application/add-google-analytics-instance';

export type OpenModalParams =
  | { currentStore: BusinessUserStore; name: 'offlineOff' }
  | { currentStore: BusinessUserStore; name: 'offlineOn' }
  | { name: 'declineCustomerReservation'; reservation: ExtendedReservation }
  | {
      addRule: (
        type: string,
        options: Array<string>,
        updateField: (value) => void
      ) => void;
      mode: string;
      name: 'editSpecialRules';
      nested: boolean;
      options: Array<any>;
      rules: Map<string, string>;
      title: string;
    }
  | {
      addProductsRule: (product) => void;
      currentSet: Set<string | number>;
      filterOptions: {
        brands: Array<string>;
        kinds: Array<string>;
        lineages: Array<string>;
      };
      mode: string;
      name: 'addIndividualProducts';
      title: string;
    }
  | { name: 'acceptReservation'; reservation: ExtendedReservation }
  | { name: 'declineReservationAssignment'; reservation: ExtendedReservation }
  | { goBack?: () => void; isCheckoutAlert?: boolean; name: 'cartAlerts' }
  | {
      canSetEquivalency: unknown;
      name: 'editCartLimitRule';
      onSubmit: unknown;
      productTypes: unknown;
      rule: unknown;
      selectedProductTypes: unknown;
      title: unknown;
    }
  | {
      context: 'staff';
      manager: {};
      name: 'editMember';
    }
  | {
      context: 'store';
      manager: {};
      name: 'editMember';
      storeId: number;
    }
  | {
      context: 'store' | 'staff' | string;
      managerId: number;
      name: 'deleteMember';
      storeId?: number;
    }
  | { context: 'staff'; name: 'inviteMember' }
  | { name: 'kioskStartOver' }
  | { name: 'kioskSkipLanding' }
  | { name: 'login' }
  | {
      action: 'create' | 'update';
      dispatch: Function;
      itemIndex?: number;
      name: 'menuRow';
      productTypes: any;
      ranking?: number;
      row?: any;
      storeId: number | string;
    }
  | { name: 'logout' }
  | { name: 'photoGallery'; photoMetadata: unknown; product: MenuProduct }
  | { name: 'replaceCart' }
  | { name: 'reservationReview' }
  | { name: 'termsAndPrivacy' }
  | { name: 'updateReservation'; newStatus: string; reservation: unknown }
  | { name: 'editPrinter'; onSubmit: unknown; printer: JanePrinter }
  | {
      geofence?: StoreDeliveryGeofence;
      handleResponse: Function;
      name: 'editGeofenceModal';
      store: Store;
    }
  | { image: string; name: 'zoomIn'; orientation?: any }
  | {
      id: number | string;
      isMember: boolean;
      name: 'toggleCustomersMembership';
    };

export const openModal = createStandardAction(APPLICATION_OPEN_MODAL)<
  {
    disallowClose?: boolean;
  } & OpenModalParams
>();

export const addGoogleAnalyticsInstance = createStandardAction(
  APPLICATION_ADD_GOOGLE_ANALYTICS_INSTANCE
)<{ trackingId: string }>();

export const APPLICATION_SET_JANE_DEVICE_ID = 'application/set-jane-device-id';

export const setJaneDeviceId = createStandardAction(
  APPLICATION_SET_JANE_DEVICE_ID
)<{ janeDeviceId: string }>();

export const APPLICATION_CLOSE_MODAL = 'application/close-modal';
export const closeModal = createSimpleAction(APPLICATION_CLOSE_MODAL);

export const APPLICATION_SHOW_NOTIFICATION = 'application/show-notification';
export const showNotification = createStandardAction(
  APPLICATION_SHOW_NOTIFICATION
)<{ messages: string[]; type: string }>();

export const APPLICATION_HIDE_NOTIFICATION = 'application/hide-notification';
export const hideNotification = createSimpleAction(
  APPLICATION_HIDE_NOTIFICATION
);

const APPEAR_DELAY = 6000;

const hideWithDelay = (dispatch: CommonDispatch, appear_delay?: number) => {
  delay(() => dispatch(hideNotification()), appear_delay || APPEAR_DELAY);
};

export const showSuccessNotification =
  (message: string): CommonThunkAction =>
  (dispatch) => {
    if (!message) return;

    dispatch(
      showNotification({
        messages: [message],
        type: 'success',
      })
    );

    hideWithDelay(dispatch);
  };

export const showErrorNotification =
  (message: string, appear_delay?: number): CommonThunkAction =>
  (dispatch) => {
    if (!message) return;
    dispatch(
      showNotification({
        messages: [message],
        type: 'error',
      })
    );

    hideWithDelay(dispatch, appear_delay);
  };

export type ApplicationActions =
  | ReturnType<typeof openModal>
  | ReturnType<typeof closeModal>
  | ReturnType<typeof showNotification>
  | ReturnType<typeof hideNotification>
  | ReturnType<typeof setPreviousPath>
  | ReturnType<typeof setJaneDeviceId>
  | ReturnType<typeof addGoogleAnalyticsInstance>;

export type ApplicationState = DeepReadonly<{
  googleAnalyticsInstance: string | null;
  isModalOpen: boolean;
  janeDeviceId: string | undefined;
  modalName: string;
  modalOptions: any;
  notificationOptions: {
    messages?: string[];
    type?: any;
  };
  notificationShown: boolean;
}>;

const getInitialState = (): ApplicationState => ({
  isModalOpen: false,
  modalName: '',
  modalOptions: {},
  notificationShown: false,
  notificationOptions: {},
  janeDeviceId: undefined,
  googleAnalyticsInstance: null,
});

const initializeEcommerceGa = function (
  trackingId: string,
  clientId?: string,
  referrer?: string
) {
  ReactGA.initialize(trackingId, {
    debug: false,
    testMode: config.nodeEnv === 'test',
    gaOptions: {
      cookieFlags: 'SameSite=None; Secure',
      cookieDomain: 'auto',
      clientId,
    },
  });

  ReactGA4.initialize(trackingId, {
    testMode: config.nodeEnv === 'test',
    gaOptions: {
      cookieFlags: 'SameSite=None; Secure',
      cookieDomain: 'auto',
    },
  });

  try {
    // @ts-ignore
    const trackers = window?.ga?.getAll() || [];
    trackers.forEach((tracker: any) => tracker.set('referrer', referrer));
  } catch (e) {
  } finally {
    ReactGA.set({ hostname: window.location.hostname, referrer });
    if (referrer) {
      Object.defineProperty(document, 'referrer', {
        get: () => referrer,
      });
    }
    ReactGA.ga('send', 'event', 'meta', 'iframe', 'loaded');
    ReactGA.plugin.require('ec');
  }
};

const removeGa = function () {
  // @ts-ignore
  if (window.ga) {
    // @ts-ignore
    window.ga('remove');
  }
};

export const applicationReducer: Reducer<ApplicationState, CommonActions> = (
  state = getInitialState(),
  action
) => {
  switch (action.type) {
    case APPLICATION_OPEN_MODAL: {
      const { name, ...rest } = action.payload;
      return {
        ...state,
        isModalOpen: true,
        modalName: name,
        modalOptions: rest,
      };
    }
    case APPLICATION_CLOSE_MODAL:
      return { ...state, isModalOpen: false, modalName: '', modalOptions: {} };
    case APPLICATION_SHOW_NOTIFICATION:
      return {
        ...state,
        notificationShown: true,
        notificationOptions: action.payload,
      };
    case APPLICATION_HIDE_NOTIFICATION:
      return {
        ...state,
        notificationShown: false,
        notificationOptions: {},
      };
    case SET_PREVIOUS_PATH: {
      const { janeDeviceId } = state;
      return {
        ...getInitialState(),
        janeDeviceId,
      };
    }
    case APPLICATION_SET_JANE_DEVICE_ID: {
      const { janeDeviceId } = action.payload;
      return {
        ...state,
        janeDeviceId,
      };
    }
    case APPLICATION_ADD_GOOGLE_ANALYTICS_INSTANCE: {
      const { trackingId } = action.payload;
      if (
        !state.googleAnalyticsInstance ||
        state.googleAnalyticsInstance !== trackingId
      ) {
        Object.defineProperty(
          window,
          'JANE_GOOGLE_ANALYTICS_ENHANCED_ECOMMERCE_ENABLED',
          { value: true, writable: false }
        );

        Object.defineProperty(window, 'GA4_ENABLED', {
          value: !trackingId.startsWith('UA'),
          writable: false,
        });

        initializeEcommerceGa(trackingId);
        window.addEventListener('message', function (event) {
          /// expects message as follows
          /// frameWindow.postMessage({ messageType: 'initializeGa', payload: { clientId: '123' }}, "*")
          if (
            event.data &&
            event.data.messageType === 'initializeGa' &&
            event.data.payload?.clientId
          ) {
            removeGa();
            initializeEcommerceGa(
              trackingId,
              event.data.payload?.clientId,
              event.data.payload?.referrer
            );
          }
        });

        return {
          ...state,
          googleAnalyticsInstance: trackingId,
        };
      }
    }
  }

  return state;
};
