export const buildOptionalFilterString = (
  accumulator: string,
  filterEntry: [string, readonly (number | string)[]],
  index: number
) => {
  const facetKey = filterEntry[0];
  const facetValues = filterEntry[1];

  if (Array.isArray(facetValues)) {
    const stringPerFacetKey = facetValues.reduce(
      (facetAcc, facetValue, index) =>
        facetAcc + (index === 0 ? '' : ',') + `${facetKey}:${facetValue}`,
      ''
    );

    return (accumulator += (index === 0 ? '' : ',') + `${stringPerFacetKey}`);
  }

  return (accumulator += '');
};
