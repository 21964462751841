import { Button, Flex, Popover, Typography, styled } from '@jane/reefer';

import { ellipsizeText } from '../../../../style/ellipsizeText';

export const QuantityButton = styled(Button)({
  width: '100%',
  marginLeft: 'auto',
});

export const StyledProductName = styled(Typography)({
  ...ellipsizeText(),
  whiteSpace: 'nowrap',
  maxWidth: '100%',
});

// TODO(Kyr): Update Popover component to handle full-width targets
export const StyledPopover = styled(Popover)({
  width: '100%',
});

export const StyledTypography = styled(Typography)({
  textTransform: 'capitalize',
});

export const ProductDetailsContainer = styled(Flex)({
  cursor: 'pointer',
});
