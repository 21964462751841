import type { CSSObject } from '@emotion/styled';
import styled from '@emotion/styled';

import type { ReeferTheme } from '../../types';

interface StyledTableProps {
  firstCellCheckbox: boolean;
  freezeFirstColumn: boolean;
  freezeLastColumn: boolean;
  scrollable: boolean;
}

const freezeColumns = (
  freezeFirstColumn: boolean,
  freezeLastColumn: boolean,
  firstCellCheckbox: boolean,
  theme: ReeferTheme
) => {
  const sharedSticky: CSSObject = { position: 'sticky', zIndex: 5 };
  let asObject: CSSObject = {};
  if (freezeLastColumn) {
    asObject = {
      ...asObject,
      '& td:last-child': {
        ...sharedSticky,
        right: 0,
        boxShadow: `-7px 0px 7px -3px ${theme.colors.grays.ultralight}`,
      },
      '& th:last-child': {
        ...sharedSticky,
        right: 0,
      },
    };
  }
  if (freezeFirstColumn && !firstCellCheckbox) {
    asObject = {
      ...asObject,
      '& td:first-of-type': {
        ...sharedSticky,
        left: 0,
        boxShadow: `7px 0px 7px -3px ${theme.colors.grays.ultralight}`,
      },
      '& th:first-of-type': {
        ...sharedSticky,
        left: 0,
      },
      '& td:nth-of-type(2), th:nth-of-type(2)': {
        paddingLeft: '24px',
      },
    };
  }
  if (freezeFirstColumn && firstCellCheckbox) {
    asObject = {
      ...asObject,
      '& td:first-of-type': {
        ...sharedSticky,
        left: 0,
      },
      '& th:first-of-type': {
        ...sharedSticky,
        left: 0,
      },
      '& td:nth-of-type(2)': {
        ...sharedSticky,
        left: 76,
        boxShadow: `7px 0px 7px ${theme.colors.grays.ultralight}`,
      },
      '& th:nth-of-type(2)': {
        ...sharedSticky,
        left: 76,
      },
      '& td:nth-of-type(3), th:nth-of-type(3)': {
        paddingLeft: '24px',
      },
    };
  }
  return asObject;
};

export const StyledTable = styled.table<StyledTableProps>(
  ({
    theme,
    freezeLastColumn,
    firstCellCheckbox,
    freezeFirstColumn,
    scrollable,
  }) => ({
    position: 'relative',
    width: '100%',
    height: '100%',
    display: scrollable ? 'block' : 'table',
    tableLayout: 'fixed',
    borderSpacing: 0,
    backgroundColor: theme.colors.grays.white,
    whiteSpace: 'nowrap',
    maxWidth: 'fit-content',
    margin: '0 auto',
    borderCollapse: 'separate',
    ...freezeColumns(
      freezeFirstColumn,
      freezeLastColumn,
      firstCellCheckbox,
      theme
    ),
  })
);
