import type { CSSObject } from '@jane/reefer';
import { shadows } from '@jane/reefer';

import { media, mediaQueries } from './media';

export const gridItem = (): CSSObject => ({
  boxShadow: shadows.hard,
  cursor: 'pointer',
  transition: 'box-shadow .3s',
  [media(mediaQueries.hover)]: {
    '&:hover': {
      boxShadow: '0 2px 11px 0 rgba(108, 108, 108, 0.5)',
    },
    '&:hover:active': {
      boxShadow: '0 2px 4px 0 rgba(212, 212, 212, 0.5)',
    },
  },
});
