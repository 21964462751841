import { z } from 'zod';

import type { SearchOptions } from '../../janeAlgoliaTypes';
import type { MenuProductSortSuffix } from '../../schemas';

// TODO(Sarah): This is duplicating a type we have created with io-ts
// See /client/libs/shared/models/src/lib/coordinates.ts
export const CoordinatesSchema = z.object({
  lat: z.number(),
  long: z.number(),
});

export type Coordinates = z.infer<typeof CoordinatesSchema>;

const Options: z.ZodType<SearchOptions> = z.object({
  facets: z.optional(z.array(z.string())),
  facetFilters: z.optional(z.array(z.string())),
  restrictSearchableAttributes: z.optional(z.array(z.string())),
  typoTolerance: z.optional(
    z.union([z.boolean(), z.literal('min'), z.literal('strict')])
  ),
});

export const SearchAlgoliaProductsParamsSchema = z.object({
  activities: z.optional(z.union([z.array(z.string()), z.null()])),
  brands: z.optional(z.union([z.array(z.string()), z.null()])),
  categories: z.optional(z.union([z.array(z.string()), z.null()])),
  cbdPotenciesMax: z.optional(z.number()),
  cbdPotenciesMin: z.optional(z.number()),
  coordinates: z.optional(CoordinatesSchema),
  enabled: z.optional(z.boolean()),
  feelings: z.optional(z.union([z.array(z.string()), z.null()])),
  hitsPerPage: z.optional(z.union([z.number(), z.string()])),
  lineages: z.optional(z.union([z.array(z.string()), z.null()])),
  options: z.optional(Options),
  page: z.optional(z.union([z.number(), z.string()])),
  prices: z.optional(z.union([z.array(z.string()), z.null()])),
  ratings: z.optional(z.union([z.array(z.string()), z.null()])),
  searchRadius: z.optional(z.number()),
  searchText: z.optional(z.union([z.string(), z.null()])),
  storeId: z.optional(z.number()),
  storeSpecificProduct: z.optional(z.boolean()),
  thcPotenciesMax: z.optional(z.number()),
  thcPotenciesMin: z.optional(z.number()),
  weights: z.optional(z.union([z.array(z.string()), z.null()])),
});

export type FullSearchAlgoliaProductsParams = z.infer<
  typeof SearchAlgoliaProductsParamsSchema
> & { sort?: MenuProductSortSuffix };

export type SearchAlgoliaProductsParams = z.infer<
  typeof SearchAlgoliaProductsParamsSchema
> & { sort?: MenuProductSortSuffix };

export const FetchAlgoliaProductParamsSchema = z.object({
  productId: z.optional(z.number()),
  storeId: z.number(),
  uniqueSlug: z.optional(z.string()),
});

export type FullFetchAlgoliaProductParams = z.infer<
  typeof FetchAlgoliaProductParamsSchema
>;

export type FetchAlgoliaProductParams = z.infer<
  typeof FetchAlgoliaProductParamsSchema
>;
