import { ReactModalAdapter, mediaQueries, styled } from '@jane/reefer';

const MOBILE_BUTTON_POSITION = 24;
const DESKTOP_BUTTON_POSITION = 40;

export const StyledLightboxModal = styled(ReactModalAdapter)(({ theme }) => ({
  '.ReactModal__Overlay': {
    position: 'fixed',
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
    zIndex: '99999999',
    opacity: 0,
    transition: 'opacity 200ms ease',
    background: theme.colors.grays.black,
  },
  '.ReactModal__Overlay--after-open': {
    opacity: 1,
  },
  '.ReactModal__Overlay--before-close': {
    opacity: 0,
  },
}));

export const StyledButtonWrapper = styled.div({
  position: 'absolute',
  zIndex: '99999999',
  top: MOBILE_BUTTON_POSITION,
  left: MOBILE_BUTTON_POSITION,
  [mediaQueries.desktop('sm', 'min')]: {
    top: DESKTOP_BUTTON_POSITION,
    left: DESKTOP_BUTTON_POSITION,
  },
});

export const StyledModalContent = styled.div({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  overflow: 'hidden',
});

export const StyledLightboxContent = styled.div({
  height: '100%',
  width: '100%',
  [mediaQueries.desktop('sm', 'min')]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
