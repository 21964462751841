import { Box, mediaQueries, styled } from '@jane/reefer';

export type ScrollSnapAlignType = 'none' | 'start' | 'end' | 'center';

interface CarouselCardStyledProps {
  scrollSnapAlign: ScrollSnapAlignType;
  width?: number;
  widthMobile?: number;
}

export const CarouselCardStyled = styled(Box)(
  ({ scrollSnapAlign, width, widthMobile }: CarouselCardStyledProps) => ({
    paddingRight: 1, // NOTE(sarah): Slighty hacky solution for chrome bug where the the edge of cards are very obviously cut off
    scrollSnapAlign,
    width: widthMobile,
    [mediaQueries.tablet('min')]: {
      width: width,
    },
  })
);
