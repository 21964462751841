import styled from '@emotion/styled';

import { spacing } from '../../styling';
import type { MarginProperties } from '../../styling';
import type { ComponentColors, ReeferTheme } from '../../types';
import type { BannerVariants } from './banner.types';

interface StyledBannerProps extends MarginProperties {
  full: boolean;
  variant: BannerVariants;
}

export const colorMap: { [key: string]: ComponentColors } = {
  error: 'error-dark',
  info: 'grays-black',
  success: 'success-dark',
};

const bannerVariants = (variant: BannerVariants, theme: ReeferTheme) => {
  switch (variant) {
    case 'error':
      return {
        backgroundColor: theme.colors.error.light,
      };
    case 'success':
      return {
        backgroundColor: theme.colors.success.light,
      };
    default:
      return {
        backgroundColor: theme.colors.grays.ultralight,
      };
  }
};

export const StyledBanner = styled.div<StyledBannerProps>(
  {
    alignItems: 'center',
    backgroundColor: 'transparent',
    display: 'inline-flex',
    padding: '16px',
  },
  ({ theme, variant, full, ...marginProps }) => ({
    borderRadius: theme.borderRadius.sm,
    justifyContent: full ? 'space-between' : 'start',
    width: full ? '100%' : 'auto',
    ...spacing(marginProps),
    ...bannerVariants(variant, theme),
  })
);
