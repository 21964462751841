import * as t from 'io-ts';

export const tDocument = t.intersection([
  t.interface({
    first_name: t.string,
    last_name: t.string,
  }),
  t.partial({
    birth_date: t.union([t.string, t.null]),
    government_photo: t.union([t.string, t.null]),
    government_assistance_photo: t.union([t.string, t.null]),
    mmj_card: t.union([t.string, t.null]),
    mmj_card_back: t.union([t.string, t.null]),
    mmj_card_id_number: t.union([t.string, t.null]),
    student_photo: t.union([t.string, t.null]),
    veteran_photo: t.union([t.string, t.null]),
    customer_uid: t.union([t.string, t.null]),
    client_token: t.union([t.string, t.null]),
    transaction_id: t.union([t.number, t.null]),
    berbix_status_verification: t.union([t.string, t.null]),
  }),
]);

export type Identification = t.TypeOf<typeof tDocument>;
