import type { PopoverContentProps } from '../popover.types';
import { StyledPopoverContent } from './popoverContent.styles';

export function PopoverContent({
  label,
  children,
  padding = true,
}: PopoverContentProps) {
  return (
    <StyledPopoverContent aria-label={label} padding={padding}>
      {children}
    </StyledPopoverContent>
  );
}
