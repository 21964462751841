import isObject from 'lodash/isObject';

import { SliderField } from '@jane/reefer';

import { useActiveFilterContext } from './activeFilterProvider';
import type { FilterRangeProps, FilterRangeValue } from './filter.types';

export const FilterSlider = ({ item, onChange }: FilterRangeProps) => {
  const { label, max, min, key } = item;

  const { activeFilters } = useActiveFilterContext();

  const filteredRange = activeFilters
    .map((filter) => {
      if (isObject(filter.value)) {
        if (filter.key === key) {
          return filter.value as FilterRangeValue;
        }
      }
      return undefined;
    })
    .filter((filter) => filter !== undefined)[0] as FilterRangeValue;

  const minValue = typeof min === 'string' ? parseInt(min) : min;
  const maxValue = typeof max === 'string' ? parseInt(max) : max;

  const rangeMin = Math.floor(minValue);
  const rangeMax = Math.ceil(maxValue);

  const defaultValue = {
    min: filteredRange?.min || rangeMin,
    max: filteredRange?.max || rangeMax,
  };

  return (
    <SliderField
      label={label}
      labelHidden
      defaultValue={defaultValue}
      value={defaultValue}
      max={rangeMax}
      min={rangeMin}
      step={1}
      onChange={(value: FilterRangeValue) => {
        onChange(key, value);
      }}
      isDouble={true}
      name={label}
      width="100%"
      endUnit="%"
      disabled={rangeMin === rangeMax}
    />
  );
};
