import {
  Button,
  CheckboxField,
  Flex,
  Link,
  Logo,
  Modal,
  Typography,
  styled,
} from '@jane/reefer';

import { paths } from '../../lib/routes';
import { Box } from '../../style/layout';

interface AgeGateModalProps {
  onConfirm: () => void;
  rememberMe: boolean;
  setRememberMe: (rememberMe: boolean) => void;
  showModal: boolean;
}

const MESSAGE = `Please confirm you're over 21 or a valid medical patient.`;
const MESSAGE_ID = 'age-message';

const Container = styled.div({ maxWidth: 327 });

const AgeGateModal = ({
  showModal,
  onConfirm,
  rememberMe,
  setRememberMe,
}: AgeGateModalProps) => {
  if (showModal) {
    const body = document.querySelector('body');
    body && body.classList.add('ReactModal__Body--open');
  }

  if (!showModal) return null;

  return (
    <Modal
      contentLabel="Confirm your age"
      open={showModal}
      onRequestClose={() => {}}
      variant="dialogue"
    >
      <Modal.Content padding={false}>
        <Container aria-describedby={MESSAGE_ID}>
          <Box m={24}>
            <Flex justifyContent="center" alignItems="center">
              <Logo color="black" variant="primary" size="sm" />
            </Flex>

            <Typography
              as="div"
              mt={12} // reefer logo has internal padding of 11px and the desired distance is 24px
              branded
              variant="header-bold"
              id={MESSAGE_ID}
              aria-label={MESSAGE}
              textAlign="center"
            >
              {MESSAGE}
            </Typography>

            <Flex justifyContent="center" alignItems="center">
              <CheckboxField
                checked={rememberMe}
                defaultChecked={false}
                mt={24}
                label="Remember me for 30 days"
                name="remember_confirmed_conditions"
                onChange={setRememberMe}
              />
            </Flex>

            <Box my={24} width="100%">
              <Button full onClick={onConfirm} label="Confirm" branded />
            </Box>

            <Typography color="grays-mid" variant="mini" textAlign="center">
              By clicking &quot;Confirm&quot; I agree to Jane&apos;s
              <br />
              <Link href={paths.termsOfUse()} color="grays-mid">
                Terms of Service
              </Link>
              {' & '}
              <Link href={paths.privacyPolicy()} color="grays-mid">
                Privacy Policy
              </Link>
            </Typography>
          </Box>
        </Container>
      </Modal.Content>
    </Modal>
  );
};

export default AgeGateModal;
