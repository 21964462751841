import { z } from 'zod';

export const AlgoliaBrandPartnerStoreSchema = z.object({
  brand_partner_id: z.optional(z.number()),
  current_deals: z.optional(z.array(z.string())),
  open_now: z.optional(z.string()),
  type: z.optional(z.array(z.string())),
});

export type AlgoliaBrandPartnerStore = z.infer<
  typeof AlgoliaBrandPartnerStoreSchema
>;

export const AlgoliaBrandPartnerStoreFacetsSchema = z.object({
  current_deals: z.optional(z.record(z.string(), z.number())),
  open_now: z.optional(z.record(z.string(), z.number())),
  type: z.optional(z.record(z.string(), z.number())),
});

export type AlgoliaBrandPartnerStoreFacets = z.infer<
  typeof AlgoliaBrandPartnerStoreFacetsSchema
>;
