import { z } from 'zod';

export const AlgoliaFacetsSchema = z.object({
  kind: z.optional(z.record(z.string(), z.number())),
  brand: z.optional(z.record(z.string(), z.number())),
  category: z.optional(z.record(z.string(), z.number())),
  feelings: z.optional(z.record(z.string(), z.number())),
  activities: z.optional(z.record(z.string(), z.number())),
  root_types: z.optional(z.record(z.string(), z.number())),
  percent_cbd: z.optional(z.record(z.string(), z.number())),
  percent_thc: z.optional(z.record(z.string(), z.number())),
  brand_subtype: z.optional(z.record(z.string(), z.number())),
  available_weights: z.optional(z.record(z.string(), z.number())),
  applicable_special_ids: z.optional(z.record(z.string(), z.number())),
  aggregate_rating: z.optional(z.record(z.string(), z.number())),
  bucket_price: z.optional(z.record(z.string(), z.number())),
});

export type AlgoliaFacets = z.infer<typeof AlgoliaFacetsSchema>;

export type ProductFilterKeys =
  | 'activities'
  | 'aggregate_rating'
  | 'applicable_special_ids'
  | 'brand'
  | 'brand_subtype'
  | 'bucket_price'
  | 'category'
  | 'feelings'
  | 'percent_cbd'
  | 'percent_thc'
  | 'root_types';
