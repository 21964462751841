import { fold } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/pipeable';
import * as t from 'io-ts';

import { config } from '@jane/shared/config';
import type { Id } from '@jane/shared/models';

import { decodeType } from '../lib/loadable';
import { request } from '../lib/request';

const tCommunicationBannerMessageSettings = t.union([
  t.interface({
    created_at: t.string,
    enabled: t.boolean,
    id: t.number,
    is_expanded: t.boolean,
    message: t.union([t.string, t.null]),
    store_id: t.number,
    title: t.union([t.string, t.null]),
    updated_at: t.string,
  }),
  t.null,
]);

export type CommunicationBannerMessageSettings = t.TypeOf<
  typeof tCommunicationBannerMessageSettings
>;

const urlRoot = (storeId: Id) =>
  `${config.apiPath}/stores/${storeId}/banner_message_settings`;

export const getCurrentStoreCommunicationBanner = (storeId: Id) =>
  request(urlRoot(storeId), { method: 'GET' })
    .then((r) =>
      decodeType({
        data: r,
        type: tCommunicationBannerMessageSettings,
        source: { name: 'StoreCommunicationBanner' },
      })
    )
    .then((r) =>
      pipe(
        r,
        fold(
          (error) => {
            throw error;
          },
          (response) => response
        )
      )
    );
