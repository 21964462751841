import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import type {
  ErrorOption,
  FieldValues,
  Path,
  SubmitHandler,
  UseFormReturn,
} from 'react-hook-form';

import type { FormProps } from '../form.types';
import { FormValidationError } from '../formValidationError';
import { StyledForm } from './baseForm.styles';

export interface BaseFormProps<T extends FieldValues> extends FormProps {
  /** `react-hook-form`'s formMethods, result of using `useForm` hook */
  formMethods: UseFormReturn<T>;
}

/**
 * Use the `Form.BaseForm` component to wrap form fields and create more complex submittable forms, in which access
 * to `react-hook-form`'s `useForm` hook's methods and state are needed in the component containing the form.
 *
 * The `useForm` hook is not included within the `Form.BaseForm` component.
 * Import `useForm` hook for use from Reefer as follows:
 *
 * ```
 * import { useForm } from '@jane/reefer';
 * ```
 *
 * NOTE: `useForm` hook accepts all `react-hook-form` `useForm` options, except `mode`.
 * (This ensures validation mode is consistent across all Jane apps.)
 *
 * `Form.BaseForm` doesn't support the `onDirty` prop, as you can acheive the same using state from the `useForm` hook.
 *
 * For more about `useForm` hook, see [documentation](https://react-hook-form.com/api/useform/) from `react-hook-form`.
 *
 * For simpler forms, in which access to `react-hook-form`'s `useForm` hook's methods and/or state are needed,
 * use [`Form`](/story/components-forms-form--default), where the `useForm` hook is included within the component.
 */
export function BaseForm<T extends FieldValues>({
  children,
  formErrorName = 'form',
  formMethods,
  name,
  onSubmit,
  autocomplete = 'on',
  testId,
}: BaseFormProps<T>) {
  const { formState, handleSubmit, setError, clearErrors } = formMethods;

  const { errors: myFormErrors, isValid, isSubmitting } = formState;

  /**
   * `onSubmitForm` wraps `onSubmit` method supplied by `onSubmit` prop. It catches errors raised
   * during form submission, and applies them at either the form or field level, as appropriate,
   * using `react-hook-form`'s `setErrror` method.
   *
   * Errors raised during form submission could arise for many reason, but the most common is
   * server side validation errors.
   *
   * See [Handling Errors onSubmit Usage Docs](/story/components-forms-usage--page#handling-errors-onsubmit)
   * for more details.
   */
  const onSubmitForm: SubmitHandler<T> = async (data: T) => {
    try {
      return await onSubmit(data);
    } catch (error: unknown) {
      if (error instanceof FormValidationError) {
        error.errors.forEach(({ name: errorName, message }) => {
          setError(errorName as Path<T>, { type: 'onSubmit', message });
        });
      } else if (error instanceof Error) {
        setError(formErrorName as Path<T>, {
          type: 'onSubmit',
          message: `${error.message}`,
        });
      }
    }
  };

  /**
   * Reapplies errors when `formState` changes, so that external `field` and `form` level errors,
   * applied `onSubmit` still render the form invalid.
   *
   * Ideally, this wouldn't be necessary, but one of the quirks of `react-hook-form` is that, when
   * `formState` updates, errors applied with `setError` before that update will no longer affect
   * the validity state of the form (only client-side validation will determine form validity),
   * and this leads to some buggy form behaviour, and bad UX.
   */
  useEffect(() => {
    const { errors: formErrors, isValid: formIsValid } = formState;
    const formErrorsKeys = Object.keys(formErrors);
    const errorCount = formErrorsKeys.length;
    if (formIsValid && errorCount > 0) {
      Object.keys(formErrors).forEach((errorName) => {
        setError(
          errorName as Path<T>,
          formErrors[errorName as Path<T>] as ErrorOption
        );
      });
    }
  }, [clearErrors, formState, setError]);

  /**
   * Removes form level error when the form contains no other errors.
   *
   * NOTE: `react-hook-forms` makes doing this a little tricky, since it mutates its errors object,
   * hence some of the idiosyncrasies of this implementation
   */
  useEffect(() => {
    const formErrorsKeys = Object.keys(myFormErrors);
    const errorCount = formErrorsKeys.length;
    if (
      !isSubmitting &&
      isValid &&
      errorCount === 1 &&
      formErrorsKeys[0] === formErrorName
    ) {
      clearErrors();
    }
  }, [formErrorName, isValid, myFormErrors, isSubmitting, clearErrors]);

  return (
    <FormProvider {...formMethods}>
      <StyledForm
        data-testid={testId}
        autoComplete={autocomplete}
        name={name}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleSubmit(onSubmitForm)(e);
        }}
      >
        {children}
      </StyledForm>
    </FormProvider>
  );
}
