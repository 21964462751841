import { config } from '@jane/shared/config';

import {
  trackClickedObjectIDs,
  trackConvertedFilters,
  trackConvertedObjectIDs,
  trackViewedFilters,
} from './algolia';
import type { TrackedEvent, ViewedProduct } from './eventNames';
import { EventNames } from './eventNames';
import {
  gaTrackAddedProductToCart,
  gaTrackCheckoutSuccess,
  gaTrackLoadedCart,
  gaTrackProductClick,
  gaTrackRemovedProductFromCart,
} from './googleAnalytics';

function getCookie(cookieName: string): string | null {
  const cookies = document.cookie.split('; ');
  for (let i = 0; i < cookies.length; i++) {
    const [name, value] = cookies[i].split('=');
    if (name === cookieName) {
      return decodeURIComponent(value);
    }
  }
  return null;
}

export const track = (event: TrackedEvent) => {
  const { event: eventName, ..._meta } = event;

  // Adding jdidv2Trial for DM on March 20, 2023. This will run for 2-3 weeks and ultimately be removed.
  const jdidV2Trial = getCookie('jdidv2_trial');
  let meta;
  if (jdidV2Trial) {
    meta = { ..._meta, jdidV2Trial: jdidV2Trial };
  } else {
    meta = _meta;
  }

  if (config.dev) {
    // eslint-disable-next-line no-console
    console.log('Tracking', eventName, meta);
    return;
  }

  mixpanel?.track(eventName, meta);

  switch (event.event) {
    case EventNames.ViewedProduct:
      gaTrackProductClick(event as ViewedProduct);
      break;
    case EventNames.AddedProductToCart:
      trackConvertedObjectIDs(event);
      trackConvertedFilters(event);
      gaTrackAddedProductToCart(event);
      break;
    case EventNames.RemovedProductFromCart:
      gaTrackRemovedProductFromCart(event);
      break;
    case EventNames.ClickedMarketplaceProductCard:
    case EventNames.ClickedMenuProductCard:
    case EventNames.UpdatedProductInCart:
      trackClickedObjectIDs(event);
      // TODO – eventually, we will likely want to handle this event for GA EC
      // however, for now, we are skipping it because it requires a non-trivial "re-plumbing" of the event
      break;
    case EventNames.SearchedMenuProducts:
    case EventNames.SearchedProducts:
    case EventNames.SearchedStores:
      trackViewedFilters(event);
      break;
    case EventNames.LoadedCartPage:
      // don't need to pass in the event here
      gaTrackLoadedCart();
      break;
    case EventNames.CheckoutSuccess:
      trackConvertedObjectIDs(event);
      trackConvertedFilters(event);
      gaTrackCheckoutSuccess(event);
      break;
    default:
      return;
  }
};
