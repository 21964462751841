import styled from '@emotion/styled';

import { mediaQueries, spacing } from '../../../styling';
import {
  DESKTOP_PADDING_HORIZONTAL,
  MOBILE_PADDING_HORIZONTAL,
  PADDING_VERTICAL,
} from '../drawer.styles';

interface StyledDrawerContentProps {
  fitScreen: boolean;
  noPadding?: boolean;
}

export const StyledDrawerContent = styled.div<StyledDrawerContentProps>(
  {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    overflowX: 'hidden',
  },
  ({ noPadding, fitScreen }) => ({
    ...(fitScreen && {
      height: 0,
      overflowY: 'scroll',
    }),
    ...spacing({
      px: noPadding ? 0 : MOBILE_PADDING_HORIZONTAL,
      py: noPadding ? 0 : PADDING_VERTICAL,
    }),
    [mediaQueries.tablet('min')]: {
      ...spacing({ px: noPadding ? 0 : DESKTOP_PADDING_HORIZONTAL }),
    },
  })
);
