import omit from 'lodash/omit';
import type { FunctionComponent } from 'react';
import { useEffect, useState } from 'react';

import { Flex, styled } from '@jane/reefer';
import type { Hit } from '@jane/search/types';
import { useDeepCompareMemo } from '@jane/shared-ecomm/hooks';
import type { CardLocation } from '@jane/shared-ecomm/tracking';
import {
  EventNames,
  coreProductProperties,
  track,
  trackClickedAd,
} from '@jane/shared-ecomm/tracking';
import type {
  AdData,
  AppMode,
  MenuProduct,
  Product,
  Store,
  StoreSpecial,
} from '@jane/shared/models';
import { deserializeCannabinoids } from '@jane/shared/util';

import { useCustomerSelector } from '../../customer/selectors';
import { doesSpecialApply } from '../../lib/storeSpecial';
import { get } from '../../redux-util/selectors';
import { spacing } from '../../style';
import { BundleSpecialEnticementContent } from '../bundleSpecialEnticementContent';
import { EnticementBubble } from '../enticementBubble';
import type { Props as MenuProductCardProps } from './menuProductCard/menuProductCard';
import { MenuProductCard } from './menuProductCard/menuProductCard';
import { DEFAULT_PRODUCT_CARD_WIDTH } from './productKindBucketCarousel';

const BubbleContainer = styled.div<{ width: number }>(
  spacing({ px: 8 }),
  ({ width }) => ({
    maxWidth: width,
    minWidth: width,
  })
);

export const findSpecialForProduct = (
  menuProduct: MenuProduct,
  specials: StoreSpecial[] = [],
  appMode: AppMode
) =>
  specials.find((special: StoreSpecial) =>
    doesSpecialApply(appMode, special, menuProduct)
  );

interface AdditionalProps {
  bucketName?: string;
  carouselView?: boolean;
  columnPosition?: number;
  creativeIds?: number[];
  currentCycleIndex?: number;
  flightId?: number;
  index?: number;
  indexName: string;
  listView: boolean;
  menuProduct:
    | Hit<(MenuProduct & { root_types: string[] }) | Product>
    | MenuProduct;
  mlScoringSignals?: Record<string, unknown>;
  rowPosition?: number;
}

export const buildTrackEvent = ({
  store,
  menuProduct,
  carouselView,
  rowPosition,
  columnPosition,
  index,
  indexName,
  listView,
  flightId,
  creativeIds,
  bucketName,
  currentCycleIndex,
  mlScoringSignals,
}: AdditionalProps & { store: Store }) => ({
  objectIds: [
    'objectID' in menuProduct
      ? menuProduct.objectID
      : menuProduct.id.toString(),
  ],
  storeCity: store.city || undefined,
  storeState: store.state || undefined,
  bestSelling:
    'root_types' in menuProduct
      ? menuProduct.root_types?.includes('best_selling')
      : false,
  mlScoringSignals,
  ...coreProductProperties(menuProduct),
  ...additionalTrackingProperties({
    carouselView,
    menuProduct,
    columnPosition,
    rowPosition,
    index,
    indexName,
    listView,
    flightId,
    creativeIds,
    bucketName,
    currentCycleIndex,
  }),
});

const additionalTrackingProperties = ({
  carouselView,
  menuProduct,
  columnPosition,
  rowPosition,
  index,
  indexName,
  listView,
  flightId,
  creativeIds,
  bucketName,
  currentCycleIndex,
}: AdditionalProps) => {
  const sharedProperties = {
    event:
      EventNames.ClickedMenuProductCard as EventNames.ClickedMenuProductCard,
    category: menuProduct.kind,
    flightId,
    creativeIds,
    bucketName,
    currentCycleIndex,
  };

  return carouselView
    ? {
        index: undefined,
        columnPosition,
        rowPosition,
        indexName,
        cardLocation: 'grid carousel' as CardLocation,
        ...sharedProperties,
      }
    : {
        index,
        cardLocation: (listView ? 'list' : 'grid table') as CardLocation,
        rowPosition: undefined,
        columnPosition: undefined,
        indexName,
        ...sharedProperties,
      };
};

type HitProductCardProps = Omit<
  MenuProductCardProps,
  'product' | 'currentSpecial' | 'cartProducts' | 'itemWidth' | 'onClick'
> & {
  algoliaIndexName: string;
  bucketName?: string;
  carouselView?: boolean;
  columnPosition?: number;
  currentCycleIndex?: number;
  flightProps?: AdData['flight'];
  hit: Hit<MenuProduct> | MenuProduct;
  index?: number;
  itemWidth?: number;
  mlScoringSignals?: Record<
    string,
    unknown
  > /* ML metadata for event tracking */;
  rowPosition?: number;
  specials?: StoreSpecial[];
};

const HitProductCard: FunctionComponent<HitProductCardProps> = ({
  hit,
  specials,
  rowPosition,
  columnPosition,
  index,
  flightProps,
  algoliaIndexName,
  bucketName,
  currentCycleIndex,
  mlScoringSignals,
  ...props
}) => {
  const { appMode } = useCustomerSelector(get('embeddedApp'));
  const { touchedProductId, bundlePossibilities } = useCustomerSelector(
    get('bundlePossibilities')
  );
  const { janeDeviceId } = useCustomerSelector(get('application'));

  const { store, carouselView, listView, itemWidth } = props;
  const [bundleSpecial, setBundleSpecial] = useState<StoreSpecial | undefined>(
    undefined
  );

  const menuProduct = useDeepCompareMemo<any>(() => {
    const mp = {
      ...deserializeCannabinoids(hit, 'menuProduct'),
      id: hit['product_id'],
    };
    return omit(mp, 'product_id');
  }, [hit]);

  flightProps = flightProps || menuProduct.flight;

  useEffect(() => {
    const matchesTouchedProduct = touchedProductId === menuProduct.id;
    const bundleSpecial =
      specials && specials.length > 0 && matchesTouchedProduct
        ? specials.find((s) => s.id === bundlePossibilities.special_id)
        : undefined;

    setBundleSpecial(bundleSpecial);
  }, [touchedProductId, bundlePossibilities]);

  const trackEvent = buildTrackEvent({
    indexName: algoliaIndexName,
    flightId: flightProps?.id,
    creativeIds: flightProps?.creative_ids,
    store,
    menuProduct,
    carouselView,
    rowPosition,
    index,
    columnPosition,
    listView,
    bucketName,
    currentCycleIndex,
    mlScoringSignals,
  });

  const enticementBubbleWidth = itemWidth || DEFAULT_PRODUCT_CARD_WIDTH;

  return (
    <Flex data-testid="hit-product-card" flexDirection="column">
      <MenuProductCard
        currentSpecial={findSpecialForProduct(menuProduct, specials, appMode)}
        product={menuProduct}
        onClick={() => {
          trackClickedAd(
            appMode === 'kiosk' ? mixpanel?.get_distinct_id() : janeDeviceId,
            flightProps,
            menuProduct.dmMeta
          );
          track(trackEvent);
        }}
        {...props}
        store={store}
      />
      {bundleSpecial && !listView && (
        <BubbleContainer width={enticementBubbleWidth}>
          <EnticementBubble onClose={() => setBundleSpecial(undefined)}>
            <BundleSpecialEnticementContent
              appMode={appMode}
              bundlePossibilities={bundlePossibilities}
              special={bundleSpecial}
              store={store}
            />
          </EnticementBubble>
        </BubbleContainer>
      )}
    </Flex>
  );
};

export default HitProductCard;
