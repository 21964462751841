import styled from '@emotion/styled';

import { mediaQueries, shadows, spacing } from '../../../styling';
import type { ComponentColors } from '../../../types';
import { getColorValue } from '../../../utils/componentStyles';
import {
  DESKTOP_PADDING_HORIZONTAL,
  MOBILE_PADDING_HORIZONTAL,
  PADDING_VERTICAL,
} from '../drawer.styles';

interface StyledDrawerHeaderProps {
  background?: ComponentColors;
  divider: boolean;
}

export const StyledDrawerHeader = styled.div<StyledDrawerHeaderProps>(
  {
    justifySelf: 'flex-start',
    width: '100%',
    textAlign: 'center',
    ...spacing({
      px: MOBILE_PADDING_HORIZONTAL,
      py: PADDING_VERTICAL,
    }),
    [mediaQueries.tablet('min')]: {
      ...spacing({
        px: DESKTOP_PADDING_HORIZONTAL,
      }),
    },
  },
  ({ theme, background, divider }) => ({
    boxShadow: divider ? shadows.divider : 'none',
    background: getColorValue(background, theme),
  })
);

export const StyledIconContainer = styled.div({
  position: 'absolute',
  top: PADDING_VERTICAL,
  left: MOBILE_PADDING_HORIZONTAL,
});
