import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Play = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.2864 10.304C18.5397 11.0873 18.5397 12.9127 17.2864 13.696L7.06 20.0875C5.7279 20.9201 4 19.9624 4 18.3915L4 5.6085C4 4.03763 5.7279 3.07994 7.06 3.9125L17.2864 10.304Z"
      fill="#0E0E0E"
    />
  </svg>
);
export const PlayIcon = ({ ...props }: DynamicIconProps) => {
  return <Icon icon={Play} {...props} color={props.color} />;
};
