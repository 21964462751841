import classNames from 'classnames';
import PropTypes from 'prop-types';
import type { FunctionComponent } from 'react';
import { useCallback, useLayoutEffect } from 'react';
import type { Props as ReactModalProps } from 'react-modal';
import Modal from 'react-modal';

import { styled, useTheme } from '@jane/reefer';
import { config } from '@jane/shared/config';

import { closeModal as defaultCloseModal } from '../../common/redux/application';
import { SvgIcon } from '../../component-library/svg-icon';
import { useCustomerDispatch } from '../../customer/dispatch';
import { useCustomerSelector } from '../../customer/selectors';
import { get } from '../../redux-util/selectors';
import { media, mediaQueries } from '../../style';

const bodyElement = document.getElementsByTagName('body')[0];

if (config.nodeEnv !== 'test') {
  Modal.setAppElement('#app');
}

interface Props
  extends Omit<ReactModalProps, 'isOpen' | 'onRequestClose' | 'children'> {
  children: (closeModal: () => void) => JSX.Element;
  className?: string;
  closeModal?: () => void;
  contentLabel: string;
  disallowClose?: boolean;
  fixedBody?: boolean;
  width?: 'default' | 'full' | 'medium' | 'narrow';
}

const CloseButton = styled.button(({ theme }) => ({
  position: 'absolute',
  top: 24,
  right: 24,
  height: 16,
  [media(mediaQueries.hover)]: {
    '&:hover': {
      background: theme?.colors?.grays?.ultralight,
      height: 32,
      top: 16,
      right: 16,
      borderColor: theme?.colors?.grays?.ultralight,
      borderWidth: 8,
      borderStyle: 'solid',
      borderRadius: 30,
    },
  },
}));

const BaseModal: FunctionComponent<Props> = ({
  className = '',
  contentLabel,
  disallowClose,
  width,
  children,
  closeModal,
  fixedBody = false,
  ...rest
}) => {
  const theme = useTheme();
  const { isModalOpen, modalOptions } = useCustomerSelector(get('application'));
  const dispatch = useCustomerDispatch();

  useLayoutEffect(() => {
    if (!fixedBody) return;
    bodyElement.classList.add('body--fixed');
    return () => {
      bodyElement.classList.remove('body--fixed');
    };
  }, [fixedBody]);

  const canClose = !disallowClose || !(modalOptions as any).disallowClose;

  const classes = classNames({
    modal: true,
    'modal--full-width': width === 'full',
    'modal--medium': width === 'medium',
    'modal--narrow': width === 'narrow',
    [className]: true,
  });

  const handleCloseModal = useCallback(() => {
    bodyElement.classList.remove('body--fixed');
    bodyElement.classList.remove('ReactModal__Body--open');
    if (closeModal) return closeModal();
    dispatch(defaultCloseModal());
  }, [closeModal]);

  return (
    <Modal
      {...rest}
      className={classes}
      contentLabel={contentLabel}
      isOpen={isModalOpen}
      onAfterClose={handleCloseModal}
      onRequestClose={canClose ? handleCloseModal : () => {}}
      overlayClassName="modal-overlay"
    >
      {!disallowClose && (
        <CloseButton onClick={handleCloseModal}>
          <SvgIcon icon="times" color={theme?.colors?.grays?.mid} size={16} />
        </CloseButton>
      )}
      {children(handleCloseModal)}
    </Modal>
  );
};

BaseModal.propTypes = {
  className: PropTypes.string,
  closeModal: PropTypes.func,
  contentLabel: PropTypes.string.isRequired,
  disallowClose: PropTypes.bool,
  fixedBody: PropTypes.bool,
  width: PropTypes.oneOf(['default', 'full', 'medium', 'narrow']),
};

export default BaseModal;
