import type { CSSObject } from '@emotion/styled';
import styled from '@emotion/styled';

import { mediaQueries, spacing } from '../../styling';
import {
  getBorderRadiusValue,
  getComponentStyles,
} from '../../utils/componentStyles';
import type {
  ButtonAlignment,
  ButtonStyleProps,
  ButtonStylePropsWithTheme,
  LabelProps,
  LabelWrapperProps,
} from './button.types';
import { getButtonVariantStyle } from './buttonVariant.styles';

const getButtonStyle = ({
  disabled,
  full,
  href,
  isLoading,
  subLabel,
  theme,
  to,
  size = 'default',
  variant,
  ...marginProps
}: ButtonStylePropsWithTheme): CSSObject => {
  const buttonStyles = getComponentStyles('Button', theme, variant);

  /** BorderRadius: 'sm' | 'lg' or custom value from embed  */
  const borderRadius = getBorderRadiusValue(buttonStyles.borderRadius, theme);

  const sizeMap = theme.components.Button.sizes[size];

  return {
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: sizeMap.borderRadius ?? borderRadius,
    borderWidth: 0,
    cursor: 'pointer',
    height: subLabel ? `calc(${sizeMap.height} + 8px)` : sizeMap.height,
    minWidth: '48px',
    textAlign: 'center',
    textDecoration: 'none',
    transition: 'all 250ms',
    width: full ? '100%' : 'fit-content',
    position: 'relative',
    ...spacing({
      px: sizeMap.paddingX,
      ...marginProps,
    }),
    ...(disabled && {
      opacity: '0.3',
      pointerEvents: 'none' as const,
    }),
    ...(isLoading && { pointerEvents: 'none' as const }),
    ...getButtonVariantStyle({ theme, variant }),
  };
};

export const StyledButton = styled.button<ButtonStyleProps>(
  ({ theme, ...props }) => getButtonStyle({ theme, ...props })
);

export const StyledFakeButton = styled.div<ButtonStyleProps>(
  ({ theme, ...props }) => getButtonStyle({ theme, ...props })
);

export const StyledLink = styled.a<ButtonStyleProps>(({ theme, ...props }) => ({
  ...getButtonStyle({ theme, ...props }),
}));

export const StyledRouterLink = styled.div<ButtonStyleProps>(
  ({ theme, ...props }) => ({
    width: props.full ? '100%' : 'auto',
    '.router-link': {
      ...getButtonStyle({ theme, ...props }),
    },
  })
);

export const LoadingLabel = styled.div<LabelProps>(
  ({ isLoading, subLabel }) => ({
    width: '100%',
    height: isLoading ? '0px' : 'auto',
    visibility: isLoading ? 'hidden' : 'initial',
    overflow: 'hidden',
    marginTop: subLabel && !isLoading ? '-5px' : 0,
    [mediaQueries.desktop('sm', 'min')]: {
      marginTop: 0,
    },
  })
);

export const LabelWrapper = styled.div<LabelWrapperProps>(
  ({ startIcon, endIcon }) => ({
    display: 'flex',
    flexDirection: 'column',
    ...(startIcon && { paddingLeft: '8px' }),
    ...(endIcon && { paddingRight: '8px' }),
  })
);

export const IconLabelWrapper = styled.div<{ alignment: ButtonAlignment }>(
  ({ alignment }) => ({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent:
      alignment === 'center'
        ? 'center'
        : alignment === 'left'
        ? 'flex-start'
        : 'flex-end',
  })
);
