import type { ReactNode } from 'react';

import { AddIcon, Button, SubtractIcon, Typography } from '@jane/reefer';

import { useCustomerSelector } from '../customer';
import { isEmbeddedModeSelector } from '../customer/selectors';
import { Flex } from '../style/layout';

const Value: React.FC<{ children?: ReactNode; variant?: Variant }> = ({
  children,
  variant,
}) => {
  const condensed = variant === 'condensed';
  const isEmbeddedMode = useCustomerSelector(isEmbeddedModeSelector);

  return (
    <Typography
      css={{ width: condensed ? 45 : 64 }}
      branded={!isEmbeddedMode}
      textAlign="center"
      variant="header-bold"
      testId="product-quantity"
    >
      {children}
    </Typography>
  );
};

type Variant = 'default' | 'small' | 'condensed';

interface Props {
  decrementQuantity: () => void;
  incrementQuantity: () => void;
  maxQuantity?: number;
  minQuantity?: number;
  multipleCountDisabled: boolean;
  value: number;
  variant?: Variant;
}

export default function QuantitySelector({
  value,
  decrementQuantity,
  incrementQuantity,
  variant = 'default',
  maxQuantity = 5,
  minQuantity = 1,
}: Props) {
  const isMinimumVal = value <= minQuantity;
  const isMaximumVal = value >= maxQuantity;

  return (
    <Flex justifyContent="center" alignSelf="center" alignItems="center">
      <Button.Icon
        ariaLabel="decrement quantity"
        disabled={isMinimumVal}
        icon={<SubtractIcon />}
        onClick={decrementQuantity}
      />
      <Value variant={variant}>{value}</Value>
      <Button.Icon
        ariaLabel="increment quantity"
        disabled={isMaximumVal}
        icon={<AddIcon />}
        onClick={incrementQuantity}
      />
    </Flex>
  );
}
