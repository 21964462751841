import * as t from 'io-ts';

import type { DeepReadonly } from './deepReadonly';

export const tCartCurbsidePickupSelection = t.interface({
  id: t.number,
  curbside_pickup_selected: t.boolean,
  cart_id: t.number,
  customer_arrived: t.union([t.null, t.boolean]),
  store_id: t.number,
  customer_details: t.any,
  store_notified: t.union([t.null, t.boolean]),
});

export type CartCurbsidePickupSelection = DeepReadonly<
  t.TypeOf<typeof tCartCurbsidePickupSelection>
>;
