import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Promo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 8C10 9.10457 9.10457 10 8 10C6.89543 10 6 9.10457 6 8C6 6.89543 6.89543 6 8 6C9.10457 6 10 6.89543 10 8ZM16.7097 7.29031C17.0968 7.6774 17.0968 8.30499 16.7097 8.69208L8.69208 16.7097C8.30499 17.0968 7.6774 17.0968 7.29031 16.7097C6.90323 16.3226 6.90323 15.695 7.29031 15.3079L15.3079 7.29031C15.695 6.90323 16.3226 6.90323 16.7097 7.29031ZM16 18C17.1046 18 18 17.1046 18 16C18 14.8954 17.1046 14 16 14C14.8954 14 14 14.8954 14 16C14 17.1046 14.8954 18 16 18Z"
      fill="#0E0E0E"
    />
    <circle cx={12} cy={12} r={11} stroke="#0E0E0E" strokeWidth={2} />
  </svg>
);
export const PromoIcon = ({ ...props }: DynamicIconProps) => {
  return <Icon icon={Promo} {...props} color={props.color} />;
};
