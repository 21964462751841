import type { ReactNode } from 'react';

import { UserPreferencesModal } from '@jane/shared-ecomm/components';
import { useUserPreferences } from '@jane/shared-ecomm/providers';
import { FLAGS, useFlagHelper } from '@jane/shared/feature-flags';
import { SessionStorage } from '@jane/shared/util';

import useSafeState from '../hooks/useSafeState';
import UserLocationModal from './userLocationModal';

const DISMISSED_KEY = 'dismissedLocationModal';

const UserLocation = ({ children }: { children?: ReactNode }) => {
  const newHomePageFlag = useFlagHelper(FLAGS.newHomePage);
  const { userLocation } = useUserPreferences();
  const [showModal, setShowModal] = useSafeState(
    SessionStorage.get(DISMISSED_KEY) === 'true'
      ? false
      : !userLocation.coordinates
  );

  const dismissModal = () => {
    setShowModal(false);
    SessionStorage.set(DISMISSED_KEY, 'true');
  };

  return (
    <>
      {children}
      {showModal &&
        !userLocation.hasResetLocation &&
        !userLocation.coordinates && (
          <>
            {newHomePageFlag.active && (
              <UserPreferencesModal
                showModal={showModal}
                onCloseModal={() => setShowModal(false)}
              />
            )}
            {!newHomePageFlag.active && (
              <UserLocationModal
                allowLocationReset={!!userLocation.coordinates}
                setShowModal={setShowModal}
                showModal={showModal}
                onCloseModal={() => setShowModal(false)}
                onDismissModal={dismissModal}
              />
            )}
          </>
        )}
    </>
  );
};

export default UserLocation;
