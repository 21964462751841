import { StyledList } from './list.styles';
import type { ListProps } from './list.types';

/**
 * Lists are a continuous group of text or other elements. They are composed of items containing primary and supplemental actions, which are represented by icons and text.
 */

export function List({
  children,
  label,
  testId,
  m = 0,
  p = 0,
  ...props
}: ListProps) {
  return (
    <StyledList aria-label={label} test-id={testId} m={m} p={p} {...props}>
      {children}
    </StyledList>
  );
}
