import { config } from '@jane/shared/config';
import type {
  AdData,
  AdFlight,
  AppMode,
  DmMeta,
  Id,
  MenuProduct,
  TrackImpressedAdPayload,
  TrackImpressedRowAdProps,
  TrackInlineImpressionProps,
  Zone,
} from '@jane/shared/models';
import {
  getLiveTests,
  isInMaintenance,
  maintenanceReload,
} from '@jane/shared/util';

import { EventNames } from './eventNames';
import { track } from './track';

export const trackImpressedRowAd = (props: TrackImpressedRowAdProps) => {
  const {
    userId,
    storeId,
    currentCycleIndex,
    janeDeviceId,
    cartProducts,
    flight,
    appMode,
    zone,
    productIds: positionedProductIds,
  } = props;

  const cartProductsBrandIds = cartProducts.map(
    (menuProduct) => menuProduct.product_brand_id
  );

  trackImpressedAd({
    appMode,
    userId,
    storeId,
    zone,
    adUnit: flight.ad_unit,
    positionedProductIds,
    currentCycleIndex,
    janeDeviceId,
    flightId: flight.id,
    flightType: flight.flight_type,
    model: flight.model,
    creativeIds: flight.creative_ids,
    productBrandId: flight.product_brand.id,
    productBrand: flight.product_brand.name,
    productBrandInCart: cartProductsBrandIds.includes(flight.product_brand.id),
    revenue: parseFloat(window.atob(flight.data_1 || '')) || 0,
    distinctId: mixpanel?.get_distinct_id(),
    kevelToken: flight.kevel_token,
    ...getLiveTests(userId),
  });
};

export const trackImpressedInlineAd = (
  props: TrackInlineImpressionProps,
  trackDmError?: (error: Error | string) => void
) => {
  const {
    appMode,
    cartProducts,
    janeDeviceId,
    mlScoringSignals,
    placements,
    storeId,
    userId,
    zone,
  } = props;
  const cartProductsBrandIds = cartProducts.map(
    (menuProduct) => menuProduct.product_brand_id
  );

  Object.entries(placements).forEach(([matchedRowName, placements]) => {
    placements.forEach((placement) => {
      const { productId, productBrandId, productBrandName, flight, dmMeta } =
        placement;
      trackImpressedAd(
        {
          appMode,
          distinctId: mixpanel?.get_distinct_id(),
          flightId: flight.id,
          flightType: flight.flight_type,
          kevelToken: flight.kevel_token,
          janeDeviceId,
          mlScoringSignals,
          model: flight.model,
          myHighD: dmMeta?.myHighD,
          placementRow: matchedRowName,
          productBrand: productBrandName,
          productBrandId,
          productBrandInCart: cartProductsBrandIds.includes(productBrandId),
          productId,
          revenue: parseFloat(window.atob(flight.data_1 || '')) || 0,
          storeId,
          userId,
          zone,
          ...getLiveTests(userId),
        },
        trackDmError
      );
    });
  });
};

interface TrackImpressedMlProductPayload {
  appMode: AppMode;
  janeDeviceId: string | undefined;
  mlScoringSignals: Record<string, unknown>;
  model: AdFlight['model'] | 'organic';
  placementRow: string;
  productBrand: string;
  productBrandId: number;
  productId: number;
  storeId: Id;
  zone: Zone;
}
export const trackImpressedMlProduct = (
  payload: TrackImpressedMlProductPayload
) => {
  track({ event: EventNames.ImpressedMlProduct, ...payload });
};

export const impressionTrackingUrl = `${config.dmUrl}/client_events/1`;
const trackImpressedAd = (
  payload: TrackImpressedAdPayload,
  trackDmError?: (error: Error | string) => void
) => {
  fetch(impressionTrackingUrl, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'text/plain',
    },
    body: JSON.stringify(payload),
  })
    .then((response) => {
      if (isInMaintenance(response)) {
        maintenanceReload();
        return;
      }
    })
    .catch((error) => {
      trackDmError && trackDmError(error);
    });
};

export const clickTrackingUrl = `${config.dmUrl}/client_events/2`;
export const trackClickedAd = (
  uniqueIdentifier?: string,
  flightProps?: AdData['flight'],
  dmMeta?: DmMeta
) => {
  if (!uniqueIdentifier || !flightProps) return;

  fetch(clickTrackingUrl, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'text/plain',
    },
    body: JSON.stringify({
      productBrandId: flightProps.product_brand.id,
      kevelToken: flightProps.kevel_token,
      model: flightProps.model,
      flightId: flightProps.id,
      myHighD: dmMeta?.myHighD,
      uniqueIdentifier,
    }),
  })
    .then((response) => {
      if (isInMaintenance(response)) {
        maintenanceReload();
        return;
      }

      track({
        event: EventNames.ClickedAd,
        dmMeta,
        uniqueIdentifier,
        flightProps,
      });
    })
    .catch((error) => {
      track({
        event: EventNames.ClickedAdError,
        dmMeta,
        uniqueIdentifier,
        flightProps,
        error,
      });
    });
};

export const viewSponsoredRowTrackingUrl = `${config.apiPath}/client_events/mp`;
interface TrackViewSponsoredRow {
  appMode: AppMode;
  flight: AdFlight;
  janeDeviceId: string;
  menuProducts?: MenuProduct[];
  mpDistinctId: string | null;
  storeId: Id;
  zone: Zone;
}
export const trackViewedSponsoredRow = ({
  appMode,
  flight,
  janeDeviceId,
  menuProducts,
  mpDistinctId,
  storeId,
  zone,
}: TrackViewSponsoredRow) => {
  const { id, ad_unit, creative_ids, model, product_brand } = flight;

  let positionedProductIds: number[] | undefined;
  if (ad_unit === 'row') {
    positionedProductIds = menuProducts?.map((mp) => mp.product_id) || [];
  }
  const payload = {
    event: 'Viewed Flight',
    distinctId: mpDistinctId,
    attributes: {
      flightId: id,
      currentUrl: window.location.href,
      storeId,
      zone,
      janeDeviceId,
      positionedProductIds,
      ad_unit,
      creative_ids,
      model,
      product_brand,
      appMode,
    },
  };

  return fetch(viewSponsoredRowTrackingUrl, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }).then((response) => {
    if (isInMaintenance(response)) {
      maintenanceReload();
      return;
    }
  });
};
