import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const EaseMyMind = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 1C3 0.447715 3.44772 3.87589e-08 4 3.87589e-08C5.04446 3.87589e-08 6.04289 0.484192 6.77014 1.36203C7.1475 1.81753 7.59685 2 8.00016 2C8.40348 2 8.85282 1.81753 9.23018 1.36203C9.95744 0.484192 10.9559 3.87589e-08 12.0003 3.87589e-08C13.0448 3.87589e-08 14.0432 0.484192 14.7705 1.36203C15.1478 1.81753 15.5972 2 16.0005 2C16.4038 2 16.8531 1.81753 17.2305 1.36203C17.9575 0.484458 18.9556 0.00028775 19.9997 3.87589e-08C20.552 -0.000152072 20.9998 0.44744 21 0.999725C21.0002 1.55201 20.5526 1.99985 20.0003 2C19.5971 2.00011 19.1479 2.18261 18.7706 2.63797C18.0434 3.51581 17.0449 4 16.0005 4C14.956 4 13.9576 3.51581 13.2303 2.63797C12.853 2.18248 12.4036 2 12.0003 2C11.597 2 11.1477 2.18248 10.7703 2.63797C10.043 3.51581 9.04462 4 8.00016 4C6.9557 4 5.95727 3.51581 5.23002 2.63797C4.85266 2.18248 4.40332 2 4 2C3.44772 2 3 1.55228 3 1Z"
      fill="#8E00E6"
    />
    <path
      d="M12 6C5.37258 6 0 11.3726 0 18V24H24V18C24 11.3726 18.6274 6 12 6Z"
      fill="#8E00E6"
    />
    <path
      d="M5.36207 12.23C5.78738 11.8776 6.41777 11.9368 6.7701 12.3621C7.14744 12.8176 7.59676 13 8.00003 13C8.4033 13 8.85261 12.8176 9.22995 12.3621C9.58228 11.9368 10.2127 11.8776 10.638 12.23C11.0633 12.5823 11.1224 13.2127 10.7701 13.638C10.0429 14.5158 9.04447 15 8.00003 15C6.95558 15 5.95718 14.5158 5.22995 13.638C4.87762 13.2127 4.93677 12.5823 5.36207 12.23Z"
      fill="#0E0E0E"
    />
    <path
      d="M7.14388 18.4833C7.42926 18.0105 8.04392 17.8585 8.51676 18.1439C9.43021 18.6952 10.6884 19 12 19C13.3116 19 14.5698 18.6952 15.4833 18.1439C15.9561 17.8585 16.5708 18.0105 16.8562 18.4833C17.1416 18.9561 16.9896 19.5708 16.5168 19.8562C15.2211 20.6382 13.5838 21 12 21C10.4162 21 8.77898 20.6382 7.4833 19.8562C7.01046 19.5708 6.8585 18.9561 7.14388 18.4833Z"
      fill="#0E0E0E"
    />
    <path
      d="M14.7701 12.3621C14.4178 11.9368 13.7874 11.8776 13.3621 12.23C12.9368 12.5823 12.8776 13.2127 13.23 13.638C13.9572 14.5158 14.9556 15 16 15C17.0445 15 18.0429 14.5158 18.7701 13.638C19.1224 13.2127 19.0633 12.5823 18.638 12.23C18.2127 11.8776 17.5823 11.9368 17.2299 12.3621C16.8526 12.8176 16.4033 13 16 13C15.5968 13 15.1474 12.8176 14.7701 12.3621Z"
      fill="#0E0E0E"
    />
  </svg>
);
export const EaseMyMindIcon = ({ ...props }: StaticIconProps) => {
  return <Icon icon={EaseMyMind} {...props} color={undefined} />;
};
