import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as t from 'io-ts';

import type { MenuRow } from '@jane/business-admin/types';
import { tMenuRow } from '@jane/business-admin/types';
import { fetchWithDecode } from '@jane/business-admin/util';
import { janeApiV2 } from '@jane/shared/data-access';

import { STORES_URL } from '..';

interface MenuRowCreateParams {
  applicability_rules?: { filter_type: string; filter_value: string }[];
  custom_display_name: string;
  enabled: boolean;
  menu_products?: number[];
  row_type: string;
}
const createMenuRow = async (
  storeId: string,
  data: MenuRowCreateParams
): Promise<null> => {
  return await janeApiV2.post<null>(
    `${STORES_URL}/${storeId}/menu/menu_row`,
    data
  );
};
export const useCreateMenuRow = (storeId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: MenuRowCreateParams) => createMenuRow(storeId, data),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['menu', storeId]);
    },
  });
};

const updateMenuRow = async (
  storeId: string,
  data: MenuRowCreateParams,
  rowId: number
): Promise<null> => {
  return await janeApiV2.patch<null>(
    `${STORES_URL}/${storeId}/menu/menu_row/${rowId}`,
    data
  );
};
export const useUpdateMenuRow = (storeId: string, rowId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: MenuRowCreateParams) =>
      updateMenuRow(storeId, data, rowId),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['menu', storeId]);
    },
  });
};

const deleteMenuRow = async (storeId: string, rowId: number): Promise<null> => {
  return await janeApiV2.delete<null>(
    `${STORES_URL}/${storeId}/menu/menu_row/${rowId}`
  );
};
export const useDeleteMenuRow = (storeId: string, rowId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => deleteMenuRow(storeId, rowId),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['menu', storeId]);
    },
  });
};

export type UpdateMenuRowOrderBody = {
  product_type: string;
  ranking: number;
}[];

const updateMenuRowOrder = async (
  data: UpdateMenuRowOrderBody,
  storeId?: string
): Promise<null> => {
  return await janeApiV2.put<null>(`${STORES_URL}/${storeId}/menu/menu_rows`, {
    menu_rankings: data,
  });
};

export const useUpdateMenuRowOrder = (storeId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: UpdateMenuRowOrderBody) =>
      updateMenuRowOrder(data, storeId),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['menu', storeId]);
    },
  });
};

type MenuResponse = {
  // TODO: Add these types
  appearance: any;
  communication_banner: any;
  filters_and_labels: any;
  menu_rows: MenuRow[];
};
const fetchMenu = async (storeId?: string): Promise<MenuResponse> => {
  const url = `${STORES_URL}/${storeId}/menu`;
  const tPayload = t.interface({
    menu_rows: t.array(tMenuRow),
  });
  const data = await fetchWithDecode(
    janeApiV2.get<MenuResponse>(url),
    tPayload,
    url
  );

  return data;
};
export const useStoreMenu = (storeId?: string) => {
  return useQuery<MenuResponse>({
    queryFn: () => fetchMenu(storeId),
    queryKey: ['menu', storeId],
    staleTime: Infinity,
    useErrorBoundary: true,
  });
};
