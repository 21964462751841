import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import Drawer from 'rc-drawer';

import '../../styling/rc-drawer.css';
import { getColorValue } from '../../utils/componentStyles';
import type { Placement, StyledDrawerProps } from './drawer.types';

export const DESKTOP_PADDING_HORIZONTAL = 40;
export const MOBILE_PADDING_HORIZONTAL = 24;
export const PADDING_VERTICAL = 16;
const FULL_HEIGHT = '100vh';
const FULL_WIDTH = '100vw';

const slideOut = (placement: Placement) => {
  return keyframes`
  from, 0%, to {
    transform: translate${
      placement === 'left' || placement === 'right' ? 'X' : 'Y'
    }(0);
  }
  100%, to {
    transform: translate${
      placement === 'left' || placement === 'right' ? 'X' : 'Y'
    }(${placement === 'bottom' || placement === 'right' ? '100%' : '-100%'});
  }
`;
};

const slideIn = (placement: Placement) => {
  return keyframes`
  from, 0%, to {
    transform: translate${
      placement === 'left' || placement === 'right' ? 'X' : 'Y'
    }(${placement === 'bottom' || placement === 'right' ? '100%' : '-100%'});
  }
  100% {
    transform: translate${
      placement === 'left' || placement === 'right' ? 'X' : 'Y'
    }(0);
  }
`;
};

const fadeIn = keyframes`
    from, 0%, to {
      opacity: 0
    }
    100% {
      opacity: 1
    }
  `;

const fadeOut = keyframes`
    from, 0%, to {
      opacity: 1
    }
    100% {
      opacity: 0
    }
  `;

const DrawerAdapter = ({
  children,
  className,
  container,
  fitScreen,
  full,
  height,
  open,
  onRequestClose,
  overlayClose,
  placement,
  width,
}: StyledDrawerProps) => {
  const horizontal = placement === 'left' || placement === 'right';

  const getHeight = () => {
    if (fitScreen) {
      return (full && !horizontal) || horizontal ? FULL_HEIGHT : height;
    } else {
      return undefined;
    }
  };

  return (
    <Drawer
      children={children}
      className={className}
      destroyOnClose
      getContainer={container}
      height={getHeight()}
      maskClosable={overlayClose}
      onClose={onRequestClose}
      open={open}
      placement={placement}
      rootClassName={className}
      width={(full && horizontal) || !horizontal ? FULL_WIDTH : width}
    />
  );
};

export const StyledDrawer = styled(DrawerAdapter)<StyledDrawerProps>(
  ({ background, closing, placement, theme }) => ({
    zIndex: 100420,
    '.rc-drawer-content-wrapper': {
      animation: `${
        closing ? slideOut(placement) : slideIn(placement)
      } 500ms cubic-bezier(0.16, 1, 0.3, 1)`,
      ...(placement === 'bottom' && { bottom: 0 }),
      zIndex: 100420,
    },
    '.rc-drawer-content': {
      overflow: 'hidden',
      background: getColorValue(background, theme),
      display: 'flex',
      flexDirection: 'column',
    },
    '.rc-drawer-mask': {
      animation: `${closing ? fadeOut : fadeIn} 500ms linear`,
      zIndex: 100420,
    },
  })
);
