import * as t from 'io-ts';

import type { Id } from './api';
import { tCartLimitPolicy } from './cartLimit';
import { CrmProvider } from './crmIntegration';
import type { StoreCrmIntegration } from './crmIntegration';
import type { DeepReadonly } from './deepReadonly';
import { tFreeDeliveryConfig } from './freeDeliveryConfigs';
import type { Kiosk } from './kiosk';
import type { MenuProduct } from './menuProduct';
import { tReservationMode } from './reservationMode';
import { tStoreTax } from './storeTax';

export const tAeropayIntegration = t.interface({
  autocapture_on_checkout: t.union([t.boolean, t.undefined]),
  custom_button_image_url: t.union([t.string, t.null, t.undefined]),
  custom_payment_method_label: t.union([t.string, t.null, t.undefined]),
  enabled: t.union([t.boolean, t.undefined]),
  id: t.union([t.number, t.undefined]),
  location_id: t.union([t.string, t.null]),
  location_uuid: t.union([t.string, t.null, t.undefined]),
  merchant_id: t.string,
});
export type AeropayIntegration = t.TypeOf<typeof tAeropayIntegration>;

export const tMonerisIntegration = t.interface({
  autocapture_on_checkout: t.union([t.boolean, t.undefined]),
  enabled: t.union([t.boolean, t.undefined]),
  id: t.union([t.number, t.undefined]),
  moneris_api_token: t.string,
  moneris_checkout_id: t.string,
  moneris_store_id: t.union([t.string, t.undefined]),
});
export type MonerisIntegration = t.TypeOf<typeof tMonerisIntegration>;

export const tPdpSetting = t.intersection([
  t.partial({ id: t.number }),
  t.interface({ hide_description: t.boolean, hide_effects: t.boolean }),
]);
export type PdpSetting = t.TypeOf<typeof tPdpSetting>;

export const tSquareIntegration = t.interface({
  id: t.string,
  location_id: t.string,
});
export type SquareIntegration = t.TypeOf<typeof tSquareIntegration>;

export const tStrongholdIntegration = t.interface({
  enabled: t.union([t.boolean, t.undefined]),
  id: t.string,
  publishable_key: t.string,
  secret_key: t.string,
});
export type StrongholdIntegration = t.TypeOf<typeof tStrongholdIntegration>;

export const tSubdomainSetting = t.intersection([
  t.partial({
    id: t.number,
  }),
  t.interface({
    home_url: t.string,
  }),
]);
export type SubdomainSetting = t.TypeOf<typeof tSubdomainSetting>;

export const tStoreDiscountSettings = t.interface({
  id: t.number,
  loyalty_points_stacking: t.boolean,
  max_specials_per_cart: t.union([t.number, t.null]),
});

export type StoreDiscountSettings = t.TypeOf<typeof tStoreDiscountSettings>;

export const tTipSetting = t.interface({
  custom_amounts: t.array(t.number),
  enabled_for: t.union([t.literal('delivery'), t.literal('pickup')]),
  id: t.number,
  variant: t.union([t.literal('dollar'), t.literal('percentage')]),
});
export type TipSetting = DeepReadonly<t.TypeOf<typeof tTipSetting>>;

export const tMenuFilterSetting = t.interface({
  collapse_desktop: t.boolean,
  id: t.number,
});
export type MenuFilterSetting = t.TypeOf<typeof tMenuFilterSetting>;

export const tGuestCheckoutSetting = t.interface({
  disabled: t.boolean,
  id: t.number,
});
export type GuestCheckoutSetting = t.TypeOf<typeof tGuestCheckoutSetting>;

const tPeriod = t.interface({
  from: t.union([t.string, t.null]),
  to: t.union([t.string, t.null]),
});

export const tWorkingHour = t.interface({
  day: t.string,
  period: tPeriod,
});
export type WorkingHour = DeepReadonly<t.TypeOf<typeof tWorkingHour>>;

export const tStorePaymentMethods = t.union([
  t.literal('debit'),
  t.literal('cash'),
  t.literal('credit'),
]);

export const tStoreOption = t.union([
  t.literal('accessible'),
  tStorePaymentMethods,
]);

export type StoreOption = t.TypeOf<typeof tStoreOption>;

export interface TimeRange {
  from: string;
  to: string;
}

export interface StoreFulfillmentSlot {
  name: string;
  value: TimeRange | undefined;
}

interface CanPayable {
  readonly canpay_enabled: boolean;
  readonly canpay_lane_id: string;
  readonly canpay_merchant_id: string;
  readonly canpay_store_code: string;
}

export const tCanpayV2Integration = t.interface({
  api_secret: t.union([t.string, t.undefined]),
  app_key: t.union([t.string, t.undefined]),
  enabled: t.union([t.boolean, t.undefined]),
  enabled_for_checkout: t.union([t.boolean, t.undefined]),
  id: t.union([t.undefined, t.string, t.number]),
  store_id: t.union([t.string, t.number, t.undefined]),
});
export type CanpayV2Integration = t.TypeOf<typeof tCanpayV2Integration>;

interface CanpayV2Editable {
  canpay_v2_integration: CanpayV2Integration;
}

export const tCanPayV2RemotePayIntegration = t.interface({
  api_secret: t.union([t.string, t.undefined]),
  app_key: t.union([t.string, t.undefined]),
  enabled: t.union([t.boolean, t.undefined]),
  id: t.union([t.undefined, t.string, t.number]),
});
export type CanPayV2RemotePayIntegration = t.TypeOf<
  typeof tCanPayV2RemotePayIntegration
>;

interface CanPayV2RemotePayEditable {
  canpay_v2remotepay_integration: CanPayV2RemotePayIntegration;
}

interface BannerMessageEditable {
  banner_message: CommunicationBannerMessageSettings;
}

interface CrmIntegrationsEditable {
  crm_integrations: StoreCrmIntegration[];
}

interface CustomRowsEditable {
  custom_rows: { [k in 'featured' | 'sale']: CustomRowSetting }[];
}

export interface DisabledFeelingAndActivityTag {
  _destroy?: boolean;
  id: Id;
  tag_id: Id;
}

interface DisabledFeelingAndActivityTagsEditable {
  disabled_feeling_and_activity_tags: DisabledFeelingAndActivityTag[];
}

interface KioskEditable {
  kiosk: Kiosk;
  kiosk_photo: string | null;
  kiosk_present: boolean;
}

export const tPayfirmaIntegration = t.interface({
  currency: t.string,
  enabled: t.boolean,
  id: t.union([t.string, t.number, t.undefined]),
  payfirma_client_id: t.union([t.string, t.undefined]),
  payfirma_client_secret: t.union([t.string, t.undefined]),
  payfirma_custom_id: t.string,
});
export type PayfirmaIntegration = t.TypeOf<typeof tPayfirmaIntegration>;

interface PayfirmaEditable {
  payfirma_integration: PayfirmaIntegration;
}

export interface StorePrinter {
  _destroy?: boolean;
  enabled: boolean;
  id?: Id;
  printer_id: string;
  store_id: Id;
}

interface WithStorePrinters {
  printers: StorePrinter[];
}

export type StoreHeaderStore = Pick<
  Store,
  | 'address'
  | 'avg_response_time'
  | 'city'
  | 'curbside_pickup_setting'
  | 'delivery'
  | 'delivery_radius'
  | 'description'
  | 'id'
  | 'lat'
  | 'long'
  | 'medical'
  | 'name'
  | 'name'
  | 'ownership_identifications'
  | 'phone'
  | 'photo'
  | 'rating'
  | 'reservation_mode_labels'
  | 'reviews_count'
  | 'state'
  | 'store_options'
  | 'url_slug'
  | 'zip'
>;

export const tStoreCurbsideSettings = t.interface({
  curbside_hours: t.union([t.array(tWorkingHour), t.null]),
  curbside_last_call_interval: t.number,
  curbside_max_lead_time: t.number,
  curbside_max_lead_time_minutes: t.number,
  curbside_min_lead_time: t.number,
  curbside_min_lead_time_minutes: t.number,
});

export const tDeliveryZipcode = t.interface({
  fee: t.string,
  id: t.number,
  last_call_minutes: t.number,
  lead_time_minutes: t.number,
  min_amount: t.string,
  zipcode: t.string,
});

export const tDeliveryGeofence = t.interface({
  cart_minimum: t.string,
  fee: t.string,
  geojson: t.string,
  id: t.number,
  last_call_minutes: t.union([t.number, t.null]),
  lead_time_minutes: t.union([t.number, t.null]),
});

export const tDeliveryConfigStrategy = t.interface({
  delivery_strategy: t.union([t.string, t.null]),
  id: t.union([t.number, t.null]),
});
export type DeliveryConfigStrategy = DeepReadonly<
  t.TypeOf<typeof tDeliveryConfigStrategy>
>;

const tReservationModeLabels = t.interface({
  curbside: t.union([t.string, t.null]),
  delivery: t.union([t.string, t.null]),
  pickup: t.union([t.string, t.null]),
});
export type ReservationModeLabels = DeepReadonly<
  t.TypeOf<typeof tReservationModeLabels>
>;

const tStoreOptionalAttributes = t.partial({
  canpay_lane_id: t.union([t.string, t.null]),
  canpay_merchant_id: t.union([t.string, t.null]),
  canpay_store_code: t.union([t.string, t.null]),
  cart_limit_policy: t.union([tCartLimitPolicy, t.null]),
  credit_cards: t.boolean,
  crm_integration: t.union([t.null, t.keyof(CrmProvider)]),
  custom_navigation_color: t.union([t.string, t.null]),
  custom_theme_color: t.union([t.string, t.null]),
  force_disable_reviews: t.union([t.null, t.boolean]),
  google_map_url: t.union([t.string, t.null]),
  id_uploading_prohibited: t.union([t.null, t.boolean]),
  latitude: t.number,
  longitude: t.number,
  ownership_identifications: t.union([t.null, t.array(t.string)]),
  require_fulfillment_slot_selection: t.boolean,
  reservation_mode_labels: tReservationModeLabels,
  store_compliance_language_settings: t.union([
    t.null,
    t.record(t.string, t.union([t.string, t.null])),
  ]),
  visible: t.union([t.null, t.boolean]),
  wheelchair_access: t.boolean,
});

const tSearchResultViewType = t.union([
  t.literal('list_view'),
  t.literal('grid_view'),
]);

export type SearchResultViewType = t.TypeOf<typeof tSearchResultViewType>;

export const tOptionalFilter = t.record(
  t.string,
  t.array(t.union([t.string, t.number]))
);

interface FulfillmentMap {
  number_of_delivery_orders_per_hour: {
    [key: number]: { [key: number]: number };
  };
  number_of_pickup_orders_per_hour: {
    [key: number]: { [key: number]: number };
  };
}

export const tStoreSearchResult = t.intersection([
  t.interface({
    allow_future_day_ordering: t.boolean,
    allow_off_hours_ordering: t.boolean,
    avg_response_time: t.union([t.number, t.null]),
    city: t.union([t.string, t.null]),
    curbside_hours: t.union([t.array(tWorkingHour), t.null]),
    curbside_interval: t.number,
    curbside_last_call_interval: t.number,
    curbside_max_lead_time: t.number,
    curbside_max_lead_time_minutes: t.number,
    curbside_min_lead_time: t.number,
    curbside_min_lead_time_minutes: t.number,
    curbside_window: t.number,
    delivery: t.boolean,
    delivery_hours: t.union([t.array(tWorkingHour), t.null]),
    delivery_last_call_interval: t.number,
    /** @todo(elliot): Remove after we deprecate lead time hours */
    delivery_max_lead_time: t.number,
    delivery_max_lead_time_minutes: t.number,
    delivery_min_lead_time: t.number,
    delivery_min_lead_time_minutes: t.number,
    delivery_minimum: t.number,
    delivery_radius: t.union([t.number, t.null]),
    display_curbside_windows_by_day: t.union([t.boolean, t.null]),
    full_address: t.string,
    google_map_url: t.union([t.string, t.null]),
    id: t.union([t.number, t.string]),
    id_required: t.boolean,
    last_call_interval: t.number,
    lat: t.number,
    long: t.number,
    medical: t.boolean,
    metro_area: t.string,
    name: t.string,
    phone: t.string,
    photo: t.union([t.string, t.null]),
    pickup: t.boolean,
    pickup_hours: t.union([t.array(tWorkingHour), t.null]),
    /** @todo(elliot): Remove after we completely deprecate lead time in hours. */
    pickup_max_lead_time: t.number,
    pickup_max_lead_time_minutes: t.number,
    pickup_min_lead_time: t.number,
    pickup_min_lead_time_minutes: t.number,
    pickup_minimum: t.number,
    primary_gallery_image: t.union([t.string, t.null]),
    product_count: t.number,
    rating: t.number,
    recreational: t.boolean,
    reviews_count: t.number,
    state: t.union([t.string, t.null]),
    store_options: t.array(tStoreOption),
    time_zone_identifier: t.string,
    type: t.array(t.string),
    url_slug: t.string,
    working_hours: t.array(tWorkingHour),
  }),
  tStoreCurbsideSettings,
]);

const optionalAttributes = t.partial({
  enabled_for_menu_product: t.boolean,
  ranking: t.number,
});

export const tApplicabilityRules = t.interface({
  filter_type: t.string,
  filter_value: t.string,
});

export const tCustomRowSetting = t.intersection([
  t.interface({
    applicability_rules: t.array(tApplicabilityRules),
    custom_display_name: t.union([t.string, t.null]),
    enabled: t.boolean,
    id: t.union([t.string, t.number]),
    row_type: t.string,
  }),
  optionalAttributes,
]);

export const tStoreMenuFilterSetting = t.union([
  t.interface({
    collapse_desktop: t.boolean,
    id: t.union([t.string, t.number]),
    store_id: t.union([t.string, t.number]),
  }),
  t.null,
]);

export const tMenuRowOption = t.intersection([
  tCustomRowSetting,
  t.interface({
    is_category: t.boolean,
    ranking: t.number,
    row_type: t.string,
  }),
]);

export type CustomRowSetting = t.TypeOf<typeof tCustomRowSetting>;
export type ApplicabilityRules = t.TypeOf<typeof tApplicabilityRules>;
export type MenuRowOption = t.TypeOf<typeof tMenuRowOption>;

export const tCommunicationBannerMessageSettings = t.union([
  t.interface({
    enabled: t.boolean,
    is_expanded: t.boolean,
    message: t.union([t.string, t.null]),
    title: t.union([t.string, t.null]),
  }),
  t.null,
]);

export type CommunicationBannerMessageSettings = t.TypeOf<
  typeof tCommunicationBannerMessageSettings
>;

export const tAnalyticsIntegration = t.interface({
  enabled: t.boolean,
  id: t.union([t.number, t.null]),
  provider: t.string,
  provider_id: t.string,
});

export const tStoreEmailClient = t.interface({
  from_email: t.string,
  from_name: t.union([t.string, t.null]),
  id: t.union([t.number, t.null]),
  provider: t.string,
  provider_api_key: t.string,
  store_display_name: t.union([t.string, t.null]),
  store_id: t.union([t.number, t.null]),
  store_url: t.union([t.string, t.null]),
});

export const tAbandonedCartConfig = t.interface({
  cadence_days: t.number,
  enabled: t.boolean,
  id: t.union([t.number, t.null]),
  provider_template_id: t.string,
  store_id: t.union([t.number, t.null]),
});

export const tCloudflareHostname = t.interface({
  _destroy: t.union([t.boolean, t.null]),
  created_at: t.union([t.string, t.null]),
  external_hostname_id: t.union([t.string, t.null]),
  hostname: t.union([t.string, t.null]),
  id: t.union([t.number, t.null]),
  store_id: t.union([t.number, t.null]),
  updated_at: t.union([t.string, t.null]),
});
export type CloudflareHostname = t.TypeOf<typeof tCloudflareHostname>;

export const tCloudflareRoute = t.interface({
  _destroy: t.union([t.boolean, t.null]),
  created_at: t.union([t.string, t.null]),
  external_route_id: t.union([t.string, t.null]),
  id: t.union([t.number, t.null]),
  route_pattern: t.union([t.string, t.null]),
  store_id: t.union([t.number, t.null]),
  updated_at: t.union([t.string, t.null]),
});
export type CloudflareRoute = t.TypeOf<typeof tCloudflareRoute>;

export type AnalyticsIntegration = t.TypeOf<typeof tAnalyticsIntegration>;
export type StoreEmailClient = t.TypeOf<typeof tStoreEmailClient>;
export type AbandonedCartConfig = t.TypeOf<typeof tAbandonedCartConfig>;

export type WithStoreEmailClient = {
  store_email_client: StoreEmailClient;
};

export type WithAbandonedCartConfig = {
  abandoned_cart_config: AbandonedCartConfig;
};

export const tStore = t.intersection([
  t.interface({
    address: t.string,
    aeropay_integration: t.union([tAeropayIntegration, t.null, t.undefined]),
    allow_future_day_ordering: t.boolean,
    allow_off_hours_ordering: t.boolean,
    analytics_integration: tAnalyticsIntegration,
    avg_response_time: t.union([t.number, t.null]),
    birth_date_required: t.boolean,
    business_paperwork: t.union([t.string, t.null]),
    canpay_enabled: t.boolean,
    canpay_v2remotepay_enabled: t.boolean,
    checkout_age_minimum: t.number,
    checkout_agreement_copy: t.union([t.string, t.null]),
    checkout_agreement_link: t.union([t.string, t.null]),
    city: t.union([t.string, t.null]),
    cloudflare_hostname: t.union([t.undefined, tCloudflareHostname]),
    cloudflare_route: t.union([t.undefined, tCloudflareRoute]),
    country_code: t.union([t.string, t.null]),
    curbside_interval: t.number,
    curbside_minimum: t.number,
    curbside_pickup_instructions: t.union([t.null, t.string]),
    curbside_pickup_setting: t.union([
      t.null,
      t.union([
        t.literal('allowed'),
        t.literal('not_allowed'),
        t.literal('mandatory'),
      ]),
    ]),
    curbside_window: t.number,
    custom_compliance_language_setting_labels: t.union([
      t.record(t.string, t.string),
      t.null,
      t.undefined,
    ]),
    custom_lineage_labels: t.union([t.record(t.string, t.string), t.null]),
    custom_payment_options: t.union([t.array(t.string), t.null]),
    custom_product_type_labels: t.union([t.record(t.string, t.string), t.null]),
    custom_product_type_ranking: t.union([t.array(t.string), t.null]),
    custom_reservation_mode_labels: t.union([
      t.record(t.string, t.string),
      t.null,
      t.undefined,
    ]),
    custom_rows: t.union([t.array(t.string), t.null]),
    customer_paperwork: t.union([t.string, t.null]),
    delivery: t.boolean,
    delivery_config_strategy: tDeliveryConfigStrategy,
    delivery_fee_amount: t.number,
    delivery_geofences: t.array(tDeliveryGeofence),
    delivery_hours: t.union([t.array(tWorkingHour), t.null]),
    delivery_interval: t.number,
    delivery_last_call_interval: t.number,
    /** @todo(elliot): Remove after we completely deprecate lead time in hours. */
    delivery_max_lead_time: t.number,
    delivery_max_lead_time_minutes: t.number,
    delivery_max_orders_per_window: t.union([t.number, t.null]),
    delivery_min_lead_time: t.number,
    delivery_min_lead_time_minutes: t.number,
    delivery_minimum: t.number,
    delivery_payment_options: t.array(t.string),
    delivery_radius: t.union([t.number, t.null]),
    delivery_tip_settings: t.union([tTipSetting, t.null]),
    delivery_window: t.number,
    delivery_zipcodes: t.array(tDeliveryZipcode),
    description: t.union([t.string, t.null]),
    disabled_feeling_and_activity_tags: t.array(t.string),
    display_curbside_windows_by_day: t.union([t.boolean, t.null]),
    display_delivery_windows_by_day: t.union([t.boolean, t.null]),
    display_pickup_windows_by_day: t.union([t.boolean, t.null]),
    enable_tax_lookup: t.union([t.boolean, t.null]),
    free_delivery_configs: t.array(tFreeDeliveryConfig),
    full_address: t.string,
    guest_checkout_setting: t.union([tGuestCheckoutSetting, t.null]),
    hide_prices: t.boolean,
    hypur_enabled: t.boolean,
    id: t.union([t.number, t.string]),
    id_required: t.boolean,
    /** @todo(elliot): Remove undefined after backend is deployed. */
    kiosk_id: t.union([t.null, t.number, t.undefined]),
    kiosk_photo: t.union([t.string, t.null]),
    last_call_interval: t.number,
    lat: t.number,
    long: t.number,
    medical: t.boolean,
    menu_filter_setting: t.union([tMenuFilterSetting, t.null]),
    metro_area: t.string,
    moneris_integration: t.union([tMonerisIntegration, t.null, t.undefined]),
    name: t.string,
    optional_filters: t.union([tOptionalFilter, t.null]),
    other_cloudflare_hostnames: t.union([
      t.undefined,
      t.array(tCloudflareHostname),
    ]),
    pdp_setting: t.union([tPdpSetting, t.null]),
    phone: t.string,
    photo: t.union([t.string, t.null]),
    pickup: t.boolean,
    pickup_hours: t.union([t.array(tWorkingHour), t.null]),
    pickup_interval: t.number,
    /** @todo(elliot): Remove after we completely deprecate lead time in hours. */
    pickup_max_lead_time: t.number,
    pickup_max_lead_time_minutes: t.number,
    pickup_max_orders_per_window: t.union([t.number, t.null]),
    pickup_min_lead_time: t.number,
    pickup_min_lead_time_minutes: t.number,
    pickup_minimum: t.number,
    pickup_payment_options: t.array(t.string),
    pickup_window: t.number,
    prepay: t.boolean,
    prepayment_providers: t.array(t.string),
    primary_gallery_image: t.union([t.string, t.null]),
    product_count: t.number,
    product_reviews_enabled: t.boolean,
    rating: t.number,
    recreational: t.boolean,
    require_checkout_agreement: t.boolean,
    reviews_count: t.number,
    sales_tax_rate: t.number,
    service_fee_amount: t.number,
    show_paperwork: t.boolean,
    square_integration: t.union([tSquareIntegration, t.null, t.undefined]),
    state: t.union([t.string, t.null]),
    store_options: t.array(tStoreOption),
    store_taxes: t.array(tStoreTax),
    stronghold_integration: t.union([
      tStrongholdIntegration,
      t.null,
      t.undefined,
    ]),
    subdomain_setting: t.union([tSubdomainSetting, t.null]),
    tax_included: t.boolean,
    time_zone_identifier: t.string,
    tip_settings: t.array(tTipSetting),
    url_slug: t.string,
    view_default: t.union([tSearchResultViewType, t.null]),
    white_label_enabled: t.boolean,
    working_hours: t.array(tWorkingHour),
    zip: t.union([t.string, t.null]),
  }),
  tStoreCurbsideSettings,
  tStoreOptionalAttributes,
]);

// Marketplace::Reservation::Details::StoreSerializer
export const tCustomerReservationDetailsStore = t.intersection([
  t.interface({
    address: t.string,
    allow_future_day_ordering: t.boolean,
    allow_off_hours_ordering: t.boolean,
    avg_response_time: t.union([t.number, t.null]),
    city: t.union([t.string, t.null]),
    curbside_form_enabled: t.union([t.boolean, t.null]),
    curbside_form_instructions: t.union([t.null, t.string]),
    delivery: t.boolean,
    delivery_hours: t.union([t.array(tWorkingHour), t.null]),
    delivery_last_call_interval: t.number,
    /** @todo(elliot): Remove after we completely deprecate lead time in hours. */
    delivery_max_lead_time: t.number,
    delivery_max_lead_time_minutes: t.number,
    delivery_min_lead_time: t.number,
    delivery_min_lead_time_minutes: t.number,
    delivery_radius: t.union([t.number, t.null]),
    delivery_zipcodes: t.array(tDeliveryZipcode),
    description: t.union([t.string, t.null]),
    display_curbside_windows_by_day: t.union([t.boolean, t.null]),
    display_delivery_windows_by_day: t.union([t.boolean, t.null]),
    display_pickup_windows_by_day: t.union([t.boolean, t.null]),
    id: t.union([t.number, t.string]),
    last_call_interval: t.number,
    lat: t.number,
    long: t.number,
    medical: t.boolean,
    name: t.string,
    phone: t.string,
    photo: t.union([t.string, t.null]),
    pickup_hours: t.union([t.array(tWorkingHour), t.null]),
    /** @todo(elliot): Remove after we completely deprecate lead time in hours. */
    pickup_max_lead_time: t.number,
    pickup_max_lead_time_minutes: t.number,
    pickup_min_lead_time: t.number,
    pickup_min_lead_time_minutes: t.number,
    prepay: t.boolean,
    rating: t.number,
    reservation_mode_labels: tReservationModeLabels,
    reviews_count: t.number,
    state: t.union([t.string, t.null]),
    store_compliance_language_settings: t.union([
      t.record(t.string, t.union([t.string, t.null, t.number])),
      t.undefined,
      t.null,
    ]),
    store_options: t.array(tStoreOption),
    time_zone_identifier: t.string,
    url_slug: t.string,
    working_hours: t.array(tWorkingHour),
    zip: t.union([t.string, t.null]),
  }),
  tStoreCurbsideSettings,
]);

export type CustomerReservationDetailsStore = DeepReadonly<
  t.TypeOf<typeof tCustomerReservationDetailsStore>
>;
export type Store = DeepReadonly<t.TypeOf<typeof tStore>>;
export type StoreSearchResult = t.TypeOf<typeof tStoreSearchResult>;

export interface CartStore {
  id: Id;
  name: string;
  reservation_mode_labels: {
    curbside: string;
    delivery: string;
    pickup: string;
  };
}

export type CustomLineageLabels = {
  [key: string]: { id: Id; label: string };
};

export type CustomProductTypeLabels = {
  customizable_id: Id;
  id: Id;
  label: string;
  product_subtype: string | null;
  product_type: string;
}[];

export type StoreWithModifiedCustomLabels = Omit<
  Store,
  | 'custom_lineage_labels'
  | 'custom_product_type_ranking'
  | 'custom_product_type_labels'
> & {
  custom_lineage_labels: CustomLineageLabels;

  custom_product_type_labels: CustomProductTypeLabels;

  custom_product_type_ranking: {
    id: Id;
    product_type: string;
    product_type_id: Id;
    ranking: number;
  }[];
};

export type StoreWithMenuProduct = Store & {
  readonly menu_product: DeepReadonly<MenuProduct>;
};

// NOTE(elliot): These fields are omitted bc they are serialized differently in the business app.
type StoreWithEditFields = Omit<
  Store,
  'custom_rows' | 'crm_integrations' | 'disabled_feeling_and_activity_tags'
>;

export const tPaymentOption = t.interface({
  enabled: t.boolean,
  id: t.union([t.string, t.number]),
  payment_option: t.string,
  reservation_mode: tReservationMode,
  store_id: t.union([t.string, t.number]),
});
export type PaymentOption = t.TypeOf<typeof tPaymentOption>;

interface WithPaymentOptions {
  payment_options: PaymentOption[];
}

export type DocumentationSetting =
  | 'number'
  | 'photo'
  | 'photo_and_number'
  | 'none';

export const tMedicalStoreDocumentationRequirement = t.interface({
  id: t.union([t.string, t.number]),
  require_medical_id: t.union([
    t.literal('number'),
    t.literal('photo'),
    t.literal('photo_and_number'),
    t.literal('none'),
  ]),
});
export type MedicalStoreDocumentationRequirement = t.TypeOf<
  typeof tMedicalStoreDocumentationRequirement
>;

interface WithMedicalStoreDocumentationRequirement {
  medical_store_documentation_requirement: MedicalStoreDocumentationRequirement;
}

export type CurbsideSetting = 'allowed' | 'mandatory' | 'not_allowed';

export const tCurbsidePickupSettingObject = t.interface({
  form_enabled: t.boolean,
  id: t.union([t.string, t.number]),
  instructions: t.union([t.string, t.null]),
  setting: t.union([
    t.literal('allowed'),
    t.literal('mandatory'),
    t.literal('not_allowed'),
  ]),
});
export type CurbsidePickupSettingObject = t.TypeOf<
  typeof tCurbsidePickupSettingObject
>;

interface WithCurbsidePickupSettingOptions {
  curbside_max_orders_per_window: number | null;
  curbside_pickup_setting: CurbsidePickupSettingObject;
}

interface WithOwnershipIdentifications {
  ownership_identifications: {
    enabled: boolean;
    group_name: string;
    id?: Id;
  }[];
}

export type StoreWithCurbsidePickupSettings = Store &
  WithCurbsidePickupSettingOptions &
  PayfirmaEditable;

export type StoreWithEditOptions = StoreWithEditFields &
  AnalyticsIntegration &
  BannerMessageEditable &
  CloudflareHostname &
  CloudflareRoute &
  CanPayable &
  CanpayV2Editable &
  CanPayV2RemotePayEditable &
  CrmIntegrationsEditable &
  CustomRowsEditable &
  WithStoreEmailClient &
  WithAbandonedCartConfig &
  WithStorePrinters &
  WithPaymentOptions &
  WithMedicalStoreDocumentationRequirement &
  WithCurbsidePickupSettingOptions &
  WithOwnershipIdentifications &
  PayfirmaEditable &
  DisabledFeelingAndActivityTagsEditable &
  KioskEditable;

export type StoreWithFulfillmentMap = Store & FulfillmentMap;
export type DeliveryZipcode = t.TypeOf<typeof tDeliveryZipcode>;
export type DeliveryGeofence = t.TypeOf<typeof tDeliveryGeofence>;

export type AbbreviatedStore = {
  available: boolean;
} & Pick<
  Store,
  | 'delivery'
  | 'id'
  | 'name'
  | 'photo'
  | 'pickup'
  | 'prepay'
  | 'recreational'
  | 'time_zone_identifier'
  | 'url_slug'
>;

export type DeferrableAction<A> = A & { defer: A };
