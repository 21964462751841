import styled from '@emotion/styled';

import { StyledButton } from '../../button/button.styles';

export const StyledCardAction = styled.div({
  marginTop: 'auto',
  width: '100%',
  padding: 0,
});

export const StyledCardActionButton = styled(StyledButton)({
  borderRadius: 0,
  '&:before': {
    borderRadius: '0 !important',
  },
});
