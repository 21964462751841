import type { MarginProperties } from '../../../../styling';
import type { FieldProps } from '../field.types';
import { StyledHelperText } from './helperText.styles';

export interface HelperTextProps
  extends MarginProperties,
    Pick<FieldProps, 'helperText'> {
  children?: React.ReactNode;
  disabled?: boolean;
  id?: string;
}

export const HelperText = ({
  children,
  id,
  disabled,
  ...props
}: HelperTextProps) => {
  if (!children) {
    return null;
  }
  return (
    <StyledHelperText
      color="grays-mid"
      disabled={disabled}
      id={`${id}-helper-text`}
      mt={16}
      {...props}
    >
      {children}
    </StyledHelperText>
  );
};
