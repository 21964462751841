import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Topicals = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 6C4 4.89543 4.89543 4 6 4H18C19.1046 4 20 4.89543 20 6V22C20 23.1046 19.1046 24 18 24H6C4.89543 24 4 23.1046 4 22V6Z"
      fill="#CE349A"
    />
    <path
      d="M7.75 1C7.75 0.447715 8.19772 0 8.75 0H14.75C15.3023 0 15.75 0.447715 15.75 1V5C15.75 5.55228 15.3023 6 14.75 6H8.75C8.19772 6 7.75 5.55228 7.75 5V1Z"
      fill="#CE349A"
    />
    <path
      d="M8 9.99988C8 8.89531 8.89543 7.99988 10 7.99988H20V19.9999H10C8.89543 19.9999 8 19.1044 8 17.9999V9.99988Z"
      fill="black"
      fillOpacity={0.5}
      className="details"
    />
  </svg>
);
export const TopicalsIcon = ({ ...props }: DynamicIconProps) => {
  return <Icon icon={Topicals} {...props} color={props.color} />;
};
