import { AnimatePresence, motion } from 'framer-motion';
import type { ReactNode } from 'react';

import { shadows, useMobileMediaQuery, useTheme } from '@jane/reefer';
import { isSortedByPrice } from '@jane/search/util';

import { gridItem } from '../../../style/boxShadow';
import {
  MENU_PRODUCT_ITEM_HEIGHT,
  MENU_PRODUCT_MOBILE_ITEM_HEIGHT,
} from '../../storeMenu/productCardHelper';
import {
  DisplayMode,
  useProductCardContext,
} from '../providers/productCardProvider';
import { MenuProductConfirmationView } from './confirmationView';
import { MenuProductCardDefaultView } from './defaultView';
import { MenuProductCardEditView } from './editView';
import type { RenderProps } from './listViewStateContainer';
import ListViewStateContainer from './listViewStateContainer';

const ProductCardContents = ({
  children,
  focused,
  childKey,
}: {
  childKey: string | number | null | undefined;
  children: ReactNode;
  focused?: boolean;
}) => {
  const theme = useTheme();
  const focusedStyles = focused
    ? {
        boxShadow: shadows.item,
        backgroundColor: theme?.colors.grays.ultralight,
      }
    : gridItem();

  return (
    <motion.div
      initial={{ opacity: 0, y: 10, scale: 0.9 }}
      animate={{ opacity: 1, y: 0, scale: 1 }}
      exit={{ opacity: 0, scale: 0.9, transition: { duration: 0.2 } }}
      transition={{ duration: 0.3 }}
      key={childKey}
      css={{
        width: '100%',
        flex: '1 0 auto',
        backfaceVisibility: 'hidden',
        WebkitBackfaceVisibility: 'hidden',
        borderRadius: theme?.borderRadius.sm,
        overflow: 'hidden',
        backgroundColor: theme.colors.grays.white,
        ...focusedStyles,
      }}
    >
      {children}
    </motion.div>
  );
};

export const MenuProductCardContainer = () => {
  const { displayMode, menuProduct, searchState, maxWeightOption } =
    useProductCardContext();
  const isMobile = useMobileMediaQuery({});
  const menuProductCardHeight = isMobile
    ? MENU_PRODUCT_MOBILE_ITEM_HEIGHT
    : MENU_PRODUCT_ITEM_HEIGHT;

  return (
    <ListViewStateContainer sortedByPrice={isSortedByPrice(searchState)}>
      {(props: RenderProps) => {
        const { shoppingDisabled, selectedQuantity } = props;
        return (
          <AnimatePresence initial={false}>
            {displayMode === DisplayMode.Confirmation && (
              <ProductCardContents
                childKey={`${menuProduct.id}-confirm`}
                focused
              >
                <MenuProductConfirmationView
                  {...props}
                  height={menuProductCardHeight}
                />
              </ProductCardContents>
            )}
            {displayMode === DisplayMode.Edit && (
              <ProductCardContents childKey={`${menuProduct.id}-edit`} focused>
                <MenuProductCardEditView
                  {...props}
                  maxWeightOption={maxWeightOption}
                  height={menuProductCardHeight}
                  selectedQuantity={selectedQuantity}
                  shoppingDisabled={shoppingDisabled}
                />
              </ProductCardContents>
            )}
            {displayMode === DisplayMode.Product && (
              <ProductCardContents childKey={`${menuProduct.id}-default`}>
                <MenuProductCardDefaultView
                  height={menuProductCardHeight}
                  selectedQuantity={selectedQuantity}
                  shoppingDisabled={shoppingDisabled}
                />
              </ProductCardContents>
            )}
          </AnimatePresence>
        );
      }}
    </ListViewStateContainer>
  );
};
