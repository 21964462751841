import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { configureCustomerStore } from '@jane/monolith/shared';

import App from './main';

const store = configureCustomerStore();
const queryClient = new QueryClient();

const container = document.getElementById('app') as HTMLElement;
const root = createRoot(container);

root.render(
  <HelmetProvider>
    <ReduxProvider store={store}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </QueryClientProvider>
    </ReduxProvider>
  </HelmetProvider>
);
