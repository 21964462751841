import { styled } from '@jane/reefer';

const OffscreenLabel = styled.label({
  position: 'absolute',
  left: '-10000px',
  top: 'auto',
  width: '1px',
  height: '1px',
  overflow: 'hidden',
});

export default OffscreenLabel;
