import { shadows, spacing, styled } from '@jane/reefer';

export const FilterSearchContainer = styled.div({
  position: 'relative',
  display: 'block',
});

export const NoClickEvent = styled.div({
  pointerEvents: 'none',
});

export const FilterToggleWrapper = styled.span(
  ({ searchBarOpen }: { searchBarOpen: boolean }) => ({
    display: searchBarOpen ? 'none' : 'block',
    outline: 'none',
    position: 'relative',
    cursor: 'pointer',
  }),
  ({ theme }) => ({
    ':focus-visible': {
      '&:after': {
        position: 'absolute',
        content: `''`,
        display: 'inherit',
        top: '-4px',
        left: '-4px',
        bottom: '-4px',
        right: '-4px',
        border: `2px solid ${theme.colors.primary.main}`,
        borderRadius: `calc(${theme.borderRadius.lg} + 4px)`,
        backgroundColor: 'transparent',
      },
    },
  })
);

export const ClearButtonWrapper = styled.span(
  ({ hasQuery }: { hasQuery: boolean }) => ({
    display: hasQuery ? 'block' : 'none',
    outline: 'none',
    position: 'relative',
    cursor: 'pointer',
  }),
  ({ theme }) => ({
    outline: 'none',
    position: 'absolute',
    top: 12,
    right: 16,
    cursor: 'pointer',
    ':focus-visible': {
      '&:after': {
        position: 'absolute',
        content: `''`,
        display: 'inherit',
        top: '-4px',
        left: '-4px',
        bottom: '-4px',
        right: '-4px',
        border: `2px solid ${theme.colors.primary.main}`,
        borderRadius: `calc(${theme.borderRadius.lg} + 4px)`,
        backgroundColor: 'transparent',
      },
    },
  })
);

export const StyledContent = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.grays.white,
  boxShadow: shadows.hard,
  overflowY: 'hidden',
  position: 'absolute',
  zIndex: 100,
  marginTop: '14px',
  borderRadius: theme.borderRadius.lg,
  width: '320px',
}));

export const StyledInput = styled.input(
  ({ theme: { borderRadius, colors } }) => ({
    display: 'flex',
    height: '48px',
    outline: 'none',
    minWidth: '268px',
    width: '100%',
    margin: 0,
    background: colors.grays.white,
    border: `2px solid ${colors.grays.light}`,
    borderRadius: borderRadius.lg,
    ...spacing({ py: 12, px: 48 }),
    WebkitAppearance: 'none',
    '&::-ms-clear': {
      display: 'none',
    },
    '&:active, &:focus, &:focus-visible': {
      borderColor: colors.primary.main,
      '+ .filter-search__icon': {
        color: colors.primary.main,
      },
    },
    '&:read-only, &:disabled': {
      borderColor: colors.grays.light,
      pointerEvents: 'none',
    },
    '&::-webkit-search-cancel-button': {
      WebkitAppearance: 'none',
    },
  })
);

export const SearchIconWrapper = styled.div({
  position: 'absolute',
  top: '50%',
  ...spacing({ mt: -12 }),
  left: 16,
  height: '24px',
});
