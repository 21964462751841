import { Flex, mediaQueries, styled } from '@jane/reefer';
import type { Brand } from '@jane/shared/models';

import { Carousel } from '../carousel';
import type { CarouselMediaProps } from '../carousel/carousel.types';

const NoAssetsBackground = styled.div<{
  backgroundColor?: string;
  forceMobile?: boolean;
}>(({ backgroundColor = 'white', forceMobile }) => ({
  position: 'relative',
  height: !forceMobile ? 192 : 96,
  backgroundSize: 'cover',
  backgroundPosition: '50% 50%',
  backgroundColor,
  [mediaQueries.mobile('lg', 'min')]: {
    height: 192,
  },
}));
const buildBrandMediaItems = (brand: Brand): CarouselMediaProps[] => {
  const { custom_images = [], custom_video = null, name } = brand;

  const brandMediaItems = [...custom_images]
    .sort((a, b) => a.position - b.position)
    .map((customImage) => ({
      imageSrc: customImage.image_url,
      imageAlt: `${name} brand page image`,
    }));

  if (custom_video) {
    const video = {
      imageSrc: custom_video.overlay_image_url,
      videoSrc: custom_video.video_url,
      videoTitle: custom_video.overlay_text || undefined,
      imageAlt: `${name} brand page video`,
    };
    if (custom_video.position) {
      brandMediaItems.splice(custom_video.position, 0, video);
    } else {
      brandMediaItems.unshift(video);
    }
  }
  return brandMediaItems;
};
export const BrandMediaCarousel = ({
  brand,
  forceMobile,
}: {
  brand: Brand;
  forceMobile?: boolean;
}) => {
  const brandMediaItems = buildBrandMediaItems(brand);

  if (brandMediaItems.length < 1) {
    return (
      <NoAssetsBackground
        data-testid="no-assets-background"
        backgroundColor={brand.color_hex || undefined}
        forceMobile={forceMobile}
        role="img"
      />
    );
  }

  return (
    <Flex width="100%">
      <Carousel
        items={brandMediaItems}
        variant="brand"
        desktopHeight={forceMobile ? '240px' : '420px'}
        mobileHeight="240px"
        forceMobile={forceMobile}
      />
    </Flex>
  );
};
