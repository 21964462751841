import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Dash = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 8C14 8.55228 13.5523 9 13 9L3 9C2.44772 9 2 8.55228 2 8C2 7.44772 2.44771 7 3 7L13 7C13.5523 7 14 7.44772 14 8Z"
      fill="#0E0E0E"
    />
  </svg>
);
export const DashIcon = ({ ...props }: DynamicIconProps) => {
  return <Icon icon={Dash} {...props} color={props.color} />;
};
