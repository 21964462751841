import styled from '@emotion/styled';

import { mediaQueries, shadows, spacing } from '../../../styling';
import { Box } from '../../box';
import type { ModalStyleProps } from '../modal.types';

type ModalHeaderStyleProps = Omit<ModalStyleProps, 'onRequestClose' | 'open'>;

export const StyledModalHeader = styled.div<ModalHeaderStyleProps>(
  ({ variant }: ModalHeaderStyleProps) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    minHeight: '80px',
    boxShadow: shadows.divider,
    ...spacing({
      px: 24,
      py: 16,
    }),
    [mediaQueries.tablet('min')]: {
      ...spacing({
        px: variant === 'full-screen' ? 40 : 24,
      }),
    },
  })
);

export const StyledIconContainer = styled(Box)({
  zIndex: 1,
});
