import * as t from 'io-ts';

import { tOptionalFilter } from './store';

const optionalAttributes = t.partial({
  optional_filters: t.union([t.null, tOptionalFilter]),
});

export const tOperator = t.intersection([
  t.interface({
    id: t.union([t.number, t.string]),
    name: t.string,
    banner_photo_url: t.union([t.null, t.string]),
    logo_url: t.union([t.null, t.string]),
    navigation_color: t.union([t.null, t.string]),
    theme_color: t.union([t.null, t.string]),
    store_ids: t.array(t.number),
    default_search_radius: t.union([t.null, t.string]),
  }),
  optionalAttributes,
]);

const tMedRec = t.union([
  t.literal('medical'),
  t.literal('recreational'),
  t.null,
]);

export type Operator = t.TypeOf<typeof tOperator>;

export type MedRec = t.TypeOf<typeof tMedRec>;
