import type { Reducer } from 'redux';

import type { DeepReadonly, Id } from '@jane/shared/models';
import { trackError } from '@jane/shared/util';

import { createSimpleAction, createStandardAction } from '../../redux-util';
import { CartSource } from '../../sources/cart';
import type { CustomerThunkAction } from '../redux';
import type { CustomerAction } from './types';

export interface BundlePossibilities {
  discountable_product_ids: readonly Id[];
  special_id: Id | null;
  status: 'applied' | 'qualified' | 'partially_qualified' | null;
}

export const SHOW_BUNDLE_POSSIBILITIES = 'bundle-possibilities/show';
export const showBundlePossibilities = createSimpleAction(
  SHOW_BUNDLE_POSSIBILITIES
);

export const STOP_SHOW_BUNDLE_POSSIBILITIES = 'bundle-possibilities/stop-show';
export const stopShowBundlePossibilities = createSimpleAction(
  STOP_SHOW_BUNDLE_POSSIBILITIES
);

export const APPLY_DISCOUNTABLE_PRODUCTS_FILTER =
  'bundle-possibilities/apply-discountable-products-filter';
export const applyDiscountableProductsFilter = createSimpleAction(
  APPLY_DISCOUNTABLE_PRODUCTS_FILTER
);

export const REMOVE_DISCOUNTABLE_PRODUCTS_FILTER =
  'bundle-possibilities/remove-discountable-products-filter';
export const removeDiscountableProductsFilter = createSimpleAction(
  REMOVE_DISCOUNTABLE_PRODUCTS_FILTER
);

export const getBundlePossibilities =
  (cartUuid: string): CustomerThunkAction =>
  (dispatch, getState) => {
    const {
      bundlePossibilities: { touchedProductId },
    } = getState();

    if (!(touchedProductId && cartUuid)) {
      return;
    }

    return CartSource.getBundlePossibilities(cartUuid, touchedProductId)
      .then((result: BundlePossibilities) =>
        dispatch(getBundlePossibilitiesSuccess(result))
      )
      .catch((error) => {
        trackError(new Error('Bundle possibilities fetch failed'), {
          error,
        });
      });
  };

export const GET_BUNDLE_POSSIBILITIES_SUCCESS =
  'bundle-possibilities/get-success';
export const getBundlePossibilitiesSuccess =
  (result: BundlePossibilities): CustomerThunkAction =>
  (dispatch) => {
    dispatch({ type: GET_BUNDLE_POSSIBILITIES_SUCCESS, payload: result });
  };

export const SET_TOUCHED_PRODUCT = 'bundle-possibilities/set-touched-product';
export const setTouchedProduct = createStandardAction(SET_TOUCHED_PRODUCT)<
  Id | undefined
>();

export const CLEAR_TOUCHED_PRODUCT =
  'bundle-possibilities/clear-touched-product';
export const clearTouchedProduct = createSimpleAction(CLEAR_TOUCHED_PRODUCT);

export type BundlePossibilitiesActions =
  | {
      type: typeof SHOW_BUNDLE_POSSIBILITIES;
    }
  | { type: typeof STOP_SHOW_BUNDLE_POSSIBILITIES }
  | {
      type: typeof APPLY_DISCOUNTABLE_PRODUCTS_FILTER;
    }
  | { type: typeof REMOVE_DISCOUNTABLE_PRODUCTS_FILTER }
  | {
      payload: BundlePossibilities;
      type: typeof GET_BUNDLE_POSSIBILITIES_SUCCESS;
    }
  | { payload: Id | undefined; type: typeof SET_TOUCHED_PRODUCT }
  | { type: typeof CLEAR_TOUCHED_PRODUCT };

export const defaultBundlePossibilities =
  (): BundlePossibilitiesState['bundlePossibilities'] => ({
    status: null,
    special_id: null,
    discountable_product_ids: [],
  });

export type BundlePossibilitiesState = DeepReadonly<{
  bundlePossibilities: BundlePossibilities;
  filterDiscountableProducts: boolean;
  showingBundlePossibilities: boolean;
  touchedProductId?: Id;
}>;

const getInitialState = (): BundlePossibilitiesState => ({
  bundlePossibilities: defaultBundlePossibilities(),
  filterDiscountableProducts: false,
  showingBundlePossibilities: false,
  touchedProductId: undefined,
});

export const bundlePossibilitiesReducer: Reducer<
  BundlePossibilitiesState,
  CustomerAction
> = (state = getInitialState(), action) => {
  switch (action.type) {
    case SHOW_BUNDLE_POSSIBILITIES:
      return { ...state, showingBundlePossibilities: true };
    case STOP_SHOW_BUNDLE_POSSIBILITIES:
      return getInitialState();
    case APPLY_DISCOUNTABLE_PRODUCTS_FILTER:
      return { ...state, filterDiscountableProducts: true };
    case REMOVE_DISCOUNTABLE_PRODUCTS_FILTER:
      return { ...state, filterDiscountableProducts: false };
    case GET_BUNDLE_POSSIBILITIES_SUCCESS:
      return { ...state, bundlePossibilities: action.payload };
    case SET_TOUCHED_PRODUCT:
      return {
        ...state,
        bundlePossibilities: defaultBundlePossibilities(),
        touchedProductId: action.payload,
      };
    case CLEAR_TOUCHED_PRODUCT:
      return {
        ...state,
        touchedProductId: undefined,
      };
  }

  return state;
};
