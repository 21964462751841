import type { Dispatch, ReactNode, SetStateAction } from 'react';
import React, { createContext, useContext, useEffect, useState } from 'react';

import { trackOpenedAccordionItem } from '@jane/shared-ecomm/tracking';

const AccordionItemExpandedContext = createContext<boolean | undefined>(
  undefined
);
const AccordionItemSetExpandedContext = createContext<
  Dispatch<SetStateAction<boolean>> | undefined
>(undefined);

const AccordionItemIdContext = createContext<string | undefined>(undefined);

interface Props {
  children: ReactNode;
  defaultExpanded?: boolean;
  expanded?: boolean;
  id: string;
}

const AccordionItem = ({
  expanded,
  children,
  id,
  defaultExpanded = false,
}: Props) => {
  const [itemExpanded, setItemExpanded] = useState(defaultExpanded);

  useEffect(() => {
    if (expanded || itemExpanded) {
      trackOpenedAccordionItem(id);
    }
  }, [expanded, itemExpanded]);

  return (
    <AccordionItemExpandedContext.Provider
      value={expanded === undefined ? itemExpanded : expanded}
    >
      <AccordionItemSetExpandedContext.Provider value={setItemExpanded}>
        <AccordionItemIdContext.Provider value={id}>
          <div id={`accordion-item-${id}`} data-testid={`accordion-item-${id}`}>
            {children}
          </div>
        </AccordionItemIdContext.Provider>
      </AccordionItemSetExpandedContext.Provider>
    </AccordionItemExpandedContext.Provider>
  );
};

export const useItemId = () => {
  const context = useContext(AccordionItemIdContext);
  if (context === undefined) {
    throw new Error('useItemId must be used within a AccordionItem');
  }

  return context;
};

export const useItemExpanded = () => {
  const context = useContext(AccordionItemExpandedContext);
  if (context === undefined) {
    throw new Error('useItemExpanded must be used within a AccordionItem');
  }

  return context;
};

export const useSetItemExpanded = () => {
  const context = useContext(AccordionItemSetExpandedContext);
  if (context === undefined) {
    throw new Error('useSetItemExpanded must be used within a AccordionItem');
  }

  return context;
};

export default AccordionItem;
