import type { ButtonConfig } from '../../types';

export const Button: ButtonConfig = {
  icons: {
    sizes: {
      default: {
        height: '48px',
      },
      jumbo: {
        height: '48px',
      },
      large: {
        height: '48px',
      },
      small: {
        height: '48px',
      },
    },
  },
  sizes: {
    default: {
      height: '48px',
      paddingX: 16,
    },
    jumbo: {
      height: '72px',
      paddingX: 16,
    },
    large: {
      height: '72px',
      paddingX: 16,
    },
    small: {
      height: '32px',
      paddingX: 16,
    },
  },
  variants: {
    destructive: {
      backgroundColor: 'error',
      borderColor: 'error',
      typographyColor: 'grays-white',
    },
    minimal: {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      typographyColor: 'grays-black',
    },
    'minimal-inverse': {
      backgroundColor: 'grays-white',
      borderColor: 'grays-white',
      typographyColor: 'grays-black',
    },
    primary: {
      backgroundColor: 'primary',
      borderColor: 'primary',
      typographyColor: 'text-inverse',
    },
    'primary-inverse': {
      backgroundColor: 'grays-white',
      borderColor: 'grays-white',
      typographyColor: 'primary',
    },
    secondary: {
      backgroundColor: 'primary-light',
      borderColor: 'primary-light',
      typographyColor: 'primary',
    },
    shared: {
      borderRadius: 'lg',
      borderWidth: '2px',
      typographyVariant: 'body-bold',
    },
    tertiary: {
      backgroundColor: 'grays-ultralight',
      borderColor: 'grays-ultralight',
      typographyColor: 'grays-black',
    },
    'tertiary-selected': {
      backgroundColor: 'grays-ultralight',
      borderColor: 'primary',
      typographyColor: 'grays-black',
    },
  },
};
