import type { MouseEventHandler, ReactChild } from 'react';

import { styled } from '@jane/reefer';

import { useScrollToElement } from '../hooks/useScrollToElement';
import { spacing } from '../style';

const Link = styled.a({
  ...spacing({ px: 24, py: 8 }),
  background: 'white',
  zIndex: 10000,
  position: 'absolute',
  left: '-10000px',
  top: 'auto',
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  '&:focus': {
    position: 'fixed',
    left: 0,
    width: 'auto',
    height: 'auto',
  },
});

interface SkipLinkProps {
  children: ReactChild;
  targetElementId: string;
}

const forceFocus = (element: HTMLElement) => {
  element.focus({ preventScroll: true });
  if (document.activeElement === element) {
    return false;
  } else {
    element.setAttribute('tabindex', '-1');
    element.focus({ preventScroll: true });
  }
};

const SkipLink = ({ targetElementId, children }: SkipLinkProps) => {
  const scrollToElement = useScrollToElement(targetElementId);

  const handleClick: MouseEventHandler = (e) => {
    e.preventDefault();
    const element = document.getElementById(targetElementId);

    scrollToElement();

    if (!element) return;
    forceFocus(element);
  };

  return (
    <Link onClick={handleClick} href={`#${targetElementId}`}>
      {children}
    </Link>
  );
};

export const SkipLinks = () => {
  const currentlyOnSearchResults =
    window.location.pathname.includes('products') ||
    window.location.pathname.includes('stores');

  const currentlyOnStoreSearchPage =
    window.location.pathname.includes('stores') &&
    !window.location.pathname.includes('stores/');

  if (!currentlyOnSearchResults && currentlyOnStoreSearchPage) return null;

  return (
    <>
      {currentlyOnSearchResults && (
        <>
          <SkipLink targetElementId="results-filter">
            Skip to Results Filters
          </SkipLink>
          <SkipLink targetElementId="results">Skip to Results</SkipLink>
        </>
      )}
      {currentlyOnStoreSearchPage ? null : (
        <SkipLink targetElementId="content">Skip to Main Content</SkipLink>
      )}
    </>
  );
};
