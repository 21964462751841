import { useState } from 'react';

import fallback from './assets/fallbackImage.svg';
import { StyledImage, StyledImageWrapper } from './image.styles';

export interface ImageProps {
  /** Description of the image */
  altText: string;

  /** Optionally add a border, 1px semi-transparent only */
  border?: boolean;

  /** Optionally add a border-radius. For circular, height and width need to be the same. */
  borderRadius?: 'circular' | 'rounded' | 'none' | string;

  /** A fallback image for when the image src is invalid */
  fallbackSrc?: string;

  /** Set a height, accepts any valid `height` value */
  height?: string;

  /** The loading attribute tells the browser how urgently you want it to load an image. Defaults to 'lazy' */
  loading?: 'lazy' | 'eager';

  /** Determines how the image will be resized to adjust to its container */
  objectFit?: 'fill' | 'contain' | 'cover' | 'scale-down' | 'none';

  /** Determines how the image will be positioned within the container, accepts any valid objectPosition value */
  objectPosition?: string;

  /** One or more strings separated by commas, indicating a set of source sizes, ex (max-width: 600px) 480px, 800px */
  sizes?: string;

  /** Image source */
  src: string;

  /** One or more strings separated by commas, indicating possible image sources, ex "image-file-480w.jpg 480w, image-file-800w.jpg 800w" */
  srcSet?: string;

  /** Set a width, accepts any valid `width` value */
  width?: string;
}

/**
 * Image component used to render images throughout the apps
 */

export function Image({
  altText,
  border,
  borderRadius = 'none',
  fallbackSrc = fallback,
  height = 'auto',
  loading = 'lazy',
  objectFit = 'cover',
  objectPosition,
  sizes,
  src,
  srcSet,
  width = '100%',
}: ImageProps) {
  const [imageError, setImageError] = useState(false);
  const [imgSrc, setImgSrc] = useState(src);
  const [imgSrcSet, setImgSrcSet] = useState<string | undefined>(srcSet);

  if (borderRadius === 'circular' && height !== width) {
    throw Error(
      'Height and width need to be equivalent to use a circular border radius.'
    );
  }

  const handleImageError = () => {
    setImageError(true);
    setImgSrc(fallbackSrc);
    setImgSrcSet(undefined);
  };

  return (
    <StyledImageWrapper
      border={border}
      borderRadius={borderRadius}
      height={height}
      width={width}
    >
      <StyledImage
        alt={imageError ? '' : altText}
        border={border}
        borderRadius={borderRadius}
        height={height}
        loading={loading}
        objectFit={objectFit}
        objectPosition={objectPosition}
        onError={handleImageError}
        sizes={sizes}
        src={imgSrc}
        srcSet={imgSrcSet}
        title={imageError ? 'Failed to load this image.' : altText}
        width={width}
      />
    </StyledImageWrapper>
  );
}
