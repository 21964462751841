import type { TableRowProps } from '../table.types';
import { StyledTableRow } from './tableRow.styles';

export function TableRow({
  children,
  onClick,
  selected = false,
  ...props
}: TableRowProps) {
  return (
    <StyledTableRow onClick={onClick} selected={selected} {...props}>
      {children}
    </StyledTableRow>
  );
}
