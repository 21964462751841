import { useQuery } from '@tanstack/react-query';
import type { ZodTypeAny } from 'zod';

import type { AlgoliaFacets } from '@jane/search/types';

import { getAlgoliaIndex } from './client';

interface FacetProps {
  indexPrefix?: string;
  schemaObject?: ZodTypeAny;
  staticFilters?: string;
}

export const facetQueryBody = (params: FacetProps) => ({
  queryFn: () => fetchAlgoliaFacets(params),
  queryKey: ['facets', params],
});

export function useFacets(params: FacetProps) {
  return useQuery(facetQueryBody(params));
}

export async function fetchAlgoliaFacets({
  indexPrefix,
  schemaObject,
  staticFilters,
}: FacetProps) {
  const index = getAlgoliaIndex(indexPrefix ?? 'menu-products-');

  const { facets, facets_stats: facetsStats } = await index.search<{
    facets: AlgoliaFacets;
  }>('', {
    facets: ['*'],
    filters: staticFilters,
  });

  if (schemaObject) {
    return await schemaObject.parseAsync(facets);
  }

  return { facets, facetsStats };
}
