import {
  Button,
  ButtonArrowIcon,
  Popover,
  useMobileMediaQuery,
} from '@jane/reefer';
import type { PopoverContextProps } from '@jane/reefer';

import {
  FilterListButton,
  FilterListItem,
  StyledFilterList,
} from '../filter.styles';
import type { ProductFilterBarProps } from './productFilterBar.types';

type ProductFilterRadiusProps = Pick<
  ProductFilterBarProps,
  'maxRadius' | 'onRadius' | 'radius'
>;

const productSearchRadii = [
  '5',
  '10',
  '15',
  '20',
  '25',
  '30',
  '35',
  '100',
  '200',
];

export const ProductFilterRadius = ({
  maxRadius,
  onRadius,
  radius,
}: ProductFilterRadiusProps) => {
  const isMobile = useMobileMediaQuery({});

  const generateSearchRadiusOptions = () => {
    return maxRadius
      ? productSearchRadii
          .concat([`${maxRadius}`])
          .sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))
      : productSearchRadii;
  };

  const searchRadiusOptions = generateSearchRadiusOptions();

  return (
    <Popover
      alignment={{ vertical: 'bottom', horizontal: 'right' }}
      label={`View Products within ${radius} miles`}
      mb={16}
      ml={16}
      target={
        <Button
          label={`Distance: ${radius} miles`}
          endIcon={<ButtonArrowIcon size="sm" />}
          variant="tertiary"
          full={isMobile && true}
        />
      }
    >
      {({ closePopover }: PopoverContextProps) => (
        <StyledFilterList aria-label="Product search radius options">
          {searchRadiusOptions.map((radius) => (
            <FilterListItem key={radius}>
              <FilterListButton
                onClick={() => {
                  onRadius && onRadius(radius);
                  closePopover();
                }}
              >
                {radius} miles
              </FilterListButton>
            </FilterListItem>
          ))}
        </StyledFilterList>
      )}
    </Popover>
  );
};
