import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';

import type {
  DeliveryConstraints,
  DeliveryZipcode,
  ReservationMode,
  Store,
} from '@jane/shared/models';
import { trackError } from '@jane/shared/util';

import type { WorkingDayFulfillmentSlot } from '../types/workingDayFulfillmentSlot';
import type { JaneDate } from './date';
import { range } from './date';
import type { ScheduleCheckout, ScheduleType } from './storeSchedule';

interface DayIndices {
  [key: string]: number;
  friday: number;
  monday: number;
  saturday: number;
  sunday: number;
  thursday: number;
  tuesday: number;
  wednesday: number;
}

const dayIndices: DayIndices = {
  monday: 0,
  tuesday: 1,
  wednesday: 2,
  thursday: 3,
  friday: 4,
  saturday: 5,
  sunday: 6,
};

const setClosingTime = (
  openingTime: JaneDate,
  closingTime: JaneDate
): JaneDate => {
  if (openingTime.isAfter(closingTime)) {
    return closingTime.clone().add(1, 'day');
  }

  return closingTime;
};

const clampTime = (time: JaneDate, earliest: JaneDate, latest: JaneDate) => {
  if (time.isSameOrBefore(earliest)) return earliest;
  if (time.isSameOrAfter(latest)) return latest;
  return time;
};

const generateSlotName = ({
  displayFulfillmentSlotsPerDay,
  dayName,
  startOfSlot,
  endOfSlot,
}: {
  dayName: string;
  displayFulfillmentSlotsPerDay: boolean;
  endOfSlot: JaneDate;
  startOfSlot: JaneDate;
}) =>
  displayFulfillmentSlotsPerDay
    ? capitalize(dayName)
    : `${capitalize(dayName)}: ${startOfSlot.format(
        'h:mm A'
      )} – ${endOfSlot.format('h:mm A')}`;

const generateSlotValue = ({
  startOfSlot,
  endOfSlot,
}: {
  endOfSlot: JaneDate;
  startOfSlot: JaneDate;
}) => ({
  from: startOfSlot.unix(),
  to: endOfSlot.unix(),
});

export const determineIfAllDayWindow = (
  store: Store,
  reservationMode: ScheduleType
) =>
  Boolean(
    (store.display_delivery_windows_by_day && reservationMode === 'delivery') ||
      (store.display_pickup_windows_by_day && reservationMode === 'pickup') ||
      (store.display_curbside_windows_by_day && reservationMode === 'curbside')
  );

export interface WorkingDay {
  readonly allowFutureDayOrdering: boolean;
  readonly allowOffHoursOrdering: boolean;
  clone(): WorkingDay;
  readonly closingTime: JaneDate | null;
  readonly dayIndex: number;
  readonly displayClosingTime: string | null;
  readonly displayOpeningTime: string | null;
  fulfillmentSlots({
    currentTime,
    checkout,
    store,
    unavailableSlots,
    isStoreCurrentlyOpen,
  }: {
    checkout: ScheduleCheckout;
    currentTime: JaneDate;
    isStoreCurrentlyOpen: boolean;
    store: Store;
    unavailableSlots: string[] | null;
  }): WorkingDayFulfillmentSlot[];
  readonly isClosedAllDay: boolean;
  isCurrentlyAcceptingReservations(
    time: JaneDate,
    reservationMode: ReservationMode,
    isStoreCurrentlyOpen: boolean,
    workingDayIsToday?: boolean
  ): boolean;
  isNowWithinDaysOperatingHours(time: JaneDate): boolean;
  readonly isOpenPartOfTheDay: boolean;
  isTimeDuringDay(time: JaneDate): boolean;
  readonly lastCallInterval: number;
  readonly lastCallTime: JaneDate | undefined;
  readonly maxLeadTimeMinutes?: number;
  readonly minLeadTimeMinutes?: number;
  readonly name: string;
  readonly openingTime: JaneDate | null;
  willOpenLater(time: JaneDate): boolean;
}

class ClosedDay implements WorkingDay {
  readonly isClosedAllDay = true;
  readonly isOpenPartOfTheDay = false;
  readonly displayOpeningTime = null;
  readonly displayClosingTime = null;
  readonly lastCallTime = undefined;
  readonly openingTime = null;
  readonly closingTime = null;
  readonly lastCallInterval: number;
  readonly name: string;
  readonly dayIndex: number;
  readonly allowOffHoursOrdering: boolean;
  readonly allowFutureDayOrdering: boolean;

  constructor({
    allowFutureDayOrdering,
    allowOffHoursOrdering,
    name,
    lastCallInterval,
  }: {
    allowFutureDayOrdering: boolean;
    allowOffHoursOrdering: boolean;
    lastCallInterval: number;
    name: string;
  }) {
    this.name = name;
    this.dayIndex = dayIndices[name];
    this.lastCallInterval = lastCallInterval;
    this.allowFutureDayOrdering = allowFutureDayOrdering;
    this.allowOffHoursOrdering = allowOffHoursOrdering;
  }

  isTimeDuringDay() {
    return false;
  }

  willOpenLater() {
    return false;
  }

  isNowWithinDaysOperatingHours() {
    return false;
  }

  isCurrentlyAcceptingReservations() {
    return this.allowOffHoursOrdering;
  }

  fulfillmentSlots() {
    return [];
  }

  clone() {
    return new ClosedDay({
      name: this.name,
      lastCallInterval: this.lastCallInterval,
      allowFutureDayOrdering: this.allowFutureDayOrdering,
      allowOffHoursOrdering: this.allowOffHoursOrdering,
    });
  }
}

class OpenDay implements WorkingDay {
  readonly openingTime: JaneDate;
  readonly closingTime: JaneDate;
  readonly name: string;
  readonly lastCallInterval: number;
  readonly maxLeadTimeMinutes?: number;
  readonly minLeadTimeMinutes?: number;
  readonly dayIndex: number;
  readonly allowOffHoursOrdering: boolean;
  readonly allowFutureDayOrdering: boolean;
  readonly isOpenPartOfTheDay = true;
  readonly isClosedAllDay = false;

  constructor({
    allowFutureDayOrdering,
    allowOffHoursOrdering,
    name,
    openingTime,
    closingTime,
    lastCallInterval,
    minLeadTimeMinutes,
    maxLeadTimeMinutes,
  }: {
    allowFutureDayOrdering: boolean;
    allowOffHoursOrdering: boolean;
    closingTime: JaneDate;
    lastCallInterval: number;
    maxLeadTimeMinutes?: number;
    minLeadTimeMinutes?: number;
    name: string;
    openingTime: JaneDate;
  }) {
    this.name = name;
    this.openingTime = openingTime;
    this.closingTime = setClosingTime(openingTime, closingTime);
    this.lastCallInterval = lastCallInterval;
    this.dayIndex = dayIndices[name];
    this.allowOffHoursOrdering = allowOffHoursOrdering;
    this.allowFutureDayOrdering = allowFutureDayOrdering;
    this.maxLeadTimeMinutes = maxLeadTimeMinutes;
    this.minLeadTimeMinutes = minLeadTimeMinutes;
  }

  isTimeDuringDay(time: JaneDate): boolean {
    return time.isBetween(this.openingTime, this.closingTime);
  }

  get displayOpeningTime() {
    return this.openingTime.format('h:mm a');
  }

  get displayClosingTime() {
    return this.closingTime.format('h:mm a');
  }

  get lastCallTime() {
    return this.closingTime.clone().subtract(this.lastCallInterval, 'minutes');
  }

  willOpenLater(time: JaneDate) {
    return (
      this.openingTime.isAfter(time) && time.isSame(this.openingTime, 'day')
    );
  }

  isNowWithinDaysOperatingHours(now: JaneDate) {
    return now.isBetween(this.openingTime, this.lastCallTime);
  }

  earliestFulfillmentTime(time: JaneDate) {
    return time.clone().add(this.minLeadTimeMinutes, 'minutes');
  }

  earliestFulfillmentTimePerZipcode(time: JaneDate, leadTime: number) {
    return time.clone().add(leadTime, 'minutes');
  }

  earliestFulfillmentTimeForGeofence(time: JaneDate, leadTime: number) {
    return time.clone().add(leadTime, 'minutes');
  }

  latestFulfillmentTime(time: JaneDate) {
    return time.clone().add(this.maxLeadTimeMinutes, 'minutes');
  }

  betweenLastCallAndClosing(time: JaneDate) {
    return time.isBetween(this.lastCallTime, this.closingTime);
  }

  isCurrentlyAcceptingReservations(
    now: JaneDate,
    reservationMode: ReservationMode | ScheduleType,
    isStoreCurrentlyOpen: boolean,
    workingDayIsToday?: boolean
  ): boolean {
    // assume the store is accepting reservations if kiosk
    if (reservationMode === 'kiosk') return true;

    // determine whether this WorkingDay is operational (i.e. open and not within last call) right now for the reservation mode
    if (this.isNowWithinDaysOperatingHours(now)) return true;

    // if the user is in the last call window, allow ordering if the store offers future day ordering and the workingDay is not today
    if (this.betweenLastCallAndClosing(now))
      return this.allowFutureDayOrdering && !workingDayIsToday;

    // if the store is currently open but no slots were available for this WorkingDay, let any future slots through
    if (isStoreCurrentlyOpen && this.allowFutureDayOrdering) return true;

    // if none of the above conditions is met, allow the WorkingDay slots through only if allowOffHoursOrdering
    return this.allowOffHoursOrdering;
  }

  isCurrentlyAcceptingReservationsPerGeofence(
    time: JaneDate,
    lastCallMinutes: number,
    isStoreCurrentlyOpen: boolean
  ) {
    const lastCallTime = this.closingTime
      .clone()
      .subtract(lastCallMinutes, 'minutes');

    if (time.isBetween(this.openingTime, lastCallTime)) return true;

    if (isStoreCurrentlyOpen && this.allowFutureDayOrdering) return true;

    return this.allowOffHoursOrdering;
  }

  isCurrentlyAcceptingReservationsPerZipcode(
    time: JaneDate,
    lastCallMinutes: number,
    isStoreCurrentlyOpen: boolean
  ) {
    const lastCallTime = this.closingTime
      .clone()
      .subtract(lastCallMinutes, 'minutes');

    if (time.isBetween(this.openingTime, lastCallTime)) return true;

    if (isStoreCurrentlyOpen && this.allowFutureDayOrdering) return true;

    return this.allowOffHoursOrdering;
  }

  isClosedForGeofence({
    currentTime,
    deliveryConstraints,
    earliestFulfillmentTime,
    isStoreCurrentlyOpen,
  }: {
    currentTime: JaneDate;
    deliveryConstraints: DeliveryConstraints;
    earliestFulfillmentTime: JaneDate;
    isStoreCurrentlyOpen: boolean;
  }) {
    return (
      !this.isCurrentlyAcceptingReservationsPerGeofence(
        currentTime,
        deliveryConstraints.last_call_minutes,
        isStoreCurrentlyOpen
      ) ||
      earliestFulfillmentTime.isSameOrAfter(
        this.closingTime
          .clone()
          .subtract(deliveryConstraints.last_call_minutes, 'minutes')
      )
    );
  }

  isClosedForZipcode({
    currentTime,
    deliveryZipcode,
    earliestFulfillmentTime,
    isStoreCurrentlyOpen,
  }: {
    currentTime: JaneDate;
    deliveryZipcode: DeliveryZipcode;
    earliestFulfillmentTime: JaneDate;
    isStoreCurrentlyOpen: boolean;
  }) {
    return (
      !this.isCurrentlyAcceptingReservationsPerZipcode(
        currentTime,
        deliveryZipcode.last_call_minutes,
        isStoreCurrentlyOpen
      ) ||
      earliestFulfillmentTime.isSameOrAfter(
        this.closingTime
          .clone()
          .subtract(deliveryZipcode.last_call_minutes, 'minutes')
      )
    );
  }

  calculateEarliestAndLatestTimes({
    checkout,
    store,
    currentTime,
    isStoreCurrentlyOpen,
  }: {
    checkout: ScheduleCheckout;
    currentTime: JaneDate;
    isStoreCurrentlyOpen: boolean;
    store: Store;
  }) {
    const { reservationMode, deliveryAddress, deliveryConstraints } = checkout;
    const { delivery_config_strategy } = store;

    const zipcode = deliveryAddress?.zipcode;

    const deliveryZipcode: DeliveryZipcode | undefined =
      store.delivery_zipcodes.find(
        (val: DeliveryZipcode) => val.zipcode === zipcode
      );

    let earliestFulfillmentTime = null;
    // currentTime + lead time for fulfillment mode
    if (
      reservationMode === 'delivery' &&
      (delivery_config_strategy.delivery_strategy === 'geofence' ||
        delivery_config_strategy.delivery_strategy === 'zipcode')
    ) {
      if (
        delivery_config_strategy.delivery_strategy === 'geofence' &&
        store.delivery_geofences.length > 0
      ) {
        earliestFulfillmentTime = this.earliestFulfillmentTimeForGeofence(
          currentTime,
          deliveryConstraints?.lead_time_minutes
        );
      } else if (
        delivery_config_strategy.delivery_strategy === 'zipcode' &&
        deliveryZipcode
      ) {
        earliestFulfillmentTime = this.earliestFulfillmentTimePerZipcode(
          currentTime,
          deliveryZipcode.lead_time_minutes
        );
      }
    } else {
      earliestFulfillmentTime = this.earliestFulfillmentTime(currentTime);
    }

    const latestFulfillmentTime = this.latestFulfillmentTime(currentTime);
    const workingDayIsToday =
      this.name.toLowerCase() === currentTime.format('dddd').toLowerCase();

    const isClosedForNonZipcodeOrder =
      !this.isCurrentlyAcceptingReservations(
        currentTime,
        reservationMode,
        isStoreCurrentlyOpen,
        workingDayIsToday
      ) ||
      (earliestFulfillmentTime &&
        earliestFulfillmentTime.isSameOrAfter(this.lastCallTime) &&
        workingDayIsToday);

    let noSlotsAvailable = null;

    if (
      reservationMode === 'delivery' &&
      (delivery_config_strategy.delivery_strategy === 'geofence' ||
        delivery_config_strategy.delivery_strategy === 'zipcode')
    ) {
      if (
        delivery_config_strategy.delivery_strategy === 'geofence' &&
        store.delivery_geofences.length > 0
      ) {
        noSlotsAvailable = this.isClosedForGeofence({
          currentTime,
          deliveryConstraints,
          earliestFulfillmentTime,
          isStoreCurrentlyOpen,
        });
      } else if (
        delivery_config_strategy.delivery_strategy === 'zipcode' &&
        deliveryZipcode
      ) {
        noSlotsAvailable = this.isClosedForZipcode({
          currentTime,
          deliveryZipcode,
          earliestFulfillmentTime,
          isStoreCurrentlyOpen,
        });
      }
    } else {
      noSlotsAvailable =
        isClosedForNonZipcodeOrder ||
        earliestFulfillmentTime.isSameOrAfter(latestFulfillmentTime) ||
        latestFulfillmentTime.isSameOrBefore(this.openingTime);
    }
    return {
      earliestFulfillmentTime,
      latestFulfillmentTime,
      noSlotsAvailable,
    };
  }

  windowAndInterval(reservationMode: ScheduleType, store: Store) {
    let fulfillmentInterval = store.pickup_interval;
    let fulfillmentWindow = store.pickup_window;
    switch (reservationMode) {
      case 'delivery':
        fulfillmentInterval = store.delivery_interval;
        fulfillmentWindow = store.delivery_window;
        break;
      case 'pickup':
        fulfillmentInterval = store.pickup_interval;
        fulfillmentWindow = store.pickup_window;
        break;
      case 'curbside':
        fulfillmentInterval = store.curbside_interval;
        fulfillmentWindow = store.curbside_window;
        break;
      default:
        trackError(
          new Error(
            'Invalid reservationMode provided to workingDay.OpenDay.windowAndInterval'
          ),
          { reservationMode, store }
        );
    }

    return { fulfillmentInterval, fulfillmentWindow };
  }

  fulfillmentSlots({
    currentTime,
    checkout,
    store,
    unavailableSlots,
    isStoreCurrentlyOpen,
  }: {
    checkout: ScheduleCheckout;
    currentTime: JaneDate;
    isStoreCurrentlyOpen: boolean;
    store: Store;
    unavailableSlots: string[] | null;
  }) {
    const { earliestFulfillmentTime, latestFulfillmentTime, noSlotsAvailable } =
      this.calculateEarliestAndLatestTimes({
        checkout,
        store,
        currentTime,
        isStoreCurrentlyOpen,
      });

    if (noSlotsAvailable) return [];

    const { reservationMode } = checkout;
    const { fulfillmentWindow, fulfillmentInterval } = this.windowAndInterval(
      reservationMode,
      store
    );
    const displayFulfillmentSlotsPerDay = determineIfAllDayWindow(
      store,
      reservationMode
    );

    const startOfFirstSlot = this.startOfFirstSlot(
      earliestFulfillmentTime,
      fulfillmentInterval,
      displayFulfillmentSlotsPerDay
    );

    if (!startOfFirstSlot) return [];

    const closingTime = this.closingTime;

    const startOfLastSlot = this.startOfLastSlot(
      closingTime,
      earliestFulfillmentTime,
      latestFulfillmentTime,
      fulfillmentWindow
    );

    const slots = Array.from(
      range(startOfFirstSlot, startOfLastSlot).by(
        displayFulfillmentSlotsPerDay ? 'days' : 'minutes'
      )
    )
      .filter((_, index) => index % fulfillmentInterval === 0)
      .reduce((hours: any[], leftTimeBound: JaneDate) => {
        const rightTimeBound = leftTimeBound
          .clone()
          .add(fulfillmentWindow || 60, 'minutes');

        const endOfSlot = rightTimeBound.isAfter(closingTime)
          ? closingTime
          : rightTimeBound;

        const startOfSlot = displayFulfillmentSlotsPerDay
          ? this.openingTime
          : leftTimeBound;

        const currentSlotIsFullyBooked = unavailableSlots?.includes(
          startOfSlot.clone().format('HH:mm')
        );

        if (
          !leftTimeBound.isAfter(closingTime) &&
          !leftTimeBound.isSameOrAfter(endOfSlot) &&
          (isEmpty(hours) || last(hours).value.to !== endOfSlot.unix()) // ensures there aren't multiple slots with the same end time, e.g. 10:00 - 11:00, 10:15 - 11:00, etc.
        ) {
          const dayName =
            this.isTimeDuringDay(currentTime) || this.willOpenLater(currentTime)
              ? 'today'
              : this.name;

          hours.push({
            name: generateSlotName({
              displayFulfillmentSlotsPerDay,
              dayName,
              startOfSlot: leftTimeBound,
              endOfSlot,
            }),
            value: generateSlotValue({
              startOfSlot,
              endOfSlot: displayFulfillmentSlotsPerDay
                ? closingTime
                : endOfSlot,
            }),
            currentSlotIsFullyBooked,
          });
        }
        return hours;
      }, []);
    return slots
      .filter((slot) => !slot.currentSlotIsFullyBooked)
      .map((slot) => {
        delete slot.currentSlotIsFullyBooked;
        return slot;
      });
  }

  storeHoursGrid(fulfillmentInterval: number): JaneDate[] {
    return Array.from(range(this.openingTime, this.closingTime).by('minutes'))
      .filter((_, index) => index % fulfillmentInterval === 0)
      .reduce<JaneDate[]>((hours: JaneDate[], leftTimeBound: JaneDate) => {
        hours.push(leftTimeBound);
        return hours;
      }, []);
  }

  startOfFirstSlot(
    earliestFulfillmentTime: JaneDate,
    fulfillmentInterval: number,
    displayFulfillmentSlotsPerDay: boolean
  ) {
    // slot doesn't need to start on the fullfillment interval if using all-day windows.
    // store opening time will be used for slot start regardless of other config
    if (displayFulfillmentSlotsPerDay) {
      return earliestFulfillmentTime;
    }

    return this.storeHoursGrid(fulfillmentInterval).find((slot: JaneDate) =>
      slot.isSameOrAfter(earliestFulfillmentTime)
    );
  }

  startOfLastSlot(
    closingTime: JaneDate,
    earliestFulfillmentTime: JaneDate,
    latestFulfillmentTime: JaneDate,
    fulfillmentWindow: number
  ) {
    // Store schedules start at 00, 15, 30, or 45 past the hour
    // Store schedules close at 00, 15, 30, or 45 past the hour
    // Store intervals can be 15, 30, or 60.
    //
    // Unless we calculate the exact value using intervals and
    // openingTime, we must assume that the last window could start
    // 15 minutes before closing time.
    const lastSlotOfDay = closingTime.clone().subtract(15, 'minutes');

    const end = clampTime(
      latestFulfillmentTime,
      earliestFulfillmentTime,
      closingTime
    );

    // allows last call ordering (time between last slot and last call)
    return end.isSame(closingTime)
      ? lastSlotOfDay
      : end.subtract(fulfillmentWindow, 'minutes');
  }

  clone() {
    return new OpenDay({
      allowFutureDayOrdering: this.allowFutureDayOrdering,
      allowOffHoursOrdering: this.allowOffHoursOrdering,
      name: this.name,
      openingTime: this.openingTime,
      closingTime: this.closingTime,
      lastCallInterval: this.lastCallInterval,
      maxLeadTimeMinutes: this.maxLeadTimeMinutes,
      minLeadTimeMinutes: this.minLeadTimeMinutes,
    });
  }
}

export const buildWorkingDay = (args: {
  allowFutureDayOrdering: boolean;
  allowOffHoursOrdering: boolean;
  closingTime: JaneDate | null;
  lastCallInterval: number;
  maxLeadTimeMinutes?: number;
  minLeadTimeMinutes?: number;
  name: string;
  openingTime: JaneDate | null;
}) =>
  args.openingTime && args.closingTime
    ? new OpenDay({
        ...args,
        openingTime: args.openingTime,
        closingTime: args.closingTime,
      })
    : new ClosedDay(args);
