import styled from '@emotion/styled';

import { mediaQueries, spacing } from '../../../styling';

/**
 * Popover.Divider is a border used to divide popover content when necessary.
 */

export const PopoverDivider = styled.hr(({ theme }) => ({
  border: 'none',
  height: 1,
  backgroundColor: theme.colors.grays.light,
  ...spacing({ my: 24, mx: -24 }),
  [mediaQueries.tablet('min')]: {
    ...spacing({ my: 16, mx: -16 }),
  },
}));
