import * as t from 'io-ts';

export const tProductBrandCustomImage = t.interface({
  position: t.number,
  description: t.union([t.string, t.null]),
  image_url: t.string,
  cta_text: t.union([t.string, t.null]),
  cta_url: t.union([t.string, t.null]),
});

export const tProductBrandCustomVideo = t.interface({
  overlay_image_url: t.string,
  overlay_text: t.union([t.string, t.null]),
  position: t.union([t.number, t.null]),
  video_url: t.string,
});

export const tBrand = t.interface({
  id: t.union([t.number, t.string]),
  name: t.string,
  color_hex: t.union([t.string, t.null]),
  custom_image_url: t.union([t.string, t.null]),
  description: t.union([t.string, t.null]),
  logo_url: t.union([t.string, t.null]),
  custom_images: t.readonlyArray(tProductBrandCustomImage),
  custom_video: t.union([t.null, tProductBrandCustomVideo]),
});

export type Brand = t.TypeOf<typeof tBrand>;
export type ProductBrandCustomImage = t.TypeOf<typeof tProductBrandCustomImage>;
export type ProductBrandCustomVideo = t.TypeOf<typeof tProductBrandCustomVideo>;
