import { Modal as BaseModal } from './modal';
import { ReactModalAdapter } from './modal.styles';
import type {
  ModalContentDividerProps,
  ModalContentProps,
  ModalFooterProps,
  ModalHeaderProps,
  ModalProps,
} from './modal.types';
import { ModalContent } from './modalContent/modalContent';
import { ModalContentDivider } from './modalContent/modalContentDivider';
import { ModalFooter } from './modalFooter/modalFooter';
import { ModalHeader } from './modalHeader/modalHeader';

export const Modal = Object.assign(BaseModal, {
  Header: ModalHeader,
  Content: ModalContent,
  ContentDivider: ModalContentDivider,
  Footer: ModalFooter,
});

export {
  ModalProps,
  ModalContentProps,
  ModalContentDividerProps,
  ModalHeaderProps,
  ModalFooterProps,
  ReactModalAdapter,
};
