import { useState } from 'react';

import { Button, Modal, SearchField, useMobileMediaQuery } from '@jane/reefer';

import type { FilterSelectItem } from '../filter.types';
import { DeselectAllButton } from '../filterModal/deselectAllButton';
import { FilterModalFooter } from '../filterModal/filterModalFooter';
import { TypeaheadList } from '../filterModal/typeaheadList';
import type { FilterBarPopoverProps } from './filterBarPopover.types';
import { FilterList } from './filterList';
import { FilterPopover } from './filterPopover';

export const FilterBarTypeahead = ({
  filterGroup,
  onChange,
  onDeselect,
  totalResults,
  variant,
}: FilterBarPopoverProps) => {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState('');

  const { label, items, key, nested } = filterGroup;
  const typeaheadItems = items as FilterSelectItem[];
  const isMobile = useMobileMediaQuery({});

  const changeHandler = (value: string) => {
    setQuery(value);
  };

  const typeaheadValues = {
    label,
    onChange: changeHandler,
    query,
  };

  const filteredTypeaheadItems = typeaheadItems.filter(
    (item: FilterSelectItem) =>
      item.label.toLowerCase().includes(query.toLowerCase())
  );

  const renderedFilterList = filteredTypeaheadItems.map((item) => {
    return (
      <FilterList
        key={item.label}
        filterKey={filterGroup.key}
        filters={item as FilterSelectItem}
        onChange={onChange}
        query={query}
      />
    );
  });

  if (isMobile) {
    return (
      <>
        <div>
          <Button
            label={label}
            variant="tertiary"
            onClick={() => setOpen(true)}
          />
        </div>
        <Modal
          open={open}
          background="grays-white"
          onRequestClose={() => setOpen(false)}
          variant="full-screen"
          contentLabel="filter bar typeahead"
        >
          <Modal.Header
            title={label}
            textAlign="center"
            actions={<DeselectAllButton onClick={() => onDeselect(key)} />}
            children={
              <SearchField
                autoFocus
                label={`Search ${label}`}
                name={`${label} search`}
                defaultValue={query}
                onChange={(value) => setQuery(value)}
                disableMobileInputStyling
                mt={32}
                width="100%"
              />
            }
          />
          <Modal.Content padding={false}>
            <TypeaheadList
              filterKey={filterGroup.key}
              items={items as FilterSelectItem[]}
              onChange={onChange}
              query={query}
            />
          </Modal.Content>
          <FilterModalFooter
            hideClear={true}
            onClose={() => setOpen(false)}
            onDeselect={onDeselect}
            totalResults={totalResults}
            variant={variant}
          />
        </Modal>
      </>
    );
  }

  return (
    <FilterPopover
      buttonText={label}
      targetLabel={`Filter products by ${label}`}
      listLabel={`Select ${label}`}
      onDeselect={() => onDeselect(key)}
      disabled={items.length === 0}
      totalResults={totalResults}
      nested={nested}
      typeaheadOptions={typeaheadValues}
      variant={variant}
    >
      {renderedFilterList}
    </FilterPopover>
  );
};
