import { Link, Typography } from '@jane/reefer';

import { FilterListButton, FilterListItem } from '../filter.styles';

export const DeselectAllListItem = ({
  closePopover,
  onClick,
}: {
  closePopover: () => void;
  onClick: () => void;
}) => {
  const handleDeselectAll = () => {
    onClick();
    closePopover();
  };
  return (
    <FilterListItem>
      <FilterListButton onClick={handleDeselectAll}>
        <Link>
          <Typography>Deselect all</Typography>
        </Link>
      </FilterListButton>
    </FilterListItem>
  );
};
