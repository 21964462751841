import { Lottie } from '../internal/lottie/lottie';
import wavyBlack from './assets/loading-wavy-black.json';
import wavyPurple from './assets/loading-wavy-purple.json';
import wavyWhite from './assets/loading-wavy-white.json';
import { StyledLoadingWrap } from './loading.styles';

export type LoadingColor = 'white' | 'purple' | 'black';

export interface LoadingProps {
  /** Accepts one of the three to determine loading color, defaults to purple */
  color?: LoadingColor;

  /** Size determines the height and width of the loading animation, defaults to sm */
  size?: 'sm' | 'lg';
}

export const Loading = ({ color = 'black', size = 'sm' }: LoadingProps) => {
  const animations = {
    white: wavyWhite,
    purple: wavyPurple,
    black: wavyBlack,
  };

  const sizes = {
    sm: {
      height: 24,
      width: 48,
    },
    lg: {
      height: 36,
      width: 72,
    },
  };

  return (
    <StyledLoadingWrap>
      <Lottie
        animationData={animations[color]}
        height={sizes[size].height}
        width={sizes[size].width}
        title="loading animation"
      />
    </StyledLoadingWrap>
  );
};
