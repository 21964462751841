import styled from '@emotion/styled';

export const getSpacing = (val: number | string) => {
  const asString = typeof val === 'number' ? `${val}px` : val;
  return asString;
};

interface StyledGridContainerProps {
  center: boolean;
  direction: string;
  spacing: string | number;
}

const createGridContainer = ({
  spacing,
  center,
}: Omit<StyledGridContainerProps, 'direction'>) => {
  const itemSpacing = getSpacing(spacing);

  const style = {
    width: `calc(100% + ${itemSpacing})`,
    paddingRight: 0,
    marginLeft: `-${itemSpacing}`,
    marginBottom: `-${itemSpacing}`,
    justifyContent: center ? 'center' : 'normal',
  };

  return style;
};

export const StyledGridContainer = styled.div<StyledGridContainerProps>(
  {
    display: 'flex',
    flexWrap: 'wrap',
    height: '100%',
    margin: 0,
  },
  ({ spacing, center, direction }) => ({
    ...createGridContainer({ spacing, center }),
    flexDirection: direction === 'row' ? 'row' : 'column',
  })
);
