import * as t from 'io-ts';

// TODO: Commented out some stuff that's missing - seems it forgot to be included
export const tAeropayIntegrationV2 = t.interface({
  autocapture_on_checkout: t.union([t.boolean, t.undefined]),
  // custom_button_image_url: t.union([t.string, t.null, t.undefined]),
  // custom_payment_method_label: t.union([t.string, t.null, t.undefined]),
  enabled: t.union([t.boolean, t.undefined]),
  id: t.union([t.number, t.undefined]),
  // location_id: t.union([t.string, t.null]),
  location_uuid: t.union([t.string, t.null, t.undefined]),
  merchant_id: t.string,
});
export type AeropayIntegrationV2 = t.TypeOf<typeof tAeropayIntegrationV2>;
