import { config } from '@jane/shared/config';

import { request } from '../lib/request';

export class LocationSource {
  static urlRoot = `${config.apiPath}/coordinates`;

  static get() {
    return request(this.urlRoot, {
      method: 'GET',
    });
  }
}
