import { trackImpressedInlineAd } from '@jane/shared-ecomm/tracking';
import type {
  AppMode,
  Id,
  MenuProduct,
  PendingCartProduct,
} from '@jane/shared/models';

interface TrackCartTopperImpressionProps {
  appMode: AppMode;
  cartProducts: PendingCartProduct[];
  cartTopper: MenuProduct;
  janeDeviceId: string;
  mlScoringSignals: Record<string, unknown>;
  storeId: Id;
  trackDmError: (error: Error | string) => void;
  userId: number;
}
export const trackCartTopperImpression = ({
  appMode,
  cartTopper,
  cartProducts,
  janeDeviceId,
  mlScoringSignals,
  storeId,
  trackDmError,
  userId,
}: TrackCartTopperImpressionProps) => {
  if (!cartTopper.flight) return;
  const placements = {
    cartToppers: [
      {
        flight: cartTopper.flight,
        productBrandId: cartTopper.product_brand_id,
        productBrandName: cartTopper.brand,
        productId: cartTopper.product_id,
        dmMeta: cartTopper.dmMeta,
      },
    ],
  };
  const props = {
    appMode,
    cartProducts,
    janeDeviceId,
    matchedRows: [],
    mlScoringSignals,
    placements,
    qualifiedRows: [],
    storeId,
    userId,
    zone: 'cart' as const,
  };
  trackImpressedInlineAd(props, trackDmError);
};
