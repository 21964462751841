import { useEffect } from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';

export const useScrollToApp = () => {
  const navigationType = useNavigationType();
  const location = useLocation();

  useEffect(() => {
    const timeoutId = setImmediate(() => {
      if (navigationType === 'PUSH') {
        document.getElementById('app').scrollIntoView();
      }
    });
    return () => clearImmediate(timeoutId);
  }, [location.pathname]);
};
