import { Box, Flex, styled } from '@jane/reefer';
import type { WeightSelectorWeight } from '@jane/shared-ecomm/types';
import type { MenuProduct, PriceId } from '@jane/shared/models';

import { useMediaQuery } from '../../../../common/providers/mediaQueryProvider';
import { SvgIcon } from '../../../../component-library/svg-icon';
import { VariantSelectionButton } from './variantSelectionButton';

export const priceForWeight = (
  weight: PriceId,
  weights: WeightSelectorWeight[]
): number | string => {
  const foundWeightItem = weights.find(
    (weightItem: WeightSelectorWeight) => weightItem.value === weight
  );
  return foundWeightItem && foundWeightItem.price ? foundWeightItem.price : '-';
};

export const CheckIconWrapper = styled.span(
  {
    position: 'absolute',
    top: -4,
    right: -3,
    zIndex: 10,
    svg: {
      background: 'white',
      opacity: 1,
      border: '1px solid inherit',
      borderRadius: '50%',
    },
  },
  ({ theme }) => [
    { color: theme ? theme.colors.primary.main : 'rgba(255, 255, 255, 1)' },
  ]
);

interface WeightItemsProps {
  cartWeights?: PriceId[];
  editingMode: boolean;
  menuProduct: MenuProduct;
  onChange: (priceId: PriceId) => void;
  selectedWeight: PriceId | null;
  weights: WeightSelectorWeight[];
}

const WeightItems = ({
  weights,
  selectedWeight,
  onChange,
  menuProduct,
  cartWeights,
}: WeightItemsProps) => {
  const { smallerThanVerticalTablet } = useMediaQuery();
  const menuProductWeightValues = weights.map((weight) => weight.value);
  const fullWidth =
    menuProductWeightValues.length === 1 && smallerThanVerticalTablet;

  // Use a 2 column grid for multiple weights on a small screen, else flex
  const narrowCss = {
    gap: 8,
    display:
      menuProductWeightValues.length === 1 && smallerThanVerticalTablet
        ? 'flex'
        : 'grid',
    gridTemplateColumns: '1fr 1fr',
    width: '100%',
    justifyItems: 'stretch',
  };
  const wideCss = { gap: 8 };

  return (
    <Flex
      flexWrap={'wrap'}
      justifyContent="flex-end"
      css={smallerThanVerticalTablet ? narrowCss : wideCss}
      width={fullWidth ? '100%' : undefined}
    >
      {menuProductWeightValues.map((weight) => {
        return (
          <Box position="relative" key={weight}>
            <VariantSelectionButton
              key={`${menuProduct.id}-${weight}`}
              onChange={() => onChange(weight)}
              selected={weight === selectedWeight}
              weight={weight}
            />
            {cartWeights.includes(weight) && (
              <CheckIconWrapper>
                <SvgIcon size={18} icon="circleCheckFilled" />
              </CheckIconWrapper>
            )}
          </Box>
        );
      })}
    </Flex>
  );
};

export default WeightItems;
