import type { ReactNode, RefObject } from 'react';
import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Typography, mediaQueries, styled } from '@jane/reefer';
import type { Hit, SearchResults, SearchState } from '@jane/search/types';
import { LoadingWrapper } from '@jane/shared-ecomm/components';
import { EventNames, track } from '@jane/shared-ecomm/tracking';
import {
  getAppliedWeightFilter,
  postMessageToIframeParent,
} from '@jane/shared-ecomm/util';
import type { MenuProduct, Store, StoreSpecial } from '@jane/shared/models';

import type { MediaQueryState } from '../../common/providers/mediaQueryProvider';
import {
  isEmbeddedModeSelector,
  useCustomerSelector,
} from '../../customer/selectors';
import BucketFooter from './bucketFooter';
import BucketHeader from './bucketHeader';
import HitProductCard from './hitProductCard';
import { generateSearchState } from './oldSearch/bucketSearchContextHelper';
import {
  DEFAULT_DESKTOP_CARD_WIDTH,
  DEFAULT_MOBILE_CARD_WIDTH,
} from './productCardHelper';

export const MIN_NUMBER_HITS_TO_RENDER = 3;
export const NUMBER_OF_HITS_TO_RENDER = 48;
export const MENU_PRODUCT_GRID_GAP = '24px 24px';

export const Count: React.FC<{ children?: ReactNode; underline?: boolean }> = ({
  children,
  underline,
}) => (
  <Typography
    color="grays-mid"
    css={underline && { textDecoration: 'underline' }}
    variant="body"
  >
    {children}
  </Typography>
);

export const MenuProductsGrid = styled.div<{
  listView: boolean;
}>(
  ({ listView }) =>
    !listView && {
      display: 'grid',
      gridGap: MENU_PRODUCT_GRID_GAP,
      justifyContent: 'flex-start',
      gridAutoRows: '1fr',
      [mediaQueries.tablet('max')]: {
        gridTemplateColumns: `repeat(auto-fill, minmax(${DEFAULT_MOBILE_CARD_WIDTH}px, 1fr))`,
      },
      [mediaQueries.mobile('md', 'max')]: {
        overflowX: 'auto',
        gridTemplateColumns: `repeat(2, minmax(${DEFAULT_MOBILE_CARD_WIDTH}px, 1fr))`,
      },
      [mediaQueries.tablet('min')]: {
        gridTemplateColumns: `repeat(auto-fill, minmax(${DEFAULT_DESKTOP_CARD_WIDTH}px, 1fr))`,
        height: '100%',
      },
    }
);

interface Props {
  filterExistsOnAlgoliaRootTypes: boolean;
  hasMore: boolean;
  hits?: Hit<MenuProduct>[];
  isKiosk: boolean;
  kindValue?: string;
  lastBucket: boolean;
  listView: boolean;
  media: MediaQueryState;
  name: string;
  outerSearchState: SearchState;
  paginationCount: number;
  placementIndex: number;
  refine: () => void;
  searchResults: Pick<SearchResults, 'nbHits' | 'index'>;
  setPaginationCount: (arg: number) => void;
  specials?: StoreSpecial[];
  store: Store;
  storeHeaderHeight: number;
}

const ProductKindBucketNonCarousel = ({
  hasMore,
  hits,
  listView,
  name,
  kindValue,
  outerSearchState,
  refine,
  searchResults,
  specials,
  store,
  media,
  isKiosk,
  filterExistsOnAlgoliaRootTypes,
  paginationCount,
  placementIndex,
  setPaginationCount,
  storeHeaderHeight,
}: Props) => {
  const [expanded, setExpanded] = useState(false);

  const topRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  const isEmbeddedMode = useCustomerSelector(isEmbeddedModeSelector);

  useLayoutEffect(() => {
    if (paginationCount === 1) {
      window.scrollTo(0, storeHeaderHeight - 100);
    }
  }, [paginationCount, storeHeaderHeight]);

  useEffect(() => {
    window.scrollTo(0, storeHeaderHeight);
  }, [filterExistsOnAlgoliaRootTypes, storeHeaderHeight]);

  const count = searchResults?.nbHits;
  const indexName = searchResults?.index;
  const searchState = generateSearchState(kindValue || name, outerSearchState);

  const alphabeticalSort =
    !outerSearchState?.sortBy?.includes('price') &&
    !outerSearchState?.sortBy?.includes('rating') &&
    !outerSearchState?.sortBy?.includes('potency');

  const hitsToRender = useMemo(() => {
    // default sort for best-selling is by overall sales and not alphabetical
    // this is temporary pending default state for all products
    if (alphabeticalSort) {
      if (kindValue === 'best_selling') {
        const sortedByBestSelling = [...hits].sort(
          (a, b) => (a.best_seller_rank || 0) - (b.best_seller_rank || 0)
        );
        return expanded
          ? sortedByBestSelling
          : sortedByBestSelling.slice(
              0,
              filterExistsOnAlgoliaRootTypes ? 48 : 16
            );
      }
    }

    return expanded
      ? hits || []
      : (hits || []).slice(0, filterExistsOnAlgoliaRootTypes ? 48 : 16);
  }, [
    hits,
    kindValue,
    expanded,
    filterExistsOnAlgoliaRootTypes,
    alphabeticalSort,
  ]);

  const onLoadMore = (isActualClickEvent?: boolean) => {
    setExpanded(true);
    refine();

    if (isActualClickEvent) {
      setPaginationCount(paginationCount + 1);
      track({ event: EventNames.ClickedShowMore, category: name });
    }
  };

  const onCollapse = () => {
    postMessageToIframeParent({
      messageType: 'shrinkNextResize',
    });
    setPaginationCount(1);
    scrollToTopOfBucket();
    setExpanded(false);
  };

  const scrollToTopOfBucket = () => {
    if (topRef && topRef.current) {
      if (isEmbeddedMode) {
        postMessageToIframeParent({
          messageType: 'scrollToBucketTop',
          payload: topRef.current.offsetTop - 100,
        });

        return;
      }
      window.scrollTo(0, topRef.current.offsetTop - 100);
    }
  };

  if (!hits) return null;
  if (count < 1) return null;

  return (
    <div ref={topRef}>
      <LoadingWrapper
        variant={listView ? 'carousel-list' : 'carousel-grid'}
        isLoading={!searchResults}
      >
        <BucketHeader
          media={media}
          count={count}
          isKiosk={isKiosk}
          store={store}
          searchState={searchState}
          expanded={expanded}
          onCollapse={onCollapse}
          name={name}
          showSeeAll={listView && !filterExistsOnAlgoliaRootTypes}
          placementIndex={placementIndex}
        />

        <MenuProductsGrid listView={listView}>
          {hitsToRender.map((hit, index) => {
            return (
              <HitProductCard
                appliedWeightFilter={getAppliedWeightFilter(outerSearchState)}
                specials={specials}
                key={hit?.flight ? `inline-ad-${hit.id}` : hit.objectID}
                hit={hit}
                listView={listView}
                searchState={outerSearchState || {}}
                store={store}
                index={index}
                algoliaIndexName={indexName}
              />
            );
          })}
        </MenuProductsGrid>

        <BucketFooter
          expanded={expanded}
          hasMore={hasMore}
          loadedCount={hitsToRender.length}
          onLoadMore={onLoadMore}
          onScrollToTop={scrollToTopOfBucket}
          totalCount={count}
          numberHitsRendered={hitsToRender.length}
          paginationCount={paginationCount}
          searchState={searchState}
          nonCarousel
          isKiosk={isKiosk}
          store={store}
          filterExistsOnAlgoliaRootTypes={filterExistsOnAlgoliaRootTypes}
          name={name}
          placementIndex={placementIndex}
        />
      </LoadingWrapper>
    </div>
  );
};

export default React.memo(ProductKindBucketNonCarousel);
