import type { HTMLAttributes } from 'react';

import { Typography } from '@jane/reefer';

import { useCustomerSelector } from '../../customer';
import { isEmbeddedModeSelector } from '../../customer/selectors';

export const Header: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  color: _,
  ...props
}) => {
  const isEmbeddedMode = useCustomerSelector(isEmbeddedModeSelector);

  return (
    <Typography
      as="div"
      mb={12}
      mx={12}
      branded={!isEmbeddedMode}
      textAlign="center"
      variant="header-bold"
      {...props}
    >
      {children}
    </Typography>
  );
};
