import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Lock = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7V8C17.6569 8 19 9.34315 19 11V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V11C5 9.34315 6.34315 8 8 8V7ZM14 7V8H10V7C10 5.89543 10.8954 5 12 5C13.1046 5 14 5.89543 14 7ZM7 11C7 10.4477 7.44772 10 8 10H10H14H16C16.5523 10 17 10.4477 17 11V18C17 18.5523 16.5523 19 16 19H8C7.44772 19 7 18.5523 7 18V11ZM13 13C13 12.4477 12.5523 12 12 12C11.4477 12 11 12.4477 11 13L11 16C11 16.5523 11.4477 17 12 17C12.5523 17 13 16.5523 13 16L13 13Z"
      fill="#0E0E0E"
    />
  </svg>
);
export const LockIcon = ({ ...props }: DynamicIconProps) => {
  return <Icon icon={Lock} {...props} color={props.color} />;
};
