import { useTheme } from '@emotion/react';
import { forwardRef } from 'react';
import { Link } from 'react-router-dom';

import { getNodeText, logger } from '../../utils';
import { getComponentStyles } from '../../utils/componentStyles';
import { NewTabLabel } from '../link/link.styles';
import { Loading } from '../loading/loading';
import { Typography } from '../typography/typography';
import {
  IconLabelWrapper,
  LabelWrapper,
  LoadingLabel,
  StyledButton,
  StyledFakeButton,
  StyledLink,
  StyledRouterLink,
} from './button.styles';
import type { ButtonProps } from './button.types';
import { iconWithProps, loadingColor } from './button.utils';

/**
 * `Button` components are used to trigger actions throughout the interface.
 * `Button` components can be used to initiate `onClick` actions, for routing, or to link externally.
 */

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      alignment = 'center',
      ariaLabel,
      branded = false,
      disabled = false,
      endIcon,
      full,
      size = 'default',
      href,
      label,
      loading = false,
      onClick,
      startIcon,
      subLabel,
      testId,
      to,
      type = 'button',
      variant = 'primary',
      ...props
    }: ButtonProps,
    ref
  ) => {
    const fakeButton = !onClick && type === 'button';
    const isSmall = size === 'small';

    if (isSmall && subLabel) {
      logger.warn(
        'Button',
        'Inline button variants should not include a subLabel.'
      );
    }

    const theme = useTheme();
    const buttonStyles = getComponentStyles('Button', theme, variant);
    const sizeSpecificStyles = theme.components.Button.sizes[size];

    const stringLabel = getNodeText(label);

    const sharedProps = {
      'aria-label': ariaLabel,
      'data-testid': testId,
      disabled,
      full,
      name: stringLabel,
      size,
      variant,
      ...props,
    };

    if (ref) {
      if (to || href) {
        throw Error(
          '[Reefer/Button] refs are not recommended and cannot be used in conjunction with "to" or "href"'
        );
      }
      if (
        testId === undefined ||
        ![
          'stronghold-confirm-button',
          'aeropay-white-label-checkout-button',
        ].includes(testId)
      ) {
        logger.warn(
          'Button',
          'refs are deprecated and will eventually be removed'
        );
      }
    }

    const accessibleStartIcon = iconWithProps(startIcon, stringLabel);
    const accessibleEndIcon = iconWithProps(endIcon, stringLabel);

    const renderLabel = (branded?: boolean, subLabel?: string) => (
      <LoadingLabel isLoading={loading} subLabel={subLabel}>
        <IconLabelWrapper alignment={alignment}>
          {accessibleStartIcon}
          <LabelWrapper
            startIcon={accessibleStartIcon}
            endIcon={accessibleEndIcon}
          >
            <Typography
              variant={
                isSmall
                  ? 'body'
                  : sizeSpecificStyles.typographyVariant ??
                    buttonStyles.typographyVariant
              }
              branded={branded}
              textAlign={alignment}
              as="div"
            >
              {label}
            </Typography>
            {subLabel && (
              <Typography
                variant="mini"
                branded={branded}
                textAlign={alignment}
                as="div"
                mt={-4}
              >
                {subLabel}
              </Typography>
            )}
          </LabelWrapper>
          {accessibleEndIcon}
        </IconLabelWrapper>
      </LoadingLabel>
    );

    if (to) {
      const { full, ...linkSharedProps } = sharedProps;
      return (
        <StyledRouterLink
          disabled={disabled}
          full={full}
          isLoading={loading}
          subLabel={subLabel}
          to={to}
          variant={variant}
          size={size}
          {...props}
        >
          <Link
            className="router-link"
            onClick={onClick}
            to={to}
            {...linkSharedProps}
          >
            {loading && <Loading color={loadingColor(variant)} />}
            {renderLabel(branded, subLabel)}
          </Link>
        </StyledRouterLink>
      );
    }

    if (href) {
      return (
        <StyledLink
          href={href}
          target="_blank"
          onClick={onClick}
          rel="noopener noreferrer"
          isLoading={loading}
          subLabel={subLabel}
          {...sharedProps}
        >
          {loading && <Loading color={loadingColor(variant)} />}
          <NewTabLabel>Opens in new window</NewTabLabel>
          {renderLabel(branded, subLabel)}
        </StyledLink>
      );
    }

    if (!href && !to && fakeButton) {
      return (
        <StyledFakeButton
          isLoading={loading}
          subLabel={subLabel}
          {...sharedProps}
        >
          {loading && <Loading color={loadingColor(variant)} />}
          {renderLabel(branded, subLabel)}
        </StyledFakeButton>
      );
    }

    return (
      <StyledButton
        onClick={onClick}
        type={type}
        ref={ref}
        isLoading={loading}
        subLabel={subLabel}
        {...sharedProps}
      >
        {loading && <Loading color={loadingColor(variant)} />}
        {renderLabel(branded, subLabel)}
      </StyledButton>
    );
  }
);

export { Button };
