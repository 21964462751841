import { config } from '@jane/shared/config';

import { api } from './api';

const apiPath = config.apiPathV2;

export const janeApiV2 = {
  get: <T>(url: string, params?: object): Promise<T> =>
    api.get(`${apiPath}${url}`, params),
  post: <T>(url: string, data: unknown, params?: object): Promise<T> =>
    api.post(`${apiPath}${url}`, data, params),
  patch: <T>(url: string, data: unknown, params?: object): Promise<T> =>
    api.patch(`${apiPath}${url}`, data, params),
  put: <T>(url: string, data: unknown, params?: object): Promise<T> =>
    api.put(`${apiPath}${url}`, data, params),
  delete: <T>(url: string, params?: object): Promise<T | null> =>
    api.delete(`${apiPath}${url}`, params),
};
