import { styled } from '@jane/reefer';

interface Props {
  complete: number;
  total: number;
}

const OuterBar = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.grays.light,
  height: 4,
  position: 'relative',
  width: '100%',
}));

const InnerBar = styled.div<{ width: number }>(({ width, theme }) => ({
  width: `${width}%`,
  backgroundColor: theme.colors.system.positive.main,
  height: '100%',
  position: 'relative',
}));

const ProgressBar = ({ complete, total }: Props) => (
  <OuterBar>
    <InnerBar width={Math.round((complete / total) * 100)} />
  </OuterBar>
);

export default ProgressBar;
