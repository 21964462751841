import groupBy from 'lodash/groupBy';

import { List, Modal, useMobileMediaQuery } from '@jane/reefer';

import type { FilterSelectItem } from '../filter.types';
import { FilterCheckbox } from '../filterCheckbox';
import { ItemDivider } from './filterModal.styles';
import { CONTENT_DIVIDER_PROPS } from './modalSection';

const findFirstChar = ({ label }: { label: string }) => {
  const firstCharacter = label[0].toLowerCase();

  return isNaN(parseInt(firstCharacter)) ? firstCharacter : '0';
};

interface TypeaheadListProps {
  filterKey: string;
  items: FilterSelectItem[];
  onChange: (key: string, label: string) => void;
  query: string;
}

const ListGroup = ({
  filterKey,
  items,
  onChange,
  query,
}: TypeaheadListProps) => {
  const isMobile = useMobileMediaQuery({});

  return (
    <>
      {items.map((item) => (
        <ItemDivider key={item.label}>
          <FilterCheckbox
            filterKey={filterKey}
            item={item}
            onChange={onChange}
            query={query}
          />
        </ItemDivider>
      ))}
      {!isMobile && <Modal.ContentDivider {...CONTENT_DIVIDER_PROPS} />}
    </>
  );
};

export const TypeaheadList = ({
  filterKey,
  items,
  onChange,
  query,
}: TypeaheadListProps) => {
  const listItems = items.filter((item: FilterSelectItem) =>
    item.label.toLowerCase().includes(query.toLowerCase())
  );

  const itemGroups = groupBy<FilterSelectItem>(listItems, findFirstChar);

  return (
    <>
      {Object.entries(itemGroups).map(([groupName, listItems]) => (
        <List label={groupName} key={groupName}>
          <ListGroup
            filterKey={filterKey}
            key={groupName}
            items={listItems}
            onChange={onChange}
            query={query}
          />
        </List>
      ))}
    </>
  );
};
