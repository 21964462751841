import { mediaQueries, shadows, spacing, styled } from '@jane/reefer';

export const NestedRangeWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  [mediaQueries.tablet('min')]: {
    '> div:first-of-type > div': {
      ...spacing({ pr: 8 }),
    },
    '> div:last-of-type > div': {
      ...spacing({ pl: 8 }),
    },
    flexDirection: 'row',
  },
});

export const ModalSectionButton = styled.div({
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  ...spacing({ px: 24, py: 20 }),
  [mediaQueries.tablet('min')]: {
    ...spacing({ px: 40 }),
  },
});

export const ItemDivider = styled.span({
  [mediaQueries.tablet('max')]: { boxShadow: shadows.divider },
});
