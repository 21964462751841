import type { Either } from 'fp-ts/lib/Either';
import { fold, isLeft, map, mapLeft, right } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/pipeable';
import type * as t from 'io-ts';
import { PathReporter } from 'io-ts/lib/PathReporter';

import { config } from '@jane/shared/config';

// import { trackError } from '@jane/shared/util';

export interface Loadable<T> {
  data: T | undefined;
  error: Error | undefined;
  fetch(): void;
  loading: boolean;
}

export interface InnerResponse {
  data: any;
  error: any;
  fetch(): void;
  loading: boolean;
}

// The response payload with its expected schema
interface Response<T> {
  response: InnerResponse;
  source: Record<string, any>;
  type: t.Type<any, T>;
}

export type LoadableOrValidationError<T> = Either<ValidationError, Loadable<T>>;

export type LoadableOrError<T> = Either<Error, Loadable<T>>;

export class ValidationError extends Error {
  name = 'ValidationError';
  public validationErrors: t.ValidationError[];
  public invalidData: unknown;

  constructor(errors: t.ValidationError[], data: unknown) {
    super('Validation Error');
    this.validationErrors = errors;
    this.invalidData = data;
  }
}

export class RuntimeError extends Error {
  name = 'RuntimeErrorOccurred';
  public error: any;

  constructor(error: any) {
    super(
      ['Runtime Error', typeof error === 'string' ? error : undefined]
        .filter(Boolean)
        .join(': ')
    );
    this.error = error;
  }
}

export const decodeType = <T extends {} | null>({
  type,
  data,
  source,
}: {
  data: object;
  source: Record<string, any>;
  type: t.Type<any, T>;
}): Either<ValidationError, T> => {
  let validation = type.decode(data);

  if (isLeft(validation)) {
    const error = new Error(
      [
        PathReporter.report(validation),
        '█',
        `${Object.keys(source)
          .map((k) => `${k}: ${source[k]}`)
          .join('\n')}`,
        '█',
        'Received:',
        JSON.stringify(data, null, 2),
      ].join('\n')
    );

    if (config.nodeEnv === 'production') {
      // trackError(error, source)
      // On production, we don't want to fail outright as the code may still
      // work. Instead, we log it and send the data on, hoping it doesn't
      // fail elsewhere.
      validation = right(data as any);
    } else {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
  return pipe(
    validation,
    mapLeft((errors) => new ValidationError(errors, data))
  );
};

export const throwValidationErrorOrReturnValue = <T>(
  value: Either<ValidationError, T>
) =>
  fold<ValidationError, T, T>(
    (error) => {
      throw error;
    },
    (response) => response
  )(value);

export const responseToLoadable = <T extends {}>({
  response: { data: maybeData, loading, error, fetch },
  type,
  source,
}: Response<T>): LoadableOrValidationError<T> => {
  if (maybeData === undefined) {
    return right({ data: undefined, loading, error, fetch });
  }

  const validation = decodeType({ type, data: maybeData, source });

  return pipe(
    validation,
    map((data) => ({ data, loading, error, fetch }))
  );
};
