import styled from '@emotion/styled';

import { mediaQueries, spacing } from '../../../../styling';
import { Typography } from '../../../typography';

interface StyledHelperTextProps {
  disabled?: boolean;
}

export const StyledHelperText = styled(Typography)<StyledHelperTextProps>(
  {
    ...spacing({ mt: 16 }),
    [mediaQueries.tablet('max')]: [
      {
        ...spacing({
          mt: 0,
        }),
      },
    ],
  },
  ({ disabled }) => ({
    opacity: disabled ? '0.3' : 'initial',
  })
);
