import styled from '@emotion/styled';

import { shadows } from '../../../../styling';

export const StyledRadioContainer = styled.div({
  position: 'relative',
  height: '24px',
});

export const StyledInput = styled.input(
  {
    appearance: 'none',
    cursor: 'pointer',
    height: '24px',
    position: 'relative',
    width: '24px',
    zIndex: '1',
    outline: 'none',
  },
  ({ theme: { colors } }) => ({
    '&:focus-visible': {
      '&:after': {
        position: 'absolute',
        content: `''`,
        display: 'inherit',
        top: '-3px',
        left: '-3px',
        bottom: '-3px',
        right: '-3px',
        border: `1px solid ${colors.primary.main}`,
        borderRadius: '50%',
        backgroundColor: 'transparent',
      },
    },
    '&:disabled': {
      cursor: 'default',
    },
  })
);

export const StyledRadio = styled.div(
  {
    borderRadius: '50%',
    height: '24px',
    position: 'absolute',
    top: '0',
    width: '24px',
    'input:checked + &': {
      boxShadow: 'none',
      border: 'none',
    },
    ':after': {
      borderRadius: '50%',
      display: 'block',
      content: '""',
      height: '12px',
      left: '6px',
      position: 'absolute',
      top: '6px',
      width: '12px',
      'input:checked + &': {},
    },
  },
  ({ theme: { colors } }) => ({
    backgroundColor: colors.grays.white,
    border: `2px solid ${colors.grays.mid}`,
    boxShadow: shadows.inner,
    'input:checked + &': {
      backgroundColor: colors.primary.main,
    },
    ':after': {
      'input:checked + &': {
        backgroundColor: colors.grays.white,
      },
    },
  })
);
