import { PRODUCT_ROOT_TYPES } from './constants';
import type { FilterIcons, IconColors } from './constants';
import { formatFilterLabel } from './formatFilterLabel';
import { getWeightLabel } from './getWeightLabel';

interface FacetToFilterOptions {
  customLabels?: { [label: string]: string };
  filterGroup?: string;
  iconColor?: IconColors;
  icons?: FilterIcons;
  isPartnerMenu?: boolean;
  storeId?: string | number;
}

export const facetToFilter = (
  facets: { [label: string]: number },
  options?: FacetToFilterOptions
) => {
  const {
    customLabels,
    filterGroup,
    iconColor,
    icons,
    isPartnerMenu,
    storeId,
  } = options || {};

  const itemLabelToRender = (labelValue: string) => {
    if (filterGroup === 'Brands') {
      return labelValue;
    }

    if (filterGroup === 'Weights') {
      return getWeightLabel(labelValue);
    }

    return formatFilterLabel(labelValue, customLabels, storeId);
  };

  const allFacets = Object.entries(facets);
  const topLevelFacets = allFacets.filter(([key]) => !key.includes(':'));
  const subFacets = allFacets.filter(([key]) => key.includes(':'));
  const facetsToRender = storeId
    ? topLevelFacets
    : filterGroup !== 'Categories' // if no store, we limit categories to PRODUCT_ROOT_TYPES
    ? topLevelFacets
    : topLevelFacets.filter(([key]) => PRODUCT_ROOT_TYPES.includes(key));

  const subItems = (topLevelFacet: string) =>
    subFacets
      .filter(([key]) => key.startsWith(`${topLevelFacet}:`))
      .map(([value, count]) => ({
        value,
        label: itemLabelToRender(value),
        count: count,
      }));

  return facetsToRender.map(([value, count]) => {
    const Icon = icons && icons[value];
    const color = iconColor && iconColor[value];

    return {
      count,
      icon: Icon ? (
        <Icon color={color ? color : isPartnerMenu ? 'primary' : undefined} />
      ) : undefined,
      label: itemLabelToRender(value),
      value,
      subItems: subItems(value),
    };
  });
};
