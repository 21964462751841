import { effects } from '../styling';
import { theme as defaultTheme } from '../theme';
import type {
  Color,
  ColorGroup,
  Components,
  CustomComponents,
  CustomTheme,
  ReeferTheme,
} from '../types';
import type { BorderRadius } from '../types/borderRadius';
import { deepMerge } from '../utils/deepMerge';
import { logPrefix } from '../utils/logger';

type CustomizableColorGroups = Omit<
  ReeferTheme['colors'],
  'text' | 'background' | 'brand' | 'tertiary' | 'system' | 'grays'
>;

const calculateColorGroup = (color: Color): ColorGroup => {
  return {
    light: effects.lighten(color, 0.8),
    main: color as string,
    dark: effects.darken(color, 0.2),
  };
};

const mergeColors = (
  customColors: CustomTheme['colors'],
  defaultColors: ReeferTheme['colors']
) => {
  if (!customColors) return defaultColors;

  const mergedColors = { ...defaultColors };
  const { text, background, ...rest } = customColors;

  Object.keys(rest).forEach((colorKey: string) => {
    const color = rest[colorKey as keyof CustomTheme['colors']];
    if (color) {
      mergedColors[colorKey as keyof CustomizableColorGroups] =
        calculateColorGroup(color);
    }
  });

  if (text) {
    mergedColors.text = {
      main: (text.main || defaultColors.text.main) as string,
      info: (text.info || defaultColors.text.info) as string,
      inverse: (text.inverse || defaultColors.text.inverse) as string,
    };
  }
  if (background) mergedColors.background = background as string;

  return mergedColors;
};

const validateBorderRadius = (borderRadius: BorderRadius) => {
  const smRadius = parseFloat(borderRadius.sm as string);
  const lgRadius = parseFloat(borderRadius.lg as string);

  if (smRadius > lgRadius) {
    throw Error(
      `${logPrefix(
        'ReeferThemeProvider'
      )} Border radius 'sm' cannot be larger than 'lg'. Please adjust your borderRadius styles.`
    );
  }
};

export const generateTheme = (customTheme?: CustomTheme): ReeferTheme => {
  if (!customTheme) return defaultTheme;
  const mergedTheme: ReeferTheme = {
    ...defaultTheme,
    borderRadius: deepMerge<BorderRadius>(
      {},
      defaultTheme.borderRadius,
      customTheme.borderRadius || {}
    ),
    colors: mergeColors(customTheme.colors, defaultTheme.colors),
    components: deepMerge<Components, CustomComponents>(
      {} as Components,
      defaultTheme.components,
      customTheme.components || {}
    ),
  };

  validateBorderRadius(mergedTheme.borderRadius);

  return mergedTheme;
};
