import { css, keyframes, spacing, styled } from '@jane/reefer';
import type { CSSObject } from '@jane/reefer';

import { border } from '../style';

const baseClockIcon = css({
  borderRadius: '100%',
  position: 'relative',
  opacity: 0.9,
});

const ClockIcon = styled.div(
  baseClockIcon,
  {
    height: 24,
    width: 24,
    top: -1,
  },
  spacing({ ml: 12 }),
  ({ theme }) => border.normal(theme.colors.grays.black)
);

const EmbeddedClockIcon = styled.div(
  baseClockIcon,
  ({ theme }) => border.normal(theme.colors.secondary.main),
  {
    height: 20,
    width: 20,
  }
);

const baseClockHandStyle: CSSObject = {
  width: 2,
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  height: '100%',
  margin: 'auto',
  '&::before': {
    position: 'absolute',
    width: '100%',
    content: '""',
  },
};

const clockStyles = () => [
  baseClockHandStyle,
  ({ theme }) => ({
    '&::before': {
      background: theme.colors.grays.black,
      top: 4,
      height: 7,
    },
  }),
];

const embeddedClockStyles = [
  baseClockHandStyle,
  ({ theme }) => ({
    '&:before': {
      background: theme.colors.secondary.main,
      top: 2,
      height: 7,
    },
  }),
];

const seconds = keyframes`
  0% {
    transform: rotate(90deg);
  }

  100% {
    transform: rotate(450deg);
  }
`;
const secondsAnimation = {
  animation: `${seconds} 10s infinite linear`,
};

const minutes = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

const minutesAnimation = {
  animation: `${minutes} 600s infinite linear`,
};

interface ClockHandProps {
  embedded?: boolean;
  hand: 'minute' | 'second';
}

const ClockHand = styled.div<ClockHandProps>(
  ({ embedded = false }) => (embedded ? embeddedClockStyles : clockStyles),
  ({ hand }) => (hand === 'minute' ? minutesAnimation : secondsAnimation)
);

const AnimatedClockIcon = ({ embedded = false }: { embedded?: boolean }) => {
  const Clock = embedded ? EmbeddedClockIcon : ClockIcon;

  return (
    <Clock>
      <ClockHand hand="minute" embedded={embedded} />
      <ClockHand hand="second" embedded={embedded} />
    </Clock>
  );
};

export default AnimatedClockIcon;
