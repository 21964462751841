import type { Components } from '../../types';
import { Button } from './button';
import { Card } from './card';
import { Typography } from './typography';

export const components: Components = {
  Button,
  Card,
  Typography,
};
