import type { HTMLAttributes } from 'react';

import { Typography } from '@jane/reefer';
import type { ProductLineage } from '@jane/shared/models';

const getCategoryColor = (category: string) => {
  switch (category) {
    case 'indica':
      return 'purple';
    case 'sativa':
      return 'ember';
    case 'cbd':
      return 'sunset';
    case 'hybrid':
      return 'grays-black';
  }
};

export const Category: React.FC<
  HTMLAttributes<HTMLDivElement> & {
    category?: ProductLineage | null;
    editingMode?: boolean;
  }
> = ({ color: _, children, category, editingMode, ...props }) => {
  const color = getCategoryColor((category || '').toLowerCase());

  return (
    <Typography
      as="div"
      color={editingMode ? 'grays-white' : color}
      variant="mini-bold"
      {...props}
    >
      {children}
    </Typography>
  );
};
