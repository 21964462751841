import styled from '@emotion/styled';

import { mediaQueries, spacing } from '../../../styling';
import type { Spacing } from '../../../styling';
import { getColorValue } from '../../../utils/componentStyles';
import type { ModalContentDividerProps } from '../modal.types';
import { DESKTOP_PADDING, MOBILE_PADDING } from './modalContent.styles';

export const ModalContentDivider = styled.hr<ModalContentDividerProps>(
  ({ theme, height = 1, padding = true, color = 'grays-light' }) => [
    {
      border: 'none',
      height,
      backgroundColor: getColorValue(color, theme),
    },
    padding && {
      ...spacing({ my: MOBILE_PADDING, mx: -MOBILE_PADDING as Spacing }),
      [mediaQueries.tablet('min')]: {
        ...spacing({ my: DESKTOP_PADDING, mx: -DESKTOP_PADDING as Spacing }),
      },
    },
  ]
);
