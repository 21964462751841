import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Gear = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.00862 3.00345C7.00862 1.89697 7.9056 1 9.01207 1H13.019C14.1254 1 15.0224 1.89697 15.0224 3.00345V9.95291C16.2722 10.574 17.3579 11.4769 18.196 12.5788L19.6279 11.752L19.1271 10.8848C18.8505 10.4056 19.0147 9.793 19.4938 9.51638C19.9729 9.23976 20.5856 9.40392 20.8622 9.88304L22.8656 13.3531C23.1422 13.8322 22.9781 14.4449 22.499 14.7215C22.0199 14.9981 21.4072 14.834 21.1306 14.3548L20.6296 13.4871L19.2244 14.2984C19.7424 15.4356 20.031 16.6995 20.031 18.031C20.031 18.98 19.8841 19.8966 19.6109 20.7582C19.1177 22.314 17.5965 23.0397 16.2239 23.0397H5.80719C4.4345 23.0397 2.91333 22.314 2.4201 20.7582C2.14696 19.8966 2 18.98 2 18.031C2 14.4896 4.04186 11.4271 7.00862 9.95291V3.00345Z"
      fill="#63B8D2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 13C11 13.5523 10.5523 14 10 14C8.34315 14 7 15.3431 7 17C7 17.5523 6.55228 18 6 18C5.44772 18 5 17.5523 5 17C5 14.2386 7.23858 12 10 12C10.5523 12 11 12.4477 11 13Z"
      fill="black"
      fillOpacity={0.5}
      className="details"
    />
  </svg>
);
export const GearIcon = ({ ...props }: DynamicIconProps) => {
  return <Icon icon={Gear} {...props} color={props.color} />;
};
