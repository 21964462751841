import type { SVGProps } from 'react';

import { LogoWrapper, StyledLogo } from './logo.styles';

const SecondaryLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 532 142"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M189.218 101.288V0h-23.011v99.321c0 15.144-9.244 21.241-19.668 21.241-10.62 0-20.061-6.687-20.061-19.667h-22.814c0 27.534 19.864 41.105 42.875 41.105 22.421 0 42.679-11.801 42.679-40.712ZM205.015 90.47c0 28.126 20.454 51.137 47.399 51.137 14.161 0 25.961-7.277 31.468-15.144v13.177h21.635V41.302h-21.635v13.177c-5.507-7.867-17.307-15.144-31.468-15.144-26.945 0-47.399 23.011-47.399 51.136Zm80.637 0c0 17.702-11.8 31.469-29.304 31.469-17.505 0-29.305-13.767-29.305-31.468 0-17.7 11.8-31.468 29.305-31.468 17.504 0 29.304 13.767 29.304 31.468ZM328.926 139.64h21.634V91.258c0-21.045 11.014-32.255 25.371-32.255 12.587 0 20.454 9.44 20.454 24.978v55.659h21.635v-59.2c0-24.977-14.751-41.105-37.565-41.105-12.784 0-23.995 5.507-29.895 15.34V41.303h-21.634v98.338ZM432.918 90.47c0 29.896 21.438 51.137 51.332 51.137 20.651 0 35.992-8.851 44.842-22.815l-16.52-11.8c-5.114 8.653-14.358 14.947-28.125 14.947-16.324 0-29.305-10.62-30.288-26.748h77.294c.196-3.147.196-4.917.196-7.08 0-31.665-21.634-48.776-47.399-48.776-29.894 0-51.332 22.225-51.332 51.136Zm50.546-32.844c12.587 0 23.994 7.67 26.354 21.831h-54.676c2.95-14.554 15.734-21.831 28.322-21.831Z"
      fill="currentColor"
    />
    <path
      d="M60.541 15.749c-4.662-6.161-13.68-14.716-27.86-14.716C14.46 1.033 0 17.515 0 33.813 0 50.116 8.12 62.88 24.366 77.567c16.19 14.644 21.75 16.118 36.103 23.836 14.502-7.718 20.067-9.193 36.252-23.836 16.246-14.687 24.361-27.451 24.361-43.754 0-16.298-14.456-32.78-32.68-32.78-14.176 0-23.199 8.555-27.861 14.716Z"
      fill="#FFC220"
    />
  </svg>
);

const PrimaryLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 434 224"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M86.706 182.962v-102.1H63.385V180.98c0 15.266-9.368 21.411-19.933 21.411-10.763 0-20.33-6.74-20.33-19.825H0C0 210.32 20.132 224 43.452 224c22.723 0 43.254-11.895 43.254-41.038ZM102.715 172.058c0 28.35 20.73 51.545 48.037 51.545 14.351 0 26.311-7.335 31.892-15.265v13.283h21.925v-99.126h-21.925v13.283c-5.581-7.93-17.541-15.266-31.892-15.266-27.307 0-48.037 23.196-48.037 51.546Zm81.723 0c0 17.843-11.96 31.72-29.7 31.72-17.739 0-29.699-13.877-29.699-31.72 0-17.843 11.96-31.72 29.699-31.72 17.74 0 29.7 13.877 29.7 31.72ZM228.293 221.621h21.926v-48.77c0-21.213 11.162-32.513 25.712-32.513 12.757 0 20.73 9.516 20.73 25.178v56.105h21.926v-59.674c0-25.178-14.95-41.435-38.071-41.435-12.956 0-24.317 5.551-30.297 15.464v-13.481h-21.926v99.126ZM333.685 172.058c0 30.134 21.727 51.545 52.024 51.545 20.929 0 36.476-8.921 45.445-22.997l-16.743-11.895c-5.182 8.723-14.55 15.067-28.503 15.067-16.544 0-29.699-10.705-30.696-26.962h78.334c.199-3.172.199-4.956.199-7.137 0-31.919-21.925-49.167-48.036-49.167-30.297 0-52.024 22.403-52.024 51.546Zm51.226-33.108c12.757 0 24.318 7.732 26.71 22.006h-55.412c2.99-14.671 15.946-22.006 28.702-22.006Z"
      fill="currentColor"
    />
    <path
      d="M216.252 14.834C211.527 8.624 202.388 0 188.017 0c-18.465 0-33.121 16.615-33.121 33.043 0 16.433 8.23 29.3 24.695 44.105 16.408 14.76 22.042 16.247 36.589 24.027 14.697-7.78 20.336-9.267 36.739-24.027 16.465-14.805 24.689-27.672 24.689-44.105 0-16.428-14.65-33.043-33.12-33.043-14.366 0-23.511 8.624-28.236 14.834Z"
      fill="#FFC220"
    />
  </svg>
);

export interface LogoProps {
  /** Aria label */
  ariaLabel?: string;

  /** Color of the logo text */
  color?: 'white' | 'black';

  /** Size of the logo */
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';

  /** Id used for testing */
  testId?: string;

  /** Determines the logo style */
  variant?: 'primary' | 'secondary';
}

/**
 * We ❤ Jane.
 *
 * The lovely and lovable Jane Technologies logo.
 */

export function Logo({
  ariaLabel = 'Jane Technologies logo',
  color = 'black',
  size = 'md',
  testId,
  variant = 'primary',
  ...props
}: LogoProps) {
  return (
    <LogoWrapper size={size}>
      <StyledLogo
        variant={variant}
        color={color}
        size={size}
        role="img"
        aria-label={ariaLabel}
        data-testid={testId}
        {...props}
      >
        {variant === 'primary' ? <PrimaryLogo /> : <SecondaryLogo />}
      </StyledLogo>
    </LogoWrapper>
  );
}
