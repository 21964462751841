import * as t from 'io-ts';

import type { Id } from './api';
import type { AppMode } from './appMode';
import type { PendingCartProduct } from './cart';
import type { DeepReadonly } from './deepReadonly';
import type { MenuProduct } from './menuProduct';

const tProductBrand = t.intersection([
  t.interface({ id: t.number }),
  t.partial({ name: t.string }),
]);

export type FlightProductBrand = t.TypeOf<typeof tProductBrand>;

const tAdData = t.intersection([
  t.interface({
    flight: t.intersection([
      t.interface({
        id: t.number,
        flight_type: t.string,
        // data_1 is the base64 encoded revenue of the impression. Revenue per impression is meaningful for CPM.
        // For non CPM model, e.g. CPC, revenue per impression would always be 0.
        // Because tAdData is returned to the browser, we hide the revenue behind non-obvious name "data_1" and encoding.
        data_1: t.string,
        ad_unit: t.string,
        custom_title: t.string,
        creative_ids: t.array(t.number),
        kevel_token: t.string,
        model: t.union([t.literal('cpc'), t.literal('cpm')]),
        product_brand: tProductBrand,
      }),
      t.partial({
        keywords: t.array(t.string),
      }),
    ]),
    product_ids: t.union([t.array(t.number), t.null]),
  }),
  t.partial({
    filter_brands: t.union([t.array(tProductBrand), t.null]),
    weights: t.record(t.number, t.number),
  }),
]);

export type AdData = t.TypeOf<typeof tAdData>;

const tAdError = t.partial({
  message: t.string,
  error: t.string,
});

type AdError = t.TypeOf<typeof tAdError>;

export type AdType = AdData | AdError;

export type AdFlight = AdData['flight'];

export type DmMeta = {
  myHighD?: string;
};

export type Zone = 'storePDP' | 'storeMenu' | 'storeMenuInline' | 'cart';

export type ImpressionTrackingPlacement = {
  dmMeta?: DmMeta;
  flight: DeepReadonly<AdFlight>;
  productBrandId: number;
  productBrandName: string;
  productId: number;
};

export type TrackImpressedRowAdProps = {
  appMode: AppMode;
  cartProducts: PendingCartProduct[];
  currentCycleIndex: number;
  flight: AdFlight;
  isDesktop: boolean;
  janeDeviceId: string | undefined;
  productIds: number[] | null;
  storeId: Id;
  userId: number | null;
  zone: Zone;
};

export type TrackImpressedAdPayload = {
  adUnit?: string;
  appMode: AppMode;
  creativeIds?: number[];
  currentCycleIndex?: number;
  distinctId: string | undefined;
  flightId: number;
  flightType: string;
  janeDeviceId: string | undefined;
  kevelToken: string | undefined;
  mlScoringSignals?: Record<string, unknown>;
  model: string;
  myHighD?: string;
  placementRow?: string;
  positionedProductIds?: number[] | null;
  productBrand: string | undefined;
  productBrandId: number;
  productBrandInCart: boolean;
  productId?: number;
  revenue: number;
  storeId: Id;
  userId: number | null;
  zone: Zone;
};

export type TrackInlineImpressionProps = {
  appMode: AppMode;
  cartProducts: PendingCartProduct[];
  janeDeviceId: string;
  matchedRows: string[];
  mlScoringSignals?: Record<string, unknown>;
  placements: Record<string, ImpressionTrackingPlacement[]>;
  qualifiedRows: string[];
  storeId: Id;
  userId: number;
  zone: Zone;
};

export type PageAttributes =
  | undefined
  | (Partial<MenuProduct> & {
      appMode?: AppMode;
      productId?: number;
      reviewCount?: number | null;
      rootTypes?: string[];
      specialId?: number | null;
      specialTitle?: string | null;
      storeId?: number | string;
    });

export type FetchAdWithMenuProductsParams = {
  app_mode: AppMode;
  count?: number;
  current_creative_ids?: number[];
  current_flight_ad_unit?: string;
  current_lineages?: string | readonly string[];
  current_root_types?: string | readonly string[];
  excluded_brand_names?: string[];
  jane_device_id: string | undefined;
  keywords?: string[];
  mp_distinct_id?: string | undefined;
  pageAttributes?: PageAttributes;
  zone: Zone | undefined;
};
