import { useState } from 'react';
import { matchRoutes, useLocation } from 'react-router-dom';

import {
  Box,
  Button,
  ButtonToggle,
  DismissIcon,
  FieldWrapper,
  Flex,
  HeartIcon,
  Modal,
  Typography,
  useMobileMediaQuery,
  useWindowListener,
} from '@jane/reefer';
import type {
  Preferences,
  StoreFulfillmentTypes,
  StoreTypes,
} from '@jane/shared-ecomm/providers';
import { useUserPreferences } from '@jane/shared-ecomm/providers';
import { LocationDetector } from '@jane/shared-ecomm/util';

import { getLocationInfo } from './getLocationInfo';
import { UnservedLocationModalContent } from './unservedLocationModalContent';
import { UserPreferencesLocation } from './userPreferencesLocation';
import {
  DismissIconContainer,
  PreferencesWrapper,
  StyledButtonToggleButton,
  StyledSlider,
} from './userPreferencesModal.styles';

export const MESSAGE_ID = 'location-message';

const FULFILLMENT_TYPES = [
  { label: 'Pickup', value: 'pickup' },
  { label: 'Delivery', value: 'delivery' },
];
const STORE_TYPES = [
  { label: 'Recreational', value: 'recreational' },
  { label: 'Medical', value: 'medical' },
  { label: 'All', value: 'all' },
];

export type LocationErrors =
  | 'illegal'
  | 'regulations'
  | 'noDeliveryStores'
  | 'noNearbyStores'
  | 'deliveryAddress'
  | 'error';

interface Props {
  appPartner?: string;
  disallowCloseModal?: boolean;
  onCloseModal: () => void;
  showModal: boolean;
}

const getInitialPreferences = (userPreferences: Preferences): Preferences => ({
  storeAvailability: userPreferences.storeAvailability,
  storeFulfillmentType: userPreferences.storeFulfillmentType || 'pickup',
  storeType: userPreferences.storeType || 'recreational',
  storeSearchRadius: userPreferences.storeSearchRadius || 20,
});

export const UserPreferencesModal = ({
  disallowCloseModal = false,
  onCloseModal,
  showModal,
}: Props) => {
  const location = useLocation();

  const {
    resetLocation,
    resetPreferences,
    userPreferences,
    setUserLocation,
    setUserPreferences,
  } = useUserPreferences();

  const [preferences, setPreferences] = useState<Preferences>(
    getInitialPreferences(userPreferences as Preferences)
  );

  const [detecting, setDetecting] = useState(false);
  const [error, setError] = useState<LocationErrors | null>(null);
  const [address, setAddress] = useState<string>('');

  const showStoreSettings = !!matchRoutes(
    [{ path: '/stores' }, { path: '/' }],
    location
  );
  const hideStoreType = !!matchRoutes(
    [
      { path: '/products/:product_id/:slug' },
      { path: '/brands/:brand_id/:brand_name/products/:product_id/:slug' },
    ],
    location
  );
  const hideRadiusSlider =
    preferences.storeFulfillmentType === 'delivery' ||
    !showStoreSettings ||
    !!matchRoutes([{ path: '/stores' }], location);

  const isMobile = useMobileMediaQuery({});

  const handleSubmit = () => {
    if (address) {
      setDetecting(true);
      LocationDetector.getInstance()
        .geocode(address)
        .then(
          (location) => {
            //NOTE(ZACK): Remove this autoswitch when hooking everything up to products
            const storeFulfillmentType =
              !location.street && !showStoreSettings
                ? 'pickup'
                : (preferences.storeFulfillmentType as StoreFulfillmentTypes);

            getLocationInfo({
              customMaxSearchRadius: preferences.storeSearchRadius,
              fulfillmentType: storeFulfillmentType,
              location,
              setDetecting,
            })
              .then(() => {
                setUserLocation({ ...location, hasResetLocation: false });
                setUserPreferences({
                  ...preferences,
                  storeFulfillmentType,
                });
                onCloseModal && onCloseModal();
              })
              .catch((err) => setError(err));
          },
          () => setError('error')
        );
    }

    if (!address) {
      resetLocation();
      resetPreferences();
      onCloseModal && onCloseModal();
    }
  };

  const handleSetAddress = (address: string) => {
    setAddress(address);
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && !detecting && address) {
      handleSubmit();
    }
  };

  useWindowListener('keydown', handleKeyPress);

  return (
    <>
      <Modal
        overlayClose
        variant={isMobile ? 'full-screen' : 'dialogue'}
        background="purple"
        open={showModal}
        onRequestClose={disallowCloseModal ? () => null : onCloseModal}
      >
        <Modal.Content>
          <Flex justifyContent="center">
            <DismissIconContainer position="absolute">
              <DismissIcon
                altText="dismiss-modal"
                onClick={onCloseModal}
                color="grays-white"
              />
            </DismissIconContainer>
            {isMobile && <HeartIcon />}
          </Flex>

          <UserPreferencesLocation
            handleSetAddress={handleSetAddress}
            fulfillmentType={preferences.storeFulfillmentType}
          />

          <PreferencesWrapper
            flexDirection="column"
            mt={showStoreSettings ? 12 : 24}
            width="100%"
          >
            {showStoreSettings && (
              <FieldWrapper
                name="Fulfillment Type"
                label=""
                labelHidden
                render={() => (
                  <ButtonToggle
                    value={preferences.storeFulfillmentType as string}
                    variant="inverse"
                    full
                    onChange={(value) =>
                      setPreferences((prevValue: Preferences) => ({
                        ...prevValue,
                        storeFulfillmentType: value as 'delivery' | 'pickup',
                      }))
                    }
                  >
                    {FULFILLMENT_TYPES.map((type) => (
                      <ButtonToggle.Button
                        key={type.value}
                        value={type.value}
                        label={type.label}
                      />
                    ))}
                  </ButtonToggle>
                )}
              />
            )}
            <Modal.ContentDivider color="purple-dark" />

            {!hideStoreType && (
              <>
                <FieldWrapper
                  name="Store Types"
                  label="Store Types"
                  disableMobileInputStyling
                  render={() => (
                    <ButtonToggle
                      value={preferences.storeType as string}
                      variant="inverse"
                      full
                      onChange={(value) =>
                        setPreferences((prevValue) => ({
                          ...prevValue,
                          storeType: value as StoreTypes,
                        }))
                      }
                    >
                      {STORE_TYPES.map((type) => (
                        <ButtonToggle.Button
                          key={type.value}
                          value={type.value}
                          label={type.label}
                        />
                      ))}
                    </ButtonToggle>
                  )}
                />
                <Modal.ContentDivider color="purple-dark" />
              </>
            )}

            {showStoreSettings && (
              <StyledButtonToggleButton>
                <FieldWrapper
                  name="Availability"
                  label="Availability"
                  disableMobileInputStyling
                  render={() => (
                    <ButtonToggle.Button
                      variant="inverse"
                      value=""
                      selected={preferences.storeAvailability === 'openNow'}
                      label="Open Now"
                      onClick={(e) => {
                        e.currentTarget.blur();
                        setPreferences((prevValue) => ({
                          ...prevValue,
                          storeAvailability:
                            prevValue.storeAvailability === 'openNow'
                              ? undefined
                              : 'openNow',
                        }));
                      }}
                    />
                  )}
                />
              </StyledButtonToggleButton>
            )}

            {!hideRadiusSlider && (
              <Box mt={showStoreSettings ? 24 : 0}>
                <Flex justifyContent="space-between" mb={16}>
                  <Typography color="grays-white">Radius</Typography>
                  <Typography color="grays-light">{`${preferences.storeSearchRadius} mi`}</Typography>
                </Flex>
                <StyledSlider
                  variant="inverse"
                  isDouble={false}
                  onChange={(value: string) =>
                    setPreferences((prevValue) => ({
                      ...prevValue,
                      storeSearchRadius: parseInt(value),
                    }))
                  }
                  defaultValue={preferences.storeSearchRadius || 20}
                  endUnit="mi"
                  min={5}
                  max={100}
                  step={5}
                  label="Radius"
                  labelHidden
                  name="radius"
                />
              </Box>
            )}
          </PreferencesWrapper>

          <Button
            disabled={detecting}
            loading={detecting}
            mt={40}
            full
            variant="primary-inverse"
            type="submit"
            onClick={handleSubmit}
            label="Update"
          />
        </Modal.Content>
      </Modal>

      {!!error && (
        <Modal
          variant="dialogue"
          onRequestClose={() => setError(null)}
          open={!!error}
        >
          <Modal.Content>
            <UnservedLocationModalContent error={error as LocationErrors} />

            <Button
              onClick={() => setError(null)}
              label={error === 'deliveryAddress' ? 'Okay' : 'Go Back'}
              full
              mt={24}
            />
          </Modal.Content>
        </Modal>
      )}
    </>
  );
};
