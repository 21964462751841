import { mediaQueries, styled } from '@jane/reefer';

import { spacing } from '../style';
import {
  DEFAULT_DESKTOP_CARD_WIDTH,
  DEFAULT_MOBILE_CARD_WIDTH,
} from './storeMenu/productCardHelper';

export const PRODUCTS_GRID_GAP = '24px 24px';

const ProductsGrid = styled.div<{ addMarginY?: boolean }>(
  ({ addMarginY = true }) =>
    spacing({ my: addMarginY ? 16 : undefined, p: 8, mx: -8 }),
  {
    display: 'grid',
    gridGap: PRODUCTS_GRID_GAP,
    gridAutoRows: '1fr',
    [mediaQueries.tablet('max')]: {
      gridTemplateColumns: `repeat(auto-fill, minmax(${DEFAULT_MOBILE_CARD_WIDTH}px, 1fr))`,
    },
    [mediaQueries.mobile('md', 'max')]: {
      overflowX: 'auto',
      gridTemplateColumns: `repeat(2, minmax(${DEFAULT_MOBILE_CARD_WIDTH}px, 1fr))`,
    },
    [mediaQueries.tablet('min')]: {
      gridTemplateColumns: `repeat(auto-fill, minmax(${DEFAULT_DESKTOP_CARD_WIDTH}px, 1fr))`,
      height: '100%',
    },
  }
);

export default ProductsGrid;
