import { CardCarousel as CardCarouselComponent } from './cardCarousel';
import { CarouselCard } from './carouselCard/carouselCard';
import { CarouselHeader } from './carouselHeader/carouselHeader';

export type { CardCarouselProps } from './cardCarousel.types';

export const CardCarousel = Object.assign(CardCarouselComponent, {
  Card: CarouselCard,
  Header: CarouselHeader,
});
