import { Button, Modal } from '@jane/reefer';
import type { AppMode } from '@jane/shared/models';

import { MESSAGE_ID } from '.';
import { Box } from '../../style/layout';
import Security from '../security';
import CTAButton from './ctaButton';
import { Header } from './header';

interface Props {
  allowLocationReset: boolean;
  appMode: AppMode;
  appPartner: string;
  detecting: boolean;
  onEnterLocation: () => void;
  onLocationReset: () => void;
}

const LocationQuestion = ({
  allowLocationReset,
  appPartner,
  detecting,
  appMode,
  onLocationReset,
  onEnterLocation,
}: Props) => {
  const message = 'Find products at stores near you.';

  return (
    <Modal.Content>
      <Header id={MESSAGE_ID} aria-label={message}>
        {message}
      </Header>
      <Box width="100%" mb={24}>
        <CTAButton
          detecting={detecting}
          onClick={onEnterLocation}
          text="Enter your location"
          aria-controls="search-results-summary-text"
        />
        {appMode === 'default' && allowLocationReset && (
          <Button
            css={{ width: '100%' }}
            label="Clear location"
            mt={16}
            onClick={onLocationReset}
            variant="secondary"
            branded
          />
        )}
      </Box>
      <Box>
        <Security appPartner={appPartner} modal />
      </Box>
    </Modal.Content>
  );
};

export default LocationQuestion;
