import { useNavigate } from 'react-router-dom';

import {
  Card,
  Flex,
  PartnerLogo,
  Typography,
  useDrawerContext,
  useMobileMediaQuery,
} from '@jane/reefer';
import type { PendingCartProduct } from '@jane/shared/models';
import { formatCurrency } from '@jane/shared/util';

import { useCustomerDispatch } from '../../../../customer';
import { clearTouchedProduct } from '../../../../customer/redux/bundlePossibilities';
import { deleteCartItem, updateCart } from '../../../../customer/redux/cart';
import productPhotoMetadata from '../../../../lib/productPhotoMetadata';
import { displayQuantity } from '../../../../lib/receipt';
import { CartDrawerItemActions } from './cartDrawerItemActions';
import {
  ProductDetailsContainer,
  StyledProductName,
  StyledTypography,
} from './cartDrawerItems.styles';
import { SpecialsTag } from './specialsTag';

interface CartDrawerCardProps {
  product: PendingCartProduct;
  productDetailLink: string;
  productPrice: number;
}
export const CartDrawerCard = ({
  product,
  productDetailLink,
  productPrice,
}: CartDrawerCardProps) => {
  const navigate = useNavigate();
  const isMobile = useMobileMediaQuery({});
  const dispatch = useCustomerDispatch();

  const { onRequestClose } = useDrawerContext();

  const {
    amount,
    brand,
    brand_subtype,
    category,
    count,
    id,
    max_cart_quantity,
    name,
    price_id,
    special_title,
  } = product;

  const maxCartQuantity = product[`max_cart_quantity_${price_id}`]
    ? product[`max_cart_quantity_${price_id}`]
    : max_cart_quantity;

  const removeProduct = () => {
    dispatch(deleteCartItem(product.id, product.price_id));
  };

  const editProductQuantity = (newCount: number) => {
    dispatch(clearTouchedProduct());
    if (count !== newCount) {
      dispatch(
        updateCart({
          product_id: id,
          count: newCount,
          price_id,
        })
      );
    }
  };

  const { url: photoUrl } = productPhotoMetadata(product);

  const showWeight = price_id !== 'each' && !amount;
  const weightString = showWeight ? `/${displayQuantity(product)}` : '';

  const goToProductDetailPage = () => {
    onRequestClose();
    navigate(productDetailLink);
  };

  return (
    <Card data-testid="cart-item" flat css={{ overflow: 'unset' }}>
      <Card.Content>
        <Flex flexDirection="column" height="100%">
          <Flex
            flexDirection="row"
            alignItems="center"
            height="100%"
            width="100%"
            minWidth={isMobile ? 'calc(95vw - 80px)' : ''}
          >
            <ProductDetailsContainer
              onClick={goToProductDetailPage}
              ariaLabel="product-image"
              alignSelf={isMobile ? 'flex-start' : ''}
            >
              <PartnerLogo
                variant="store"
                name={name}
                image={photoUrl}
                size={isMobile ? 'sm' : 'lg'}
              />
            </ProductDetailsContainer>

            <ProductDetailsContainer
              onClick={goToProductDetailPage}
              ariaLabel="product-detail"
              mx={16}
              width={isMobile ? '153px' : '172px'}
              flexDirection="column"
            >
              <StyledProductName variant="body-bold">{name}</StyledProductName>
              <StyledTypography color="grays-mid">{brand}</StyledTypography>
              <StyledTypography color="grays-mid">
                {brand_subtype}
                {brand_subtype && category ? ' - ' : ''}
                {category}
              </StyledTypography>
              <StyledTypography color="grays-mid">
                {formatCurrency(productPrice)}
                {weightString}
              </StyledTypography>

              {special_title && <SpecialsTag title={special_title} />}
            </ProductDetailsContainer>

            <Flex
              alignSelf="stretch"
              flexDirection="column"
              justifyContent="space-between"
              grow
            >
              <Typography variant="body-bold" textAlign="right" ml={16}>
                {formatCurrency(productPrice * count)}
              </Typography>
              {!isMobile && (
                <CartDrawerItemActions
                  count={count}
                  name={name}
                  handleEditQuantity={editProductQuantity}
                  handleRemoveProduct={removeProduct}
                  maxQuantity={maxCartQuantity}
                />
              )}
            </Flex>
          </Flex>
          {isMobile && (
            <Flex width="100%" mt={16}>
              <CartDrawerItemActions
                count={count}
                name={name}
                handleEditQuantity={editProductQuantity}
                handleRemoveProduct={removeProduct}
                maxQuantity={maxCartQuantity}
              />
            </Flex>
          )}
        </Flex>
      </Card.Content>
    </Card>
  );
};
