import * as t from 'io-ts';

export enum BerbixStatusOptions {
  disabled = 'disabled',
  locked = 'locked',
  noTransactionsForUser = 'no_transactions_for_user',
  none = 'none',
  rejected = 'rejected',
  verified = 'verified',
}

export const tBerbixTransaction = t.interface({
  customer_uid: t.string,
  transaction_id: t.number,
  client_token: t.string,
});

export type BerbixTransaction = t.TypeOf<typeof tBerbixTransaction>;

export const tBerbixStatus = t.interface({
  status: t.string,
});

export type BerbixStatus = t.TypeOf<typeof tBerbixStatus>;

const tBerbixIdImage = t.interface({
  front: t.union([t.string, t.null]),
});

export const tBerbixVerification = t.interface({
  status: t.string,
  images: t.union([tBerbixIdImage, t.null]),
});

export type BerbixVerification = t.TypeOf<typeof tBerbixVerification>;
