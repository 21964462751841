import { Card, Flex } from '@jane/reefer';

import { useMediaQuery } from '../../../../common/providers/mediaQueryProvider';
import {
  DisplayMode,
  useProductCardContext,
} from '../../providers/productCardProvider';
import ListViewStateContainer from '../listViewStateContainer';
import ActionCardContent from './actionCardContent';
import EditingCard from './editingCard';
import ProductInfoCardContent from './productInfoCardContent';

export const ListViewProductCard = () => {
  const { smallerThanVerticalTablet } = useMediaQuery();
  const { displayMode } = useProductCardContext();
  const direction = smallerThanVerticalTablet ? 'column' : 'row';

  return (
    <div data-testid="list-view-product-card">
      <ListViewStateContainer>
        {({
          decrementQuantity,
          discountedPrice,
          incrementQuantity,
          multipleCountDisabled,
          noSelectedWeightDefault,
          onAddToCartPressed,
          onWeightSelected,
          originalPrice,
          selectedQuantity,
          selectedWeight,
          shoppingDisabled,
          soldByWeight,
          weights,
        }) => {
          return (
            <Flex mb={16}>
              <Card.Group
                direction={direction}
                width="100%"
                border="grays-light"
              >
                <Flex flexDirection="column" width="100%">
                  <Flex flexDirection={direction}>
                    <ProductInfoCardContent />
                    <ActionCardContent
                      noSelectedWeightDefault={noSelectedWeightDefault}
                      onWeightSelected={onWeightSelected}
                      selectedWeight={selectedWeight}
                      weights={weights}
                    />
                  </Flex>
                  {displayMode === DisplayMode.Edit && (
                    <EditingCard
                      decrementQuantity={decrementQuantity}
                      discountedPrice={discountedPrice}
                      incrementQuantity={incrementQuantity}
                      multipleCountDisabled={multipleCountDisabled}
                      onAddToCartPressed={onAddToCartPressed}
                      originalPrice={originalPrice}
                      selectedQuantity={selectedQuantity}
                      selectedWeight={selectedWeight}
                      shoppingDisabled={shoppingDisabled}
                      soldByWeight={soldByWeight}
                    />
                  )}
                </Flex>
              </Card.Group>
            </Flex>
          );
        }}
      </ListViewStateContainer>
    </div>
  );
};
