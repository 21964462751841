import branch from 'branch-sdk';

import { config } from '@jane/shared/config';
import { getErrorDetails, trackDmEvent } from '@jane/shared/util';
import type { DmEventMeta } from '@jane/shared/util';

let isBranchIoInitialized = false;

export const initializeBranchIo = (
  onError: (error: Error | string) => void
) => {
  const { branchIoKey } = config;
  branch.init(branchIoKey, {}, (branchError) => {
    if (branchError) {
      const error = new Error(`Failed to initialize branch.io: ${branchError}`);
      onError(error);
    }
  });
  isBranchIoInitialized = true;
};

interface CheckoutData {
  transaction_id: string;
}

export const trackBranchCheckout = (
  cartId: string,
  dmEventMeta: DmEventMeta
): boolean => {
  if (!isBranchIoInitialized) return false;
  const eventData: CheckoutData = { transaction_id: cartId };
  branch.logEvent(
    'PURCHASE',
    eventData,
    undefined,
    undefined,
    (branchError) => {
      if (branchError) {
        const error = getErrorDetails(
          new Error(`Failed to track checkout in branch.io: ${branchError}`)
        );
        trackDmEvent({ ...dmEventMeta, type: 'error', payload: error });
      }
    }
  );
  return true;
};
