import { CheckboxField } from '../../forms/fields/checkboxField';
import { SortUpIcon } from '../../icon/icons/dynamic';
import type { TableHeaderCellProps } from '../table.types';
import {
  StyledTableHeadCell,
  TableHeadCellWrapper,
} from './tableHeaderCell.styles';

export function TableHeaderCell({
  checkbox,
  width,
  children,
  sort,
}: TableHeaderCellProps) {
  return (
    <StyledTableHeadCell
      width={width || 'auto'}
      {...(checkbox && { 'data-id': 'checkbox-cell' })}
      {...(sort && {
        sorted: sort.active,
        onClick: () =>
          sort.onClick(
            sort.key,
            sort.active && sort.direction === 'asc' ? 'desc' : 'asc'
          ),
      })}
    >
      {checkbox && (
        <CheckboxField
          checked={checkbox.checked}
          onChange={checkbox.onChange}
          label=""
          name="cell-checkbox"
          {...(checkbox.indeterminate !== undefined && {
            indeterminate: checkbox.indeterminate,
          })}
        />
      )}
      {!checkbox && (
        <TableHeadCellWrapper>
          {children}
          {sort && (
            <SortUpIcon
              color={sort.active ? 'purple-dark' : 'purple-light'}
              rotate={sort.active && sort.direction === 'asc' ? 'up' : 'down'}
              altText={`sort-by`}
            />
          )}
        </TableHeadCellWrapper>
      )}
    </StyledTableHeadCell>
  );
}
