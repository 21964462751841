import compact from 'lodash/compact';
import flow from 'lodash/flow';

import type { JaneRefinementItem } from '@jane/search/types';
import type { AppMode, StoreSpecial } from '@jane/shared/models';

import { sortSearchFilterItems } from './sortSearchFilterItems';

export const sortAndLabelSpecialItems = (specials: StoreSpecial[]) =>
  flow([
    (items, appMode) =>
      items.map((item: JaneRefinementItem) =>
        matchSpecialToItem(specials, item, appMode)
      ),
    compact,
    (items) => sortSearchFilterItems({ items, sort: 'alpha' }),
  ]);

export const matchSpecialToItem = (
  specials: StoreSpecial[],
  item: JaneRefinementItem,
  appMode?: AppMode
) => {
  const specialId = item.label;

  const special = specials?.find(
    (s) =>
      s.id === parseInt(specialId) &&
      (appMode !== 'kiosk' || s.reservation_modes?.kiosk)
  );

  if (!special) return undefined;

  return {
    ...item,
    label: special.title,
    specialId: specialId,
  };
};

export const sortAndLabelSpecials = (specials: StoreSpecial[]) =>
  flow([
    (facets, appMode) =>
      Object.entries(facets).map((facet: [string, unknown]) =>
        matchSpecials(specials, facet, appMode)
      ),
    compact,
    (items) => sortSearchFilterItems({ items, sort: 'alpha' }),
  ]);

export const matchSpecials = (
  specials: StoreSpecial[],
  facet: [string, unknown],
  appMode?: AppMode
) => {
  const [value, count] = facet;

  const special = specials?.find(
    (s) =>
      s.id === parseInt(value) &&
      (appMode !== 'kiosk' || s.reservation_modes?.kiosk)
  );

  if (!special) return undefined;

  return {
    count,
    label: special.title,
    value,
  };
};
