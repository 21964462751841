import type { MenuProduct, Product } from '@jane/shared/models';
import { defaultProductPhotoUrl } from '@jane/shared/util';

const productPhotoMetadata = (product: Product | MenuProduct) => {
  let photoSource: 'jane' | 'default';
  let url;

  const { type, root_subtype, image_urls, category, kind } = product;

  if (image_urls?.length) {
    url = image_urls[0];
    photoSource = 'jane';
  } else {
    url = defaultProductPhotoUrl({
      category,
      kind,
      productRootSubtype: root_subtype,
      productType: type,
    });
    photoSource = 'default';
  }

  return {
    photoSource,
    url,
  };
};

export default productPhotoMetadata;
