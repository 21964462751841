import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const OwnershipVeteran = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.58579 19.5858C3.80474 20.3668 3.80474 21.6332 4.58579 22.4142C5.36683 23.1953 6.63317 23.1953 7.41421 22.4142L4.58579 19.5858ZM9.58579 14.5858L4.58579 19.5858L7.41421 22.4142L12.4142 17.4142L9.58579 14.5858Z"
      fill="#1F42F0"
    />
    <path
      d="M8.53551 11.5355L7.1213 12.9497L7.1213 12.9497L8.53551 11.5355ZM15.6066 11.5355L16.9806 12.9888L17.001 12.9696L17.0208 12.9497L15.6066 11.5355ZM16.5858 22.4142C17.3668 23.1953 18.6332 23.1953 19.4142 22.4142C20.1953 21.6332 20.1953 20.3668 19.4142 19.5858L16.5858 22.4142ZM9.94972 10.1213C8.77815 8.94975 8.77815 7.05025 9.94972 5.87868L7.1213 3.05025C4.38763 5.78392 4.38763 10.2161 7.1213 12.9497L9.94972 10.1213ZM9.94972 5.87868C11.1213 4.70711 13.0208 4.70711 14.1924 5.87868L17.0208 3.05025C14.2871 0.316582 9.85497 0.316582 7.1213 3.05025L9.94972 5.87868ZM14.1924 5.87868C15.3639 7.05025 15.3639 8.94975 14.1924 10.1213L17.0208 12.9497C19.7545 10.2161 19.7545 5.78392 17.0208 3.05025L14.1924 5.87868ZM14.2325 10.0823L11.626 12.5467L14.374 15.4533L16.9806 12.9888L14.2325 10.0823ZM7.1213 12.9497L16.5858 22.4142L19.4142 19.5858L9.94972 10.1213L7.1213 12.9497Z"
      fill="#FF5500"
    />
  </svg>
);
export const OwnershipVeteranIcon = ({ ...props }: StaticIconProps) => {
  return <Icon icon={OwnershipVeteran} {...props} color={undefined} />;
};
