import type { FunctionComponent } from 'react';
import { useEffect } from 'react';

import { closeModal } from '../../common/redux/application';
import { useCustomerDispatch } from '../../customer/dispatch';
import { useCustomerSelector } from '../../customer/selectors';
import { get } from '../../redux-util/selectors';
import LoginForm from '../login/form';
import Modal from '../modal';

const LoginModal: FunctionComponent = () => {
  const { authenticated: isLoggedIn } = useCustomerSelector(get('customer'));
  const dispatch = useCustomerDispatch();

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }

    dispatch(closeModal());
  }, [isLoggedIn]);

  return (
    <Modal contentLabel="Log in" width="narrow" fixedBody>
      {() => (
        <div className="modal-content">
          <div className="login--modal">
            <LoginForm embeddedMode={false} nextPathname={null} />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default LoginModal;
