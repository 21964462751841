import type { ReactElement } from 'react';

import type { MarginProperties } from '../../styling';
import type { ComponentColors } from '../../types';
import type { IconProps } from '../icon';
import { Typography } from '../typography/typography';
import { StyledTag } from './tag.styles';

export type TagSizes = 'sm' | 'lg';

export interface TagProps extends MarginProperties {
  /** Background changes the background color, defaults to 'grays-mid'  */
  background?: ComponentColors;

  /** Color changes the text color, defaults to black with light backgrounds and white with dark backgrounds */
  color?: ComponentColors;

  /* Icon that will show up on the left side of the tag */
  icon?: ReactElement<IconProps>;

  /** Label is the text content of the tag */
  label: string;

  /** Size of the tag */
  size?: TagSizes;
}

/**
 * Small colored tag with some text in it, used to add labels or context to other elements
 */
export function Tag({
  background = 'grays-mid',
  color,
  icon,
  label,
  size = 'sm',
  ...props
}: TagProps) {
  return (
    <StyledTag
      background={background}
      size={size}
      color={color}
      hasIcon={!!icon}
      alignItems="center"
      {...props}
    >
      {icon && icon}
      <Typography variant={size === 'sm' ? 'mini-bold' : 'body-bold'}>
        {label}
      </Typography>
    </StyledTag>
  );
}
