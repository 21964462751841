import { AlgoliaBrandsSchema } from '@jane/search/types';
import type {
  AlgoliaBrands,
  FullSearchAlgoliaBrandsParams,
} from '@jane/search/types';

import { search } from './client';

export async function searchAlgoliaBrands({
  name,
  paging,
  searchText = '',
}: FullSearchAlgoliaBrandsParams) {
  const { hits, ...rest } = await search<AlgoliaBrands>({
    indexPrefix: 'product-brand-',
    ...paging,
    searchText,
    options: {
      facetFilters: name
        ? [name.map((facetFilter) => `name:${facetFilter}`)]
        : [],
    },
  });

  return {
    hits: await AlgoliaBrandsSchema.parseAsync(hits),
    ...rest,
  };
}
