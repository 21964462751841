import { Box, Flex, Typography } from '@jane/reefer';
import type { AppMode } from '@jane/shared/models';

import type {
  CartLimitPolicyReport,
  CartLimitRuleReport,
} from '../../../../../lib/cartLimitPolicy';
import { CartAlertsWrapper } from '../cartDrawerAlerts.styles';
import { CartError } from '../cartError';
import { LimitItem, LimitItems } from './cartLimitPolicyAlert.styles';

const formatWeight = (weight: number) =>
  weight.toFixed(2).replace(/0+$/, '').replace(/\.$/, '');

export const CartLimitPolicyAlert = ({
  appMode,
  report,
}: {
  appMode: AppMode;
  report?: CartLimitPolicyReport | null;
}) => {
  if (!report || !report.over) return null;
  const { overReports, storeName } = report;

  return (
    <CartAlertsWrapper>
      <Box mt={16} mx="auto" width="100%">
        <CartError headerText="Cart limit exceeded">
          <LimitItems>
            {overReports.map((ruleReport: CartLimitRuleReport, i: number) => {
              const {
                limit_unit_total,
                limit_value,
                limit_unit,
                product_group_name,
              } = ruleReport;

              return (
                <LimitItem key={i}>
                  <Flex flexDirection="row" flexWrap="wrap">
                    <Typography variant="body">
                      {`Your bag has ${
                        limit_unit === 'mg' ? 'a total dosage of' : ''
                      } ${formatWeight(
                        limit_unit_total
                      )}${limit_unit} of ${product_group_name}  products, but ${storeName} only allows ${formatWeight(
                        limit_value
                      )}${limit_unit} per order.`}
                    </Typography>
                  </Flex>
                </LimitItem>
              );
            })}
          </LimitItems>
        </CartError>
      </Box>
    </CartAlertsWrapper>
  );
};
