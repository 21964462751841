import { AnimatePresence, motion } from 'framer-motion';
import type { ReactNode } from 'react';
import React from 'react';

import { styled } from '@jane/reefer';

import openCollapsed from '../styles/animations/open-collapsed';
import { useItemExpanded, useItemId } from './accordion-item';

const Summary = styled(motion.div)({
  overflow: 'hidden',
});

type Props = {
  children: ReactNode;
  hidden?: boolean;
};

const AccordionSummary = ({ hidden = false, children, ...props }: Props) => {
  const id = useItemId();
  const isExpanded = useItemExpanded();
  // Only show the summary content when hidden is false, and when it's not expanded.
  const isVisible = !hidden && !isExpanded;

  return (
    <AnimatePresence initial={false}>
      {isVisible && (
        <Summary
          key={`summary-${id}`}
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={openCollapsed}
          {...props}
        >
          {children}
        </Summary>
      )}
    </AnimatePresence>
  );
};

export default AccordionSummary;
