import { config } from '@jane/shared/config';
import { api } from '@jane/shared/data-access';
import type { Coordinates } from '@jane/shared/models';

interface ApiResponse {
  store_ids: string[];
}

export const fetchDeliveryStores = async (
  coords: Coordinates,
  zipcode?: string
) => {
  const params = new URLSearchParams({
    user_latitude: coords.lat.toString(),
    user_longitude: coords.long.toString(),
    user_zipcode: zipcode || '',
  });

  const url = `${config.apiPath}/delivery/available_stores?${params}`;
  const res: ApiResponse = await api.get(url);
  return res;
};
