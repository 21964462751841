import cloneDeep from 'lodash/cloneDeep';
import includes from 'lodash/includes';
import sum from 'lodash/sum';
import type { NavigateFunction } from 'react-router-dom';
import type { Reducer } from 'redux';

import { config } from '@jane/shared/config';
import type {
  CustomerReservationDetails,
  CustomerReservationHistory,
  DeepReadonly,
  GuestCustomerReservationDetails,
  Id,
  User,
} from '@jane/shared/models';
import { Storage } from '@jane/shared/util';

import { APPLICATION_CLOSE_MODAL } from '../../common/redux/application';
import {
  hideFormValidations,
  showFormValidations,
} from '../../common/redux/form';
import { paths } from '../../lib/routes';
import { createSimpleAction, createStandardAction } from '../../redux-util';
import { NotificationsService } from '../../services/notifications';
import { cancelGuestReservation } from '../../sources/guestCarts';
import {
  getGuestUserReservationDetails,
  getUserReservationDetails,
  getUserReservationHistory,
  getUserStoreReservationHistory,
} from '../../sources/reservation';
import { UsersSource } from '../../sources/users';
import type { CustomerThunkAction, CustomerThunkActionCreator } from '../redux';
import { isEmbeddedModeSelector } from '../selectors';
import { CHECKOUT_SUCCESS } from './cart';
import { CUSTOMER_LOG_OUT } from './customer';
import { REFUSE_REVIEW_SUCCESS } from './reviewableReservations';
import type { CustomerAction } from './types';

const STORAGE_KEY = config.storageKey;

export const SET_PREVIOUS_PATH = 'users/set-previous-path';

export const setPreviousPath =
  createStandardAction(SET_PREVIOUS_PATH)<string>();

export const GET_RESERVATIONS_BY_STORE = 'users/get-reservations-by-store';
export const getReservationsByStore: CustomerThunkActionCreator<Id> =
  (storeId) => (dispatch) => {
    dispatch({ type: GET_RESERVATIONS_BY_STORE });
    return getUserStoreReservationHistory(storeId).then((result) =>
      dispatch(getReservationsSuccess(result.reservations))
    );
  };

export const CANCEL_RESERVATION = 'users/cancel-reservation';
export const cancelReservation: CustomerThunkActionCreator<{
  id: Id;
  isGuestReservation: boolean;
  navigate: NavigateFunction;
}> =
  ({ id, isGuestReservation, navigate }) =>
  (dispatch) => {
    dispatch({ type: CANCEL_RESERVATION });

    const { source, successAction } = isGuestReservation
      ? {
          source: cancelGuestReservation,
          successAction: cancelGuestReservationSuccess,
        }
      : {
          source: (id: Id) => UsersSource.cancelReservation(id),
          successAction: cancelReservationSuccess,
        };

    return source(String(id)).then(
      (result) => dispatch(successAction({ uuid: id, id, result, navigate })),
      (err) => dispatch(cancelReservationError(err))
    );
  };

export const CANCEL_GUEST_RESERVATION_SUCCESS =
  'users/cancel-guest-reservation-success';
export const cancelGuestReservationSuccess: CustomerThunkActionCreator<{
  navigate: NavigateFunction;
  result: { message: string };
  uuid: Id;
}> =
  ({ uuid, result, navigate }) =>
  (dispatch, getState) => {
    const isEmbeddedMode = isEmbeddedModeSelector(getState());

    NotificationsService.success(result.message);

    if (isEmbeddedMode) {
      navigate(paths.guestReservationReceipt(String(uuid)));
    }

    dispatch({ type: CANCEL_GUEST_RESERVATION_SUCCESS, payload: uuid });
  };

export const CANCEL_RESERVATION_SUCCESS = 'users/cancel-reservation-success';
export const cancelReservationSuccess: CustomerThunkActionCreator<{
  id: Id;
  result: { message: string };
}> =
  ({ id, result }) =>
  (dispatch) => {
    NotificationsService.success(result.message);
    dispatch({ type: CANCEL_RESERVATION_SUCCESS, payload: id });
  };

export const CANCEL_RESERVATION_ERROR = 'users/cancel-reservation-error';
export const cancelReservationError = createStandardAction(
  CANCEL_RESERVATION_ERROR
)<unknown>();

export const UPDATE_USERS = 'users/update-users';
export const updateUsers = ({
  attributes,
  disableNotification = false,
}: {
  attributes: Record<string, string | undefined>;
  disableNotification?: boolean;
}): CustomerThunkAction => {
  const updates = cloneDeep(attributes);

  if (updates.phone) {
    updates.phone = updates.phone.replace(/-/g, '');
  }

  return (dispatch) => {
    UsersSource.update({ user: updates }).then((result) => {
      const { user, validations } = result;

      dispatch(hideFormValidations());

      if (validations) {
        dispatch(showFormValidations(validations));
      } else {
        !disableNotification &&
          NotificationsService.success('Profile has been updated');
        Storage.set(STORAGE_KEY, user);
        dispatch({ type: UPDATE_USERS, payload: user });
      }
    });
  };
};

export const CREATE_REVIEW = 'users/create-review';
export const createReview =
  ({
    reservationId,
    review,
  }: {
    reservationId: Id;
    review: { body: string | undefined; rating: number };
  }): CustomerThunkAction =>
  (dispatch) =>
    UsersSource.createReview(reservationId, review).then(
      (result) => {
        dispatch(getReservation(reservationId));
        dispatch(
          createReviewSuccess({
            reservationId,
            rating: review.rating,
            message: result.message,
          })
        );
      },
      (err) => dispatch(createReviewError(err))
    );

export const CREATE_REVIEW_SUCCESS = 'users/create-review-success';
export const createReviewSuccess: CustomerThunkActionCreator<{
  message?: string;
  rating: number;
  reservationId: Id;
}> =
  ({ reservationId, rating, message }) =>
  (dispatch) => {
    NotificationsService.success(message);

    dispatch({
      type: CREATE_REVIEW_SUCCESS,
      payload: { rating, reservationId },
    });
  };

export const CREATE_REVIEW_ERROR = 'users/create-review-error';
export const createReviewError: CustomerThunkActionCreator<{
  error: string;
}> = (result) => (dispatch) => {
  NotificationsService.error(result.error);
  dispatch({ type: CREATE_REVIEW_ERROR });
};

export const GET_RESERVATIONS = 'users/get-reservations';
export const getReservations: CustomerThunkActionCreator = () => (dispatch) => {
  dispatch({ type: GET_RESERVATIONS });
  return getUserReservationHistory().then((result) =>
    dispatch(getReservationsSuccess(result.reservations))
  );
};

export const GET_RESERVATIONS_SUCCESS = 'users/get-reservations-success';
export const getReservationsSuccess = createStandardAction(
  GET_RESERVATIONS_SUCCESS
)<CustomerReservationHistory[]>();

export const PREPAY_RESERVATION = 'users/prepay-reservation';
export const prepayReservation: CustomerThunkActionCreator<{
  reservation: unknown;
  token: string;
}> =
  ({ token, reservation }) =>
  (dispatch) => {
    dispatch({ type: PREPAY_RESERVATION });

    return UsersSource.prepayReservation({ token, reservation }).then(
      (result) => dispatch(prepayReservationSuccess(result)),
      (err) => dispatch(prepayReservationError(err))
    );
  };

export const PREPAY_RESERVATION_SUCCESS = 'users/prepay-reservation-success';
export const prepayReservationSuccess: CustomerThunkActionCreator<{
  message: string;
  reservation: CustomerReservationDetails;
}> = (result) => (dispatch) => {
  NotificationsService.success(result.message);
  dispatch({ type: PREPAY_RESERVATION_SUCCESS, payload: result });
};

export const PREPAY_RESERVATION_ERROR = 'users/prepay-reservation-error';
export const prepayReservationError: CustomerThunkActionCreator<string> =
  (error) => (dispatch) => {
    NotificationsService.error(error);
    dispatch({ type: PREPAY_RESERVATION_ERROR });
  };

export const SET_RESERVATION_RATING = 'users/set-reservation-rating';
export const setReservationRating = createStandardAction(
  SET_RESERVATION_RATING
)<{ rating: number; reservationId: Id; storeName: string }>();

export const NOTIFY_CURBSIDE_PICKUP_ARRIVAL =
  'users/notify-curbside-pickup-arrival';
export const notifyCurbsidePickupArrival = createSimpleAction(
  NOTIFY_CURBSIDE_PICKUP_ARRIVAL
);

export const NOTIFY_GUEST_CURBSIDE_PICKUP_ARRIVAL =
  'users/notify-guest-curbside-pickup-arrival';
export const notifyGuestCurbsidePickupArrival = createSimpleAction(
  NOTIFY_GUEST_CURBSIDE_PICKUP_ARRIVAL
);

export const GET_RESERVATION = 'users/get-reservation';
export const getReservation =
  (id: Id): CustomerThunkAction =>
  (dispatch) => {
    getUserReservationDetails(id).then((payload) =>
      dispatch({ type: GET_RESERVATION, payload })
    );
  };

export const GET_GUEST_RESERVATION = 'users/get-guest-reservation';
export const getGuestReservation =
  (uuid: string): CustomerThunkAction =>
  (dispatch) => {
    getGuestUserReservationDetails(uuid).then((payload) =>
      dispatch({ type: GET_GUEST_RESERVATION, payload })
    );
  };

export type UsersActions =
  | { type: typeof CANCEL_RESERVATION }
  | { type: typeof CREATE_REVIEW }
  | {
      payload: { reservation: CustomerReservationDetails };
      type: typeof GET_RESERVATION;
    }
  | {
      payload: { guestReservation: GuestCustomerReservationDetails };
      type: typeof GET_GUEST_RESERVATION;
    }
  | { type: typeof GET_RESERVATIONS }
  | { type: typeof GET_RESERVATIONS_BY_STORE }
  | { type: typeof PREPAY_RESERVATION }
  | ReturnType<typeof setPreviousPath>
  | ReturnType<typeof setReservationRating>
  | ReturnType<typeof getReservationsSuccess>
  | { payload: User; type: typeof UPDATE_USERS }
  | { payload: Id; type: typeof CANCEL_RESERVATION_SUCCESS }
  | { payload: Id; type: typeof CANCEL_GUEST_RESERVATION_SUCCESS }
  | {
      payload: {
        rating: number;
        reservationId: Id;
      };
      type: typeof CREATE_REVIEW_SUCCESS;
    }
  | { type: typeof PREPAY_RESERVATION_ERROR }
  | { type: typeof NOTIFY_CURBSIDE_PICKUP_ARRIVAL }
  | { type: typeof NOTIFY_GUEST_CURBSIDE_PICKUP_ARRIVAL }
  | {
      payload: {
        reservation: CustomerReservationDetails;
      };
      type: typeof PREPAY_RESERVATION_SUCCESS;
    }
  | { type: typeof CREATE_REVIEW_ERROR }
  | ReturnType<typeof cancelReservationError>;

export type UsersState = DeepReadonly<{
  hasLoadedReservations: boolean;
  isCancellingReservation: boolean;
  isLoading: boolean;
  isLoadingReservations: boolean;
  previousPath: string;
  productsOrdered: number;
  reservations: CustomerReservationHistory[];
  reservationsCompleted: number;
  reviewsLeft: number;
  shouldUpdate: boolean;
  user:
    | {
        birth_date: string;
        email: string;
        nickname: string;
        password: string;
        phone: string;
      }
    | User;
}>;

const getInitialState = (): UsersState => ({
  user: {
    birth_date: '',
    nickname: '',
    email: '',
    phone: '',
    password: '',
  },
  reservations: [],
  reservationsCompleted: 0,
  productsOrdered: 0,
  reviewsLeft: 0,
  shouldUpdate: true,
  hasLoadedReservations: false,
  isCancellingReservation: false,
  isLoadingReservations: false,
  isLoading: true,
  previousPath: '',
});

const completedStatuses = [
  'finished',
  'with_review',
  'finished_without_review',
];

const recalculateDerivedFields = (state: UsersState): UsersState => {
  const completedReservations = state.reservations.filter((reservation) =>
    includes(completedStatuses, reservation.status)
  );

  return {
    ...state,
    reservationsCompleted: completedReservations.length,
    reviewsLeft: completedReservations.filter(
      (reservation) => reservation.rating !== null
    ).length,
    productsOrdered: sum(
      completedReservations.map((reservation) => reservation.products_count)
    ),
  };
};

export const usersReducer: Reducer<UsersState, CustomerAction> = (
  state = getInitialState(),
  action
) => {
  switch (action.type) {
    case UPDATE_USERS:
      return { ...state, user: { ...state.user, ...action.payload } };

    case GET_RESERVATIONS:
      return { ...state, isLoadingReservations: true };

    case GET_RESERVATIONS_SUCCESS:
      return recalculateDerivedFields({
        ...state,
        reservations: action.payload,
        hasLoadedReservations: true,
        isLoadingReservations: false,
      });

    case APPLICATION_CLOSE_MODAL:
      return { ...state, user: getInitialState().user };

    case CUSTOMER_LOG_OUT:
      return recalculateDerivedFields({
        ...state,
        user: getInitialState().user,
        hasLoadedReservations: false,
        reservations: [],
      });

    case CHECKOUT_SUCCESS:
      return recalculateDerivedFields({
        ...state,
        reservations: [action.payload as any, ...state.reservations],
      });

    case CANCEL_RESERVATION:
      return { ...state, isCancellingReservation: true };

    case CANCEL_RESERVATION_ERROR:
      return { ...state, isCancellingReservation: false };

    case CANCEL_RESERVATION_SUCCESS:
      return {
        ...state,
        isCancellingReservation: false,
        reservations: state.reservations.map((res) =>
          res.id === action.payload ? { ...res, status: 'cancelled' } : res
        ),
      };

    case CANCEL_GUEST_RESERVATION_SUCCESS:
      return {
        ...state,
        isCancellingReservation: false,
      };

    case CREATE_REVIEW_SUCCESS: {
      const { rating, reservationId } = action.payload;
      return recalculateDerivedFields({
        ...state,
        isCancellingReservation: false,
        reservations: state.reservations.map((res) =>
          res.id === reservationId
            ? { ...res, status: 'with_review', rating }
            : res
        ),
      });
    }

    case REFUSE_REVIEW_SUCCESS: {
      const { id, status } = action.payload.reservation;
      return {
        ...state,
        isCancellingReservation: false,
        reservations: state.reservations.map((res) =>
          res.id === id ? { ...res, status } : res
        ),
      };
    }

    case SET_PREVIOUS_PATH:
      return { ...state, previousPath: action.payload };
  }

  return state;
};
