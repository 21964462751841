import type { CustomTypography, Font } from '../types';

export const loadCustomFont = async (font?: Font) => {
  if (!font?.fontFamily) {
    return;
  }

  if (typeof FontFace === 'undefined') {
    throw Error('FontFace is undefined');
  }
  const fontFamily = font.fontFamily.toString();
  if (font.url) {
    const externalFont = new FontFace(fontFamily, `url(${font.url})`);
    const externalFontResponse = await externalFont.load();
    document.fonts.add(externalFontResponse);
  }
  document.body.style.fontFamily = fontFamily;
};

export const loadCustomFonts = async (customTypography: CustomTypography) => {
  if (!customTypography) return;

  if (customTypography.fonts.branded) {
    await loadCustomFont(customTypography.fonts.branded);
  }

  if (customTypography.fonts.default) {
    await loadCustomFont(customTypography.fonts.default);
  }
};
