import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const CreditCard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.81241 16.9957L4.01364 18.4275C4.32109 20.6151 6.34376 22.1393 8.5314 21.8318L20.4146 20.1618C22.6023 19.8543 24.1265 17.8316 23.819 15.644L22.8448 8.71212C22.6165 7.08771 21.4425 5.82911 19.9575 5.41457C19.9855 5.60566 20 5.80113 20 6V7.6093C20.4544 7.91516 20.782 8.40535 20.8643 8.99046L21.8385 15.9223C21.9922 17.0162 21.2301 18.0275 20.1363 18.1812L8.25306 19.8513C7.15924 20.005 6.1479 19.2429 5.99417 18.1491L5.83268 17H4C3.93712 17 3.87458 16.9985 3.81241 16.9957Z"
      fill="#0E0E0E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 4H4C2.89543 4 2 4.89543 2 6V13C2 14.1046 2.89543 15 4 15H16C17.1046 15 18 14.1046 18 13V6C18 4.89543 17.1046 4 16 4ZM4 2C1.79086 2 0 3.79086 0 6V13C0 15.2091 1.79086 17 4 17H16C18.2091 17 20 15.2091 20 13V6C20 3.79086 18.2091 2 16 2H4Z"
      fill="#0E0E0E"
    />
    <path
      d="M5 12C5 12.5523 4.55228 13 4 13C3.44772 13 3 12.5523 3 12C3 11.4477 3.44772 11 4 11C4.55228 11 5 11.4477 5 12Z"
      fill="#0E0E0E"
    />
    <path
      d="M8 12C8 12.5523 7.55228 13 7 13C6.44772 13 6 12.5523 6 12C6 11.4477 6.44772 11 7 11C7.55228 11 8 11.4477 8 12Z"
      fill="#0E0E0E"
    />
    <path
      d="M11 12C11 12.5523 10.5523 13 10 13C9.44772 13 9 12.5523 9 12C9 11.4477 9.44772 11 10 11C10.5523 11 11 11.4477 11 12Z"
      fill="#0E0E0E"
    />
    <path
      d="M14 12C14 12.5523 13.5523 13 13 13C12.4477 13 12 12.5523 12 12C12 11.4477 12.4477 11 13 11C13.5523 11 14 11.4477 14 12Z"
      fill="#0E0E0E"
    />
    <path
      d="M17 12C17 12.5523 16.5523 13 16 13C15.4477 13 15 12.5523 15 12C15 11.4477 15.4477 11 16 11C16.5523 11 17 11.4477 17 12Z"
      fill="#0E0E0E"
    />
    <path
      d="M13 7C13 6.44772 13.4477 6 14 6H16C16.5523 6 17 6.44772 17 7V8C17 8.55228 16.5523 9 16 9H14C13.4477 9 13 8.55228 13 8V7Z"
      fill="#0E0E0E"
    />
  </svg>
);
export const CreditCardIcon = ({ ...props }: DynamicIconProps) => {
  return <Icon icon={CreditCard} {...props} color={props.color} />;
};
