import { Button, Typography } from '@jane/reefer';

import { Box, Flex } from '../../style/layout';

interface Props {
  embeddedMode: boolean;
  onCancel: () => void;
  onLogout: () => void;
}

const LogoutForm = ({ onCancel, onLogout, embeddedMode }: Props) => (
  <Box py={embeddedMode ? 16 : 0}>
    <Typography
      branded={!embeddedMode}
      mb={8}
      textAlign="center"
      variant="header-bold"
    >
      Log Out?
    </Typography>
    <Typography color="grays-black" mb={24} textAlign="center" variant="body">
      Shopping experience may be limited.
    </Typography>
    <Typography variant="body-bold">
      <Flex justifyContent="space-around">
        <Button
          branded
          full
          variant="secondary"
          onClick={onCancel}
          label="Cancel"
          mr={16}
        />
        <Button
          branded
          full
          variant="destructive"
          onClick={onLogout}
          data-testid="Log Out-button"
          label="Log Out"
        />
      </Flex>
    </Typography>
  </Box>
);

export default LogoutForm;
