import intersection from 'lodash/intersection';
import toArray from 'lodash/toArray';

import type {
  AlgoliaProduct,
  JaneSearchState,
  KindFacet,
} from '@jane/search/types';
import type { DeepReadonly } from '@jane/shared/models';

export const isVisibleFacet =
  (
    searchState: JaneSearchState<AlgoliaProduct>,
    customRows: DeepReadonly<string[] | null>
  ) =>
  (kindFacet: KindFacet) => {
    const refinements = searchState?.filters;
    return (
      !refinements ||
      !refinements['root_types'] ||
      refinements['root_types'].length === 0 ||
      !intersection(customRows, refinements['root_types'] as string[]).length ||
      toArray(refinements['root_types'] as string[])
        .map((type) => type.split(':')[0])
        .includes(kindFacet.kind)
    );
  };
