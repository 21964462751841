import kebabCase from 'lodash/kebabCase';
import trimEnd from 'lodash/trimEnd';
import trimStart from 'lodash/trimStart';
import values from 'lodash/values';
import qs from 'qs';

import { config } from '@jane/shared/config';
import type {
  Id,
  StoreSpecial,
  TermsOfServiceVariants,
} from '@jane/shared/models';

import type { Categories } from '../components/category/category.types';

type AppMode =
  | { type: 'default' }
  | {
      partnerId: Id;
      partnerName: string;
      type:
        | 'embedded'
        | 'whiteLabel'
        | 'brandEmbed'
        | 'kiosk'
        | 'framelessEmbed';
    }
  | {
      type: 'headless';
    }
  | {
      partnerId: Id;
      partnerName: string | undefined;
      type: 'kiosk';
    }
  | { operatorId: Id; storeId: Id; type: 'operatorEmbed' };

let appMode: AppMode = { type: 'default' };

export const setAppMode = (newAppMode: AppMode) => {
  appMode = newAppMode;
};

export type PartnerHostedConfig = {
  isPartnerHosted: boolean;
  partnerHostedPath?: string;
};
let partnerHostedConfig: PartnerHostedConfig = { isPartnerHosted: false };

export const setPartnerHostedConfig = (
  newPartnerHostedConfig: PartnerHostedConfig
) => {
  partnerHostedConfig = newPartnerHostedConfig;
};

const optionalQueryStringPath = (path: string, query?: {}) => {
  if (values(query).filter(Boolean).length === 0) return path;
  return `${path}?${qs.stringify(query)}`;
};

export const absolutePath = (relativePath: string) =>
  [trimEnd(config.deployPublicPath, '/'), trimStart(relativePath, '/')].join(
    '/'
  );

export const objectToGetParams = (obj: object) =>
  `?${Object.entries(obj)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&')}`;

interface NameAndSlugAndId {
  id: Id;
  name?: string;
  slug?: string;
}

interface ProductNameAndSlugAndId extends NameAndSlugAndId {
  brand?: string | null;
}

const getUrlBase = (path: string): string => {
  switch (appMode.type) {
    case 'default':
      return '';
    case 'framelessEmbed': {
      if (partnerHostedConfig.isPartnerHosted)
        return partnerHostedConfig.partnerHostedPath;
      return path.startsWith(`/stores/${appMode.partnerId}`)
        ? '/embed'
        : `/embed/stores/${appMode.partnerId}`;
    }
    case 'embedded':
    case 'whiteLabel': {
      if (partnerHostedConfig.isPartnerHosted)
        return `/${partnerHostedConfig.partnerHostedPath}`;
      return path.startsWith(`/stores/${appMode.partnerId}`)
        ? '/embed'
        : `/embed/stores/${appMode.partnerId}`;
    }
    case 'operatorEmbed':
      return path.startsWith(`/stores/${appMode.storeId}`)
        ? `/operator_embed/operators/${appMode.operatorId}`
        : `/operator_embed/operators/${appMode.operatorId}/stores/${appMode.storeId}`;
    case 'brandEmbed':
      return `/brand_embed/brand_partners/${appMode.partnerId}`;
    case 'kiosk': {
      const name = appMode.partnerName
        ? `/${kebabCase(appMode.partnerName)}`
        : '';
      return `/stores/${appMode.partnerId}${name}`;
    }
    case 'headless': {
      return `/headless`;
    }
  }
};

export const addUrlBase = (path: string, query?: object | string) => {
  const base = getUrlBase(path);

  return optionalQueryStringPath(`${base}${path}`, query);
};

export const paths = {
  root() {
    return '/';
  },
  aboutUs(query?: any) {
    const path = ['/about-us'];
    if (query) {
      path.push(qs.stringify(query));
    }
    return path.join('?');
  },
  embeddedMenu(query?: any) {
    return addUrlBase('/menu', query);
  },
  embeddedProduct(query?: any) {
    return addUrlBase('/', query);
  },
  embeddedProductDetail({
    product,
    recommended,
    fromMenu,
    bundleSpecialId,
  }: {
    bundleSpecialId?: Id | null;
    fromMenu?: boolean;
    product: ProductNameAndSlugAndId;
    recommended?: boolean;
  }) {
    return addUrlBase(
      `/products/${product.id}/${kebabCase(
        product.brand ? product.brand : ''
      )}${product.slug}`,
      { recommended, fromMenu, bundleSpecialId }
    );
  },
  embeddedLogin() {
    return addUrlBase('/login');
  },
  embeddedLogout() {
    return addUrlBase('/logout');
  },
  embeddedForgotPassword() {
    return addUrlBase('/forgot-password');
  },
  embeddedRegistration() {
    return addUrlBase('/register');
  },
  embeddedProfile() {
    return addUrlBase('/profile');
  },
  embeddedProfileEdit() {
    return addUrlBase('/profile/edit');
  },
  embeddedReservations() {
    return addUrlBase('/profile/reservations');
  },
  embeddedReservation(id: Id, query?: Record<string, unknown>) {
    return addUrlBase(`/profile/reservations/${id}`, query);
  },
  embeddedReservationReceipt(id: Id) {
    return addUrlBase(`/profile/reservations/${id}/receipt`);
  },
  embeddedReservationStore(id: Id) {
    return addUrlBase(`/profile/reservations/${id}/store`);
  },
  embeddedReservationCancel() {
    return addUrlBase('/reservationCancel');
  },
  embeddedIdentification() {
    return addUrlBase('/profile/identification');
  },
  embeddedSettings() {
    return addUrlBase('/settings');
  },
  embeddedPassword() {
    return addUrlBase('/password');
  },
  embeddedReservationReview(reservationData?: any) {
    return addUrlBase(`/reservationReview?${qs.stringify(reservationData)}`);
  },
  embeddedReplaceCart() {
    return addUrlBase('/replaceCart');
  },
  embeddedCartAlerts() {
    return addUrlBase('/cartAlerts');
  },

  product({
    product,
    slug,
    recommended,
    fromMenu,
    bundleSpecialId,
  }: {
    bundleSpecialId?: Id | null;
    fromMenu?: boolean;
    product: ProductNameAndSlugAndId;
    recommended?: boolean;
    slug?: string;
  }) {
    // slug comes from server and used for canonical links for now
    // eventually, product links and canonicals should be the same
    return optionalQueryStringPath(
      `/products/${product.id}/${
        slug
          ? slug
          : kebabCase(`${product.brand ? product.brand : ''}${product.name}`)
      }`,
      { recommended, fromMenu, bundleSpecialId }
    );
  },
  products(query?: {}) {
    return addUrlBase(`/products`, query);
  },

  stores(query: any = '') {
    return addUrlBase('/stores', query);
  },
  store(store: NameAndSlugAndId, query: string | {} = '', slug?: string) {
    return addUrlBase(
      `/stores/${store.id}/${slug ? slug : kebabCase(store.name)}`,
      query
    );
  },
  storeAbout(store: NameAndSlugAndId) {
    return `/stores/${store.id}/${kebabCase(store.name)}/about`;
  },
  storeReviews(store: NameAndSlugAndId, slug?: string) {
    return `/stores/${store.id}/${slug ? slug : kebabCase(store.name)}/reviews`;
  },
  storeMenu(store: NameAndSlugAndId, name: string) {
    return `/stores/${store.id}/${kebabCase(store.name)}/menu/${name}`;
  },
  storeProduct({
    store,
    product,
    recommended,
    fromMenu,
    bundleSpecialId,
    fromSpecialId,
  }: {
    bundleSpecialId?: Id | null;
    fromMenu?: boolean;
    fromSpecialId?: Id | null;
    product: ProductNameAndSlugAndId;
    recommended?: boolean;
    store: NameAndSlugAndId;
  }) {
    return optionalQueryStringPath(
      `/stores/${store.id}/${kebabCase(store.name)}/products/${
        product.id
      }/${kebabCase(`${product.brand ? product.brand : ''}${product.name}`)}`,
      { recommended, fromMenu, bundleSpecialId, fromSpecialId }
    );
  },
  storeSpecial(store: NameAndSlugAndId, special: StoreSpecial) {
    return `/stores/${store.id}/${kebabCase(store.name)}/specials/${
      special.id
    }/${kebabCase(special.title)}`;
  },

  brandProduct({
    brand,
    product,
    recommended,
    fromMenu,
    bundleSpecialId,
  }: {
    brand: NameAndSlugAndId;
    bundleSpecialId?: Id | null;
    fromMenu?: boolean;
    product: ProductNameAndSlugAndId;
    recommended?: boolean;
  }) {
    return optionalQueryStringPath(
      `/brands/${brand.id}/${kebabCase(brand.name)}/products/${
        product.id
      }/${kebabCase(`${product.brand ? product.brand : ''}${product.name}`)}`,
      { recommended, fromMenu, bundleSpecialId }
    );
  },

  category(category: Categories, query?: {}) {
    return addUrlBase(`/categories/${category}`, query);
  },

  collections() {
    return '/collections';
  },

  cart() {
    return addUrlBase('/cart');
  },
  checkout() {
    return addUrlBase('/cart/checkout');
  },
  guestCheckout() {
    return addUrlBase('/cart/guest_checkout');
  },
  dispensaries() {
    return 'https://dispensaries.iheartjane.com';
  },
  brands() {
    return `${this.dispensaries()}/brands`;
  },
  brandProducts(brandId: Id, brandName: string, query?: {}) {
    return addUrlBase(`/brands/${brandId}/${kebabCase(brandName)}`, query);
  },
  invoice(invoiceId: Id) {
    return addUrlBase(`/invoice/${invoiceId}`);
  },
  profile() {
    return addUrlBase('/profile');
  },
  profileSettings() {
    return addUrlBase('/profile/settings');
  },
  profileEdit() {
    return addUrlBase('/profile/edit');
  },
  profilePassword() {
    return addUrlBase('/profile/password');
  },
  profileTermsOfUse() {
    return '/profile/terms';
  },
  profilePrivacyPolicy() {
    return '/profile/policy';
  },
  guestReservationReceipt(cart_uuid: string, query?: {}) {
    return addUrlBase(`/reservation_receipt/${cart_uuid}`, query);
  },
  reservations() {
    return '/profile/reservations';
  },
  reservation(id: Id, query?: Record<string, unknown>) {
    return addUrlBase(`/profile/reservations/${id}`, query);
  },
  reservationStore(id: Id) {
    return `/profile/reservations/${id}/store`;
  },
  reservationReceipt(id: Id) {
    return `/profile/reservations/${id}/receipt`;
  },
  newProductReview({
    productId,
    productName,
    storeId,
    storeName,
    rating,
  }: {
    productId: Id;
    productName: string;
    rating?: number;
    storeId?: Id;
    storeName?: string;
  }) {
    return addUrlBase(
      `/products/${productId}/${encodeURIComponent(productName)}/reviews/new/`,
      { storeId, storeName, rating }
    );
  },
  identification() {
    return addUrlBase('/profile/identification');
  },
  termsOfUse(termsType: TermsOfServiceVariants = 'customer') {
    switch (termsType) {
      case 'customer':
        return '/terms';
      case 'brands':
        return '/brands-terms';
      case 'data':
        return '/data-terms';
      case 'business':
        return '/dispensary-terms';
      default:
        return '/terms';
    }
  },
  accessibility() {
    return '/ada';
  },
  privacyPolicy() {
    return '/privacy-policy';
  },
  doNotSellMyInformation() {
    return '/do-not-sell-my-information';
  },
  contactUs() {
    return '/profile/contactUs';
  },
  forgotPassword() {
    return '/forgot-password';
  },
  resetPassword() {
    return '/reset-password';
  },
  login() {
    return '/login';
  },
  maintenance() {
    return '/maintenance';
  },
  kioskMenu(query: string | {} = '') {
    return addUrlBase('/menu', query);
  },
  kioskLanding() {
    return addUrlBase('/landing');
  },
  kioskSetup() {
    return addUrlBase('/setup');
  },
  kioskThankyou() {
    return addUrlBase('/thankyou');
  },
  kioskQRThankyou(cart_uuid: string) {
    return addUrlBase(`/cart/${cart_uuid}/thankyou`);
  },
  brandEmbedProducts(query: string | {} = '') {
    return addUrlBase('/products', query);
  },
  brandEmbedStores(query: string | {} = '') {
    return addUrlBase('/stores', query);
  },
  brandEmbedStore(store: NameAndSlugAndId, query: string | {} = '') {
    return addUrlBase(`/stores/${store.id}/${kebabCase(store.name)}`, query);
  },
  brandEmbedStoreProduct(
    store: NameAndSlugAndId,
    product: ProductNameAndSlugAndId,
    fromMenu?: boolean,
    bundleSpecialId?: Id | null
  ) {
    return addUrlBase(
      `/stores/${store.id}/${kebabCase(store.name)}/products/${
        product.id
      }/${kebabCase(
        `${product.brand ? product.brand : ''}${product.name}`
      )}/?${qs.stringify({ fromMenu, bundleSpecialId })}`
    );
  },
} as const;
