import AgeGateModal from '../../components/ageGateModal';
import CartAlertsModal from '../../components/cartAlerts';
import ProductSearchModal from '../../components/header/productSearchModal';
import LoginModal from '../../components/loginModal';
import Logout from '../../components/logout';
import RegisterModal from '../../components/registration/container';
import ReplaceCartModal from '../../components/replaceCart';
import ZoomInModal from '../../components/zoomInModal';
import { useCustomerSelector } from '../../customer';

const MODALS = {
  ageGate: AgeGateModal,
  cartAlerts: CartAlertsModal,
  login: LoginModal,
  logout: Logout,
  productSearch: ProductSearchModal,
  register: RegisterModal,
  replaceCart: ReplaceCartModal,
  zoomIn: ZoomInModal,
};

const Modals = () => {
  const { modalName, modalOptions } = useCustomerSelector(
    ({ application: { modalName, modalOptions } }) => ({
      modalName,
      modalOptions,
    })
  );

  if (modalName) {
    const CurrentModal = MODALS[modalName];
    if (!CurrentModal) throw new Error(`Cannot find modal: ${modalName}`);
    return <CurrentModal {...modalOptions} />;
  }

  return null;
};
export default Modals;
