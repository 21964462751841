export const ALPHA_MAP = {
  100: 0.08,
  200: 0.16,
  300: 0.24,
  400: 0.32,
  500: 0.4,
  600: 0.48,
  700: 0.56,
  800: 0.64,
  900: 0.72,
  1000: 0.8,
};
