import {
  Box,
  Flex,
  Modal,
  Typography,
  useMobileMediaQuery,
} from '@jane/reefer';
import type { ComponentColors } from '@jane/reefer';

import { DeselectAllButton } from './deselectAllButton';

export const CONTENT_DIVIDER_PROPS = {
  color: 'grays-ultralight' as ComponentColors,
  height: 16,
  padding: false,
};
export interface ModalSectionProps {
  children: React.ReactNode;
  isPartnerMenu: boolean;
  onDeselectClick: () => void;
  title: string;
}

export const ModalSection = ({
  children,
  isPartnerMenu,
  onDeselectClick,
  title,
}: ModalSectionProps) => {
  const isMobile = useMobileMediaQuery({});

  return (
    <Box width="100%">
      <Modal.ContentDivider {...CONTENT_DIVIDER_PROPS} />
      <Flex flexDirection="column" p={isMobile ? 24 : 40}>
        <Flex justifyContent="space-between" mb={16}>
          <Typography
            variant={isMobile ? 'body' : 'header-bold'}
            branded={!isPartnerMenu && !isMobile}
          >
            {title}
          </Typography>
          <DeselectAllButton onClick={onDeselectClick} />
        </Flex>
        {children && (
          <Flex flexWrap="wrap" gap={16}>
            {children}
          </Flex>
        )}
      </Flex>
    </Box>
  );
};
