import isEmpty from 'lodash/isEmpty';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Modal as RefeerModal } from '@jane/reefer';

import { closeModal } from '../../common/redux/application';
import { useCustomerDispatch } from '../../customer/dispatch';
import {
  clearCart,
  clearRemovedCrmRedemptions,
  deleteUnavailableProducts,
  dismissCheckoutError,
  removeSpecialHasChangedFlag,
  unavailableCheckoutFixed,
} from '../../customer/redux/cart';
import {
  isEmbeddedModeSelector,
  useCustomerSelector,
} from '../../customer/selectors';
import { getUnavailableProductIds } from '../../lib/cart';
import { get } from '../../redux-util/selectors';
import Modal from '../modal';
import CartAlertsForm from './form';

const CartAlerts = ({
  goBack,
  isCheckoutAlert,
  isKiosk,
  onClose,
}: {
  goBack?: () => void;
  isCheckoutAlert?: boolean;
  isKiosk?: boolean;
  onClose?: () => void;
}) => {
  const navigate = useNavigate();
  const { cart, isFixingUnavailableCheckout } = useCustomerSelector(
    get('cart')
  );
  const { store } = useCustomerSelector(get('store'));

  const isEmbeddedMode = useCustomerSelector(isEmbeddedModeSelector);
  const dispatch = useCustomerDispatch();

  const onClick = useCallback(
    (doClose: boolean) => {
      const {
        special_has_changed,
        unavailable_products,
        removed_crm_redemptions,
      } = cart;

      if (unavailable_products.length) {
        const ids = getUnavailableProductIds(unavailable_products as any);
        dispatch(deleteUnavailableProducts(ids));
      }

      if (special_has_changed) {
        dispatch(removeSpecialHasChangedFlag());
      }

      if (removed_crm_redemptions) {
        dispatch(clearRemovedCrmRedemptions());
      }

      if (isCheckoutAlert) {
        dispatch(dismissCheckoutError());
      }

      if (isFixingUnavailableCheckout) {
        dispatch(unavailableCheckoutFixed());

        // cart has been reseted by checkout
        if (cart.uuid === '' && unavailable_products.length === 0) {
          if (goBack) {
            goBack();
          }
          // cart was emptied by the cartdrawer
        } else if (cart.uuid && isEmpty(cart.products)) {
          dispatch(clearCart());
        }
      }

      // if we have an onClose behavior ignore the redux dispatch
      if (doClose && !onClose) {
        dispatch(closeModal());
      } else if (onClose) {
        onClose();
      }
    },
    [navigate]
  );

  if ((isEmbeddedMode || isKiosk) && !isCheckoutAlert) {
    return (
      <div className="former-modal__container">
        <CartAlertsForm
          isFixingUnavailableCheckout={isFixingUnavailableCheckout}
          cart={cart}
          store={store}
          onContinueCheckout={() => onClick(false)}
        />
      </div>
    );
  }

  if (isCheckoutAlert && (isEmbeddedMode || isKiosk)) {
    return (
      <RefeerModal
        appId="root"
        open
        onRequestClose={onClose}
        variant="dialogue"
        overflow="hidden"
      >
        <RefeerModal.Header title="Your bag has changed" />
        <RefeerModal.Content>
          <CartAlertsForm
            noTitle
            isFixingUnavailableCheckout={isFixingUnavailableCheckout}
            cart={cart}
            store={store}
            onContinueCheckout={() => onClick(false)}
          />
        </RefeerModal.Content>
      </RefeerModal>
    );
  }

  return (
    <Modal
      className="modal--small"
      closeModal={() => onClick(true)}
      contentLabel="Cart has changed"
    >
      {() => (
        <div className="modal-content">
          <CartAlertsForm
            isFixingUnavailableCheckout={isFixingUnavailableCheckout}
            cart={cart}
            store={store}
            onContinueCheckout={() => onClick(true)}
          />
        </div>
      )}
    </Modal>
  );
};

export default CartAlerts;
