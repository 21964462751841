import { Box, Loading } from '@jane/reefer';

import {
  CardRowSkeleton,
  CarouselGridSkeleton,
  CarouselListSkeleton,
  CarouselSkeleton,
  FilterSkeleton,
  ProductGridSkeleton,
} from './skeletonComponents';
import {
  MenuProductDetailSkeleton,
  ProductDetailSkeleton,
  ProductSearchSkeleton,
  StoreMenuSkeleton,
} from './skeletonPages';

export interface LoadingWrapperProps {
  children?: React.ReactNode | (() => React.ReactNode);
  isLoading: boolean;
  variant?:
    | 'wavy'
    | 'card-row'
    | 'carousel'
    | 'carousel-list'
    | 'carousel-grid'
    | 'filters'
    | 'product-grid'
    | 'product-detail'
    | 'menu-product-detail'
    | 'product-search'
    | 'store-menu';
}

export const LoadingWrapper = ({
  isLoading,
  children,
  variant = 'wavy',
}: LoadingWrapperProps) => {
  const loader = () => {
    switch (variant) {
      case 'product-search':
        return <ProductSearchSkeleton />;
      case 'product-grid':
        return <ProductGridSkeleton />;
      case 'card-row':
        return <CardRowSkeleton />;
      case 'carousel':
        return <CarouselSkeleton />;
      case 'carousel-list':
        return <CarouselListSkeleton />;
      case 'carousel-grid':
        return <CarouselGridSkeleton />;
      case 'filters':
        return <FilterSkeleton />;
      case 'product-detail':
        return <ProductDetailSkeleton />;
      case 'menu-product-detail':
        return <MenuProductDetailSkeleton />;
      case 'store-menu':
        return <StoreMenuSkeleton />;
      default:
        return (
          <Box width="100%" p={24} position="relative">
            <Loading color="black" />
          </Box>
        );
    }
  };

  if (isLoading)
    return (
      <Box width="100%" ariaLabel="loading">
        {loader()}
      </Box>
    );

  return (
    <Box width="100%">
      {typeof children === 'function' ? children() : children}
    </Box>
  );
};
