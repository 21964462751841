import includes from 'lodash/includes';
import partition from 'lodash/partition';
import sortBy from 'lodash/sortBy';

import type { JaneRefinementItem } from '@jane/search/types';
import type { SearchSort } from '@jane/shared/models';

interface PriorityLists {
  [k: string]: string[];
}
const priorityLists: PriorityLists = {
  default: [],
  productRootType: ['flower', 'vape', 'extract'],
  menuRootType: ['featured', 'flower', 'vape', 'extract'],
  storeType: ['medical', 'recreational', 'pickup', 'delivery'],
  weights: [
    'half gram',
    'gram',
    'two gram',
    'eighth ounce',
    'quarter ounce',
    'half ounce',
    'ounce',
  ],
  lineage: ['indica', 'sativa', 'hybrid', 'cbd'],
};

export type PriorityValue = keyof PriorityLists;

function descendingCountThenAlphaSort(items: JaneRefinementItem[]) {
  return items.sort((a, b) => {
    if (a.count === b.count) {
      if (a.label < b.label) {
        return -1;
      }

      if (a.label > b.label) {
        return 1;
      }

      return 0;
    }

    return b.count - a.count;
  });
}

export function sortSearchFilterItems({
  items,
  priorityValue = 'default',
  sort = 'default',
}: {
  items: JaneRefinementItem[];
  priorityValue?: PriorityValue;
  sort?: SearchSort;
}) {
  if (priorityValue === 'productRootType') {
    items = items.filter((i) => i.label !== 'featured');
  }

  const priorityList = priorityLists[priorityValue] as string[];

  const [priorityItems, otherItems] = partition(items, (i) =>
    includes(priorityList, i.label)
  );
  const sortedPriorityItems = sortBy(priorityItems, (pi) =>
    priorityList.indexOf(pi.label)
  );

  return sortedPriorityItems.concat(
    sort === 'alpha'
      ? sortBy(otherItems, (item) => item.label.toLowerCase())
      : descendingCountThenAlphaSort(otherItems)
  );
}
