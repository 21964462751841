import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { BaseForm } from './baseForm/baseForm';
import type { FormProps as FormPropsShared } from './form.types';

export interface FormProps extends FormPropsShared {
  /** Function called when after after the user modifies any of the inputs for the first time **/
  onDirty?(isDirty: boolean): void;
}

/**
 * Use the `Form` component to wrap form fields and create simpler submittable forms, in which access to
 * `react-hook-form`'s `useForm` hook's methods and state are not needed in the component containing the form.
 *
 * The `useForm` hook is included within the `Form` component.
 *
 * The `Form` component supports an `onDirty` prop, for mooitoring when form is dirty.
 *
 * For more complex forms, in which access to `react-hook-form`'s `useForm` hook's methods and/or state are needed,
 * use [`Form.BaseForm`](/story/components-forms-form-baseform--default), where the `useForm` hook is not
 * included within the component.
 */
export function Form({
  children,
  formErrorName = 'form',
  name,
  onSubmit,
  onDirty,
  autocomplete = 'on',
  testId,
}: FormProps) {
  const formMethods = useForm({
    mode: 'all',
  });
  const {
    formState: { isDirty },
  } = formMethods;

  useEffect(() => {
    onDirty && onDirty(isDirty);
  }, [isDirty, onDirty]);

  return (
    <BaseForm
      formErrorName={formErrorName}
      formMethods={formMethods}
      name={name}
      onSubmit={onSubmit}
      autocomplete={autocomplete}
      testId={testId}
    >
      {children}
    </BaseForm>
  );
}
