import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Checkmark = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.7071 4.29289C23.0976 4.68342 23.0976 5.31658 22.7071 5.70711L9.70711 18.7071C9.31658 19.0976 8.68342 19.0976 8.29289 18.7071L1.29289 11.7071C0.902368 11.3166 0.902368 10.6834 1.29289 10.2929C1.68342 9.90237 2.31658 9.90237 2.70711 10.2929L9 16.5858L21.2929 4.29289C21.6834 3.90237 22.3166 3.90237 22.7071 4.29289Z"
      fill="#0E0E0E"
    />
  </svg>
);
export const CheckmarkIcon = ({ ...props }: DynamicIconProps) => {
  return <Icon icon={Checkmark} {...props} color={props.color} />;
};
