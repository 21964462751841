import {
  showErrorNotification,
  showSuccessNotification,
} from '../common/redux/application';

let dispatch = () => {};

export const setNotificationsDispatch = (newDispatch) => {
  dispatch = newDispatch;
};

export const NotificationsService = {
  success: (message) => {
    dispatch(showSuccessNotification(message));
  },
  error: (errorOrMessage) => {
    const message =
      typeof errorOrMessage === 'string'
        ? errorOrMessage
        : 'Something went wrong';
    dispatch(showErrorNotification(message));
  },
};
