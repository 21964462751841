import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const ButtonArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7071 6.29771C13.0976 6.68824 13.0976 7.3214 12.7071 7.71193L8.70711 11.7119C8.51957 11.8995 8.26522 12.0048 8 12.0048C7.73478 12.0048 7.48043 11.8995 7.29289 11.7119L3.29289 7.71193C2.90237 7.3214 2.90237 6.68824 3.29289 6.29771C3.68342 5.90719 4.31658 5.90719 4.70711 6.29771L8 9.59061L11.2929 6.29772C11.6834 5.90719 12.3166 5.90719 12.7071 6.29771Z"
      fill="#0E0E0E"
    />
  </svg>
);
export const ButtonArrowIcon = ({ ...props }: DynamicIconProps) => {
  return <Icon icon={ButtonArrow} {...props} color={props.color} />;
};
