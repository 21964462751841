import { useEffect, useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { ReeferThemeProvider, styled } from '@jane/reefer';
import { LoadingWrapper } from '@jane/shared-ecomm/components';
import { useCurrentStoreId } from '@jane/shared-ecomm/hooks';
import { UserPreferencesProvider } from '@jane/shared-ecomm/providers';
import { parseSearch } from '@jane/shared-ecomm/util';
import type { Store } from '@jane/shared/models';
import { useRuntimeConfig } from '@jane/shared/runtime-config';
import { StoreScripts } from '@jane/shared/scripts';

import {
  setAppMode,
  useCustomerDispatch,
  useCustomerSelector,
} from '../../customer';
import { updateQueryPromoCode, updateTags } from '../../customer/redux/cart';
import { whoami } from '../../customer/redux/customer';
import { setPartner, verifyStore } from '../../customer/redux/embeddedApp';
import type { NoStore } from '../../customer/redux/store';
import { isNoStore } from '../../customer/redux/store';
import {
  getReservationsByStore,
  setPreviousPath,
} from '../../customer/redux/users';
import { useEmbeddedOptionsListener } from '../../hooks/useEmbeddedOptionsListener';
import { useForceClientRefresh } from '../../hooks/useForceClientRefresh';
import { useRecordPageView } from '../../hooks/useRecordPageView';
import { getStoreTheme } from '../../lib/getTheme';
import { get } from '../../redux-util/selectors';
import { PendingCartDrawer } from '../../shared-components';
import { flex, flexItem, spacing } from '../../style';
import { DEFAULT_THEME } from '../../theming';
import { IdentifyCustomer } from '../../tracking';
import { SetJaneDeviceId } from '../SetJaneDeviceId';
import { ApplicationGates } from '../applicationGates';
import CheckAuthTimer from '../checkAuthTimer';
import EmbeddedHeader from '../embeddedMenuApp/header';
import ServerNotifications from '../notifications/server';
import { ScrollPositionProvider } from '../storeDetail/scrollPositionProvider';
import { PaginationCountProvider } from '../storeMenu/providers/paginationCountProvider';

const MainContainer = styled.div(spacing({ mx: 8 }));
const EmbeddedOrWhiteLabelContainer = styled.div(
  spacing({ pb: 24 }),
  flex({ flexDirection: 'column' }),
  flexItem({ grow: true })
);
const buildTheme = (store: Store | NoStore) => {
  if (isNoStore(store) || !store.white_label_enabled) {
    return DEFAULT_THEME;
  }
  return getStoreTheme(store);
};

export const FramelessEmbedAppContainer = () => {
  useForceClientRefresh();
  useRecordPageView();
  const dispatch = useCustomerDispatch();
  const location = useLocation();
  const { theme } = useEmbeddedOptionsListener();
  const { isPartnerHosted } = useRuntimeConfig();
  const initialStoreId = useCurrentStoreId();

  const { authenticated, sessionChecked } = useCustomerSelector(
    get('customer')
  );
  const { hasLoaded: hasLoadedCart } = useCustomerSelector(get('cart'));
  const {
    hasLoaded: hasLoadedStore,
    isLoadingStore,
    store,
  } = useCustomerSelector(get('store'));
  const { hasVerified, partnerId } = useCustomerSelector(get('embeddedApp'));

  const isLoading =
    !sessionChecked ||
    !hasLoadedCart ||
    !hasLoadedStore ||
    isLoadingStore ||
    !hasVerified;

  const previousPathRef = useRef(location.pathname);

  useEffect(() => {
    dispatch(setPreviousPath(previousPathRef.current));
    previousPathRef.current = location.pathname;
  }, [location.pathname]);

  useEffect(() => {
    dispatch(setAppMode('framelessEmbed'));
  }, []);

  useEffect(() => {
    const query = parseSearch(window.location.search);
    dispatch(updateTags(query));
    dispatch(updateQueryPromoCode(query.promo_code));

    dispatch(setPartner({ id: initialStoreId, name: '' }));
    dispatch(
      verifyStore({
        storeId: initialStoreId,
        options: {
          forceEmbed: !!query.embed,
          isPartnerHosted,
          framelessEmbed: true,
        },
      })
    );
    const maybeRef = query.ref;
    const ref = typeof maybeRef === 'string' ? maybeRef : undefined;
    dispatch(whoami({ ref }, true));
    const body = document.querySelector('body');
    if (body) {
      body.classList.add('embedded'); // set fixed modal height for embedded/whiteLabel. see layout.css
    }
  }, []);

  useEffect(() => {
    if (authenticated && partnerId) {
      dispatch(getReservationsByStore(partnerId));
    }
  }, [authenticated, partnerId]);

  const shadowHost = document.getElementById('shadow-host')?.shadowRoot;
  const drawerContainer = shadowHost?.getElementById(
    'shadow-app-sibling'
  ) as HTMLElement;

  return (
    <LoadingWrapper isLoading={isLoading}>
      <StoreScripts storeId={partnerId} />
      <CheckAuthTimer />
      <SetJaneDeviceId />

      <IdentifyCustomer
        app="embed"
        appStoreId={initialStoreId}
        queryParams={parseSearch(window.location.search)}
      />
      <PaginationCountProvider>
        <ScrollPositionProvider>
          <ReeferThemeProvider theme={theme || buildTheme(store)}>
            <UserPreferencesProvider>
              <PendingCartDrawer container={drawerContainer} />
              {/*TODO: can ReeferThemeProvider get moved to the top level?*/}
              <ApplicationGates />

              <ServerNotifications />
              <EmbeddedOrWhiteLabelContainer>
                <EmbeddedHeader store={isNoStore(store) ? undefined : store} />
                <MainContainer>
                  <LoadingWrapper isLoading={isLoading}>
                    <Outlet />
                  </LoadingWrapper>
                </MainContainer>
              </EmbeddedOrWhiteLabelContainer>
            </UserPreferencesProvider>
          </ReeferThemeProvider>
        </ScrollPositionProvider>
      </PaginationCountProvider>
    </LoadingWrapper>
  );
};
