import upperFirst from 'lodash/upperFirst';

import type {
  DeepReadonly,
  DeliveryValidation,
  ReservationMode,
  Store,
} from '@jane/shared/models';
import { formatCurrency } from '@jane/shared/util';

import minimumDeliveryOrder from './minimumDeliveryOrder';
import type OrderingSchedule from './orderingSchedule';

/* eslint-disable max-statements, max-params */
export default function (
  store: Store,
  orderingSchedule: DeepReadonly<OrderingSchedule>,
  cartSubtotal: number,
  reservationMode: ReservationMode,
  deliveryValidateData?: DeliveryValidation | undefined
) {
  const genericMessage = `You can't proceed to checkout because ${store.name} is closed`;
  const nicerMessage = `${store.name} online ordering is closed until`;

  if (orderingSchedule.isAlwaysClosed) {
    return `${genericMessage} for pre-ordering.`;
  }

  if (
    orderingSchedule.isCurrentlyClosed &&
    !orderingSchedule.allowOffHoursOrdering
  ) {
    if (orderingSchedule.today && orderingSchedule.willOpenLaterToday) {
      return `${nicerMessage} ${orderingSchedule.today.displayOpeningTime}.`;
    }

    const nextOpenDay = orderingSchedule.nextOpenDay;
    if (nextOpenDay) {
      return `${nicerMessage} ${nextOpenDay.displayOpeningTime} on ${upperFirst(
        nextOpenDay.name
      )}.`;
    }

    return genericMessage;
  }

  if (
    !orderingSchedule.isCurrentlyAcceptingReservations(reservationMode) &&
    !orderingSchedule.allowOffHoursOrdering
  ) {
    return `You can't proceed to checkout because ${store.name} is closing soon.`;
  }

  if (
    deliveryValidateData &&
    !minimumDeliveryOrder({
      discountedSubtotal: cartSubtotal,
      store,
      deliveryValidateData,
    })
  ) {
    return `${
      store.name
    } requires a minimum delivery purchase of ${formatCurrency(
      deliveryValidateData?.minimum || 0
    )}.`;
  }

  return undefined;
}
