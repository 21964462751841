import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Twitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.1543 8.32617C19.8574 7.79883 20.4902 7.16602 20.9824 6.42773C20.3496 6.70898 19.6113 6.91992 18.873 6.99023C19.6465 6.5332 20.209 5.83008 20.4902 4.95117C19.7871 5.37305 18.9785 5.68945 18.1699 5.86523C17.4668 5.12695 16.5176 4.70508 15.4629 4.70508C13.4238 4.70508 11.7715 6.35742 11.7715 8.39648C11.7715 8.67773 11.8066 8.95898 11.877 9.24023C8.81836 9.06445 6.07617 7.58789 4.24805 5.37305C3.93164 5.90039 3.75586 6.5332 3.75586 7.23633C3.75586 8.50195 4.38867 9.62695 5.4082 10.2949C4.81055 10.2598 4.21289 10.1191 3.7207 9.83789V9.87305C3.7207 11.666 4.98633 13.1426 6.67383 13.4941C6.39258 13.5645 6.04102 13.6348 5.72461 13.6348C5.47852 13.6348 5.26758 13.5996 5.02148 13.5645C5.47852 15.041 6.84961 16.0957 8.4668 16.1309C7.20117 17.1152 5.61914 17.7129 3.89648 17.7129C3.58008 17.7129 3.29883 17.6777 3.01758 17.6426C4.63477 18.6973 6.56836 19.2949 8.67773 19.2949C15.4629 19.2949 19.1543 13.7051 19.1543 8.81836C19.1543 8.64258 19.1543 8.50195 19.1543 8.32617Z"
      fill="#0E0E0E"
    />
  </svg>
);
export const TwitterIcon = ({ ...props }: DynamicIconProps) => {
  return <Icon icon={Twitter} {...props} color={props.color} />;
};
