import type { PropsWithoutRef } from 'react';

import { Typography, css, styled } from '@jane/reefer';

import { flexItem, spacing } from '../../style';
import InputControl from './input-control';
import { useTextField } from './text-field-context';
import type { Variant } from './text-input';

type InputProps = {
  variant?: Variant;
};

const material = ({ theme }) =>
  css({
    ...spacing({ py: 8, px: 0 }),
    width: '100%',
    display: 'block',
    outline: 'none',
    background: 'transparent',
    border: '0',
    '&:disabled': {
      color: theme.colors.grays.light,
    },
    '&::placeholder': {
      color: theme.colors.grays.mid,
      opacity: 0,
      transition: 'opacity 200ms ease-in-out',
    },
    '&:focus::placeholder': {
      opacity: 1,
    },
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 100px white inset',
    },
  });

const border = ({ theme }) =>
  css({
    border: `1px solid ${theme.colors.grays.light}`,
    boxSizing: 'border-box',
    ...flexItem({ grow: true }),
    ...spacing({ px: 8, my: 8 }),
    backgroundColor: theme.colors.grays.white,
    borderRadius: 4,
    height: 40,
    width: '100%',
    WebkitAppearance: 'none',
    '&::-ms-clear': {
      display: 'none',
    },
    '&::-webkit-search-cancel-button': {
      display: 'none',
    },
    '&:-webkit-autofill': {
      boxShadow: '0 0 0 100px white inset',
    },
    '::placeholder': {
      color: theme.colors.grays.mid,
    },
    ':disabled': {
      color: theme.colors.grays.mid,
      backgroundColor: theme.colors.grays.ultralight,
    },
  });

export const Input = styled.input<InputProps>(({ theme, variant }) => {
  switch (variant) {
    case 'material':
      return material({ theme });

    case 'border':
      return border({ theme });

    default:
      break;
  }
});

const InputContainer = ({
  ...props
}: PropsWithoutRef<JSX.IntrinsicElements['input']>) => {
  const { inputRef, variant } = useTextField();

  return (
    <InputControl disabled={props.disabled}>
      <Typography as="div" variant="body">
        <Input ref={inputRef} variant={variant} {...props} />
      </Typography>
    </InputControl>
  );
};

export default InputContainer;
