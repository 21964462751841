import { useCustomerSelector } from '../../customer/selectors';
import { get } from '../../redux-util/selectors';
import type { IdentificationProps } from './idUploader';
import DeluxeIdentificationUploader from './idUploader';
import { WarningMessage } from './warningMessage';

const VeteranIdentification = ({
  showValidationErrors,
  onIdUploadAttempt,
}: IdentificationProps) => {
  const { document, loading_veteran_photo } = useCustomerSelector(
    get('identification')
  );

  if (!document) return null;

  return (
    <>
      <DeluxeIdentificationUploader
        imageKey="veteran_photo"
        inputName="government-id"
        label="Upload Veteran ID"
        document={document}
        isLoading={loading_veteran_photo}
        showValidationErrors={showValidationErrors}
        onIdUploadAttempt={onIdUploadAttempt}
      />
      {!document.veteran_photo && (
        <WarningMessage>ID required for discount</WarningMessage>
      )}
    </>
  );
};

export default VeteranIdentification;
