import type { PDFDocumentProxy, PDFPageProxy } from 'pdfjs-dist';
import PDFJS from 'pdfjs-dist';

import { trackError } from '@jane/shared/util';

// Using external CDN for delivery of worker as described here: https://github.com/wojtekmaj/react-pdf#standard-browserify-and-others
// Importing pdfjs from `pdfjs-dist/webpack` should provide a local worker but did not functioning as expected
// Some links to relevant issues are included below
// https://github.com/mozilla/pdf.js/issues/10952
// https://gitmemory.com/issue/wojtekmaj/react-pdf/67/508944113
PDFJS.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDFJS.version}/pdf.worker.js`;

//Takes a file and converts to an image if input is a pdf
export const convertIfPDF = async function (file: string): Promise<string> {
  if (file.includes('application/pdf')) {
    return await PDFJS.getDocument(file).promise.then(
      (pdf: PDFDocumentProxy) =>
        pdf.getPage(1).then((page: PDFPageProxy) => {
          const viewport = page.getViewport({ scale: 1.5 });
          const canvas: HTMLCanvasElement = document.createElement('canvas');
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          //Get the canvasContext from the canvas, if null create a new CanvasRenderingContext
          const context: CanvasRenderingContext2D =
            canvas.getContext('2d') || new CanvasRenderingContext2D();
          const task = page.render({
            canvasContext: context,
            viewport,
          });
          return task.promise.then(() => {
            return canvas.toDataURL('image/jpeg');
          });
        }),
      //if conversion fails, return the file for backend processing
      (e) => {
        trackError(e, { file: file.slice(0, 100) });
        return file;
      }
    );
  } else {
    //if the file is not a PDF, return for backend processing
    return file;
  }
};
