import type { InitialQuery, SearchState } from '@jane/search/types';
import type { AppMode, DeepReadonly } from '@jane/shared/models';

import { resetInitialQuery } from '../customer/redux/initialQuery';
import type { CustomerDispatch } from '../customer/redux/types';

export const formatInitialQuery = (
  initialQuery: DeepReadonly<InitialQuery>
): SearchState => ({
  // we only want to add items to refinementList
  // if there is a value, otherwise we omit the
  // entire property from the object.
  refinementList: {
    ...(initialQuery?.category?.value && {
      category: [...initialQuery.category.value],
    }),
    ...(initialQuery?.brand?.value && {
      brand: [...initialQuery.brand.value],
    }),
    ...(initialQuery?.brand_subtype?.value && {
      brand_subtype: [...initialQuery.brand_subtype.value],
    }),
    ...(initialQuery?.root_types?.value && {
      root_types: [...initialQuery.root_types.value],
    }),
    ...(initialQuery?.store_type?.value && {
      store_types: [...initialQuery.store_type.value],
    }),
  },
  query:
    initialQuery.query && initialQuery.query.value
      ? initialQuery.query.value[0]
      : '',
});

export const callResetInitialQuery = (
  appMode: AppMode,
  dispatch: CustomerDispatch,
  initialQuery?: InitialQuery
) => {
  if (
    ['operatorEmbed', 'kiosk', 'brandEmbed'].includes(appMode) &&
    initialQuery
  ) {
    dispatch(resetInitialQuery());
  }
};
