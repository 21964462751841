import type { StarRange } from '../../types/starRange';

export const REVIEWS_REQUIRED_BEFORE_DISPLAY = 5;

export const POSSIBLE_RATINGS: StarRange[] = ['5', '4', '3', '2', '1'];

export type ProductInfo = {
  productId: number;
  productName: string;
  storeId?: string | number;
  storeName?: string;
};
