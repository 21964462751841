import pluralise from 'pluralise';

import { Typography, styled, useTheme } from '@jane/reefer';
import type { SearchState } from '@jane/search/types';
import { JANE_DEFINED_ROWS } from '@jane/search/util';
import { EventNames } from '@jane/shared-ecomm/tracking';
import { generateCustomLabel } from '@jane/shared-ecomm/util';
import type { AdData, Store } from '@jane/shared/models';
import { titleCase } from '@jane/shared/util';

import type { MediaQueryState } from '../../common/providers/mediaQueryProvider';
import { SvgIcon } from '../../component-library/svg-icon';
import { appModeSelector, useCustomerSelector } from '../../customer/selectors';
import { usePDPDetector } from '../../hooks/usePDPDetector';
import { flex, spacing } from '../../style';
import { Box } from '../../style/layout';
import { SponsoredLabel } from './ad';
import { SeeAllLinkWrapper } from './productKindBucketCarousel';
import { Count } from './productKindBucketNonCarousel';

const BucketHeaderContainer = styled.div<{ isSingleLineAdHeader?: boolean }>(
  spacing({ px: 4, pb: 32 }),
  ({ isSingleLineAdHeader }) =>
    flex({
      justifyContent: 'space-between',
      flexDirection: isSingleLineAdHeader ? 'column' : 'row',
    })
);

const CustomRowLabelContainer = styled.div(
  flex({ justifyContent: 'space-between' })
);

const CountAndSeeAll = styled.div<{ isAd?: boolean }>(
  flex({ alignItems: 'center' }),
  {
    position: 'relative',
    flexShrink: 0,
  },
  ({ isAd }) => isAd && spacing({ pt: 16 })
);

const CollapseButton = styled.button(
  flex({ alignItems: 'center', justifyContent: 'center' })
);

interface Props {
  count: number;
  expanded?: boolean;
  flightProps?: AdData['flight'];
  isAd?: boolean;
  isDiscoverMenuProducts?: boolean;
  isKiosk: boolean;
  media: MediaQueryState;
  name: string;
  onCollapse?: () => void;
  placementIndex: number;
  searchState: SearchState;
  showSeeAll?: boolean;
  store: Store;
}

const BucketHeader = ({
  media,
  count,
  searchState,
  isKiosk,
  store,
  expanded,
  onCollapse,
  name,
  showSeeAll,
  placementIndex,
  isAd,
  flightProps,
  isDiscoverMenuProducts,
}: Props) => {
  const appMode = useCustomerSelector(appModeSelector);
  const isPDP = usePDPDetector();
  const theme = useTheme();

  if (isPDP) {
    showSeeAll = false;
  }

  const getLabel = () => {
    const customRowDisplayName = store.custom_product_type_labels[name];
    // NOTE(elliot): If jane defined rows' custom labels are the default ie. best_selling or sale, then it should get title cased.
    const customRow =
      !JANE_DEFINED_ROWS.includes(customRowDisplayName) && customRowDisplayName;

    return generateCustomLabel({
      appMode,
      store,
      attribute: name,
      fallback: isAd
        ? flightProps?.custom_title || flightProps?.product_brand.name
        : customRow
        ? customRow
        : titleCase(name),
    });
  };

  return (
    <BucketHeaderContainer isSingleLineAdHeader={isAd}>
      {isAd && (
        <CustomRowLabelContainer>
          {!isDiscoverMenuProducts && (
            <SponsoredLabel isDesktop={media.desktop} textColor="grays-mid">
              Sponsored
            </SponsoredLabel>
          )}

          {!isPDP && (
            <SeeAllLinkWrapper
              searchState={searchState}
              isKiosk={isKiosk}
              store={store}
              flightProps={flightProps}
              trackingInfo={{
                event: EventNames.ClickedSeeAll,
                category: name,
                linkLocation: 'see all link',
                numberOfItems: count,
                flightId: flightProps?.id,
                creativeIds: flightProps?.creative_ids,
                productBrandId: flightProps?.product_brand.id,
                placementIndex,
              }}
            >
              {`See All ${name}`}
            </SeeAllLinkWrapper>
          )}
        </CustomRowLabelContainer>
      )}

      {isPDP && isAd ? (
        <Typography
          as="h2"
          color="grays-black"
          branded={appMode === 'default'}
          variant="header-bold"
        >
          {isDiscoverMenuProducts
            ? 'Discover additional products'
            : 'You may also like'}
        </Typography>
      ) : (
        <Typography
          color="grays-black"
          as="h2"
          branded={appMode === 'default'}
          variant="header-bold"
        >
          <SeeAllLinkWrapper
            searchState={searchState}
            isKiosk={isKiosk}
            store={store}
            ariaLabel={isAd ? 'sponsored content' : 'see all'}
            flightProps={flightProps}
            trackingInfo={{
              event: EventNames.ClickedSeeAll,
              category: name,
              linkLocation: 'bucket title',
              numberOfItems: count,
              flightId: flightProps?.id,
              creativeIds: flightProps?.creative_ids,
              productBrandId: flightProps?.product_brand.id,
              placementIndex,
            }}
            variant="minimal"
          >
            {getLabel()}
          </SeeAllLinkWrapper>
        </Typography>
      )}

      {!isAd && (
        <CountAndSeeAll isAd={isAd}>
          <Count>{`${count} ${pluralise.withCount(count, 'Item')}`}</Count>

          {(expanded || showSeeAll) && (
            <Box mx={8}>
              <Count>|</Count>
            </Box>
          )}

          {expanded ? (
            <CollapseButton onClick={onCollapse}>
              <SvgIcon
                icon="times"
                color={theme.colors.primary.main}
                size={18}
              />
            </CollapseButton>
          ) : showSeeAll ? (
            <SeeAllLinkWrapper
              searchState={searchState}
              isKiosk={isKiosk}
              store={store}
              flightProps={flightProps}
              trackingInfo={{
                event: EventNames.ClickedSeeAll,
                category: name,
                linkLocation: 'see all link',
                numberOfItems: count,
                flightId: flightProps?.id,
                creativeIds: flightProps?.creative_ids,
                productBrandId: flightProps?.product_brand.id,
                placementIndex,
              }}
            >
              See All
            </SeeAllLinkWrapper>
          ) : null}
        </CountAndSeeAll>
      )}
    </BucketHeaderContainer>
  );
};

export default BucketHeader;
