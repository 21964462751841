import type { MotionProps } from 'framer-motion';
import { AnimatePresence, motion } from 'framer-motion';
import type { ReactNode } from 'react';

import { styled } from '@jane/reefer';

import openCollapsed from '../styles/animations/open-collapsed';
import { useItemExpanded, useItemId } from './accordion-item';

const Content = styled(motion.div)({
  overflow: 'hidden',
});

type Props = {
  children: ReactNode;
};

const AccordionContent = ({ children, ...props }: Props & MotionProps) => {
  const isExpanded = useItemExpanded();
  const id = useItemId();

  return (
    <AnimatePresence initial={false}>
      {isExpanded && (
        <Content
          id={`accordion-content-${id}`}
          data-testid={`accordion-content-${id}`}
          key={`accordion-content-${id}`}
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={openCollapsed}
          role="region"
          aria-labelledby={`accordion-heading-${id}`}
          {...props}
        >
          {children}
        </Content>
      )}
    </AnimatePresence>
  );
};

export default AccordionContent;
