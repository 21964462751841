import { Button, Typography } from '@jane/reefer';

import { UnlockWalletWrapper } from '../customerLoyaltyPoints.styles';

const formatPhoneNumber = (phone: string) => {
  const match = phone.match(/^(\d{3})(\d{3})(\d{4})$/);

  return match
    ? `${match[1].toString()}-${match[2].toString()}–${match[3].toString()}`
    : null;
};

export const InitiateUnlockWallet = ({
  phone,
  onSendCode,
  loading,
}: {
  loading: boolean;
  onSendCode: (e: React.MouseEvent) => void;
  phone: string;
}) => (
  <UnlockWalletWrapper>
    <Typography variant="body">
      Please confirm your identity by entering a verification code sent to the
      following phone number:{' '}
    </Typography>
    <Typography variant="body-bold">{formatPhoneNumber(phone)}.</Typography>
    <Button
      variant="secondary"
      onClick={onSendCode}
      loading={loading}
      css={{ marginTop: 10, width: '100%' }}
      label="Send code"
    />
  </UnlockWalletWrapper>
);
