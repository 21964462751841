import { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { Box, Flex, shadows, spacing, styled, useTheme } from '@jane/reefer';
import { PoweredByJane } from '@jane/shared-ecomm/components';
import { postMessageToIframeParent } from '@jane/shared-ecomm/util';
import type { Store } from '@jane/shared/models';
import { useRuntimeConfig } from '@jane/shared/runtime-config';

import { SvgIcon } from '../../component-library/svg-icon';
import { useCustomerSelector } from '../../customer/selectors';
import { inIframe } from '../../lib/iframe';
import { paths } from '../../lib/routes';
import { get } from '../../redux-util/selectors';
import { PaginationCountContext } from '../storeMenu/providers/paginationCountProvider';
import EmbeddedButton from './headerButton';
import UserActions from './userActions';

export const EmbeddedHeaderContainer = styled(Flex)<{ clearZIndex?: boolean }>(
  ({ clearZIndex }) => ({
    ...spacing({ px: 24 }),
    height: 50,
    width: '100%',
    zIndex: !clearZIndex ? 420 : 'initial',
    boxShadow: shadows.divider,
  })
);

interface SubdomainHeaderProps {
  store?: Store;
}

const SubdomainHeader = ({ store }: SubdomainHeaderProps) => {
  const theme = useTheme();
  const rootDomain = window.location.host.split('.').slice(-2).join('.');
  const parentUrl =
    store?.subdomain_setting?.home_url ||
    `${window.location.protocol}//${rootDomain}`;
  const { isPartnerHosted } = useRuntimeConfig();

  if (inIframe() || !store || isPartnerHosted) return null;

  return (
    <Flex m={16} alignItems="center" justifyContent="space-between">
      {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={parentUrl}
        aria-label="Store photo link"
        css={{
          width: 50,
          height: 50,
          backgroundImage: `url(${store.photo})`,
          backgroundSize: 'cover',
        }}
      />
      <a
        css={{ color: theme.colors.grays.mid }}
        target="_blank"
        rel="noopener noreferrer"
        href={parentUrl}
      >
        <Flex alignItems="center">
          <SvgIcon
            color={theme.colors.grays.mid}
            size={{ height: 16 }}
            icon="chevron"
            rotate="180"
          />
          <span css={spacing({ ml: 4 })}>Back home</span>
        </Flex>
      </a>
    </Flex>
  );
};

interface Props {
  store?: Store;
}

const EmbeddedHeader = ({ store }: Props) => {
  const { setPaginationCount } = useContext(PaginationCountContext);
  const { appMode } = useCustomerSelector(get('embeddedApp'));
  const { isPartnerHosted } = useRuntimeConfig();
  const menuUrlTest = `^\\/embed\\/stores\\/\\d{1,}\\/?(\\bmenu\\b.*)?$`;
  const menuUrlRegex = RegExp(menuUrlTest, 'i');
  const isMenuPage = menuUrlRegex.test(window.location.pathname);
  const location = useLocation();

  if (location.pathname.includes('checkout')) {
    return null;
  }

  return (
    <>
      <SubdomainHeader store={store} />
      <EmbeddedHeaderContainer
        alignItems="center"
        clearZIndex={isPartnerHosted}
      >
        {!isPartnerHosted && !isMenuPage && (
          <Box mr={8}>
            <EmbeddedButton
              onClick={() => {
                postMessageToIframeParent({
                  messageType: 'shrinkNextResize',
                });
                setPaginationCount(1);
              }}
              to={paths.embeddedMenu(false)}
            >
              Menu
            </EmbeddedButton>
          </Box>
        )}
        {!store?.white_label_enabled &&
          appMode !== 'operatorEmbed' &&
          !isPartnerHosted && <PoweredByJane />}
        <Box ml="auto">
          <UserActions />
        </Box>
      </EmbeddedHeaderContainer>
    </>
  );
};

export default EmbeddedHeader;
