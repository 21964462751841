import type { ButtonVariant } from '../components/button/button.types';
import type {
  BorderRadiusKeys,
  ButtonStyles,
  CardStyles,
  ComponentColors,
  Components,
  ReeferTheme,
} from '../types';

/**
 * Color Helpers
 */
export const getColorValue = (color: ComponentColors, theme: ReeferTheme) => {
  switch (color) {
    case 'primary':
      return theme.colors.primary.main;
    case 'primary-light':
      return theme.colors.primary.light;
    case 'primary-dark':
      return theme.colors.primary.dark;
    case 'secondary':
      return theme.colors.secondary.main;
    case 'secondary-light':
      return theme.colors.secondary.light;
    case 'secondary-dark':
      return theme.colors.secondary.dark;
    case 'info':
      return theme.colors.info.main;
    case 'info-light':
      return theme.colors.info.light;
    case 'info-dark':
      return theme.colors.info.dark;
    case 'success':
      return theme.colors.success.main;
    case 'success-light':
      return theme.colors.success.light;
    case 'success-dark':
      return theme.colors.success.dark;
    case 'error':
      return theme.colors.error.main;
    case 'error-light':
      return theme.colors.error.light;
    case 'error-dark':
      return theme.colors.error.dark;
    case 'text-main':
      return theme.colors.text.main;
    case 'text-inverse':
      return theme.colors.text.inverse;
    case 'text-info':
      return theme.colors.text.info;
    case 'gold':
      return theme.colors.brand.gold.main;
    case 'gold-light':
      return theme.colors.brand.gold.light;
    case 'gold-dark':
      return theme.colors.brand.gold.dark;
    case 'tangerine':
      return theme.colors.brand.tangerine.main;
    case 'tangerine-light':
      return theme.colors.brand.tangerine.light;
    case 'tangerine-dark':
      return theme.colors.brand.tangerine.dark;
    case 'ember':
      return theme.colors.brand.ember.main;
    case 'ember-light':
      return theme.colors.brand.ember.light;
    case 'ember-dark':
      return theme.colors.brand.ember.dark;
    case 'sunset':
      return theme.colors.brand.sunset.main;
    case 'sunset-light':
      return theme.colors.brand.sunset.light;
    case 'sunset-dark':
      return theme.colors.brand.sunset.dark;
    case 'grape':
      return theme.colors.brand.grape.main;
    case 'grape-light':
      return theme.colors.brand.grape.light;
    case 'grape-dark':
      return theme.colors.brand.grape.dark;
    case 'purple':
      return theme.colors.brand.purple.main;
    case 'purple-light':
      return theme.colors.brand.purple.light;
    case 'purple-dark':
      return theme.colors.brand.purple.dark;
    case 'emerald':
      return theme.colors.tertiary.emerald.main;
    case 'emerald-light':
      return theme.colors.tertiary.emerald.light;
    case 'emerald-dark':
      return theme.colors.tertiary.emerald.dark;
    case 'palm':
      return theme.colors.tertiary.palm.main;
    case 'palm-light':
      return theme.colors.tertiary.palm.light;
    case 'palm-dark':
      return theme.colors.tertiary.palm.dark;
    case 'seafoam':
      return theme.colors.tertiary.seafoam.main;
    case 'seafoam-light':
      return theme.colors.tertiary.seafoam.light;
    case 'seafoam-dark':
      return theme.colors.tertiary.seafoam.dark;
    case 'pacific':
      return theme.colors.tertiary.pacific.main;
    case 'pacific-light':
      return theme.colors.tertiary.pacific.light;
    case 'pacific-dark':
      return theme.colors.tertiary.pacific.dark;
    case 'grays-white':
      return theme.colors.grays.white;
    case 'grays-ultralight':
      return theme.colors.grays.ultralight;
    case 'grays-light':
      return theme.colors.grays.light;
    case 'grays-mid':
      return theme.colors.grays.mid;
    case 'grays-dark':
      return theme.colors.grays.dark;
    case 'grays-black':
      return theme.colors.grays.black;
    case 'transparent':
      return 'transparent';
    default:
      return 'inherit';
  }
};

const lightBackgroundColors: ComponentColors[] = [
  'primary-light',
  'secondary',
  'secondary-light',
  'info-light',
  'error',
  'error-light',
  'success',
  'success-light',
  'text-inverse',
  'text-info',
  'gold',
  'gold-light',
  'tangerine',
  'tangerine-light',
  'ember',
  'ember-light',
  'sunset-light',
  'grape-light',
  'purple-light',
  'emerald-light',
  'palm-light',
  'seafoam',
  'seafoam-light',
  'pacific-light',
  'grays-white',
  'grays-ultralight',
  'grays-light',
  'transparent',
];

export const textColorForBackgroundColor = (
  background: ComponentColors,
  theme: ReeferTheme
) =>
  lightBackgroundColors.includes(background)
    ? theme.colors.text.main
    : theme.colors.text.inverse;

/**
 * Border Radius Helpers
 */

export const getBorderRadiusValue = (
  borderRadiusKey: BorderRadiusKeys | string,
  theme: ReeferTheme
) => {
  const valueFromTheme =
    theme.borderRadius[borderRadiusKey as BorderRadiusKeys];
  return valueFromTheme || borderRadiusKey;
};

/** Style Configuration Helpers */

/**
 * Returns component styles for a given component from theme.components
 *
 * This will be the default styles (defined in theme/components/*) unless
 * the user has passed custom component styles in their ReeferThemeProvider.
 */

export function getComponentStyles(
  component: 'Button',
  theme: ReeferTheme,
  variant?: ButtonVariant
): ButtonStyles;

export function getComponentStyles(
  component: 'Card',
  theme: ReeferTheme,
  variant?: never
): CardStyles;

export function getComponentStyles(
  component: keyof Components,
  theme: ReeferTheme,
  variant?: ButtonVariant
) {
  if (component === 'Button' && variant) {
    const baseStyles = theme.components['Button']['variants'].shared;
    const buttonStyles = theme.components['Button']['variants'][variant];
    return { ...baseStyles, ...buttonStyles };
  }

  /**
   * Note:
   * Someday it might be nice to return all of these values already mapped to the appropriate
   * values (via getColorValue and getBorderRadiusValue, etc)
   */
  return theme.components['Card'].styles;
}
