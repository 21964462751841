import isArray from 'lodash/isArray';

import { StarIcon } from '@jane/reefer';

import { formatFilterLabel } from './formatFilterLabel';
import { getPriceLabel } from './getPriceLabel';
import { getWeightLabel } from './getWeightLabel';

interface ActiveFilterOptions {
  customLabels?: { [label: string | number]: string };
  storeId?: string | number;
}

export const formatActiveFilterLabel = (
  filterKey: string,
  filterItems: string[],
  options?: ActiveFilterOptions
) => {
  const { customLabels, storeId } = options || {};

  const itemLabelToRender = (labelValue: string) => {
    if (filterKey === 'brand') {
      return labelValue;
    }

    if (filterKey === 'available_weights') {
      return getWeightLabel(labelValue);
    }

    if (filterKey === 'bucket_price') {
      return getPriceLabel(labelValue);
    }

    if (filterKey === 'aggregate_rating') {
      return `${labelValue.split(':')[0]} and up`;
    }

    return formatFilterLabel(labelValue, customLabels, storeId);
  };

  return isArray(filterItems)
    ? filterItems?.map((filter) => {
        return {
          icon: filterKey === 'aggregate_rating' ? StarIcon : undefined,
          key: filterKey,
          label: itemLabelToRender(filter),
          value: filter,
        };
      })
    : [];
};
