import type { ReactNode } from 'react';

import { css, useTheme } from '@jane/reefer';

import Button from '../../component-library/button';
import { flex, media, mediaQueries, spacing } from '../../style';

const baseStyle = ({ theme }) =>
  css(
    flex({ inline: true, alignItems: 'center', justifyContent: 'center' }),
    spacing({ px: 16 }),
    {
      borderStyle: 'none',
      background: 'unset',
      color: theme?.colors.secondary.main,
      cursor: 'pointer',
      fontWeight: 600,
      height: 40,
      position: 'relative',
      whiteSpace: 'nowrap',
      [media(mediaQueries.largerThanMobile)]: spacing({ px: 24 }),
    }
  );

export const headerButtonStyle = ({ theme }) =>
  css(baseStyle({ theme }), {
    borderRadius: 4,
  });

export const buttonGroupStyle = ({ theme }) =>
  css(baseStyle({ theme }), {
    '& + &': {
      borderLeft: `1px solid ${theme?.colors.grays.white}`,
    },
    '&:first-of-type': {
      borderRadius: '4px 0 0 4px',
    },
    '&:last-of-type': {
      borderRadius: '0 4px 4px 0',
    },
  });

interface Props {
  ariaLabel?: string;
  children?: ReactNode;
  icon?: string;
  inGroup?: boolean;
  onClick?: () => void;
  to?: string;
}

const EmbeddedOrWhiteLabelButton = ({
  ariaLabel,
  children,
  icon,
  onClick,
  to,
  inGroup,
}: Props) => {
  const theme = useTheme();
  const style = inGroup
    ? buttonGroupStyle({ theme })
    : headerButtonStyle({ theme });

  return to ? (
    <Button
      aria-label={ariaLabel}
      onClick={onClick}
      data-testid="embed-header-button"
      css={style}
      variant="secondary-inverse"
      to={to}
      icon={icon}
      textColor={theme?.colors.secondary.main}
    >
      {children}
    </Button>
  ) : (
    <Button
      css={style}
      aria-label={ariaLabel}
      onClick={onClick}
      variant="secondary-inverse"
      type="button"
      icon={icon}
      textColor={theme?.colors.secondary.main}
    >
      {children}
    </Button>
  );
};

export default EmbeddedOrWhiteLabelButton;
