import { z } from 'zod';

export enum Feeling {
  Blissful = 'blissful',
  Creative = 'creative',
  Energetic = 'energetic',
  Hungry = 'hungry',
  NotHigh = 'not high',
  PainFree = 'pain free',
  Relaxed = 'relaxed',
  Sleepy = 'sleepy',
}

export const AlgoliaReviewSchema = z.object({
  ID: z.string(),
  activities: z.array(z.string()),
  avatar: z.number(),
  comment: z.string(),
  created_at: z.string(),
  feelings: z.array(z.string()),
  has_comment: z.boolean(),
  hidden: z.boolean(),
  nickname: z.string(),
  objectID: z.string(),
  product_id: z.number(),
  rating: z.number(),
  updated_at: z.string(),
  upvote_count: z.number(),
});

export type AlgoliaReview = z.infer<typeof AlgoliaReviewSchema>;

export const AlgoliaReviewsSchema = z.array(AlgoliaReviewSchema);

export type AlgoliaReviews = z.infer<typeof AlgoliaReviewsSchema>;
