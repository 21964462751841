import capitalize from 'lodash/capitalize';
import type { ReactElement } from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Link } from '@jane/reefer';
import type { AppMode, ReservationMode } from '@jane/shared/models';

import { useCustomerDispatch } from '../../../../../customer/dispatch';
import { closeCart } from '../../../../../customer/redux/cart';
import { getIdentification } from '../../../../../customer/redux/identification';
import { useCustomerSelector } from '../../../../../customer/selectors';
import { paths } from '../../../../../lib/routes';
import { get } from '../../../../../redux-util/selectors';

interface Props {
  children: ReactElement;
  reservationMode: ReservationMode;
  specialLabel?: string | null;
}

export const LoginProtected = ({
  children,
  reservationMode,
  specialLabel,
}: Props): ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const { appMode } = useCustomerSelector(get('embeddedApp'));
  const { authenticated } = useCustomerSelector(get('customer'));
  const dispatch = useCustomerDispatch();

  // load/unload the user's document:
  useEffect(() => {
    if (authenticated) {
      dispatch(getIdentification());
    }
  }, [authenticated]);

  const redirectToLogin = (appMode: AppMode) => {
    dispatch(closeCart());
    navigate(appMode === 'default' ? paths.login() : paths.embeddedLogin(), {
      state: {
        nextPathname: location.pathname,
        from: 'cartDrawer',
      },
      replace: true,
    });
  };

  if (authenticated || reservationMode === 'kiosk') return children;

  return (
    <Box pt={8} pb={24} pl={2}>
      <Link onClick={() => redirectToLogin(appMode)}>
        Sign In To See {capitalize(specialLabel || 'discounts')}
      </Link>
    </Box>
  );
};
