import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';

import { Flex, Form, PinIcon, TextField } from '@jane/reefer';
import { getGoogleAddresses } from '@jane/shared-ecomm/util';

import type { AddressAutocompleteProps } from './addressAutocomplete.types';
import { GoogleFooter } from './googleFooter';

export const AddressAutocomplete = ({
  autoFocus,
  clearIcon,
  countries = ['us'],
  defaultValue = '',
  disableMobileInputStyling = false,
  errorMessage,
  name,
  isForm,
  label,
  onBlur,
  onChange,
  onSelection,
  locationIcon,
  placeholder,
  testId,
}: AddressAutocompleteProps) => {
  const [options, setOptions] = useState<string[]>([]);

  const TextFieldComponent = isForm ? Form.TextField : TextField;

  const handleGetAddresses = debounce((value) => {
    getGoogleAddresses(value, countries).then((result) =>
      setOptions(
        result.map(
          (location: { label: string; value: string }) => location.value
        )
      )
    );
  }, 200);

  const handleOnChange = (value: string) => {
    if (value.length > 0) {
      handleGetAddresses(value);
    } else {
      setOptions([]);
    }

    onChange && onChange(value);
  };

  useEffect(() => {
    if (defaultValue.length > 0) {
      handleGetAddresses(defaultValue);
    }
  }, [defaultValue]);

  return (
    <Flex width="100%">
      <TextFieldComponent
        autocompleteFooter={<GoogleFooter />}
        autocompleteOptions={options}
        autoFocus={autoFocus}
        clearIcon={clearIcon}
        defaultValue={defaultValue}
        disableMobileInputStyling={disableMobileInputStyling}
        helperText={errorMessage}
        label={label}
        name={name}
        onBlur={onBlur}
        onChange={handleOnChange}
        onSelection={onSelection}
        placeholder={placeholder}
        required
        startIcon={locationIcon && <PinIcon size="sm" color="grays-mid" />}
        testId={testId}
        width="100%"
      />
    </Flex>
  );
};
