import * as t from 'io-ts';

import { tStore } from './store';

export const tKiosk = t.interface({
  allow_anonymous_checkout: t.union([t.boolean, t.null]),
  checkout_on_separate_device: t.union([t.boolean, t.null]),
  disable_phone_number_at_checkout: t.union([t.boolean, t.null]),
  enabled: t.boolean,
  frost_landing_page: t.union([t.boolean, t.null]),
  id: t.number,
  inactivity_timeout_ms: t.union([t.number, t.null]),
  refresh_to_landing_timeout_ms: t.union([t.number, t.null]),
  remove_jane_branding: t.union([t.boolean, t.null]),
  show_checkout_message_field: t.union([t.boolean, t.null]),
  skip_landing_page: t.union([t.boolean, t.null]),
  stores: t.array(tStore),
});

export type Kiosk = t.TypeOf<typeof tKiosk>;
