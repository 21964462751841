import type { SearchRadius } from '@jane/search/types';

const MILES_TO_METERS: Record<SearchRadius, number> = {
  '5': 8047,
  '10': 16093,
  '15': 24140,
  '20': 32187,
  '25': 40234,
  '30': 48280,
  '35': 56327,
};

export const METERS_PER_MILE = 1609.34;

export const searchRadiusToMeters = (searchRadius: SearchRadius) =>
  searchRadius
    ? MILES_TO_METERS[searchRadius] ||
      Math.ceil(METERS_PER_MILE * Number(searchRadius))
    : undefined;
