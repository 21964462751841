import { mediaQueries, spacing, styled } from '@jane/reefer';

export const StyledProductFilterBar = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  ...spacing({ pt: 16, pb: 8 }),
  [mediaQueries.tablet('min')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    ...spacing({ pt: 24, px: 24 }),
  },
});

// categories, lineage, brands, etc
export const StyledFilterWrapper = styled.div({
  display: 'flex',
  width: '100%',
  overflowX: 'scroll',
  '::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
  '> :first-of-type': {
    ...spacing({ pl: 24 }),
  },
  '> :last-of-type': {
    ...spacing({ pr: 24 }),
  },
  [mediaQueries.tablet('min')]: {
    overflow: 'initial',
    width: '65%',
    flexWrap: 'wrap',
    '> :first-of-type': {
      ...spacing({ pl: 0 }),
    },
  },
  '> *': {
    ...spacing({ mb: 16 }),
  },
  '> :not(:last-of-type)': {
    ...spacing({ mr: 16 }),
  },
});

// sort by, grid/list view
export const StyledSortWrapper = styled.div({
  display: 'flex',
  width: '100%',
  overflowX: 'scroll',
  '::-webkit-scrollbar': {
    display: 'none',
  },
  ...spacing({ px: 24 }),
  [mediaQueries.tablet('min')]: {
    overflow: 'initial',
    justifyContent: 'flex-end',
    width: '35%',
    flexWrap: 'wrap',
    ...spacing({ px: 0 }),
  },
  '> div, span': {
    // popover targets
    width: '100%',
    [mediaQueries.tablet('min')]: {
      width: 'fit-content',
    },
    '> div, div': {
      // popover target button label
      whiteSpace: 'nowrap',
    },
  },
});

export const StyledFilterSearchCard = styled.li(({ theme }) => ({
  display: 'flex',
  ...spacing({ py: 16, px: 24 }),
  borderTop: `1px solid ${theme.colors.grays.light}`,
  cursor: 'pointer',
  '&:hover, &:focus': {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
}));
