import { z } from 'zod';

export const AlgoliaBrandImageSchema = z.object({
  cta_text: z.union([z.string(), z.null()]),
  cta_url: z.union([z.string(), z.null()]),
  description: z.union([z.string(), z.null()]),
  image_url: z.string(),
  position: z.number(),
});

export const AlgoliaBrandVideoSchema = z.object({
  overlay_image_url: z.union([z.string(), z.null()]),
  overlay_text: z.union([z.string(), z.null()]),
  video_url: z.string(),
});

export type AlgoliaBrandImage = z.infer<typeof AlgoliaBrandImageSchema>;
export type AlgoliaBrandVideo = z.infer<typeof AlgoliaBrandVideoSchema>;

export const AlgoliaBrandSchema = z.object({
  color_hex: z.optional(z.union([z.string(), z.null()])),
  custom_image_url: z.optional(z.union([z.string(), z.null()])),
  custom_images: z.optional(
    z.union([z.array(AlgoliaBrandImageSchema), z.null()])
  ),
  custom_video: z.optional(z.union([AlgoliaBrandVideoSchema, z.null()])),
  description: z.optional(z.union([z.string(), z.null()])),
  id: z.number(),
  logo_url: z.optional(z.union([z.string(), z.null()])),
  name: z.string(),
  objectID: z.string(),
  productCount: z.optional(z.number()),
  rank: z.optional(z.union([z.number(), z.null()])),
  uuid: z.string(),
});

export type AlgoliaBrand = z.infer<typeof AlgoliaBrandSchema>;

export const AlgoliaBrandsSchema = z.array(AlgoliaBrandSchema);

export type AlgoliaBrands = z.infer<typeof AlgoliaBrandsSchema>;
