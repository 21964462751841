import type { CSSObject } from '@emotion/react';

import type { TypographyProps } from '../components';

export interface Font {
  fontFamily?: CSSObject['fontFamily'];
  url?: string;
}

export interface ReeferTypographyFonts {
  branded: Font;
  default: Font;
}

type InnerTypographyVariantConfigOptions = Pick<
  CSSObject,
  'fontSize' | 'fontWeight' | 'lineHeight' | 'textTransform' | 'letterSpacing'
>;

type SharedTypographyVariantConfig =
  Partial<InnerTypographyVariantConfigOptions>;

type TypographyVariantConfigOptions = Pick<TypographyProps, 'branded'> &
  SharedTypographyVariantConfig & {
    desktopStyles?: SharedTypographyVariantConfig;
    mobileStyles?: SharedTypographyVariantConfig;
  };

export type TypographyVariantConfig<T extends string = TypographyVariant> = {
  [key in T]: Partial<TypographyVariantConfigOptions>;
};

export type BaseTypographyVariantConfig =
  TypographyVariantConfig<BaseTypographyVariants>;

export type BoldTypographyVariantConfig =
  TypographyVariantConfig<BoldTypographyVariants>;

export type TypographyConfig<
  Variants extends string = TypographyVariant,
  Map extends string = BaseTypographyVariants
> = {
  componentMapping: TypographyVariantComponentMap<Map>;
  fonts: ReeferTypographyFonts;
  variants: TypographyVariantConfig<Variants>;
};

export type CustomTypography = {
  componentMapping?: Partial<TypographyVariantComponentMap>;
  fonts: Partial<ReeferTypographyFonts>;
  variants: Partial<TypographyVariantConfig>;
};

export type TypographyComponent =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'div'
  | 'span'
  | 'label'
  | 'legend';

export const baseTypographyVariants = [
  'title',
  'header',
  'body',
  'mini',
  'caps',
] as const;

const boldTypographyVariants = [
  'title-bold',
  'header-bold',
  'body-bold',
  'mini-bold',
  'caps-bold',
] as const;

const TypographyVariants = [
  ...baseTypographyVariants,
  ...boldTypographyVariants,
] as const;

export type BoldTypographyVariants = typeof boldTypographyVariants[number];
export type BaseTypographyVariants = typeof baseTypographyVariants[number];

export type TypographyVariant = typeof TypographyVariants[number];

export type TypographyVariantComponentMap<
  T extends string = BaseTypographyVariants
> = {
  [variant in T]: TypographyComponent;
};
