import { styled, useTheme } from '@jane/reefer';
import type { Identification } from '@jane/shared/models';
import { trackError } from '@jane/shared/util';

import { SvgIcon } from '../../component-library/svg-icon';
import { useCustomerDispatch } from '../../customer/dispatch';
import { uploadIdentification } from '../../customer/redux/identification';
import { convertIfPDF } from '../../lib/convertPDF';
import { flex, spacing } from '../../style';
import { Flex } from '../../style/layout';
import type { IdPhotoType } from '../../types/idPhotoType';
import ImageUploader from '../imageUploader';

const Validation = styled.div(
  spacing({ ml: 16 }),
  flex({ alignItems: 'center', justifyContent: 'center' }),
  {
    borderRadius: '50%',
    height: 24,
    width: 24,
  }
);

export const ValidationIcon = () => {
  const theme = useTheme();
  return (
    <Validation>
      <SvgIcon
        color={theme.colors.system.positive.main}
        icon="circleCheckFilled"
        size={24}
      />
    </Validation>
  );
};

export type OnUploadAttemptType = (valid: boolean, type: IdPhotoType) => void;

export interface IdentificationProps {
  cardBackComplianceLanguage?: string | null;
  cardFrontComplianceLanguage?: string | null;
  onIdUploadAttempt?: OnUploadAttemptType;
  onRemove?: (type: IdPhotoType) => void;
  showValidationErrors?: boolean;
  valid?: boolean;
}

interface IdUploaderProps extends IdentificationProps {
  document: Identification;
  imageKey: IdPhotoType;
  inputName: string;
  isLoading: boolean;
  label?: string;
}

export const IdentificationUploader = ({
  imageKey,
  inputName,
  label,
  document,
  isLoading,
  valid = false,
  showValidationErrors = false,
  onIdUploadAttempt = (_valid, _type) => {},
  onRemove = (_type) => {},
}: IdUploaderProps) => {
  const dispatch = useCustomerDispatch();
  if (!document) return null;

  const setPhoto = (key: IdPhotoType, file: string) => {
    convertIfPDF(file).then(
      (result) => dispatch(uploadIdentification({ key, value: result })),
      //send the original file if conversion fails
      (e) => {
        trackError(e, file.slice(0, 100));
        dispatch(uploadIdentification({ key, value: file }));
      }
    );
  };

  const unsetPhoto = (key: IdPhotoType) => {
    dispatch(uploadIdentification({ key, value: null }));
    onRemove(key);
  };

  const imageValue = (document as any)[imageKey];

  return (
    <ImageUploader
      allowPDFs
      label={label}
      image={{
        key: imageKey,
        value: imageValue,
      }}
      inputName={inputName}
      isLoading={isLoading}
      onSetImage={setPhoto}
      onUnsetImage={unsetPhoto}
      valid={valid}
      showValidationErrors={showValidationErrors}
      onSuccess={() => onIdUploadAttempt(true, imageKey)}
      onFailure={() => onIdUploadAttempt(false, imageKey)}
    />
  );
};

const DeluxeIdentificationUploader = (props: IdUploaderProps) => {
  const { imageKey, document } = props;
  if (!document) return null;
  const imageValue = (document as any)[imageKey];

  return (
    <Flex alignItems="center">
      <IdentificationUploader {...props} />
      {!!imageValue && <ValidationIcon />}
    </Flex>
  );
};

export default DeluxeIdentificationUploader;
