import type { CSSObject } from '@emotion/react';
import every from 'lodash/every';
import isNumber from 'lodash/isNumber';
import isUndefined from 'lodash/isUndefined';
import pick from 'lodash/pick';

import type { Spacing } from './spacing';

export const combine = (
  ...styles: (CSSObject | false | null | undefined)[]
): CSSObject =>
  styles.reduce<CSSObject>(
    (result, style) => (style ? { ...result, ...style } : result),
    {}
  );
export type FlexProperties = Pick<
  CSSObject,
  | 'alignItems'
  | 'alignContent'
  | 'flexDirection'
  | 'justifyContent'
  | 'justifyItems'
  | 'flexWrap'
  | 'width'
  | 'height'
  | 'minWidth'
  | 'maxWidth'
> & {
  /** Set `gap`, the spacing between rows and columns */
  gap?: Spacing;

  /** Set `display` as `inline-flex` on the item */
  inline?: boolean;
};

export const flex = ({
  inline,
  ...properties
}: FlexProperties = {}): CSSObject =>
  combine(
    {
      display: inline ? 'inline-flex' : 'flex',
    },
    pick(properties, [
      'alignItems',
      'alignContent',
      'gap',
      'flexDirection',
      'flexWrap',
      'justifyContent',
      'justifyItems',
      'width',
      'height',
      'minWidth',
      'maxWidth',
    ])
  );

interface FlexPropertyArgs {
  /** The length of the item. Legal values: "auto", "inherit", or a number followed by "%", "px", "em" or any other length unit */
  basis?: string;

  /** Set `flex-grow`, how the item grows relative to other flex items*/
  grow?: boolean | number;

  /** Documented above under FlexProperties */
  inline?: boolean;

  /** Set `flex-shrink`, how the item shrinks relative to other flex items*/
  shrink?: boolean | number;
}

export type FlexItemProperties = Pick<
  CSSObject,
  'alignSelf' | 'justifySelf' | 'width' | 'height'
> &
  FlexPropertyArgs;

const flexProperty = ({ grow, shrink, basis }: FlexPropertyArgs) => {
  if (every([grow, shrink, basis], isUndefined)) return null;

  const growVal = isNumber(grow) ? grow : grow ? 1 : 0;
  const shrinkVal = isNumber(shrink) ? shrink : !shrink ? 0 : 1;
  const basisVal = !basis ? 'auto' : basis;

  return { flex: `${growVal} ${shrinkVal} ${basisVal}` };
};

export const flexItem = ({
  grow,
  shrink,
  basis,
  inline,
  ...properties
}: FlexItemProperties = {}): CSSObject =>
  combine(
    inline && { display: 'inline-flex' },
    flexProperty({ grow, shrink, basis }),
    pick(properties, ['alignSelf', 'justifySelf', 'width', 'height'])
  );
