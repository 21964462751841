import { config } from '@jane/shared/config';

import { request } from '../lib/request';

export class CartProductsSource {
  static urlRoot = `${config.apiPath}/cart_products`;

  static add({ store, cartProduct, appMode, appModeName, cart_uuid, tags }) {
    const data = {
      store_id: store.id,
      product: {
        product_id: cartProduct.id,
        count: cartProduct.count,
        price_id: cartProduct.price_id,
        recommended: !!cartProduct.recommended,
      },
      app_mode: appMode,
      app_mode_name: appModeName,
      user_agent: navigator.userAgent,
      cart_uuid,
      tags,
    };

    return request(this.urlRoot, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  }

  static update(id, count, price_id, cart_uuid) {
    const data = {
      product: {
        product_id: id,
        count,
        price_id,
      },
      cart_uuid,
    };

    return request(`${this.urlRoot}/${id}`, {
      method: 'PATCH',
      body: JSON.stringify(data),
    });
  }

  static delete(id, price_id, cart_uuid) {
    const data = { price_id, cart_uuid };
    return request(`${this.urlRoot}/${id}`, {
      method: 'DELETE',
      body: JSON.stringify(data),
    });
  }

  static deleteUnavailableProducts(cart_uuid) {
    return request(`${this.urlRoot}/unavailable`, {
      method: 'DELETE',
      body: JSON.stringify({ cart_uuid }),
    });
  }
}
