import type { ReactElement } from 'react';
import React from 'react';

import type { TabProps } from './tab/tab';
import { StyledTabs } from './tabs.styles';

export interface TabsProps {
  /** Aria label */
  ariaLabel?: string;

  /** Only allows Tabs.Tab children */
  children: ReactElement<TabProps>[];

  /** Stretch to full width of container (overrides tabWidth) */
  full?: boolean;

  /** Function that returns the value of the clicked button */
  onChange(value: string | number): void;

  /** Set the width of each tab */
  tabWidth?: number | string;

  /** Id used for testing */
  testId?: string;

  /** The currently selected value */
  value: number | string;
}

/**
 * The `Tabs` components is used to display a series of selectable tabs.
 *
 * Each individual tab within the `Tabs` component is defined using the `Tab.Tab` component.
 */

export function Tabs({
  ariaLabel,
  children,
  full,
  onChange,
  tabWidth,
  testId,
  value,
  ...props
}: TabsProps) {
  const renderedTabs = React.Children.map(children, (child) => {
    /** value for buttons, to for router links */
    const childValue = child.props.to || child.props.value;

    const isSelected = value === child.props.to || value === child.props.value;

    const onClickHandler = () => {
      child.props?.onClick && child.props.onClick();
      onChange(childValue as string | number);
    };

    return React.cloneElement(child, {
      full,
      width: tabWidth,
      selected: isSelected,
      onClick: onClickHandler,
      ...props,
    });
  });

  return (
    <StyledTabs data-testid={testId} aria-label={ariaLabel} full={full}>
      {renderedTabs}
    </StyledTabs>
  );
}
