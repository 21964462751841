import { useEffect } from 'react';

import { postMessageToIframeParent } from './postMessageToIframeParent';

export const inIframe = () => window.self !== window.top;

export const useHandleMobilePopover = (isOpen: boolean, isMobile: boolean) => {
  const isInIframe = inIframe();

  useEffect(() => {
    if (isMobile) {
      if (isOpen) {
        document.body.style.overflow = 'hidden';

        // resize iframe to 100vh height
        isInIframe && postMessageToIframeParent({ messageType: 'openModal' });
      } else {
        document.body.style.overflow = 'unset';

        // reset iframe height
        isInIframe && postMessageToIframeParent({ messageType: 'closeModal' });
      }
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen, isMobile, isInIframe]);
};
