import { Box, Flex } from '@jane/reefer';
import type { WeightSelectorWeight } from '@jane/shared-ecomm/types';
import type { PriceId } from '@jane/shared/models';

import { useMediaQuery } from '../../../../common/providers/mediaQueryProvider';
import { SvgIcon } from '../../../../component-library/svg-icon';
import {
  DisplayMode,
  useProductCardContext,
} from '../../providers/productCardProvider';
import { VariantSelectionButton } from './variantSelectionButton';
import WeightItems, { CheckIconWrapper } from './weightItems';

interface WeightSelectorContainerProps {
  isSoldByWeight: boolean;
  noSelectedWeightDefault: boolean;
  onWeightSelected: (selectedWeight: PriceId) => void;
  selectedWeight: PriceId;
  weights: WeightSelectorWeight[];
}
const WeightSelectorContainer = ({
  isSoldByWeight,
  noSelectedWeightDefault,
  onWeightSelected,
  selectedWeight,
  weights,
}: WeightSelectorContainerProps) => {
  const { cartProduct, displayMode, menuProduct, setDisplayMode } =
    useProductCardContext();
  const { smallerThanVerticalTablet } = useMediaQuery();

  if (isSoldByWeight) {
    return (
      <Flex
        alignItems="center"
        onClick={() => setDisplayMode(DisplayMode.Edit)}
        ariaLabel="edit"
        width={smallerThanVerticalTablet ? '100%' : undefined}
      >
        <WeightItems
          cartWeights={cartProduct?.map((product) => product.price_id)}
          menuProduct={menuProduct}
          selectedWeight={noSelectedWeightDefault ? null : selectedWeight}
          onChange={onWeightSelected}
          weights={weights}
          editingMode={displayMode === DisplayMode.Edit}
        />
      </Flex>
    );
  }

  return (
    <Box position="relative">
      <Flex
        alignItems="center"
        onClick={() => setDisplayMode(DisplayMode.Edit)}
        ariaLabel="edit"
        width={smallerThanVerticalTablet ? '100%' : undefined}
      >
        <VariantSelectionButton
          onChange={() => onWeightSelected('each')}
          selected={displayMode === DisplayMode.Edit}
          weight="each"
        />
        {cartProduct.length > 0 && (
          <CheckIconWrapper>
            <SvgIcon size={18} icon="circleCheckFilled" />
          </CheckIconWrapper>
        )}
      </Flex>
    </Box>
  );
};

export default WeightSelectorContainer;
