import { useQuery } from '@tanstack/react-query';

import { searchAlgoliaProducts } from '@jane/search/data-access';
import type { SearchAlgoliaProductsParams } from '@jane/search/types';

const getProducts = async (params: SearchAlgoliaProductsParams) => {
  const products = await searchAlgoliaProducts(params);
  return products;
};

export const useProducts = (params: SearchAlgoliaProductsParams) => {
  return useQuery({
    queryFn: () => getProducts({ ...params }),
    queryKey: ['products', params],
  });
};
