import floor from 'lodash/floor';
import type { FileWithPath } from 'react-dropzone';

export const VALID_IMAGE_TYPES = ['image/png', 'image/jpeg', 'image/jpg'];
export const MAX_IMAGE_SIZE = 2_048_000; // TODO: verify this with Jarred and change
export const FAMILIAR_MAX_IMAGE_SIZE = `${floor(
  MAX_IMAGE_SIZE / 1_000_000
)} MB`;

export const validateImage = (file: FileWithPath) =>
  VALID_IMAGE_TYPES.includes(file.type) && file.size <= MAX_IMAGE_SIZE;
