import { useRoutes } from 'react-router-dom';

import {
  CustomerGlobal,
  MediaQueryProvider,
  Modals,
  SkipLinks,
  bootstrap,
  initAlgoliaInsights,
  setPartnerHostedConfig,
  useScrollReset,
  useScrollToApp,
} from '@jane/monolith/shared';
import { config } from '@jane/shared/config';
import { maybeSetPublicPath } from '@jane/shared/public-path';
import { useRuntimeConfig } from '@jane/shared/runtime-config';
import {
  initGoogleAnalytics,
  initTrackJS,
  loadTimezoneData,
} from '@jane/shared/util';

import { routes } from './app/routes';
import ShadowRoot from './shadow-root';

maybeSetPublicPath('frameless_embeds/');
initTrackJS();
initGoogleAnalytics(config.googleAnalyticsCustomerTrackingId);
loadTimezoneData();
initAlgoliaInsights();

const App = () => {
  useScrollReset();
  useScrollToApp();

  const { isPartnerHosted, partnerHostedPath } = useRuntimeConfig();
  const routing = useRoutes(routes(isPartnerHosted, partnerHostedPath));

  setPartnerHostedConfig({ isPartnerHosted, partnerHostedPath });

  if (!isPartnerHosted) return <div>Partner Config Not Found!</div>;

  return (
    <ShadowRoot>
      <CustomerGlobal />
      <MediaQueryProvider>
        <SkipLinks />
        <Modals />
        <div style={{ minHeight: '100vh' }}>{routing}</div>
      </MediaQueryProvider>
    </ShadowRoot>
  );
};

bootstrap();

export default App;
