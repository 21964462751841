import { ThemeProvider } from '@emotion/react';
import type { PropsWithChildren } from 'react';
import { useEffect, useState } from 'react';

import '../../styling/base.css';
import '../../styling/fonts.css';
import { theme as defaultTheme } from '../../theme';
import { generateTheme } from '../../theme/generateTheme';
import { loadCustomFonts } from '../../theme/loadFonts';
import type { CustomTheme, ReeferTheme } from '../../types';

export interface ReeferThemeProviderProps extends PropsWithChildren {
  /** Component to render while theme is loading */
  loading?: JSX.Element;

  /** Supply a theme that will override the default Reefer theme */
  theme?: CustomTheme;
}

/**
 * The `ReeferThemeProvider` component is used to provide theme configuration
 * to its child components.
 */
export function ReeferThemeProvider({
  children,
  loading,
  theme,
}: ReeferThemeProviderProps) {
  const [finalTheme, setFinalTheme] = useState<ReeferTheme>();

  useEffect(() => {
    let isSubscribed = true;
    if (theme) {
      const loadFontsAndSetTheme = async () => {
        const customTheme = generateTheme(theme);
        if (theme.components?.Typography) {
          try {
            await loadCustomFonts(theme.components.Typography);
          } catch (e: unknown) {
            // If we get an error while trying to load fonts, fall back
            // to default Reefer typography
            console.log((e as Error).message);
            customTheme.components.Typography =
              defaultTheme.components.Typography;
          }
        } else {
          customTheme.components.Typography =
            defaultTheme.components.Typography;
        }
        if (isSubscribed) {
          setFinalTheme(customTheme);
        }
      };
      loadFontsAndSetTheme();
    } else {
      if (isSubscribed) {
        setFinalTheme(defaultTheme);
      }
    }
    return () => {
      isSubscribed = false;
    };
  }, [theme]);

  /**
   * Don't render until we have the final theme.
   * This will prevent flashing of the default theme before showing
   * a custom theme.
   *
   * TODO: Consider adding a `loading` prop so users can give us a
   * loading state component to show while the theme is being calculated.
   */
  if (!finalTheme) {
    return loading || null;
  }

  return <ThemeProvider theme={finalTheme}>{children}</ThemeProvider>;
}
