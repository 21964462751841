import styled from '@emotion/styled';

import { mediaQueries, spacing } from '../../../styling';
import type { Overflow } from '../modal.types';

interface StyledModalContentProps {
  height: string;
  overflowY?: Overflow;
  padding?: boolean;
}

export const MOBILE_PADDING = 24;
export const DESKTOP_PADDING = 40;

export const StyledModalContent = styled.div<StyledModalContentProps>(
  {
    '::-webkit-scrollbar': {
      display: 'none',
    },
  },
  ({ overflowY }) => overflowY && { overflowY },
  ({ padding }) =>
    padding && {
      ...spacing({ p: MOBILE_PADDING }),
      [mediaQueries.tablet('min')]: {
        ...spacing({ p: DESKTOP_PADDING }),
      },
    },
  ({ height }) => ({
    height,
  })
);
