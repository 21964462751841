import styled from '@emotion/styled';

interface StyledTableHeadProps {
  fixed: boolean;
}

export const StyledTableHead = styled.thead<StyledTableHeadProps>(
  { zIndex: 10, backgroundColor: 'inherit', top: 0 },
  ({ fixed }) => ({
    position: fixed ? 'sticky' : 'relative',
  })
);
