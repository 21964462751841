import { useEffect } from 'react';

import { inIframe, postMessageToIframeParent } from '@jane/shared-ecomm/util';

export const useHandleIframeModal = (isOpen: boolean) => {
  const isInIframe = inIframe();

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';

      // resize iframe to 100vh height
      isInIframe && postMessageToIframeParent({ messageType: 'openModal' });
    } else {
      document.body.style.overflow = 'unset';

      // reset iframe height
      isInIframe && postMessageToIframeParent({ messageType: 'closeModal' });
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen, isInIframe]);
};
