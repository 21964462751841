import type { Id } from '@jane/shared/models';

export const buildMenuProductFilterString = (
  storeId: Id,
  productIds: number[]
) => {
  if (!storeId || !productIds || productIds.length === 0) return;

  return (
    `store_id:${storeId} AND (` +
    productIds.map((pid) => `product_id:${pid}`).join(' OR ') +
    ')'
  );
};
