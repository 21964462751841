import type { WorkingHour } from '@jane/shared/models';

import type { JaneDate } from '../date';
import { currentTime, parseWithTimezone } from '../date';
import OrderingSchedule from '../orderingSchedule';
import StoreSchedule, { ScheduleType } from '../storeSchedule';
import type { WorkingDay } from '../workingDay';
import { buildWorkingDay } from '../workingDay';

function adjustTime(
  currentTime: JaneDate,
  daysToAdd: number,
  time: string
): JaneDate | null {
  const [hour, min] = time.split(':');
  if (!(hour && min)) return null;
  return currentTime
    .clone()
    .add(daysToAdd, 'days')
    .hour(parseInt(hour, 10))
    .minute(parseInt(min, 10))
    .seconds(0)
    .milliseconds(0);
}

// returns an eight-day week starting yesterday, with yesterday's day of the week duplicated
function buildWorkingDays({
  allowFutureDayOrdering,
  allowOffHoursOrdering,
  days,
  lastCallInMinutes,
  maxLeadTimeMinutes,
  minLeadTimeMinutes,
  yesterday,
}: {
  allowFutureDayOrdering: boolean;
  allowOffHoursOrdering: boolean;
  days: readonly WorkingHour[];
  lastCallInMinutes: number;
  maxLeadTimeMinutes: number;
  minLeadTimeMinutes: number;
  yesterday: JaneDate;
}): WorkingDay[] {
  const yesterdayName = yesterday.format('dddd').toLowerCase();
  const yesterdayIndex = days.findIndex((day) => day.day === yesterdayName);
  const beforeDays = days.slice(0, yesterdayIndex + 1);
  const restOfWeek = days.slice(yesterdayIndex, days.length);
  return restOfWeek.concat(beforeDays).map((day, index) => {
    const {
      day: name,
      period: { from, to },
    } = day;
    const openingTime = from ? adjustTime(yesterday, index, from) : null;
    const closingTime = to ? adjustTime(yesterday, index, to) : null;

    return buildWorkingDay({
      allowFutureDayOrdering,
      allowOffHoursOrdering,
      name,
      openingTime,
      closingTime,
      lastCallInterval: lastCallInMinutes,
      maxLeadTimeMinutes,
      minLeadTimeMinutes,
    });
  });
}

function currentWorkingDayIndex(
  workingDays: WorkingDay[],
  time: JaneDate
): number {
  return workingDays.findIndex((day) => day.isTimeDuringDay(time));
}

function removeDuplicateWorkingDay(
  eightWorkingDays: WorkingDay[],
  currentTime: JaneDate
): WorkingDay[] {
  const firstDayIndex = currentWorkingDayIndex(eightWorkingDays, currentTime);
  if (firstDayIndex > -1) {
    return eightWorkingDays.slice(firstDayIndex, firstDayIndex + 7);
  }
  return eightWorkingDays.slice(1, eightWorkingDays.length);
}

export function buildSchedule({
  allowFutureDayOrdering = false,
  allowOffHoursOrdering = false,
  days,
  lastCallInMinutes,
  maxLeadTimeMinutes = 24 * 60,
  minLeadTimeMinutes = 0,
  startSunday = false,
  timeZoneIdentifier,
  type,
}: {
  allowFutureDayOrdering?: boolean;
  allowOffHoursOrdering?: boolean;
  days: readonly WorkingHour[];
  lastCallInMinutes: number;
  maxLeadTimeMinutes?: number;
  minLeadTimeMinutes?: number;
  startSunday?: boolean;
  timeZoneIdentifier: string;
  type: ScheduleType;
}): StoreSchedule {
  const startTime = startSunday
    ? parseWithTimezone('2018-03-25 12:00', timeZoneIdentifier)
    : currentTime(timeZoneIdentifier);
  const yesterday = startTime.clone().subtract(1, 'day');
  const eightWorkingDays = buildWorkingDays({
    allowFutureDayOrdering,
    allowOffHoursOrdering,
    days,
    lastCallInMinutes,
    maxLeadTimeMinutes,
    minLeadTimeMinutes,
    yesterday,
  });
  const workingDays = removeDuplicateWorkingDay(eightWorkingDays, startTime);

  return new StoreSchedule({
    allowFutureDayOrdering,
    allowOffHoursOrdering,
    maxLeadTimeMinutes,
    minLeadTimeMinutes,
    timeZoneIdentifier,
    type,
    workingDays,
  });
}

export function deserializeStoreSchedule({
  allowFutureDayOrdering,
  allowOffHoursOrdering,
  deliveryHours,
  deliveryMaxLeadTimeMinutes,
  deliveryMinLeadTimeMinutes,
  lastCallInterval,
  deliveryLastCallInterval,
  pickupHours,
  pickupMaxLeadTimeMinutes,
  pickupMinLeadTimeMinutes,
  curbsideHours,
  curbsideMaxLeadTimeMinutes,
  curbsideMinLeadTimeMinutes,
  curbsideLastCallInterval,
  timeZoneIdentifier,
  workingHours,
}: {
  allowFutureDayOrdering: boolean;
  allowOffHoursOrdering: boolean;
  curbsideHours: readonly WorkingHour[] | null;
  curbsideLastCallInterval: number;
  curbsideMaxLeadTimeMinutes: number;
  curbsideMinLeadTimeMinutes: number;
  deliveryHours: readonly WorkingHour[] | null;
  deliveryLastCallInterval: number;
  deliveryMaxLeadTimeMinutes: number;
  deliveryMinLeadTimeMinutes: number;
  lastCallInterval: number;
  pickupHours: readonly WorkingHour[] | null;
  pickupMaxLeadTimeMinutes: number;
  pickupMinLeadTimeMinutes: number;
  timeZoneIdentifier: string;
  workingHours: readonly WorkingHour[];
}): OrderingSchedule {
  const pickupLastCallInMinutes = lastCallInterval / 60;
  const deliveryLastCallInMinutes = deliveryLastCallInterval / 60;
  const curbsideLastCallInMinutes = curbsideLastCallInterval / 60;

  const deliverySchedule = deliveryHours
    ? buildSchedule({
        allowFutureDayOrdering,
        allowOffHoursOrdering,
        days: deliveryHours,
        lastCallInMinutes: deliveryLastCallInMinutes,
        maxLeadTimeMinutes: deliveryMaxLeadTimeMinutes,
        minLeadTimeMinutes: deliveryMinLeadTimeMinutes,
        timeZoneIdentifier,
        type: ScheduleType.Delivery,
      })
    : null;
  const pickupSchedule = pickupHours
    ? buildSchedule({
        allowFutureDayOrdering,
        allowOffHoursOrdering,
        days: pickupHours,
        lastCallInMinutes: pickupLastCallInMinutes,
        maxLeadTimeMinutes: pickupMaxLeadTimeMinutes,
        minLeadTimeMinutes: pickupMinLeadTimeMinutes,
        timeZoneIdentifier,
        type: ScheduleType.Pickup,
      })
    : null;
  const retailSchedule = buildSchedule({
    allowFutureDayOrdering,
    allowOffHoursOrdering,
    days: workingHours,
    lastCallInMinutes: pickupLastCallInMinutes,
    timeZoneIdentifier,
    type: ScheduleType.Retail,
  });
  const curbsideSchedule = curbsideHours
    ? buildSchedule({
        allowFutureDayOrdering,
        allowOffHoursOrdering,
        days: curbsideHours,
        lastCallInMinutes: curbsideLastCallInMinutes,
        maxLeadTimeMinutes: curbsideMaxLeadTimeMinutes,
        minLeadTimeMinutes: curbsideMinLeadTimeMinutes,
        timeZoneIdentifier,
        type: ScheduleType.Curbside,
      })
    : null;

  return new OrderingSchedule(
    retailSchedule,
    deliverySchedule,
    pickupSchedule,
    curbsideSchedule,
    allowOffHoursOrdering
  );
}
