import styled from '@emotion/styled';
import type { CSSObject } from '@emotion/styled';
import { Link } from 'react-router-dom';

import { spacing } from '../../../styling';
import type { ReeferTheme } from '../../../types';

interface TabStyleProps {
  full?: boolean;
  selected: boolean;
  width?: number | string;
}

interface TabStylePropsWithTheme extends TabStyleProps {
  theme: ReeferTheme;
}

const sharedTabStyle = ({
  theme: { colors },
  selected,
  width,
  full,
}: TabStylePropsWithTheme) =>
  ({
    ...spacing({ px: 24, py: 12 }),
    borderWidth: 0,
    textAlign: 'center',
    cursor: 'pointer',
    transition: 'all 250ms',
    overflow: 'hidden',
    flexShrink: 0,
    width: width ? width : 'auto',
    color: colors.grays.black,
    background: 'transparent',
    boxShadow: `0px -1px inset ${colors.grays.light}`,
    ...(selected && {
      boxShadow: `0px -2px inset ${colors.primary.main}`,
      pointerEvents: 'none',
    }),
    ...(full && {
      flexGrow: 1,
      flexShrink: 0,
      maxWidth: 'none',
    }),
    '&:hover, &:focus-visible': {
      backgroundColor: colors.grays.hover,
      outline: 'none',
    },
  } as CSSObject);

export const StyledTab = styled.button<TabStyleProps>(
  ({ theme, selected, width, full }) => ({
    ...sharedTabStyle({ theme, selected, width, full }),
  })
);

export const StyledRouterTab = styled(Link)<TabStyleProps>(
  ({ theme, selected, width, full }) => ({
    ...sharedTabStyle({ theme, selected, width, full }),
    textDecoration: 'none',
  })
);
