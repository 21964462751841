import type { ReactNode } from 'react';

import type { ComponentColors } from '@jane/reefer';
import { Typography, styled } from '@jane/reefer';

import { Box } from '../../../style/layout';

const AD_WIDTH_DESKTOP = 728;
const AD_WIDTH_MOBILE = 320;
const AD_HEIGHT_DESKTOP = 90;
const AD_HEIGHT_MOBILE = 100;

export const SponsoredLabel: React.FC<{
  children?: ReactNode;
  isBanner?: boolean;
  isDesktop: boolean;
  textColor: ComponentColors;
}> = ({ children, textColor, isDesktop, isBanner }) => (
  <Typography
    css={{
      minHeight: 22,
      width: isBanner
        ? isDesktop
          ? AD_WIDTH_DESKTOP
          : AD_WIDTH_MOBILE
        : undefined,
    }}
    variant="body"
    color={textColor}
  >
    {children}
  </Typography>
);

export const AdBox = styled(Box)<{ isDesktop: boolean; url: string }>(
  {
    backgroundSize: 'cover',
    marginBottom: 32,
  },
  ({ url, isDesktop }) => ({
    backgroundImage: `url(${url})`,
    height: isDesktop ? AD_HEIGHT_DESKTOP : AD_HEIGHT_MOBILE,
    width: isDesktop ? AD_WIDTH_DESKTOP : AD_WIDTH_MOBILE,
  })
);
