import { Button, Flex, useMobileMediaQuery } from '@jane/reefer';
import { truncateLabel } from '@jane/shared-ecomm/util';

import { useActiveFilterContext } from './activeFilterProvider';
import type { FilterItemProps } from './filter.types';

export const FilterButton = ({
  filterKey,
  item,
  onChange,
}: FilterItemProps) => {
  const { label, icon, count, value } = item;
  const isMobile = useMobileMediaQuery({});
  const { activeFilters } = useActiveFilterContext();

  const labelLength = isMobile ? 20 : 40;
  const renderedLabel = `${truncateLabel(label, labelLength)} (${count || 0})`;

  // prevents the icon from shrinking
  const iconToRender = <Flex data-testid="filter-button-icon">{icon}</Flex>;

  const isSelected = activeFilters.some((filter) => filter.value === value);

  return (
    <Button
      key={label}
      label={renderedLabel}
      startIcon={icon ? iconToRender : undefined}
      onClick={() => onChange(filterKey, value as string)}
      variant={isSelected ? 'tertiary-selected' : 'tertiary'}
      alignment="left"
      size="large"
      full
    />
  );
};
