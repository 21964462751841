import { Card, Flex } from '@jane/reefer';
import type { WeightSelectorWeight } from '@jane/shared-ecomm/types';
import {
  getTotalOriginalAndDiscountedPrices,
  isSoldByWeight,
} from '@jane/shared-ecomm/util';
import type { PriceId } from '@jane/shared/models';

import {
  DisplayMode,
  useProductCardContext,
} from '../../providers/productCardProvider';
import Confirmation from './confirmation';
import WeightSelectorContainer from './weightSelectorContainer';

interface ActionCardContentProps {
  noSelectedWeightDefault: boolean;
  onWeightSelected: (selectedWeight: PriceId) => void;
  selectedWeight: PriceId;
  weights: WeightSelectorWeight[];
}

const ActionCardContent = ({
  noSelectedWeightDefault,
  onWeightSelected,
  selectedWeight,
  weights,
}: ActionCardContentProps) => {
  const { cartProduct, displayMode, menuProduct } = useProductCardContext();
  const { totalOriginalPrice, totalDiscountedPrice } =
    getTotalOriginalAndDiscountedPrices({
      menuProduct,
      cartProduct,
    });

  return (
    <Card.Content
      background={
        displayMode === DisplayMode.Confirmation
          ? 'grays-ultralight'
          : undefined
      }
    >
      <Flex
        p={8}
        flexDirection="column"
        alignItems={
          displayMode === DisplayMode.Confirmation ? 'center' : 'flex-end'
        }
        justifyContent="center"
        height="100%"
        grow={2}
      >
        {displayMode === DisplayMode.Confirmation && (
          <Confirmation
            discountedPrice={totalDiscountedPrice}
            onWeightSelected={onWeightSelected}
            originalPrice={totalOriginalPrice}
          />
        )}
        {(displayMode === DisplayMode.Product ||
          displayMode === DisplayMode.Edit) && (
          <WeightSelectorContainer
            isSoldByWeight={isSoldByWeight(menuProduct.kind)}
            noSelectedWeightDefault={noSelectedWeightDefault}
            onWeightSelected={onWeightSelected}
            selectedWeight={selectedWeight}
            weights={weights}
          />
        )}
      </Flex>
    </Card.Content>
  );
};

export default ActionCardContent;
