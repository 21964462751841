import styled from '@emotion/styled';
import type { MouseEventHandler } from 'react';

import type { ReeferTheme } from '../../../types';

interface StyledTableCellProps {
  onClick?: MouseEventHandler<HTMLTableCellElement>;
}

export const sharedCellStyles = (theme: ReeferTheme) => ({
  height: '72px',
  padding: '8px 12px',
  display: 'table-cell',
  verticalAlign: 'inherit',
  borderBottom: `1px solid ${theme.colors.grays.light}`,
  backgroundColor: theme.colors.grays.white,
});

export const StyledTableCell = styled.td<StyledTableCellProps>(
  ({ theme, onClick }) => ({
    zIndex: 1,
    ...sharedCellStyles(theme),
    ...(onClick && {
      cursor: 'pointer',
      '&:hover': { backgroundColor: theme.colors.grays.light },
    }),
  })
);
