import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Link, Typography } from '@jane/reefer';

import { openModal } from '../../common/redux/application';
import { useCustomerDispatch } from '../../customer/dispatch';
import { paths } from '../../lib/routes';
import { Flex } from '../../style/layout';

interface Props {
  customerKnown: boolean;
  embeddedMode: boolean;
  nickname: string;
}

const LoginSecondaryAction = ({
  customerKnown,
  nickname,
  embeddedMode,
}: Props) => {
  const navigate = useNavigate();
  const dispatch = useCustomerDispatch();
  const onClick = useCallback(() => {
    if (embeddedMode) {
      return navigate(paths.embeddedLogout(), { replace: true });
    }

    return dispatch(openModal({ name: 'logout' }));
  }, [embeddedMode]);

  if (customerKnown) {
    return (
      <Flex pt={24}>
        <Typography mr={4} variant="body">
          Not {nickname}?
        </Typography>
        <Link color="grays-black" onClick={onClick}>
          Log out
        </Link>
      </Flex>
    );
  }

  return null;
};

export default LoginSecondaryAction;
