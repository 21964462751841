import { mediaQueries, styled } from '@jane/reefer';

export const InnerCarouselItem = styled.div({
  height: '100%',
  minWidth: '100%',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
});

export const PlayIconWrapper = styled.div({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

export const CarouselPlayerWrapper = styled.div(({ theme }) => ({
  width: '100%',
  overflow: 'hidden',
  [mediaQueries.tablet('min')]: {
    borderRadius: theme.borderRadius.lg,
  },
}));

export const StockPhotoLabel = styled.div({
  position: 'absolute',
  bottom: '16px',
  left: '50%',
  transform: 'translateX(-50%)',
});
