import { Button, LeftArrowIcon } from '@jane/reefer';

interface Props {
  onClick: () => void;
}

const UserLocationModalBackLink = ({ onClick }: Props) => (
  <Button.Icon
    onClick={onClick}
    icon={<LeftArrowIcon />}
    ml={12}
    mt={12}
    ariaLabel="back"
  />
);

export default UserLocationModalBackLink;
