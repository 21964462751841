import type { FormState } from 'final-form';

import type { MobileFilterTab, ShareNetwork } from '@jane/shared-ecomm/types';
import type {
  AdData,
  AppMode,
  Coordinates,
  CrmProviderType,
  DmMeta,
  Id,
  PriceId,
  ProductKind,
  ProductLineage,
  Zone,
} from '@jane/shared/models';

export enum EventNames {
  AbandonedCheckoutPage = 'Abandoned Checkout Page',
  AddedProductToCart = 'Added Product to Cart',
  AddedStrongholdPaymentSource = 'Added Stronghold Payment Source',
  AllSpecialsTap = 'All Specials Tap',
  BrandPageLoaded = 'Brand Page Loaded',
  BrandPageOpenedVideo = 'Brand Page Opened Video',
  CheckoutSuccess = 'Checkout Success',
  ClickedAd = 'Clicked Ad',
  ClickedAdError = 'Clicked Ad Error',
  ClickedBrandLink = 'Clicked Brand Link',
  ClickedBundleEnticementFilter = 'Clicked Bundle Enticement Filter',
  ClickedCart = 'Clicked Cart',
  ClickedCartProductCard = 'Clicked Cart Product Card',
  ClickedCheckout = 'Clicked Checkout',
  ClickedContinueShopping = 'Clicked Continue Shopping',
  ClickedHomePageProductCard = 'Clicked HomePage Product Card',
  ClickedMarketplaceProductCard = 'Clicked Marketplace Product Card',
  ClickedMenuProductCard = 'Clicked Menu Product Card',
  ClickedPayWithStronghold = 'Clicked Pay With Stronghold',
  ClickedProceedWithAeropay = 'Clicked Proceed With Aeropay',
  ClickedSeeAll = 'Clicked See All',
  ClickedSegmentedMobileFilterTab = 'Clicked Segmented Store Menu Filter Tab',
  ClickedSegmentedMobileFilterToggle = 'Clicked Segmented Mobile Filter Toggle',
  ClickedShowMore = 'Clicked Show More',
  ClickedStoreInfo = 'Clicked Store Info',
  ClickedStoreNearYou = 'Clicked HomePage Store Near You',
  ConvertedAdSale = 'Converted Ad Sale',
  FetchedAdCandidatesError = 'Fetched Ad Candidates Error',
  HomeTap = 'Home Tap',
  ImpressedMlProduct = 'Impressed ML Product',
  InteractedWithProductReviews = 'Interacted With Product Reviews',
  LinkedAeropayBankAccount = 'Linked Aeropay Bank Account',
  LoadedCartPage = 'Loaded Cart Page',
  LoadedCheckoutPage = 'Loaded Checkout Page',
  LoadedGuestCheckoutPage = 'Loaded Guest Checkout Page',
  LoadedHomePage = 'Loaded Homepage',
  LoadedLoginRegistrationPage = 'Loaded Login/Registration Page',
  LoadedLoyaltyPoints = 'Loaded Loyalty Points',
  LoadedStoresPage = 'Loaded Stores Page',
  LoadedWriteProductReviewForm = 'Loaded Write Product Review Form',
  LoggedIn = 'Logged In',
  NavBarTap = 'Nav Bar Tap',
  OpenedAccordionItem = 'Opened Accordion Item',
  Registered = 'Registered',
  RemovedProductFromCart = 'Removed Product from Cart',
  ReviewedProduct = 'Reviewed Product',
  SearchedMenuProducts = 'Searched Menu Products',
  SearchedProducts = 'Searched Products',
  SearchedStores = 'Searched Stores',
  SetLocation = 'Set Location',
  SharedProduct = 'Shared Product',
  StartedKioskOrder = 'Started Kiosk Order',
  StoreCommunicationBannerLoaded = 'Store Communication Banner Loaded',
  StoreSpecialTap = 'Store Special Tap',
  TimedGetJaneDeviceId = 'Timed Get Jane Device ID',
  TimedKioskInactivity = 'Timed Kiosk Inactivity',
  TimedKioskRefreshToLanding = 'Timed Kiosk Refresh To Landing',
  TimedMenuLoad = 'Timed Menu Load',
  UpdatedProductInCart = 'Updated Product in Cart',
  ViewedBundleEnticement = 'Viewed Bundle Enticement',
  ViewedClickableBundleEnticement = 'Viewed Clickable Bundle Enticement',
  ViewedExperiment = 'Viewed Experiment',
  ViewedProduct = 'Viewed Product',
  ViewedProductReviews = 'Viewed Product Reviews',
}

export interface AbandonedCheckoutPage extends Partial<FormState<any>> {
  appMode: AppMode;
  checkoutError: string | null;
  checkoutTotal: number;
  event: EventNames.AbandonedCheckoutPage;
  formState: any;
  guestCheckout: boolean;
  imageUploadFieldsValid: boolean;
  invalid: boolean;
  minimumMet: boolean;
  pathname: string;
  step?: string;
  storeId: Id;
}

interface AddedStrongholdPaymentSource {
  event: EventNames.AddedStrongholdPaymentSource;
}

export interface AllSpecialsTap {
  event: EventNames.AllSpecialsTap;
  storeId: Id;
  storeName: string;
}

export interface ClickedAd {
  dmMeta?: DmMeta;
  event: EventNames.ClickedAd;
  flightProps: AdData['flight'];
  uniqueIdentifier: string;
}

export interface ClickedAdError {
  dmMeta?: DmMeta;
  error: object | string;
  event: EventNames.ClickedAdError;
  flightProps: AdData['flight'];
  uniqueIdentifier: string;
}

export interface ClickedBrandLink {
  appMode: AppMode;
  brandName: string;
  event: EventNames.ClickedBrandLink;
  page: string;
  storeId: Id | null;
}

export interface ClickedBundleEnticementFilter {
  event: EventNames.ClickedBundleEnticementFilter;
  specialId: Id;
  storeId: Id;
}

interface ClickedCart {
  event: EventNames.ClickedCart;
}

interface ClickedCheckout {
  event: EventNames.ClickedCheckout;
}

export interface CheckoutSuccess
  extends AlgoliaFilterProperties,
    AlgoliaObjectProperties {
  cartId: Id;
  event: EventNames.CheckoutSuccess;
  priceTotal: number;
  products: any[];
  storeCurrency?: string;
}

interface ClickedContinueShopping {
  event: EventNames.ClickedContinueShopping;
}

interface ClickedProceedWithAeropay {
  event: EventNames.ClickedProceedWithAeropay;
}

interface ClickedPayWithStronghold {
  event: EventNames.ClickedPayWithStronghold;
}

export interface ClickedSeeAll {
  category: string;
  creativeIds?: number[];
  event: EventNames.ClickedSeeAll;
  flightId?: number;
  linkLocation:
    | 'carousel card'
    | 'bucket title'
    | 'bottom of bucket button'
    | 'see all link'
    | 'ad banner';
  numberOfItems: number;
  placementIndex: number;
  productBrandId?: number;
  zone?: Zone;
}

interface ClickedShowMore {
  category: string;
  event: EventNames.ClickedShowMore;
}

export type StoreInfoAttribute =
  | 'description'
  | 'map'
  | 'phone'
  | 'ratings'
  | 'schedule';

interface ClickedStoreInfo {
  attribute: StoreInfoAttribute;
  event: EventNames.ClickedStoreInfo;
}

export type CardType = 'Order Again' | 'Trending' | 'Best Selling';

export interface ClickedHomePageProductCard {
  cardType: CardType;
  event: EventNames.ClickedHomePageProductCard;
}

export interface ClickedMarketplaceProductCard
  extends ProductProperties,
    AlgoliaObjectProperties {
  event: EventNames.ClickedMarketplaceProductCard;
}

interface SegmentedMobileFilterEvent {
  filterTab: MobileFilterTab;
}

export interface ClickedSegmentedMobileFilterTab
  extends SegmentedMobileFilterEvent {
  event: EventNames.ClickedSegmentedMobileFilterTab;
}

export interface ClickedSegmentedMobileFilterToggle
  extends SegmentedMobileFilterEvent {
  event: EventNames.ClickedSegmentedMobileFilterToggle;
}

export type CardLocation = 'grid carousel' | 'grid table' | 'list';

export interface AlgoliaObjectProperties {
  event: string;
  indexName: string;
  objectIds: string[];
}

export interface AlgoliaFilterProperties {
  event: string;
  filters: string[];
  indexName: string;
}

export interface ClickedMenuProductCard
  extends ProductProperties,
    AlgoliaObjectProperties {
  bestSelling: boolean;
  bucketName?: string;
  cardLocation: CardLocation;
  category: string;
  columnPosition?: number;
  event: EventNames.ClickedMenuProductCard;
  index?: number;
  rowPosition?: number;
}

export interface ClickedCartProductCard
  extends ProductProperties,
    AlgoliaObjectProperties {
  event: EventNames.ClickedCartProductCard;
}

export interface ClickedStoreNearYou {
  event: EventNames.ClickedStoreNearYou;
}

export interface FetchedAdCandidatesError {
  error: object | string;
  event: EventNames.FetchedAdCandidatesError;
}

export interface StoreCommunicationBannerLoaded {
  bannerEnabled: boolean;
  bannerMessageCharacterCount: number;
  event: EventNames.StoreCommunicationBannerLoaded;
  isExpanded: boolean;
  storeId: Id;
}

export interface BrandPageLoaded {
  brandId: Id;
  brandName: string;
  event: EventNames.BrandPageLoaded;
  productReferrerId?: Id;
}

export interface BrandPageOpenedVideo {
  brandId: Id;
  event: EventNames.BrandPageOpenedVideo;
}

export interface ConvertedAdSale {
  event: EventNames.ConvertedAdSale;
}

interface TimedKioskInactivity {
  event: EventNames.TimedKioskInactivity;
}

interface TimedKioskRefreshToLanding {
  event: EventNames.TimedKioskRefreshToLanding;
}

interface HomeTap {
  cityState?: string;
  event: EventNames.HomeTap;
  section: string;
  storeFulfillmentType?: string;
  storeSearchRadius?: number;
  storeType?: string;
  tile?: string;
  zipcode?: string;
}

export interface ImpressedMlProduct {
  event: EventNames.ImpressedMlProduct;
  zone: Zone;
}

interface LinkedAeropayBankAccount {
  event: EventNames.LinkedAeropayBankAccount;
}

export interface LoadedCheckoutPage {
  event: EventNames.LoadedCheckoutPage;
}

export interface LoadedGuestCheckoutPage {
  event: EventNames.LoadedGuestCheckoutPage;
}

export interface LoadedCartPage {
  event: EventNames.LoadedCartPage;
  isEmpty: boolean;
}

export interface LoadedHomePage {
  event: EventNames.LoadedHomePage;
}

export interface LoadedStoresPage {
  event: EventNames.LoadedStoresPage;
  map: boolean;
}

export interface LoadedLoginRegistrationPage {
  event: EventNames.LoadedLoginRegistrationPage;
}

export interface LoadedLoyaltyPoints {
  crmProvider: CrmProviderType | null;
  crmRewards: { point_value: number; reward_name: string }[] | null;
  event: EventNames.LoadedLoyaltyPoints;
  memberPoints: number | null;
  storeHasProvider: boolean;
}

export interface LoadedWriteProductReviewForm {
  event: EventNames.LoadedWriteProductReviewForm;
}

export enum NavBarTapSection {
  Account = 'Account',
  Bag = 'Bag',
  BrandsBusiness = 'Brands (Business)',
  DispensariesBusiness = 'Dispensaries (Business)',
  Login = 'Login',
  Logo = 'Logo',
  Products = 'Products',
  Stores = 'Stores',
}

export interface NavBarTap {
  cityState?: string;
  event: EventNames.NavBarTap;
  navBarTap: NavBarTapSection;
  source: string;
  storeFulfillmentType?: string;
  storeSearchRadius?: number;
  storeType?: string;
  zipcode?: string;
}

export interface OpenedAccordionItem {
  event: EventNames.OpenedAccordionItem;
  itemName: string;
}

export type ProductReview = { comment?: string; rating: number };

export interface ReviewedProduct {
  activities: string[];
  event: EventNames.ReviewedProduct;
  feelings: string[];
  review: ProductReview;
}

export type SSOChannel = 'google' | 'facebook' | 'cognito' | 'apple';

interface Registered {
  event: EventNames.Registered;
  partnerChannel: AppMode;
  partnerId?: Id;
  partnerName?: string;
  ssoChannel?: SSOChannel;
}

interface LoggedIn {
  event: EventNames.LoggedIn;
  ssoChannel?: SSOChannel;
}

export interface SharedSearchProperties
  extends Omit<AlgoliaFilterProperties, 'event'> {
  aggregateRatingMin?: number;
  filterCount: number;
  filters: string[];
  hits: number;
  priceRange?: string;
  query?: string;
  sort: 'price' | 'a-z' | 'rating';
  typeahead?: boolean;
}

export interface SearchedProducts
  extends SharedSearchProperties,
    AlgoliaFilterProperties {
  cityState?: string;
  event: EventNames.SearchedProducts;
}

export interface SearchedMenuProducts
  extends SharedSearchProperties,
    AlgoliaFilterProperties {
  event: EventNames.SearchedMenuProducts;
  percentCBDMax?: number;
  percentCBDMin?: number;
  percentTHCMax?: number;
  percentTHCMin?: number;
  storeId: string;
  storeName: string;
  view: 'grid' | 'list';
}

export interface SearchedStores
  extends SharedSearchProperties,
    AlgoliaFilterProperties {
  cityState?: string;
  event: EventNames.SearchedStores;
}

interface StartedKioskOrder {
  event: EventNames.StartedKioskOrder;
}

interface StoreSpecialTap {
  event: EventNames.StoreSpecialTap;
  source: string;
  specialId: Id;
  specialRankOnList: number;
  specialType: string;
  storeId: Id;
  storeName: string;
}

export interface ProductProperties {
  productAggregateRating?: number;
  productBrand?: string;
  productBrandSubtype?: string;
  productId: string;
  productKind: ProductKind;
  productLineage?: ProductLineage;
  productName: string;
  productPercentCBD?: number;
  productPercentTHC?: number;
  productReviewCount?: number;
  productRootSubtype?: string;
  storeCity?: string;
  storeState?: string;
}

export interface AddedOrUpdatedProductInCart
  extends ProductProperties,
    AlgoliaObjectProperties,
    AlgoliaFilterProperties {
  event: EventNames.AddedProductToCart | EventNames.UpdatedProductInCart;
  location: 'menu' | 'productDetailPage' | 'pendingCart' | 'marketplaceProduct';
  pricePerUnit: number;
  priceTotal: number;
  priceUnit: PriceId;
  quantity: number;
  recommended?: boolean;
  specialDiscountPerUnit?: number;
  specialDiscountTotal?: number;
  specialId?: string;
  storeCurrency: string;
  storeId: string;
  storeName: string;
}

export interface ViewedProduct extends ProductProperties {
  event: EventNames.ViewedProduct;
  pricePerUnit?: number;
  priceUnit?: string;
  productReviewsVisible?: boolean;
  recommended?: boolean;
  storeCurrency?: string;
  storeId?: string;
  storeName?: string;
}

export interface ViewedProductReviews extends ProductProperties {
  aggregateRating?: number | null;
  event: EventNames.ViewedProductReviews;
  reviewCount?: number | null;
}

export type ProductReviewInteractionTypes =
  | 'Clicked Pagination'
  | 'Filter Rating'
  | 'Filter Tags'
  | 'Sort Ratings'
  | 'Clicked Helpful'
  | 'Report';

export interface InteractedWithProductReviews extends ProductProperties {
  aggregateRating?: number | null;
  event: EventNames.InteractedWithProductReviews;
  interactionType: ProductReviewInteractionTypes;
  interactionValue: any;
  reviewCount?: number | null;
}

export interface RemovedProductFromCart {
  event: EventNames.RemovedProductFromCart;
  productBrand?: string | null;
  productCategory?: string | null;
  productId: string;
  productKind?: string | null;
  productName?: string | null;
  productPrice?: number | null;
  quantity?: number | null;
  storeCurrency?: string | null;
  variant?: string | null;
}

export interface TimedMenuLoad {
  event: EventNames.TimedMenuLoad;
  seconds: number;
  storeId: Id;
}

export interface TimedGetJaneDeviceId {
  event: EventNames.TimedGetJaneDeviceId;
}

export interface SetLocation {
  cityState?: string;
  coordinates?: Coordinates;
  event: EventNames.SetLocation;
  zipcode?: string;
}

export interface SharedProduct {
  event: EventNames.SharedProduct;
  productId: string;
  storeId?: string;
  via: ShareNetwork;
}

export interface ViewedBundleEnticement {
  event: EventNames.ViewedBundleEnticement;
  specialId: Id;
  storeId: Id;
}

export interface ViewedClickableBundleEnticement {
  event: EventNames.ViewedClickableBundleEnticement;
  specialId: Id;
  storeId: Id;
}

export interface ViewedExperiment {
  event: EventNames.ViewedExperiment;
  experimentName: string;
  variantName: string;
}

export type TrackedEvent =
  | AbandonedCheckoutPage
  | AddedOrUpdatedProductInCart
  | AddedStrongholdPaymentSource
  | AllSpecialsTap
  | BrandPageLoaded
  | BrandPageOpenedVideo
  | CheckoutSuccess
  | ClickedAd
  | ClickedAdError
  | ClickedBrandLink
  | ClickedBundleEnticementFilter
  | ClickedCart
  | ClickedCartProductCard
  | ClickedCheckout
  | ClickedContinueShopping
  | ClickedHomePageProductCard
  | ClickedMarketplaceProductCard
  | ClickedMenuProductCard
  | ClickedPayWithStronghold
  | ClickedProceedWithAeropay
  | ClickedSeeAll
  | ClickedSegmentedMobileFilterTab
  | ClickedSegmentedMobileFilterToggle
  | ClickedShowMore
  | ClickedStoreInfo
  | ClickedStoreNearYou
  | ConvertedAdSale
  | FetchedAdCandidatesError
  | HomeTap
  | ImpressedMlProduct
  | InteractedWithProductReviews
  | LinkedAeropayBankAccount
  | LoadedCartPage
  | LoadedCheckoutPage
  | LoadedGuestCheckoutPage
  | LoadedHomePage
  | LoadedLoginRegistrationPage
  | LoadedLoyaltyPoints
  | LoadedStoresPage
  | LoadedWriteProductReviewForm
  | LoggedIn
  | NavBarTap
  | OpenedAccordionItem
  | Registered
  | RemovedProductFromCart
  | ReviewedProduct
  | SearchedMenuProducts
  | SearchedProducts
  | SearchedStores
  | SetLocation
  | SharedProduct
  | StartedKioskOrder
  | StoreCommunicationBannerLoaded
  | StoreSpecialTap
  | TimedGetJaneDeviceId
  | TimedKioskInactivity
  | TimedKioskRefreshToLanding
  | TimedMenuLoad
  | ViewedBundleEnticement
  | ViewedClickableBundleEnticement
  | ViewedExperiment
  | ViewedProduct
  | ViewedProductReviews;
