import merge from 'lodash/merge';
import pickBy from 'lodash/pickBy';
import qs from 'qs';
import 'whatwg-fetch';

import { getJwt } from '@jane/shared/auth';
import { isInMaintenance, maintenanceReload } from '@jane/shared/util';

import { handleInvalidLoginToken } from '../common/redux/login';
import type { CustomerDispatch } from '../customer/redux/types';
import { NotificationsService } from '../services/notifications';

let dispatch: CustomerDispatch = () => {};

export const setRequestDispatch = (newDispatch: CustomerDispatch) => {
  dispatch = newDispatch;
};

function filteredParams(params: object) {
  const filteredParams = pickBy(params, Boolean);

  return `?${qs.stringify(filteredParams, { arrayFormat: 'brackets' })}`;
}

function handleErrors(response: any | undefined) {
  if (!response) return null;
  if (response.errors) {
    const { error, validations, status } = response.errors;

    if (validations) return { validations };
    if (status === 401) {
      dispatch(handleInvalidLoginToken(true));
    }
    if (error) {
      NotificationsService.error(error);
      throw error;
    }
    NotificationsService.error('Server error has occurred');
    throw response;
  }

  return response;
}

const handleEmpty = (response: Response) => {
  if (response.ok) return;
  else {
    return {
      errors: { status: response.status, error: response.statusText },
    };
  }
};

function parseJSON(response: Response) {
  if (isInMaintenance(response)) {
    maintenanceReload();
    return;
  }

  if (response.status === 204) return;
  return response.text().then((text) => {
    if (text.trim() === '') {
      return handleEmpty(response);
    } else return JSON.parse(text);
  });
}

export function formDataRequest(
  url: string,
  params: RequestInit,
  queryParams?: object
) {
  const defaultParams: {
    credentials: RequestCredentials;
    headers: Record<string, string>;
  } = {
    credentials: 'include',
    headers: {},
  };

  const jwt = getJwt();
  if (jwt) {
    defaultParams.headers.Authorization = jwt;
  }

  if (queryParams) {
    url += filteredParams(queryParams);
  }

  return fetch(url, merge({}, defaultParams, params))
    .then(parseJSON)
    .then(handleErrors);
}

export function request(
  url: string,
  params: RequestInit & { requireAuth?: boolean } = {},
  queryParams?: object,
  // a request is simple if it does not trigger CORS. See https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS#simple_requests
  simple?: boolean
) {
  if (queryParams) {
    url += filteredParams(queryParams);
  }

  if (simple) {
    return fetch(url, params).then(parseJSON).then(handleErrors);
  }

  const defaultParams: {
    credentials: RequestCredentials;
    headers: Record<string, string>;
  } = {
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  const jwt = getJwt();
  if (jwt) {
    defaultParams.headers.Authorization = jwt;
  }

  return fetch(url, merge({}, defaultParams, params))
    .then(parseJSON)
    .then(handleErrors);
}

export function requestWithoutErrorHandling(
  url: string,
  params: RequestInit,
  queryParams?: object
) {
  const defaultParams: {
    credentials: RequestCredentials;
    headers: Record<string, string>;
  } = {
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  const jwt = getJwt();
  if (jwt) {
    defaultParams.headers.Authorization = jwt;
  }

  if (queryParams) {
    url += filteredParams(queryParams);
  }

  return fetch(url, merge({}, defaultParams, params)).then(parseJSON);
}

export async function loginRequest(
  url: string,
  params: RequestInit,
  queryParams?: object
) {
  const defaultParams = {
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  if (queryParams) {
    url += filteredParams(queryParams);
  }

  const response = await fetch(url, merge({}, defaultParams, params));
  const body = await response.json();
  const headers = { Authorization: response.headers.get('Authorization') };

  if (isInMaintenance(response)) {
    maintenanceReload();
  }

  return { body, headers };
}

export async function businessLoginRequest(
  url: string,
  params: RequestInit,
  queryParams?: object
) {
  const defaultParams = {
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  if (queryParams) {
    url += filteredParams(queryParams);
  }

  const response = await fetch(url, merge({}, defaultParams, params));
  const body = await response.json().then(handleErrors);
  const headers = { Authorization: response.headers.get('Authorization') };

  if (isInMaintenance(response)) {
    maintenanceReload();
  }

  return { body, headers };
}
