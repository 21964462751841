import { z } from 'zod';

import { SearchAlgoliaProductsParamsSchema } from './products';

export const SearchAlgoliaBrandsParamsSchema = z.object({
  name: z.optional(z.array(z.string())),
  paging: z.optional(
    z.object({
      hitsPerPage: z.optional(z.union([z.number(), z.string()])),
      page: z.optional(z.union([z.number(), z.string()])),
    })
  ),
  hitsPerPage: z.optional(z.number()),
  page: z.optional(z.number()),
  searchText: z.optional(z.union([z.string(), z.null()])),
});

export type FullSearchAlgoliaBrandsParams = z.infer<
  typeof SearchAlgoliaBrandsParamsSchema
>;

export type SearchAlgoliaBrandsParams = z.infer<
  typeof SearchAlgoliaBrandsParamsSchema
>;

export const SearchAlgoliaLocalBrandsParamsSchema =
  SearchAlgoliaBrandsParamsSchema.merge(
    SearchAlgoliaProductsParamsSchema.pick({
      coordinates: true,
      searchRadius: true,
      storeSpecificProduct: true,
    })
  );

export type SearchAlgoliaLocalBrandsParams = z.infer<
  typeof SearchAlgoliaLocalBrandsParamsSchema
>;
