import styled from '@emotion/styled';

interface StyledTableFootProps {
  fixed: boolean;
}

export const StyledTableFoot = styled.tfoot<StyledTableFootProps>(
  { zIndex: 10, backgroundColor: 'inherit', bottom: 0 },
  ({ fixed }) => ({
    position: fixed ? 'sticky' : 'relative',
  })
);
