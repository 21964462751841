import React, { useEffect } from 'react';

import useObjectState from '../../../../../../hooks/useObjectState';
import { InitiateUnlockWallet } from './initiateUnlockWallet';
import { VerificationCodeForm } from './verificationCodeForm';

interface UnlockWalletState {
  code: string;
  displayError: boolean;
  sendingCode: boolean;
  unlockInitiated: boolean;
  validationError?: string;
}

const VALIDATION_ERROR = 'Code must be 4 digits';

const FOUR_DIGIT = /^\d{4}$/;

export const UnlockWalletForm = ({
  onSendCode,
  onSubmit,
  phone,
  unlockLoading,
}: {
  onSendCode: () => Promise<any>;
  onSubmit: (code: string) => void;
  phone: string | null;
  unlockLoading: boolean;
}) => {
  const [
    { code, validationError, unlockInitiated, sendingCode, displayError },
    setUnlockWalletState,
  ] = useObjectState<UnlockWalletState>({
    code: '',
    unlockInitiated: false,
    sendingCode: false,
    displayError: false,
  });

  useEffect(() => {
    const valid = FOUR_DIGIT.test(code);
    setUnlockWalletState({
      validationError: valid ? undefined : VALIDATION_ERROR,
      displayError: false,
    });
  }, [code]);

  const onFormSubmit = (e: React.FormEvent | React.MouseEvent) => {
    e.preventDefault();

    if (validationError) {
      return;
    }

    onSubmit(code);
  };

  if (!unlockInitiated) {
    const handleSendCode = async (e: React.MouseEvent) => {
      e.preventDefault();

      setUnlockWalletState({ sendingCode: true });
      try {
        await onSendCode();
      } catch (_) {
        // TODO(elliot): Show better error message?
        setUnlockWalletState({ sendingCode: false });
        return;
      }
      setUnlockWalletState({ unlockInitiated: true, sendingCode: false });
    };

    return (
      <InitiateUnlockWallet
        phone={phone || ''}
        onSendCode={handleSendCode}
        loading={sendingCode}
      />
    );
  }

  return (
    <VerificationCodeForm
      onSubmit={onFormSubmit}
      onCancel={() => setUnlockWalletState({ unlockInitiated: false })}
      onChange={(value: string) => setUnlockWalletState({ code: value })}
      onBlur={() => setUnlockWalletState({ displayError: true })}
      error={validationError}
      displayError={displayError}
      unlockLoading={unlockLoading}
    />
  );
};
