import type { MouseEvent, ReactElement } from 'react';
import React from 'react';

import { StyledButtonToggle } from './buttonToggle.styles';
import type { ButtonToggleButtonProps } from './buttonToggleButton/buttonToggleButton';

export interface ButtonToggleProps {
  /** Aria label */
  ariaLabel?: string;

  /** Only allows `ButtonToggle.Button` components as children */
  children: ReactElement<ButtonToggleButtonProps>[];

  /** Stretches the container to fit its parent; buttons positioned with space-between */
  full?: boolean;

  /** `onChange` handler, returns the value of the clicked button */
  onChange: (value: string | number) => void;

  /** Id used for testing */
  testId?: string;

  /** The currently selected value */
  value: string | number;

  /** Set the variant of button to display */
  variant?: 'primary' | 'inverse' | 'tertiary';
}

/**
 * The `ButtonToggle` component allows the user to toggle between a series of options.
 * An `onChange` handler will be fired when the selected option is changed.
 */
export function ButtonToggle({
  ariaLabel,
  children,
  full = true,
  onChange,
  testId,
  value,
  variant = 'primary',
}: ButtonToggleProps) {
  return (
    <StyledButtonToggle full={full} data-testid={testId} aria-label={ariaLabel}>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          full,
          variant,
          selected: child.props.value === value,
          onClick: (e: MouseEvent<HTMLButtonElement>) => {
            child.props?.onClick && child.props.onClick(e);
            onChange(child.props.value);
          },
        })
      )}
    </StyledButtonToggle>
  );
}
