import { useMobileMediaQuery } from '@jane/reefer';
import type { MenuProduct, Product, Recommendation } from '@jane/shared/models';

import {
  imageSrcSet,
  productSrcWidths,
} from '../../component-library/image/image';
import productPhotoMetadata from '../../lib/productPhotoMetadata';

export const useImage = (product: Product | MenuProduct | Recommendation) => {
  const isMobile = useMobileMediaQuery({});

  const photoMetadata = productPhotoMetadata(product);

  // TODO(elliot): Deprecate old component library image src set when reefer images can handle responsive images.
  const { srcSet, fallbackSrc } = imageSrcSet(
    photoMetadata.url,
    productSrcWidths,
    200
  );

  const imageDimension = isMobile ? '150px' : '200px';

  return {
    srcSet,
    fallbackSrc,
    imageDimension,
  };
};
