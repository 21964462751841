import type {
  BaseTypographyVariantConfig,
  BaseTypographyVariants,
  BoldTypographyVariantConfig,
  BoldTypographyVariants,
  TypographyConfig,
  TypographyVariantConfig,
} from '../../types';
import { ObjectKeys } from '../../utils';

const WEIGHT_SEMIBOLD = 600;
const WEIGHT_REGULAR = 400;

const baseVariantConfig: BaseTypographyVariantConfig = {
  body: {
    desktopStyles: {
      fontSize: '16px',
      lineHeight: '24px',
    },
    fontWeight: WEIGHT_REGULAR,
    mobileStyles: {
      fontSize: '15px',
      lineHeight: '24px',
    },
    textTransform: 'none',
  },
  caps: {
    desktopStyles: {
      fontSize: '14px',
      lineHeight: '24px',
    },
    fontWeight: WEIGHT_REGULAR,
    letterSpacing: '0.1em',
    mobileStyles: {
      fontSize: '13px',
      lineHeight: '16px',
    },
    textTransform: 'uppercase',
  },
  header: {
    desktopStyles: {
      fontSize: '24px',
      lineHeight: '32px',
    },
    fontWeight: WEIGHT_REGULAR,
    mobileStyles: {
      fontSize: '20px',
      lineHeight: '24px',
    },
    textTransform: 'none',
  },
  mini: {
    desktopStyles: {
      fontSize: '14px',
      lineHeight: '24px',
    },
    fontWeight: WEIGHT_REGULAR,
    mobileStyles: {
      fontSize: '13px',
      lineHeight: '16px',
    },
    textTransform: 'none',
  },
  title: {
    branded: true,
    desktopStyles: {
      fontSize: '36px',
      lineHeight: '40px',
    },
    fontWeight: WEIGHT_REGULAR,
    mobileStyles: {
      fontSize: '28px',
      lineHeight: '32px',
    },
    textTransform: 'none',
  },
};

const boldVariantConfig = ObjectKeys<BaseTypographyVariants>(baseVariantConfig)
  .map<BoldTypographyVariantConfig>((value) => {
    const variantKey: BoldTypographyVariants = `${value}-bold`;
    const obj: TypographyVariantConfig<string> = {
      [variantKey]: {
        ...baseVariantConfig[value],
        fontWeight: WEIGHT_SEMIBOLD,
      },
    };
    return obj as TypographyVariantConfig<BoldTypographyVariants>;
  })
  .reduce((prev, curr) => ({ ...prev, ...curr }));

export const Typography: TypographyConfig = {
  componentMapping: {
    body: 'p',
    caps: 'p',
    header: 'h2',
    mini: 'p',
    title: 'h1',
  },
  fonts: {
    branded: {
      fontFamily: 'Euclid Circular B',
    },
    default: {
      fontFamily: 'Source Sans Pro',
    },
  },
  variants: {
    ...baseVariantConfig,
    ...boldVariantConfig,
  },
};
