import { styled } from '@jane/reefer';

const StyledImage = styled.img({
  transition: '0.3s opacity',
  width: '100%',
  height: '100%',
});

interface Props {
  alt: string;
  fallbackWidth?: number;
  src: string;
  srcWidths: number[];
}

export const imageSrcSet = (
  src: string,
  srcWidths: number[],
  fallbackWidth?: number
) => {
  fallbackWidth = fallbackWidth ?? srcWidths[0];

  const url = new URL(src);
  const origin = url.origin;
  const pathname = url.pathname;
  const cloudflareBase = '/cdn-cgi/image/';
  const productSrcSizes = '(max-width: 600px) 800px, 1000px, 1200px'; // should match productSrcWidths

  const checkIOSVersion = () => {
    const agent = window?.navigator?.userAgent;
    if (!agent) return 0;
    const start = agent.indexOf(' OS ');
    if ((agent.includes('iPhone') || agent.includes('iPad')) && start > -1) {
      return window.Number(agent.substr(start + 3, 3).replace('_', '.'));
    }
    return 0;
  };

  const cloudflareSrc = (resizeWidth: number, addPixelWidth?: boolean) => {
    // See: https://github.com/janetechinc/iheartjane/pull/9145
    const format = checkIOSVersion() === 16 ? 'webp' : 'auto';

    const cloudflareOptions = `width=${resizeWidth},fit=scale-down,format=${format},metadata=none`;
    const srcSetPixelWidth = addPixelWidth ? ` ${resizeWidth}w` : '';
    return (
      origin + cloudflareBase + cloudflareOptions + pathname + srcSetPixelWidth
    );
  };

  const srcSetList = srcWidths.map((width) => cloudflareSrc(width, true));

  const fallbackSrc = cloudflareSrc(fallbackWidth);

  const srcSet = srcSetList.join(',');

  return { srcSet, fallbackSrc, sizes: productSrcSizes };
};

export const productSrcWidths = [100, 200, 400, 800, 1000, 1200];

export const ProductImage = ({
  src,
  alt,
  fallbackWidth = 200,
}: {
  alt: string;
  fallbackWidth?: number;
  src: string;
}) => {
  return (
    <Image
      src={src}
      alt={alt}
      fallbackWidth={fallbackWidth}
      srcWidths={productSrcWidths}
    />
  );
};

const Image = ({ src, srcWidths, fallbackWidth, alt }: Props) => {
  const { srcSet, fallbackSrc } = imageSrcSet(src, srcWidths, fallbackWidth);

  return (
    <StyledImage src={fallbackSrc} srcSet={srcSet} alt={alt} loading="lazy" />
  );
};
