import lottie from 'lottie-web';
import { useEffect, useRef } from 'react';

import { StyledLottie } from './lottie.styles';

/** Custom Lottie player to render animation */

export interface LottieProps {
  /** Exported Lottie animation as JSON */
  animationData: any;

  /** Height of container */
  height: number;

  /** Element title */
  title: string;

  /** Width of container */
  width: number;
}

export const Lottie = ({
  animationData,
  width,
  height,
  title,
}: LottieProps) => {
  const element = useRef<HTMLDivElement>(null);
  const lottieInstance = useRef<any>();

  useEffect(() => {
    if (element.current) {
      lottieInstance.current = lottie.loadAnimation({
        animationData,
        container: element.current,
        renderer: 'canvas',
        loop: true,
        autoplay: true,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      });
    }
    return () => {
      lottieInstance.current?.destroy();
    };
  }, [animationData]);

  return (
    <StyledLottie ref={element} height={height} width={width} title={title} />
  );
};
