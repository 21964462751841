import { config } from '@jane/shared/config';

import { api } from './api';

const dmPath = config.dmUrl;

export const dmApi = {
  get: <T>(url: string, params?: RequestInit): Promise<T> =>
    api.get(`${dmPath}${url}`, params),
  post: <T>(url: string, data: unknown, params?: RequestInit): Promise<T> =>
    api.post(`${dmPath}${url}`, data, params),
  patch: <T>(url: string, data: unknown, params?: RequestInit): Promise<T> =>
    api.patch(`${dmPath}${url}`, data, params),
  delete: <T>(url: string, params?: RequestInit): Promise<T | null> =>
    api.delete(`${dmPath}${url}`, params),
};
