import type {
  Identification,
  ReservationMode,
  StoreSpecial,
} from '@jane/shared/models';

import { isEligibleForGroup } from './qualifiedGroup';

export const isEligibleForGroupDiscount = ({
  document,
  special,
  timezone,
  reservationMode,
}: {
  document: Identification;
  reservationMode: ReservationMode;
  special: StoreSpecial | undefined;
  timezone: string;
}): boolean => {
  if (!(special && special.conditions.qualified_group)) return false;

  if (reservationMode === 'kiosk') return true;

  const requiredAge =
    'required_age' in special.conditions.qualified_group
      ? special.conditions.qualified_group.required_age
      : undefined;

  return isEligibleForGroup({
    document,
    timezone,
    groupType: special.conditions.qualified_group.type,
    requiredAge,
  });
};
