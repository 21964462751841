interface Args {
  messageType: string;
  payload?: any;
  setOptions?: {
    preventContainerResizing?: boolean;
    preventResizing?: boolean;
  };
}

export const postMessageToIframeParent = ({
  messageType,
  payload,
  setOptions,
}: Args) => {
  const pathname = window.location.href;
  window.parent.postMessage(
    { messageType, payload, pathname, setOptions },
    '*'
  );
};
