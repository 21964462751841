import { useEffect, useState } from 'react';

import { DrawerContext } from './drawer.context';
import { StyledDrawer } from './drawer.styles';
import type { DrawerProps } from './drawer.types';

/**
 * `Drawer` component is a modal that slides from the side of the screen
 * with available headers and footers, or full-screen drawers.
 */

export const Drawer = ({
  background = 'grays-white',
  children,
  container = 'body',
  fitScreen = true,
  full = false,
  height = 'fit-content',
  open,
  onRequestClose,
  overlayClose = true,
  placement = 'right',
  width = 'fit-content',
}: DrawerProps) => {
  const [closing, setClosing] = useState(false);
  let timeout: ReturnType<typeof setTimeout>;

  const onClose = () => {
    setClosing(true);
    document.body.style.overflow = 'unset';
    timeout = setTimeout(() => {
      onRequestClose();
      setClosing(false);
    }, 475);
  };

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      setClosing(false);
      clearTimeout(timeout);
      document.body.style.overflow = 'unset';
    };
  }, [open]);

  return (
    <DrawerContext.Provider value={{ onRequestClose: onClose, fitScreen }}>
      <StyledDrawer
        background={background}
        closing={closing}
        container={container}
        fitScreen={fitScreen}
        full={full}
        height={height}
        onRequestClose={onClose}
        open={open}
        overlayClose={overlayClose}
        placement={placement}
        width={width}
      >
        {children}
      </StyledDrawer>
    </DrawerContext.Provider>
  );
};
