import type { PropsWithoutRef, ReactNode } from 'react';
import type { FieldMetaState } from 'react-final-form';
import { Field } from 'react-final-form';

import { useTheme } from '@jane/reefer';

import FieldControl from './field-control';
import HelperText from './helper-text';
import Input from './input';
import Label from './label';

export type Variant = 'material' | 'border';

export interface TextInputProps
  extends PropsWithoutRef<JSX.IntrinsicElements['input']> {
  error?: boolean;
  helperText?: ReactNode;
  id: string;
  isFocused?: boolean;
  label?: string;
  labelId?: string;
  success?: boolean;
  variant?: Variant;
}

const TextInput = ({
  label,
  labelId,
  defaultValue,
  disabled,
  placeholder,
  helperText,
  variant,
  isFocused,
  error,
  success,
  name,
  id,
  ...props
}: TextInputProps) => {
  const theme = useTheme();
  return (
    <FieldControl
      isFocused={isFocused}
      variant={variant}
      error={error}
      success={success}
    >
      <Label id={labelId} htmlFor={id} disabled={disabled}>
        {label}
      </Label>
      <Input
        id={id}
        aria-describedby={helperText ? `${id}-helper` : undefined}
        aria-invalid={error ? 'true' : 'false'}
        defaultValue={defaultValue}
        disabled={disabled}
        placeholder={placeholder}
        name={name}
        css={
          error && variant === 'border'
            ? { border: `1px solid ${theme.colors.error}` }
            : {}
        }
        {...props}
      />
      {helperText && <HelperText id={`${id}-helper`}>{helperText}</HelperText>}
    </FieldControl>
  );
};

export interface TextInputFieldProps extends TextInputProps {
  name: string;
  renderSubmitError?: (meta: FieldMetaState<any>) => ReactNode;
}

export const TextInputField = (props: TextInputFieldProps) => (
  <Field type={props.type} label={props.label} name={props.name}>
    {({ meta, input }) => {
      const hasError = (meta.error || meta.submitError) && meta.touched;
      // Show error or helper text
      const renderError = () => {
        if (!hasError) return null;
        if (props.renderSubmitError && meta.submitError)
          return props.renderSubmitError(meta.submitError);
        return meta.error || meta.submitError;
      };
      const helperText = hasError ? renderError() : props.helperText;

      // Overwrite helperText and error props based on final form data.
      return (
        <TextInput
          {...props}
          {...input}
          helperText={helperText}
          error={hasError}
        />
      );
    }}
  </Field>
);

export default TextInput;
