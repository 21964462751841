import { forwardRef } from 'react';

import { useDrawerContext } from '../drawer.context';
import type { DrawerContentProps } from '../drawer.types';
import { StyledDrawerContent } from './drawerContent.styles';

export const DrawerContent = forwardRef<HTMLDivElement, DrawerContentProps>(
  ({ children, noPadding = false }, ref) => {
    const { fitScreen } = useDrawerContext();
    return (
      <StyledDrawerContent
        ref={ref}
        noPadding={noPadding}
        fitScreen={fitScreen}
      >
        {children}
      </StyledDrawerContent>
    );
  }
);
