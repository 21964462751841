import { useContext } from 'react';

import { useUserPreferences } from '@jane/shared-ecomm/providers';
import type { Store } from '@jane/shared/models';

import { BrandPartnerContext } from '../common/providers/brandPartnerProvider';
import { useCustomerSelector } from '../customer/selectors';
import { get } from '../redux-util/selectors';

export const useProductReviewsEnabled = (currentStore?: Store | null) => {
  const { userLocation } = useUserPreferences();
  const { cityState } = userLocation;

  const { appMode } = useCustomerSelector(get('embeddedApp'));
  const brandPartner = useContext(BrandPartnerContext);

  // Product reviews not allowed in Ohio
  if (cityState?.match(/, OH$/)) {
    return false;
  }

  if (appMode === 'default') {
    if (currentStore) {
      return !currentStore.force_disable_reviews;
    } else {
      return true;
    }
  }
  if (brandPartner) return brandPartner.product_reviews_enabled;
  if (currentStore) return currentStore.product_reviews_enabled;
  return true;
};
