import type { FormEvent, MouseEvent, PropsWithoutRef, ReactNode } from 'react';

import { styled } from '@jane/reefer';

import { spacing } from '../../style';
import {
  useItemExpanded,
  useItemId,
  useSetItemExpanded,
} from './accordion-item';

interface Props {
  children: ReactNode;
  onChange?: (e: FormEvent<HTMLButtonElement>, expanded: boolean) => void;
  onWillClose?: () => void | boolean;
}

const StyledButton = styled.button({
  width: '100%',
  border: 'none',
  ...spacing({ m: 0, p: 0 }),
  textAlign: 'left',
  boxSizing: 'border-box',
  display: 'block',
  cursor: 'pointer',
  ':disabled': {
    cursor: 'auto',
  },
});

const AccordionHeading = ({
  children,
  onChange,
  onClick,
  ...props
}: Props &
  Omit<PropsWithoutRef<JSX.IntrinsicElements['button']>, 'onChange'>) => {
  const expanded = useItemExpanded();
  const setExpanded = useSetItemExpanded();
  const id = useItemId();

  // Expand on click
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    onClick && onClick(e);
    setExpanded(!expanded);
  };

  return (
    <StyledButton
      id={`accordion-heading-${id}`}
      data-testid={`accordion-heading-${id}`}
      aria-expanded={expanded}
      aria-controls={`accordion-content-${id}`}
      onClick={handleClick}
      onMouseDown={(e) => e.preventDefault()}
      {...props}
      type="button"
    >
      {children}
    </StyledButton>
  );
};

export default AccordionHeading;
