import { getNodeText } from '../../../utils';
import { LoadingLabel } from '../../button/button.styles';
import { loadingColor } from '../../button/button.utils';
import { Loading } from '../../loading/loading';
import { Typography } from '../../typography/typography';
import type { CardActionProps } from '../card.types';
import { StyledCardAction, StyledCardActionButton } from './cardAction.styles';

/**
 * The `Card.Action` component can be included as a child of a `Card` component to add an
 * action button to the `Card`. Should be used below `Card.Content`.
 * */

export function CardAction({
  ariaLabel,
  disabled,
  endIcon,
  label,
  loading = false,
  onClick,
  testId,
  type = 'button',
  variant = 'primary',
}: CardActionProps) {
  const stringLabel = getNodeText(label);

  return (
    <StyledCardAction>
      <StyledCardActionButton
        aria-label={ariaLabel}
        data-testid={testId}
        disabled={disabled}
        full={true}
        isLoading={loading}
        name={stringLabel}
        onClick={onClick}
        type={type}
        variant={variant}
      >
        {loading && <Loading color={loadingColor(variant)} />}
        <LoadingLabel isLoading={loading}>
          <Typography variant="body-bold" as="span">
            {label}
          </Typography>
          {endIcon}
        </LoadingLabel>
      </StyledCardActionButton>
    </StyledCardAction>
  );
}
