import forEach from 'lodash/forEach';
import { useEffect, useRef } from 'react';

import { useUserPreferences } from '@jane/shared-ecomm/providers';
import { extractTrackingTags } from '@jane/shared-ecomm/tracking';
import { config } from '@jane/shared/config';
import {
  getFlagUser,
  useFlagClient,
  useFlags,
} from '@jane/shared/feature-flags';
import { getLiveTests } from '@jane/shared/util';

import {
  isEmbeddedModeSelector,
  useCustomerSelector,
} from '../customer/selectors';
import { get } from '../redux-util/selectors';

interface IdentifyCustomerProps {
  app: 'embed' | 'marketplace' | 'brandEmbed' | 'operatorEmbed' | 'headless';
  appStoreId?: number | string;
  brandPartnerId?: number | string;
  operatorId?: number | string;
  queryParams?: {};
}

const IdentifyCustomer = ({
  app,
  appStoreId,
  brandPartnerId,
  operatorId,
  queryParams,
}: IdentifyCustomerProps) => {
  const flagClient = useFlagClient();
  const customer = useCustomerSelector(get('customer'));
  const { janeDeviceId } = useCustomerSelector(get('application'));
  const isEmbeddedMode = useCustomerSelector(isEmbeddedModeSelector);
  const { userLocation } = useUserPreferences();
  const signedIn = useRef(false);
  const featureFlags = useFlags();

  useEffect(() => {
    if (!janeDeviceId) return;
    flagClient?.identify(getFlagUser(janeDeviceId, customer));
  }, [flagClient, janeDeviceId, customer]);

  useEffect(() => {
    const trackingTags = extractTrackingTags(queryParams);
    const mappedFeatureFlags = {};
    Object.keys(featureFlags).forEach((flag) => {
      mappedFeatureFlags[`Flag_${flag}`] = featureFlags[flag];
    });

    if (customer.id) {
      signedIn.current = true;
      mixpanel.identify(customer.id.toString());
      mixpanel.people.set({
        $email: customer.email,
        $last_login: new Date(),
        $first_name: customer.firstName || customer.nickname,
        $last_name: customer.lastName,
        Nickname: customer.nickname,
        Phone: customer.phone,
        'User Id': customer.id,
        cityState: userLocation.cityState,
        coordinates: userLocation.coordinates,
        ...mappedFeatureFlags,
        ...trackingTags,
      });

      // add first touch attribution tags to user profile
      const ogTrackingTags = {};
      forEach(
        trackingTags,
        (value, key) =>
          ((ogTrackingTags as any)[key + ' [first touch]'] = value)
      );
      mixpanel.people.set_once(ogTrackingTags);
    } else if (signedIn.current) {
      mixpanel.reset();
    }

    // set utm tags as super properties to enable last touch attribution
    // https://help.mixpanel.com/hc/en-us/articles/360001337103-Last-Touch-UTM-Tags
    mixpanel.register({
      app,
      janeDeviceId,
      appStoreId: appStoreId || null,
      brandPartnerId: brandPartnerId || null,
      operatorId: operatorId || null,
      userCityState: userLocation.cityState,
      userCoordinates: userLocation.coordinates,
      build: config.buildVersion?.substring(0, 7),
      ...mappedFeatureFlags,
      ...getLiveTests(customer.id),
      ...trackingTags,
    });
  }, [
    app,
    appStoreId,
    brandPartnerId,
    customer.email,
    customer.firstName,
    customer.id,
    customer.lastName,
    customer.nickname,
    customer.phone,
    featureFlags,
    isEmbeddedMode,
    janeDeviceId,
    operatorId,
    queryParams,
    userLocation.cityState,
    userLocation.coordinates,
  ]);

  return null;
};

export default IdentifyCustomer;
