import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Drawer, Flex } from '@jane/reefer';
import { useUserPreferences } from '@jane/shared-ecomm/providers';
import { trackCheckoutClick } from '@jane/shared-ecomm/tracking';
import { useGetStore } from '@jane/shared/data-access';
import type { PendingCartProduct } from '@jane/shared/models';
import { useRuntimeConfig } from '@jane/shared/runtime-config';

import { useCustomerDispatch, useCustomerSelector } from '../../../../customer';
import { stopShowBundlePossibilities } from '../../../../customer/redux/bundlePossibilities';
import {
  closeCart,
  removeDeliveryAddress,
} from '../../../../customer/redux/cart';
import { useGuestCheckoutEnabled } from '../../../../hooks/useGuestCheckoutEnabled';
import { useSource } from '../../../../hooks/useSource';
import { getLocalizedMenuProductContent } from '../../../../lib/localizeProduct';
import { paths } from '../../../../lib/routes';
import { snakeCaseToCamelCaseStoreDeserializer } from '../../../../lib/store';
import { applicableFreeDeliveryConfig } from '../../../../lib/userFreeDeliveryConfigs';
import { getCurrentStoreSpecials } from '../../../../sources/storeSpecials';
import { productDetailLink as getProductDetailLink } from '../../../storeMenu/productCardHelper';
import { CartToppers } from '../../cartToppers';
import { CartDrawerAddress } from '../cartDrawerAddress';
import { CartDrawerAlerts } from '../cartDrawerAlerts';
import { CartDrawerCancerWarning } from '../cartDrawerCancerWarning';
import { CartDrawerDiscountTerms } from '../cartDrawerDiscountTerms/cartDrawerDiscountTerms';
import { CartDrawerFooter } from '../cartDrawerFooter';
import {
  useGetCartLineItems,
  useIsCheckoutDisabled,
  useValidateDeliveryAddress,
} from '../cartDrawerHooks';
import { CartDrawerItem } from '../cartDrawerItems';
import { CartDrawerSpecials } from '../cartDrawerSpecials';
import { CartDrawerSummary } from '../cartDrawerSummary';
import { CartDrawerToggle } from '../cartDrawerToggle';
import { FinePrintWrapper, ItemsWrapper } from './cartDrawerStates.styles';

const getInitialDeliveryAddress = (deliveryAddress) => {
  return deliveryAddress?.street
    ? [
        deliveryAddress.street,
        deliveryAddress.city,
        deliveryAddress.state_code,
      ].join(', ')
    : undefined;
};

export const CartDrawer = () => {
  const {
    appMode,
    cart,
    deliveryAddress,
    id: customerId,
  } = useCustomerSelector(
    ({
      cart: { cart, deliveryAddress },
      customer: { id },
      embeddedApp: { appMode },
    }) => ({
      appMode,
      cart,
      deliveryAddress,
      id,
    })
  );
  const contentRef = useRef(null);
  const { data: store } = useGetStore(cart?.store.id);
  const navigate = useNavigate();
  const isGuestCheckoutEnabled = useGuestCheckoutEnabled();
  const dispatch = useCustomerDispatch();
  const [schedules, setSchedules] = useState(
    snakeCaseToCamelCaseStoreDeserializer(store)
  );

  const [address, setAddress] = useState(
    getInitialDeliveryAddress(deliveryAddress)
  );

  const { data: specialsData } = useSource(
    getCurrentStoreSpecials(cart?.store.id)
  );

  const specials = specialsData ? specialsData.specials : [];

  const {
    deliveryValidationData,
    addressErrorMessage,
    resetAddressValidation,
    googleValidatedAddress,
  } = useValidateDeliveryAddress({
    store,
    address,
    street2: deliveryAddress?.street2,
  });

  const { isFramelessEmbedMode } = useRuntimeConfig();
  const { setUserLocation } = useUserPreferences();

  // this is the only way of retrieving customer location data in boost/frameless embed
  // since the marketplace is essentially scoped to a single store and the user is not prompted
  // to share their location when browsing products.

  useEffect(() => {
    if (isFramelessEmbedMode && googleValidatedAddress) {
      // set in local storage
      setUserLocation({
        ...googleValidatedAddress,
        hasResetLocation: true,
      });
    }
  }, [googleValidatedAddress, isFramelessEmbedMode, setUserLocation]);

  useEffect(() => {
    if (contentRef.current) {
      contentRef?.current?.scrollTo(0, 0);
    }
  }, [cart.reservation_mode]);

  useEffect(() => {
    if (store) {
      // TODO(Kyr): move this into the `useGetStore` hook so we don't have to repeatedly deserialize
      setSchedules(snakeCaseToCamelCaseStoreDeserializer(store));
    }
  }, [store]);

  const { products, reservation_mode, localized_products_content } = cart;

  const cartLineItems = useGetCartLineItems();

  const checkoutDisabled = useIsCheckoutDisabled({
    cartLineItems,
    deliveryValidationData,
    store,
    schedules,
  });

  const checkoutPath =
    !!customerId || !isGuestCheckoutEnabled
      ? paths.checkout()
      : paths.guestCheckout();

  const handleSubmit = async () => {
    dispatch(stopShowBundlePossibilities());

    trackCheckoutClick();
    const storeIsAcceptingReservations =
      schedules?.isCurrentlyAcceptingReservations(reservation_mode);

    if (reservation_mode !== 'delivery') {
      dispatch(removeDeliveryAddress());
    }

    if (storeIsAcceptingReservations) {
      dispatch(closeCart());
      navigate(checkoutPath);
    }
  };

  const handleSetAddress = (address: string) => {
    setAddress(address);
  };

  const cartSpecials = specials.find(
    (special) => special.id === cart.cart_discount_special_id
  )?.id;

  const appliedSpecials = cart?.products
    .map((product) => product.special_id)
    .filter((special, i, arr) => !!special && arr.indexOf(special) === i)
    .concat([cartSpecials])
    .filter(Boolean);

  const freeDeliveryMinimum = applicableFreeDeliveryConfig({
    freeDeliveryConfigs: store.free_delivery_configs,
    discountedSubtotal: cartLineItems?.discountedSubtotal,
  });

  return (
    <>
      <Drawer.Header
        divider
        title="Bag"
        subtitle={store?.name ? `${store.name}, ${store.city}` : ''}
      >
        <CartDrawerToggle store={store} schedules={schedules} />
      </Drawer.Header>

      <Drawer.Content noPadding ref={contentRef}>
        <Box background="grays-ultralight" height="100%" width="100%">
          <Flex flexDirection="column" height="100%">
            {reservation_mode === 'delivery' && (
              <CartDrawerAddress
                addressErrorMessage={addressErrorMessage}
                initialDeliveryAddress={address}
                handleSetAddress={handleSetAddress}
                resetAddressValidation={resetAddressValidation}
              />
            )}
            {checkoutDisabled && (
              <CartDrawerAlerts
                cartLineItems={cartLineItems}
                deliveryValidationData={deliveryValidationData}
              />
            )}
            <ItemsWrapper>
              <Flex flexDirection="column">
                {products.map((product: PendingCartProduct) => {
                  const productDetailLink = getProductDetailLink({
                    product,
                    appMode,
                    store,
                  });

                  const localizedProduct = getLocalizedMenuProductContent(
                    product,
                    localized_products_content
                  );
                  return (
                    <CartDrawerItem
                      key={product?.id + product.price_id}
                      product={localizedProduct}
                      productDetailLink={productDetailLink}
                      specials={specials}
                    />
                  );
                })}
              </Flex>
            </ItemsWrapper>

            <CartDrawerSpecials specials={specials} />

            <CartDrawerSummary cartLineItems={cartLineItems} />

            <CartToppers isDrawer={true} />

            {(appliedSpecials.length > 0 ||
              cartLineItems.discountTotal > 0 ||
              store.state === 'California') && (
              <FinePrintWrapper flexDirection="column">
                {(appliedSpecials.length > 0 ||
                  cartLineItems.discountTotal > 0) && (
                  <CartDrawerDiscountTerms
                    appliedSpecials={appliedSpecials}
                    freeDeliveryMinimum={
                      reservation_mode === 'delivery' &&
                      freeDeliveryMinimum?.cart_minimum
                    }
                    storeId={store.id}
                    storeName={store?.name}
                    specialLabel={
                      store.store_compliance_language_settings?.specials
                    }
                  />
                )}

                {store.state === 'California' && <CartDrawerCancerWarning />}
              </FinePrintWrapper>
            )}
          </Flex>
        </Box>
      </Drawer.Content>

      <CartDrawerFooter
        checkoutDisabled={checkoutDisabled}
        deliveryValidationData={deliveryValidationData}
        handleSubmit={handleSubmit}
        total={cartLineItems.total}
        specials={specials}
      />
    </>
  );
};
