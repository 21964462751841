import { Box, Flex, Typography, useDesktopMediaQuery } from '@jane/reefer';
import { labelForWeightId } from '@jane/shared-ecomm/util';
import type { PriceId, ProductWeightSpecial } from '@jane/shared/models';
import { formatCurrency } from '@jane/shared/util';

import { useMediaQuery } from '../../../../common/providers/mediaQueryProvider';
import Button from '../../../../component-library/button';
import { StrikeThroughPrice } from '../../../productCard/strikeThroughPrice';
import { useProductCardContext } from '../../providers/productCardProvider';

const formatLabel = (label: string) => 1 * Number(parseFloat(label).toFixed(2));

const getPriceLabel = (price: string, weight: PriceId, amount: string) => {
  const priceLabel = formatCurrency(price);

  if (weight !== 'each') {
    return `${priceLabel}/${labelForWeightId(weight, amount)}`;
  }

  return priceLabel;
};

const getSpecialLabel = (special: ProductWeightSpecial | null) => {
  if (!special) {
    return null;
  }

  const { discount_type, discount_percent, discount_amount } = special;

  if (discount_type === 'percent') {
    return `-${formatLabel(discount_percent)}%`;
  } else {
    return `-$${formatLabel(discount_amount)}`;
  }
};

interface VariantSelectionButtonProps {
  onChange?: () => void;
  selected: boolean;
  weight?: PriceId;
}

export const VariantSelectionButton = ({
  onChange,
  selected,
  weight,
}: VariantSelectionButtonProps) => {
  const { currentSpecial, menuProduct } = useProductCardContext();
  const { smallerThanVerticalTablet } = useMediaQuery();

  const { amount, special_id } = menuProduct;
  // if there is no current_special(server request), don't use the discount price (Algolia might have stale data)
  const price = menuProduct[`price_${weight}`];
  const discountPrice =
    currentSpecial && menuProduct[`discounted_price_${weight}`];
  const special = menuProduct[`special_price_${weight}`];
  const priceLabel = getPriceLabel(discountPrice || price, weight, amount);
  const specialLabel = currentSpecial && getSpecialLabel(special);

  const smallerThanDesktop = useDesktopMediaQuery({ size: 'sm', width: 'max' });

  const showDiscountPrice =
    currentSpecial && special_id && discountPrice && discountPrice !== price;

  return (
    <Button
      css={{
        minWidth: showDiscountPrice && !smallerThanDesktop ? 195 : 140,
        width: smallerThanVerticalTablet ? '100%' : undefined,
        height: showDiscountPrice ? 72 : 48,
      }}
      variant={selected ? 'tertiary-selected' : 'tertiary'}
      onClick={() => onChange && onChange()}
    >
      <Flex flexDirection="column" justifyContent="center" height="100%">
        <Flex justifyContent="center" height="100%" alignItems="center">
          <Typography variant="body-bold" as="span">
            {priceLabel}
          </Typography>
          {showDiscountPrice && !smallerThanDesktop && (
            <Box ml={4}>
              <StrikeThroughPrice>{formatCurrency(price)}</StrikeThroughPrice>
            </Box>
          )}
        </Flex>
        <Flex justifyContent="center">
          {showDiscountPrice && smallerThanDesktop && (
            <Box mr={4}>
              <StrikeThroughPrice>{formatCurrency(price)}</StrikeThroughPrice>
            </Box>
          )}
          {showDiscountPrice && specialLabel && (
            <Flex height="100%" alignItems="center">
              <Typography variant="mini-bold" color="sunset">
                {specialLabel}
              </Typography>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Button>
  );
};
