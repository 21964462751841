import { useLDClient, useLDClientError } from 'launchdarkly-react-client-sdk';

export const useFlagClient = useLDClient;
export const useFlagClientError = useLDClientError;

export { asyncFlagProvider } from './flagProvider';
export * from './eventNames';
export * from './flagConfigs';
export * from './storeIdentification';
export * from './useFlagHelper';
export { useFlags } from './useFlags';
export * from './userIdentification';
