import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const GetActive = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.029 0.285316C12.0818 -0.28298 10.8533 0.0241503 10.285 0.971311L4.28501 10.9713C3.9143 11.5892 3.90459 12.3587 4.2596 12.9857C4.61461 13.6127 5.27946 14.0003 6 14.0003H14.4676L10.285 20.9713C9.71672 21.9185 10.0238 23.147 10.971 23.7153C11.9182 24.2836 13.1467 23.9765 13.715 23.0293L19.715 13.0293C20.0857 12.4114 20.0954 11.6419 19.7404 11.0149C19.3854 10.3879 18.7205 10.0003 18 10.0003H9.53238L13.715 3.02929C14.2833 2.08213 13.9762 0.853613 13.029 0.285316Z"
      fill="#FF5500"
    />
  </svg>
);
export const GetActiveIcon = ({ ...props }: StaticIconProps) => {
  return <Icon icon={GetActive} {...props} color={undefined} />;
};
