import { useCustomerSelector } from '../../customer/selectors';
import { get } from '../../redux-util/selectors';
import { Box, Flex } from '../../style/layout';
import type { IdentificationProps } from './idUploader';
import { IdentificationUploader, ValidationIcon } from './idUploader';
import { WarningMessage } from './warningMessage';

export const uploaderLabel = (
  customLanguage?: string | null,
  side?: string
): string => `Upload ${customLanguage || `Card ${side}`}`;

const MMJIdentification = ({
  showValidationErrors,
  valid,
  onIdUploadAttempt,
  onRemove,
  cardFrontComplianceLanguage,
  cardBackComplianceLanguage,
}: IdentificationProps) => {
  const { document, loading_mmj_card, loading_mmj_card_back } =
    useCustomerSelector(get('identification'));

  return (
    <>
      <Flex alignItems="center">
        <IdentificationUploader
          document={document}
          label={uploaderLabel(cardFrontComplianceLanguage, 'Front')}
          imageKey="mmj_card"
          inputName="mmj-card"
          isLoading={loading_mmj_card}
          valid={valid}
          showValidationErrors={showValidationErrors}
          onIdUploadAttempt={onIdUploadAttempt}
          onRemove={onRemove}
        />

        <Box ml={16}>
          <IdentificationUploader
            document={document}
            imageKey="mmj_card_back"
            inputName="mmj-card-back"
            label={uploaderLabel(cardBackComplianceLanguage, 'Back')}
            isLoading={loading_mmj_card_back}
            valid={valid}
            showValidationErrors={showValidationErrors}
            onIdUploadAttempt={onIdUploadAttempt}
            onRemove={onRemove}
          />
        </Box>
        {!!(document.mmj_card && document.mmj_card_back) && <ValidationIcon />}
      </Flex>
      {!(document.mmj_card || document.mmj_card_back) && (
        <Box mt={8}>
          <WarningMessage>ID required for discount</WarningMessage>
        </Box>
      )}
    </>
  );
};

export default MMJIdentification;
