import { Button, Flex, Modal, useMobileMediaQuery } from '@jane/reefer';
import { pluralize } from '@jane/shared/util';

import type { FilterModalFooterProps } from './filterModal.types';

export const FilterModalFooter = ({
  hideClear = false,
  onDeselect,
  onClose,
  totalResults,
  variant,
}: FilterModalFooterProps) => {
  const isMobile = useMobileMediaQuery({});
  const isSmallestMobile = useMobileMediaQuery({ size: 'sm', width: 'max' });

  const countLabel = pluralize({ noun: variant, number: totalResults });

  const noResultsLabel = `${countLabel} ${!isMobile ? 'available' : ''}`;

  const buttonLabel =
    totalResults === 0 ? noResultsLabel : `Show ${countLabel}`;

  return (
    <Modal.Footer>
      <Flex gap={16} flexDirection={isSmallestMobile ? 'column' : 'row'}>
        {!hideClear && (
          <Button
            label="Clear all"
            variant="tertiary"
            full
            onClick={() => onDeselect('all')}
          />
        )}
        <Button
          label={buttonLabel}
          full
          onClick={onClose}
          testId={`show-${variant}-button`}
          loading={totalResults === undefined}
          disabled={totalResults === 0}
        />
      </Flex>
    </Modal.Footer>
  );
};
