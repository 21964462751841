import { Button } from '@jane/reefer';

import CTAButton from './ctaButton';
import { Header } from './header';
import type { LocationModalScreen } from './index';

interface Props {
  appPartner: string;
  getLocationByIpAddress: () => void;
  setShowScreen: (screen: LocationModalScreen) => void;
}

const ErrorQuestion = ({
  appPartner,
  getLocationByIpAddress,
  setShowScreen,
}: Props) => (
  <>
    <Header>{`${appPartner} is unable to detect your location.`}</Header>
    <CTAButton
      onClick={() => setShowScreen('manualEntry')}
      text="Enter your location"
    />
    <Button
      css={{ width: '100%' }}
      label="Use nearest city"
      my={12}
      onClick={getLocationByIpAddress}
      variant="secondary"
    />
  </>
);

export default ErrorQuestion;
