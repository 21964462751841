import styled from '@emotion/styled';

import { hidden, mediaQueries, spacing } from '../../../../styling';
import type { MarginProperties } from '../../../../styling';
import { Flex } from '../../../flex';
import { Typography } from '../../../typography';

interface StyledFieldWrapperProps extends MarginProperties {
  // NOTE: This currently only applies to the search field
  disableMobileInputStyling?: boolean;
  disabled: boolean;
  isWrappingTextInput: boolean;
  labelHidden: boolean;
  repositionMobileHelperText: boolean;
  width: string | number;
}

export const StyledFieldWrapper = styled.div<StyledFieldWrapperProps>(
  ({
    theme,
    disabled,
    labelHidden,
    width,
    isWrappingTextInput,
    repositionMobileHelperText,
    disableMobileInputStyling,
    ...marginProps
  }) => ({
    opacity: disabled ? '0.3' : 'initial',
    ...spacing(marginProps),
    label: {
      ...hidden(labelHidden),
      display: 'block',
    },
    width,
    [mediaQueries.tablet('max')]: [
      {
        ...(isWrappingTextInput &&
          !disableMobileInputStyling && {
            ...spacing({ py: 16, px: 24 }),
            borderBottom: `1px solid ${theme.colors.grays.light}`,
            '&:focus-within': {
              marginBottom:
                typeof marginProps.mb === 'number' ? marginProps.mb - 1 : -1,
              borderBottom: `2px solid ${theme.colors.primary.main}`,
            },
          }),
        '> p': {
          ...hidden(!labelHidden && repositionMobileHelperText),
          ...spacing({
            mt: labelHidden && repositionMobileHelperText ? 16 : 0,
          }),
        },
      },
    ],
  })
);

export const StyledLabelWrapper = styled(Flex)({
  [mediaQueries.tablet('min')]: {
    '> p': {
      ...hidden(),
    },
  },
});

interface StyledLabelProps {
  disableMobileInputStyling: boolean;
  labelPosition: 't' | 'r';
}

export const StyledLabel = styled(Typography)<StyledLabelProps>(
  ({ disableMobileInputStyling, labelPosition }) => ({
    ...spacing({
      mb: labelPosition === 't' ? 16 : 0,
      ml: labelPosition === 'r' ? 16 : 0,
    }),
    [mediaQueries.tablet('max')]: [
      !disableMobileInputStyling && {
        ...spacing({
          mb: 0,
          ml: labelPosition === 'r' ? 8 : 0,
        }),
      },
    ],
  })
);
