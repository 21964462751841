import * as t from 'io-ts';

export const tInvoice = t.intersection([
  t.interface({
    id: t.string,
    issued: t.string,
    amount: t.string,
    store_id: t.number,
  }),
  t.partial({
    email: t.union([t.string, t.null]),
    paid: t.union([t.string, t.null]),
    currency: t.union([t.string, t.null]),
    payment_method: t.union([t.string, t.null]),
    reference_code: t.union([t.string, t.null]),
  }),
]);

export type Invoice = t.TypeOf<typeof tInvoice>;
