export interface ConditionalWrapperProps {
  /** The component's children */
  children: React.ReactNode;

  /** Whether or not to display children within wrapper */
  condition: boolean;

  /** Function called for wrapping children in React component */
  wrapper?: (children: React.ReactNode) => React.ReactElement;
}

/**
 * `ConditionalWrapper` is used to wrap `children` of the `ConditionalWrapper` in a wrapper component based on a condition.
 *
 * I.e. When the condition is true, the `children` will be wrapped in the wrapper.
 * When the condition is false, the `children` will not be wrapped in the wrapper.
 */
export const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: ConditionalWrapperProps) => {
  return condition && wrapper ? wrapper(children) : <>{children}</>;
};
