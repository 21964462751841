import { dmApi } from '@jane/shared/data-access';
import type { AdFlight, AppMode, DmMeta, Id } from '@jane/shared/models';

const cartToppersUrl = (storeId: Id) => `/stores/${storeId}/toppers`;

export interface FetchCartToppersParams {
  appMode: AppMode;
  cartProductIds: Id[];
  janeDeviceId: string;
  maxProducts: number;
  storeId: Id;
}

export interface FetchCartToppersResponse {
  cartToppers: BaseCartTopper[];
  maxPrice: number;
}

export interface BaseCartTopper {
  dmMeta?: DmMeta;
  flight?: AdFlight;
  mlScoringSignals?: Record<string, unknown>;
  product_id: number;
}

export const fetchCartToppers = async ({
  appMode,
  cartProductIds,
  janeDeviceId,
  maxProducts,
  storeId,
}: FetchCartToppersParams): Promise<FetchCartToppersResponse> => {
  const queryParams = new URLSearchParams({
    app_mode: appMode,
    product_ids_in_cart: cartProductIds.join(','),
    max_products: `${maxProducts}`,
    store_id: `${storeId}`,
    jane_device_id: janeDeviceId,
  });
  const url = `${cartToppersUrl(storeId)}?${queryParams.toString()}`;

  const {
    flights = [],
    products,
    max_price_per_product,
  } = await dmApi.get<ApiResponse>(url);

  const rankedProducts = combineFlightsAndProducts(flights, products);

  return { cartToppers: rankedProducts, maxPrice: max_price_per_product };
};

interface ApiResponse {
  flights?: AdFlight[];
  max_price_per_product: number;
  products: ApiProduct[];
}

interface ApiProduct {
  flight_id?: number;
  my_high_d: string;
  product_id: number;
  scoring_signals?: Record<string, unknown>;
}

const combineFlightsAndProducts = (
  flights: AdFlight[],
  products: ApiProduct[]
): BaseCartTopper[] => {
  const flightsMap: Record<number, AdFlight> = {};
  if (flights) {
    flights.forEach((flight) => {
      flightsMap[flight.id] = flight;
    });
  }
  const rankedProducts =
    products?.map(({ product_id, flight_id, scoring_signals, my_high_d }) => ({
      product_id,
      flight: flight_id ? flightsMap[flight_id] : undefined,
      mlScoringSignals: scoring_signals,
      dmMeta: {
        myHighD: my_high_d,
      },
    })) || [];
  return rankedProducts;
};
