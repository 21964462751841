import type { HTMLAttributes } from 'react';

import { Typography } from '@jane/reefer';

export const Name: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  color: _,
  ...props
}) => (
  <Typography
    color="grays-black"
    as="div"
    css={{
      '@supports (-webkit-line-clamp: 2)': {
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
      },
    }}
    variant="body-bold"
    {...props}
  >
    {children}
  </Typography>
);
