import get from 'lodash/get';

import type { MenuProduct, PriceId } from '@jane/shared/models';

export const getMaxCartQuantity = (
  menuProduct: MenuProduct,
  weight: PriceId
) => {
  return (
    get(
      menuProduct,
      `max_cart_quantity_${weight}`,
      menuProduct.max_cart_quantity
    ) || menuProduct.max_cart_quantity
  );
};

export default getMaxCartQuantity;
