import type { CustomTheme, ReeferTheme } from '@jane/reefer';
import { useTheme as useEmotionTheme } from '@jane/reefer';

export const LEGACY_COLORS = {
  grassGreen: '#2BB967',
  midnightGreen: '#1A3C34',
  fashionPink: '#1A3C34',
  white: '#FFFFFF',
  black: '#1A3C34',
};

export const DEFAULT_THEME: CustomTheme = {
  colors: {
    secondary: LEGACY_COLORS.midnightGreen,
    info: LEGACY_COLORS.fashionPink,
    text: {
      info: LEGACY_COLORS.white,
      inverse: LEGACY_COLORS.white,
    },
  },
  borderRadius: {
    sm: '16px',
    lg: '24px',
    xl: '36px',
  },
};

export const DEFAULT_MARKETPLACE_THEME: CustomTheme = {
  borderRadius: DEFAULT_THEME.borderRadius,
};

export const DEFAULT_BUSINESS_THEME: CustomTheme = {
  ...DEFAULT_THEME,
  colors: {
    ...DEFAULT_THEME.colors,
    primary: LEGACY_COLORS.grassGreen,
    secondary: LEGACY_COLORS.black,
  },
};

export const DEFAULT_RETAIL_THEME: CustomTheme = {
  ...DEFAULT_THEME,
  colors: {
    ...DEFAULT_THEME.colors,
    primary: LEGACY_COLORS.black,
  },
};

export const DEFAULT_OPERATOR_THEME: CustomTheme = {
  ...DEFAULT_THEME,
  colors: {
    ...DEFAULT_THEME.colors,
    primary: LEGACY_COLORS.black,
    secondary: LEGACY_COLORS.black,
  },
};

export const useTheme = (fallbackTheme = DEFAULT_THEME) =>
  (useEmotionTheme() || fallbackTheme) as ReeferTheme;
