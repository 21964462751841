import capitalize from 'lodash/capitalize';
import { useState } from 'react';

import {
  Button,
  ButtonArrowIcon,
  Popover,
  useMobileMediaQuery,
} from '@jane/reefer';
import type { PopoverContextProps } from '@jane/reefer';

import {
  FilterListButton,
  FilterListItem,
  StyledFilterList,
} from '../filter.styles';

export interface FilterSortOption {
  aliases?: string[];
  label: string;
  value: string;
}

export interface FilterSortProps {
  /** Current sort option */
  currentSort: string;

  /** Function to call when changing the sort option */
  onSort: (value: string) => void;

  /** Array of sort options */
  sortOptions: FilterSortOption[];

  /** Variant is used in various label text within the component */
  variant?: 'brand' | 'product' | 'store';
}

export const FilterSort = ({
  currentSort,
  onSort,
  sortOptions,
  variant = 'product',
}: FilterSortProps) => {
  const isMobile = useMobileMediaQuery({});

  const defaultSortLabel =
    sortOptions.filter(
      (option) =>
        option.value === currentSort || option.aliases?.includes(currentSort)
    )[0]?.label || 'A-Z';

  const [sortLabel, setSortLabel] = useState(defaultSortLabel);

  return (
    <Popover
      alignment={{ vertical: 'bottom', horizontal: 'right' }}
      label={`Sort ${capitalize(variant)}s by ${sortLabel}`}
      mb={16}
      openOn={!sortOptions.length ? 'disabled' : 'click'}
      target={
        <Button
          label={`Sort: ${sortLabel}`}
          endIcon={<ButtonArrowIcon size="sm" />}
          variant="tertiary"
          full={isMobile}
          disabled={!sortOptions.length}
        />
      }
      targetWidth="100%"
    >
      {({ closePopover }: PopoverContextProps) => (
        <StyledFilterList aria-label={`${capitalize(variant)} sort options`}>
          {sortOptions.map((option) => (
            <FilterListItem key={option.label}>
              <FilterListButton
                onClick={() => {
                  setSortLabel(option.label);
                  onSort(option.value);
                  closePopover();
                }}
              >
                {option.label}
              </FilterListButton>
            </FilterListItem>
          ))}
        </StyledFilterList>
      )}
    </Popover>
  );
};
