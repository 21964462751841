import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createMemoryHistory } from 'history';
import { Router } from 'react-router-dom';

import { Form } from '../../components/forms/form/form';
import { ReeferThemeProvider } from '../../components/reeferThemeProvider';

export interface WithWrappersOptionsType {
  appContainer?: string | boolean;
  form?: boolean;
  reactQueryProvider?: boolean;
  reactRouterProvider?: boolean;
  themeProvider?: boolean;
}

export const withWrappers = (
  children: React.ReactElement,
  options: WithWrappersOptionsType = {
    appContainer: false,
    form: false,
    reactQueryProvider: false,
    reactRouterProvider: false,
    themeProvider: true,
  }
): React.ReactElement => {
  let wrapped = children;

  const mergedOptions = {
    appContainer: false,
    form: false,
    reactQueryProvider: false,
    reactRouterProvider: false,
    themeProvider: true,
    ...options,
  };

  if (mergedOptions.form) {
    wrapped = (
      <Form name="my-form" onSubmit={jest.fn()}>
        {wrapped}
      </Form>
    );
  }

  if (mergedOptions.themeProvider) {
    wrapped = <ReeferThemeProvider>{wrapped}</ReeferThemeProvider>;
  }

  if (mergedOptions.reactQueryProvider) {
    const queryClient = new QueryClient({
      /** @see https://tanstack.com/query/latest/docs/react/guides/testing */
      defaultOptions: {
        queries: {
          cacheTime: Infinity,
          retry: false,
        },
      },
    });
    wrapped = (
      <QueryClientProvider client={queryClient}>{wrapped}</QueryClientProvider>
    );
  }

  if (mergedOptions.reactRouterProvider) {
    wrapped = (
      <Router
        location="/"
        navigator={createMemoryHistory({ initialEntries: ['/'] })}
      >
        {wrapped}
      </Router>
    );
  }
  if (mergedOptions.appContainer) {
    wrapped = (
      <div
        id={
          typeof mergedOptions.appContainer === 'string'
            ? mergedOptions.appContainer
            : 'app'
        }
      >
        {wrapped}
      </div>
    );
  }

  return wrapped;
};
