import { spacing, styled } from '@jane/reefer';

export const StoreRedeemableListItem = styled.li({
  listStyleType: 'none',
  position: 'relative',
  left: 12,
  '&::before': {
    content: '"•"',
    position: 'absolute',
    left: -12,
  },
  ...spacing({ mb: 8 }),
});

export const AutoRedemptionInfo = styled.div(
  spacing({ py: 12, pl: 8, pr: 16, mb: 16 }),
  ({ theme }) => ({
    backgroundColor: theme.colors.brand.purple.light,
    borderRadius: 4,
    display: 'flex',
    border: `1px solid ${theme.colors.brand.purple.dark}`,
  })
);

interface DiscountAccordionHeadingContainerProps {
  cartDrawer?: boolean;
}

export const DiscountAccordionHeadingContainer =
  styled.div<DiscountAccordionHeadingContainerProps>(
    ({ theme, cartDrawer }) => ({
      ...spacing(cartDrawer ? { px: 24, pt: 24, pb: 12 } : { py: 20 }),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderTop: `1px solid ${theme.colors.grays.light}`,
    })
  );

export const ApplyableDiscountWrapper = styled.div<{ enabled?: boolean }>(
  ({ enabled }) => ({
    display: 'flex',
    alignItems: 'center',
    ...spacing({ mb: 8 }),
    '&:hover': {
      cursor: enabled ? 'pointer' : 'auto',
    },
  })
);

export const ApplyableDiscountItem = styled.li({
  listStyleType: 'none',
});

export const UnlockWalletWrapper = styled.div(({ theme }) => ({
  border: `1px solid ${theme.colors.grays.light}`,
  boxShadow: '0px 2px 5px rgba(224, 229, 238, 0.5)',
  borderRadius: 4,
  boxSizing: 'border-box',
  background: theme.colors.grays.white,
  ...spacing({ px: 12, py: 16 }),
}));
