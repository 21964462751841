import { getActualUnitPrice } from '@jane/shared-ecomm/util';
import type { PendingCartProduct, StoreSpecial } from '@jane/shared/models';

import { CartDrawerCard } from './cartDrawerCard';

interface CartDrawerItemsProps {
  product: PendingCartProduct;
  productDetailLink: string;
  specials: StoreSpecial[];
}
export const CartDrawerItem = ({
  product,
  productDetailLink,
  specials,
}: CartDrawerItemsProps) => {
  const applicableSpecial = specials?.find(
    (special: StoreSpecial) => product.special_id === special.id
  );

  const productPrice = getActualUnitPrice(
    product,
    product.price_id,
    applicableSpecial
  ) as number;

  return (
    <CartDrawerCard
      productPrice={productPrice}
      product={product}
      productDetailLink={productDetailLink}
    />
  );
};
