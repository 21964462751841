import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Star = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.46596 0.559876C7.83277 -0.18337 8.89262 -0.18337 9.25943 0.559876L11.069 4.22636C11.2146 4.52151 11.4962 4.72608 11.8219 4.7734L15.8681 5.36135C16.6883 5.48054 17.0158 6.48851 16.4223 7.06705L13.4944 9.92101C13.2588 10.1507 13.1512 10.4817 13.2069 10.8061L13.898 14.836C14.0381 15.6529 13.1807 16.2759 12.4471 15.8902L8.82804 13.9875C8.53671 13.8344 8.18868 13.8344 7.89736 13.9875L4.27832 15.8902C3.54469 16.2759 2.68726 15.6529 2.82737 14.836L3.51854 10.8061C3.57418 10.4817 3.46663 10.1507 3.23095 9.92101L0.303081 7.06705C-0.290436 6.48851 0.0370741 5.48054 0.857294 5.36135L4.9035 4.7734C5.22921 4.72608 5.51078 4.52151 5.65644 4.22636L7.46596 0.559876Z"
      fill="#FFC220"
    />
  </svg>
);
export const StarIcon = ({ ...props }: DynamicIconProps) => {
  return (
    <Icon icon={Star} testId={'star-icon'} {...props} color={props.color} />
  );
};
