import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Facebook = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.2012 13.125L16.6934 9.89062H13.5645V7.78125C13.5645 6.86719 13.9863 6.02344 15.3926 6.02344H16.834V3.24609C16.834 3.24609 15.5332 3 14.3027 3C11.7363 3 10.0488 4.58203 10.0488 7.39453V9.89062H7.16602V13.125H10.0488V21H13.5645V13.125H16.2012Z"
      fill="#0E0E0E"
    />
  </svg>
);
export const FacebookIcon = ({ ...props }: DynamicIconProps) => {
  return <Icon icon={Facebook} {...props} color={props.color} />;
};
