import { styled, useTheme } from '@jane/reefer';

import { SvgIcon } from '../../../component-library/svg-icon';
import { Flex } from '../../../style/layout';

const CloseContainer = styled.div({ cursor: 'pointer' });

interface Props {
  onClick: () => void;
}

export const MenuProductCardBackButton = ({ onClick }: Props) => {
  const theme = useTheme();

  return (
    <Flex alignItems="center">
      <CloseContainer>
        <Flex
          onClick={onClick}
          justifyContent="left"
          width={30}
          mt={-12}
          ml={-12}
          pt={12}
          pl={12}
          pb={12}
        >
          <SvgIcon
            size={{ width: 9, height: 14 }}
            icon="chevronFat"
            color={theme.colors.grays.mid}
          />
        </Flex>
      </CloseContainer>
    </Flex>
  );
};
