import round from 'lodash/round';

import type { AlgoliaProduct } from '@jane/search/types';
import type {
  MenuProduct,
  PendingCartProduct,
  PriceId,
  ReservationCartProduct,
  StoreSpecial,
} from '@jane/shared/models';

import { defaultWeightIdForMenuProduct } from '../storeMenu';

const maybeParseFloat = (val?: string | null) =>
  val ? parseFloat(val) : undefined;

export const getActualUnitPrice = (
  menuProduct: MenuProduct | PendingCartProduct | AlgoliaProduct,
  priceId: PriceId,
  applicableSpecial?: StoreSpecial
): number | undefined => {
  const discountedPrice = menuProduct[`discounted_price_${priceId}`];

  return maybeParseFloat(
    applicableSpecial && discountedPrice
      ? discountedPrice
      : menuProduct[`price_${priceId}`]
  );
};

export const getUnitPrice = (
  menuProduct: MenuProduct | AlgoliaProduct,
  priceId: PriceId
): number | undefined => maybeParseFloat(menuProduct[`price_${priceId}`]);

export const getDiscountedUnitPrice = (
  menuProduct: MenuProduct | AlgoliaProduct,
  priceId: PriceId
): number | undefined =>
  maybeParseFloat(menuProduct[`discounted_price_${priceId}`]);

export const calculateTotalPrice = (
  unitPrice: number | undefined,
  count: number
) => {
  if (unitPrice === undefined) return undefined;

  return unitPrice * count;
};

export const getOriginalAndDiscountedTotalPrices = ({
  menuProduct,
  count = 1,
  priceId,
}: {
  count?: number;
  menuProduct: MenuProduct | AlgoliaProduct;
  priceId?: PriceId;
}) => {
  const selectedWeight =
    priceId || defaultWeightIdForMenuProduct({ menuProduct });
  const originalUnitPrice = getUnitPrice(menuProduct, selectedWeight);
  const discountUnitPrice = getDiscountedUnitPrice(menuProduct, selectedWeight);
  const originalPrice = calculateTotalPrice(originalUnitPrice, count);
  const discountedPrice = calculateTotalPrice(discountUnitPrice, count);

  return { originalPrice, discountedPrice };
};

export const getTotalOriginalAndDiscountedPrices = ({
  menuProduct,
  cartProduct,
}: {
  cartProduct: ReservationCartProduct[];
  menuProduct: MenuProduct | AlgoliaProduct;
}) => {
  const withPrices = cartProduct.map((product) => {
    const originalUnitPrice = getUnitPrice(menuProduct, product.price_id);
    const discountUnitPrice = getDiscountedUnitPrice(
      menuProduct,
      product.price_id
    );
    return {
      originalPrice: calculateTotalPrice(originalUnitPrice, product.count),
      discountedPrice: calculateTotalPrice(discountUnitPrice, product.count),
    };
  });

  const { totalOriginalPrice, totalDiscountedPrice } = withPrices.reduce(
    (prev, curr) => ({
      totalOriginalPrice: prev.totalOriginalPrice + (curr.originalPrice || 0),
      totalDiscountedPrice:
        prev.totalDiscountedPrice + (curr.discountedPrice || 0),
    }),
    { totalOriginalPrice: 0, totalDiscountedPrice: 0 }
  );

  return { totalOriginalPrice, totalDiscountedPrice };
};

export const calculateProductDiscount = (cartProduct: PendingCartProduct) => {
  const { count, price_id, special_id } = cartProduct;
  if (!special_id) return 0;

  const discountedPrice = calculateTotalPrice(
    getDiscountedUnitPrice(cartProduct, price_id),
    count
  );
  const originalPrice = calculateTotalPrice(
    getUnitPrice(cartProduct, price_id),
    count
  );

  return discountedPrice && originalPrice
    ? calculateDiscount(originalPrice, discountedPrice)
    : undefined;
};

export const calculateDiscount = (
  originalPrice: number,
  discountedPrice: number
) => round(originalPrice - discountedPrice, 2);
