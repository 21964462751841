import { useState } from 'react';

import {
  Button,
  ButtonArrowIcon,
  Popover,
  useMobileMediaQuery,
} from '@jane/reefer';
import type { PopoverContextProps } from '@jane/reefer';

import {
  FilterListButton,
  FilterListItem,
  StyledFilterList,
} from '../filter.styles';
import type { ProductFilterBarProps } from './productFilterBar.types';

type ProductFilterViewProps = Pick<
  ProductFilterBarProps,
  'listView' | 'onView'
>;

export const ProductFilterView = ({
  listView,
  onView,
}: ProductFilterViewProps) => {
  const isMobile = useMobileMediaQuery({});

  const [viewLabel, setViewLabel] = useState(listView ? 'List' : 'Grid');

  const viewOptions = [
    {
      label: 'Grid',
    },
    {
      label: 'List',
    },
  ];

  return (
    <Popover
      alignment={{ horizontal: 'right', vertical: 'bottom' }}
      label={`View Products in ${viewLabel}`}
      ml={16}
      mb={16}
      target={
        <Button
          label={`View: ${viewLabel}`}
          endIcon={<ButtonArrowIcon size="sm" />}
          variant="tertiary"
          full={isMobile && true}
        />
      }
    >
      {({ closePopover }: PopoverContextProps) => (
        <StyledFilterList aria-label="Product view options">
          {viewOptions.map((option) => (
            <FilterListItem key={option.label}>
              <FilterListButton
                onClick={() => {
                  onView && onView(!listView as boolean);
                  setViewLabel(option.label);
                  closePopover();
                }}
              >
                {option.label}
              </FilterListButton>
            </FilterListItem>
          ))}
        </StyledFilterList>
      )}
    </Popover>
  );
};
