import { Image, fakeButtonProps, handleEnterKey } from '@jane/reefer';

import type { CarouselThumbnailItemProps } from '../carousel.types';
import { StyledCarouselThumbnail } from './carouselThumbnail.styles';

/**
 * Carousel Thumbnails to select brand or product media
 */

export const CarouselThumbnailItem = ({
  activeIndex,
  imageAlt,
  imageSrc,
  thumbnailIndex,
  thumbnailRef,
  updateIndex,
}: CarouselThumbnailItemProps) => {
  const selectSlideHandler = () => {
    updateIndex(thumbnailIndex);
  };

  return (
    <StyledCarouselThumbnail
      selected={activeIndex === thumbnailIndex}
      {...fakeButtonProps}
      onClick={selectSlideHandler}
      onKeyUp={(event) => handleEnterKey(event, selectSlideHandler)}
      ref={thumbnailRef}
    >
      <Image
        src={imageSrc}
        altText={imageAlt}
        objectFit="cover"
        height="90px"
        width="90px"
        borderRadius="rounded"
      />
    </StyledCarouselThumbnail>
  );
};
