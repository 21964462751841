import styled from '@emotion/styled';

import checkSmall from '../../../../../../../../shared/assets/reefer/icons/dynamic/check-small-icon.svg';
import dash from '../../../../../../../../shared/assets/reefer/icons/dynamic/dash-icon.svg';
import { shadows } from '../../../../styling';

export const StyledCheckboxContainer = styled.div({
  position: 'relative',
  height: '24px',
});

export const StyledInput = styled.input(
  {
    appearance: 'none',
    cursor: 'pointer',
    height: '24px',
    position: 'relative',
    width: '24px',
    zIndex: '1',
    outline: 'none',
    backgroundColor: 'transparent',
    '&:indeterminate, &:checked': {
      backgroundColor: 'transparent',
    },
  },
  ({ theme: { colors } }) => ({
    '&:focus-visible': {
      '&:after': {
        position: 'absolute',
        content: `''`,
        display: 'inherit',
        top: '-3px',
        left: '-3px',
        bottom: '-3px',
        right: '-3px',
        border: `1px solid ${colors.primary.main}`,
        borderRadius: '7px',
        backgroundColor: 'transparent',
      },
    },
    '&:disabled': {
      cursor: 'default',
    },
  })
);

export const StyledCheckbox = styled.div(
  {
    borderRadius: '4px',
    height: '24px',
    position: 'absolute',
    top: '0',
    width: '24px',
    'input:checked + &, input:indeterminate + &': {
      boxShadow: 'none',
      border: 'none',
    },
    ':after': {
      display: 'block',
      content: '""',
      height: '100%',
      position: 'absolute',
      top: '0',
      width: '100%',
      'input:checked + &': {
        backgroundImage: `url(${checkSmall})`,
      },
      'input:indeterminate + &': {
        backgroundImage: `url(${dash})`,
      },
      'input:checked + &, input:indeterminate + &': {
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        filter: `brightness(100)`,
      },
    },
  },
  ({ theme: { colors } }) => ({
    backgroundColor: colors.grays.white,
    border: `2px solid ${colors.grays.mid}`,
    boxShadow: shadows.inner,
    'input:checked + &, input:indeterminate + &': {
      backgroundColor: colors.primary.main,
    },
  })
);
