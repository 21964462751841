import { BagIcon, JaneNavBagIcon, styled } from '@jane/reefer';

import { flex } from '../../style';
import AnimatedClockIcon from '../animatedClockIcon';
import type { HeaderCartProps } from './cart';

const CartIcon = styled.div({
  position: 'relative',
});

const CartDot = styled.div(
  flex({ alignItems: 'center', justifyContent: 'center' }),
  ({ theme }) => ({
    backgroundColor: theme.colors.system.negative.main,
    borderRadius: '50%',
    color: theme.colors.grays.white,
    position: 'absolute',
    fontSize: 10,
    height: 16,
    width: 16,
    right: 16,
    top: 11,
  })
);

const ClockContainer = styled.div({ width: 24 });

const EmbeddedOrWhiteLabelCart = ({
  isReservation = false,
  productCount = 0,
}: Pick<HeaderCartProps, 'isReservation' | 'productCount'>) => {
  if (isReservation)
    return (
      <ClockContainer>
        <AnimatedClockIcon embedded={true} />
      </ClockContainer>
    );
  const cartFilled = productCount > 0;

  return (
    <CartIcon data-testid="embedded-header-cart">
      {cartFilled && <JaneNavBagIcon color="secondary" />}
      {!cartFilled && <BagIcon color="secondary" />}

      {productCount > 0 && <CartDot>{productCount}</CartDot>}
    </CartIcon>
  );
};

export default EmbeddedOrWhiteLabelCart;
