import isEmpty from 'lodash/isEmpty';
import pluralise from 'pluralise';

import { Button, Typography, styled } from '@jane/reefer';
import type { Cart, Store } from '@jane/shared/models';

import { useCustomerSelector } from '../../customer';
import type { NoStore } from '../../customer/redux/store';
import { isEmbeddedModeSelector } from '../../customer/selectors';
import { PRICE_ID_TO_GRAMS } from '../../lib/cartLimitPolicy';
import { sentenceify } from '../../lib/words';
import { spacing } from '../../style';
import { Box } from '../../style/layout';

const RemovedRewardListItem = styled.li(spacing({ mb: 8 }), {
  listStyleType: 'none',
  position: 'relative',
  left: 12,
  textAlign: 'left',
  '&::before': {
    content: '"•"',
    position: 'absolute',
    left: -12,
  },
});

const unavailableProductsText = (cart: Cart, store: Store | NoStore) => {
  const { unavailable_products } = cart;

  const withoutDuplicates = unavailable_products.reduce(
    (prev, curr) =>
      prev.some(
        (product) =>
          product.product_id === curr.product_id &&
          product.price_id === curr.price_id
      )
        ? prev
        : [...prev, curr],
    []
  );

  const { name } = store as Store;

  if (!cart.products.length) {
    return `All of your selected products have sold out or are no longer available at ${name}.
        Please start a new bag.`;
  } else {
    const productNames = sentenceify(
      withoutDuplicates.map((p) =>
        p.price_id === 'each'
          ? `${p.name}`
          : `${PRICE_ID_TO_GRAMS[p.price_id]}g ${p.name}`
      )
    );
    const verb = withoutDuplicates.length === 1 ? 'is' : 'are';
    return (
      <span>
        <em>{productNames}</em> {verb} no longer available at {name}.
      </span>
    );
    // if (withoutDuplicates.length === 1) {
    //   const productName = withoutDuplicates[0].name;
    //   return (
    //     <span>
    //       <em>
    //         {PRICE_ID_TO_GRAMS[withoutDuplicates[0].price_id]}g {productName}
    //       </em>{' '}
    //       is no longer available at {name}.
    //     </span>
    //   );
    // } else {
    //   const productNames = sentenceify(
    //     withoutDuplicates.map(
    //       (p) => `${PRICE_ID_TO_GRAMS[p.price_id]}g ${p.name}`
    //     )
    //   );
    //   return (
    //     <span>
    //       <em>{productNames}</em> are no longer available at {name}.
    //     </span>
    //   );
    // }
  }
};

interface Props {
  cart: Cart;
  isFixingUnavailableCheckout?: boolean;
  noTitle?: boolean;
  onContinueCheckout: () => void;
  store: Store | NoStore;
}

export default function CartAlertsForm({
  cart,
  isFixingUnavailableCheckout,
  noTitle,
  store,
  onContinueCheckout,
}: Props) {
  const isEmbeddedMode = useCustomerSelector(isEmbeddedModeSelector);
  const {
    products,
    special_has_changed,
    removed_crm_redemptions,
    unavailable_products,
  } = cart;

  const getTitle = () =>
    noTitle
      ? ''
      : isFixingUnavailableCheckout
      ? 'Your bag has changed'
      : 'Bag may have changed';

  return (
    <div data-testid="cart-alert-form">
      <div className="alerts-modal__text">
        <Typography branded={!isEmbeddedMode} variant="header">
          {getTitle()}
        </Typography>
        <div className="alerts-modal__alerts">
          {(!products.length || !!unavailable_products.length) && (
            <Box mt={24}>
              <Typography>{unavailableProductsText(cart, store)}</Typography>
            </Box>
          )}
          {special_has_changed && (
            <Box mt={24}>
              <Typography>
                Specials applied to one or more of your selected products have
                changed.
              </Typography>
            </Box>
          )}
          {removed_crm_redemptions && removed_crm_redemptions.length > 0 && (
            <Box mt={24} mb={24}>
              <Typography>
                The following rewards have been removed from your bag:
              </Typography>
              <ul>
                {removed_crm_redemptions.map((redemption) => (
                  <RemovedRewardListItem key={redemption.id}>{`${
                    redemption.reward_name
                  } (${redemption.point_value} ${pluralise(
                    redemption.point_value,
                    'pt',
                    'pts'
                  )})`}</RemovedRewardListItem>
                ))}
              </ul>
            </Box>
          )}
          {isEmpty(products) && !isEmpty(unavailable_products) && (
            <Box mt={24} mb={24}>
              <Typography>{`Please review your ${
                isFixingUnavailableCheckout ? 'order' : 'bag'
              } for more details.`}</Typography>
            </Box>
          )}
        </div>
      </div>
      <div className="modal-buttons modal-buttons--small alerts-modal__buttons">
        <Button
          data-testid="dismiss-cart-alert-button"
          onClick={onContinueCheckout}
          label="Got it!"
        />
      </div>
    </div>
  );
}
