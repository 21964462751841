import { fold } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/pipeable';
import * as t from 'io-ts';

import { config } from '@jane/shared/config';

import { decodeType } from '../lib/loadable';
import { request } from '../lib/request';

const tAddUserSuccessResponse = t.interface({
  message: t.string,
  reservation: t.interface({
    cart_uuid: t.string,
    cart_id: t.number,
  }),
});

const tAddUserResponse = tAddUserSuccessResponse;

export const addUserToCart = (uuid: string) =>
  request(`${config.apiPath}/guest_carts/add_user_to_cart`, {
    method: 'PATCH',
    requireAuth: true,
    body: JSON.stringify({
      uuid,
    }),
  })
    .then((r) =>
      decodeType({
        data: r,
        type: tAddUserResponse,
        source: { name: 'AddUserToReservation' },
      })
    )
    .then((r) =>
      pipe(
        r,
        fold(
          (error) => {
            throw error;
          },
          (response) => response
        )
      )
    );

export const cancelGuestReservation = (uuid: string) =>
  request(`${config.apiPath}/guest_carts`, {
    method: 'DELETE',
    body: JSON.stringify({ uuid }),
  });
