import type { AppMode } from '@jane/shared/models';

export const FLAGS = {
  kioskOrderConfirmationRating: 'kioskOrderConfirmationRatingEnabled',
  janeGold: 'fepJaneGold',
  newBagDrawer: 'newBag',
  newHomePage: 'fepNewHomePage',
  newSearchLib: 'fepNewSearchLib',
  newSpecialsUx: 'newSpecialsUx',
};

export interface FlagConfigs {
  [key: string]: {
    validAppModes: AppMode[];
  };
}

export const FLAG_CONFIGS: FlagConfigs = {
  newBag: {
    validAppModes: ['default'],
  },
  newSpecialsUx: {
    validAppModes: ['default'],
  },
};
