import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as t from 'io-ts';

import { tManager, tStaffMember } from '@jane/business-admin/types';
import type { Manager, StaffMember } from '@jane/business-admin/types';
import { fetchWithDecode } from '@jane/business-admin/util';
import { getJwt, removeJwt, setJwt } from '@jane/shared/auth';
import { config } from '@jane/shared/config';
import { api, janeApiV2 } from '@jane/shared/data-access';
import { Mixpanel } from '@jane/shared/util';

interface LoginData {
  manager: {
    email?: string;
    password?: string;
  };
}

const SESSION_URL = `${config.apiPathV2}/managers/sessions`;

const login = async (
  loginData: LoginData
): Promise<{ authHeader: string | null; manager: Manager }> => {
  const response = await api.post<Response>(
    SESSION_URL,
    loginData,
    undefined,
    true
  );
  const { manager } = await response.json();
  return {
    authHeader: response.headers.get('Authorization'),
    manager,
  };
};
export const useLogin = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: login,
    mutationKey: ['login'],
    onSuccess: (data) => {
      if (data.authHeader) {
        queryClient.setQueryData(['jwt'], data.authHeader);
        setJwt(data.authHeader);
      }
      queryClient.setQueryData(['manager'], data);
    },
    onError: (error) => {
      console.error(error);
    },
  });
};

const logout = async () => await api.delete(SESSION_URL);
export const useLogout = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: logout,
    mutationKey: ['logout'],
    onSuccess: () => {
      Mixpanel.reset();
      queryClient.invalidateQueries(['manager']);
      queryClient.invalidateQueries(['jwt']);
      removeJwt();
    },
  });
};

const WHO_AM_I_URL = `${config.apiPathV2}/managers/whoami`;

const currentManagerRequest = async () => {
  const data = await fetchWithDecode(
    api.get(WHO_AM_I_URL),
    t.interface({ manager: t.union([tManager, t.UnknownRecord]) }),
    WHO_AM_I_URL
  );
  return data;
};
// Used as a general query to keep track of manager credentials
// Can be modified by requests from /whoami and /login
export const useManager = () =>
  useQuery<{ manager: Manager }>({
    queryFn: currentManagerRequest,
    queryKey: ['manager'],
    staleTime: Infinity,
  });

// Persist jwt token even when pages are routed
const jwtRequest = async (): Promise<string | null | undefined> => {
  return new Promise((res) => {
    const jwt = getJwt();
    return res(jwt);
  });
};
export const useGetJwt = () =>
  useQuery({ queryFn: jwtRequest, queryKey: ['jwt'], staleTime: Infinity });

const UPDATE_PROFILE_URL = `${config.apiPathV2}/managers/profile`;

export interface UpdateProfileParams {
  email?: string;
  first_name?: string;
  last_name?: string;
  phone?: string;
}

const updateProfile = async (manager: UpdateProfileParams) =>
  api.patch(UPDATE_PROFILE_URL, { manager });
export const useUpdateProfile = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: UpdateProfileParams) => updateProfile(data),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['manager']);
    },
  });
};

export type RequestPasswordResetData = {
  email: string;
};
const requestPasswordReset = async (
  data: RequestPasswordResetData
): Promise<RequestPasswordResetData> => {
  return janeApiV2.post<RequestPasswordResetData>(
    `/business/reset_password_request`,
    {
      reset_password: data,
    }
  );
};
export const useRequestPasswordReset = () => {
  return useMutation({
    mutationFn: (data: RequestPasswordResetData) => {
      return requestPasswordReset(data);
    },
    onError: (error) => {
      console.error(error);
    },
  });
};

export type ResetPasswordData = {
  password: string;
  password_confirmation: string;
  reset_password_token: string;
};
const resetPassword = async (
  data: ResetPasswordData
): Promise<ResetPasswordData> => {
  return janeApiV2.patch<ResetPasswordData>(`/business/reset_password`, {
    reset_password: data,
  });
};
export const useResetPassword = () => {
  return useMutation({
    mutationFn: (data: ResetPasswordData) => {
      const path = window.location.pathname;
      data.reset_password_token = path.substring(path.lastIndexOf('/') + 1);
      return resetPassword(data);
    },
    onError: (error) => {
      console.error(error);
    },
  });
};

const UPDATE_PASSWORD_URL = `${config.apiPathV2}/managers/passwords`;

export interface UpdatePasswordParams {
  current_password: string;
  password: string;
  password_confirmation: string;
}

const updatePassword = async (manager: UpdatePasswordParams) =>
  api.patch(UPDATE_PASSWORD_URL, { manager });
export const useUpdatePassword = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: UpdatePasswordParams) => updatePassword(data),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['manager']);
    },
  });
};

const INVITES_URL = `${config.apiPathV2}/managers/invites`;

const invitationRequest = (invitationToken: string) =>
  fetchWithDecode(
    api.get(`${INVITES_URL}/${invitationToken}`),
    tStaffMember,
    `${INVITES_URL}/${invitationToken}`
  );
export const useInvitation = (invitationToken: string) =>
  useQuery<StaffMember>({
    queryKey: ['manager_invitation', invitationToken],
    queryFn: () => invitationRequest(invitationToken),
    staleTime: Infinity,
    enabled: Boolean(invitationToken),
  });

export type CompleteRegistrationParams = Pick<
  StaffMember,
  | 'first_name'
  | 'last_name'
  | 'password'
  | 'phone'
  | 'accepted_terms_and_conditions'
>;

const completeRegistration = (
  invitationToken: string,
  manager: CompleteRegistrationParams
) =>
  api.patch(`${INVITES_URL}/${invitationToken}`, {
    manager,
  });
export const useCompleteRegistration = (invitationToken: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (manager: CompleteRegistrationParams) =>
      completeRegistration(invitationToken, manager),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['manager_invitation', invitationToken]);
    },
  });
};
