import { trackError } from './errorTracking';

const scriptTagExists = (src: string) =>
  document.head.querySelector(`script[src="${src}"]`);

export async function loadExternalScript({
  src,
  dependencies = [],
}: {
  dependencies?: string[];
  src: string;
}) {
  if (dependencies.length) {
    await Promise.all(
      dependencies.map((dep) => loadExternalScript({ src: dep }))
    );
  }

  if (scriptTagExists(src)) return;

  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      document.head.removeChild(script);
      trackError(`Failed to load script from ${src}`);
      reject(src);
    };
    script.src = src;

    document.head.appendChild(script);
  });
}
