import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Rewards = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.3495 6.41808C11.6246 5.86064 12.4195 5.86064 12.6946 6.41808L14.0517 9.16794C14.161 9.3893 14.3721 9.54273 14.6164 9.57822L17.6511 10.0192C18.2662 10.1086 18.5119 10.8646 18.0667 11.2985L15.8708 13.4389C15.6941 13.6112 15.6134 13.8595 15.6551 14.1028L16.1735 17.1252C16.2786 17.7378 15.6355 18.2051 15.0853 17.9158L12.371 16.4888C12.1525 16.374 11.8915 16.374 11.673 16.4888L8.95874 17.9158C8.40852 18.2051 7.76544 17.7378 7.87053 17.1252L8.38891 14.1028C8.43063 13.8595 8.34997 13.6112 8.17321 13.4389L5.97731 11.2985C5.53217 10.8646 5.77781 10.1086 6.39297 10.0192L9.42763 9.57822C9.67191 9.54273 9.88308 9.3893 9.99233 9.16794L11.3495 6.41808Z"
      fill="#0E0E0E"
    />
    <circle cx={12} cy={12} r={11} stroke="#0E0E0E" strokeWidth={2} />
  </svg>
);
export const RewardsIcon = ({ ...props }: DynamicIconProps) => {
  return <Icon icon={Rewards} {...props} color={props.color} />;
};
