import type { GridContainerProps, GridItemProps } from './grid.types';
import { GridContainer } from './gridContainer/gridContainer';
import { GridItem } from './gridItem/gridItem';

export const Grid = Object.assign(
  {},
  {
    Container: GridContainer,
    Item: GridItem,
  }
);

export type { GridContainerProps, GridItemProps };
