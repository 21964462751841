import type { PropsWithoutRef } from 'react';

import { Typography, styled } from '@jane/reefer';
import type { CSSObject, ReeferTheme } from '@jane/reefer';

import { ellipsizeText } from '../../style/ellipsizeText';
import { useTextField } from './text-field-context';
import type { Variant } from './text-input';

interface TextFieldLabelProps {
  disabled?: boolean;
  error?: boolean;
  focused?: boolean;
  shrink?: boolean;
  success?: boolean;
  theme?: ReeferTheme;
  variant?: Variant;
}

const material = (props: TextFieldLabelProps): CSSObject => ({
  ...ellipsizeText(),
  width: '100%',
  display: 'block',
  transformOrigin: 'top left',
  lineHeight: 1,
  top: 0,
  left: 0,
  position: 'absolute',
  fontWeight: 400,
  color: props.focused
    ? props.theme && props.theme.colors.primary.main
    : props.shrink
    ? props.theme.colors.grays.black
    : props.theme.colors.grays.mid,
  transform: props.shrink
    ? 'translate(0, 0px) scale(0.875)'
    : 'translate(0, 29px) scale(1)',
  transition:
    'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
  ...(props.error && props.shrink && { color: props.theme.colors.error }),
  ...(props.success && props.shrink && { color: props.theme.colors.success }),
  ...(props.disabled && { color: props.theme.colors.grays.light }),
});

export const TextFieldLabel = styled.label<TextFieldLabelProps>((props) => {
  switch (props.variant) {
    case 'material':
      return material(props);

    default:
      break;
  }
});

const Label = ({
  children,
  ...props
}: { disabled?: boolean } & PropsWithoutRef<
  JSX.IntrinsicElements['label']
>) => {
  const { shrink, focused, error, success, variant } = useTextField();

  return (
    <TextFieldLabel
      shrink={shrink}
      focused={focused}
      error={error}
      success={success}
      variant={variant}
      {...props}
    >
      <Typography color="grays-black" variant="body">
        {children}
      </Typography>
    </TextFieldLabel>
  );
};

export default Label;
