import { Storage } from '@jane/shared/util';

import { getCookie } from './getCookie';
import { inIframe } from './iframe';
import { ParentLocalStorage } from './parentLocalStorage';

export const getJaneDeviceId = async () => {
  return getJaneDeviceIdAndSource().then((v) => v.value);
};

export const getJaneDeviceIdAndSource = async () => {
  if (inIframe()) {
    const v = await ParentLocalStorage.getJaneDeviceId();
    return { value: v, isCookie: false };
  } else {
    const cookie = getCookie('jdid');
    if (cookie !== null && cookie?.length > 0) {
      return { value: cookie, isCookie: true };
    }
    return {
      value: Storage.get('j_device_id'),
      isCookie: false,
    };
  }
};
