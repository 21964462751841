import { StarIcon } from '@jane/reefer';

import { PRICE_BUCKET_FILTER_ITEMS, RATING_FILTER_ITEMS } from './filterItems';

const priceInRange = (
  price: string,
  start: number | undefined,
  end: number | undefined
) => {
  const numericPrice = parseInt(price);
  return numericPrice >= (start || 0) && numericPrice <= (end || Infinity);
};

const ratingInRange = (rating: string, start: number) => {
  const ratingRange = parseInt(rating.split('.')[0]);
  return ratingRange === start || 0;
};

export const getPriceBucketCount = (facet: { [label: string]: number }) => {
  return PRICE_BUCKET_FILTER_ITEMS.map(({ label, value, start, end }) => {
    const count = Object.entries(facet).reduce((sum, [price, count]) => {
      return priceInRange(price, start, end) ? sum + count : sum;
    }, 0);

    return {
      value,
      label,
      count,
    };
  });
};

export const getRatingBucketCount = (facet: { [label: string]: number }) => {
  return RATING_FILTER_ITEMS.map(({ label, value, start }) => {
    const count = Object.entries(facet).reduce((sum, [rating, count]) => {
      return ratingInRange(rating, start) ? sum + count : sum;
    }, 0);

    const icon = (
      <>
        {Array.from({ length: parseInt(label) }, (_, i) => (
          <StarIcon size="sm" key={i} ml={i !== 0 ? 4 : undefined} />
        ))}
      </>
    );

    return {
      count,
      icon,
      label: `${label}+`,
      value,
    };
  });
};
