import { config } from '@jane/shared/config';

import { getJaneDeviceIdAndSource } from '../lib/getJaneDeviceId';
import { loginRequest, request } from '../lib/request';

export const CustomerSource = {
  async whoami({ ref } = {}, isBoost = false) {
    const janeUrl = `${config.apiPath}/whoami`;
    const boostUrl = '/whoami';
    let queryParams = ref ? { ref } : {};
    let jdidWithSource;
    try {
      jdidWithSource = await getJaneDeviceIdAndSource();
    } catch (e) {
      console.info(e);
    }
    if (jdidWithSource && !jdidWithSource.isCookie) {
      queryParams.legacy = jdidWithSource.value;
    }

    if (isBoost) {
      try {
        const boostUrlResp = await request(
          boostUrl,
          { method: 'GET' },
          queryParams
        );
        if (boostUrlResp?.user !== undefined) {
          // if the user is not ID'd in the whoami call, we still get back {user: {}}.
          return boostUrlResp;
        }
      } catch {
        console.debug('boost whoami proxy not available');
      }
    }

    return request(
      janeUrl,
      {
        method: 'GET',
      },
      queryParams
    );
  },

  logIn(credentials) {
    const url = `${config.apiPath}/sessions`;
    return loginRequest(url, {
      body: JSON.stringify({ user: credentials }),
      method: 'POST',
    });
  },

  logInWithCognitoToken(cognitoToken) {
    const url = `${config.apiPath}/sessions`;
    return loginRequest(url, {
      body: JSON.stringify({ cognito_token: cognitoToken }),
      method: 'POST',
    });
  },

  authenticateGoogleCode(auth) {
    const url = `${config.apiPath}/auth/google_oauth2/callback`;
    return loginRequest(url, {
      body: JSON.stringify(auth),
      method: 'POST',
    });
  },

  authenticateAppleCode(auth) {
    const url = `${config.apiPath}/users/id_tokens/apple`;
    return loginRequest(url, {
      body: JSON.stringify(auth),
      method: 'POST',
    });
  },

  logOut() {
    const url = `${config.apiPath}/sessions`;
    return request(url, {
      method: 'DELETE',
    });
  },
};
