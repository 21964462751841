import { useFormContext } from 'react-hook-form';

import { Button } from '../../button';
import type { ButtonProps } from '../../button/button.types';

export type FormSubmitButtonProps = Omit<
  ButtonProps,
  'disabled' | 'href' | 'onClick' | 'ref' | 'to' | 'type'
>;

/**
 * Use `Form.SubmitButton` to add a `button` of `type='submit'` to a `Form`.
 *
 * `Form.SubmitButton` is wired up to `Form` state, amd will be `disabled` if:
 * - the `Form` has any invalid fields.
 * - the `Form` is currently submitting.
 */
export function FormSubmitButton(props: FormSubmitButtonProps) {
  const {
    formState: { isSubmitting, isValid },
  } = useFormContext();

  return (
    <Button
      disabled={!isValid || isSubmitting}
      loading={isSubmitting || props.loading}
      type="submit"
      {...props}
    />
  );
}
