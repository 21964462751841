import { config } from '@jane/shared/config';

import { withAuthHeader } from '../utils';
import { api } from './api';

const apiPath = `${config.apiPath}`;

export const janeApi = {
  get: <T>(
    url: string,
    authToken?: string | null,
    params?: object
  ): Promise<T> => {
    return api.get(`${apiPath}${url}`, withAuthHeader(params, authToken));
  },
  post: <T>(
    url: string,
    data: unknown,
    authToken?: string | null,
    params?: object
  ): Promise<T> => {
    return api.post(
      `${apiPath}${url}`,
      data,
      withAuthHeader(params, authToken)
    );
  },
  patch: <T>(
    url: string,
    data: unknown,
    authToken?: string | null,
    params?: object
  ): Promise<T> => {
    return api.patch(
      `${apiPath}${url}`,
      data,
      withAuthHeader(params, authToken)
    );
  },
  delete: <T>(
    url: string,
    authToken?: string | null,
    params?: object
  ): Promise<T | null> => {
    return api.delete(`${apiPath}${url}`, withAuthHeader(params, authToken));
  },
};
