import type { DeliveryValidation, Store } from '@jane/shared/models';

import { useCustomerSelector } from '../../../../customer';
import { generateCartLimitReport } from '../../../../lib/cartLimitPolicy';
import type { CartLineItems } from '../../../../lib/getCartLineItems';
import minimumDeliveryOrder from '../../../../lib/minimumDeliveryOrder';
import { minimumPickupOrder } from '../../../../lib/minimumPickupOrder';
import type OrderingSchedule from '../../../../lib/orderingSchedule';

interface useIsCheckoutDisabledProps {
  cartLineItems: CartLineItems;
  deliveryValidationData: DeliveryValidation;
  schedules: OrderingSchedule;
  store: Store;
}
export const useIsCheckoutDisabled = ({
  cartLineItems,
  deliveryValidationData,
  store,
  schedules,
}: useIsCheckoutDisabledProps) => {
  const {
    cart,
    isLoading: isLoadingCart,
    salesTaxLoading,
  } = useCustomerSelector(({ cart: { cart, isLoading, salesTaxLoading } }) => ({
    cart,
    isLoading,
    salesTaxLoading,
  }));

  const { reservation_mode } = cart;

  const cartReport =
    store && store.cart_limit_policy
      ? generateCartLimitReport({
          cart,
          store,
        })
      : null;

  const storeIsAcceptingReservations =
    schedules?.isCurrentlyAcceptingReservations(reservation_mode);

  const canDeliver =
    reservation_mode === 'delivery'
      ? minimumDeliveryOrder({
          discountedSubtotal: cartLineItems.total,
          store,
          deliveryValidateData: deliveryValidationData,
        }) && !!deliveryValidationData?.can_deliver
      : true;

  const cartReportOver = cartReport && cartReport.over;

  const minimumPickupOrderMet = minimumPickupOrder(cartLineItems.total, store);

  const invalidDeliveryAddress =
    !deliveryValidationData?.can_deliver && reservation_mode === 'delivery';

  const checkoutDisabled =
    isLoadingCart ||
    salesTaxLoading ||
    !storeIsAcceptingReservations ||
    !canDeliver ||
    cartReportOver ||
    !minimumPickupOrderMet ||
    invalidDeliveryAddress;

  return checkoutDisabled;
};
