import castArray from 'lodash/castArray';
import escape from 'lodash/escape';
import map from 'lodash/map';

import type { SearchState } from '@jane/search/types';
import type { Id } from '@jane/shared/models';

import { CATEGORIES } from '../../home/homeCurrent/homePageHelper';
import { REVIEWS_REQUIRED_BEFORE_DISPLAY } from '../../productReviews/common';

const maybeQuote = (value: unknown) =>
  typeof value === 'string' ? `"${escape(value)}"` : value;

export const MIN_REVEIEWS_QUERY = `review_count >= ${REVIEWS_REQUIRED_BEFORE_DISPLAY}`;

type RangeValue = {
  // TODO(wes): this needs to be one or the other
  max?: number | string;
  min?: number | string; // TODO(wes): this needs to be one or the other
  rangeKey: string;
};
const generateRangeQuery = ({ min, max, rangeKey }: RangeValue) =>
  [!!min && `${rangeKey} >= ${min}`, !!max && `${rangeKey} <= ${max}`]
    .filter(Boolean)
    .join(' AND ');

const rangeToFilters = (range: SearchState['range']) =>
  range
    ? Object.keys(range)
        .map((rangeKey: string) => {
          const { min, max }: Omit<RangeValue, 'rangeKey'> = range[rangeKey];
          return generateRangeQuery({ min, max, rangeKey });
        })
        .filter(Boolean)
    : [];

const multiRangeToFilters = (multiRange: SearchState['multiRange']) => {
  const multiRangeFilters = multiRange
    ? Object.entries(multiRange)
        .map(([rangeKey, rangeValue]) => {
          const [min, max] = rangeValue.split(':');
          return generateRangeQuery({ min, max, rangeKey });
        })
        .filter(Boolean)
    : [];
  return multiRangeFilters;
};

const minReviewCountFilter = (multiRange: SearchState['multiRange']) => {
  return multiRange?.aggregate_rating ? [MIN_REVEIEWS_QUERY] : [];
};

export const refinementListToFilters = (
  refinementList: SearchState['refinementList']
) =>
  map(refinementList, (values, facet) =>
    castArray(values)
      .filter(Boolean)
      .map((value) => `${facet}:${maybeQuote(value)}`)
      .join(' OR ')
  ).filter(Boolean);

export const buildFilterString = (
  searchState: SearchState,
  storeId: Id,
  kindValue: string
) => {
  const { refinementList, range, multiRange } = searchState;

  return [
    ...refinementListToFilters(refinementList),
    ...rangeToFilters(range),
    ...multiRangeToFilters(multiRange),
    ...minReviewCountFilter(multiRange),
    `store_id = ${storeId}`,
    `kind:"${escape(kindValue)}" OR root_types:"${escape(kindValue)}"`,
  ].join(' AND ');
};

export const buildInlineAdsFilterString = (
  searchState: SearchState,
  storeId: Id,
  productIds: number[] = []
) => {
  const { refinementList, range, multiRange } = searchState;

  return [
    ...refinementListToFilters(refinementList),
    ...rangeToFilters(range),
    ...multiRangeToFilters(multiRange),
    ...minReviewCountFilter(multiRange),
    `store_id = ${storeId}`,
    productIds.length > 0
      ? '(' + productIds.map((pid) => `product_id:${pid}`).join(' OR ') + ')'
      : null,
  ]
    .filter(Boolean)
    .join(' AND ');
};

export const buildOptionalFilterString = (
  accumulator: string,
  filterEntry: [string, readonly (number | string)[]],
  index: number
) => {
  const facetKey = filterEntry[0];
  const facetValues = filterEntry[1];

  if (Array.isArray(facetValues)) {
    const stringPerFacetKey = facetValues.reduce(
      (facetAcc, facetValue, index) =>
        facetAcc + (index === 0 ? '' : ',') + `${facetKey}:${facetValue}`,
      ''
    );

    return (accumulator += (index === 0 ? '' : ',') + `${stringPerFacetKey}`);
  }

  return (accumulator += '');
};

export const generateSearchState = (
  name: string,
  outerSearchState: SearchState,
  isAd?: boolean
) =>
  CATEGORIES.includes(name)
    ? {
        ...outerSearchState,
        refinementList: {
          ...outerSearchState.refinementList,
          category: [name],
          root_types: ['flower'],
        },
      }
    : isAd
    ? {
        ...outerSearchState,
        refinementList: {
          ...{ brand: [name] },
        },
      }
    : {
        ...outerSearchState,
        refinementList: {
          ...outerSearchState.refinementList,
          root_types: [name],
        },
      };
