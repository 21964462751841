import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import type { WeightSelectorWeight } from '@jane/shared-ecomm/types';
import {
  enabledWeightsForMenuProduct,
  getOriginalAndDiscountedTotalPrices,
  isSoldByWeight,
} from '@jane/shared-ecomm/util';
import type { PriceId } from '@jane/shared/models';

import { useCustomerDispatch } from '../../../customer/dispatch';
import {
  attemptToAddToCart,
  deleteCartItem,
} from '../../../customer/redux/cart';
import { usePDPDetector } from '../../../hooks/usePDPDetector';
import initialPriceIdForProduct from '../../../lib/initialPriceIdForProduct';
import {
  DisplayMode,
  useProductCardContext,
} from '../providers/productCardProvider';

export interface RenderProps {
  confirmationOrEditingMode: boolean;
  decrementQuantity: () => void;
  discountedPrice?: number;
  incrementQuantity: () => void;
  multipleCountDisabled: boolean;
  noSelectedWeightDefault: boolean;
  onAddToCartPressed: () => void;
  onWeightSelected: (selectedWeight: PriceId) => void;
  originalPrice?: number;
  selectedQuantity: number;
  selectedWeight: PriceId;
  shoppingDisabled: boolean;
  soldByQuantityOnly: boolean;
  soldByWeight: boolean;
  weights: WeightSelectorWeight[];
}

interface Props {
  children: (arg: RenderProps) => JSX.Element;
  sortedByPrice?: boolean;
}

const ListViewStateContainer = ({ sortedByPrice, children }: Props) => {
  const {
    appliedWeightFilter,
    cartProduct,
    currentSpecial,
    displayMode,
    listView,
    menuProduct,
    store,
    setDisplayMode,
    trackListViewClick,
  } = useProductCardContext();
  const navigate = useNavigate();
  const dispatch = useCustomerDispatch();
  const isPDP = usePDPDetector();

  const [selectedWeight, setSelectedWeight] = useState<PriceId | null>(null);

  const [selectedQuantity, setSelectedQuantity] = useState(
    cartProduct.length > 0 ? cartProduct[0].count : 1
  );
  const [noSelectedWeightDefault, setNoSelectedWeightDefault] = useState(
    !cartProduct
  );

  useEffect(() => {
    if (listView) {
      if (cartProduct.length === 0) {
        setDisplayMode(DisplayMode.Product);
        setSelectedWeight(null);
      } else {
        setDisplayMode(DisplayMode.Confirmation);
      }
    }
  }, [cartProduct, listView]);

  useEffect(() => {
    if (!listView) {
      setSelectedWeight(
        initialPriceIdForProduct({
          menuProduct,
          cartProduct: cartProduct[0],
          special: currentSpecial,
          appliedWeightFilter,
          sortedByPrice,
        })
      );
      setSelectedQuantity(cartProduct.length > 0 ? cartProduct[0].count : 1);
    }
  }, [appliedWeightFilter, sortedByPrice, displayMode]);

  const onAddToCartPressed = debounce(
    () => {
      if (selectedQuantity === 0 && cartProduct.length > 0) {
        dispatch(deleteCartItem(cartProduct[0].id, selectedWeight));
        setSelectedQuantity(1);
      } else {
        dispatch(
          attemptToAddToCart({
            store,
            menuProduct,
            price_id: selectedWeight,
            count: selectedQuantity,
            special: currentSpecial,
            location: isPDP ? 'productDetailPage' : 'menu',
            navigate,
          })
        );
      }
      if (!listView) {
        setDisplayMode(DisplayMode.Confirmation);
      }
      listView && trackListViewClick && trackListViewClick();
    },
    500,
    { leading: true, trailing: false }
  );

  const onWeightSelected = (selectedWeight: PriceId) => {
    const isInCart = cartProduct.find(
      (product) => product.price_id === selectedWeight
    );
    setNoSelectedWeightDefault(false);
    setSelectedWeight(selectedWeight);
    setSelectedQuantity(isInCart ? isInCart.count : 1);
    setDisplayMode(DisplayMode.Edit);
    listView && trackListViewClick && trackListViewClick();
  };

  const incrementQuantity = () => {
    setSelectedQuantity((prevQuantity) => prevQuantity + 1);
    listView && trackListViewClick && trackListViewClick();
  };

  const decrementQuantity = () => {
    setSelectedQuantity((prevQuantity) => prevQuantity - 1);
    listView && trackListViewClick && trackListViewClick();
  };

  const multipleCountDisabled =
    menuProduct.kind === 'flower' && !menuProduct.allow_multiple_flower_count;

  const weights = enabledWeightsForMenuProduct(menuProduct, currentSpecial);

  const soldByWeight = isSoldByWeight(menuProduct.kind);
  const soldByQuantityOnly = !(multipleCountDisabled || soldByWeight);

  const { originalPrice, discountedPrice } =
    getOriginalAndDiscountedTotalPrices({
      menuProduct,
      count: selectedQuantity,
      priceId: selectedWeight,
    });

  const confirmationOrEditingMode =
    displayMode === DisplayMode.Confirmation ||
    displayMode === DisplayMode.Edit ||
    !!cartProduct;

  const shoppingDisabled = store.hide_prices;

  const propsForChildren = {
    multipleCountDisabled,
    weights,
    soldByWeight,
    soldByQuantityOnly,
    shoppingDisabled,
    originalPrice,
    discountedPrice,
    onAddToCartPressed,
    incrementQuantity,
    decrementQuantity,
    onWeightSelected,
    setDisplayMode,
    noSelectedWeightDefault,
    displayMode,
    confirmationOrEditingMode,
    selectedWeight,
    selectedQuantity,
  };

  return children(propsForChildren);
};

export default ListViewStateContainer;
