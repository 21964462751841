import type { Reducer } from 'redux';

import type {
  CartStore,
  CustomerReservationDetailsStore,
  DeepReadonly,
  Id,
  Store,
  StoreHeaderStore,
} from '@jane/shared/models';

import type OrderingSchedule from '../../lib/orderingSchedule';
import { snakeCaseToCamelCaseStoreDeserializer } from '../../lib/store';
import { createStandardAction } from '../../redux-util';
import { StoreSource } from '../../sources/store';
import type { CustomerThunkActionCreator } from '../redux';
import * as EmbeddedAppActions from './embeddedApp';
import type { CustomerAction } from './types';

export const GET_STORE = 'store/get';
export const getStore: CustomerThunkActionCreator<{
  id: Id;
  params?: unknown;
}> =
  ({ id, params }) =>
  (dispatch) => {
    dispatch({ type: GET_STORE });
    StoreSource.get(id, params).then(
      (result) => dispatch(getStoreSuccess(result)),
      (err) => dispatch(getStoreError(err))
    );
  };

export const GET_STORE_SUCCESS = 'store/get-success';
export const getStoreSuccess = createStandardAction(GET_STORE_SUCCESS)<{
  store: Store;
}>();

export const GET_STORE_ERROR = 'store/get-error';
export const getStoreError = createStandardAction(GET_STORE_ERROR)<unknown>();

export type StoreActions =
  | { type: typeof GET_STORE }
  | { type: typeof GET_STORE_ERROR }
  | ReturnType<typeof getStoreSuccess>
  | ReturnType<typeof getStoreError>;

export type StoreState = DeepReadonly<{
  hasLoaded: boolean;
  isLoadingStore: boolean;
  previousPath: string;
  schedules: undefined | OrderingSchedule;
  store: Store | NoStore;
}>;

export interface NoStore {
  noStore: true;
}

export const isNoStore = (
  x:
    | Store
    | CustomerReservationDetailsStore
    | StoreHeaderStore
    | CartStore
    | NoStore
): x is NoStore => 'noStore' in x;

const getInitialState = (): StoreState => ({
  store: {
    noStore: true,
  },
  schedules: undefined,
  isLoadingStore: false,
  hasLoaded: false,
  previousPath: '',
});

export const storeReducer: Reducer<StoreState, CustomerAction> = (
  state = getInitialState(),
  action
) => {
  switch (action.type) {
    case GET_STORE:
    case EmbeddedAppActions.VERIFY_STORE:
      return { ...state, isLoadingStore: true };

    case EmbeddedAppActions.VERIFY_STORE_SUCCESS:
    case GET_STORE_SUCCESS: {
      const { store } = action.payload;
      return {
        ...state,
        store,
        schedules: snakeCaseToCamelCaseStoreDeserializer(store),
        isLoadingStore: false,
        hasLoaded: true,
      };
    }

    case GET_STORE_ERROR:
      return { ...state, isLoadingStore: false };
  }

  return state;
};
