import type { RumInitConfiguration } from '@datadog/browser-rum';
import { datadogRum } from '@datadog/browser-rum';
import isEmpty from 'lodash/isEmpty';

import { config } from '@jane/shared/config';

export const initDatadogRum = (rumConfig: RumInitConfiguration) => {
  const { applicationId, clientToken, service } = rumConfig;
  // These env vars are only provided on production, so we don't init on dev env
  if (!isEmpty(applicationId) && !isEmpty(clientToken) && !isEmpty(service)) {
    const defaultConfig = {
      site: 'datadoghq.com',
      env: config.nodeEnv,
      version: config.buildVersion,
      sessionSampleRate: 10,
      sessionReplaySampleRate: 10,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    };
    const configWithOverrides = {
      ...defaultConfig,
      ...rumConfig,
    } as RumInitConfiguration;

    datadogRum.init(configWithOverrides);
    datadogRum.startSessionReplayRecording();
  } else {
    console.error(
      'Datadog RUM was not initialized. Missing required config: applicationId, clientToken, service'
    );
  }
};
