import { useCallback, useEffect, useRef } from 'react';
import type { MutableRefObject } from 'react';

import { Card, Typography, styled } from '@jane/reefer';
import { isSortedByPrice } from '@jane/search/util';
import type {
  Id,
  MenuProduct,
  Recommendation,
  ReservationCartProduct,
} from '@jane/shared/models';

import { customerConnect } from '../../../customer/redux';
import initialCountForProduct from '../../../lib/initialCountForProduct';
import { flex, spacing } from '../../../style';
import { Box } from '../../../style/layout';
import { RecommendationBadge } from '../../productCard/recommendationBadge';
import SpecialBadge from '../../productCard/specialBadge';
import { useImage } from '../../productCard/useImage';
import type { ProductCardMode } from '../../storeMenu/productCardHelper';
import { ProductDetailPageLink } from '../../storeMenu/productCardHelper';
import {
  DisplayMode,
  useProductCardContext,
} from '../providers/productCardProvider';
import ProductInfo from './menuProductInfo';

export const findCartProductForMenuProduct = (
  cartProducts: ReservationCartProduct[],
  menuProductId: Id,
  storeId: Id,
  cartStoreId?: Id
) =>
  cartProducts.find((cp) => cp.id === menuProductId && cartStoreId === storeId);

export interface Props {
  cartProducts: ReservationCartProduct[];
  cartStoreId?: Id;
  height?: number | string;
  itemWidth?: number | null;
  numberOfVisibleItems?: number;
  productCardMode?: ProductCardMode;
  selectedQuantity?: number;
  setProductCardWidth?: (arg: number) => void;
  shoppingDisabled: boolean;
}

export const BadgeContainer = styled.div(flex(), {
  position: 'absolute',
  top: 8,
  right: 8,
  zIndex: 99,
});

const Count = styled.span<{ count: number }>(
  spacing({ ml: 8 }),
  {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'inline-flex',
    borderRadius: '50%',
    height: 20,
    width: 20,
  },
  ({ theme, count }) => [
    {
      backgroundColor: theme.colors.primary.main,
    },

    {
      '> p': {
        marginLeft: count === 1 ? 1 : 0,
      },
    },
  ]
);

const UpdateCount = ({ count }: { count: number }) => (
  <Count count={count}>
    <Typography color="text-inverse" as="p" variant="body-bold">
      {count}
    </Typography>
  </Count>
);

const HIGHLIGHT_THRESHOLD = 65;

const MenuProductCard = ({
  height,
  itemWidth,
  setProductCardWidth,
  shoppingDisabled,
}: Props) => {
  const {
    cartProduct,
    currentSpecial,
    menuProduct: product,
    recommendation,
    searchState,
    setDisplayMode,
    store,
    fromSpecialId,
  } = useProductCardContext();
  const { srcSet, fallbackSrc, imageDimension } = useImage(product);
  const containerRef: MutableRefObject<HTMLDivElement | undefined> =
    useRef<HTMLDivElement>();

  useEffect(() => {
    setProductCardWidth &&
      containerRef.current &&
      setProductCardWidth(
        itemWidth || containerRef.current.getBoundingClientRect().width
      );
  }, []);

  const count = initialCountForProduct(cartProduct);

  const specialAmount = (product as MenuProduct).special_amount;

  const discountAmount =
    (specialAmount?.toUpperCase() === 'SALE' &&
      store?.store_compliance_language_settings?.specials) ||
    specialAmount;

  const specialApplies = discountAmount && currentSpecial;

  const score =
    (product as Recommendation).showScore && (product as Recommendation).score;
  const highlight =
    score &&
    (product as Recommendation).score >= HIGHLIGHT_THRESHOLD &&
    !!(product as Recommendation).best_match;

  const productDetailPageLinkProps = {
    product,
    searchState,
    store,
    fromMenu: true,
    fromSpecialId,
    bundleSpecialId:
      currentSpecial?.special_type === 'bundle' ? currentSpecial.id : undefined,
  };

  const hasMultipleWeights = product.available_weights.length > 1;

  const addToClickHandler = useCallback(() => {
    !shoppingDisabled && setDisplayMode(DisplayMode.Edit);
  }, [shoppingDisabled, setDisplayMode]);

  return (
    <Card width="100%" height={height}>
      <BadgeContainer>
        {specialApplies && (
          <SpecialBadge amount={discountAmount} specialAmount={specialAmount} />
        )}
      </BadgeContainer>
      {recommendation && (
        <RecommendationBadge highlight={!!highlight}>
          {score && <Box>{score}% Match</Box>}
        </RecommendationBadge>
      )}
      <ProductDetailPageLink {...productDetailPageLinkProps}>
        <Card.Image
          alt={product.name}
          ariaLabel="view menu product details"
          height={imageDimension}
          src={fallbackSrc}
          srcSet={srcSet}
          testId="menu-product-card default-view"
        />
      </ProductDetailPageLink>
      <ProductDetailPageLink height="100%" {...productDetailPageLinkProps}>
        <ProductInfo
          currentSpecial={currentSpecial}
          productIsInCart={!!cartProduct}
          sortedByPrice={isSortedByPrice(searchState)}
        />
      </ProductDetailPageLink>
      <Card.Action
        testId="menu-product-card-cta"
        ariaLabel={
          shoppingDisabled
            ? 'shopping disabled'
            : cartProduct
            ? 'update bag'
            : 'add to bag'
        }
        disabled={shoppingDisabled}
        endIcon={
          !shoppingDisabled &&
          cartProduct.length > 0 && <UpdateCount count={count} />
        }
        onClick={addToClickHandler}
        label={
          shoppingDisabled
            ? 'Shopping disabled'
            : cartProduct.length > 0
            ? 'Update'
            : hasMultipleWeights
            ? 'Select weight'
            : 'Add to bag'
        }
        variant="secondary"
      />
    </Card>
  );
};

export const MenuProductCardDefaultView = customerConnect(
  ({ cart: { cart } }) => {
    return {
      cartProducts: cart.products as unknown as ReservationCartProduct[],
      cartStoreId: cart.store && cart.store.id,
    };
  }
)(MenuProductCard);
