import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { ReactNode } from 'react';

import {
  Button,
  LeftArrowIcon,
  RightArrowIcon,
  mediaQueries,
  styled,
  useMobileMediaQuery,
} from '@jane/reefer';

import type { Spacing } from '../../style';
import { flex, spacing } from '../../style';
import { horizontalScroll } from '../../style/horizontalScroll';
import { PRODUCTS_GRID_GAP } from '../productsGrid';
import {
  useCarouselMenuProductCardWidth,
  useSetCarouselMenuProductCardWidth,
} from '../storeMenu/carouselMenuProductCardWidthContext';
import { DEFAULT_DESKTOP_CARD_WIDTH } from '../storeMenu/productCardHelper';

const DEFAULT_MOBILE_CAROUSEL_CARD_WIDTH = 175;

const ArrowContainer = styled.div<{
  arrowOffset?: number;
  left?: boolean;
}>(
  flex({ justifyContent: 'center', alignItems: 'center' }),
  ({ theme }) => ({
    position: 'absolute',
    zIndex: 1,
    backgroundColor: theme.colors.grays.white,
    width: 48,
    height: 48,
    borderRadius: '50%',
    [mediaQueries.desktop('sm', 'max')]: {
      display: 'none',
    },
  }),
  ({ left, arrowOffset }) => [
    left && { left: arrowOffset ? -arrowOffset : -22 },
    { right: arrowOffset ? -arrowOffset : -22 },
  ]
);

const ItemsContainer = styled.div<{
  blockDesktopScrolling?: boolean;
  itemMargin: number;
}>(
  flex({ alignItems: 'center' }),
  horizontalScroll(),
  ({ itemMargin, blockDesktopScrolling }) => [
    blockDesktopScrolling && {
      [mediaQueries.desktop('sm', 'min')]: { overflowX: 'clip' },
    },
    spacing({
      mx: -itemMargin as Spacing,
      py: itemMargin as Spacing,
      my: -itemMargin as Spacing,
    }),
  ]
);

const CardsContainer = styled.div<{
  dynamicWidth?: boolean;
  flexBasis?: string;
  itemWidth?: number;
  translateAmount: number;
}>(
  spacing({ py: 4, mx: 12 }),
  {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoRows: '1fr',
    gridGap: PRODUCTS_GRID_GAP,
    width: '100%',
    transition: 'all 500ms',
    [mediaQueries.mobile('lg', 'max')]: {
      // NOTE(elliot): On mobile there is no column gap at the end.
      '&:after': {
        content: '""',
        width: '0.01px',
      },
    },
  },
  ({ dynamicWidth, translateAmount, itemWidth, flexBasis }) => ({
    transform: `translateX(-${translateAmount}px)`,
    flexBasis: flexBasis || 'auto',
    gridAutoColumns: dynamicWidth
      ? '1fr'
      : `${itemWidth || DEFAULT_DESKTOP_CARD_WIDTH}px`,
    [mediaQueries.tablet('max')]: {
      gridAutoColumns: dynamicWidth
        ? '1fr'
        : `${itemWidth || DEFAULT_MOBILE_CAROUSEL_CARD_WIDTH}px`,
    },
  })
);

interface RenderProps<T> {
  index: number;
  item: T;
  itemWidth?: number;
}

interface ControlledIndex {
  currentIndex: number | null /* null if scrolling is allowed eg mobile */;
  onShowRightArrow: (showRightArrow: boolean) => void;
}

interface Props<T> {
  alwaysUseScrolling?: boolean;
  arrowOffset?: number;
  controlledIndex?: ControlledIndex /* allow controlling scroll/paging index externally */;
  dynamicWidth?: boolean;
  flexBasis?: string;
  itemMargin: number;
  itemRenderer: (arg: RenderProps<T>) => ReactNode;
  items: readonly T[];
  storeMenu?: boolean;
}

const ItemsCarousel = <T extends {}>({
  items,
  itemRenderer,
  itemMargin,
  arrowOffset,
  alwaysUseScrolling,
  dynamicWidth,
  storeMenu,
  controlledIndex,
  flexBasis,
}: Props<T>) => {
  const isMobile = useMobileMediaQuery({});

  const cardWidth = useCarouselMenuProductCardWidth();
  const setCardWidth = useSetCarouselMenuProductCardWidth();

  const [maxScrollWidth, setMaxScrollWidth] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(
    controlledIndex ? controlledIndex?.currentIndex : 0
  );

  const [itemWidth, setItemWidth] = useState(storeMenu ? cardWidth : 0);

  useEffect(() => {
    if (!storeMenu) {
      return;
    }
    setItemWidth(cardWidth);
  }, [cardWidth, storeMenu]);

  const carousel = useRef<HTMLDivElement | null>();
  const cardGap = useMemo(() => itemMargin * 2, [itemMargin]);
  const updateMaxWidth = useCallback(
    () =>
      carousel.current &&
      setMaxScrollWidth(
        carousel.current?.scrollWidth - carousel.current?.offsetWidth
      ),
    []
  );

  useEffect(() => {
    updateMaxWidth();
  }, [itemWidth, updateMaxWidth, cardWidth, items]);

  useEffect(() => {
    // NOTE(elliot): When item width is updated, the cards should shrink or grow to fit new space.
    const onResize = debounce(updateMaxWidth, 300);
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, []);

  useEffect(() => {
    // NOTE(elliot): Should only widen item width to fill space when there is overflow and we don't want to show a cut off card.
    if (carousel.current?.scrollWidth === carousel.current?.offsetWidth) {
      return;
    }

    const defaultCardWidth = isMobile
      ? Math.min(
          DEFAULT_MOBILE_CAROUSEL_CARD_WIDTH,
          (carousel.current?.offsetWidth || 0) / 2
        )
      : DEFAULT_DESKTOP_CARD_WIDTH + cardGap;

    const newItemWidth =
      carousel.current?.offsetWidth /
        Math.floor(carousel.current?.offsetWidth / defaultCardWidth) -
      cardGap;

    if (newItemWidth !== itemWidth) {
      // NOTE(elliot): When the carousel is in the store menu, we have to sync the card widths even if some rows don't fill all the space.
      storeMenu ? setCardWidth(newItemWidth) : setItemWidth(newItemWidth);
    }
  }, [
    carousel.current?.scrollWidth,
    carousel.current?.offsetWidth,
    cardGap,
    isMobile,
    itemWidth,
    maxScrollWidth,
    cardWidth,
    setCardWidth,
    storeMenu,
  ]);

  const carouselItems = useMemo(
    () =>
      items.map((item: T, index: number) =>
        itemRenderer({
          item,
          index,
          itemWidth,
        })
      ),
    [items, itemRenderer, itemWidth]
  );

  useEffect(() => {
    if (controlledIndex) setCurrentIndex(controlledIndex?.currentIndex);
  }, [controlledIndex?.currentIndex]);

  const showArrows = !controlledIndex && !alwaysUseScrolling;
  const showRightArrow = carousel.current
    ? carousel.current.offsetWidth * currentIndex < maxScrollWidth
    : false;
  const showLeftArrow = currentIndex > 0;

  useEffect(() => {
    if (controlledIndex?.onShowRightArrow)
      controlledIndex?.onShowRightArrow(showRightArrow);
  }, [controlledIndex?.onShowRightArrow, showRightArrow]);

  return (
    <ItemsContainer
      blockDesktopScrolling={!alwaysUseScrolling}
      itemMargin={itemMargin}
    >
      {showArrows && showLeftArrow && (
        <ArrowContainer left arrowOffset={arrowOffset}>
          <Button.Icon
            variant="primary"
            icon={<LeftArrowIcon />}
            onClick={() => setCurrentIndex(currentIndex - 1)}
            aria-label="Scroll left"
          />
        </ArrowContainer>
      )}

      <CardsContainer
        data-testid="product-cards"
        flexBasis={flexBasis}
        dynamicWidth={dynamicWidth}
        ref={carousel}
        translateAmount={Math.min(
          (carousel.current?.offsetWidth || 0) * currentIndex,
          maxScrollWidth + cardGap // NOTE(elliot): Needs to scroll with margin at the end.
        )}
        itemWidth={cardWidth || itemWidth}
      >
        {carouselItems}
      </CardsContainer>
      {showArrows && showRightArrow && (
        <ArrowContainer arrowOffset={arrowOffset}>
          <Button.Icon
            variant="primary"
            icon={<RightArrowIcon />}
            onClick={() => setCurrentIndex(currentIndex + 1)}
            aria-label="Scroll right"
          />
        </ArrowContainer>
      )}
    </ItemsContainer>
  );
};

export default ItemsCarousel;
