import isEqual from 'lodash/isEqual';
import type { FunctionComponent } from 'react';
import { useEffect, useRef } from 'react';

import type { TrackedEvent } from './eventNames';
import { track } from './track';

export const useTrack: (event: TrackedEvent | null) => void = (event) => {
  const lastEvent = useRef<TrackedEvent | null>(null);

  useEffect(() => {
    if (event && !isEqual(event, lastEvent.current)) {
      track(event);
      lastEvent.current = event;
    }
  });
};

export const Track: FunctionComponent<{
  event: TrackedEvent | null;
}> = (props) => {
  useTrack(props.event);
  return null;
};
