import { useEffect, useRef, useState } from 'react';

import { appModeSelector, useCustomerSelector } from '../customer/selectors';
import { useUpdateBoundingRect } from './useUpdateBoundingRect';

export interface InViewObserverProps {
  mktpOffsetBottom?: number;
  mktpOffsetTop?: number;
  threshold?: number;
}

export const useInViewObserver = (
  element: HTMLElement | null,
  {
    mktpOffsetTop = 0,
    mktpOffsetBottom = 0,
    threshold = 0.5,
  }: InViewObserverProps
) => {
  const [inView, setInView] = useState(false);
  const mktpObserverRef = useRef(new IntersectionObserver(() => {}));
  const appMode = useCustomerSelector(appModeSelector);
  const rect = useUpdateBoundingRect(element);

  useEffect(() => {
    if (!appMode || !element || !rect) return;

    mktpObserverRef.current.disconnect();

    const intersectionCallback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        setInView(entry.isIntersecting);
      });
    };

    mktpObserverRef.current = new IntersectionObserver(intersectionCallback, {
      rootMargin: `-${mktpOffsetTop}px 0px -${mktpOffsetBottom}px 0px`,
      threshold,
    });
    mktpObserverRef.current.observe(element);

    return () => mktpObserverRef.current.disconnect();
  }, [
    appMode,
    element,
    mktpOffsetTop,
    threshold,
    mktpOffsetBottom,
    rect && rect.top,
    rect && rect.left,
    rect && rect.width,
    rect && rect.height,
  ]);

  return inView;
};
