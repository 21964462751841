import type { CSSObject } from '@emotion/css';
import styled from '@emotion/styled';

import type { ReeferTheme } from '../../types';
import type { ImageProps } from './image';

export interface StyledImageProps
  extends Omit<ImageProps, 'altText' | 'borderRadius'> {
  borderRadius: string;
}

interface StyledImageWrapperProps {
  border?: boolean;
  borderRadius: 'circular' | 'rounded' | 'rounded-small' | 'none' | string;
  height: string;
  width: string;
}

interface StyledImageBorderRadiusProps
  extends Pick<StyledImageWrapperProps, 'borderRadius'> {
  theme: ReeferTheme;
}

const imageBorderRadius = ({
  borderRadius,
  theme,
}: StyledImageBorderRadiusProps) => {
  let radius = borderRadius;

  if (borderRadius === 'none') {
    return;
  }

  if (borderRadius === 'rounded') {
    radius = theme.borderRadius.lg as string;
  }

  if (borderRadius === 'circular') {
    radius = '50%';
  }

  if (borderRadius === 'rounded-small') {
    radius = theme.borderRadius.sm as string;
  }

  return {
    borderRadius: radius,
  } as CSSObject;
};

export const StyledImageWrapper = styled.div<StyledImageWrapperProps>(
  ({ theme, height, width, border, borderRadius }) => ({
    position: 'relative',
    height,
    width,
    overflow: 'hidden',
    '&:before': {
      position: 'absolute',
      content: "''",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      ...(border && {
        border: '1px solid rgba(0, 0, 0, 0.05)',
        borderStyle: 'inset',
      }),
      ...imageBorderRadius({ borderRadius, theme }),
    },
  })
);

export const StyledImage = styled.img<StyledImageProps>(
  ({ objectFit, borderRadius, theme, objectPosition }) => ({
    height: '100%',
    width: '100%',
    objectFit,
    objectPosition,
    ...imageBorderRadius({ borderRadius, theme }),
  })
);
