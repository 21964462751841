import React from 'react';

import { ImagePreview } from '@jane/shared/util';

import { commonConnect } from '../../common/redux';
import Modal from '../modal';
import { PinchView } from './react-pinch-zoom-pan';

interface Props {
  modalOptions: {
    image: string;
    orientation?: number;
  };
}

class ZoomInModal extends React.Component<Props> {
  render() {
    const { modalOptions } = this.props;
    const { image, orientation } = modalOptions;
    const rotation = ImagePreview.rotationForOrientation(orientation);

    const dummyImage = new Image();
    dummyImage.src = image;

    return (
      <Modal className="product-detail-modal" contentLabel="Zoom in">
        {() => (
          <div className="modal-image">
            <PinchView
              backgroundColor="#444"
              maxScale={3}
              containerRatio={(dummyImage.height / dummyImage.width) * 100}
            >
              <div
                className="uploader-preview__image"
                style={{
                  backgroundImage: `url(${image})`,
                  transform: `rotate(${rotation}deg)`,
                }}
              />
            </PinchView>
          </div>
        )}
      </Modal>
    );
  }
}

export default commonConnect((state) => ({
  modalOptions: state.application.modalOptions,
}))(ZoomInModal);
