import type { ReactNode } from 'react';

import { Flex, Skeleton, Typography } from '@jane/reefer';

import { CarouselHeaderContainer } from './carouselHeader.styles';

export interface CarouselHeaderProps {
  /** Carousel children, add carousel cards here */
  children?: ReactNode;

  /** Whether to display the carousel header's loading state */
  isLoading?: boolean;

  /** Name of the carousel, displayed as heading beside the controls */
  name: string;
}

type CarouselHeaderSkeletonProps = Omit<
  CarouselHeaderProps,
  'isLoading' | 'name'
>;

function CarouselHeaderSkeleton({ children }: CarouselHeaderSkeletonProps) {
  return (
    <CarouselHeaderContainer
      alignItems="center"
      justifyContent="space-between"
      width="100%"
    >
      <Flex width="50%">
        <Skeleton.Bone height="48px" width="100%" borderRadius="sm" />
      </Flex>
      {children}
    </CarouselHeaderContainer>
  );
}

export function CarouselHeader({
  children,
  isLoading = false,
  name,
}: CarouselHeaderProps) {
  if (isLoading) {
    return <CarouselHeaderSkeleton>{children}</CarouselHeaderSkeleton>;
  }
  return (
    <CarouselHeaderContainer
      alignItems="center"
      justifyContent="space-between"
      width="100%"
    >
      <Typography as="h2" branded variant="header-bold">
        {name}
      </Typography>
      {children}
    </CarouselHeaderContainer>
  );
}
