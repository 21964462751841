import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Energetic = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 26 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.029 0.285316C13.0818 -0.28298 11.8533 0.0241503 11.285 0.971311L5.28501 10.9713C4.9143 11.5892 4.90459 12.3587 5.2596 12.9857C5.61461 13.6127 6.27946 14.0003 7 14.0003H15.4676L11.285 20.9713C10.7167 21.9185 11.0238 23.147 11.971 23.7153C12.9182 24.2836 14.1467 23.9765 14.715 23.0293L20.715 13.0293C21.0857 12.4114 21.0954 11.6419 20.7404 11.0149C20.3854 10.3879 19.7205 10.0003 19 10.0003H10.5324L14.715 3.02929C15.2833 2.08213 14.9762 0.853613 14.029 0.285316Z"
      fill="#FF6334"
    />
  </svg>
);
export const EnergeticIcon = ({ ...props }: StaticIconProps) => {
  return <Icon icon={Energetic} {...props} color={undefined} />;
};
