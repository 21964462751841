import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const ChevronRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.29289 4.29289C7.68342 3.90237 8.31658 3.90237 8.70711 4.29289L15.7071 11.2929C16.0976 11.6834 16.0976 12.3166 15.7071 12.7071L8.70711 19.7071C8.31658 20.0976 7.68342 20.0976 7.29289 19.7071C6.90237 19.3166 6.90237 18.6834 7.29289 18.2929L13.5858 12L7.29289 5.70711C6.90237 5.31658 6.90237 4.68342 7.29289 4.29289Z"
      fill="#0E0E0E"
    />
  </svg>
);
export const ChevronRightIcon = ({ ...props }: DynamicIconProps) => {
  return <Icon icon={ChevronRight} {...props} color={props.color} />;
};
