import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const HangWithFriends = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={16} cy={8} r={8} fill="#CE349A" />
    <circle cx={16} cy={8} r={8} fill="white" fillOpacity={0.8} />
    <path
      d="M14.6667 6.00002C14.6667 6.7364 14.0697 7.33335 13.3333 7.33335C12.597 7.33335 12 6.7364 12 6.00002C12 5.26364 12.597 4.66669 13.3333 4.66669C14.0697 4.66669 14.6667 5.26364 14.6667 6.00002Z"
      fill="black"
      fillOpacity={0.5}
    />
    <path
      d="M11.2929 9.95958C11.6834 9.56906 12.3166 9.56906 12.7071 9.95958C14.5257 11.7782 17.4743 11.7782 19.2929 9.95958C19.6834 9.56906 20.3166 9.56906 20.7071 9.95958C21.0976 10.3501 21.0976 10.9833 20.7071 11.3738C18.1074 13.9735 13.8926 13.9735 11.2929 11.3738C10.9024 10.9833 10.9024 10.3501 11.2929 9.95958Z"
      fill="black"
      fillOpacity={0.5}
    />
    <path
      d="M18.6667 7.33335C19.4031 7.33335 20 6.7364 20 6.00002C20 5.26364 19.4031 4.66669 18.6667 4.66669C17.9303 4.66669 17.3334 5.26364 17.3334 6.00002C17.3334 6.7364 17.9303 7.33335 18.6667 7.33335Z"
      fill="black"
      fillOpacity={0.5}
    />
    <circle cx={8} cy={16} r={8} fill="#FFC220" />
    <path
      d="M6.66667 14C6.66667 14.7364 6.06971 15.3334 5.33333 15.3334C4.59695 15.3334 4 14.7364 4 14C4 13.2636 4.59695 12.6667 5.33333 12.6667C6.06971 12.6667 6.66667 13.2636 6.66667 14Z"
      fill="black"
      fillOpacity={0.5}
    />
    <path
      d="M3.29289 17.9596C3.68342 17.5691 4.31658 17.5691 4.70711 17.9596C6.52572 19.7782 9.47428 19.7782 11.2929 17.9596C11.6834 17.5691 12.3166 17.5691 12.7071 17.9596C13.0976 18.3501 13.0976 18.9833 12.7071 19.3738C10.1074 21.9735 5.89256 21.9735 3.29289 19.3738C2.90237 18.9833 2.90237 18.3501 3.29289 17.9596Z"
      fill="black"
      fillOpacity={0.5}
    />
    <path
      d="M10.6667 15.3334C11.4031 15.3334 12 14.7364 12 14C12 13.2636 11.4031 12.6667 10.6667 12.6667C9.93033 12.6667 9.33337 13.2636 9.33337 14C9.33337 14.7364 9.93033 15.3334 10.6667 15.3334Z"
      fill="black"
      fillOpacity={0.5}
    />
  </svg>
);
export const HangWithFriendsIcon = ({ ...props }: StaticIconProps) => {
  return <Icon icon={HangWithFriends} {...props} color={undefined} />;
};
