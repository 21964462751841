import { rest } from 'msw';

import { buildBrand } from '@jane/shared/factories';

export const mockGetAlgoliaBrands = (brands = [buildBrand()], facets = {}) => {
  return rest.post(
    `https://*.algolia.net/1/indexes/product-brand-*/query`,
    async (_req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json({
          facets,
          hits: brands,
          length: brands.length,
          nbHits: brands.length,
          nbPages: 0,
          offset: 0,
          page: 0,
        })
      );
    }
  );
};
