import * as t from 'io-ts';

import type { Id } from './api';
import type { AppMode } from './appMode';
import type { CrmRedemption } from './crmIntegration';
import type { DeepReadonly } from './deepReadonly';
import type { MenuProduct, PriceId } from './menuProduct';
import { tMenuProduct, tPriceId } from './menuProduct';
import { tPhoto, tProduct } from './product';
import type { LocalizedProductContent } from './reservation';
import type { ReservationMode } from './reservationMode';
import type { BrandDiscount } from './specials';

export interface Tags {
  og_ref?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_medium?: string;
  utm_source?: string;
  utm_term?: string;
}

export type Cart = DeepReadonly<{
  all_discounts_total?: number;
  app_mode?: {
    id: Id;
    name: AppMode;
  };
  app_mode_name?: AppMode;
  brand_discounts: BrandDiscount[];
  brand_special_has_changed: boolean;
  cart_discount_amount?: number;
  cart_discount_special_id?: Id | null;
  crm_redemptions?: CrmRedemption[];
  delivery_fee_amount?: number;
  delivery_finished_at?: string | null;
  delivery_started_at?: string | null;
  discounted_product_total?: number;
  discounted_subtotal?: number;
  id?: Id;
  is_open?: boolean;
  item_total?: number;
  localized_products_content: LocalizedProductContent[];
  loyalty_points_stacking_enabled: boolean;
  max_specials_per_cart: number | null;
  max_specials_per_cart_hit: boolean;
  message?: string | null;
  order_total?: number;
  payment_method?: string | null;
  product_discounts_total?: number;
  products: PendingCartProduct[];
  promo_code?: string;
  quantity_adjusted_products?: Array<number>;
  removed_crm_redemptions?: CrmRedemption[];
  reservation_mode: ReservationMode;
  sales_tax_amount: number;
  sales_tax_rate: number;
  service_fee_amount?: number;
  special_has_changed: boolean;
  store:
    | {
        id: Id;
      }
    | undefined;
  store_tax_amount?: number;
  tags?: Tags;
  tax_included: boolean;
  tip_amount: string;
  unavailable_products: UnavailableProduct[];
  uuid: string;
}>;

export interface CartProductCreateParams {
  count: number;
  id: number;
  price_id: PriceId;
}

export const tPendingCartProduct = t.intersection([
  tMenuProduct,
  t.interface({
    id: t.number,
    count: t.number,
    price_id: tPriceId,
  }),
]);

export type PendingCartProduct = MenuProduct &
  Readonly<CartProductCreateParams>;

type UnavailableProduct = Pick<
  PendingCartProduct,
  'product_id' | 'price_id' | 'name'
>;

export const tReservationCartProduct = t.intersection([
  tProduct,
  t.interface({
    amount: t.union([t.string, t.null]),
    brand: t.union([t.string, t.null]),
    checkout_price: t.number,
    count: t.number,
    discounted_checkout_price: t.number,
    photos: t.union([t.array(tPhoto), t.null, t.undefined]),
    price_id: tPriceId,
    special_id: t.union([t.number, t.null]),
    special_title: t.union([t.string, t.null]),
  }),
]);

export type ReservationCartProduct = DeepReadonly<
  t.TypeOf<typeof tReservationCartProduct>
>;
