import type { ReactNode } from 'react';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Card,
  Flex,
  Link,
  Typography,
  useMobileMediaQuery,
} from '@jane/reefer';
import type { Hit, SearchResults, SearchState } from '@jane/search/types';
import { LoadingWrapper } from '@jane/shared-ecomm/components';
import type { ClickedSeeAll } from '@jane/shared-ecomm/tracking';
import { EventNames, track, trackClickedAd } from '@jane/shared-ecomm/tracking';
import {
  generateCustomLabel,
  getAppliedWeightFilter,
  postMessageToIframeParent,
} from '@jane/shared-ecomm/util';
import { config } from '@jane/shared/config';
import { FLAGS, useFlagHelper } from '@jane/shared/feature-flags';
import type {
  AdData,
  MenuProduct,
  Store,
  StoreSpecial,
  _DeepReadonlyObject,
} from '@jane/shared/models';
import { titleCase } from '@jane/shared/util';

import type { MediaQueryState } from '../../common/providers/mediaQueryProvider';
import { useCustomerDispatch } from '../../customer/dispatch';
import {
  appModeSelector,
  isEmbeddedModeSelector,
  useCustomerSelector,
} from '../../customer/selectors';
import { callResetInitialQuery } from '../../lib/initialQuery';
import { paths } from '../../lib/routes';
import { get } from '../../redux-util/selectors';
import ItemsCarousel from '../itemsCarousel';
import { ITEM_MARGIN_AMOUNT } from '../itemsCarousel/carouselHelper';
import BucketHeader from './bucketHeader';
import HitProductCard from './hitProductCard';
import { generateSearchState } from './oldSearch/bucketSearchContextHelper';
import {
  MENU_PRODUCT_ITEM_HEIGHT,
  MENU_PRODUCT_MOBILE_ITEM_HEIGHT,
} from './productCardHelper';

const { algoliaEnv } = config;

const NUMBER_HITS_TO_RENDER = 16;

export const SeeAllCard = ({
  productType,
  store,
  onClick,
}: {
  onClick?: () => void;
  productType: string;
  store: Store;
}) => {
  const appMode = useCustomerSelector(appModeSelector);
  const isMobile = useMobileMediaQuery({});

  const displayedProductType = generateCustomLabel({
    appMode,
    store,
    attribute: productType,
    fallback: productType,
  });

  return (
    <Card
      width="100%"
      height={
        isMobile ? MENU_PRODUCT_MOBILE_ITEM_HEIGHT : MENU_PRODUCT_ITEM_HEIGHT
      }
      onClick={onClick}
      ariaLabel="See all card"
    >
      <Box height="100%" background="primary" borderRadius="lg">
        <Flex
          height="100%"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            color="grays-white"
            textAlign="center"
          >{`See All ${titleCase(displayedProductType)}`}</Typography>
        </Flex>
      </Box>
    </Card>
  );
};

interface LinkWrapperProps {
  ariaLabel?: string;
  children: ReactNode;
  flightProps?: AdData['flight'];
  isKiosk: boolean;
  searchState: SearchState;
  store: Store;
  trackingInfo: ClickedSeeAll;
  variant?: 'underline' | 'minimal';
}

export const SeeAllLinkWrapper = ({
  isKiosk,
  searchState,
  store,
  children,
  trackingInfo,
  ariaLabel,
  flightProps,
  variant = 'underline',
}: LinkWrapperProps) => {
  const isEmbeddedMode = useCustomerSelector(isEmbeddedModeSelector);
  const { appMode } = useCustomerSelector(get('embeddedApp'));
  const { janeDeviceId } = useCustomerSelector(get('application'));
  const { initialQuery } = useCustomerSelector(get('initialQuery'));
  const dispatch = useCustomerDispatch();

  return (
    <Link
      aria-label={ariaLabel}
      onClick={() => {
        callResetInitialQuery(appMode, dispatch, initialQuery);
        if (isEmbeddedMode) {
          postMessageToIframeParent({
            messageType: 'scrollToTop',
          });
        }
        trackClickedAd(
          isKiosk ? mixpanel?.get_distinct_id() : janeDeviceId,
          flightProps
        );
        track(trackingInfo);
      }}
      to={
        isKiosk
          ? paths.kioskMenu(searchState)
          : isEmbeddedMode && appMode !== 'brandEmbed'
          ? paths.embeddedMenu(searchState)
          : paths.store({ id: store.id, name: store.name }, searchState)
      }
      variant={variant}
    >
      {children}
    </Link>
  );
};

export const useSeeAllLinkClick = ({
  flightProps,
  isKiosk,
  searchState,
  store,
  trackingInfo,
}: Omit<LinkWrapperProps, 'variant' | 'children' | 'ariaLabel'>) => {
  const isEmbeddedMode = useCustomerSelector(isEmbeddedModeSelector);
  const { appMode } = useCustomerSelector(get('embeddedApp'));
  const { janeDeviceId } = useCustomerSelector(get('application'));
  const { initialQuery } = useCustomerSelector(get('initialQuery'));
  const dispatch = useCustomerDispatch();
  const navigate = useNavigate();

  const to = useMemo(
    () =>
      isKiosk
        ? paths.kioskMenu(searchState)
        : isEmbeddedMode && appMode !== 'brandEmbed'
        ? paths.embeddedMenu(searchState)
        : paths.store({ id: store.id, name: store.name }, searchState),
    [appMode, isEmbeddedMode, isKiosk, searchState, store.id, store.name]
  );

  const onClick = useCallback(() => {
    callResetInitialQuery(appMode, dispatch, initialQuery);
    if (isEmbeddedMode) {
      postMessageToIframeParent({
        messageType: 'scrollToTop',
      });
    }
    trackClickedAd(
      isKiosk ? mixpanel?.get_distinct_id() : janeDeviceId,
      flightProps
    );
    track(trackingInfo);

    navigate(to);
  }, [
    appMode,
    dispatch,
    initialQuery,
    isEmbeddedMode,
    flightProps,
    isKiosk,
    janeDeviceId,
    navigate,
    to,
    trackingInfo,
  ]);

  return onClick;
};

export const DEFAULT_PRODUCT_CARD_WIDTH = 200;

interface Props {
  currentCycleIndex?: number;
  flightProps?: AdData['flight'];
  hits?:
    | _DeepReadonlyObject<Hit<MenuProduct>[]>
    | _DeepReadonlyObject<MenuProduct[]>;
  isAd?: boolean;
  isDiscoverMenuProducts?: boolean;
  isKiosk: boolean;
  kindValue?: string;
  lastBucket: boolean;
  listView: boolean;
  media: MediaQueryState;
  name: string;
  outerSearchState: SearchState;
  placementIndex: number;
  searchResults: Pick<SearchResults, 'nbHits' | 'index'>;
  specials?: StoreSpecial[];
  store: Store;
}

export const ProductKindBucketCarousel = ({
  hits,
  listView,
  name,
  kindValue,
  outerSearchState,
  searchResults,
  specials,
  store,
  media,
  isKiosk,
  placementIndex,
  isAd,
  flightProps,
  isDiscoverMenuProducts,
  currentCycleIndex,
}: Props) => {
  const newSearchLib = useFlagHelper(FLAGS.newSearchLib);

  const seeAllLinkWrapperProps = {
    searchState: generateSearchState(kindValue || name, outerSearchState, isAd),
    isKiosk,
    store,
  };
  const onSeeAllLinkClick = useSeeAllLinkClick({
    ...seeAllLinkWrapperProps,
    flightProps,
    trackingInfo: {
      event: EventNames.ClickedSeeAll,
      category: name,
      linkLocation: 'carousel card',
      numberOfItems: searchResults?.nbHits,
      flightId: flightProps?.id,
      creativeIds: flightProps?.creative_ids,
      productBrandId: flightProps?.product_brand.id,
      placementIndex,
    },
  });

  const count = searchResults?.nbHits;
  const indexName = searchResults?.index;

  const alphabeticalSort =
    !outerSearchState?.sortBy?.includes('price') &&
    !outerSearchState?.sortBy?.includes('rating') &&
    !outerSearchState?.sortBy?.includes('potency');

  const hitsToRender = useMemo(() => {
    // default sort for best-selling is by overall sales and not alphabetical
    // this is temporary pending default state for all products
    if (alphabeticalSort) {
      if (kindValue === 'best_selling') {
        return [...hits]
          .sort((a, b) => (a.best_seller_rank || 0) - (b.best_seller_rank || 0))
          .slice(0, NUMBER_HITS_TO_RENDER);
      }
    }

    return (hits || []).slice(0, NUMBER_HITS_TO_RENDER);
  }, [hits, kindValue, alphabeticalSort]);

  if (!hits) return null;

  return (
    <LoadingWrapper variant="carousel" isLoading={!searchResults}>
      {count > 0 && (
        <Box
          aria-label={isAd ? 'sponsored content' : 'product carousel'}
          pb={64}
        >
          <BucketHeader
            showSeeAll
            media={media}
            count={count}
            name={name}
            isAd={isAd}
            flightProps={flightProps}
            isDiscoverMenuProducts={isDiscoverMenuProducts}
            placementIndex={placementIndex}
            {...seeAllLinkWrapperProps}
          />
          {
            <ItemsCarousel
              alwaysUseScrolling={isKiosk}
              storeMenu
              items={hitsToRender}
              itemMargin={ITEM_MARGIN_AMOUNT}
              arrowOffset={newSearchLib.active ? 20 : 34}
              itemRenderer={({ item, index, itemWidth }) => {
                return index === NUMBER_HITS_TO_RENDER - 1 ? (
                  <SeeAllCard
                    key={isAd ? `ad-${name}` : name}
                    onClick={onSeeAllLinkClick}
                    productType={name}
                    store={store}
                  />
                ) : (
                  <HitProductCard
                    appliedWeightFilter={getAppliedWeightFilter(
                      outerSearchState
                    )}
                    specials={specials}
                    key={
                      isAd
                        ? `ad-${item.id}`
                        : item?.flight
                        ? `inline-ad-${item.id}`
                        : `mp-${(item as Hit<MenuProduct>).objectID}-${index}`
                    }
                    listView={listView}
                    hit={item}
                    searchState={outerSearchState || {}}
                    store={store}
                    carouselView
                    rowPosition={placementIndex}
                    columnPosition={index}
                    flightProps={flightProps}
                    algoliaIndexName={
                      isAd ? `products-${algoliaEnv}` : indexName
                    }
                    bucketName={name}
                    currentCycleIndex={currentCycleIndex}
                    itemWidth={itemWidth}
                  />
                );
              }}
            />
          }
        </Box>
      )}
    </LoadingWrapper>
  );
};
