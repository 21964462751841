import { CheckboxField } from '../../forms/fields/checkboxField';
import type { TableCellProps } from '../table.types';
import { StyledTableCell } from './tableCell.styles';

export function TableCell({ checkbox, children, onClick }: TableCellProps) {
  return (
    <StyledTableCell onClick={onClick}>
      <>
        {checkbox && (
          <CheckboxField
            checked={checkbox.checked}
            onChange={checkbox.onChange}
            label=""
            name="cell-checkbox"
          />
        )}
        {!checkbox && children}
      </>
    </StyledTableCell>
  );
}
