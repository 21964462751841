import { useCallback, useReducer, useState } from 'react';

import {
  Button,
  Flex,
  LeftArrowIcon,
  RightArrowIcon,
  Typography,
  spacing,
  styled,
  useDesktopMediaQuery,
} from '@jane/reefer';
import type { CartTopper } from '@jane/shared-ecomm/hooks';
import { useCartToppers } from '@jane/shared-ecomm/hooks';
import { trackImpressedMlProduct } from '@jane/shared-ecomm/tracking';
import { useGetStore } from '@jane/shared/data-access';

import {
  appModeSelector,
  isEmbeddedModeSelector,
  useCustomerSelector,
} from '../../../customer/selectors';
import { MENU_PRODUCTS_BY_PRICE_ASC } from '../../../lib/algoliaIndices';
import { get } from '../../../redux-util/selectors';
import { useTrackDmEvent } from '../../../shared-hooks';
import { ErrorBoundary } from '../../errorBoundary';
import ItemsCarousel from '../../itemsCarousel';
import { ITEM_MARGIN_AMOUNT } from '../../itemsCarousel/carouselHelper';
import HitProductCard from '../../storeMenu/hitProductCard';
import { InViewTrackedComponent } from './inViewTrackedComponent';
import { trackCartTopperImpression } from './tracking';

const Container = styled(Flex)<{ isDrawer }>(
  ({ isDrawer, theme }) =>
    isDrawer && {
      ...spacing({ mt: 16, px: 24, pb: 20 }),
      backgroundColor: theme.colors.grays.white,
    }
);

export const CartToppers = ({ isDrawer }: { isDrawer?: boolean }) => {
  const appMode = useCustomerSelector(appModeSelector);
  const isEmbedded = useCustomerSelector(isEmbeddedModeSelector);
  const { janeDeviceId } = useCustomerSelector(get('application'));
  const { cart } = useCustomerSelector(get('cart'));
  const storeId = cart?.store.id;
  const { id: userId } = useCustomerSelector(get('customer'));
  const { data: store } = useGetStore(storeId);
  const { trackDmError } = useTrackDmEvent();
  const isDesktop = useDesktopMediaQuery({});
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [trackedItems, dispatchTrackedItem] = useReducer(
    (state, productId) => ({ ...state, [productId]: true }),
    {}
  );

  const showArrows = isDesktop || appMode === 'headless';
  const [rightArrowDisabled, setRightArrowDisabled] = useState(true);
  const onShowRightArrow = useCallback(
    (showRightArrow: boolean) => setRightArrowDisabled(!showRightArrow),
    []
  );

  const cartProductIds = cart.products.map((product) => product.id);
  const cartToppers = useCartToppers({
    appMode,
    cartProductIds,
    janeDeviceId,
    storeId,
    trackDmError,
  });

  const trackImpression = useCallback(
    (isInView: boolean, cartTopper: CartTopper) => {
      const { menuProduct, mlScoringSignals } = cartTopper;
      const productId = menuProduct.product_id;
      const hasTrackedImpression = !!trackedItems[productId];
      const shouldTrackImpression = isInView && !hasTrackedImpression;
      if (shouldTrackImpression) {
        dispatchTrackedItem(productId);
        trackCartTopperImpression({
          appMode,
          cartTopper: menuProduct,
          cartProducts: [...cart.products],
          janeDeviceId,
          mlScoringSignals,
          storeId,
          userId,
          trackDmError,
        });
        if (mlScoringSignals) {
          trackImpressedMlProduct({
            appMode,
            janeDeviceId,
            mlScoringSignals,
            model: menuProduct.flight ? menuProduct.flight.model : 'organic',
            placementRow: 'cartToppers',
            productBrand: menuProduct.brand,
            productBrandId: menuProduct.product_brand_id,
            productId: menuProduct.product_id,
            storeId,
            zone: 'cart',
          });
        }
      }
    },
    [
      appMode,
      cart.products,
      janeDeviceId,
      storeId,
      trackDmError,
      trackedItems,
      userId,
    ]
  );

  if (!store || !cartToppers.length) return null;

  return (
    <ErrorBoundary>
      <Container isDrawer={isDrawer} flexDirection="column">
        <Flex
          alignItems="center"
          flexDirection="row"
          justifyContent="space-between"
          mt={40}
          mb={24}
        >
          <Typography as="h1" branded={!isEmbedded} variant="header-bold">
            Add to your order
          </Typography>
          {showArrows && (
            <Flex gap={16}>
              <Button.Icon
                variant="tertiary"
                icon={<LeftArrowIcon />}
                disabled={carouselIndex === 0}
                onClick={() => setCarouselIndex(carouselIndex - 1)}
              />
              <Button.Icon
                variant="tertiary"
                icon={<RightArrowIcon />}
                disabled={rightArrowDisabled}
                onClick={() => setCarouselIndex(carouselIndex + 1)}
              />
            </Flex>
          )}
        </Flex>
        <ItemsCarousel
          controlledIndex={{
            currentIndex: showArrows ? carouselIndex : null,
            onShowRightArrow,
          }}
          items={cartToppers}
          itemMargin={ITEM_MARGIN_AMOUNT}
          itemRenderer={({ item: cartTopper, index }) => (
            <InViewTrackedComponent
              key={`cart-topper-${cartTopper.menuProduct.product_id}`}
              onChildrenInView={(isInView) =>
                trackImpression(isInView, cartTopper)
              }
              delay={1000}
            >
              <HitProductCard
                algoliaIndexName={MENU_PRODUCTS_BY_PRICE_ASC}
                appliedWeightFilter={''}
                bucketName="cart-toppers"
                carouselView={true}
                columnPosition={index}
                hit={cartTopper.menuProduct}
                store={store}
                listView={false}
                searchState={{ sortBy: MENU_PRODUCTS_BY_PRICE_ASC }}
                mlScoringSignals={cartTopper.mlScoringSignals}
              />
            </InViewTrackedComponent>
          )}
        />
      </Container>
    </ErrorBoundary>
  );
};
