import * as t from 'io-ts';

export const tStoreMaxOrderSetting = t.interface({
  id: t.number,
  store_id: t.number,
  reservation_mode: t.string,
  enabled: t.boolean,
  start_time: t.string,
  day_of_week: t.string,
  max_order_limit: t.number,
});

export type StoreMaxOrderSetting = t.TypeOf<typeof tStoreMaxOrderSetting>;
