import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const RightArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_3329_7959)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3954 4.28795C15.7887 3.90017 16.4218 3.90462 16.8096 4.29787L23.7121 11.2979C24.096 11.6872 24.096 12.3128 23.7121 12.7021L16.8096 19.7021C16.4218 20.0954 15.7887 20.0998 15.3954 19.7121C15.0022 19.3243 14.9977 18.6911 15.3855 18.2979L20.6095 13H1C0.447715 13 0 12.5523 0 12C0 11.4477 0.447715 11 1 11H20.6095L15.3855 5.70213C14.9977 5.30887 15.0022 4.67572 15.3954 4.28795Z"
        fill="#0E0E0E"
      />
    </g>
    <defs>
      <clipPath id="clip0_3329_7959">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const RightArrowIcon = ({ ...props }: DynamicIconProps) => {
  return <Icon icon={RightArrow} {...props} color={props.color} />;
};
