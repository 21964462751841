import { faker } from '@faker-js/faker';

import type {
  Reservation,
  ReservationStore,
  ReservationStoreOptions,
} from '@jane/shared/types';

import { workingHours } from './store';

const currentTimeUnix = faker.datatype.datetime().getTime();

const buildReservationStore = (
  options: Partial<ReservationStore> = {}
): ReservationStore => ({
  custom_navigation_color: null,
  custom_theme_color: null,
  delivery_hours: workingHours,
  description: null,
  disabled_feeling_and_activity_tags: [],
  full_address: '1347 Pacific Ave #201, Santa Cruz, CA 95060',
  id: faker.datatype.number(),
  kiosk_id: null,
  kiosk_photo: null,
  name: 'Jane Shop',
  pdp_setting: null,
  phone: faker.phone.number(),
  photo: null,
  pickup_hours: workingHours,
  prepay: faker.datatype.boolean(),
  recreational: true,
  store_options: faker.helpers.arrayElements<ReservationStoreOptions>([
    'cash',
    'credit_cards',
    'debit',
    'wheelchair_access',
  ]),
  time_zone_identifier: 'America/Los_Angeles',
  url_slug: 'jane-shop',
  working_hours: workingHours,
  ...options,
});

export const buildReservation = (
  options: Partial<Reservation> = {}
): Reservation => ({
  amount: faker.finance.amount(),
  checked_out_at_with_store_timezone: currentTimeUnix,
  created_at: currentTimeUnix,
  delivery_finished_at: currentTimeUnix,
  delivery_started_at: currentTimeUnix,
  dismissed_message: null,
  employee: { name: null, phone: null },
  id: faker.datatype.number(),
  products_count: faker.datatype.number(),
  rating: faker.datatype.number(),
  reservation_mode: 'kiosk',
  status: 'verification',
  store: buildReservationStore(),
  uuid: faker.datatype.uuid(),
  ...options,
});
