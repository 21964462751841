import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const StimulateMyMind = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.31874 6.30509C9.41039 3.23164 14.4229 3.23164 17.5146 6.30509C20.6062 9.37854 20.6062 14.3616 17.5146 17.435L16.4822 18.6457C14.0868 21.455 9.74658 21.455 7.35111 18.6457L6.31874 17.435C3.22709 14.3616 3.22709 9.37854 6.31874 6.30509Z"
      fill="#FFC220"
    />
    <path
      d="M3 7.5C3 8.32843 2.32843 9 1.5 9C0.671573 9 0 8.32843 0 7.5C0 6.67157 0.671573 6 1.5 6C2.32843 6 3 6.67157 3 7.5Z"
      fill="#FFC220"
    />
    <path
      d="M9 1.5C9 2.32843 8.32843 3 7.5 3C6.67157 3 6 2.32843 6 1.5C6 0.671573 6.67157 0 7.5 0C8.32843 0 9 0.671573 9 1.5Z"
      fill="#FFC220"
    />
    <path
      d="M18 1.5C18 2.32843 17.3284 3 16.5 3C15.6716 3 15 2.32843 15 1.5C15 0.671573 15.6716 0 16.5 0C17.3284 0 18 0.671573 18 1.5Z"
      fill="#FFC220"
    />
    <path
      d="M24 7.5C24 8.32843 23.3284 9 22.5 9C21.6716 9 21 8.32843 21 7.5C21 6.67157 21.6716 6 22.5 6C23.3284 6 24 6.67157 24 7.5Z"
      fill="#FFC220"
    />
    <path
      d="M3 15.5C3 16.3284 2.32843 17 1.5 17C0.671573 17 0 16.3284 0 15.5C0 14.6716 0.671573 14 1.5 14C2.32843 14 3 14.6716 3 15.5Z"
      fill="#FFC220"
    />
    <path
      d="M24 15.5C24 16.3284 23.3284 17 22.5 17C21.6716 17 21 16.3284 21 15.5C21 14.6716 21.6716 14 22.5 14C23.3284 14 24 14.6716 24 15.5Z"
      fill="#FFC220"
    />
    <path
      d="M9 20H15V22C15 23.1046 14.1046 24 13 24H11C9.89543 24 9 23.1046 9 22V20Z"
      fill="#666666"
    />
    <circle cx={14.25} cy={12} r={3} fill="white" />
  </svg>
);
export const StimulateMyMindIcon = ({ ...props }: StaticIconProps) => {
  return <Icon icon={StimulateMyMind} {...props} color={undefined} />;
};
