import { Button, Typography, styled } from '@jane/reefer';

import { spacing } from '../../style';

export const SUBMIT_ERROR_TEXT = 'Please check the red errors above';

export interface ShowLoginProps {
  setShowLogin?: (arg: boolean) => void;
}

export const SubmitErrorMessage = ({ children }) => (
  <Typography color="error" as="div" mt={8}>
    {children}
  </Typography>
);

export const CustomFieldError = styled.div(spacing({ mb: 12 }));

interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  label: string;
  loading?: boolean;
  testId?: string;
  type?: 'button' | 'submit';
}
export const PrimaryLoginButton = ({
  testId,
  disabled,
  loading,
  label,
  onClick,
}: ButtonProps) => {
  return (
    <Button
      data-testid={testId}
      disabled={disabled}
      loading={loading}
      onClick={onClick}
      css={{
        height: 40,
        ...spacing({ py: 0 }),
      }}
      label={label}
      type="submit"
    />
  );
};
