import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const OwnershipBipoc = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.4853 3.92904C18.1422 1.5859 14.3432 1.5859 12 3.92904L10.5858 5.34326L19.0711 13.8285L20.4853 12.4143C22.8285 10.0712 22.8285 6.27219 20.4853 3.92904Z"
      fill="#FF9900"
    />
    <path
      d="M20.4853 3.92904C18.1422 1.5859 14.3432 1.5859 12 3.92904L10.5858 5.34326L19.0711 13.8285L20.4853 12.4143C22.8285 10.0712 22.8285 6.27219 20.4853 3.92904Z"
      fill="black"
      fillOpacity={0.5}
    />
    <path
      d="M2.80759 15.9497C3.58864 16.7308 4.85497 16.7308 5.63602 15.9497L14.1213 7.46446L11.2929 4.63603L2.80759 13.1213C2.02654 13.9024 2.02654 15.1687 2.80759 15.9497Z"
      fill="#FF9900"
    />
    <path
      d="M2.80759 15.9497C3.58864 16.7308 4.85497 16.7308 5.63602 15.9497L14.1213 7.46446L11.2929 4.63603L2.80759 13.1213C2.02654 13.9024 2.02654 15.1687 2.80759 15.9497Z"
      fill="black"
      fillOpacity={0.5}
    />
    <path
      d="M8.46445 21.6066C9.2455 22.3876 10.5118 22.3876 11.2929 21.6066L19.7782 13.1213L16.9497 10.2929L8.46445 18.7782C7.6834 19.5592 7.6834 20.8256 8.46445 21.6066Z"
      fill="#FF9900"
    />
    <path
      d="M8.46445 21.6066C9.2455 22.3876 10.5118 22.3876 11.2929 21.6066L19.7782 13.1213L16.9497 10.2929L8.46445 18.7782C7.6834 19.5592 7.6834 20.8256 8.46445 21.6066Z"
      fill="black"
      fillOpacity={0.5}
    />
    <path
      d="M4.92893 19.4853C5.70997 20.2663 6.9763 20.2663 7.75735 19.4853L16.2426 11L13.4142 8.17155L4.92893 16.6568C4.14788 17.4379 4.14788 18.7042 4.92893 19.4853Z"
      fill="#FF9900"
    />
    <path
      d="M4.92893 19.4853C5.70997 20.2663 6.9763 20.2663 7.75735 19.4853L16.2426 11L13.4142 8.17155L4.92893 16.6568C4.14788 17.4379 4.14788 18.7042 4.92893 19.4853Z"
      fill="black"
      fillOpacity={0.5}
    />
    <path
      d="M3.51468 3.92892C5.85783 1.58577 9.65682 1.58577 12 3.92892L13.4142 5.34313L4.9289 13.8284L3.51468 12.4142C1.17154 10.0711 1.17154 6.27207 3.51468 3.92892Z"
      fill="#FF9900"
    />
    <path
      d="M3.51468 3.92892C5.85783 1.58577 9.65682 1.58577 12 3.92892L13.4142 5.34313L4.9289 13.8284L3.51468 12.4142C1.17154 10.0711 1.17154 6.27207 3.51468 3.92892Z"
      fill="black"
      fillOpacity={0.2}
    />
    <path
      d="M21.1924 15.9497C20.4114 16.7308 19.145 16.7308 18.364 15.9497L9.8787 7.46446L12.7071 4.63603L21.1924 13.1213C21.9735 13.9024 21.9735 15.1687 21.1924 15.9497Z"
      fill="#FF9900"
    />
    <path
      d="M21.1924 15.9497C20.4114 16.7308 19.145 16.7308 18.364 15.9497L9.8787 7.46446L12.7071 4.63603L21.1924 13.1213C21.9735 13.9024 21.9735 15.1687 21.1924 15.9497Z"
      fill="black"
      fillOpacity={0.2}
    />
    <path
      d="M15.5356 21.6066C14.7545 22.3876 13.4882 22.3876 12.7071 21.6066L4.22184 13.1213L7.05027 10.2929L15.5356 18.7782C16.3166 19.5592 16.3166 20.8256 15.5356 21.6066Z"
      fill="#FF9900"
    />
    <path
      d="M15.5356 21.6066C14.7545 22.3876 13.4882 22.3876 12.7071 21.6066L4.22184 13.1213L7.05027 10.2929L15.5356 18.7782C16.3166 19.5592 16.3166 20.8256 15.5356 21.6066Z"
      fill="black"
      fillOpacity={0.2}
    />
    <path
      d="M19.0711 19.4853C18.29 20.2663 17.0237 20.2663 16.2426 19.4853L7.75736 11L10.5858 8.17155L19.0711 16.6568C19.8521 17.4379 19.8521 18.7042 19.0711 19.4853Z"
      fill="#FF9900"
    />
    <path
      d="M19.0711 19.4853C18.29 20.2663 17.0237 20.2663 16.2426 19.4853L7.75736 11L10.5858 8.17155L19.0711 16.6568C19.8521 17.4379 19.8521 18.7042 19.0711 19.4853Z"
      fill="black"
      fillOpacity={0.2}
    />
    <path
      d="M7.75742 11.0001C8.53847 11.7811 9.8048 11.7811 10.5858 11.0001L14.8285 6.75745L12.0001 3.92903L7.75742 8.17167C6.97637 8.95271 6.97637 10.219 7.75742 11.0001Z"
      fill="#FF9900"
    />
    <path
      d="M7.75742 11.0001C8.53847 11.7811 9.8048 11.7811 10.5858 11.0001L14.8285 6.75745L12.0001 3.92903L7.75742 8.17167C6.97637 8.95271 6.97637 10.219 7.75742 11.0001Z"
      fill="black"
      fillOpacity={0.5}
    />
    <path
      d="M16.2427 8.17166C15.4617 7.39061 14.1953 7.39061 13.4143 8.17166L14.8285 6.75745L16.2427 8.17166Z"
      fill="#FF9900"
    />
    <path
      d="M16.2427 8.17166C15.4617 7.39061 14.1953 7.39061 13.4143 8.17166L14.8285 6.75745L16.2427 8.17166Z"
      fill="black"
      fillOpacity={0.5}
    />
  </svg>
);
export const OwnershipBipocIcon = ({ ...props }: StaticIconProps) => {
  return <Icon icon={OwnershipBipoc} {...props} color={undefined} />;
};
