import { useContext } from 'react';

import type { Spacing } from '../../../styling';
import type { ComponentColors } from '../../../types';
import { Box } from '../../box';
import { Flex } from '../../flex/flex';
import { ChevronLeftIcon, DismissIcon } from '../../icon';
import { Typography } from '../../typography/typography';
import { ModalContext } from '../modal';
import type { ModalHeaderProps } from '../modal.types';
import { StyledIconContainer, StyledModalHeader } from './modalHeader.styles';

export function ModalHeader({
  actions,
  branded = false,
  centerContent,
  children,
  onIconClick,
  nested,
  subtitle,
  testId,
  textAlign = 'left',
  title,
}: ModalHeaderProps) {
  const { onRequestClose, variant } = useContext(ModalContext);

  const iconProps = {
    color: 'inherit' as ComponentColors,
    mr: textAlign === 'left' ? (24 as Spacing) : undefined,
    onClick: onIconClick ? onIconClick : onRequestClose,
  };

  return (
    <StyledModalHeader
      variant={variant}
      data-id="modal-header"
      data-testid={testId}
    >
      <Flex width="100%" alignItems="center">
        <StyledIconContainer position="relative">
          {nested ? (
            <ChevronLeftIcon
              altText="Back to previous modal"
              testId="modal-header-back-icon"
              {...iconProps}
            />
          ) : (
            <DismissIcon
              altText="Close Modal"
              testId="modal-header-close-icon"
              {...iconProps}
            />
          )}
        </StyledIconContainer>
        <Flex flexDirection="column" alignSelf="center" width="100%" mr={24}>
          <Typography
            branded={branded}
            variant="header-bold"
            textAlign={textAlign}
          >
            {title}
          </Typography>
          {subtitle && (
            <Typography
              color="grays-mid"
              branded={branded}
              textAlign={textAlign}
            >
              {subtitle}
            </Typography>
          )}
        </Flex>
        {centerContent && textAlign !== 'center' && (
          <Box position="absolute" left={0} width="100%">
            <Flex
              alignSelf="center"
              justifyContent="center"
              pr={24}
              px={24}
              width="100%"
            >
              {centerContent}
            </Flex>
          </Box>
        )}
        {actions && (
          <Box position="absolute" right={24}>
            <Flex alignSelf="center" width="100%">
              {actions}
            </Flex>
          </Box>
        )}
      </Flex>
      {children && <Flex width="100%">{children}</Flex>}
    </StyledModalHeader>
  );
}
