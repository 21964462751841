import type { HTMLAttributes } from 'react';

import { Typography } from '@jane/reefer';

import { ellipsizeText } from '../../style/ellipsizeText';

export const Brand: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  color: _,
  ...props
}) => (
  <Typography
    color="grays-mid"
    css={ellipsizeText}
    variant="body"
    {...props}
    as="div"
  >
    {children}
  </Typography>
);
