import { ApiRequestError } from '../types';

export type ParsedApiRequestError = {
  fieldValidationErrors?: Array<{ message: string; name: string }>;
  formValidationErrors?: Array<{ message: string }>;
  message: string;
  serverError?: { status: number; statusText: string };
};

export const parseApiResponseError = async (
  error: unknown
): Promise<ParsedApiRequestError> => {
  if (error instanceof ApiRequestError) {
    let body;

    try {
      body = await error.response.json();
    } catch {
      /* we don't care if this fails */
    }

    if (body) {
      if (body.errors) {
        const { errors } = body;

        const fieldValidationErrors = Object.keys(errors).map((e) => ({
          name: e,
          message: `${errors[e]}`,
        }));

        return {
          message: `Field validation error(s) occurred.`,
          fieldValidationErrors,
        };
      } else {
        const errorFromBody = body.exception || body;

        return {
          message: `Form validation error(s) occurred.`,
          formValidationErrors: [
            {
              message: `${errorFromBody}`,
            },
          ],
        };
      }
    }

    return {
      message: `${error.response.status} ${error.response.statusText}`,
      serverError: {
        status: error.response.status,
        statusText: error.response.statusText,
      },
    };
  }

  return { message: `${error}` };
};
