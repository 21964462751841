import { Box, PartnerLogo, ReadMore, Typography, styled } from '@jane/reefer';
import type { Brand } from '@jane/shared/models';

const MobileBrandContainer = styled.div({
  position: 'relative',
  bottom: 75,
  zIndex: 1,
});

const PartnerLogoContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
});

const StyledBrandName = styled(Typography)<{ forceMobile?: boolean }>(
  ({ forceMobile }) => ({
    '&&': {
      fontSize: forceMobile ? 28 : undefined,
      textAlign: forceMobile ? 'center' : undefined,
    },
  })
);

const StyledDescription = styled(Typography)<{ forceMobile?: boolean }>(
  ({ forceMobile }) => ({
    '&&': {
      fontSize: forceMobile ? 15 : undefined,
      textAlign: forceMobile ? 'center' : undefined,
    },
  })
);

export const MobileBrandDetail = ({
  brand,
  forceMobile,
}: {
  brand: Brand;
  forceMobile?: boolean;
}) => {
  const { name, logo_url, description } = brand;

  return (
    <MobileBrandContainer data-testid="brand-detail-mobile">
      <Box mx={24} py={24} pb={40}>
        <PartnerLogoContainer>
          <PartnerLogo variant="brand" name={name} image={logo_url} />
        </PartnerLogoContainer>
        <StyledBrandName
          variant="title-bold"
          mt={16}
          textAlign="center"
          forceMobile={forceMobile}
        >
          {name}
        </StyledBrandName>
        {description && (
          <StyledDescription mt={8} as="div" forceMobile={forceMobile}>
            <ReadMore content={description} more="View more" less="View less" />
          </StyledDescription>
        )}
      </Box>
    </MobileBrandContainer>
  );
};
