import { Button, DismissIcon } from '@jane/reefer';

interface Props {
  dark?: boolean;
  onCloseModal: () => void;
}

const CloseButton = ({ dark = false, onCloseModal }: Props) => (
  <Button.Icon
    icon={<DismissIcon />}
    onClick={onCloseModal}
    data-testid="close-button"
    aria-label="close"
    ml="auto"
    mr={12}
    mt={12}
  />
);

export default CloseButton;
