import pluralise from 'pluralise';
import { useState } from 'react';

import { Box, Button, Flex, InfoIcon, Typography } from '@jane/reefer';
import type { CrmIntegration, CrmReward } from '@jane/shared/models';

import { initiateCrmAuth } from '../../../../../../lib/sources/crmIntegration';
import { ApplyableDiscount } from './applyableDiscount';
import {
  AutoRedemptionInfo,
  StoreRedeemableListItem,
} from './customerLoyaltyPoints.styles';
import { UnlockWalletForm } from './unlockWalletForm/';

export const CustomerLoyaltyPointsContent = ({
  cartUuid,
  crmIntegration: { signed_up, wallet_state, automatic_redemption },
  error,
  handleApplyDiscount,
  loyaltyPointsStacking = true,
  maxRedemptionCountExceeded,
  noRewards,
  onUnlockWalletSubmit,
  phone,
  redeemableInStore,
  redeemableOnline,
  reservationMode,
  storeLoyaltyPointsLabel,
  storeName,
  unlockLoading,
}: {
  cartUuid: string;
  crmIntegration: CrmIntegration;
  error?: string;
  handleApplyDiscount: (reward: CrmReward) => void;
  loyaltyPointsStacking: boolean;
  maxRedemptionCountExceeded: boolean;
  noRewards: boolean;
  onUnlockWalletSubmit: (code: string) => void;
  phone: string | null;
  redeemableInStore: CrmReward[];
  redeemableOnline: CrmReward[];
  reservationMode: string;
  storeLoyaltyPointsLabel?: string | null;
  storeName: string;
  unlockLoading: boolean;
}) => {
  const [acknowledgedStacking, setAcknowledgedStacking] = useState(false);

  if (error) {
    return (
      <Box ml={32}>
        <div>
          <Typography mt={8}>Unable to get your store reward points</Typography>
        </div>
      </Box>
    );
  }

  if (!signed_up) {
    return (
      <Box ml={32}>
        <Typography mt={8} variant="body">{`Ask about becoming a ${
          storeLoyaltyPointsLabel ? storeLoyaltyPointsLabel : 'loyalty points'
        } member at ${storeName.trim()}!`}</Typography>
      </Box>
    );
  }

  if (signed_up && wallet_state === 'locked') {
    return (
      <UnlockWalletForm
        onSubmit={onUnlockWalletSubmit}
        phone={phone}
        onSendCode={() => initiateCrmAuth(cartUuid)}
        unlockLoading={unlockLoading}
      />
    );
  }

  if (signed_up && noRewards) {
    return (
      <Box ml={32}>
        <Typography mt={8}>
          You do not qualify for{' '}
          {maxRedemptionCountExceeded ? 'additional' : 'any'} rewards at{' '}
          {storeName}.
        </Typography>
      </Box>
    );
  }

  if (signed_up && !loyaltyPointsStacking && !acknowledgedStacking) {
    return (
      <Box ml={32}>
        <Typography as="div" mb={8} variant="body">
          This store doesn’t allow loyalty points to be redeemed when there are
          existing discounts applied to your bag.
        </Typography>
        <Button
          onClick={() => setAcknowledgedStacking(true)}
          variant="tertiary"
          css={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}
          label="Use rewards instead"
        />
      </Box>
    );
  }

  return (
    <Box ml={32} mb={24}>
      {!automatic_redemption && (
        <AutoRedemptionInfo>
          <Box mr={4}>
            <InfoIcon mt={2} color="purple-dark" />
          </Box>
          <Box>
            Please present your reward account at the time of {reservationMode}
          </Box>
        </AutoRedemptionInfo>
      )}
      {redeemableOnline.length > 0 && (
        <div>
          <Typography mt={16} variant="body-bold">
            Redeemable online
          </Typography>
          <Typography mt={8}>
            <ul>
              {redeemableOnline.map((reward: CrmReward) => {
                return (
                  <ApplyableDiscount
                    key={reward.crm_reward_id}
                    applied={false}
                    enabled
                    onClick={() => handleApplyDiscount(reward)}
                    text={`${reward.reward_name} `}
                  >
                    <Typography>
                      {`Use ${reward.point_value}`}{' '}
                      {pluralise(reward.point_value, 'pt', 'pts')}
                    </Typography>
                  </ApplyableDiscount>
                );
              })}
            </ul>
          </Typography>
        </div>
      )}

      {redeemableInStore.length > 0 && (
        <Box mt={16}>
          <Typography variant="body-bold" mt={16}>
            Redeemable in-store
          </Typography>
          <Flex mt={8}>
            <ul>
              {redeemableInStore.map((reward: CrmReward) => (
                <StoreRedeemableListItem key={reward.crm_reward_id}>
                  <Typography variant="body">
                    {reward.reward_name} ({reward.point_value}{' '}
                    {pluralise(reward.point_value, 'pt', 'pts')})
                  </Typography>
                </StoreRedeemableListItem>
              ))}
            </ul>
          </Flex>
        </Box>
      )}
    </Box>
  );
};
