import type { JaneSearchState, ProductFilterKeys } from '@jane/search/types';

import { bucketFilterKeys, rangeFilterKeys } from './constants';

export const handleFilterDeselect = (
  filterKey: ProductFilterKeys | 'all',
  searchState: JaneSearchState<any>
) => {
  const { bucketFilters, filters, rangeFilters, ...restOfState } = searchState;

  let updatedFilters = filters;
  let updatedBucketFilters = bucketFilters;
  let updatedRangeFilters = rangeFilters;

  if (rangeFilterKeys.includes(filterKey)) {
    updatedRangeFilters = {
      ...(rangeFilters || {}),
      [filterKey]: {},
    };
  } else if (bucketFilterKeys.includes(filterKey)) {
    updatedBucketFilters = {
      ...(bucketFilters || {}),
      [filterKey]: [],
    };
  } else {
    updatedFilters = {
      ...(filters || {}),
      [filterKey]: [],
    };
  }

  if (filterKey === 'all') {
    updatedBucketFilters = undefined;
    updatedFilters = undefined;
    updatedRangeFilters = undefined;
  }

  return {
    ...restOfState,
    bucketFilters: updatedBucketFilters,
    filters: updatedFilters,
    rangeFilters: updatedRangeFilters,
  };
};
