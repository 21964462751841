import { useEffect, useRef, useState } from 'react';

import { Drawer, useMobileMediaQuery } from '@jane/reefer';
import { postMessageToIframeParent } from '@jane/shared-ecomm/util';

import { useCustomerDispatch, useCustomerSelector } from '../../../customer';
import { closeCart } from '../../../customer/redux/cart';
import { get } from '../../../redux-util/selectors';
import { useResizeIframe } from '../../../shared-hooks';
import CartAlerts from '../../cartAlerts';
import { CartDrawerContainer } from './cartDrawer';
import { CartReferenceContainer } from './cartDrawer/cartDrawerStates.styles';

export const EmbeddedCartDrawer = ({
  container,
}: {
  container?: string | HTMLElement;
}) => {
  const hasBeenOpened = useRef(false);

  const isMobile = useMobileMediaQuery({});
  const dispatch = useCustomerDispatch();
  const { appMode } = useCustomerSelector(get('embeddedApp'));

  const [showCartAlerts, setShowCartAlerts] = useState<boolean>(false);

  const { isCartOpen, isCartLoading } = useCustomerSelector(
    ({ cart: { cart, isLoading } }) => ({
      isCartOpen: cart.is_open,
      isCartLoading: isLoading,
    })
  );

  const janeDrawerRef = useRef<any>(null);

  const { registerResizer, unregisterResizer } = useResizeIframe(
    janeDrawerRef,
    { isModal: true, autoRegister: false }
  );

  useEffect(() => {
    if (isCartOpen) {
      if (!hasBeenOpened.current) {
        hasBeenOpened.current = true;
      }
      postMessageToIframeParent({
        messageType: 'saveScrollPosition',
      });
      // This allows the drawer to shrink the iframe to a smaller size than the contentHeightMap (show.js.erb)
      postMessageToIframeParent({
        messageType: 'enableShrinkEveryResize',
      });
      postMessageToIframeParent({
        messageType: 'scrollToTop',
      });
      registerResizer();
    }

    if (!isCartOpen && hasBeenOpened) {
      postMessageToIframeParent({
        messageType: 'disableShrinkEveryResize',
      });

      const app = document.getElementById('app');
      if (app) {
        postMessageToIframeParent({
          messageType: 'resizeIFrame',
          payload: app.scrollHeight,
        });
      }
      postMessageToIframeParent({
        messageType: 'restoreScrollPosition',
        payload: 'smooth',
      });
    }

    return () => unregisterResizer();
  }, [isCartOpen]);

  const handleClose = () => {
    dispatch(closeCart());
  };

  return (
    <Drawer
      background="grays-ultralight"
      container={container}
      fitScreen={false}
      onRequestClose={handleClose}
      open={isCartOpen}
      width={isMobile ? '95vw' : '540px'}
    >
      <CartReferenceContainer
        ref={janeDrawerRef}
        height={isCartLoading ? '100vh' : 'auto'}
      >
        <CartDrawerContainer
          showCartAlerts={setShowCartAlerts}
          appMode={appMode}
        />
        {showCartAlerts && appMode !== 'default' && (
          <CartAlerts
            onClose={() => setShowCartAlerts(false)}
            isCheckoutAlert
          />
        )}
      </CartReferenceContainer>
    </Drawer>
  );
};
