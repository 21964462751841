import { useEffect, useRef } from 'react';
import type { ReactNode } from 'react';

import { useIsInView } from '../../../hooks/useAd/useIsInView';

interface InViewTrackedComponentProps {
  children: ReactNode;
  delay?: number;
  onChildrenInView: (isInView: boolean) => void;
}
export const InViewTrackedComponent = ({
  onChildrenInView,
  children,
  delay,
}: InViewTrackedComponentProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const savedCallback = useRef<typeof onChildrenInView>(null);
  const isInView = useIsInView(ref);

  useEffect(() => {
    savedCallback.current = onChildrenInView;
  }, [onChildrenInView]);

  useEffect(() => {
    const timeoutId = window.setTimeout(
      () => savedCallback.current(isInView),
      delay
    );
    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [delay, isInView]);

  return <div ref={ref}>{children}</div>;
};
