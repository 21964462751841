import { fold } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/pipeable';

import { config } from '@jane/shared/config';
import type { Coordinates, Id } from '@jane/shared/models';
import { tDeliveryValidation } from '@jane/shared/models';

import { decodeType } from '../lib/loadable';
import { request } from '../lib/request';

export const DeliveryValidateSource = {
  get({
    storeId,
    zipcode,
    coordinates,
  }: {
    coordinates: Coordinates | undefined;
    storeId: Id;
    zipcode: string | undefined | null;
  }) {
    const url = `${config.apiPath}/delivery/validate`;

    const params = {
      store_id: storeId,
      user_zipcode: zipcode || '',
      user_latitude: coordinates?.lat || '',
      user_longitude: coordinates?.long || '',
    };

    return request(
      url,
      {
        method: 'GET',
      },
      params
    )
      .then((r) =>
        decodeType({
          data: r,
          type: tDeliveryValidation,
          source: { name: 'DeliveryValidation.get' },
        })
      )
      .then((r) =>
        pipe(
          r,
          fold(
            (error) => {
              throw error;
            },
            (response) => response
          )
        )
      );
  },
  getSource({
    storeId,
    zipcode,
    coordinates,
  }: {
    coordinates?: Coordinates | undefined;
    storeId: Id;
    zipcode?: string | undefined | null;
  }) {
    let url = `${config.apiPath}/delivery/validate`;

    const encodedStoreId = encodeURIComponent(storeId);
    const encodedUserZipcode = zipcode && encodeURIComponent(zipcode);
    const encodedUserLatitude =
      coordinates && encodeURIComponent(coordinates['lat']);
    const encodedUserLongitude =
      coordinates && encodeURIComponent(coordinates['long']);

    url = url.concat(
      `?store_id=${encodedStoreId}&user_zipcode=${encodedUserZipcode}&user_latitude=${encodedUserLatitude}&user_longitude=${encodedUserLongitude}`
    );

    return {
      url,
      type: tDeliveryValidation,
    };
  },
};
