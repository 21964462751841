import isNil from 'lodash/isNil';

import { Box, Button, Flex, spacing, styled } from '@jane/reefer';
import type { WeightSelectorWeight } from '@jane/shared-ecomm/types';
import type { PriceId } from '@jane/shared/models';
import { formatCurrency } from '@jane/shared/util';

import { ProductImage } from '../../../component-library/image';
import {
  canWeightBeAddedToCart,
  filterWeightsAboveMax,
} from '../../../lib/cartLimitPolicy';
import getMaxCartQuantity from '../../../lib/getMaxCartQuantity';
import productPhotoMetadata from '../../../lib/productPhotoMetadata';
import QuantitySelector from '../../quantitySelector';
import { WeightPriceItems } from '../../weightSelector/weightPriceItems';
import {
  DisplayMode,
  useProductCardContext,
} from '../providers/productCardProvider';
import { MenuProductCardBackButton } from './backButton';
import { MenuBasicProductInfo } from './menuProductInfo';

export const ProductPhotoCorner = styled.div(({ theme }) => ({
  position: 'absolute',
  left: 0,
  bottom: 0,
  borderTop: '120px solid transparent',
  borderLeft: `100px solid ${theme.colors.grays.ultralight}`,
  width: 0,
}));

const ProductPhoto = styled.div(
  {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 100,
    overflow: 'hidden',
    height: 100,
    img: {
      objectFit: 'cover',
    },
  },
  ({ theme }) => ({ borderRadius: theme.borderRadius.sm })
);

const OverflowGrid = styled.div<{ moreThanTwoWeights: boolean }>(
  ({ moreThanTwoWeights, theme }) => ({
    '> div': {
      ...spacing({ pb: 12 }),
      display: 'grid',
      justifyContent: moreThanTwoWeights ? 'left' : 'center',
      gridTemplateRows: '54px 54px',
      gridAutoFlow: moreThanTwoWeights ? 'column' : 'row',
      gridAutoColumns: 88,
      gridGap: 4,
      overflowX: 'scroll',
      scrollbarColor: `gray transparent`,
      '&::-webkit-scrollbar': {
        backgroundColor: `transparent`,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: `${theme.colors.grays.mid}`,
        borderRadius: 100,
      },
    },
  })
);

const WeightSelectorLabel = styled.label({
  border: 0,
  clip: 'rect(1px, 1px, 1px, 1px)',
  height: 1,
  margin: -1,
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  width: 1,
});

interface Props {
  decrementQuantity: () => void;
  discountedPrice?: number;
  height?: number | string;
  incrementQuantity: () => void;
  maxWeightOption?: number;
  multipleCountDisabled: boolean;
  onAddToCartPressed: () => void;
  onWeightSelected: (selectedWeight: PriceId) => void;
  originalPrice?: number;
  selectedQuantity: number;
  selectedWeight: PriceId;
  shoppingDisabled: boolean;
  soldByWeight: boolean;
  weights: WeightSelectorWeight[];
}

export const MenuProductCardEditView = ({
  decrementQuantity,
  discountedPrice,
  height,
  incrementQuantity,
  multipleCountDisabled,
  onAddToCartPressed,
  onWeightSelected,
  originalPrice,
  selectedQuantity,
  selectedWeight,
  shoppingDisabled,
  soldByWeight,
  weights,
  maxWeightOption,
}: Props) => {
  const { cartProduct, currentSpecial, menuProduct, setDisplayMode } =
    useProductCardContext();
  const price =
    currentSpecial && discountedPrice ? discountedPrice : originalPrice;

  const moreThanTwoWeights = weights.length > 2;

  const photoMetadata = productPhotoMetadata(menuProduct);

  const selectedWeightInCart = cartProduct?.find(
    (product) => product.price_id === selectedWeight
  );

  const buttonText = shoppingDisabled
    ? 'Shopping disabled'
    : cartProduct && selectedQuantity === 0
    ? 'Remove'
    : cartProduct && selectedWeightInCart
    ? 'Update'
    : soldByWeight && !selectedWeight
    ? 'Select a weight'
    : 'Confirm';

  const weightSelectionLabelId = 'weight-selection-' + menuProduct.id;

  const hasMaxWeightOption = !isNil(maxWeightOption);

  const filteredWeights = isNil(maxWeightOption)
    ? weights
    : filterWeightsAboveMax(weights, cartProduct, maxWeightOption);

  const weightAlreadyInCart = selectedWeight === selectedWeightInCart?.price_id;
  const weightAndOptionAlreadyInCart =
    weightAlreadyInCart && selectedQuantity === selectedWeightInCart?.count;

  let selectedWeightIsOverMaxWeight = false;

  let canAddMore = true;

  if (hasMaxWeightOption) {
    const weightStatus = canWeightBeAddedToCart(
      selectedWeight,
      cartProduct,
      selectedQuantity,
      maxWeightOption
    );

    selectedWeightIsOverMaxWeight = !weightStatus['canBeAddedToCart'];
    canAddMore = weightStatus['canAddMore'];
  }

  const minQuantity = cartProduct && selectedWeightInCart?.count > 0 ? 0 : 1;

  return (
    <Flex flexDirection="column" justifyContent="space-between" height={height}>
      <Box px={8} pt={8}>
        <MenuProductCardBackButton
          onClick={() => {
            setDisplayMode(DisplayMode.Product);
          }}
        />
        <Box pb={8} position="relative" css={{ zIndex: 1 }}>
          <MenuBasicProductInfo applyTruncateOffset fullDetails={false} />
        </Box>

        <ProductPhoto>
          <ProductPhotoCorner />
          <ProductImage src={photoMetadata.url} alt={menuProduct.name} />
        </ProductPhoto>
      </Box>
      <Box>
        {soldByWeight && (
          <OverflowGrid moreThanTwoWeights={moreThanTwoWeights}>
            <WeightSelectorLabel id={weightSelectionLabelId}>
              Select Package Weight
            </WeightSelectorLabel>
            <WeightPriceItems
              labelId={weightSelectionLabelId}
              onChange={onWeightSelected}
              selectedWeight={selectedWeight}
              weights={filteredWeights}
            />
          </OverflowGrid>
        )}
      </Box>
      <Flex justifyContent="center" px={8}>
        {!multipleCountDisabled && (
          <QuantitySelector
            maxQuantity={
              canAddMore
                ? getMaxCartQuantity(menuProduct, selectedWeight)
                : selectedQuantity
            }
            minQuantity={minQuantity}
            multipleCountDisabled={multipleCountDisabled}
            value={selectedQuantity ?? 1}
            incrementQuantity={incrementQuantity}
            decrementQuantity={decrementQuantity}
          />
        )}
      </Flex>
      <Flex px={4} mx={8} mb={16}>
        <Button
          full
          label={
            selectedQuantity > 0
              ? `${buttonText} ${formatCurrency(price)}`
              : buttonText
          }
          aria-label={buttonText.toLowerCase()}
          disabled={
            selectedWeightIsOverMaxWeight ||
            shoppingDisabled ||
            (soldByWeight && !selectedWeight) ||
            weightAndOptionAlreadyInCart
          }
          onClick={onAddToCartPressed}
        />
      </Flex>
    </Flex>
  );
};
