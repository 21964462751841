import debounce from 'lodash/debounce';
import {
  Children,
  isValidElement,
  useCallback,
  useEffect,
  useState,
} from 'react';

import {
  Box,
  Button,
  ChevronLeftIcon,
  ChevronRightIcon,
  Link,
  Skeleton,
} from '@jane/reefer';

import {
  CarouselCardGrid,
  CarouselCardScrollArea,
  CarouselControlsContainer,
  CarouselControlsContainerMobile,
  StyledSkeletonBone,
} from './cardCarousel.styles';
import type { CardCarouselProps } from './cardCarousel.types';
import { getCardCarouselDimensions } from './cardCarousel.utils';
import { CarouselCard } from './carouselCard/carouselCard';
import { CarouselHeader } from './carouselHeader/carouselHeader';

interface CarouselControlsSkeletonProps {
  showButton?: boolean;
}

function CarouselControlsSkeleton({
  showButton,
}: CarouselControlsSkeletonProps) {
  return (
    <>
      <CarouselControlsContainer alignItems="center">
        <Skeleton.Bone height="48px" width="48px" mr={16} />
        <Skeleton.Bone height="48px" width="48px" mr={16} />
        {showButton && <Skeleton.Bone height="48px" width="90px" />}
      </CarouselControlsContainer>
      {showButton && (
        <CarouselControlsContainerMobile>
          <Skeleton.Bone height="48px" width="70px" />
        </CarouselControlsContainerMobile>
      )}
    </>
  );
}

/**
 * A carousel component that displays a scrollable grid of cards.
 *
 * Scroll position of the carousel is controlled via left-right scroll on mobile, and via a pair of control buttons on desktop.
 * Cards can be displayed in on of 3 different modes. See usage docs for details.
 */
export function CardCarousel({
  buttonLabel = 'View all',
  buttonOnClick,
  buttonTo,
  cardGap: cardGapDesktop = 24,
  cardGapMobile = 16,
  cardHeight = 100,
  cardHeightMobile = cardHeight,
  cardWidth: cardWidthProp,
  cardWidthMobile,
  children,
  mode = 'fixed',
  name,
  isLoading = false,
  rowCountMobile = 1,
  ...props
}: CardCarouselProps) {
  const [scrollAreaNode, setScrollAreaNode] = useState<null | HTMLDivElement>(
    null
  );
  const scrollAreaRef = useCallback((scrollAreaNode: null | HTMLDivElement) => {
    setScrollAreaNode(scrollAreaNode);
  }, []);

  const [width, setWidth] = useState(0);
  const [scrollWidth, setScrollWidth] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const setScrollLeftDebounced = debounce(setScrollLeft, 100);

  // Watch scrollAreaNode resize and update state
  useEffect(() => {
    if (!scrollAreaNode) return;

    const measureScrollArea = () => {
      setScrollWidth(scrollAreaNode.scrollWidth);
      setWidth(scrollAreaNode.getBoundingClientRect().width);
    };
    measureScrollArea();

    const observer = new ResizeObserver(() => {
      measureScrollArea();
    });
    observer.observe(scrollAreaNode);

    return () => {
      observer.disconnect();
    };
  }, [scrollAreaNode]);

  // Calculate tablet / desktop `cardWidth` and `cardGap` based on `mode`
  const { cardGap, cardWidth } = getCardCarouselDimensions(
    mode,
    cardWidthProp,
    cardGapDesktop,
    width
  );

  // Trigger left or right scroll
  const scrollCarousel = (direction = 'right') => {
    if (!scrollAreaNode) {
      return;
    }
    const scrollModifier = direction === 'right' ? 1 : -1;
    scrollAreaNode.scrollBy({
      behavior: 'smooth',
      left: scrollModifier * width,
    });
  };

  return (
    <Box as="section" width="100%" {...props}>
      <CarouselHeader name={name} isLoading={isLoading}>
        {!isLoading && (
          <CarouselControlsContainer alignItems="center">
            <Button.Icon
              ariaLabel="Back"
              disabled={scrollLeft === 0}
              icon={<ChevronLeftIcon />}
              onClick={() => scrollCarousel('left')}
            />
            <Button.Icon
              ariaLabel="Forward"
              disabled={
                // Chrome rounding bug requires use of Math.floor / Math.ceil here
                Math.ceil(scrollLeft) + Math.ceil(width) >=
                Math.floor(scrollWidth)
              }
              icon={<ChevronRightIcon />}
              onClick={() => scrollCarousel('right')}
            />
            {(buttonTo || buttonOnClick) && (
              <Button
                label={buttonLabel}
                ml={12}
                onClick={buttonOnClick}
                to={buttonTo}
                variant="tertiary"
              />
            )}
          </CarouselControlsContainer>
        )}
        {!isLoading && (buttonTo || buttonOnClick) && (
          <CarouselControlsContainerMobile>
            <Link onClick={buttonOnClick} to={buttonTo}>
              {buttonLabel}
            </Link>
          </CarouselControlsContainerMobile>
        )}
        {isLoading && (
          <CarouselControlsSkeleton
            showButton={!!(buttonTo || buttonOnClick)}
          />
        )}
      </CarouselHeader>
      <CarouselCardScrollArea
        disableScroll={isLoading}
        onScroll={(event) =>
          setScrollLeftDebounced((event.target as HTMLElement).scrollLeft)
        }
        ref={scrollAreaRef}
      >
        {/* Boolean check fixes a Chrome bug, where the carousel loads scrolled to random card */}
        {/* The initial width measurement of the scrollArea + cardWidth / cardGap calculations must occur before cards are rendered */}
        {!!cardWidth && !!cardGap && (
          <CarouselCardGrid
            cardGap={cardGap}
            cardGapMobile={cardGapMobile}
            cardWidth={cardWidth}
            cardWidthMobile={cardWidthMobile}
            rowCountMobile={rowCountMobile}
          >
            {!isLoading &&
              Children.map(children, (child) => {
                if (isValidElement(child)) {
                  return {
                    ...child,
                    props: {
                      ...child.props,
                      width: cardWidth,
                      widthMobile: cardWidthMobile,
                    },
                  };
                }
                return child;
              })}
            {isLoading &&
              Array.from({ length: 20 }, (_, i) => (
                <CarouselCard width={cardWidth} widthMobile={cardWidthMobile}>
                  <StyledSkeletonBone
                    height={cardHeight}
                    heightMobile={cardHeightMobile}
                    key={i}
                  />
                </CarouselCard>
              ))}
          </CarouselCardGrid>
        )}
      </CarouselCardScrollArea>
    </Box>
  );
}
