import styled from '@emotion/styled';

import { mediaQueries, spacing } from '../../../../styling';
import { mobileInputStyling } from '../field.styles';
import type { StyledFieldProps } from '../field.types';

export const StyledInputWrapper = styled.div<StyledFieldProps>(
  ({
    theme: { borderRadius, colors },
    disableMobileInputStyling,
    radiusSize,
  }) => ({
    display: 'flex',
    height: '48px',
    outline: 'none',
    width: '100%',
    margin: 0,
    background: colors.grays.white,
    alignItems: 'center',
    border: `1px solid ${colors.grays.light}`,
    borderRadius: borderRadius[radiusSize],
    ...spacing({ py: 12, px: 16 }),
    '&:focus-within': {
      borderColor: colors.primary.main,
    },
    '&:disabled': {
      borderColor: colors.grays.light,
    },
    [mediaQueries.tablet('max')]: [
      !disableMobileInputStyling && {
        ...mobileInputStyling(),
        backgroundColor: 'transparent',
      },
    ],
  })
);

export const StyledInput = styled.input<{
  disableMobileInputStyling?: boolean;
}>(({ disableMobileInputStyling }) => ({
  height: '100%',
  outline: 'none',
  border: 'none',
  width: '100%',
  margin: 0,
  padding: 0,
  '&:read-only': {
    pointerEvents: 'none',
  },
  '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
  [mediaQueries.tablet('max')]: [
    !disableMobileInputStyling && {
      backgroundColor: 'transparent',
    },
  ],
}));
