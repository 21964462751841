import { z } from 'zod';

export enum MenuProductSortSuffix {
  Default = '',
  Price = 'by-price-',
  PriceDesc = 'by-price-desc-',
  Rating = 'by-rating-',
}

export const ProductCategoryEnum = z.enum([
  'indica',
  'sativa',
  'hybrid',
  'cbd',
  '',
]);
export type ProductCategory = z.infer<typeof ProductCategoryEnum>;

export const ProductCategoriesSchema = z.array(ProductCategoryEnum);

export const ProductKindEnum = z.enum([
  'gear',
  'pre-roll',
  'extract',
  'edible',
  'merch',
  'grow',
  'tincture',
  'flower',
  'topical',
  'vape',
]);
export type ProductKind = z.infer<typeof ProductKindEnum>;
export const ProductTypeEnum = z.enum([
  'indica',
  'sativa',
  'hybrid',
  'cbd',
  'vape',
  'extract',
  'pre-roll',
  'edible',
  'tincture',
  'topical',
  'gear',
  'grow',
  'merch',
  'flower',
]);
export type ProductType = z.infer<typeof ProductTypeEnum>;

export const ProductWeightEnum = z.enum([
  'half gram',
  'gram',
  'two gram',
  'eighth ounce',
  'quarter ounce',
  'half ounce',
  'ounce',
]);

export type ProductWeight = z.infer<typeof ProductWeightEnum>;

export const PriceIdEnum = z.enum([...ProductWeightEnum.options, 'each']);

export type PriceId = z.infer<typeof PriceIdEnum>;

const OptionalStringOrNumber = z.union([z.string(), z.number(), z.null()]);
const OptionalArrayOrString = z.union([z.string(), z.array(z.string())]);

export const AlgoliaProductSchema = z.object({
  activities: z.union([z.array(z.string()), z.null()]),
  aggregate_rating: z.number(),
  allow_multiple_flower_count: z.boolean(),
  available_weights: z.array(PriceIdEnum),
  best_seller_rank: z.optional(z.number().nullable()),
  brand: z.union([z.string(), z.null()]),
  brand_subtype: z.union([z.string(), z.null()]),
  bucket_price: z.number(),
  category: z.union([ProductCategoryEnum, z.null()]),
  description: z.string(),
  discounted_price_half_gram: z.union([z.string(), z.null()]),
  discounted_price_gram: z.union([z.string(), z.null()]),
  discounted_price_two_gram: z.union([z.string(), z.null()]),
  discounted_price_eighth_ounce: z.union([z.string(), z.null()]),
  discounted_price_quarter_ounce: z.union([z.string(), z.null()]),
  discounted_price_half_ounce: z.union([z.string(), z.null()]),
  discounted_price_ounce: z.union([z.string(), z.null()]),
  discounted_price_each: z.union([z.string(), z.null()]),
  feelings: z.array(z.string()),
  flavors: z.optional(z.array(z.string())),
  image_urls: z.array(z.string()),
  indexed_at: z.optional(z.string()),
  kind: ProductKindEnum,
  max_cart_quantity: z.number(),
  name: z.string(),
  objectID: z.string(),
  percent_cbd: OptionalStringOrNumber,
  percent_cbda: OptionalStringOrNumber,
  percent_tac: OptionalStringOrNumber,
  percent_thc: OptionalStringOrNumber,
  percent_thca: OptionalStringOrNumber,
  price_each: z.union([z.string(), z.null()]),
  price_eighth_ounce: z.union([z.string(), z.null()]),
  price_gram: z.union([z.string(), z.null()]),
  price_half_gram: z.union([z.string(), z.null()]),
  price_half_ounce: z.union([z.string(), z.null()]),
  price_ounce: z.union([z.string(), z.null()]),
  price_quarter_ounce: z.union([z.string(), z.null()]),
  price_two_gram: z.union([z.string(), z.null()]),
  product_id: z.coerce.number(),
  product_percent_cbd: OptionalStringOrNumber,
  product_percent_thc: OptionalStringOrNumber,
  review_count: z.number(),
  root_subtype: z.string().nullable(),
  root_types: z.array(OptionalArrayOrString),
  special_amount: z.string().nullable(),
  special_id: z.number().nullable(),
  special_title: z.string().nullable(),
  store_id: z.optional(z.coerce.number().nullable()),
  store_notes: z.string().nullable(),
  store_specific_product: z.optional(z.boolean()),
  type: ProductTypeEnum.nullable(),
  unique_slug: z.optional(z.string()),
});

export type AlgoliaProduct = z.infer<typeof AlgoliaProductSchema>;

export const AlgoliaProductsSchema = z.array(AlgoliaProductSchema);

export type AlgoliaProducts = z.infer<typeof AlgoliaProductsSchema>;

export const AlgoliaProductKeySchema = AlgoliaProductSchema.keyof();

export type AlgoliaProductKey = z.infer<typeof AlgoliaProductKeySchema>;
