import { config } from '@jane/shared/config';

import { request } from '../lib/request';

export class UsersSource {
  static urlRoot = `${config.apiPath}/users`;

  static update(user) {
    return request(this.urlRoot, {
      method: 'PUT',
      body: JSON.stringify(user),
    });
  }

  static cancelReservation(id) {
    return request(`${this.urlRoot}/reservations/${id}`, {
      method: 'DELETE',
    });
  }

  static createReview(reservationId, review) {
    return request(`${this.urlRoot}/reservations/${reservationId}/reviews`, {
      method: 'POST',
      body: JSON.stringify(review),
    });
  }

  static prepayReservation({ token, reservation }) {
    return request(`${this.urlRoot}/reservations/${reservation.id}/prepay`, {
      method: 'POST',
      body: JSON.stringify({ token }),
    });
  }
}
