export const updateSortSuffix = (value: string, weightFilters: string[]) => {
  let newSortSuffix = value;
  const sortedDesc = newSortSuffix.includes('desc');
  const sortedByPrice = newSortSuffix.includes('price');

  if (sortedByPrice) {
    if (weightFilters?.length) {
      const weight = (weightFilters[0] as string).replace(' ', '-');

      newSortSuffix = sortedDesc
        ? `by-price-${weight}-desc-`
        : `by-price-${weight}-`;
    } else {
      newSortSuffix = sortedDesc ? `by-price-desc-` : `by-price-`;
    }
  }

  return newSortSuffix;
};

export const updateSortSuffixOnChange = (
  sortSuffix: string,
  weightFilter: string,
  isAddingFilter: boolean
) => {
  let newSortSuffix = sortSuffix;
  const sortedDesc = newSortSuffix.includes('desc');
  const sortedByPrice = newSortSuffix.includes('price');

  if (sortedByPrice) {
    if (isAddingFilter) {
      const weight = weightFilter.replace(' ', '-');

      newSortSuffix = sortedDesc
        ? `by-price-${weight}-desc-`
        : `by-price-${weight}-`;
    } else {
      newSortSuffix = sortedDesc ? `by-price-desc-` : `by-price-`;
    }
  }

  return newSortSuffix;
};
