import { FilterBarButtons } from './filterBarButtons';
import { FilterBarPopover } from './filterPopover/filterBarPopover';
import type { FilterBarPopoverProps } from './filterPopover/filterBarPopover.types';
import { FilterBarTypeahead } from './filterPopover/filterBarTypeahead';

/** Renders filter bar components according to FilterItemType */

export const FilterBarGroup = ({
  filterGroup,
  onChange,
  onDeselect,
  totalResults,
  variant,
}: FilterBarPopoverProps) => {
  if (!filterGroup.showInFilterBar) return null;

  if (filterGroup.type === 'singleselect') {
    return (
      <FilterBarButtons
        key={filterGroup.key}
        filterGroup={filterGroup}
        onChange={onChange}
      />
    );
  }

  if (filterGroup.typeahead) {
    return (
      <FilterBarTypeahead
        key={filterGroup.key}
        filterGroup={filterGroup}
        onChange={onChange}
        onDeselect={onDeselect}
        totalResults={totalResults}
        variant={variant}
      />
    );
  }

  return (
    <FilterBarPopover
      key={filterGroup.key}
      filterGroup={filterGroup}
      onChange={onChange}
      onDeselect={onDeselect}
      totalResults={totalResults}
      variant={variant}
    />
  );
};
