import { useCustomerSelector } from '../../customer/selectors';
import { get } from '../../redux-util/selectors';
import type { IdentificationProps } from './idUploader';
import DeluxeIdentificationUploader from './idUploader';

const IDIdentification = ({
  showValidationErrors,
  valid,
  onIdUploadAttempt,
  onRemove,
}: IdentificationProps) => {
  const { document, loading_government_photo } = useCustomerSelector(
    get('identification')
  );

  if (!document) return null;

  return (
    <DeluxeIdentificationUploader
      imageKey="government_photo"
      inputName="government-id"
      label="Upload Government ID"
      document={document}
      isLoading={loading_government_photo}
      valid={valid}
      showValidationErrors={showValidationErrors}
      onIdUploadAttempt={onIdUploadAttempt}
      onRemove={onRemove}
    />
  );
};

export default IDIdentification;
