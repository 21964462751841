import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import React, { Component, useContext } from 'react';
import type { ReactNode } from 'react';
import {
  MediaQueryProvider as OuterMediaQueryProvider,
  withMedia,
} from 'react-media-query-hoc';

import { mediaQueries } from '../../style';

export interface MediaQueryState {
  desktop: boolean;
  fourteenForty: boolean;
  horizontalTablet: boolean;
  largerThanMobile: boolean;
  largerThanVerticalTablet: boolean;
  mobile: boolean;
  smallerThanDesktop: boolean;
  smallerThanVerticalTablet: boolean;
  verticalTablet: boolean;
}

const defaultState = () => ({
  fourteenForty: false,
  desktop: false,
  horizontalTablet: false,
  verticalTablet: false,
  largerThanMobile: false,
  largerThanVerticalTablet: false,
  smallerThanDesktop: false,
  smallerThanVerticalTablet: false,
  mobile: false,
});

export const MediaQueryContext = React.createContext<MediaQueryState>(
  defaultState()
);

interface Props {
  children?: ReactNode;
  media: any;
}

interface State {
  value?: MediaQueryState;
}

class MediaQueryProviderState extends Component<Props> {
  state: State = {
    value: defaultState(),
  };

  componentDidUpdate() {
    if (!isEqual(this.props.media, this.state.value)) {
      this.updateMedia(this.props.media);
    }
  }

  updateMedia = debounce((value) => this.setState({ value }));

  render() {
    return (
      <MediaQueryContext.Provider value={this.state.value as any}>
        {this.props.children}
      </MediaQueryContext.Provider>
    );
  }
}

const MediaQueryProviderContext = withMedia(MediaQueryProviderState);

export const MediaQueryProvider = ({ children }: { children: ReactNode }) => (
  <OuterMediaQueryProvider queries={mediaQueries}>
    <MediaQueryProviderContext>{children}</MediaQueryProviderContext>
  </OuterMediaQueryProvider>
);

export const MediaQueryValue = MediaQueryContext.Provider;
export const MediaQuery = MediaQueryContext.Consumer;

export const DesktopOnly = ({ children }: { children: any }) => (
  <MediaQuery>{(media) => (media.desktop ? children() : null)}</MediaQuery>
);

export const useMediaQuery = () => useContext(MediaQueryContext);
