import { useContext } from 'react';

import { ModalContext } from '../modal';
import type { ModalFooterProps } from '../modal.types';
import { StyledModalFooter } from './modalFooter.styles';

export function ModalFooter({ children, testId }: ModalFooterProps) {
  const { variant } = useContext(ModalContext);
  return (
    <StyledModalFooter
      data-id="modal-footer"
      data-testid={testId}
      variant={variant}
    >
      {children}
    </StyledModalFooter>
  );
}
