import { Flex, mediaQueries, spacing, styled } from '@jane/reefer';

import {
  DESKTOP_PADDING_HORIZONTAL,
  PADDING_VERTICAL,
} from '../pendingCartDrawer.styles';

export const DeliveryAddressWrapper = styled(Flex)(({ theme }) => ({
  backgroundColor: theme.colors.grays.white,

  ...spacing({ px: 0, py: 0 }),
  [mediaQueries.tablet('min')]: {
    borderBottom: `1px solid ${theme.colors.grays.light}`,
    ...spacing({ px: DESKTOP_PADDING_HORIZONTAL, py: PADDING_VERTICAL }),
  },
}));
