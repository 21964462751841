import algoliasearch from 'algoliasearch';

import { Box, Flex, Modal, styled } from '@jane/reefer';
import type { Location } from '@jane/shared-ecomm/providers';
import { useUserPreferences } from '@jane/shared-ecomm/providers';
import { EventNames, track } from '@jane/shared-ecomm/tracking';
import { LocationDetector } from '@jane/shared-ecomm/util';
import { config } from '@jane/shared/config';
import type { StoreHit } from '@jane/shared/models';

import { appModeSelector, useCustomerSelector } from '../../customer/selectors';
import useSafeState from '../../hooks/useSafeState';
import { STORE_DEFAULT } from '../../lib/algoliaIndices';
import { get } from '../../redux-util/selectors';
import { LocationSource } from '../../sources/location';
import UserLocationModalBackLink from './backLink';
import CloseButton from './closeButton';
import ConfirmLocation from './confirmLocation';
import ErrorQuestion from './errorQuestion';
import LocationQuestion from './locationQuestion';
import Manually from './manually';
import NotInArea from './notInArea';

export const MESSAGE_ID = 'location-message';

const METERS_PER_MILE = 1609.34;

function fetchProductsForLocation({
  location,
  setDetecting,
  setShowScreen,
  setShowModal,
  setUnservedLocation,
  setUserLocation,
  customMaxSearchRadius,
}: {
  customMaxSearchRadius?: string | number | null;
  location: Location;
  setDetecting: (detecting: boolean) => void;
  setShowModal: (show: boolean) => void;
  setShowScreen: (screen: LocationModalScreen) => void;
  setUnservedLocation: (location: Location) => void;
  setUserLocation: (location: Location) => void;
}) {
  track({ event: EventNames.SetLocation, ...location });

  if (!location.coordinates) {
    setShowScreen('error');
    return;
  }

  return algoliasearch(config.algoliaAppId, config.algoliaApiKey)
    .search<StoreHit>([
      {
        indexName: STORE_DEFAULT,
        query: '',
        params: {
          aroundLatLng: `${location.coordinates.lat}, ${location.coordinates.long}`,
          aroundRadius: Math.ceil(
            Number(customMaxSearchRadius || 35) * METERS_PER_MILE
          ),
          filters: 'marketplace_visible:true AND product_count > 0',
          hitsPerPage: 1,
        },
      },
    ])
    .then(
      (response) => {
        if (response.results[0].nbHits > 0) {
          setUserLocation({ ...location, hasResetLocation: false });
          setDetecting(false);
          setShowModal(false);
        } else {
          setUnservedLocation(location);
          setDetecting(false);
          setShowScreen('notInArea');
        }
      },
      () => setShowScreen('error')
    );
}

const Container = styled.div({ maxWidth: 327 });

interface Props {
  allowLocationReset: boolean;
  appPartner?: string;
  disallowCloseModal?: boolean;
  onCloseModal: () => void;
  onDismissModal?: () => void;
  onLocationReset?: () => void;
  setAskUserForLocation?: (show: boolean) => void;
  setShowModal: (show: boolean) => void;
  showModal: boolean;
}

export type LocationModalScreen =
  | 'question'
  | 'manualEntry'
  | 'notInArea'
  | 'error';

// eslint-disable-next-line max-statements
const UserLocationModal = ({
  allowLocationReset,
  appPartner = 'Jane',
  disallowCloseModal = false,
  onCloseModal,
  onLocationReset = () => {},
  setShowModal,
  setAskUserForLocation,
  showModal,
  onDismissModal,
}: Props) => {
  const { searchRadius } = useCustomerSelector(get('search'));
  const appMode = useCustomerSelector(appModeSelector);
  const [showScreen, setShowScreen] =
    useSafeState<LocationModalScreen>('question');
  const [detecting, setDetecting] = useSafeState<boolean>(false);
  const [unservedLocation, setUnservedLocation] = useSafeState<Location | null>(
    null
  );

  const { setUserLocation, userLocation } = useUserPreferences();

  const handleLocation = (location: Location) => {
    if (appMode === 'operatorEmbed') {
      setUserLocation({ ...location, hasResetLocation: false });
      setDetecting(false);
      setShowModal(false);
      setAskUserForLocation && setAskUserForLocation(false);
      return;
    }

    fetchProductsForLocation({
      location,
      setDetecting,
      setShowModal,
      setShowScreen,
      setUnservedLocation,
      setUserLocation,
      customMaxSearchRadius: searchRadius,
    });
  };

  const handleError = () => {
    setDetecting(false);
    setShowScreen('error');
  };

  const onEnterLocation = () => {
    setShowScreen('manualEntry');
  };

  const getLocationByIpAddress = () => {
    setDetecting(true);

    return LocationSource.get().then(({ lat, long }) => {
      LocationDetector.getInstance()
        .reverseGeocode(lat, long)
        .then(handleLocation, handleError);
    }, handleError);
  };

  const isMarketplace = appPartner === 'Jane';

  return (
    <Modal
      contentLabel="Choose your location"
      open={showModal}
      onRequestClose={disallowCloseModal ? () => {} : onCloseModal}
      overflow="visible"
      variant="dialogue"
    >
      <Container aria-describedby={MESSAGE_ID}>
        <>
          {userLocation.coordinates && appMode === 'operatorEmbed' ? (
            <ConfirmLocation
              setUserLocation={setUserLocation}
              setAskUserForLocation={setAskUserForLocation}
              userLocation={userLocation}
            />
          ) : (
            <>
              <Flex width="100%" justifyContent="space-between">
                {['manualEntry', 'notInArea'].includes(showScreen) && (
                  <UserLocationModalBackLink
                    onClick={() => setShowScreen('question')}
                  />
                )}
                {!disallowCloseModal && (
                  <CloseButton
                    dark={!isMarketplace}
                    onCloseModal={onDismissModal || onCloseModal}
                  />
                )}
              </Flex>
              <Box width="100%">
                {showScreen === 'question' && (
                  <LocationQuestion
                    allowLocationReset={allowLocationReset}
                    appPartner={appPartner}
                    detecting={detecting}
                    appMode={appMode}
                    onEnterLocation={onEnterLocation}
                    onLocationReset={onLocationReset}
                  />
                )}
                {showScreen === 'manualEntry' && (
                  <Manually
                    appPartner={appPartner}
                    detecting={detecting}
                    handleLocation={handleLocation}
                    setDetecting={setDetecting}
                    setShowScreen={setShowScreen}
                  />
                )}
                {showScreen === 'notInArea' && (
                  <NotInArea
                    appPartner={appPartner}
                    disallowCloseModal={disallowCloseModal}
                    isMarketplace={isMarketplace}
                    onCloseModal={onCloseModal}
                    setShowScreen={setShowScreen}
                    unservedLocation={unservedLocation}
                  />
                )}
                {showScreen === 'error' && (
                  <ErrorQuestion
                    appPartner={appPartner}
                    getLocationByIpAddress={getLocationByIpAddress}
                    setShowScreen={setShowScreen}
                  />
                )}
              </Box>
            </>
          )}
        </>
      </Container>
    </Modal>
  );
};

export default UserLocationModal;
