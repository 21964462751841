import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const External = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.5 3C2.5 2.44772 2.94772 2 3.5 2H8.5C9.05229 2 9.5 1.55228 9.5 1C9.5 0.447715 9.05229 0 8.5 0H3.5C1.84315 0 0.5 1.34315 0.5 3V17C0.5 18.6569 1.84315 20 3.5 20H17.5C19.1569 20 20.5 18.6569 20.5 17V12C20.5 11.4477 20.0523 11 19.5 11C18.9477 11 18.5 11.4477 18.5 12V17C18.5 17.5523 18.0523 18 17.5 18H3.5C2.94772 18 2.5 17.5523 2.5 17V3Z"
      fill="black"
    />
    <path
      d="M12.5 0C11.9477 0 11.5 0.447715 11.5 1C11.5 1.55228 11.9477 2 12.5 2H17.0858L9.79289 9.29289C9.40237 9.68342 9.40237 10.3166 9.79289 10.7071C10.1834 11.0976 10.8166 11.0976 11.2071 10.7071L18.5 3.41421V8C18.5 8.55228 18.9477 9 19.5 9C20.0523 9 20.5 8.55228 20.5 8V1C20.5 0.447715 20.0523 0 19.5 0H12.5Z"
      fill="black"
    />
  </svg>
);
export const ExternalIcon = ({ ...props }: DynamicIconProps) => {
  return <Icon icon={External} {...props} color={props.color} />;
};
