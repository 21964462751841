import type { ReactNode } from 'react';

import { Typography, styled, useTheme } from '@jane/reefer';

import { SvgIcon } from '../component-library/svg-icon';
import { border, flex, spacing } from '../style';

export const ENTICING_GRADIENT =
  'linear-gradient(90deg, #E02D52 0%, #C531CA 100%)';

const Bubble = ({ children }) => {
  const theme = useTheme();

  return (
    <Typography
      as="div"
      css={{
        ...spacing({ px: 16, py: 12 }),
        ...flex({
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }),
        backgroundImage: ENTICING_GRADIENT,
        borderRadius: 4,
        position: 'relative',
        color: theme.colors.grays.white,
        '&::before': {
          content: '""',
          position: 'absolute',
          width: 0,
          height: 0,
          top: -28,
          left: '48%',
          borderLeft: '9px solid transparent',
          borderRight: '9px solid transparent',
          borderTop: '20px solid transparent',
          borderBottom: '8px solid #d23093',
        },
      }}
      textAlign="center"
      variant="body"
      mt={16}
    >
      {children}
    </Typography>
  );
};

const CloseButton = styled.button(
  ({ theme }) => border.thin(theme.colors.grays.ultralight),
  ({ theme }) => ({
    position: 'absolute',
    width: 30,
    height: 30,
    borderRadius: 15,
    right: -8,
    top: -15,
    background: theme.colors.grays.white,
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.16)',
  })
);

interface Props {
  children: ReactNode;
  onClose?: () => void;
}

export const EnticementBubble = ({ children, onClose }: Props) => {
  const theme = useTheme();
  return (
    <Bubble>
      {onClose && (
        <CloseButton onClick={onClose}>
          <SvgIcon
            icon="times"
            size={15}
            color={theme.colors.grays.mid}
            nudge={{ down: 2 }}
          />
        </CloseButton>
      )}
      {children}
    </Bubble>
  );
};
