import { getAlgoliaClient } from '@jane/search/data-access';
import { config } from '@jane/shared/config';
import type { MenuProduct } from '@jane/shared/models';
import { deserializeCannabinoids } from '@jane/shared/util';

import { MENU_PRODUCTS_DEFAULT } from '../../../../lib/algoliaIndices';

export const DEFAULT_HITS_PER_PAGE = 16;

export type SortingMode = 'special-review' | 'weight-special-review';
export type ProductRelevantWeights = { [key: number]: number };

export function sortByMode(
  menuProducts: MenuProduct[],
  mode: SortingMode | undefined,
  productRelevantWeights?: ProductRelevantWeights
) {
  if (!mode) return menuProducts;

  if (mode === 'special-review') {
    return sortBySpecialReview(menuProducts);
  }

  if (mode === 'weight-special-review' && !!productRelevantWeights) {
    let sortedMenuProducts = [];
    const menuProductsByWeight = groupMpsByWeight(
      menuProducts,
      productRelevantWeights
    );
    const weightsDesc = Object.keys(menuProductsByWeight).reverse();

    weightsDesc.forEach((weight) => {
      sortedMenuProducts = [
        ...sortedMenuProducts,
        ...sortBySpecialReview(menuProductsByWeight[weight]),
      ];
    });

    return sortedMenuProducts;
  }

  return menuProducts;
}

function sortBySpecialReview(menuProducts: MenuProduct[]) {
  const hasSpecialMPs = menuProducts.filter((mp) => !!mp.special_id);
  const noSpecialMPs = menuProducts.filter((mp) => !mp.special_id);

  return [
    ...hasSpecialMPs,
    ...noSpecialMPs.sort(
      (a, b) => (b.review_count || 0) - (a.review_count || 0)
    ),
  ];
}

function groupMpsByWeight(
  menuProducts: MenuProduct[],
  productRelevantWeights: ProductRelevantWeights
) {
  return menuProducts.reduce<{ [key: number]: MenuProduct[] }>(
    (acc, menuProduct) => {
      const weight = productRelevantWeights[menuProduct.product_id];

      if (weight)
        acc[weight] = acc[weight]
          ? [...acc[weight], menuProduct]
          : [menuProduct];

      return acc;
    },
    {}
  );
}

export interface FetchMenuProductsProps {
  filters?: string;
  hitsPerPage?: number;
  productRelevantWeights?: ProductRelevantWeights;
  sortingMode?: SortingMode;
}

export async function fetchMenuProducts({
  sortingMode,
  productRelevantWeights,
  filters = '',
  hitsPerPage = DEFAULT_HITS_PER_PAGE,
}: FetchMenuProductsProps): Promise<MenuProduct[]> {
  try {
    const client = getAlgoliaClient(config);
    const index = client.initIndex(MENU_PRODUCTS_DEFAULT);

    const { hits } = await index.search('', {
      hitsPerPage,
      filters,
    });

    const deserializedMenuProducts = hits.map((hit) =>
      deserializeCannabinoids(hit, 'menuProduct')
    );

    return sortByMode(
      deserializedMenuProducts,
      sortingMode,
      productRelevantWeights
    );
  } catch (_err) {
    // do nothing
  }
}
