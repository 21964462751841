import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Preroll = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.8749 0.515754C22.6075 0.0325423 21.999 -0.142373 21.5158 0.125069C20.6824 0.586291 20.0175 1.33773 20.0003 2.34578C19.9838 3.3134 20.5763 4.14826 21.3793 4.78396C21.9452 5.23199 22.0014 5.53809 22 5.62004C21.9992 5.66159 21.9797 5.86827 21.5157 6.12509C21.0325 6.39255 20.8576 7.00109 21.1251 7.48429C21.3926 7.96749 22.0011 8.14237 22.4843 7.87491C23.3176 7.41364 23.9825 6.66216 23.9997 5.65413C24.0162 4.6865 23.4237 3.85162 22.6207 3.2159C22.0548 2.76785 21.9986 2.46176 22 2.37986C22.0007 2.33839 22.0202 2.13174 22.4842 1.87493C22.9675 1.60749 23.1424 0.998965 22.8749 0.515754Z"
      fill="black"
      fillOpacity={0.5}
      className="details"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3899 6.42447C19.0543 7.18041 19.8132 8.26506 20.5262 9.50002C21.2392 10.735 21.7991 11.9346 22.1216 12.8879C22.2801 13.3566 22.3988 13.8163 22.4314 14.2134C22.4475 14.4092 22.4482 14.6489 22.3867 14.8892C22.3247 15.1317 22.1689 15.4798 21.7942 15.6962C21.739 15.728 21.6822 15.755 21.6244 15.7772L5.14241 22.9985C4.66405 23.2428 4.18104 23.1374 3.88003 23.0173C3.55242 22.8865 3.25054 22.6695 2.99053 22.4389C2.46226 21.9705 1.92666 21.2846 1.47366 20.5C1.02066 19.7154 0.694526 18.9086 0.552977 18.2169C0.483308 17.8765 0.446252 17.5065 0.496816 17.1574C0.543271 16.8366 0.693577 16.3657 1.14426 16.0735L15.6391 5.41038C15.6872 5.37145 15.739 5.33573 15.7942 5.30387C16.1689 5.08752 16.5483 5.12665 16.7893 5.1942C17.0281 5.26111 17.2354 5.38152 17.3969 5.49334C17.7246 5.72016 18.0633 6.05281 18.3899 6.42447Z"
      fill="#FF5500"
    />
    <path
      d="M13.8301 9.9019C14.1062 10.3802 13.9423 10.9918 13.464 11.2679C12.9857 11.5441 12.3742 11.3802 12.098 10.9019C11.8219 10.4236 11.9857 9.81202 12.464 9.53587C12.9423 9.25973 13.5539 9.42361 13.8301 9.9019Z"
      fill="black"
      fillOpacity={0.5}
      className="details"
    />
    <path
      d="M17.9282 11C18.2043 11.4783 18.0405 12.0899 17.5622 12.366C17.0839 12.6422 16.4723 12.4783 16.1962 12C15.92 11.5217 16.0839 10.9101 16.5622 10.634C17.0405 10.3578 17.6521 10.5217 17.9282 11Z"
      fill="black"
      fillOpacity={0.5}
      className="details"
    />
    <path
      d="M16.464 16.4641C16.9423 16.188 17.1062 15.5764 16.8301 15.0981C16.5539 14.6198 15.9423 14.4559 15.464 14.732C14.9857 15.0082 14.8219 15.6198 15.098 16.0981C15.3742 16.5764 15.9857 16.7402 16.464 16.4641Z"
      fill="black"
      fillOpacity={0.5}
      className="details"
    />
    <path
      d="M12.732 14C13.0082 14.4782 12.8443 15.0898 12.366 15.366C11.8877 15.6421 11.2761 15.4782 11 15C10.7238 14.5217 10.8877 13.9101 11.366 13.6339C11.8443 13.3578 12.4559 13.5217 12.732 14Z"
      fill="black"
      fillOpacity={0.5}
      className="details"
    />
    <path
      d="M5.43779 19.366C5.91608 19.0899 6.07996 18.4783 5.80381 18C5.52767 17.5217 4.91608 17.3578 4.43779 17.6339C3.9595 17.9101 3.79562 18.5217 4.07176 19C4.34791 19.4783 4.9595 19.6421 5.43779 19.366Z"
      fill="black"
      fillOpacity={0.5}
      className="details"
    />
    <path
      d="M8.26793 14.2679C8.54407 14.7462 8.38019 15.3578 7.9019 15.634C7.42361 15.9101 6.81202 15.7462 6.53587 15.2679C6.25973 14.7896 6.42361 14.178 6.9019 13.9019C7.38019 13.6258 7.99178 13.7896 8.26793 14.2679Z"
      fill="black"
      fillOpacity={0.5}
      className="details"
    />
    <path
      d="M9.9019 19.0981C10.3802 18.8219 10.5441 18.2103 10.2679 17.732C9.99178 17.2537 9.38019 17.0899 8.9019 17.366C8.42361 17.6422 8.25973 18.2537 8.53587 18.732C8.81202 19.2103 9.42361 19.3742 9.9019 19.0981Z"
      fill="black"
      fillOpacity={0.5}
      className="details"
    />
  </svg>
);
export const PrerollIcon = ({ ...props }: DynamicIconProps) => {
  return <Icon icon={Preroll} {...props} color={props.color} />;
};
