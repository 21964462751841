import { useEffect, useRef, useState } from 'react';

export const useUpdateBoundingRect = (
  element: HTMLElement | null,
  detectionDelay = 800
) => {
  const [rect, setRect] = useState<DOMRect>();
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>(
    0 as unknown as ReturnType<typeof setTimeout>
  );
  const bodyRef = useRef<HTMLElement>(document.getElementsByTagName('body')[0]);

  useEffect(() => {
    if (!element || !bodyRef.current) return;

    const updateBoundingRect = () => {
      clearTimeout(timeoutRef.current);

      timeoutRef.current = setTimeout(
        () => setRect(element.getBoundingClientRect()),
        detectionDelay
      );
    };

    const bodyObserver = new ResizeObserver(updateBoundingRect);
    window.addEventListener('resize', updateBoundingRect);

    bodyObserver.observe(bodyRef.current);

    return () => {
      bodyObserver.disconnect();
      window.removeEventListener('resize', updateBoundingRect);
    };
  }, [element, bodyRef.current]);

  return rect;
};
