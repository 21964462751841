import type { AppMode } from '@jane/shared/models';

import { useFlagClient, useFlags } from '.';
import { FLAG_CONFIGS } from './flagConfigs';

const handleTrueFlag = (trueValue: any) => {
  if (typeof trueValue === 'function') {
    return trueValue();
  }
  return trueValue;
};

const handleFalseFlag = (falseValue: any) => {
  if (typeof falseValue === 'function') {
    return falseValue();
  }
  return falseValue;
};

interface TrackProps {
  appMode?: AppMode;
  customData?: {
    [key: string]: string | number | boolean;
  };
  eventName: string;
  value?: number;
}

export interface FlagHelper {
  active: boolean;
  evaluate: (appMode: AppMode, trueValue: any, falseValue?: any) => any;
  name: string;
  track: ({
    appMode,
    /** The kabob-cased event name used in Launch Darkly; parsed from flags.ts object */
    eventName,
    /** For custom events, pass an object with key/value pair */
    customData,
    /** For numeric-based events, pass a value */
    value,
  }: TrackProps) => void;
}

/**
 * Helper for BOOLEAN flags (i.e. flag value will either be true or false)
 */
export const useFlagHelper = (flagName: string) => {
  const flags = useFlags();
  const flagClient = useFlagClient();

  const flag = flags[flagName];
  const flagConfig = FLAG_CONFIGS[flagName];

  return {
    active: flag,
    name: flagName,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    evaluate: (
      /**
       * The current app mode, to check against the list of valid app modes
       * for the given feature flag (as set in the FLAG_CONFIGS).
       * If excluded, appMode will not be evaluated when determining what to return.
       */
      appMode: AppMode,

      /**
       * If the flag is true for the current user, this value will be returned.
       * If a function is provided, it will be called.
       */
      trueValue: any,

      /**
       * If the flag is false for the current user, this value will be returned.
       * If a function is provided, it will be called.
       * If no value is provided, will return "null"
       */
      falseValue: any = null
    ) => {
      if (appMode && flagConfig?.validAppModes?.length) {
        if (!flagConfig.validAppModes.includes(appMode)) {
          return handleFalseFlag(falseValue);
        }
      }

      if (flag) return handleTrueFlag(trueValue);

      return handleFalseFlag(falseValue);
    },
    track: ({ appMode, eventName, customData, value }: TrackProps) => {
      if (!appMode && flagConfig?.validAppModes?.length > 1) {
        return console.error(
          `event tracking for ${flagName} requires app mode prop`
        );
      }

      if (
        !flagConfig?.validAppModes?.length ||
        (appMode && flagConfig.validAppModes.includes(appMode))
      ) {
        flagClient?.track(eventName, customData, value);
      }
    },
  } as FlagHelper;
};
