export enum PaymentMethod {
  aeropay = 'aeropay',
  aeropayWhiteLabel = 'aeropay_white_label',
  canpay = 'canpay',
  cash = 'cash',
  credit = 'credit',
  debit = 'debit',
  hypur = 'hypur',
  moneris = 'moneris',
  payfirma = 'payfirma',
  paytender = 'paytender',
  square = 'square',
  stronghold = 'stronghold',
}
