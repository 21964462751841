import { styled, useTheme } from '@jane/reefer';
import { trackCartClick } from '@jane/shared-ecomm/tracking';

import { MediaQuery } from '../../common/providers/mediaQueryProvider';
import { SvgIcon } from '../../component-library/svg-icon';
import { useCustomerDispatch } from '../../customer';
import { openCart } from '../../customer/redux/cart';
import { isNoStore } from '../../customer/redux/store';
import {
  hasUserSelector,
  reservationsInProgressSelector,
  useCustomerSelector,
} from '../../customer/selectors';
import { calcNumberOfCartItems } from '../../lib/cart';
import highestPriorityCartDecider from '../../lib/highestPriorityCartDecider';
import { getReservationStatus } from '../../lib/reservation';
import { paths } from '../../lib/routes';
import { get } from '../../redux-util/selectors';
import { flex, media, mediaQueries } from '../../style';
import { Flex } from '../../style/layout';
import EmbeddedCart from '../header/embeddedCart';
import EmbeddedButton from './headerButton';

const AccountContainer = styled.div({
  lineHeight: 0,
  position: 'relative',
});

const AccountLink = ({ hasUser }: { hasUser: boolean }) => {
  const theme = useTheme();
  const label = hasUser ? 'Account' : 'Log in';
  return (
    <MediaQuery>
      {(media) =>
        media.largerThanMobile ? (
          <>{label}</>
        ) : (
          <SvgIcon
            ariaLabel={label}
            color={theme.colors.secondary.main}
            icon="userCircle"
            size={18}
            scale={1.2}
            dim
          />
        )
      }
    </MediaQuery>
  );
};
const DotContainer = styled.div(flex({ alignItems: 'center' }), {
  position: 'absolute',
  height: 15,
  left: -8,
  top: 10,
  [media(mediaQueries.largerThanMobile)]: {
    left: -11,
    top: 0,
  },
});

const WhiteLabelUserActions = () => {
  const theme = useTheme();
  const { disableAuthFeatures } = useCustomerSelector(get('embeddedApp'));
  const hasUser = useCustomerSelector((state) =>
    hasUserSelector(state.customer)
  );
  const cart = useCustomerSelector((state) => state.cart.cart);
  const reservations = useCustomerSelector((state) => state.users.reservations);
  const inProgressReservations = useCustomerSelector((state) =>
    reservationsInProgressSelector(state.users)
  );
  const hidePrices = useCustomerSelector(({ store: { store } }) =>
    isNoStore(store) ? true : store.hide_prices
  );

  const dispatch = useCustomerDispatch();

  if (hidePrices) return null;

  const { isReservation } = highestPriorityCartDecider(
    cart,
    inProgressReservations
  );

  const accountPath = () =>
    hasUser ? paths.embeddedProfile() : paths.embeddedLogin();

  const hasNonRatedCompletedReservations =
    reservations &&
    reservations.some((res) => getReservationStatus(res.status).isCompleted);

  const handleCartClick = () => {
    dispatch(openCart());
    trackCartClick();
  };

  return (
    <Flex>
      <EmbeddedButton
        inGroup={!disableAuthFeatures}
        onClick={handleCartClick}
        ariaLabel="cart"
      >
        <EmbeddedCart
          isReservation={isReservation && !disableAuthFeatures}
          productCount={(cart && calcNumberOfCartItems(cart)) || 0}
        />
      </EmbeddedButton>
      {disableAuthFeatures ? null : (
        <EmbeddedButton inGroup to={accountPath()}>
          <AccountContainer>
            <AccountLink hasUser={!!hasUser} />
            {hasNonRatedCompletedReservations && (
              <DotContainer>
                <SvgIcon
                  icon="dot"
                  color={theme.colors.secondary.main}
                  size={15}
                />
              </DotContainer>
            )}
          </AccountContainer>
        </EmbeddedButton>
      )}
    </Flex>
  );
};

export default WhiteLabelUserActions;
