import { mediaQueries, styled } from '@jane/reefer';

export const ProductsGrid = styled.div({
  display: 'grid',
  gridGap: '24px 24px',
  gridAutoRows: '1fr',
  [mediaQueries.mobile('md', 'min')]: {
    overflowX: 'auto',
    gridTemplateColumns: `repeat(auto-fill, minmax(150px, 1fr))`,
  },
  [mediaQueries.tablet('min')]: {
    gridTemplateColumns: `repeat(auto-fill, minmax(200px, 1fr))`,
  },
});

export const RowWrapper = styled.div({
  display: 'flex',
  gap: 24,
  width: '100%',
  overflowX: 'scroll',
  '::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
});
