import compact from 'lodash/compact';

type PluralizeParams = {
  noun: string;
  number: number;
};

export const pluralize = ({ number, noun }: PluralizeParams) => {
  const count = number >= 1000 ? '1,000+' : `${number}`;
  const finalNoun = number === 1 ? noun : `${noun}s`;

  return compact([count, finalNoun]).join(' ');
};
