import { useCallback } from 'react';

import {
  isEmbeddedModeSelector,
  useCustomerSelector,
} from '../customer/selectors';
import { HEADER_HEIGHT } from '../types/headerConstants';

export const useScrollToElement = (elementId: string) => {
  const isEmbeddedMode = useCustomerSelector(isEmbeddedModeSelector);

  const headerOffset = HEADER_HEIGHT;

  return useCallback(() => {
    const element = document.getElementById(elementId);
    const elementY = element?.getBoundingClientRect().top;
    const elementMarginString = element
      ? window.getComputedStyle(element).marginTop
      : '0';
    const elementMargin = parseInt(elementMarginString, 10);

    if (!elementY) return;

    if (isEmbeddedMode) {
      // We don't currently have a reliable way to calc distance to top of window,
      // so do not attempt to scroll on embed.
      return;
    } else {
      const scrollTop = document.documentElement.scrollTop;

      window.scrollTo({
        top: elementY - headerOffset - elementMargin + scrollTop,
        behavior: 'smooth',
      });
    }
  }, [elementId, isEmbeddedMode]);
};
