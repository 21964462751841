import { useQuery } from '@tanstack/react-query';

import {
  searchAlgoliaBrands,
  searchAlgoliaProducts,
} from '@jane/search/data-access';
import type {
  AlgoliaBrand,
  SearchAlgoliaBrandsParams,
  SearchAlgoliaLocalBrandsParams,
} from '@jane/search/types';
import { trackError } from '@jane/shared/util';

const METERS_PER_MILE = 1609.34;

export const brandOrder = (brandA: AlgoliaBrand, brandB: AlgoliaBrand) => {
  // rank is sorted in ascending order
  const brandAFirst =
    (brandA.rank && brandB.rank && brandA.rank < brandB.rank) ||
    (brandA.rank && !brandB.rank) ||
    (brandA.logo_url && !brandB.logo_url);

  const brandBFirst =
    (brandA.rank && brandB.rank && brandA.rank > brandB.rank) ||
    (!brandA.rank && brandB.rank) ||
    (!brandA.logo_url && brandB.logo_url);

  if (brandAFirst) {
    return -1;
  } else if (brandBFirst) {
    return 1;
  } else if (brandB.productCount && brandA.productCount) {
    return brandB.productCount - brandA.productCount;
  }
  return 0;
};

const getBrands = async (params: SearchAlgoliaBrandsParams) => {
  const brands = await searchAlgoliaBrands({
    ...params,
  });
  return brands;
};

export const useBrands = (params: SearchAlgoliaBrandsParams) => {
  return useQuery({
    queryFn: async () => await getBrands(params),
    queryKey: ['brands', params],
  });
};

const getLocalBrands = async ({
  coordinates,
  storeSpecificProduct,
  searchRadius = 35,
  ...params
}: SearchAlgoliaLocalBrandsParams) => {
  let results = null;
  try {
    const localProducts = await searchAlgoliaProducts({
      options: {
        aroundLatLng: coordinates
          ? `${coordinates.lat}, ${coordinates.long}`
          : undefined,
        aroundRadius: Math.ceil(searchRadius * METERS_PER_MILE),
        facets: ['brand'],
      },
      storeSpecificProduct,
    });
    const brandFacets = localProducts?.facets
      ? localProducts.facets['brand']
      : {};
    const names = brandFacets ? Object.keys(brandFacets) : [];

    const brands = await searchAlgoliaBrands({
      name: names,
      ...params,
    });

    // because algolia is case insensitive, we want to do an exact match here
    const namesSet = new Set(names);
    results = {
      ...brands,
      hits: brands.hits
        .filter((hit) => namesSet.has(hit.name))
        .map((hit) => ({ ...hit, productCount: brandFacets[hit.name] }))
        .sort(brandOrder),
    };
  } catch (error) {
    trackError('Error fetching home page brands', error);
  }
  return results;
};

export const useLocalBrands = (params: SearchAlgoliaLocalBrandsParams) => {
  return useQuery({
    queryFn: async () => await getLocalBrands(params),
    queryKey: ['localBrands', params],
  });
};
