import styled from '@emotion/styled';

import { spacing } from '../../styling';
import type { MarginProperties } from '../../styling';
import type { ComponentColors } from '../../types';
import {
  getColorValue,
  textColorForBackgroundColor,
} from '../../utils/componentStyles';
import { Flex } from '../flex';
import type { TagSizes } from './tag';

export interface StyledTagProps extends MarginProperties {
  background: ComponentColors;
  color?: ComponentColors;
  hasIcon?: boolean;
  size: TagSizes;
}
export const StyledTag = styled(Flex)<StyledTagProps>(
  ({
    background = 'grays-mid',
    theme,
    color,
    size,
    hasIcon,
    ...marginProps
  }) => ({
    background: getColorValue(background, theme),
    borderRadius: size === 'sm' ? theme.borderRadius.sm : theme.borderRadius.lg,
    color: color
      ? getColorValue(color, theme)
      : textColorForBackgroundColor(background, theme),
    height: 'fit-content',
    width: 'fit-content',
    ...spacing({
      pr: size === 'sm' ? 8 : 16,
      pl: ((size === 'sm' ? 8 : 16) / (hasIcon ? 2 : 1)) as 16 | 8 | 4,
      py: size === 'sm' ? 0 : 8,
      ...marginProps,
    }),
    // Fix bug where tag height is too small on mobile for small sized tag
    '> p': {
      lineHeight: '24px',
    },
  })
);
