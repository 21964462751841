import { Typography } from '@jane/reefer';
import type { MenuProduct, PriceId, Product } from '@jane/shared/models';

import { ellipsizeText } from '../../style/ellipsizeText';

interface Props {
  product: MenuProduct | Product;
  selectedWeight?: PriceId;
}

export const nonCannabisKinds = ['gear', 'merch'];

export const formatPotency = (potency?: number | string) => {
  if (typeof potency === 'string') {
    return potency;
  }
  return potency ? potency?.toFixed(2) : null;
};

export const primaryPotencies = (
  product: MenuProduct | Product,
  selectedWeight?: PriceId
) => {
  const { primaryPercentCBD, primaryPercentTHC } = product;
  const fallbackPercentTAC =
    'percentTAC' in product ? product.percentTAC : undefined;

  const inventoryPotencies =
    'inventory_potencies' in product && selectedWeight
      ? product.inventory_potencies
      : [];

  const inventoryPotency = (inventoryPotencies || []).find(
    (potency) => potency.price_id === selectedWeight
  );

  const percentTAC = inventoryPotency?.tac_potency || fallbackPercentTAC;
  const percentTHC = inventoryPotency?.thc_potency || primaryPercentTHC;
  const percentCBD = inventoryPotency?.cbd_potency || primaryPercentCBD;

  return { percentTAC, percentTHC, percentCBD };
};

export const getPackTotal = (product: MenuProduct | Product) => {
  const shouldAppendPackTotal =
    product.kind === 'pre-roll' &&
    product.root_subtype?.toLowerCase()?.includes('packs');
  if (!shouldAppendPackTotal) return;
  if (product.quantity_units && product.quantity_value > 0) {
    const standardizedUnits = product.quantity_units.replace('_', ' ');
    return `/ ${product.quantity_value}${standardizedUnits} per pack`;
  }
  if (product.net_weight_grams > 0)
    return `/ ${product.net_weight_grams}g per pack`;

  return '';
};

export const getLabResults = (
  product: MenuProduct | Product,
  selectedWeight?: PriceId
) => {
  const { percentTHCA, percentCBDA } = product;

  const { percentTAC, percentTHC, percentCBD } = primaryPotencies(
    product,
    selectedWeight
  );

  if (percentTHC || percentCBD || percentTHCA || percentCBDA || percentTAC) {
    return [
      percentTAC && `TAC ${formatPotency(percentTAC)}%`,
      percentTHC && `THC ${formatPotency(percentTHC)}%`,
      percentCBD && `CBD ${formatPotency(percentCBD)}%`,
      percentTHCA && `THCa ${formatPotency(percentTHCA)}%`,
      percentCBDA && `CBDa ${formatPotency(percentCBDA)}%`,
    ]
      .filter(Boolean)
      .join(' ');
  }
};

export const getDosageAmountPackInfo = (product: MenuProduct | Product) => {
  const { amount, dosage } = product;

  if (dosage || amount) {
    const packTotal = getPackTotal(product);

    return [dosage, amount, packTotal].filter(Boolean).join(' ');
  }
};

export const potencyInfo = (
  product: MenuProduct | Product,
  selectedWeight?: PriceId
) => {
  if (nonCannabisKinds.includes(product.kind)) {
    return null;
  }
  const labResults = getLabResults(product, selectedWeight);

  if (labResults) {
    return labResults;
  }

  const dosageAmountInfo = getDosageAmountPackInfo(product);

  if (dosageAmountInfo) {
    return dosageAmountInfo;
  }

  return null;
};

const ProductPotency = ({ product, selectedWeight }: Props) => (
  <Typography color="grays-mid" as="div" css={ellipsizeText} variant="body">
    {potencyInfo(product, selectedWeight)}
  </Typography>
);

export default ProductPotency;
