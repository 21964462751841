import type { SearchOptions } from '@algolia/client-search';
import type { RequestOptions } from '@algolia/transporter';

import type { AlgoliaReview } from '@jane/search/types';

import { search } from './client';

interface SearchProductReviewParams {
  searchOptions: SearchOptions & RequestOptions;
}

export const searchProductReviews = async ({
  searchOptions,
}: SearchProductReviewParams) =>
  search<AlgoliaReview>({
    indexPrefix: 'product-reviews-',
    options: searchOptions,
  });
