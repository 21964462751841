import { Children } from 'react';
import type { ReactElement } from 'react';

import { Typography } from '../typography';
import { StyledOrderedList } from './breadcrumbs.styles';
import type { BreadcrumbsProps } from './breadcrumbs.types';

const Seperator = () => (
  <Typography as="span" mx={8} color="grays-mid">
    &#47;
  </Typography>
);

/**
 * The breadcrumbs component renders a list of children with a seperator between them.
 */
export const Breadcrumbs = ({ children }: BreadcrumbsProps) => {
  const arrayOfChildren = Children.toArray(children).map((child, i) => (
    <li key={`list-item-${i}`}>{child}</li>
  ));

  const withSeperator = arrayOfChildren.reduce(
    (prev: ReactElement[], curr: ReactElement, i) => {
      if (i < arrayOfChildren.length - 1) {
        return [...prev, curr, <Seperator key={`seperator-${i}`} />];
      } else {
        return [...prev, curr];
      }
    },
    []
  );

  return <StyledOrderedList>{withSeperator}</StyledOrderedList>;
};
