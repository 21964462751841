import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useState } from 'react';

import type { Coordinates, MedRec } from '@jane/shared/models';
import { Storage } from '@jane/shared/util';

export type StoreTypes = MedRec | 'all';
export type StoreFulfillmentTypes = 'pickup' | 'delivery' | 'all';

export interface Location {
  cityState?: string;
  coordinates?: Coordinates;
  countryCode?: string;
  hasResetLocation: boolean;
  street?: string;
  zipcode?: string;
}

export interface Preferences {
  storeAvailability?: 'openNow';
  storeFulfillmentType?: StoreFulfillmentTypes;
  storeSearchRadius?: number;
  storeType?: StoreTypes;
}

const getInitialLocation = (): Location => ({
  cityState: Storage.get<string>('cityState'),
  coordinates: Storage.get<Coordinates>('coordinates'),
  countryCode: Storage.get<string>('countryCode'),
  hasResetLocation: false,
  street: Storage.get<string>('street'),
  zipcode: Storage.get<string>('zipcode'),
});

const getInitialPreferences = (): Preferences => ({
  storeAvailability: Storage.get<'openNow' | undefined>('storeAvailability'),
  storeFulfillmentType: Storage.get<StoreFulfillmentTypes>(
    'storeFulfillmentType'
  ),
  storeSearchRadius: Storage.get<number>('storeSearchRadius'),
  storeType: Storage.get<StoreTypes>('storeType'),
});

export interface UserPreferences {
  resetLocation: () => void;
  resetPreferences: () => void;
  setUserLocation: (location: Location) => void;
  setUserPreferences: (preferences: Preferences) => void;
  userLocation: Location;
  userPreferences?: Preferences;
}

export const UserPreferencesContext = createContext<UserPreferences>({
  resetLocation: () => null,
  resetPreferences: () => null,
  setUserLocation: () => null,
  setUserPreferences: () => null,
  userLocation: getInitialLocation(),
  userPreferences: getInitialPreferences(),
});

interface Props {
  children: ReactNode;
}

export const UserPreferencesProvider = ({ children }: Props) => {
  const [userLocation, setUserLocation] = useState(getInitialLocation());
  const [userPreferences, setUserPreferences] = useState(
    getInitialPreferences()
  );

  const handleSetPreferences = useCallback((preferences: Preferences) => {
    Object.keys(preferences).forEach((key) =>
      Storage.set(key, preferences[key as keyof Preferences])
    );
    setUserPreferences(preferences);
  }, []);

  const handleSetLocation = useCallback((location: Location) => {
    Object.keys(location).forEach((key) =>
      Storage.set(key, location[key as keyof Location])
    );
    setUserLocation(location);
  }, []);

  const handleResetLocation = useCallback(() => {
    const locationKeys = [
      'coordinates',
      'cityState',
      'countryCode',
      'street',
      'zipcode',
    ];
    locationKeys.forEach((key) => {
      Storage.remove(key);
    });

    setUserLocation(
      locationKeys.reduce((prev, curr) => ({ ...prev, [curr]: undefined }), {
        hasResetLocation: true,
      })
    );
  }, []);

  const handleResetPreferences = useCallback(() => {
    const preferencesKeys = [
      'storeAvailability',
      'storeFulfillmentType',
      'storeSearchRadius',
      'storeType',
    ];
    preferencesKeys.forEach((key) => {
      Storage.remove(key);
    });

    setUserPreferences(
      preferencesKeys.reduce(
        (prev, curr) => ({ ...prev, [curr]: undefined }),
        {}
      )
    );
  }, []);

  const value = {
    resetLocation: handleResetLocation,
    resetPreferences: handleResetPreferences,
    setUserLocation: handleSetLocation,
    setUserPreferences: handleSetPreferences,
    userLocation,
    userPreferences,
  };

  return (
    <UserPreferencesContext.Provider value={value}>
      {children}
    </UserPreferencesContext.Provider>
  );
};

export const useUserPreferences = () => {
  const context = useContext(UserPreferencesContext);

  if (context === undefined) {
    throw new Error(
      'useUserPreferences must be used within a UserPreferencesProvider'
    );
  }

  return context;
};
