import { Drawer as BaseDrawer } from './drawer';
import { useDrawerContext } from './drawer.context';
import type { DrawerProps } from './drawer.types';
import { DrawerContent } from './drawerContent/drawerContent';
import { DrawerContentDivider } from './drawerContent/drawerContentDivider';
import { DrawerFooter } from './drawerFooter/drawerFooter';
import { DrawerHeader } from './drawerHeader/drawerHeader';

export const Drawer = Object.assign(BaseDrawer, {
  Header: DrawerHeader,
  Content: DrawerContent,
  ContentDivider: DrawerContentDivider,
  Footer: DrawerFooter,
});

export { DrawerProps, useDrawerContext };
