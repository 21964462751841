import { useEffect, useState } from 'react';

import { Button, FiltersIcon, Modal, useMobileMediaQuery } from '@jane/reefer';
import { useHandleIframeModal } from '@jane/shared-ecomm/hooks';

import type { FilterModalProps } from './filterModal.types';
import { FilterModalFooter } from './filterModalFooter';
import { FilterModalSection } from './filterModalSection';
import { FilterModalTypeahead } from './filterModalTypeahead';
import { CONTENT_DIVIDER_PROPS } from './modalSection';
import { ModalTypeaheadToggle } from './modalSectionToggle';

export const FilterModal = <SearchState,>({
  activeFilterCount,
  filters,
  isPartnerMenu = false,
  onChange,
  onDeselect,
  open,
  searchState,
  setOpen,
  setSearchState,
  totalResults,
  variant,
}: FilterModalProps<SearchState>) => {
  const isMobile = useMobileMediaQuery({});
  const [currentTypeahead, setCurrentTypeahead] = useState<string | null>(null);
  const [initialSearchState, setInitialSearchState] = useState<
    SearchState | undefined
  >();
  const hasActiveFilters = activeFilterCount && activeFilterCount > 0;

  useEffect(() => {
    if (open) {
      setInitialSearchState(searchState);
    }
  }, [open]);

  const handleDiscardSelection = () => {
    initialSearchState && setSearchState(initialSearchState);
    setOpen(false);
  };

  const handleCurrentTypeahead = (key: string | null) => {
    setCurrentTypeahead(key);
  };

  const closeModal = () => {
    setOpen(false);
  };

  // resizes iframe when modal is open
  useHandleIframeModal(open);

  const renderedModalFilters = filters?.map((filterGroup) => {
    if (filterGroup.typeahead) {
      // show typeahead when selected
      if (currentTypeahead === filterGroup.label) {
        return (
          <FilterModalTypeahead
            key={filterGroup.key}
            closeTypeahead={() => handleCurrentTypeahead(null)}
            filterGroup={filterGroup}
            isPartnerMenu={isPartnerMenu}
            onChange={onChange}
            onDeselect={onDeselect}
          />
        );
      }

      return (
        // if no typeahead is selected, show the toggle
        currentTypeahead === null && (
          <ModalTypeaheadToggle
            isPartnerMenu={isPartnerMenu}
            key={filterGroup.key}
            label={filterGroup.label}
            onClick={() => handleCurrentTypeahead(filterGroup.label)}
          />
        )
      );
    }

    return (
      // render all other sections when no typeahead is selected
      currentTypeahead === null && (
        <FilterModalSection
          key={filterGroup.key}
          filterGroup={filterGroup}
          isPartnerMenu={isPartnerMenu}
          onChange={onChange}
          onDeselect={onDeselect}
        />
      )
    );
  });

  return (
    <div>
      <Button.Icon
        icon={<FiltersIcon />}
        onClick={() => setOpen(true)}
        variant={hasActiveFilters ? 'tertiary-selected' : 'tertiary'}
        ariaLabel="Click to open product filters"
        disabled={!filters.length}
      />
      <Modal
        open={open}
        onRequestClose={handleDiscardSelection}
        overlayClose
        variant={isMobile ? 'full-screen' : 'standard'}
        background="grays-white"
      >
        {/** if a typeahead is selected, hide main modal.header to show only the typeahead */}
        {currentTypeahead !== null ? (
          <>{renderedModalFilters}</>
        ) : (
          <>
            <Modal.Header
              branded={!isPartnerMenu}
              title="All filters"
              textAlign="center"
            />

            <Modal.Content padding={false}>
              {renderedModalFilters}
              <Modal.ContentDivider {...CONTENT_DIVIDER_PROPS} />
            </Modal.Content>
          </>
        )}
        <FilterModalFooter
          onDeselect={onDeselect}
          onClose={closeModal}
          totalResults={totalResults}
          variant={variant}
        />
      </Modal>
    </div>
  );
};
