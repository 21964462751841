import type { JaneSearchState } from '@jane/search/types';

import { bucketFilterKeys, rangeFilterKeys } from './constants';
import { updateSortSuffixOnChange } from './updateSortSuffix';

export const handleFilterChange = (
  filterKey: string,
  filterValue:
    | string
    | {
        max: number;
        min: number;
      },
  searchState: JaneSearchState<any>
) => {
  const { filters, rangeFilters, bucketFilters, sortSuffix, ...restOfState } =
    searchState;
  let updatedFilters = filters;
  let updatedBucketFilters = bucketFilters;
  let updatedRangeFilters = rangeFilters;
  let updatedSortSuffix = sortSuffix;

  if (rangeFilterKeys.includes(filterKey)) {
    if (!rangeFilters) {
      updatedRangeFilters = {
        [filterKey]: filterValue as {
          max: number;
          min: number;
        },
      };
    } else {
      const value = filterValue as {
        max: number;
        min: number;
      };

      if (filterKey in rangeFilters) {
        const currentRange = rangeFilters[filterKey];

        if (currentRange === filterValue) {
          updatedRangeFilters = {
            ...rangeFilters,
            [filterKey]: {},
          };
        } else {
          updatedRangeFilters = {
            ...rangeFilters,
            [filterKey]: value,
          };
        }
      }

      if (filterKey in rangeFilters === false) {
        updatedRangeFilters = {
          ...rangeFilters,
          [filterKey]: value,
        };
      }
    }
  } else if (bucketFilterKeys.includes(filterKey)) {
    const value = filterValue as string;

    if (!bucketFilters) {
      updatedBucketFilters = {
        [filterKey]: [value],
      };
    } else {
      if (filterKey in bucketFilters) {
        const currentFilters = bucketFilters[filterKey] as string[];

        updatedBucketFilters = {
          ...bucketFilters,
          [filterKey]: [value, ...currentFilters],
        };

        if (currentFilters.includes(value)) {
          currentFilters.splice(currentFilters.indexOf(value), 1);
          updatedBucketFilters = {
            ...bucketFilters,
            [filterKey]: [...currentFilters],
          };
        }
      }

      if (filterKey in bucketFilters === false) {
        updatedBucketFilters = {
          ...bucketFilters,
          [filterKey]: [value],
        };
      }
    }
  } else {
    const value = filterValue as string;

    if (!filters) {
      updatedFilters = {
        [filterKey]: [value],
      };
    } else {
      if (filterKey in filters) {
        const currentFilters = filters[filterKey] as string[];
        const isRemovingFilter = currentFilters.includes(value);

        updatedFilters = {
          ...filters,
          [filterKey]: [value, ...currentFilters],
        };

        if (isRemovingFilter) {
          currentFilters.splice(currentFilters.indexOf(value), 1);
          updatedFilters = {
            ...filters,
            [filterKey]: [...currentFilters],
          };
        }

        if (filterKey === 'available_weights') {
          updatedSortSuffix = updateSortSuffixOnChange(
            updatedSortSuffix || '',
            value,
            !isRemovingFilter
          );
        }
      }

      if (filterKey in filters === false) {
        updatedFilters = {
          ...filters,
          [filterKey]: [value],
        };

        if (filterKey === 'available_weights') {
          updatedSortSuffix = updateSortSuffixOnChange(
            updatedSortSuffix || '',
            value,
            true
          );
        }
      }
    }
  }

  return {
    ...restOfState,
    filters: updatedFilters,
    rangeFilters: updatedRangeFilters,
    bucketFilters: updatedBucketFilters,
    sortSuffix: updatedSortSuffix,
  };
};
