import { Flex, styled } from '@jane/reefer';

import { Accordion } from '../../../../component-library/accordion';

interface AppliedPromoCodeSpecialWrapperProps {
  enabled: boolean;
}
export const AppliedPromoCodeSpecialWrapper = styled(
  Flex
)<AppliedPromoCodeSpecialWrapperProps>(({ enabled }) => ({
  '&hover': {
    cursor: enabled ? 'pointer' : 'auto',
  },
}));

export const SpecialsWrapper = styled(Flex)(({ theme }) => ({
  borderBottom: `1px solid ${theme.colors.grays.light}`,
  backgroundColor: theme.colors.grays.white,
}));

export const GroupDiscountHeader = styled(Accordion.Heading)(({ theme }) => ({
  borderTop: `1px solid ${theme.colors.grays.light}`,
  padding: '24px',
}));
