import last from 'lodash/last';
import type { ReactNode } from 'react';

import type { ReeferTheme } from '@jane/reefer';
import { Card, Flex, Typography, styled, useTheme } from '@jane/reefer';
import {
  availableWeightsForMenuProduct,
  defaultWeightIdForMenuProduct,
  generateCustomLabel,
  getDiscountedUnitPrice,
  getUnitPrice,
} from '@jane/shared-ecomm/util';
import type {
  MenuProduct,
  Product,
  Store,
  StoreSpecial,
} from '@jane/shared/models';
import { formatCurrency } from '@jane/shared/util';

import { useMediaQuery } from '../../../common/providers/mediaQueryProvider';
import { useProductReviewsEnabled } from '../../../hooks/useProductReviewsEnabled';
import { titleize } from '../../../lib/titleCase';
import { ellipsizeText } from '../../../style/ellipsizeText';
import ProductPotency, {
  getDosageAmountPackInfo,
  getLabResults,
  getPackTotal,
} from '../../potency';
import { Brand } from '../../productCard/brand';
import { Category } from '../../productCard/category';
import { Kind } from '../../productCard/kind';
import { Name } from '../../productCard/name';
import SpecialBadge from '../../productCard/specialBadge';
import { StrikeThroughPrice } from '../../productCard/strikeThroughPrice';
import { REVIEWS_REQUIRED_BEFORE_DISPLAY } from '../../productReviews/common';
import FiveStarRating from '../../starRatings/fiveStarRating';
import { PRICE_LOOKUP } from '../../storeMenu/productCardHelper';
import { useProductCardContext } from '../providers/productCardProvider';

const PriceDistance: React.FC<{
  children?: ReactNode;
  theme?: ReeferTheme;
}> = ({ children, theme }) => (
  <Typography
    color={
      theme.colors.primary.main.toUpperCase() === theme.colors.brand.purple.dark
        ? 'purple'
        : 'text-main'
    }
    ml="auto"
    variant="body-bold"
  >
    {children}
  </Typography>
);

const TruncateWrapper = styled.div<{ truncateOffset: number | false }>(
  ({ truncateOffset }) => [
    {
      maxWidth: truncateOffset ? `calc(100% - ${truncateOffset}px)` : '100%',
    },
  ]
);

type ReviewStarsProps = {
  product: MenuProduct | Product;
  store: Store | undefined | null;
};
const ReviewStars = ({ product, store }: ReviewStarsProps) => {
  const productReviewsEnabled = useProductReviewsEnabled(store);
  if (
    productReviewsEnabled &&
    product.aggregate_rating &&
    product.review_count &&
    product.review_count >= REVIEWS_REQUIRED_BEFORE_DISPLAY
  ) {
    return (
      <Flex alignItems="center" data-testid="product-rating">
        <FiveStarRating rating={product.aggregate_rating} />
        <Typography color="grays-mid" variant="body">
          {product.review_count}
        </Typography>
      </Flex>
    );
  }
  return null;
};

const FlexEllipsize = styled.div(ellipsizeText, {
  display: 'flex',
  alignItems: 'center',
});

export const MenuBasicProductInfo = ({
  fullDetails = true,
  applyTruncateOffset = false,
  specialApplies,
}: {
  applyTruncateOffset?: boolean;
  fullDetails?: boolean;
  specialApplies?: boolean;
}) => {
  const { appMode, menuProduct: product, store } = useProductCardContext();
  const { mobile } = useMediaQuery();
  const discountAmount = (product as MenuProduct).special_amount;

  const productKind = generateCustomLabel({
    appMode,
    store,
    attribute: product.brand_subtype || product.kind,
    fallback: product.brand_subtype || titleize(product.kind),
  });

  const availableWeights = availableWeightsForMenuProduct(
    product as MenuProduct
  ).map((price) => PRICE_LOOKUP[price.toUpperCase()]);

  const availablePrices =
    availableWeights.length === 0 || product.amount
      ? 'EACH'
      : formatWeights(availableWeights);

  const brandText = (
    <Brand title={product.brand || ''}>
      {product.brand ? product.brand : ''}
    </Brand>
  );

  const productLineage = generateCustomLabel({
    appMode,
    store,
    attribute: product.category,
    isLineageLabel: true,
    fallback: titleize(product.category),
  });

  // NOTE(elliot): Display pre roll pack info if product has lab results. (potencyInfo pioritizes lab results over other info)
  const displayPackInfo = Boolean(
    getLabResults(product) &&
      getDosageAmountPackInfo(product) &&
      getPackTotal(product)
  );

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <Category
          css={{ minHeight: mobile ? 16 : 24 }}
          title={product.category || ''}
          category={product.category}
        >
          {productLineage}
        </Category>
        {specialApplies && discountAmount && (
          <Flex ml={8}>
            <SpecialBadge
              amount={discountAmount}
              specialAmount={discountAmount}
            />
          </Flex>
        )}
      </Flex>

      <TruncateWrapper truncateOffset={applyTruncateOffset && 40}>
        <Name title={product.name}>{product.name}</Name>
      </TruncateWrapper>

      <Typography color="grays-mid" variant="body" as="div">
        {product.brand ? (
          <TruncateWrapper truncateOffset={applyTruncateOffset && 15}>
            {brandText}
          </TruncateWrapper>
        ) : (
          brandText
        )}
      </Typography>

      {fullDetails && (
        <>
          <ReviewStars product={product} store={store} />
          <FlexEllipsize>
            <Kind title={productKind}>{productKind}</Kind>
            <Typography color="grays-mid" ml={4} variant="body">
              (
              {displayPackInfo
                ? getDosageAmountPackInfo(product)
                : availablePrices.toUpperCase()}
              )
            </Typography>
          </FlexEllipsize>
        </>
      )}
    </>
  );
};

const formatWeights = (availableWeights: string[]) => {
  switch (availableWeights.length) {
    case 1:
      return availableWeights[0];
    case 2:
      return `${availableWeights[0]}, ${availableWeights[1]}`;
    default:
      return `${availableWeights[0]} - ${last(availableWeights)}`;
  }
};

interface Props {
  currentSpecial?: StoreSpecial;
  productIsInCart?: boolean;
  sortedByPrice: boolean;
}

const MenuProductInfo = ({ currentSpecial, sortedByPrice }: Props) => {
  const { appliedWeightFilter, menuProduct: product } = useProductCardContext();
  const defaultWeight = defaultWeightIdForMenuProduct({
    menuProduct: product as MenuProduct,
    special: currentSpecial,
    appliedWeightFilter,
    sortedByPrice,
  });

  const theme = useTheme();

  const originalPrice = getUnitPrice(product as MenuProduct, defaultWeight);
  const discountedUnitPrice = getDiscountedUnitPrice(
    product as MenuProduct,
    defaultWeight
  );
  const unitPrice =
    currentSpecial && discountedUnitPrice ? discountedUnitPrice : originalPrice;

  const modifiedTheme =
    theme.colors.primary.main.toUpperCase() === theme.colors.brand.purple.dark
      ? { ...theme, themeColor: theme.colors.brand.purple.main }
      : theme;

  const defaultPriceId = product.amount
    ? ''
    : PRICE_LOOKUP[defaultWeight?.toUpperCase()];

  return (
    <Card.Content ariaLabel="menu product content">
      <Flex flexDirection="column" height="100%">
        <MenuBasicProductInfo />

        <ProductPotency product={product} />

        <Flex mt="auto">
          {currentSpecial && originalPrice && originalPrice !== unitPrice && (
            <StrikeThroughPrice>
              {formatCurrency(originalPrice)}
            </StrikeThroughPrice>
          )}
          {unitPrice !== undefined && (
            <PriceDistance theme={modifiedTheme}>
              {formatCurrency(unitPrice)}
              {defaultPriceId && (
                <Typography as="span" variant="body-bold">
                  /{defaultPriceId}
                </Typography>
              )}
            </PriceDistance>
          )}
        </Flex>
      </Flex>
    </Card.Content>
  );
};

export default MenuProductInfo;
