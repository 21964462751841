import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const CheckSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_3329_8032)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.711 3.30163C16.0994 3.69431 16.0959 4.32746 15.7032 4.71582L6.60319 13.7158C6.21358 14.1012 5.58642 14.1012 5.19681 13.7158L0.296811 8.86967C-0.0958652 8.48131 -0.0993633 7.84815 0.288997 7.45548C0.677358 7.0628 1.31051 7.05931 1.70319 7.44767L5.9 11.5984L14.2968 3.29382C14.6895 2.90546 15.3226 2.90896 15.711 3.30163Z"
        fill="#0E0E0E"
      />
    </g>
    <defs>
      <clipPath id="clip0_3329_8032">
        <rect
          width={16}
          height={16}
          fill="white"
          transform="translate(0 0.00482178)"
        />
      </clipPath>
    </defs>
  </svg>
);
export const CheckSmallIcon = ({ ...props }: DynamicIconProps) => {
  return <Icon icon={CheckSmall} {...props} color={props.color} />;
};
