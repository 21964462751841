import * as t from 'io-ts';

export const tAeropayBankAccount = t.interface({
  bank_account_id: t.string,
  account_last_4: t.string,
  name: t.string,
  is_selected: t.boolean,
});

export const tAeropayWhiteLabelUser = t.interface({
  user_id: t.string,
  bank_accounts: t.array(tAeropayBankAccount),
});

export type AeropayBankAccount = t.TypeOf<typeof tAeropayBankAccount>;
export type AeropayWhiteLabelUser = t.TypeOf<typeof tAeropayWhiteLabelUser>;
