import { useEffect, useState } from 'react';

import { styled } from '@jane/reefer';

import { useCustomerDispatch } from '../../customer/dispatch';
import { updateBirthDate } from '../../customer/redux/identification';
import { useCustomerSelector } from '../../customer/selectors';
import { ageAtTimezone } from '../../lib/qualifiedGroup';
import { get } from '../../redux-util/selectors';
import { border } from '../../style';
import { Flex } from '../../style/layout';
import { DateInput } from '../dateInputField';
import { InstructionsText } from '../instructionsText';
import type { IdentificationProps } from './idUploader';
import { ValidationIcon } from './idUploader';
import { WarningMessage } from './warningMessage';

const BirthDateOuter = styled.div(({ theme }) => ({
  '& input': [
    border.thin(theme.colors.grays.light),
    {
      width: 120,
      height: 40,
    },
  ],
}));

const validBirthdateRegex = /^\d{4}-\d{2}-\d{2}$/;

interface Props extends IdentificationProps {
  requiredAge: number;
  timezone: string;
}
const PediatricIdentification = ({ requiredAge, timezone }: Props) => {
  const idStoreState = useCustomerSelector(get('identification'));
  const dispatch = useCustomerDispatch();
  const { document } = idStoreState;
  const [ageAtStore, setAgeAtStore] = useState<number | undefined>();

  useEffect(() => {
    const { birth_date } = document;
    const ageAtStore = ageAtTimezone(birth_date, timezone);
    setAgeAtStore(ageAtStore);
  }, [document.birth_date]);

  if (!document) return null;
  const meetsRequiredBirthDate = ageAtStore && ageAtStore <= requiredAge;
  const maybeUpdateBirthDate = (value = '') => {
    if (
      !value ||
      !validBirthdateRegex.test(value) ||
      value === document.birth_date
    ) {
      return;
    }

    dispatch(updateBirthDate(value));
  };

  return (
    <Flex alignItems="center">
      <Flex column>
        <BirthDateOuter>
          <InstructionsText>Enter Birthdate:</InstructionsText>
          <DateInput
            date={document.birth_date || ''}
            onChange={maybeUpdateBirthDate}
            invalid={!meetsRequiredBirthDate}
          />
          {!meetsRequiredBirthDate && (
            <WarningMessage>
              You do not meet the age requirement for this discount.
            </WarningMessage>
          )}
        </BirthDateOuter>
      </Flex>
      {meetsRequiredBirthDate ? <ValidationIcon /> : null}
    </Flex>
  );
};

export default PediatricIdentification;
