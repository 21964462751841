import { Tag } from '@jane/reefer';

import { useCustomerSelector } from '../../customer';
import { isNoStore } from '../../customer/redux/store';
import { get } from '../../redux-util/selectors';

const SpecialBadge = ({
  amount,
  isShorten,
  specialAmount,
}: {
  amount: string;
  complianceLabel?: string;
  isShorten?: boolean;
  specialAmount?: string;
}) => {
  const { store } = useCustomerSelector(get('store'));

  const shouldDisplayComplianceLanguage =
    specialAmount?.toUpperCase() === 'SALE';
  const complianceLanguage =
    !isNoStore(store) &&
    store?.store_compliance_language_settings?.specials_badge;

  const label =
    shouldDisplayComplianceLanguage && complianceLanguage
      ? complianceLanguage
      : amount;

  const renderLabel = (label: string) => {
    return isShorten && label.length > 7
      ? `${label.substring(0, 7)}...`
      : label;
  };

  return (
    <Tag label={renderLabel(label)} color="grays-white" background="info" />
  );
};

export default SpecialBadge;
