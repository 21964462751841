import pluralise from 'pluralise';

import { Box, Flex, Link, RewardsIcon, Typography } from '@jane/reefer';
import type { CrmIntegration, CrmRedemption } from '@jane/shared/models';

import { Accordion } from '../../../../../component-library/accordion';
import { ApplyableDiscount } from './applyableDiscount';
import { DiscountAccordionHeadingContainer } from './customerLoyaltyPoints.styles';

export const CustomerLoyaltyPointsAccordionHeader = ({
  appliedRedemptions,
  availablePoints,
  cartDrawer = false,
  crmIntegration: { wallet_state, jane_redeemable_rewards },
  handleRemoveCrmRedemption,
  loading,
  storeRewardLabel,
}: {
  appliedRedemptions: readonly CrmRedemption[];
  availablePoints: number;
  cartDrawer?: boolean;
  crmIntegration: CrmIntegration;
  handleRemoveCrmRedemption: (id: string) => void;
  loading: boolean;
  storeRewardLabel?: string | null;
}) => {
  const headingTitle = `Use store ${
    storeRewardLabel ? storeRewardLabel : 'rewards'
  }`;

  const Header = () => (
    <DiscountAccordionHeadingContainer cartDrawer={cartDrawer}>
      <Flex alignItems="center">
        <RewardsIcon />
        <Typography color="grays-black" ml={12} variant="body-bold">
          {headingTitle}
        </Typography>
      </Flex>
      <Accordion.Indicator size={12} />
    </DiscountAccordionHeadingContainer>
  );

  if (loading) {
    return <Header />;
  }

  return (
    <>
      <Header />
      <Box ml={32}>
        {wallet_state === 'unlocked' && (
          <Typography as="div" mb={8} variant="body">
            {availablePoints} {pluralise(availablePoints, 'pt', 'pts')}
          </Typography>
        )}
        {wallet_state === 'locked' && (
          <Box pb={16}>
            <Typography>Authenticate your account to unlock rewards</Typography>
          </Box>
        )}
        {appliedRedemptions.length > 0 && jane_redeemable_rewards.length > 0 && (
          <Box mt={16}>
            <Typography mt={16} variant="body-bold">
              Applied rewards
            </Typography>

            <Box>
              <ul>
                {appliedRedemptions.map((redemption) => (
                  <ApplyableDiscount
                    key={redemption.id}
                    applied
                    enabled
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveCrmRedemption(redemption.id.toString());
                    }}
                    text={`${redemption.reward_name} (${
                      redemption.point_value
                    } ${pluralise(redemption.point_value, 'pt', 'pts')}) `}
                  >
                    <Link>Remove</Link>
                  </ApplyableDiscount>
                ))}
              </ul>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};
