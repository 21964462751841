import type { ReactNode } from 'react';

import AccordionContent from './accordion-content';
import AccordionHeading from './accordion-heading';
import AccordionIndicator from './accordion-indicator';
import AccordionItem from './accordion-item';
import AccordionSummary from './accordion-summary';

type Props = {
  children: ReactNode;
  id?: string;
};

const Accordion = ({ children, id }: Props) => (
  <div id={id} data-testid={id}>
    {children}
  </div>
);

/**
 *  Note: when using long duration animation in iframe, it can lead to laggy UI and low framerate
 *  due to the iframe getting resize as the content changes. This can be reduced by decreasing
 *  transition duration
 */

Accordion.Content = AccordionContent;
Accordion.Heading = AccordionHeading;
Accordion.Indicator = AccordionIndicator;
Accordion.Item = AccordionItem;
Accordion.Summary = AccordionSummary;

export default Accordion;
