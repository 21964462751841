import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Merch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 22 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1427 15.7364L15.4985 7.95251C15.6579 7.42571 16.2131 7.12678 16.7406 7.28367L21.0368 8.56126C21.568 8.71922 21.8695 9.27903 21.7089 9.80945L19.356 17.5841C18.6932 19.7739 17.0593 21.5446 14.9178 22.3937L11.4488 23.7692C9.78568 24.4286 7.89811 23.6268 7.23284 21.9781C6.56758 20.3295 7.37653 18.4584 9.0397 17.799L12.5087 16.4235C12.8146 16.3022 13.048 16.0492 13.1427 15.7364Z"
      fill="#4BA279"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.14271 8.73642L8.49848 0.952512C8.65791 0.425711 9.21308 0.126781 9.74064 0.283667L14.0368 1.56126C14.568 1.71922 14.8695 2.27903 14.7089 2.80945L12.356 10.5841C11.6932 12.7739 10.0593 14.5446 7.91784 15.3937L4.44885 16.7692C2.78568 17.4286 0.898109 16.6268 0.232842 14.9781C-0.432425 13.3295 0.376533 11.4584 2.0397 10.799L5.50869 9.42351C5.81461 9.30221 6.04803 9.04925 6.14271 8.73642Z"
      fill="#4BA279"
    />
    <path
      d="M15.5486 7.82057C15.529 7.86274 15.5122 7.90677 15.4984 7.95252L14.9598 9.732L21.1685 11.5946L21.7088 9.80945C21.7221 9.76568 21.7322 9.72171 21.7392 9.67777L15.5486 7.82057Z"
      fill="black"
      fillOpacity={0.5}
      className="details"
    />
    <path
      d="M20.9155 12.4307L14.7068 10.568L14.1274 12.4823L20.3362 14.3449L20.9155 12.4307Z"
      fill="black"
      fillOpacity={0.5}
      className="details"
    />
    <path
      d="M8.56493 0.787201C8.53837 0.839345 8.51603 0.894552 8.49849 0.952518L7.97055 2.69695L14.1793 4.55957L14.7089 2.80945C14.7259 2.75349 14.7377 2.69721 14.7446 2.6411L8.56493 0.787201Z"
      fill="black"
      fillOpacity={0.5}
      className="details"
    />
    <path
      d="M13.9263 5.3956L7.71753 3.53298L7.13818 5.44724L13.3469 7.30986L13.9263 5.3956Z"
      fill="black"
      fillOpacity={0.5}
      className="details"
    />
  </svg>
);
export const MerchIcon = ({ ...props }: DynamicIconProps) => {
  return <Icon icon={Merch} {...props} color={props.color} />;
};
