import { Typography } from '../../typography/typography';
import { StyledRouterTab, StyledTab } from './tab.styles';

export interface TabProps {
  /** Aria label */
  ariaLabel?: string;

  /** Tabs component stretches across the whole container (controlled by parent) */
  full?: boolean;

  /** Tab label and name */
  label: string;

  /** Tab `onClick` handler (controlled by parent) */
  onClick?(): void;

  /** Whether tab is selected (controlled by parent) */
  selected?: boolean;

  /** Id used for testing */
  testId?: string;

  /** An internal route: if passed, it renders a `<Link>` from `react-router-dom` */
  to?: string;

  /** Value that is returned to the onChange handler when clicked */
  value?: string | number;

  /** Width of each tab (controlled by parent) */
  width?: number | string;
}

/**
 * Each individual tab within the `Tabs` component is defined using the `Tab.Tab` component.
 */

export function Tab({
  ariaLabel,
  full,
  label,
  onClick,
  selected = false,
  testId,
  to,
  value,
  width,
  ...props
}: TabProps) {
  const sharedProps = {
    'aria-label': ariaLabel,
    'data-testid': testId,
    full,
    onClick,
    selected,
    width,
    ...props,
  };

  if (to) {
    return (
      <StyledRouterTab to={to} {...sharedProps}>
        <Typography variant={selected ? 'body-bold' : 'body'} as="span">
          {label}
        </Typography>
      </StyledRouterTab>
    );
  }

  return (
    <StyledTab value={value} type="button" {...sharedProps}>
      <Typography variant={selected ? 'body-bold' : 'body'} as="span">
        {label}
      </Typography>
    </StyledTab>
  );
}
