import { mediaQueries, shadows, spacing, styled } from '@jane/reefer';

export const StyledFilterList = styled.ul(
  ({ query, subList }: { query?: boolean; subList?: boolean }) => ({
    maxHeight: '360px',
    overflow: 'auto',
    position: 'relative',
    '::-webkit-scrollbar': {
      display: 'none',
    },
    [mediaQueries.tablet('min')]: {
      maxHeight: '420px',
    },
    // ensures hover effect is seamless on all list items
    '> li:first-of-type > div > div > div': {
      // less padding top for sublists/typeahead items
      ...spacing({ pt: subList || query ? 12 : 24 }),
    },
    '> li:first-of-type > button': {
      ...spacing({ pt: 20 }),
    },
    '> li:last-of-type > button': {
      ...spacing({ pb: 20 }),
    },
  })
);

export const FilterListItem = styled.li(({ theme }) => ({
  listStyle: 'none',
  transition: 'all 250ms',
  mark: {
    color: theme.colors.primary.main,
    backgroundColor: 'transparent',
  },
}));

export const FilterListButton = styled.button({
  outline: 'none',
  borderWidth: 0,
  textDecoration: 'none',
  backgroundColor: 'transparent',
  width: '100%',
  height: '100%',
  textAlign: 'left',
  ...spacing({ py: 12, px: 24, m: 0 }),
  display: 'block',
  color: 'inherit',
  '&:hover, &:focus': {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },

  cursor: 'pointer',
});

export const FilterListButtonLabels = styled.span({
  display: 'flex',
  justifyContent: 'space-between',
  pointerEvents: 'none',
});

export const FilterListCheckbox = styled.div(
  ({ subItem }: { subItem?: boolean }) => ({
    position: 'relative',
    '> div > div': {
      ...spacing({ py: 12, m: 0, pr: 24, pl: subItem ? 40 : 24 }),
      '&:hover, &:focus': {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
      },
    },
    label: {
      width: '100%',
      cursor: 'pointer',
      color: 'inherit',
    },
    cursor: 'pointer',
  })
);

export const MobileFilterWrapper = styled.div({
  '> li': {
    boxShadow: shadows.dividerTop,
  },
  boxShadow: shadows.divider,
});

export const FilterListCheckboxArrow = styled.span({
  position: 'absolute',
  bottom: '12px',
  right: '20px',
});

export const StyledButtonWrapper = styled.div(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  background: theme.colors.grays.white,
}));

interface FilterButtonGridProps {
  itemsPerRow: number;
  mobileItemsPerRow: number;
}

export const FilterButtonGrid = styled.div<FilterButtonGridProps>(
  {
    display: 'grid',
    gridGap: 16,
    gridAutoRows: '1fr',
    width: '100%',
  },
  ({ itemsPerRow, mobileItemsPerRow }) => ({
    gridTemplateColumns: `repeat(1, minmax(0, 1fr))`,
    [mediaQueries.mobile('md', 'min')]: {
      gridTemplateColumns: `repeat(${mobileItemsPerRow}, minmax(0, 1fr))`,
    },
    [mediaQueries.tablet('min')]: {
      gridTemplateColumns: `repeat(${itemsPerRow}, minmax(0, 1fr))`,
    },
  })
);
