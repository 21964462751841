import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const GetIntimate = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 5.19349C11.0759 4.01119 9.2884 2.36945 6.47771 2.36945C2.86629 2.36945 0 5.53248 0 8.66001C0 11.7885 1.60957 14.238 4.82972 17.0565C8.03875 19.8665 9.14078 20.1495 11.9858 21.6306C14.8602 20.1495 15.9633 19.8665 19.1713 17.0565C22.3914 14.238 24 11.7885 24 8.66001C24 5.53248 21.1347 2.36945 17.5223 2.36945C14.7126 2.36945 12.9241 4.01119 12 5.19349Z"
      fill="#FF5500"
    />
  </svg>
);
export const GetIntimateIcon = ({ ...props }: StaticIconProps) => {
  return <Icon icon={GetIntimate} {...props} color={undefined} />;
};
