import { Skeleton as BaseSkeleton } from './skeleton';
import type { SkeletonProps } from './skeleton';
import type { SkeletonBoneProps } from './skeletonBone/skeletonBone';
import { SkeletonBone } from './skeletonBone/skeletonBone';

export const Skeleton = Object.assign(BaseSkeleton, {
  Bone: SkeletonBone,
});

export { SkeletonProps, SkeletonBoneProps };
