import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Dismiss = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.7097 5.69208C20.0968 5.30499 20.0968 4.6774 19.7097 4.29031C19.3226 3.90323 18.695 3.90323 18.3079 4.29031L12 10.5982L5.69208 4.29031C5.30499 3.90323 4.6774 3.90323 4.29031 4.29031C3.90323 4.6774 3.90323 5.30499 4.29031 5.69208L10.5982 12L4.29031 18.3079C3.90323 18.695 3.90323 19.3226 4.29031 19.7097C4.6774 20.0968 5.30499 20.0968 5.69208 19.7097L12 13.4018L18.3079 19.7097C18.695 20.0968 19.3226 20.0968 19.7097 19.7097C20.0968 19.3226 20.0968 18.695 19.7097 18.3079L13.4018 12L19.7097 5.69208Z"
      fill="#0E0E0E"
    />
  </svg>
);
export const DismissIcon = ({ ...props }: DynamicIconProps) => {
  return <Icon icon={Dismiss} {...props} color={props.color} />;
};
