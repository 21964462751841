import type { LDContext } from 'launchdarkly-js-client-sdk';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

const initialContext: LDContext = {
  anonymous: true,
  /** Provide a temporary user ID so LaunchDarkly doesn't assign
   * its own anonymous ID every time we initialize the client. */
  key: 'TEMPORARY_USER_ID',
  kind: 'user',
};

export const asyncFlagProvider = async (
  clientSideID: string,
  context = initialContext
) => {
  return await asyncWithLDProvider({
    clientSideID,
    context,
    options: {
      /**
       * The capacity of the analytics events queue. (Default is 100)
       */
      eventCapacity: 500,

      /**
       * If set to true, this prevents the SDK from trying to use a synchronous HTTP request to deliver
       * analytics events if the page is being closed. Not all browsers allow such requests; the SDK
       * normally tries to avoid making them if not allowed, by using browser detection, but sometimes
       * browser detection may not work so if you are seeing errors like "synchronous XHR request
       * during page dismissal", you may want to set this option. Since currently the SDK does not have
       * a better way to deliver events in this scenario, some events may be lost.
       *
       * We have set this to true to prevent the app throwing Errors during Cypress tests.
       */
      disableSyncEventPost: true,

      /**
       * Whether analytics events should be sent only when you call variation (true), or also when you
       * call allFlags (false).
       *
       * By default, this is false (events will be sent in both cases).
       */
      sendEventsOnlyForVariation: true,
    },
  });
};
