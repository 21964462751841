import isUndefined from 'lodash/isUndefined';
import { useState } from 'react';

import { Hide, useMobileMediaQuery } from '@jane/reefer';

import { useActiveFilterContext } from '../activeFilterProvider';
import { StyledFilterList } from '../filter.styles';
import { FilterButton } from '../filterButton';
import { FilterCheckbox } from '../filterCheckbox';
import type { FilterListProps } from './filterBarPopover.types';

export const FilterList = ({
  filterKey,
  filters,
  isNested,
  onChange,
  query,
}: FilterListProps) => {
  const { label, subItems, value } = filters;
  const [showSubItems, setShowSubItems] = useState(false);
  const hasSubItems = subItems && subItems.length > 0;
  const isMobile = useMobileMediaQuery({});
  const { activeFilters } = useActiveFilterContext();

  const isIndeterminate = subItems
    ?.map((subItem) =>
      activeFilters.some((filter) => filter.value === subItem.value)
    )
    .includes(true);

  const isTopLevelChecked =
    hasSubItems && activeFilters.some((filter) => filter.value === value);

  const subItemValues = hasSubItems
    ? subItems.map((subItem) => subItem.value)
    : undefined;

  // multiselect filters can either be a checkbox or button,
  // depending on the screen size
  const renderedItem = (children?: React.ReactNode) => {
    // if there are no subItems, render as a button on mobile
    if (!children && !isNested && isMobile) {
      return (
        <FilterButton
          filterKey={filterKey}
          item={filters}
          onChange={onChange}
        />
      );
    }

    return (
      <FilterCheckbox
        key={label}
        children={children}
        filterKey={filterKey}
        hasSubItems={hasSubItems}
        indeterminate={isIndeterminate}
        item={filters}
        onChange={onChange}
        showSubItems={showSubItems}
        setShowSubItems={setShowSubItems}
        subItemValues={subItemValues}
        query={query}
      />
    );
  };

  // for now, subItems are only rendered as checkboxes
  const renderedSubItems = subItems?.map((item) => {
    // if parent category is refined, fire onchange for parent category on sub item select
    return (
      <FilterCheckbox
        filterKey={filterKey}
        key={item.label}
        isTopLevelChecked={isTopLevelChecked}
        item={item}
        onChange={onChange}
        isSubItem={true}
        topLevelValue={value as string}
      />
    );
  });

  if (hasSubItems) {
    // passes subItems to be nested under their parent item in collapsible list
    return renderedItem(
      <Hide isHidden={!showSubItems}>
        <StyledFilterList
          aria-label={`${label}: sub items`}
          query={isUndefined(query)}
          subList
        >
          {renderedSubItems}
        </StyledFilterList>
      </Hide>
    );
  }

  return renderedItem();
};
