import { Button, DismissIcon, Flex, Link, Typography } from '@jane/reefer';
import type { IconComponent } from '@jane/reefer';

import type { ProductFilterBarProps } from './productFilterBar.types';

type ActiveFilterProps = Pick<
  ProductFilterBarProps,
  'activeFilters' | 'onChange' | 'onDeselect'
>;

export const ActiveFilters = ({
  activeFilters,
  onChange,
  onDeselect,
}: ActiveFilterProps) => {
  const filterButtons = activeFilters.map((filter) => {
    const { icon, key, label, value } = filter;
    const hasIcon = icon !== undefined;
    const Icon = icon as IconComponent;
    return (
      <Button
        key={label}
        label={label}
        endIcon={hasIcon ? <Icon size="sm" /> : undefined}
        startIcon={<DismissIcon />}
        onClick={() => onChange(key, value)}
        variant="secondary"
        mr={16}
        mb={16}
      />
    );
  });

  return activeFilters.length > 0 ? (
    <Flex alignItems="center" flexWrap="wrap" px={24}>
      {filterButtons}
      <Link onClick={() => onDeselect('all')} ml={12} mb={16}>
        <Typography>Clear all</Typography>
      </Link>
    </Flex>
  ) : null;
};
