import uniq from 'lodash/uniq';

import type { AddedOrUpdatedProductInCart } from '@jane/shared-ecomm/tracking';
import {
  EventNames,
  coreProductProperties,
  track,
} from '@jane/shared-ecomm/tracking';
import { getActualUnitPrice, getUnitPrice } from '@jane/shared-ecomm/util';
import { config } from '@jane/shared/config';
import type {
  Cart,
  Id,
  MenuProduct,
  PriceId,
  Recommendation,
  Store,
  StoreSpecial,
} from '@jane/shared/models';

import { isNoStore } from '../../lib/customer/redux/store';
import { currencyCodeFromCountryCode } from '../lib/currencyCode';

const { algoliaEnv } = config;

const buildFiltersFromMenuProduct = (menuProduct: MenuProduct) => {
  const filters = [];
  const algoliaFilterMap = {
    brand: menuProduct.brand,
    kind: menuProduct.kind,
    category: menuProduct.category,
    brand_subtype: menuProduct.brand_subtype,
    root_subtype: menuProduct.root_subtype,
  };

  for (const [key, value] of Object.entries(algoliaFilterMap)) {
    if (value) {
      filters.push(`${key}:${value}`);
    }
  }

  return filters;
};

export const trackCartEvent = ({
  count,
  event,
  menuProduct,
  price_id,
  store,
  special,
  location,
}: {
  count: number;
  event: AddedOrUpdatedProductInCart['event'];
  location: AddedOrUpdatedProductInCart['location'];
  menuProduct: MenuProduct | Recommendation;
  price_id: PriceId;
  special?: StoreSpecial;
  store: Store;
}) => {
  const pricePerUnit =
    getActualUnitPrice(menuProduct as MenuProduct, price_id, special) || 0;
  const pricePerUnitWithoutDiscount =
    getUnitPrice(menuProduct as MenuProduct, price_id) || 0;

  const priceTotal = pricePerUnit * count;

  const storeCurrency = !isNoStore(store)
    ? currencyCodeFromCountryCode(store.country_code || '')
    : undefined;

  track({
    event,
    location,
    indexName: `products-${algoliaEnv}`,
    objectIds: [menuProduct.id.toString()],
    filters: buildFiltersFromMenuProduct(menuProduct),
    storeId: store.id.toString(),
    storeName: store.name,
    storeCity: store.city || undefined,
    storeCurrency,
    storeState: store.state || undefined,
    ...coreProductProperties(menuProduct),
    quantity: count,
    priceUnit: price_id,
    pricePerUnit,
    priceTotal,
    recommended:
      !!(menuProduct as Recommendation).score ||
      !!(menuProduct as MenuProduct).recommended,
    ...(special
      ? {
          specialId: (menuProduct as any).special_id?.toString() || '',
          specialDiscountPerUnit: pricePerUnitWithoutDiscount - pricePerUnit,
          specialDiscountTotal:
            (pricePerUnitWithoutDiscount - pricePerUnit) * count,
        }
      : {}),
  });
};

export const trackCheckoutSuccess = (
  products: Cart['products'],
  cartId?: Id,
  store?: any
) => {
  const objectIds = products.map((product) => product.id.toString());

  const filters = products.reduce<string[]>((acc, product) => {
    acc.push(...buildFiltersFromMenuProduct(product));
    return acc;
  }, []);

  const priceTotal = products.reduce<number>((acc, product) => {
    const unitPrice =
      getActualUnitPrice(product as MenuProduct, product.price_id) || 0;
    acc += product.count * unitPrice;
    return acc;
  }, 0);

  const storeCurrency = store
    ? currencyCodeFromCountryCode(store.countr_code)
    : undefined;

  const eventsProducts = products.reduce<any>((acc, product) => {
    acc.push({
      productId: product.id,
      productName: product.name,
      productKind: product.kind,
      productBrand: product.brand,
      quantity: product.count,
      priceUnit:
        getActualUnitPrice(product as MenuProduct, product.price_id) || 0,
    });
    return acc;
  }, []);

  let trackableCartId;
  if (cartId) {
    trackableCartId = cartId;
  } else {
    trackableCartId = `cart-${Date.now().toString()}`;
  }

  track({
    event: EventNames.CheckoutSuccess,
    indexName: `products-${algoliaEnv}`,
    filters: uniq(filters),
    products: eventsProducts,
    objectIds,
    priceTotal,
    cartId: trackableCartId,
    storeCurrency,
  });
};
