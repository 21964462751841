import {
  BlissfulIcon,
  CbdIcon,
  CreativeIcon,
  CreditCardIcon,
  EaseMyMindIcon,
  EdiblesIcon,
  EnergeticIcon,
  ExtractsIcon,
  FlowerIcon,
  GearIcon,
  GetActiveIcon,
  GetIntimateIcon,
  GetReliefIcon,
  GetSomeSleepIcon,
  GrowIcon,
  HangWithFriendsIcon,
  HungryIcon,
  HybridIcon,
  IndicaIcon,
  MerchIcon,
  NotHighIcon,
  OwnershipBipocIcon,
  OwnershipLgbtqIcon,
  OwnershipVeteranIcon,
  OwnershipWomanIcon,
  PainFreeIcon,
  PrerollIcon,
  RelaxedIcon,
  SativaIcon,
  SleepyIcon,
  StimulateMyMindIcon,
  TincturesIcon,
  TopicalsIcon,
  VapeIcon,
  WheelchairAccessIcon,
} from '@jane/reefer';
import type { ComponentColors, IconComponent } from '@jane/reefer';

export const PRODUCT_ROOT_TYPES = [
  'gear',
  'pre-roll',
  'extract',
  'edible',
  'merch',
  'grow',
  'tincture',
  'flower',
  'topical',
  'vape',
];

export const bucketFilterKeys = ['bucket_price', 'aggregate_rating'];
export const rangeFilterKeys = ['percent_cbd', 'percent_thc'];

export const HITS_PER_PAGE = 48;

export type FilterIcons = { [label: string]: IconComponent };
export type IconColors = { [label: string]: ComponentColors };

export const CATEGORY_ICONS: FilterIcons = {
  flower: FlowerIcon,
  vape: VapeIcon,
  'pre-roll': PrerollIcon,
  edible: EdiblesIcon,
  extract: ExtractsIcon,
  tincture: TincturesIcon,
  topical: TopicalsIcon,
  gear: GearIcon,
  merch: MerchIcon,
  grow: GrowIcon,
};

export const FEELING_ICONS: FilterIcons = {
  Relaxed: RelaxedIcon,
  Sleepy: SleepyIcon,
  Blissful: BlissfulIcon,
  Hungry: HungryIcon,
  'Pain free': PainFreeIcon,
  'Not high': NotHighIcon,
  Energetic: EnergeticIcon,
  Creative: CreativeIcon,
};

export const ACTIVITY_ICONS: FilterIcons = {
  'Ease my mind': EaseMyMindIcon,
  'Get relief': GetReliefIcon,
  'Get some sleep': GetSomeSleepIcon,
  'Stimulate my mind': StimulateMyMindIcon,
  'Hang with friends': HangWithFriendsIcon,
  'Get Active': GetActiveIcon,
  'Get intimate': GetIntimateIcon,
};

export const LINEAGE_ICONS: FilterIcons = {
  sativa: SativaIcon,
  indica: IndicaIcon,
  hybrid: HybridIcon,
  cbd: CbdIcon,
};

export const OWNERSHIP_ICONS: FilterIcons = {
  lgbtq: OwnershipLgbtqIcon,
  bipoc: OwnershipBipocIcon,
  veteran: OwnershipVeteranIcon,
  woman: OwnershipWomanIcon,
};

export const SERVICES_ICONS: FilterIcons = {
  credit_cards: CreditCardIcon,
  accessible: WheelchairAccessIcon,
};

export const LINEAGE_COLORS: IconColors = {
  sativa: 'ember',
  indica: 'grape',
  hybrid: 'grays-black',
  cbd: 'grays-mid',
};

export const sortOptions = [
  {
    label: 'A-Z',
    value: '',
  },
  {
    label: 'Price Low To High',
    value: 'by-price-',
    aliases: [
      'by-price-half-gram-',
      'by-price-gram-',
      'by-price-two-gram-',
      'by-price-eighth-ounce-',
      'by-price-quarter-ounce-',
      'by-price-half-ounce-',
      'by-price-ounce-',
    ],
  },
  {
    label: 'Price High to Low',
    value: 'by-price-desc-',
    aliases: [
      'by-price-half-gram-desc-',
      'by-price-gram-desc-',
      'by-price-two-gram-desc-',
      'by-price-eighth-ounce-desc-',
      'by-price-quarter-ounce-desc-',
      'by-price-half-ounce-desc-',
      'by-price-ounce-desc-',
    ],
  },
  {
    label: 'THC% Low to High',
    value: 'by-thc-potency-asc-',
  },
  {
    label: 'THC% High to Low',
    value: 'by-thc-potency-desc-',
  },
  {
    label: 'Rating',
    value: 'by-rating-',
  },
];
