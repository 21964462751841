import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Grow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_7797_13272)">
      <rect x={11.0225} y={11} width={2} height={10} fill="#4BA279" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 24C18.911 22.9724 15.5356 22.4067 12 22.4067C8.46437 22.4067 5.08904 22.9724 2 24C3.78387 20.4512 7.59121 18 12 18C16.4088 18 20.2161 20.4512 22 24Z"
        fill="#4BA279"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 24C18.911 22.9724 15.5356 22.4067 12 22.4067C8.46437 22.4067 5.08904 22.9724 2 24C3.78387 20.4512 7.59121 18 12 18C16.4088 18 20.2161 20.4512 22 24Z"
        fill="black"
        fillOpacity={0.5}
        className="details"
      />
      <path
        d="M9.5 8C9.5 3 10.75 0 12 0C13.25 0 14.5 3 14.5 8C14.5 13 13.25 13 12 13C10.75 13 9.5 13 9.5 8Z"
        fill="#4BA279"
      />
      <path
        d="M0 8C0 6.58594 8 6 12 9.85369V13.5C10.0041 13.5 8.00818 11.8537 6.01225 10.8537C2.85444 9.27156 0 9 0 8Z"
        fill="#4BA279"
      />
      <path
        d="M24 8C24 6.58594 16 6 12 9.85369V13.5C13.9959 13.5 15.9918 11.8537 17.9878 10.8537C21.1456 9.27156 24 9 24 8Z"
        fill="#4BA279"
      />
      <path
        d="M11.0484 14.9232C9.36658 14.9232 6.89489 17.6089 6.16915 16.872C5.25473 15.9436 8.12083 12 13 12C13 13.7075 12.7301 14.9232 11.0484 14.9232Z"
        fill="#4BA279"
      />
      <path
        d="M12.9516 14.9232C14.6334 14.9232 17.1051 17.6089 17.8309 16.872C18.7453 15.9436 15.8792 12 11 12C11 13.7075 11.2699 14.9232 12.9516 14.9232Z"
        fill="#4BA279"
      />
    </g>
    <defs>
      <clipPath id="clip0_7797_13272">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const GrowIcon = ({ ...props }: DynamicIconProps) => {
  return <Icon icon={Grow} {...props} color={props.color} />;
};
