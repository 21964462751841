import type { CSSObject } from '@jane/reefer';

export const horizontalScroll = (): CSSObject => ({
  overflowX: 'scroll',
  scrollbarWidth: 'none',
  MsOverflowStyle: 'none',
  WebkitOverflowScrolling: 'touch',
  '&::-webkit-scrollbar': {
    width: 0,
  },
});
