import { Flex, Form, Typography, spacing, styled } from '@jane/reefer';

export const SubmittableTextContainer = styled(Flex)(({ theme }) => ({
  ...spacing({ mt: 16, mb: 24 }),
  overflow: 'hidden',
}));

export const StyledTextField = styled(Form.TextField)(({ theme }) => ({
  width: '100%',
  color: theme.colors.primary.main,
  backgroundColor: theme.colors.grays.white,
  WebkitAppearance: 'none',
  '&::-ms-clear': {
    display: 'none',
  },
  '&::-webkit-search-cancel-button': {
    display: 'none',
  },
  '&:-webkit-autofill': {
    boxShadow: '0 0 0 100px white inset',
  },
  '::placeholder': {
    color: theme.colors.grays.light,
  },
}));

export const StyledValidationMessage = styled(Typography)(({ theme }) => ({
  border: `1px solid ${theme.colors.grays.light}`,
  width: '100%',
  height: '48px',
  ...spacing({ py: 12, px: 16 }),
  borderRadius: theme.borderRadius.sm,
}));
