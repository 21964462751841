import styled from '@emotion/styled';
import type { MouseEventHandler } from 'react';

import type { MarginProperties } from '../../styling';
import { spacing } from '../../styling';
import type { ComponentColors } from '../../types';
import { getColorValue } from '../../utils/componentStyles';
import type { Rotate } from './icon.types';

export const transform = (rotate?: Rotate) => {
  switch (rotate) {
    case 'left':
      return 'rotate(-90deg)';
    case 'right':
      return 'rotate(90deg)';
    case 'down':
      return 'rotate(-180deg)';
    default:
      return 'none';
  }
};

export interface StyledIconProps {
  color?: ComponentColors | 'inherit';
  rotate?: Rotate;
}

interface StyledContainerProps extends MarginProperties {
  color?: ComponentColors | 'inherit';
  height?: string | number;
  onClick?: MouseEventHandler<HTMLElement>;
  size: 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  width?: string | number;
}

const sizes = {
  sm: '16px',
  md: '24px',
  lg: '48px',
  xl: '64px',
  xxl: '96px',
};

export const StyledContainer = styled.div<StyledContainerProps>(
  ({ color, size, onClick, height, width, theme, ...marginProps }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: height ? height : sizes[size],
    width: width ? width : sizes[size],
    cursor: onClick ? 'pointer' : 'inherit',
    outline: 'none',
    ...spacing(marginProps),
    position: 'relative',
    ':focus-visible': {
      '&:after': {
        position: 'absolute',
        content: `''`,
        display: 'inherit',
        top: '-4px',
        left: '-4px',
        bottom: '-4px',
        right: '-4px',
        border: `2px solid ${
          color === 'inherit' || color === undefined
            ? theme.colors.primary.main
            : getColorValue(color, theme)
        }`,
        borderRadius: theme.borderRadius.lg,
        backgroundColor: 'transparent',
      },
    },
  })
);
