import merge from 'lodash/merge';

import type { SearchState, SearchStateWithStartPage } from '@jane/search/types';
import type { Store } from '@jane/shared/models';

export const JANE_DEFINED_ROWS = ['sale', 'best_selling'];

export const getUniqueCustomRow = (customRow: string, storeId?: Store['id']) =>
  storeId ? `${customRow}-${storeId}` : customRow;

export const formatUniqueCustomRow = (
  customRow: string,
  storeId?: Store['id']
) => (storeId && customRow ? customRow.replace(`-${storeId}`, '') : customRow);

export const searchStateWithStartPage = (
  searchState: SearchStateWithStartPage
) => {
  if (!searchState) {
    return {};
  }

  const { page, startPage, ...restOfSearchState } = searchState;
  return {
    ...restOfSearchState,
    page: parseInt(startPage as unknown as string) || 1,
  };
};

export const searchStateWithoutPage = (searchState: SearchState) => {
  if (!searchState) {
    return {};
  }

  const { page, ...restOfSearchState } = searchState;
  return restOfSearchState;
};

export const searchStateWithoutConfigure = (
  searchState: SearchStateWithStartPage
) => {
  if (!searchState) {
    return {};
  }

  const { configure, ...restOfSearchState } = searchState;
  return restOfSearchState;
};

const shouldDisambiguateCustomRow = (
  customRows: Store['custom_rows'],
  rootType: string
) => customRows?.includes(rootType) && !JANE_DEFINED_ROWS.includes(rootType);

export const searchStateWithCustomRowsDisambiguated = (
  searchState: SearchState,
  store: Store
) => {
  const rootTypes = searchState.refinementList?.['root_types'];
  if (Array.isArray(rootTypes)) {
    return merge(searchState, {
      refinementList: {
        root_types: rootTypes.map((rootType) =>
          shouldDisambiguateCustomRow(store.custom_rows, rootType)
            ? getUniqueCustomRow(rootType, store.id)
            : rootType
        ),
      },
    });
  }

  // NOTE(elliot): When there is only one refinement, it is a string
  if (typeof rootTypes === 'string') {
    const rootType = rootTypes;
    return merge(searchState, {
      refinementList: {
        root_types: shouldDisambiguateCustomRow(store.custom_rows, rootType)
          ? getUniqueCustomRow(rootType, store.id)
          : rootType,
      },
    });
  }

  return searchState;
};

const SORT_BY_PRICE_SUBSTRING = 'by-price';

export const isSortedByPrice = (searchState?: SearchState) => {
  return Boolean(searchState?.sortBy?.includes(SORT_BY_PRICE_SUBSTRING));
};

export const REVIEWS_REQUIRED_BEFORE_DISPLAY = 5;
export const MIN_REVIEWS_QUERY = `review_count >= ${REVIEWS_REQUIRED_BEFORE_DISPLAY}`;
