import { Typography, styled } from '@jane/reefer';
import type { WeightSelectorWeight } from '@jane/shared-ecomm/types';
import { labelForWeightId } from '@jane/shared-ecomm/util';
import type { PriceId } from '@jane/shared/models';
import { formatCurrency } from '@jane/shared/util';

import { SvgIcon } from '../../component-library/svg-icon';
import {
  border,
  flex,
  flexItem,
  media,
  mediaQueries,
  spacing,
} from '../../style';
import { Box } from '../../style/layout';
import { useProductCardContext } from '../storeMenu/providers/productCardProvider';

const WeightButtonItem = styled.button<{
  fullWidth?: boolean;
  selected: boolean;
  translateAmount: number;
  width: number;
  xMargin: number;
}>(
  {
    height: 54,
    outline: '0 !important',
    borderRadius: 4,
    position: 'relative',
    textAlign: 'left',
    flex: '0 0 auto',
    padding: '3px 3px',
    cursor: 'pointer',
  },
  ({ width, xMargin, fullWidth, theme }) => [
    border.thin(theme.colors.grays.light),
    { borderRadius: theme.borderRadius.lg },
    spacing({ mx: xMargin as any }),
    fullWidth && flexItem({ grow: true }),
    { minWidth: width },
  ],
  ({ selected, translateAmount, theme }) => [
    {
      backgroundColor: selected
        ? theme.colors.grays.white
        : 'rgba(255, 255, 255, .9)',
      color: selected ? theme.colors.primary.main : theme.colors.grays.black,
      [media(mediaQueries.hover)]: {
        '&:hover': {
          background: 'rgba(255, 255, 255, .8)',
        },
      },
    },
    selected && {
      borderColor: theme.colors.primary.main,
      borderWidth: '3px !important',
    },
    {
      transition: 'transform .2s',
      transform: `translateX(${translateAmount}px)`,
    },
  ]
);

const CheckIconWrapper = styled.span(
  {
    position: 'absolute',
    top: -4,
    right: -3,
    zIndex: 10,
    svg: {
      background: 'white',
      opacity: 1,
      border: '1px solid inherit',
      borderRadius: '50%',
    },
  },
  ({ theme }) => [
    { color: theme ? theme.colors.primary.main : 'rgba(255, 255, 255, 1)' },
  ]
);

const WeightsButtonItems = styled.div<{ fullWidth?: boolean }>(
  flex(),
  spacing({ p: 8 }),
  {
    overflow: 'hidden',
  },
  ({ fullWidth }) =>
    fullWidth && [flex({ justifyContent: 'space-around' }), { width: '100%' }]
);

interface Props {
  fullWidth?: boolean;
  icon?: boolean;
  itemWidth?: number;
  labelId: string;
  onChange: (priceId: PriceId) => void;
  selectedWeight: PriceId;
  translateAmount?: number;
  weights: WeightSelectorWeight[];
  xMargin?: number;
}

export const WeightPriceItems = ({
  fullWidth,
  itemWidth = 88,
  labelId,
  onChange,
  selectedWeight,
  translateAmount = 0,
  weights,
  xMargin = 2,
}: Props) => {
  const { cartProduct, menuProduct: product } = useProductCardContext();
  const weightsInCart = cartProduct.map((product) => product.price_id);

  return (
    <WeightsButtonItems
      fullWidth={fullWidth}
      role="radiogroup"
      aria-labelledby={labelId}
    >
      {weights.map((weight) => {
        const selected = weight.value === selectedWeight;
        const inCart = weightsInCart.includes(weight.value);
        const weightLabel = labelForWeightId(weight.value, product.amount);
        return (
          <WeightButtonItem
            key={weight.value}
            selected={selected}
            translateAmount={translateAmount}
            width={itemWidth}
            xMargin={xMargin}
            fullWidth={fullWidth}
            onClick={() => onChange(weight.value)}
            role="radio"
            aria-checked={selected}
          >
            <Box
              mt={selected ? -2 : 0}
              ml={selected ? -2 : 0}
              css={{ textAlign: 'center' }}
            >
              <Typography
                color="grays-mid"
                as="div"
                css={{ width: '100%' }}
                mb={-2}
                variant="mini"
              >
                {weightLabel}
              </Typography>
              <Typography as="div" variant="mini-bold">
                {formatCurrency(weight.price)}
              </Typography>
            </Box>
            {inCart && (
              <CheckIconWrapper>
                <SvgIcon size={18} icon="circleCheckFilled" />
              </CheckIconWrapper>
            )}
          </WeightButtonItem>
        );
      })}
    </WeightsButtonItems>
  );
};
