import * as t from 'io-ts';

import { tMenuProduct } from './menuProduct';
import { tProduct } from './product';

// payload schema
const tRecommendationRaw = t.interface({
  best_match: t.boolean,
  menu_product: t.union([tMenuProduct, t.null]),
  product: t.union([tProduct, t.null]),
  score: t.number,
});

export type RecommendationRaw = t.TypeOf<typeof tRecommendationRaw>;

export const tRecommendations = t.union([
  t.interface({
    recommendations: t.array(tRecommendationRaw),
  }),
  t.null,
]);

// js app schema (flattened version of the payload schema)
const tAbbreviatedRecommendation = t.interface({
  best_match: t.boolean,
  score: t.number,
  /** sometimes we don't want to show the score, e.g. homepage */
  showScore: t.union([t.boolean, t.null]),
});

export const tRecommendation = t.intersection([
  tAbbreviatedRecommendation,
  tMenuProduct,
]);
export type Recommendation = t.TypeOf<typeof tRecommendation>;
