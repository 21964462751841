import { hidden } from './accessibility';
import { effects } from './effects';
import { mediaQueries } from './mediaQueries';
import { shadows } from './shadows';
import { spacing } from './spacing';

export * from './accessibility';
export * from './colors';
export * from './effects';
export * from './flex';
export * from './mediaQueries';
export * from './shadows';
export * from './spacing';

export const styling = {
  mediaQueries,
  effects,
  hidden,
  shadows,
  spacing,
};
