import type {
  CustomerReservationDetails,
  ReservationMode,
  ReservationStatus,
} from '@jane/shared/models';

export function getReservationStatus(status: ReservationStatus) {
  return {
    isBeingVerified:
      status === 'verification' || status === 'staff_member_review',
    isBeingPrepared: status === 'preparing',
    isStarted: status === 'delivery_started',
    isDelivered: status === 'delivery_finished',
    isReady: status === 'ready_for_pickup',
    isCompleted: status === 'finished',
    isReviewed: status === 'with_review',
    isCancelled: status === 'cancelled',
    isDismissed: status === 'dismissed',
    isCompletedWithoutReview: status === 'finished_without_review',
    isInProgress:
      status === 'preparing' ||
      status === 'verification' ||
      status === 'staff_member_review' ||
      status === 'ready_for_pickup' ||
      status === 'delivery_started' ||
      status === 'delivery_finished',
  };
}

export function getReservationMode(mode: ReservationMode) {
  const isDelivery = mode === 'delivery';
  const isPickup = !isDelivery;

  return {
    isDelivery,
    isPickup,
  };
}

export function isPrepayable(reservation: CustomerReservationDetails) {
  if (!reservation.store.prepay) return false;
  if (reservation.prepaid) return false;

  const { isBeingPrepared, isStarted, isDelivered, isReady } =
    getReservationStatus(reservation.status);
  return isBeingPrepared || isStarted || isDelivered || isReady;
}

export function canHaveReview(
  reservation: Pick<CustomerReservationDetails, 'status'>
) {
  const { isReviewed, isCompleted } = getReservationStatus(reservation.status);
  return isReviewed || isCompleted;
}

export function isCompleteWithoutReview(
  reservation: Pick<CustomerReservationDetails, 'status'>
) {
  return getReservationStatus(reservation.status).isCompletedWithoutReview;
}

export function isDone(
  reservation: Pick<CustomerReservationDetails, 'status'>
) {
  const { isReviewed, isCompleted, isCompletedWithoutReview, isDelivered } =
    getReservationStatus(reservation.status);
  return isReviewed || isCompleted || isCompletedWithoutReview || isDelivered;
}
