import { useQuery } from '@tanstack/react-query';

import type {
  StoreFulfillmentTypes,
  StoreTypes,
} from '@jane/shared-ecomm/providers';
import { encodeQuery } from '@jane/shared-ecomm/util';
import { config } from '@jane/shared/config';
import { api } from '@jane/shared/data-access';
import type { Coordinates, Id } from '@jane/shared/models';

interface OptionalParams {
  distance?: number;
  fulfillmentType?: StoreFulfillmentTypes;
  openNow?: boolean;
  storeType?: StoreTypes;
}

interface ApiResponse {
  store_ids: Id[];
}

export const fetchUserPreferredStores = async (
  coordinates: Coordinates,
  { distance, fulfillmentType, openNow, storeType }: OptionalParams = {}
): Promise<ApiResponse['store_ids']> => {
  const params = {
    ...coordinates,
    distance,
    fulfillment_type: fulfillmentType,
    open_now: openNow,
    store_type: storeType,
  };

  const url = encodeQuery(
    `${config.apiPath}/stores/ids_by_shopping_preferences`,
    params
  );

  const res: ApiResponse = await api.get(url);

  return res.store_ids;
};

export const useUserPreferredStores = (
  coordinates: Coordinates,
  optionalParams: OptionalParams
) =>
  useQuery({
    enabled: true,
    queryFn: () => fetchUserPreferredStores(coordinates, optionalParams),
    queryKey: ['userPreferredStores', coordinates, optionalParams],
  });
