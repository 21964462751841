import type { ReactNode } from 'react';
import React, { useState } from 'react';

export const PaginationCountContext = React.createContext<any>({
  paginationCount: 1,
  setPaginationCount: () => {},
});

interface Props {
  children: ReactNode;
}

export const PaginationCountProvider = ({ children }: Props) => {
  const [paginationCount, setPaginationCount] = useState(1);

  return (
    <PaginationCountContext.Provider
      value={{
        paginationCount,
        setPaginationCount,
      }}
    >
      {children}
    </PaginationCountContext.Provider>
  );
};
