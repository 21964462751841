import { useCallback } from 'react';

import { CheckmarkIcon, Flex, Link, Typography } from '@jane/reefer';
import type { PriceId } from '@jane/shared/models';
import { formatCurrency } from '@jane/shared/util';

import { useMediaQuery } from '../../../../common/providers/mediaQueryProvider';
import { useCustomerDispatch } from '../../../../customer';
import { deleteCartItem } from '../../../../customer/redux/cart';
import type { CustomerState } from '../../../../customer/redux/types';
import { isEmbeddedModeSelector } from '../../../../customer/selectors';
import {
  DisplayMode,
  useProductCardContext,
} from '../../providers/productCardProvider';

interface ConfirmationProps {
  discountedPrice: number;
  onWeightSelected: (selectedWeight: PriceId) => void;
  originalPrice: number;
}
const Confirmation = ({
  originalPrice,
  discountedPrice,
  onWeightSelected,
}: ConfirmationProps) => {
  const { appMode, cartProduct, setDisplayMode, trackListViewClick } =
    useProductCardContext();
  const { smallerThanVerticalTablet } = useMediaQuery();

  const isEmbeddedMode = isEmbeddedModeSelector({
    embeddedApp: { appMode },
  } as CustomerState);

  const dispatch = useCustomerDispatch();
  const onRemove = useCallback(() => {
    if (cartProduct) {
      dispatch(deleteCartItem(cartProduct[0].id, cartProduct[0].price_id));
    }
    trackListViewClick && trackListViewClick();
  }, [cartProduct, dispatch, trackListViewClick]);

  const onlyOneWeightInCart = cartProduct.length === 1;

  const handleEditExisting = () => {
    onWeightSelected(cartProduct[0].price_id);
    setDisplayMode(DisplayMode.Edit);
  };

  return (
    <Flex
      flexDirection={smallerThanVerticalTablet ? 'row' : 'column'}
      justifyContent={smallerThanVerticalTablet ? 'space-between' : 'center'}
      width={smallerThanVerticalTablet ? '100%' : undefined}
      alignItems="center"
    >
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems={smallerThanVerticalTablet ? 'flex-start' : 'center'}
      >
        <CheckmarkIcon color="grays-black" />
        {originalPrice && (
          <Typography
            color="grays-black"
            variant="header-bold"
            branded={!isEmbeddedMode}
          >
            {discountedPrice
              ? formatCurrency(discountedPrice)
              : formatCurrency(originalPrice)}
          </Typography>
        )}
        <Typography
          color="grays-black"
          variant="header-bold"
          branded={!isEmbeddedMode}
        >
          Added to Bag
        </Typography>
      </Flex>
      <Flex
        width={smallerThanVerticalTablet ? undefined : '100%'}
        alignItems={smallerThanVerticalTablet ? 'flex-end' : undefined}
        justifyContent={smallerThanVerticalTablet ? undefined : 'space-around'}
        flexDirection={smallerThanVerticalTablet ? 'column' : 'row'}
      >
        <Link onClick={handleEditExisting}>Edit</Link>
        {onlyOneWeightInCart && <Link onClick={onRemove}>Remove</Link>}
      </Flex>
    </Flex>
  );
};

export default Confirmation;
