import sortBy from 'lodash/sortBy';

import type {
  Coordinates,
  PriceId,
  StoreWithMenuProduct,
} from '@jane/shared/models';

import { calculateDistance } from './delivery';

const POPULARITY_DIVISOR = 100;

export enum SortType {
  popularity = 'popularity',
  price = 'price',
  productCount = 'productCount',
  productCountForBrand = 'productCountForBrand',
  proximity = 'proximity',
}

export function sortStoresBy(
  stores: StoreWithMenuProduct[],
  sortType: SortType,
  priceSortUnit: PriceId,
  coordinates: Coordinates | undefined
): StoreWithMenuProduct[] {
  if (sortType === SortType.proximity) {
    return sortByProximity(stores, coordinates) as StoreWithMenuProduct[];
  } else if (sortType === SortType.price) {
    return sortByPrice(stores, priceSortUnit);
  } else if (sortType === SortType.popularity) {
    return sortByPopularity(stores);
  } else if (sortType === SortType.productCount) {
    return sortByProductCount(stores);
  } else if (sortType === SortType.productCountForBrand) {
    return sortByBrandProductCount(stores);
  }

  throw new Error('Unknown sortType');
}

function sortByProximity(
  stores: StoreWithMenuProduct[],
  coordinates: Coordinates | undefined
) {
  return sortBy(stores, (store) => calculateDistance(coordinates, store));
}

function sortByProductCount(stores: StoreWithMenuProduct[]) {
  return sortBy(stores, 'product_count').reverse();
}

function sortByBrandProductCount(stores: StoreWithMenuProduct[]) {
  return sortBy(stores, 'product_count_for_brand').reverse();
}

function sortableValueForPrice(store: StoreWithMenuProduct, key: string) {
  const str =
    (store.menu_product as any)[`discounted_${key}`] ||
    (store.menu_product as any)[key];
  return str ? parseFloat(str) : 999999999;
}

function sortByPrice(stores: StoreWithMenuProduct[], priceSortUnit: PriceId) {
  return sortBy(stores, (store) =>
    sortableValueForPrice(store, `price_${priceSortUnit}`)
  );
}

function sortByPopularity(stores: StoreWithMenuProduct[]) {
  return stores.sort(function (a, b) {
    const aNormalizedRating = a.rating + a.reviews_count / POPULARITY_DIVISOR;
    const bNormalizedRating = b.rating + b.reviews_count / POPULARITY_DIVISOR;
    if (bNormalizedRating > aNormalizedRating) return 1;
    if (bNormalizedRating < aNormalizedRating) return -1;
    return 0;
  });
}
