export const shadows = {
  soft: '0px 2px 16px rgba(0, 0, 0, 0.05)',
  hard: '0px 2px 16px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)',
  item: '0px 2px 8px rgba(0, 0, 0, 0.05)',
  bar: '0px 0px 8px rgba(199, 161, 161, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)',
  divider: 'inset 0px -1px 0px rgba(0, 0, 0, 0.1)',
  dividerTop: 'inset 0px 1px 0px rgba(0, 0, 0, 0.1)',
  drop: '0px 2px 4px rgba(0, 0, 0, 0.05)',
  inner: 'inset 0px 1px 4px rgba(0, 0, 0, 0.1)',
};
