import type { MouseEventHandler, ReactNode } from 'react';

import type { MarginProperties, PaddingProperties } from '../../styling';
import type { ComponentColors } from '../../types';
import type { AccessibilityProps } from '../../types/accessibility';
import {
  fakeButtonProps,
  getAccessibilityProps,
  handleEnterKey,
} from '../../utils';
import { StyledBox } from './box.styles';

export interface BoxProps
  extends AccessibilityProps,
    MarginProperties,
    PaddingProperties {
  /** Render the `Box` as this HTML element. Defaults to `div`. */
  as?: keyof JSX.IntrinsicElements;

  /** Add a background color to the component */
  background?: ComponentColors;

  /** Add a 1px solid border around the component */
  border?: ComponentColors;

  /** Add a border radius to the component using available theme sizes */
  borderRadius?: 'sm' | 'lg';

  /** Optionally change the border width, defaults to 1px */
  borderWidth?: string;

  /** CSS bottom attribute of element */
  bottom?: string | number;

  /** Child elements of the `Box` */
  children?: ReactNode;

  /** Class attribute of element */
  className?: string;

  /** Set a height, accepts any valid `height` value */
  height?: string | number;

  /** Element id */
  id?: string;

  /** CSS left attribute of element */
  left?: string | number;

  /** Set item `max-width`, accepts any valid `max-width` value */
  maxWidth?: string | number;

  /** Set item `min-width`, accepts any valid `min-width` value */
  minWidth?: string | number;

  /** An optional `onClick` handler */
  onClick?: MouseEventHandler<HTMLElement>;

  /** CSS position attribute of element */
  position?:
    | 'static'
    | 'relative'
    | 'absolute'
    | 'fixed'
    | 'sticky'
    | 'inherit';

  /** CSS right attribute of element */
  right?: string | number;

  /** CSS top attribute of element */
  top?: string | number;

  /** Set item `width`, accepts any valid `width` value */
  width?: string | number;

  /** Set item `z-index`, accepts any valid `z-index` value */
  zIndex?: string | number;
}

/**
 * The `Box` component is a wrapping container component with `margin` and `padding`
 * spacing props. Additional style props including `background`, `border`, `borderRadius`, `height` and `width` are also available.
 * */

export const Box = ({
  ariaLabel,
  ariaLabelledBy,
  as = 'div',
  background,
  border,
  borderRadius,
  borderWidth = '1px',
  bottom,
  children,
  className,
  height,
  id,
  left,
  maxWidth,
  minWidth,
  onClick,
  position,
  right,
  role,
  top,
  width,
  zIndex,
  ...props
}: BoxProps) => {
  const a11yProps = getAccessibilityProps(
    { ariaLabel, ariaLabelledBy, role, onClick },
    'Box'
  );

  return (
    <StyledBox
      as={as}
      background={background}
      border={border}
      borderRadius={borderRadius}
      borderWidth={borderWidth}
      onClick={onClick}
      onKeyUp={(event) => handleEnterKey(event, onClick)}
      {...(onClick && fakeButtonProps)}
      bottom={bottom}
      className={className}
      height={height}
      id={id}
      left={left}
      maxWidth={maxWidth}
      minWidth={minWidth}
      position={position}
      right={right}
      top={top}
      width={width}
      zIndex={zIndex}
      {...a11yProps}
      {...props}
    >
      {children}
    </StyledBox>
  );
};
