import * as t from 'io-ts';

import type { AdFlight, DmMeta } from './ad';
import type { DeepReadonly } from './deepReadonly';
import {
  tBrandSpecialPrices,
  tEffect,
  tFlavor,
  tPhoto,
  tProductKind,
  tProductLineage,
  tProductType,
} from './product';

const tInventoryTax = t.interface({
  title: t.string,
  rate: t.number,
  is_taxable: t.boolean,
  apply_to_discounted_price: t.boolean,
  apply_to_non_cannabis_item: t.boolean,
});

export type InventoryTax = t.TypeOf<typeof tInventoryTax>;

const tInventory = t.intersection([
  t.interface({
    price_id: t.union([t.string, t.null]),
    id: t.number,
    menu_product_id: t.number,
    equivalency: t.number,
    bulk: t.boolean,
  }),
  t.partial({
    tax: t.union([tInventoryTax, t.null]),
  }),
]);

type Inventory = t.TypeOf<typeof tInventory>;

const tInventoryPotency = t.interface({
  price_id: t.union([t.string, t.null]),
  tac_potency: t.union([t.number, t.null]),
  thc_potency: t.union([t.number, t.null]),
  cbd_potency: t.union([t.number, t.null]),
});

type InventoryPotency = t.TypeOf<typeof tInventoryPotency>;

// This lines up with api/app/serializers/abbreviated_menu_product_serializer.rb
const tAbbreviatedMenuProduct = t.interface({
  id: t.number,
  brand: t.union([t.string, t.null]),
  brand_subtype: t.union([t.string, t.null]),
  kind: tProductKind,
  name: t.string,
  type: tProductType,
  price_each: t.union([t.string, t.null]),
  price_eighth_ounce: t.union([t.string, t.null]),
  price_gram: t.union([t.string, t.null]),
  price_half_gram: t.union([t.string, t.null]),
  price_half_ounce: t.union([t.string, t.null]),
  price_id: t.union([t.string, t.null, t.undefined]),
  price_ounce: t.union([t.string, t.null]),
  price_quarter_ounce: t.union([t.string, t.null]),
  price_two_gram: t.union([t.string, t.null]),
  product_id: t.number,
  product_brand_id: t.union([t.number, t.null, t.undefined]),
});
export type AbbreviatedMenuProduct = t.TypeOf<typeof tAbbreviatedMenuProduct>;
export const tAbbreviatedMenuProducts = t.array(tAbbreviatedMenuProduct);

export const tProductWeight = t.keyof({
  half_gram: null,
  gram: null,
  two_gram: null,
  eighth_ounce: null,
  quarter_ounce: null,
  half_ounce: null,
  ounce: null,
});

export type ProductWeight = t.TypeOf<typeof tProductWeight>;

export const tPriceId = t.union([tProductWeight, t.literal('each')]);

export type PriceId = t.TypeOf<typeof tPriceId>;

const tPhotoWeight = t.interface({
  id: t.number,
  menu_product_id: t.number,
  public_image_id: t.number,
  weights: t.union([t.undefined, t.array(tPriceId)]),
});

export const tProductWeightSpecial = t.interface({
  special_id: t.number,
  price: t.number,
  discount_price: t.union([t.string, t.null]),
  discount_amount: t.union([t.string, t.null]),
  discount_type: t.union([t.string, t.null]),
  discount_percent: t.union([t.string, t.null]),
});

export type ProductWeightSpecial = t.TypeOf<typeof tProductWeightSpecial>;

// This lines up with api/app/serializers/menu_product_serializer.rb which
// inherits from AbbreviatedMenuProductSerializer It duplicates several things
// from tProduct, but so does the serializer, so it's easier to keep them in
// sync if we don't couple to tProduct. It also includes things that only exist
// in the algolia model. Those are included in a partial.
export const tMenuProduct = t.intersection([
  tAbbreviatedMenuProduct,
  t.interface({
    aggregate_rating: t.union([t.number, t.null, t.undefined]),
    allow_multiple_flower_count: t.boolean,
    category: t.union([tProductLineage, t.null]),
    description: t.string,
    max_cart_quantity: t.number,
    net_weight_grams: t.union([t.number, t.null]),
    percent_cbd: t.union([t.number, t.null]),
    percent_thc: t.union([t.number, t.null]),
    product_percent_cbd: t.union([t.number, t.null]),
    product_percent_thc: t.union([t.number, t.null]),
    cbd_dosage_milligrams: t.union([t.number, t.null, t.undefined]),
    thc_dosage_milligrams: t.union([t.number, t.null, t.undefined]),
    visible: t.boolean,
    special_id: t.union([t.number, t.null]),
    special_title: t.union([t.string, t.null]),
    discounted_price_half_gram: t.union([t.string, t.null]),
    discounted_price_gram: t.union([t.string, t.null]),
    discounted_price_two_gram: t.union([t.string, t.null]),
    discounted_price_eighth_ounce: t.union([t.string, t.null]),
    discounted_price_quarter_ounce: t.union([t.string, t.null]),
    discounted_price_half_ounce: t.union([t.string, t.null]),
    discounted_price_ounce: t.union([t.string, t.null]),
    discounted_price_each: t.union([t.string, t.null]),
    quantity_value: t.union([t.number, t.null]),
    quantity_units: t.union([t.string, t.null]),
    review_count: t.union([t.number, t.null, t.undefined]),
    url_slug: t.string,
    root_subtype: t.union([t.string, t.null]),
    store_notes: t.union([t.string, t.null]),
    photos: t.array(tPhoto),
    image_urls: t.array(t.string),
    effects: t.array(tEffect),
    flavors: t.array(tFlavor),
  }),
  // From algolia, reference api/app/models/menu_product.rb
  t.partial({
    available_weights: t.array(t.string),
    brand_special_prices: t.union([tBrandSpecialPrices, t.null]),
    has_brand_discount: t.union([t.boolean, t.null]),
    dosage: t.union([t.string, t.null]),
    amount: t.union([t.string, t.null]),
    special_id: t.union([t.number, t.null]),
    special_title: t.union([t.string, t.null]),
    special_amount: t.union([t.string, t.null]),
    applicable_bundle_special_ids: t.union([
      t.null,
      t.interface({
        qualify: t.array(t.number),
        discount: t.array(t.number),
      }),
    ]),
    terpenes: t.array(t.record(t.string, t.string)),
    ingredients: t.array(t.string),
    allergens: t.array(t.string),
    operator_store_rank: t.union([t.number, t.null]),
    best_seller_rank: t.union([t.number, t.null]),
    applicable_special_ids: t.array(t.number),
    photo_weights: t.array(tPhotoWeight),
    percent_tac: t.union([t.number, t.null]),
    special_price_half_gram: t.union([tProductWeightSpecial, t.null]),
    special_price_gram: t.union([tProductWeightSpecial, t.null]),
    special_price_two_gram: t.union([tProductWeightSpecial, t.null]),
    special_price_eighth_ounce: t.union([tProductWeightSpecial, t.null]),
    special_price_quarter_ounce: t.union([tProductWeightSpecial, t.null]),
    special_price_half_ounce: t.union([tProductWeightSpecial, t.null]),
    special_price_ounce: t.union([tProductWeightSpecial, t.null]),
    special_price_each: t.union([tProductWeightSpecial, t.null]),
    quantity_value: t.union([t.number, t.null]),
    quantity_units: t.union([t.string, t.null]),
    review_count: t.union([t.number, t.null, t.undefined]),
    url_slug: t.string,
    root_subtype: t.union([t.string, t.null]),
    store_notes: t.union([t.string, t.null]),
    photos: t.array(tPhoto),
    image_urls: t.array(t.string),
    effects: t.array(tEffect),
    flavors: t.array(tFlavor),
  }),
]);

export type MenuProduct = DeepReadonly<
  t.TypeOf<typeof tMenuProduct> &
    Partial<{
      // flight and dm are for ads
      dmMeta?: DmMeta;
      flight?: AdFlight;
      inventories?: Inventory[];
      inventory_potencies?: InventoryPotency[];
      percentCBDA: number;
      percentTAC: number;
      percentTHCA: number;
      // These are from the client side cannabinoids serializer
      primaryPercentCBD: number;
      primaryPercentTHC: number;
      // This is added for some recommendation tracking on the client
      recommended: boolean;
      secondaryPercentCBD: number;
      secondaryPercentTHC: number;
    }>
>;
