import styled from '@emotion/styled';

import type { ReeferColors } from '../../../../types';
import type { SliderVariants } from '../field.types';

export const StyledSliderSpacer = styled.div({
  backgroundColor: 'transparent',
  height: '16px',
  pointerEvents: 'none',
  width: '100%',
  zIndex: -1,
});

interface StyledSliderProps {
  direction: 'left' | 'right';
  isDouble: boolean;
  variant: SliderVariants;
}

interface GetBackgroundColorProps {
  colors: ReeferColors;
  direction: 'left' | 'right';
  isDouble: boolean;
  variant: SliderVariants;
}
const getSliderBackgroundColor = ({
  variant,
  direction,
  isDouble,
  colors,
}: GetBackgroundColorProps) => {
  if (direction === 'left' && variant === 'inverse') return colors.grays.white;
  if (variant === 'inverse' && !isDouble) return colors.grays.white;
  if (direction === 'left' || !isDouble) return colors.primary.main;
  return 'transparent';
};

export const StyledSlider = styled.div<StyledSliderProps>(
  ({ direction, isDouble, variant, theme: { colors } }) => ({
    backgroundColor: getSliderBackgroundColor({
      variant,
      direction,
      isDouble,
      colors,
    }),
    borderRadius: '12px',
    bottom: '-2px',
    height: '2px',
    position: 'relative',
    pointerEvents: 'none',
    width: '100%',
  })
);

interface StyledInputProps {
  direction: 'left' | 'right';
  percentageValue: number;
  variant: SliderVariants;
}

export const StyledInput = styled.input<StyledInputProps>(
  {
    appearance: 'none',
    borderRadius: '12px',
    height: '2px',
    outline: 'none',
    position: 'relative',
    width: '100%',

    ':focus-visible': {
      '::-moz-range-thumb': {
        filter: 'brightness(90%)',
      },
      '::-webkit-slider-thumb': {
        filter: 'brightness(90%)',
      },
    },

    '::-moz-range-thumb': {
      appearance: 'none',
      borderRadius: '16px',
      cursor: 'pointer',
      height: '32px',
      position: 'relative',
      width: '32px',
      zIndex: 1,
      ':hover': {
        filter: 'brightness(90%)',
      },
    },

    '::-webkit-slider-thumb': {
      appearance: 'none',
      borderRadius: '16px',
      cursor: 'pointer',
      height: '32px',
      position: 'relative',
      width: '32px',
      zIndex: 1,
      ':hover': {
        filter: 'brightness(90%)',
      },
    },
  },
  ({
    direction,
    percentageValue,
    theme: {
      colors: { primary, grays },
    },
    width,
    variant,
  }) => {
    const sliderBarFill = 'transparent';

    if (variant === 'inverse') {
      return {
        background: `linear-gradient(to ${direction}, ${sliderBarFill} 0%, ${sliderBarFill} ${percentageValue}%, ${primary.dark} ${percentageValue}%, ${primary.dark} 100%);`,
        width,

        '::-moz-range-thumb': {
          background: `radial-gradient(circle, ${grays.white} 0px, ${grays.white} 25%, ${primary.main} 25%, ${primary.main} 100%);`,
          border: `2px solid ${grays.white}`,
        },

        '::-webkit-slider-thumb': {
          background: `radial-gradient(circle, ${grays.white} 0px, ${grays.white} 25%, ${primary.main} 25%, ${primary.main} 100%);`,
          border: `2px solid ${grays.white}`,
        },
      };
    }

    return {
      background: `linear-gradient(to ${direction}, ${sliderBarFill} 0%, ${sliderBarFill} ${percentageValue}%, ${grays.light} ${percentageValue}%, ${grays.light} 100%);`,
      width,

      '::-moz-range-thumb': {
        background: `radial-gradient(circle, ${primary.main} 0px, ${primary.main} 25%, ${grays.white} 25%, ${grays.white} 100%);`,
        border: `2px solid ${primary.main}`,
      },

      '::-webkit-slider-thumb': {
        background: `radial-gradient(circle, ${primary.main} 0px, ${primary.main} 25%, ${grays.white} 25%, ${grays.white} 100%);`,
        border: `2px solid ${primary.main}`,
      },
    };
  }
);
