import { Suspense } from 'react';
import type { FunctionComponent } from 'react';

import { LoadingWrapper } from '@jane/shared-ecomm/components';

export const Loader = (Component: FunctionComponent) => (props) =>
  (
    <Suspense fallback={<LoadingWrapper isLoading />}>
      <Component {...props} />
    </Suspense>
  );
