import styled from '@emotion/styled';

import type { PartnerLogoProps, PartnerLogoSizeType } from './partnerLogo';

export interface StyledPartnerLogoProps
  extends Pick<PartnerLogoProps, 'image' | 'variant'> {
  size: PartnerLogoSizeType;
}

export const PARTNER_LOGO_SIZES = {
  sm: 64,
  lg: 96,
  xl: 120,
};

export const StyledPartnerLogo = styled.div<StyledPartnerLogoProps>(
  ({ variant, theme, image, size }) => ({
    alignItems: 'center',
    background: image
      ? `url(${image}) no-repeat center`
      : theme.colors.grays.white,
    backgroundSize: PARTNER_LOGO_SIZES[size],
    border: '1px solid rgba(0, 0, 0, 0.05)',
    borderRadius: variant === 'store' ? theme.borderRadius[size] : '50%',
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    height: PARTNER_LOGO_SIZES[size],
    justifyContent: 'space-around',
    overflow: 'hidden',
    position: 'relative',
    width: PARTNER_LOGO_SIZES[size],
  })
);
