import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Edibles = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.9177 0.995913C12.0087 0.451176 12.4493 -0.00424429 12.9997 0.0411503C19.1593 0.549158 24 5.70934 24 12.0001C24 18.6275 18.6274 24.0001 12 24.0001C6.4882 24.0001 1.84436 20.2841 0.437072 15.2207C0.306475 14.7508 0.575955 14.2761 1.01236 14.0584C3.68637 12.7244 5.60314 10.0986 5.94524 6.99835C6.00581 6.4494 6.45105 6.00884 6.99578 5.91784C9.51167 5.49759 11.4975 3.5118 11.9177 0.995913Z"
      fill="#FF9900"
    />
    <path
      d="M7.33208 0.707091C6.94155 0.316567 6.30839 0.316567 5.91786 0.707092L5.33208 1.29288C4.94155 1.6834 4.94155 2.31657 5.33208 2.70709L5.91786 3.29288C6.30839 3.6834 6.94155 3.6834 7.33208 3.29288L7.91786 2.70709C8.30839 2.31657 8.30839 1.6834 7.91786 1.29288L7.33208 0.707091Z"
      fill="#FF9900"
    />
    <path
      d="M2.45708 4.70709C2.06655 4.31657 1.43339 4.31657 1.04286 4.70709L0.457077 5.29288C0.0665532 5.6834 0.0665538 6.31657 0.457078 6.70709L1.04286 7.29288C1.43339 7.6834 2.06655 7.6834 2.45708 7.29288L3.04286 6.70709C3.43339 6.31657 3.43339 5.6834 3.04286 5.29288L2.45708 4.70709Z"
      fill="#FF9900"
    />
    <path
      d="M17.4571 6.70708C17.0666 6.31655 16.4334 6.31655 16.0429 6.70708L15.4571 7.29286C15.0666 7.68339 15.0666 8.31655 15.4571 8.70708L16.0429 9.29287C16.4334 9.68339 17.0666 9.68339 17.4571 9.29286L18.0429 8.70708C18.4334 8.31655 18.4334 7.68339 18.0429 7.29286L17.4571 6.70708Z"
      fill="black"
      fillOpacity={0.5}
      className="details"
    />
    <path
      d="M16.3358 14.7927C15.7835 14.7927 15.3358 15.2404 15.3358 15.7927V16.6212C15.3358 17.1734 15.7835 17.6212 16.3358 17.6212H17.1642C17.7165 17.6212 18.1642 17.1734 18.1642 16.6212V15.7927C18.1642 15.2404 17.7165 14.7927 17.1642 14.7927H16.3358Z"
      fill="black"
      fillOpacity={0.5}
      className="details"
    />
    <path
      d="M9.45708 14.7071C9.06655 14.3166 8.43339 14.3166 8.04286 14.7071L7.45708 15.2929C7.06655 15.6834 7.06655 16.3166 7.45708 16.7071L8.04286 17.2929C8.43339 17.6834 9.06655 17.6834 9.45708 17.2929L10.0429 16.7071C10.4334 16.3166 10.4334 15.6834 10.0429 15.2929L9.45708 14.7071Z"
      fill="black"
      fillOpacity={0.5}
      className="details"
    />
  </svg>
);
export const EdiblesIcon = ({ ...props }: DynamicIconProps) => {
  return <Icon icon={Edibles} {...props} color={props.color} />;
};
