import * as t from 'io-ts';

import { tCoordinates } from './coordinates';

const tGeocodingResultOptionalAttributes = t.interface({
  city: t.union([t.string, t.undefined]),
  cityState: t.union([t.string, t.undefined]),
  countryCode: t.union([t.string, t.undefined]),
  google_place_id: t.union([t.string, t.undefined]),
  state: t.union([t.string, t.undefined]),
  street: t.union([t.string, t.undefined]),
  street2: t.union([t.string, t.undefined]),
  zipcode: t.union([t.string, t.undefined]),
  hasResetLocation: t.boolean,
});

const tGeocodingResult = t.intersection([
  t.interface({
    coordinates: tCoordinates,
  }),
  tGeocodingResultOptionalAttributes,
]);

export type GeocodingResult = t.TypeOf<typeof tGeocodingResult>;
