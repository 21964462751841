import React from 'react';

import type { GridContainerProps } from '../grid.types';
import { StyledGridContainer } from './gridContainer.styles';

/**
 * The `Grid` component is used to display a series of divs in a grid layout.
 *
 * Each individual item within the `Grid.Container` component is defined using the `Grid.Item` compoennt.
 */
export function GridContainer({
  children,
  direction = 'row',
  testId,
  ariaLabel,
  spacing = 16,
  center = false,
}: GridContainerProps) {
  return (
    <StyledGridContainer
      data-testid={testId}
      aria-label={ariaLabel}
      direction={direction}
      spacing={spacing}
      center={center}
    >
      {React.Children.map(
        children,
        (child) =>
          child &&
          React.cloneElement(child, {
            spacing,
            direction,
          })
      )}
    </StyledGridContainer>
  );
}
