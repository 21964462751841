import { config } from '@jane/shared/config';
import type { Id } from '@jane/shared/models';
import {
  tApplicableProducts,
  tQualifyingStatus,
  tStoreSpecials,
} from '@jane/shared/models';

const urlRoot = (storeId: Id) => `${config.apiPath}/stores/${storeId}/specials`;

export const getCurrentStoreSpecials = (storeId: Id) => ({
  url: urlRoot(storeId),
  type: tStoreSpecials,
});

export const getSpecialApplicableProducts = (
  storeId: Id,
  specialId: Id,
  cartId: Id
) => ({
  url: `${urlRoot(storeId)}/${specialId}/applicable_products`,
  type: tApplicableProducts,
});

export const getStoreSpecialQualifyingStatus = (
  storeId: Id,
  specialId: Id,
  cartId: Id
) => ({
  url: `${urlRoot(storeId)}/${specialId}/qualifying_status/${cartId}`,
  type: tQualifyingStatus,
});
