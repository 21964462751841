import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const GetRelief = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.0002 1C16.0002 0.447715 15.5525 0 15.0002 0H9.00024C8.44796 0 8.00024 0.447716 8.00024 1V7.00024C8.00024 7.55253 7.55253 8.00024 7.00024 8.00024H1C0.447715 8.00024 0 8.44796 0 9.00024V15.0002C0 15.5525 0.447715 16.0002 1 16.0002H7.00024C7.55253 16.0002 8.00024 16.448 8.00024 17.0002V23C8.00024 23.5523 8.44796 24 9.00024 24H15.0002C15.5525 24 16.0002 23.5523 16.0002 23V17.0002C16.0002 16.448 16.448 16.0002 17.0002 16.0002H23C23.5523 16.0002 24 15.5525 24 15.0002V9.00024C24 8.44796 23.5523 8.00024 23 8.00024H17.0002C16.448 8.00024 16.0002 7.55253 16.0002 7.00024V1Z"
      fill="#94B200"
    />
  </svg>
);
export const GetReliefIcon = ({ ...props }: StaticIconProps) => {
  return <Icon icon={GetRelief} {...props} color={undefined} />;
};
