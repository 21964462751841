import type { MouseEventHandler, ReactNode } from 'react';

import { Typography } from '../../typography/typography';
import { StyledButton } from './buttonToggleButton.styles';

export interface ButtonToggleButtonProps {
  /** Aria label */
  ariaLabel?: string;

  /** Child elements of the `ButtonToggle.Button` */
  children?: ReactNode;

  /** Disable this button option */
  disabled?: boolean;

  /** Stretches the container to fit its parent; buttons positioned with space-between */
  full?: boolean;

  /** Button label */
  label?: string;

  /** `onClick` handler (controlled by parent) */
  onClick?: MouseEventHandler<HTMLButtonElement>;

  /** Whether button is currently selected (controlled by parent) */
  selected?: boolean;

  /** Id used for testing */
  testId?: string;

  /** Value that is returned to the `onChange` handler when clicked */
  value: string | number;

  /** Defines button variant (controlled by parent) */
  variant?: 'primary' | 'inverse' | 'tertiary';
}

/**
 * Use `ButtonToggle.Button` within `ButtonToggle` to define available `ButtonToggle` options.
 */
export function ButtonToggleButton({
  ariaLabel,
  children,
  disabled = false,
  full = true,
  label,
  onClick,
  selected = false,
  testId,
  value,
  variant = 'primary',
}: ButtonToggleButtonProps) {
  return (
    <StyledButton
      aria-label={ariaLabel}
      data-testid={testId}
      disabled={disabled}
      full={full}
      id={`toggle-${value}`}
      onClick={onClick}
      selected={selected}
      type="button"
      value={value}
      variant={variant}
    >
      {!children && (
        <Typography variant="body-bold" as="span">
          {label}
        </Typography>
      )}
      {children && children}
    </StyledButton>
  );
}
