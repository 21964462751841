import { DateTimeFieldInternal } from '../fields/dateTimeField';
import type { DateTimeFieldProps } from '../fields/field.types';
import type { FormFieldProps } from '../formField.types';
import { FormFieldWrapper } from '../formFieldWrapper/formFieldWrapper';

export interface FormDateTimeFieldProps
  extends Omit<DateTimeFieldProps, 'children'>,
    Omit<FormFieldProps, 'max' | 'maxLength' | 'min' | 'minLength'> {}

/**
 * DateTime fields allow users to specify the date, time or both values into a UI.
 *
 * Use this component *within forms* for inputs of `type`: `date`, `time`, or `datetime-local`.
 *
 * **NOTE: DO NOT USE THIS COMPONENT OUTSIDE FORMS**
 *
 * For a similar component for use outside forms, see [`DateTimeField`](/story/components-forms-fields-datetimefield--default).
 */

export function FormDateTimeField({
  autoFocus = false,
  defaultValue,
  disabled = false,
  autocomplete,
  helperText,
  label,
  labelHidden = false,
  max,
  min,
  name,
  onBlur,
  onChange,
  readOnly = false,
  required = false,
  step,
  testId,
  type = 'date',
  validate,
  width,
  ...props
}: FormDateTimeFieldProps) {
  return (
    <FormFieldWrapper
      defaultValue={defaultValue}
      name={name}
      render={({ children, field }) => (
        <DateTimeFieldInternal
          autoFocus={autoFocus}
          defaultValue={defaultValue}
          disabled={disabled}
          autocomplete={autocomplete}
          helperText={helperText}
          label={label}
          labelHidden={labelHidden}
          name={name}
          onBlur={(value) => {
            onBlur && onBlur(value);
            field.onBlur();
          }}
          onChange={(value) => {
            onChange && onChange(value);
            field.onChange(value);
          }}
          onUpdate={onChange}
          min={min}
          max={max}
          readOnly={readOnly}
          step={step}
          ref={field.ref}
          type={type}
          testId={testId}
          value={field.value}
          width={width}
          {...props}
        >
          {children}
        </DateTimeFieldInternal>
      )}
      required={required}
      validate={validate}
    />
  );
}
