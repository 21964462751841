import type { SearchResults } from 'algoliasearch-helper';
import { Component } from 'react';
import type {
  BasicDoc,
  HighlightResult,
  Hit,
  Refinement,
  RefinementValue,
  SearchState,
} from 'react-instantsearch-core';
import type { RefinementItem } from 'react-instantsearch-dom';

import type {
  DeepReadonly,
  Product,
  ProductKind,
  ProductLineage,
} from '@jane/shared/models';

export type { SearchOptions, SearchResponse } from '@algolia/client-search';

export type {
  AllSearchResults,
  AlgoliaError,
  Refinement,
  RefinementValue,
  Hit,
  MenuProvided,
  MenuExposed,
  SearchState,
  SearchResults,
  StateResultsProvided,
  CurrentRefinementsProvided,
  CurrentRefinementsExposed,
  RefinementListExposed,
  RefinementListProvided,
  StatsProvided,
  InfiniteHitsProvided,
  NumericMenuExposed,
  NumericMenuProvided,
  SearchBoxProvided,
  SearchBoxExposed,
  InstantSearchProps,
  IndexProps,
} from 'react-instantsearch-core';

export type { Hit as AlgoliaHit } from '@algolia/client-search';

export type AlgoliaSearchResults = SearchResults;

export type ProductSearchResultType = HitType<Product> & {
  __position: number;
  product_id?: number;
};

export interface KindFacet {
  count: number;
  kind: KindOrType;
  name?: string;
}

export type KindOrType =
  | ProductLineage
  | ProductKind
  | 'featured'
  | 'sale'
  | 'best_selling'
  | 'discountableBundleProducts';

export type InitialQueryParams =
  | 'category'
  | 'brand'
  | 'root_types'
  | 'store_type'
  | 'query'
  | 'brand_subtype'
  | 'store_id';

export type InitialQuery = DeepReadonly<
  {
    [key in InitialQueryParams]?: {
      hard: boolean;
      value: string[];
    };
  } & { canUseSearchState: boolean }
>;

export type HitType<TDoc = BasicDoc> = TDoc & {
  __position: number; // TODO: (Evan) position is undocumented and should not be used. Can use Hit from react-instantsearch-core after __position is no longer used by Jane code
  _highlightResult: HighlightResult<TDoc>;
  objectID: string;
};

export interface JaneRefinementItem extends RefinementItem<string[]> {
  count: number;
  customLabel?: string | null;
  isRefined: boolean;
}

export type CurrentRefinementItem =
  | RefinementValue
  | RefinementValue[]
  | Refinement[];

export type RefinementListItem = Hit<{
  count: number;
  isRefined: boolean;
  label: string;
  value: string[];
}>;

export type MenuItem = {
  count: number;
  isRefined: boolean;
  label: string;
  value: string;
};

export interface SearchStateWithStartPage extends SearchState {
  startPage?: number;
}

export interface PaginationResultsProvidedProps {
  currentRefinement: number;
  nbPages: number;
  refine: (page: number) => void;
}

export interface RangeProvided {
  createURL: () => void;
  currentRefinement: {
    max?: number;
    min?: number;
  };
  max: number;
  min: number;
  precision: number;
  refine: () => void;
}

export interface ProvidedSortByItem {
  isRefined: boolean;
  label: string;
  value: string;
}

export interface SortByProvided {
  createURL: () => void;
  currentRefinement: string;
  hideLabel?: boolean;
  items: ProvidedSortByItem[];
  refine: (value: string) => void;
}

export interface FilterItem {
  end?: number;
  label: string;
  start?: number;
  value: string;
}

export interface ConfigureProps {
  aroundLatLng?: string;
  aroundPrecision?: number;
  aroundRadius?: number;
  attributesToHighlight?: string[];
  attributesToRetrieve?: string[];
  filters?: string;
  hitsPerPage?: number;
  optionalFilters?: string;
  query?: string;
  userToken?: string;
}

export class Configure extends Component<ConfigureProps> {}

export class ExperimentalConfigureRelatedItems extends Component<ConfigureProps> {}
