import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const OwnershipLgbtq = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 8.00061C14.268 8.00061 8 14.2686 8 22.0006H10C10 15.3732 15.3726 10.0006 22 10.0006V8.00061Z"
      fill="#4BA279"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 2.00061C10.9543 2.00061 2 10.9549 2 22.0006H4C4 12.0595 12.0589 4.00061 22 4.00061V2.00061Z"
      fill="#FF3333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 4.00061C12.0589 4.00061 4 12.0595 4 22.0006H6C6 13.1641 13.1634 6.00061 22 6.00061V4.00061Z"
      fill="#FF9900"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 6.00061C13.1634 6.00061 6 13.1641 6 22.0006H8C8 14.2686 14.268 8.00061 22 8.00061V6.00061Z"
      fill="#FFC220"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 10.0006C15.3726 10.0006 10 15.3732 10 22.0006H12C12 16.4778 16.4772 12.0006 22 12.0006V10.0006Z"
      fill="#4485DA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 12.0006C16.4772 12.0006 12 16.4778 12 22.0006H14C14 17.5823 17.5817 14.0006 22 14.0006V12.0006Z"
      fill="#8E00E6"
    />
  </svg>
);
export const OwnershipLgbtqIcon = ({ ...props }: StaticIconProps) => {
  return <Icon icon={OwnershipLgbtq} {...props} color={undefined} />;
};
