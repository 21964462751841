import styled from '@emotion/styled';

interface StyledTabsProps {
  full?: boolean;
}

export const StyledTabs = styled.div<StyledTabsProps>(
  {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    height: '48px',
    overflowX: 'auto',
    overflowY: 'hidden',
    scrollbarWidth: 'none',
    '&:hover': {
      overflowX: 'auto',
      scrollbarWidth: 'thin',
    },
  },
  ({ full }) => ({
    width: full ? '100%' : 'auto',
  })
);
