export const updateFacetCounts = (
  allFacets: { [attribute: string]: { [facet: string]: number } },
  updatedFacets: { [attribute: string]: { [facet: string]: number } }
) => {
  for (const facetGroup in updatedFacets) {
    if (facetGroup in allFacets) {
      const allAttributes = allFacets[facetGroup];
      const updatedAttributes = updatedFacets[facetGroup];

      for (const filter in updatedAttributes) {
        if (filter in allAttributes) {
          allAttributes[filter] = updatedAttributes[filter];
        }
      }
    }
  }
  return allFacets;
};
