import React from 'react';
import type { ReactElement, ReactNode } from 'react';

import type { LoadingColor } from '../loading';
import type { ButtonVariant } from './button.types';

export const iconWithProps = (icon: ReactNode, label?: string) =>
  React.Children.map(icon, (child) => {
    if (React.isValidElement(child)) {
      const altText = child.props.altText || label;
      const color = child.props.color || 'inherit';
      return React.cloneElement(child as ReactElement<any, string>, {
        altText,
        color,
      });
    }
    return child;
  });

export const loadingColor = (variant: ButtonVariant) => {
  let animationColor: LoadingColor = 'white';

  if (variant === 'secondary' || variant === 'primary-inverse') {
    animationColor = 'purple';
  }

  if (variant === 'tertiary' || variant === 'tertiary-selected') {
    animationColor = 'black';
  }

  return animationColor;
};
