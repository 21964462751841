import { Flex } from '../../flex';
import { DismissIcon } from '../../icon';
import { Typography } from '../../typography';
import { useDrawerContext } from '../drawer.context';
import type { DrawerHeaderProps } from '../drawer.types';
import { StyledDrawerHeader, StyledIconContainer } from './drawerHeader.styles';

export const DrawerHeader = ({
  background = 'grays-white',
  children,
  divider = false,
  title,
  subtitle,
}: DrawerHeaderProps) => {
  const { onRequestClose } = useDrawerContext();

  return (
    <StyledDrawerHeader background={background} divider={divider}>
      <Flex alignItems="center" justifyContent="center" width="100%">
        <StyledIconContainer>
          <DismissIcon
            altText="Close Drawer"
            color="inherit"
            mr={24}
            onClick={onRequestClose}
            testId="drawer-header-close-icon"
          />
        </StyledIconContainer>
        <Flex flexDirection="column">
          <Typography branded variant="header-bold">
            {title}
          </Typography>
          {subtitle && <Typography color="grays-mid">{subtitle}</Typography>}
        </Flex>
        <div />
      </Flex>
      {children && (
        <Flex mt={24} alignItems="center">
          {children}
        </Flex>
      )}
    </StyledDrawerHeader>
  );
};
