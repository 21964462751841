import { styled } from '@jane/reefer';

import type {
  FlexItemProperties,
  FlexProperties,
  MarginProperties,
  PaddingProperties,
} from '.';
import { flex, flexItem, hide, spacing } from '.';

export const Flex = styled.div<
  FlexProperties &
    MarginProperties &
    PaddingProperties &
    FlexItemProperties & { as?: keyof JSX.IntrinsicElements }
>(flex, flexItem, spacing);
export const Box = styled.div<
  FlexItemProperties &
    MarginProperties &
    PaddingProperties & { as?: keyof JSX.IntrinsicElements }
>(flexItem, spacing);
export const HiddenHeading = styled.h1(hide);
