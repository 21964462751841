import styled from '@emotion/styled';

import { spacing } from '../../styling';
import type { StyledListProps } from './list.types';

export const StyledList = styled.ul<StyledListProps>(({ ...props }) => ({
  display: 'flex',
  flexDirection: 'column',
  listStyle: 'none',
  width: '100%',
  ...spacing(props),
}));
