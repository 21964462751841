import type { MouseEventHandler, ReactNode } from 'react';

import type {
  FlexItemProperties,
  FlexProperties,
  MarginProperties,
  PaddingProperties,
} from '../../styling';
import type { AccessibilityProps } from '../../types/accessibility';
import {
  fakeButtonProps,
  getAccessibilityProps,
  handleEnterKey,
} from '../../utils';
import { StyledFlex } from './flex.styles';

export interface FlexProps
  extends AccessibilityProps,
    FlexProperties,
    FlexItemProperties,
    MarginProperties,
    PaddingProperties {
  /** Render the `Flex` component as this HTML element. Defaults to `div`. */
  as?: keyof JSX.IntrinsicElements;

  /** Child elements of the `Flex` component */
  children?: ReactNode;

  /** An optional `onClick` handler */
  onClick?: MouseEventHandler<HTMLElement>;
}

/**
 * The `Flex` component is a container component with `flex` layout that allows you
 * to easily set any `flex` parent and child related properties, as well as `margin`
 * and `padding` spacing props.
 * */
export const Flex = ({
  ariaLabel,
  ariaLabelledBy,
  as,
  children,
  onClick,
  role,
  ...props
}: FlexProps) => {
  const a11yProps = getAccessibilityProps(
    { ariaLabel, ariaLabelledBy, role, onClick },
    'Flex'
  );

  return (
    <StyledFlex
      as={as}
      onClick={onClick}
      onKeyUp={(event) => handleEnterKey(event, onClick)}
      {...(onClick && fakeButtonProps)}
      {...a11yProps}
      {...props}
    >
      {children}
    </StyledFlex>
  );
};
