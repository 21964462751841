import { Box, Button, Flex, Link, TextField } from '@jane/reefer';

import { UnlockWalletWrapper } from '../customerLoyaltyPoints.styles';

export const VerificationCodeForm = ({
  error,
  onBlur,
  onCancel,
  onChange,
  onSubmit,
  unlockLoading,
  displayError,
}: {
  displayError: boolean;
  error?: string;
  onBlur: () => void;
  onCancel: () => void;
  onChange: (value: string) => void;
  onSubmit: (e: React.SyntheticEvent) => void;
  unlockLoading: boolean;
}) => (
  <UnlockWalletWrapper>
    <Flex alignItems="flex-start" flexDirection="column">
      Enter your verification code
      <TextField
        width="100%"
        label=""
        name="verification-code"
        helperText={displayError && error}
        onChange={onChange}
        onBlur={onBlur}
        placeholder="Verification code"
      />
      <Box mt={16}>
        <Button
          onClick={onSubmit}
          disabled={Boolean(error)}
          variant="secondary"
          css={{ marginRight: 20 }}
          loading={unlockLoading}
          label="Submit"
        />
        <Link onClick={onCancel}>Cancel</Link>
      </Box>
    </Flex>
  </UnlockWalletWrapper>
);
