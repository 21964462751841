import { Link as RouterLink } from 'react-router-dom';

import { fakeButtonProps, handleEnterKey } from '../../../utils';
import type { ListItemProps } from '../list.types';
import {
  StyledListItem,
  StyledListItemLink,
  StyledListItemRouterLink,
} from './listItem.styles';

export const ListItem = ({
  alignment = 'left',
  children,
  disableHover = false,
  href,
  onClick,
  px = 8,
  py = 12,
  selected,
  to,
  ...spacingProps
}: ListItemProps) => {
  const sharedProps = {
    alignment,
    disableHover,
    selected,
  };

  if (href) {
    return (
      <StyledListItem
        hasLink={!!href}
        onClick={onClick}
        onKeyUp={(event) => handleEnterKey(event, onClick)}
        {...(onClick && fakeButtonProps)}
        {...sharedProps}
      >
        <StyledListItemLink
          href={href}
          variant="minimal"
          px={px}
          py={py}
          {...spacingProps}
        >
          {children}
        </StyledListItemLink>
      </StyledListItem>
    );
  }

  if (to) {
    return (
      <StyledListItem
        hasLink={!!to}
        onClick={onClick}
        onKeyUp={(event) => handleEnterKey(event, onClick)}
        {...(onClick && fakeButtonProps)}
        {...sharedProps}
      >
        <StyledListItemRouterLink px={px} py={py} {...spacingProps}>
          <RouterLink className="router-link" to={to}>
            {children}
          </RouterLink>
        </StyledListItemRouterLink>
      </StyledListItem>
    );
  }

  return (
    <StyledListItem
      onClick={onClick}
      onKeyUp={(event) => handleEnterKey(event, onClick)}
      {...(onClick && fakeButtonProps)}
      px={px}
      py={py}
      {...spacingProps}
      {...sharedProps}
    >
      {children}
    </StyledListItem>
  );
};
