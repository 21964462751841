import type { DependentBundleConditions } from '@jane/shared/models';

export interface ConstructedMenuSearchState {
  multiRange?: {
    bucket_price: string;
  };
  refinementList: {
    available_weights?: string[];
    brand?: string[];
    category?: string[];
    root_types?: string[];
  };
}

export const searchStateFromBundleConditions = (
  conditions: DependentBundleConditions
) => {
  const state: ConstructedMenuSearchState = {
    refinementList: {},
  };

  if (conditions.kinds && conditions.kinds.length > 0) {
    state.refinementList.root_types = conditions.kinds.map((kind) => {
      const baseKind = `${kind.kind}`;
      return kind.root_subtype ? `${baseKind}:${kind.root_subtype}` : baseKind;
    });
  }

  if (conditions.lineage) {
    state.refinementList.category = [conditions.lineage];
  }

  if (conditions.brands) {
    state.refinementList.brand = conditions.brands;
  }

  // store menu only supports filtering by one weight
  if (conditions.weights && conditions.weights.length === 1) {
    state.refinementList.available_weights = [
      conditions.weights[0].replace('_', ' '),
    ];
  }

  return state;
};
