import { createContext } from 'react';

import { StyledSkeleton } from './skeleton.styles';

export interface SkeletonProps {
  /** Animate all child Skeleton.Bones, defaults to `false` */
  animate?: boolean;

  /** Skeleton.Bones and any additional wrapping containers to create the required layout */
  children: React.ReactNode;

  /** Determines the flexDirection of the main Skeleton element */
  direction?: 'column' | 'row';

  /** Determines the height of the main Skeleton element, accepts any valid height property */
  height?: string | number;

  /** Id for testing */
  testId?: string;

  /** Determines the width of the main Skeleton element, accepts any valid width property */
  width?: string | number;
}

/**
 * Skeleton Context to pass 'animate' to Skeleton.Bones
 */

export const SkeletonContext = createContext({
  animate: true,
});

/**
 * Skeleton to build component load, save or error states
 */

export function Skeleton({
  animate = false,
  children,
  direction = 'column',
  height = 'fit-content',
  testId,
  width = '100%',
}: SkeletonProps) {
  return (
    <SkeletonContext.Provider value={{ animate }}>
      <StyledSkeleton
        direction={direction}
        height={height}
        width={width}
        role="presentation"
        test-id={testId}
      >
        {children}
      </StyledSkeleton>
    </SkeletonContext.Provider>
  );
}
