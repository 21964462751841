import styled from '@emotion/styled';

import { getColorValue } from '../../utils/componentStyles';
import { fakeButtonProps, handleEnterKey } from '../../utils/handleEnterKey';
import type { StyledIconProps } from './icon.styles';
import { StyledContainer, transform } from './icon.styles';
import type { IconProps } from './icon.types';

/**
 * Reefer offers a set of beautiful icons for use across application.
 */
export function Icon({
  altText,
  color,
  icon,
  id,
  height,
  width,
  onClick,
  rotate = 'up',
  size = 'md',
  testId,
  title,
  ...marginProps
}: IconProps) {
  const StyledIcon = styled(icon)<StyledIconProps>(
    ({ rotate, color, theme }) => ({
      userSelect: 'none',
      overflow: 'hidden',
      display: 'inline-block',
      textAlign: 'center',
      flexShrink: 0,
      height: '100%',
      width: '100%',
      transform: rotate ? transform(rotate) : 'none',
      ...(color && {
        '& rect, circle': {
          stroke:
            color === 'inherit' ? 'currentColor' : getColorValue(color, theme),
        },
        '& path:not(.details)': {
          fill:
            color === 'inherit' ? 'currentColor' : getColorValue(color, theme),
        },
      }),
    })
  );

  // Accessibility check
  if (onClick && !altText) {
    throw Error(
      `[reefer/icon]: Icons with onClick functionality should provide an "altText" prop.`
    );
  }

  return (
    <StyledContainer
      size={size}
      height={height}
      width={width}
      title={title}
      color={color === 'default' ? undefined : color}
      onClick={onClick}
      onKeyUp={(event) => handleEnterKey(event, onClick)}
      {...(onClick && fakeButtonProps)}
      {...marginProps}
    >
      <StyledIcon
        rotate={rotate}
        color={color === 'default' ? undefined : color}
        data-testid={testId}
        id={id}
        role="img"
        aria-hidden={!altText}
        aria-label={altText}
      />
    </StyledContainer>
  );
}
