import * as t from 'io-ts';

import { tOptionalFilter } from './store';

export const tProductBrand = t.interface({
  id: t.union([t.number, t.string]),
  name: t.string,
});

export const tBrandPartner = t.interface({
  id: t.union([t.number, t.string]),
  name: t.string,
  product_brands: t.array(tProductBrand),
  banner_photo_url: t.union([t.null, t.string]),
  logo_url: t.union([t.null, t.string]),
  navigation_color: t.union([t.null, t.string]),
  theme_color: t.union([t.null, t.string]),
  show_banner: t.union([t.null, t.boolean]),
  product_reviews_enabled: t.boolean,
  products_tab_hidden: t.union([t.null, t.undefined, t.boolean]),
  max_search_radius: t.union([t.null, t.string, t.number]),
  brand_subtypes: t.array(t.string),
  optional_filters: t.union([t.null, tOptionalFilter]),
  force_store_visibility: t.union([t.null, t.boolean]),
});

export type BrandPartner = t.TypeOf<typeof tBrandPartner>;
